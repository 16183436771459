import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lang',
})
export class LanguagePipe implements PipeTransform {
  transform(labelKey: string): string {
    const labels = {
      ortho: 'Ortho',
      logout: 'Logout',

      'forms.cancel': 'Cancel',
      'forms.draft': 'Draft',
      'forms.new': ' (new)',
      'forms.error.required': 'required',
      'forms.error.length': 'You have to enter less than 1000 characters.',
      'forms.error.repeated_item': 'This item is already added',
      'forms.error.repeated_patient':
        'Validation Error: Patient already exists',
      'forms.error.repeated_provider':
        'Validation Error: Provider already exists',
      'forms.error.npi_validate': 'Validation Error: Not a valid NPI',
      'forms.error.invalid_date': 'Not a valid date',
      'forms.error.invalidFormat': 'File format should be .json',
      'forms.error.editing_questions':
        'You should finish editing all the questions',
      'forms.error.deletePatientOrder':
        'You can not delete a patient that has an order in the system',
      'forms.confirm.delete': 'Are you sure you want to delete this item?',
      'forms.confirm.deleteStrong':
        'Are you sure you want to permanently delete this item and the related information?',
      'forms.confirm_delete_text.error':
        "You did not type the word 'Delete' to confirm the deletion",
      'forms.confirm.subtitle': 'If you exit your information will be lost',
      'forms.confirm.title': 'Leave without saving?',
      'forms.session.expired': 'Your session has expired. Please login again',
      'forms.session.logout': 'Go to login',
      'forms.confirm_delete_text':
        'Please type the word "Delete" to confirm deletion.',
      'forms.confirm.addInsurance':
        'You are trying to enter an existing Insurance.',
      'forms.confirm.addInventory':
        'You are trying to enter an existing Inventory.',
      'forms.no_cost_format':
        'Some of the cost you are trying to enter have the wrong format. Plese enter a cost like $123.00',

      'upload.confirm_file': 'Confirm File',
      'upload.no_file':
        'Please select a file to parse before to start any parser.',

      'orders.order-form-new.title': 'Create new order',
      'orders.order-form.title': 'Order Forms',
      'orders.order-form.title.step1': 'Patient information',
      'orders.order-form.title.step2': 'Insurance / Provider',
      'orders.order-form.title.step3': 'Line Items',
      'orders.order-form.title.step4': 'Notes',
      'orders.order-form.title.summary': 'Order Summary',
      'orders.order-form.title.step5': 'Order Forms',
      // 'orders.order-form.title.summary': 'Order Summary',
      'orders.order-form.label.date': 'Date of Service',
      'orders.order-form.label.date.esp': 'Fecha de Servicio',
      'orders.order-form.label.designated_fitter': 'Designated Fitter',
      'orders.order-form.label.billed_date': 'Billed Date',
      'orders.order-form.label.claim_id': 'Claim ID',
      'orders.order-form.label.patient_first_name': 'First Name',
      'orders.order-form.label.patient_last_name': 'Last Name',
      'orders.order-form.label.patient_dob': 'DOB',
      'orders.order-form.label.patient_mrn': 'MRN',
      'orders.order-form.label.patient_error_msg': 'Not a valid Patient',
      'orders.order-form.label.patient_insurance': 'Patient Insurance',
      'orders.order-form.label.patient_insurance_error_msg':
        'Not a valid Insurance',
      'orders.order-form.label.physician_error_msg': 'Not a valid Provider',
      'orders.order-form.label.physician': 'Provider',
      'orders.order-form.label.orderingProvider': 'Ordering provider',
      'orders.order-form.label.location': 'Location',
      'orders.order-form.label.hcpcs': 'Inventory',
      'orders.order-form.add_hcpcs': 'Search Inventory by code or name',
      'orders.order-form.label.hcpcs_error_msg':
        'Not a valid Inventory code for this location',
      'orders.order-form.label.icd10': 'ICD-10 Diagnosis Codes',
      'orders.order-form.label.icd10_error_msg': 'Not a valid ICD 10 Code',
      'orders.order-form.label.line_items': 'Order Lines',
      'orders.order-form.error.line_items':
        'You must add at least one order line item',
      'orders.order-form.modifier': 'Modifier',
      'orders.order-form.label.notes': 'Notes',
      'orders.order-form.label.submit_order': 'Submit order',
      'orders.order-form.label.save_order': 'Save order',
      'orders.order-form.label.save_draft': 'Save Draft',
      'orders.order-form.label.submit_go_froms': 'NEXT',
      'orders.order-form.message.success': 'Order successfully created.',
      'orders.order-form.placeholder.search-patient': 'Search patient by MRN',
      'orders.confirm.change.insurance':
        'Are you sure you want to change the insurance? The inventory items that are not related to it will be removed from the order',
      'orders.confirm.change.location':
        'Are you sure you want to change the provider - location? The inventory items that are not related to it will be removed from the order',
      'orders.delete': 'Delete Order',
      'orders.fully_dispatched': 'Fully Dispatched',
      'orders.edit': 'Edit Order',
      'orders.patient': 'Patient Info',
      'orders.order-form.placeholder.patient_insurance': 'Search by name',
      'orders.order-form.placeholder.physician': 'Search by name',
      'forms.form.label.name': 'Name',
      'forms.form.label.name.base': 'Base Name',
      'forms.form.label.language': 'Language',

      'orders.update.cin7.ref.title': 'Confirm Update?',
      'orders.update.cin7.ref.text': "Confirm updating order's cin7 ref?",

      'orders.popup.delete.title': 'Are you sure?',
      'orders.popup.delete.text': 'Are you sure you want to delete this order?',
      'locationGroups.popup.delete.text':
        'Are you sure you want to delete this group?',
      'orders.popup.cancel.draft.title': 'Are you sure?',
      'orders.popup.cancel.draft.text':
        'Are you sure you want to cancel this draft?',

      'orders.order-external-document.title': 'External Documents',
      'orders.order-external-document.error':
        'This document has not been fully processed yet therefore it can not be open yet, please try again in a minute.',

      'patients.patient-form-new.title': 'Create new patient',
      'patients.patient-form-search.title': 'Patient Search',
      'patients.patient-form-list.title': 'List Patients',
      'patients.title': 'Patient',
      'patients.patient_info': 'Patient Info',
      'patients.name.first_name': 'First Name',
      'patients.name.last_name': 'Last Name',
      'patients.MRN': 'MRN',
      'patients.DOB': 'DOB',
      'patients.phone_number': 'Phone Number',
      'patients.submit': 'Submit Patient',
      'patients.save': 'Save Patient',
      'patients.delete': 'Delete Patient',
      'patients.edit': 'Edit Patient',
      'patients.view': 'View',
      'patients.submited': 'Patient Submited',
      'patients.view.orders': 'View Orders History',
      'patients.view.patient': 'View Patient',

      'address.street_address_1': 'Street Address 1',
      'address.street_address_2': 'Street Address 2',
      'address.city': 'City',
      'address.state': 'State',
      'address.postal_code': 'Postal Code',

      'views.name': 'Name',
      'views.info': 'Info',
      'views.item_number': 'Item Number',
      'views.account_number': 'Account Number',
      'views.contact_name': 'Contact Name',
      'views.phone_number': 'Phone Number',
      'views.fax_number': 'Fax Number',

      'locations.location-form-new.title': 'Create new location',
      'locations.location-form-update.title': 'Update locations',
      'locations.location-form-list.title': 'List locations',
      'locations.phone_number': 'Phone Number',
      'locations.name': 'Location Name',
      'locations.title': 'Location',
      'locations.submit': 'Submit Location',
      'locations.save': 'Save Location',
      'locations.delete': 'Delete Location',
      'locations.edit': 'Edit Location',
      'locations.back': 'Back',
      'locations.view': 'View',
      'locations.new': 'new',
      'locations.submited': 'Location Submited',
      'locations.insurances': 'Insurances',
      'locations.inventory_items': 'Inventory items',
      'locations.location_cost': 'Location cost',
      'locations.allowable': 'Allowable',
      'locations.split': 'SOH margin',
      'locations.related_providers': 'Related Providers',
      'locations.related_fitters': 'Related Fitters',
      'locations.related_insurances': 'Related Insurances',
      'locations.search_provider': 'Search a Provider to add it',
      'locations.search_fitter': 'Search a Fitter to add it',
      'locations.search_insurance': 'Search an Insurance to add it',
      'locations.stock': 'Related Stock',
      'locations.select.logo': 'Select Logo',
      'locations.selected.logo': 'Selected Logo',

      'inventories.inventory-form-new.title': 'Create new inventory',
      'inventories.inventory-form-update.title': 'Update inventories',
      'inventories.inventory-form-list.title': 'List inventories',
      'inventories.item.info': 'Item Info',
      'inventories.item.name': 'Item Name',
      'inventories.item.vendor': 'Vendor',
      'inventories.title': 'Inventory',
      'inventories.name': 'Item Name',
      'inventories.vendor_name': 'Vendor Name',
      'inventories.vendor_name_view': 'Name',
      'inventories.vendor_info': 'Vendor Info',
      'inventories.vendor_info_view': 'Info',
      'inventories.description': 'Long Description',
      'inventories.vendor_item_number': 'Vendor Item Number',
      'inventories.vendor_item_number_view': 'Item Number',
      'inventories.vendor_account_number': 'Vendor Account Number',
      'inventories.vendor_account_number_view': 'Account Number',
      'inventories.vendor_contact_name': 'Vendor Contact Name',
      'inventories.vendor_contact_name_view': 'Contact Name',
      'inventories.vendor_contact_phone_number': 'Vendor Phone Number',
      'inventories.vendor_contact_phone_number_view': 'Phone Number',
      'inventories.HCPCS_code': 'HCPCS Code',
      'inventories.cost': 'Cost',
      'inventories.submit': 'Submit Inventory',
      'inventories.save': 'Save Inventory',
      'inventories.delete': 'Delete Inventory',
      'inventories.edit': 'Edit Inventory',
      'inventories.view': 'View',
      'inventories.submited': 'Inventory Submited',
      'inventories.related_HCPCS': 'Related HCPCS',
      'inventories.related_forms': 'Related Forms',
      'inventories.add_HCPCS': 'Search an HCPCS to add',
      'inventories.add_forms': 'Search a Form to add',
      'inventories.form': 'Form',
      'inventories.add_forms.error': 'Not a valid form name',
      'inventories.related_modifiers': 'Modifiers',
      'inventory.popup.medicare_medicaid':
        'What type of insurance is applied to this form?',
      'inventories.product_options': 'Product Options',
      'inventories.update': 'Sync with CIN7',

      'insurances.insurance-form-new.title': 'Create new insurance',
      'insurances.insurance-form-list.title': 'List insurances',
      'insurances.title': 'Insurance',
      'insurances.name': 'Insurance Name',
      'insurances.phone_number': 'Phone Number',
      'insurances.fax_number': 'Fax Number',
      'insurances.submit': 'Submit Insurance',
      'insurances.save': 'Save Insurance',
      'insurances.delete': 'Delete Insurance',
      'insurances.edit': 'Edit Insurance',
      'insurances.view': 'View',
      'insurances.submited': 'Insurance Submited',
      'insurances.insurance_info': 'Insurance Info',
      'insurances.type': 'Insurance Type',
      'insurances.type0': 'All',
      'insurances.type1': 'Medicare',
      'insurances.type2': 'Medicaid',

      'icd10s.icd10-form-new.title': 'Create new ICD-10',
      'icd10s.icd10-form-list.title': 'List ICD-10',
      'icd10s.title': 'ICD-10',
      'icd10s.description': 'Description',
      'icd10s.code': 'Code',
      'icd10s.submit': 'Submit Icd 10',
      'icd10s.save': 'Save ICD-10',
      'icd10s.delete': 'Delete ICD-10',
      'icd10s.edit': 'Edit ICD-10',
      'icd10s.edit.title': 'Edit ICD-10 Code',
      'icd10s.view': 'View',
      'icd10s.submited': 'ICD-10 Submited',

      'providers.provider-form-new.title': 'Create new Provider',
      'providers.provider-form-list.title': 'Providers',
      'providers.title': 'Provider',
      'providers.provider_info': 'Provider Info',
      'providers.name.first_name': 'First Name',
      'providers.name.last_name': 'Last Name',
      'providers.NPI': 'NPI',
      'providers.preffix': 'Prefix',
      'providers.suffix': 'Suffix',
      'providers.select_file': 'Select the signature',
      'providers.selected_file': 'Signature:',
      'providers.submit': 'Submit Provider',
      'providers.save': 'Save Provider',
      'providers.delete': 'Delete Provider',
      'providers.edit': 'Edit Provider',
      'providers.view': 'View',
      'providers.submited': 'Provider Submited',

      'orders.order-form.abn.title': 'ABN form',
      'orders.order-form.abn.label.reason': 'Reason Medicare May Not Pay:',
      'orders.order-form.abn.label.reason.op1':
        'Medicare coverage does not include this item.',
      'orders.order-form.abn.label.reason.op2':
        'Beneficiary has recieved this same or similar item recently.',
      'orders.order-form.abn.label.cost': 'Estimated cost',
      'orders.order-form.abn.label.options': 'OPTIONS: Check only one box',
      'orders.order-form.abn.label.options.op1.start':
        'OPTION 1. I want the D. ',
      'orders.order-form.abn.label.options.op1.end':
        'listed above. You may ask to be paid now, but I also want Medicare billed for an official decision on payment, which is sent to me on a Medicare Summary Notice (MSN). I understand that if Medicare doesn’t pay, I am responsible for payment, but I can appeal to Medicare by following the directions on the MSN. If Medicare does pay, you will refund any payments I made to you, less co-pays or deductibles.',
      'orders.order-form.abn.label.options.op2.start':
        'OPTION 2. I want the D. ',
      'orders.order-form.abn.label.options.op2.end':
        'listed above, but do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.',
      'orders.order-form.abn.label.options.op3.start':
        'OPTION 3. I don’t want the D. ',
      'orders.order-form.abn.label.options.op3.end':
        'listed above. I understand with this choice I am not responsible for payment, and I cannot appeal to see if Medicare would pay.',
      'orders.order-form.abn.populate': 'Populate ABN form',

      'orders.popup.cancel.title': 'Leave without saving?',
      'orders.popup.cancel.text': 'If you exit your information will be lost',
      'orders.popup.remove.line.title': 'Remove item?',
      'orders.popup.remove.line.text':
        'Are you sure you want to remove this item?',

      'orders.label.insurance.primary': 'Primary Insurance',
      'orders.label.insurance.secondary': 'Secondary Insurance',
      'orders.label.insurance.primary.id': 'Primary Insurance ID #',
      'orders.label.insurance.secondary.id': 'Secondary Insurance ID #',

      'auth.form.label.forgot_password': 'I forgot my password',
      'auth.form.label.i_dont_have_an_account': "I don't have an account",
      'auth.form.label.create_one': 'create one',
      'auth.label.go_back_to_login': 'Go back to login',

      'login.form.title': 'Welcome back!',
      'login.form.placeholder.email': 'email',
      'login.form.placeholder.password': 'Password',
      'login.form.placeholder.token': 'Verification Token',
      'login.form.error.generic': 'Error login',

      'forms.form-form-new.title': 'Upload a new form',
      'forms.form-rule-new.title': 'Create a new rule',
      'forms.form-form-list.title': 'List forms',
      'forms.title': 'Forms',
      'forms.name': 'name',
      'forms.select_file': 'Select the template file',
      'forms.selected_file': 'Template:',
      'forms.questions': 'Questions',
      'forms.questions.required': 'Is this question required?',
      'forms.questions.settings': 'Settings',
      'forms.questions.type': 'Answer type:',
      'forms.questions.multiselect': 'More than one option selection',
      'forms.questions.options': 'Options',
      'forms.submit': 'Submit form',
      'forms.save': 'Save form',
      'forms.submited': 'Form successfully uploaded',
      'forms.delete': 'Delete form',
      'forms.edit': 'Edit form',
      'forms.download': 'Download Template',
      'forms.view': 'View',

      'rules.title': 'Rules',
      'rules.delete': 'Delete rule',
      'rules.name': 'name',

      'forgot-password.form.title': 'Reset your Password',
      'forgot-password.form.placeholder.email': 'Enter your email',
      'forgot-password.message.success':
        "We've sent you an email with instructions to reset your password.",

      'reset-password.form.title': 'Enter new Password',
      'reset-password.form.placeholder.password': 'New Password',
      'reset-password.form.placeholder.confirmPassword': 'Confirm Password',
      'reset-password.message.success':
        'Your new password has been set. You may now sign in.',
      'reset-password.message.invalid_password_reset_token':
        'Invalid password reset token.',
      'reset-password.message.passwords_must_match': 'Passwords must match.',

      'menu.orders': 'Orders',
      'menu.locationGroups': 'Location Groups',
      'menu.providers': 'Providers',
      'menu.locations': 'Locations',
      'menu.locations_insurances': 'Margins',
      'menu.inventory': 'Inventory',
      'menu.patients': 'Patients',
      'menu.insurances': 'Insurances',
      'menu.icd10': 'ICD-10 Codes',
      'menu.forms': 'Forms',
      'menu.fitters': 'Fitters',
      'menu.dashboard': 'Dashboard',
      'menu.reporting': 'Reporting',
      'menu.external.access': 'External Access',
      'menu.bulk.operations': 'Invoices',
      'menu.eligible': 'Eligible.com',
      'menu.pricer': 'Pricer Tool',
      'menu.modifiers': 'Modifiers',
      'menu.rules': 'Rules',
      'modifier.cancel.form': 'Are you sure you want to cancel?',
      'modifier.delete.confirm': 'Are you sure you want do delete this item?',
      'menu.location_providers': 'Location-Providers',
      'menu.location_fitters': 'Location-Fitters',
      'menu.location_insurance_inventory': 'Location-Insurance-Inventory',

      'locations_insurances.search_location': 'Search a Location',
      'locations_insurances.save_info': 'Save',
      'locations_insurances.search_insurance': 'Add an Insurance',
      'locations_insurances.filter_insurance': 'Filter by Insurance',
      'locations_insurances.location_autocomplere_error':
        'Not a valid Location',
      'locations_insurances.insurance_autocomplere_error':
        'Not a valid Insurance',
      'locations_insurances.search_inventories': 'Add an Inventory',
      'locations_insurances.inventories_autocomplere_error':
        'Not a valid Inventory',
      'locations_insurances.add': 'Add',

      'fitter.search_fitter': 'Search a Fitter',
      'fitter.save_info': 'Save Info',
      'fitter.search_location': 'Add a Location',
      'fitter.fitter_autocomplere_error': 'Not a valid Fitter',
      'fitter.location_autocomplere_error': 'Not a valid Location',
      'fitter.inventories_autocomplere_error': 'Not a valid Inventory',
      'fitter.add': 'Add',
      'fitter.delete': 'Delete Fitter',
      'fitter.edit': 'Edit Fitter',
      'fitter.cancel': 'Cancel',
      'fitter.back': 'Back',
      'fitter.related_locations': 'Related Locations',

      'modifier.create': 'Create new Modifier',
      'modifier.delete': 'Delete Modifier',
      'modifier.edit': 'Edit Modifier',
      'modifier.title': 'Modifiers',
      'modifier.form.name': 'Name',
      'modifier.hcpcs_codes': 'HCPCS Codes',
      'modifier.question.hcpcs_codes': 'HCPCS Codes Question',
      'modifier.hcpcs.add': 'Add Particular HCPCS Code',
      'modifier.question.hcpcs.add': 'Add Particular HCPCS Code To Question',
      'modifier.insurances': 'Insurance Providers',
      'modifier.question.insurances': 'Insurance Providers Question',
      'modifier.insurances.add': 'Add Particular Insurance Provider',
      'modifier.question.insurances.add':
        'Add Particular Insurance Provider To Question',
      'modifier.questions.title': 'Questions',
      'modifier.questions.text': 'Question Text',
      'modifier.question.response.options': 'Response Options',
      'modifier.question.option.text': 'Option Text',
      'modifier.question.option.attach.form': 'Attached Forms',
      'modifier.question.option.attach.modifier': 'Attached Modifiers',
      'modifier.placeholder.name': 'Modifier Name',
      'modifier.placeholder.hcpcs': 'Add HCPCS Code',
      'modifier.placeholder.insurance': 'Add Insurance Provider',
      'modifier.placeholder.question.text': 'Enter Question',
      'modifier.placeholder.question.response.text': 'Enter Response Option',
      'modifier.placeholder.question.response.form': 'Add Form',
      'modifier.placeholder.question.response.modifier': 'Add Modifier',
      'modifier.placeholder.new.response.option': 'Enter New Response Option',
      'modifier.label.response.option': 'Response Option',

      'dashboard.title': 'Dashboard',
      'dashboard.delete_date': 'Delete Date',
      'dashboard.delete_reason': 'Delete Reason',
      'dashboard.last_modified_by': 'Last Modified By',
      'dashboard.cin7_ref': 'Cin7 Ref.',
      'dashboard.dos_label': 'Date of Service:',
      'dashboard.ordered_date': 'Order Date:',
      'dashboard.status': 'Status',
      'dashboard.creator.name': 'Order Creator',
      'dashboard.fitter.name': 'Fitter Name',
      'dashboard.billingProvider.name': 'Supervising Provider',
      'dashboard.order': 'Order',
      'dashboard.check': 'Check',
      'dashboard.bulk_update': 'Bulk Update',
      'dashboard.update_orders': 'Update Orders',
      'dashboard.billed_date': 'Billed Date',
      'dashboard.claim_id': 'Claim ID',
      'dashboard.hcpcs': 'HCPCS',
      'dashboard.patient_name': 'Patient Name',
      'dashboard.size': 'Size',
      'dashboard.vendor': 'Vendor',
      'dashboard.modifiers': 'Modifiers',
      'dashboard.warnings': 'Warnings',
      'dashboard.icds': 'ICD-10 Codes',
      'dashboard.item_name': 'Item Name',
      'dashboard.date': 'Date of Service',
      'dashboard.system_date': 'System Date',
      'dashboard.patient_mrn': 'Patient MRN',
      'dashboard.sales_tax': 'Sales Tax',
      'dashboard.provider': 'Provider Name',
      'dashboard.location': 'Location',
      'dashboard.insurance': 'Insurance',
      'dashboard.primary_insurance': 'Primary Insurance',
      'dashboard.primary_insurance_id': 'Primary Insurance ID',
      'dashboard.secondary_insurance': 'Secondary Insurance',
      'dashboard.secondary_insurance_id': 'Secondary Insurance ID',
      'dashboard.charge_out_cost': 'Charge Out',
      'dashboard.product_option_code': 'Product Code',
      'dashboard.allowable_cost': 'Allowable',
      'dashboard.quantity': 'Quantity',
      'dashboard.self_pay_cost': 'Self Pay Cost',
      'dashboard.patient_payment': 'Patient Payment',
      'dashboard.export': 'Export',
      'dashboard.mrn': 'Patient MRN',
      'dashboard.no_results':
        'There is no order to show in the selected date range',
      'dashboard.orderId': 'orderId',
      'dashboard.itemId': 'itemId',
      'dashboard.invoiced': 'Invoiced',
      'dashboard.invoiceNr': 'Invoice Number',
      'dashboard.lastModified': 'lastModified',
    };

    return labels[labelKey] || `missing_${labelKey}`;
  }
}
