import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraysort',
})
export class ArraysortPipe implements PipeTransform {
  transform(value: any[], args?: any): any {
    if (!args || !args.field || !args.order) {
      return value;
    }

    const field = args.field;
    const order = args.order;
    const self = this;

    return value.sort(function (a, b) {
      const aField = self.getProperty(field, { ...a });
      const bField = self.getProperty(field, { ...b });
      if (aField < bField) {
        console.log(aField, '<', bField);
        return -1 * order;
      }
      if (aField > bField) {
        console.log(aField, '>', bField);
        return order;
      }
      return 0;
    });
  }

  getProperty(path, object, depth = 0) {
    const parts = path.split('.');

    if (depth == parts.length - 1) {
      if (object[parts[depth]] != undefined) {
        const value = object[parts[depth]].toLowerCase();
        return value;
      } else {
        return 0;
      }
    } else {
      return this.getProperty(path, object[parts[depth]], depth + 1);
    }
  }
}
