<div class="loading-screen-wrapper" *ngIf="loading$ | async">
  <div class="loading-screen-icon">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
