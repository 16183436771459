import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class PatientService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createPatient(patient: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.patients}`,
      patient,
      this.httpOptions
    );
  }

  editPatient(patientId: string, patient: any): Observable<any> {
    delete patient.acl;
    return this.http.put<any>(
      `${this.API.patients}/${patientId}`,
      patient,
      this.httpOptions
    );
  }

  getPatient(patientId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.patients}/${patientId}`,
      this.httpOptions
    );
  }

  deletePatient(patientId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.patients}/${patientId}`,
      this.httpOptions
    );
  }

  getPatients(): Observable<any> {
    return this.http.get<any>(
      `${this.API.patients}?limit=500`,
      this.httpOptions
    );
  }

  searchPatients(input: string): Observable<any> {
    const filter = `where={"c_mrn":{"$regex":"/${input}/i"}}`,
      limit = 'limit=80',
      sort = 'sort={"c_mrn": 1}',
      query = `${filter}&${limit}&${sort}`;

    return this.http.get<any>(
      `${this.API.patients}?${query}`,
      this.httpOptions
    );
  }

  searchExistingPatient(patient: any): Observable<any> {
    return this.http.get<any>(
      `${this.API.patients}?where={"$and":[{"c_mrn":"` +
        patient.c_mrn +
        `"}, {"c_name.c_first_name": {"$regex": "/${patient.c_name.c_first_name}/i"}}, {"c_name.c_last_name":{"$regex": "/${patient.c_name.c_last_name}/i"}}, {"c_dob":"` +
        patient.c_dob +
        `"}]}`,
      this.httpOptions
    );
  }

  searchExistingOrderPatient(patientId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orders}?limit=1&where={"c_patient": "` + patientId + `"}`,
      this.httpOptions
    );
  }

  getAllPatients(): Observable<any> {
    const localSubject = new Subject();
    let allPatients = [];
    let page = 0;
    const self = this;
    const chunck = 1000;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.patients}?limit=${chunck}&skip=` + page * chunck,
          self.httpOptions
        )
        .subscribe((response) => {
          allPatients = allPatients.concat(response.data);
          if (response.hasMore) {
            // console.log("Page: ", page, " has more!");
            page++;
            loadAll();
          } else {
            localSubject.next(allPatients);
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getPatientsByFilter(filter: string): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    let page = 0;
    const self = this;
    let allPatients = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.patients}?${filter}&limit=${chunck}&skip=${page * 1000}`,
          self.httpOptions
        )
        .subscribe((patients) => {
          allPatients = allPatients.concat(patients.data);
          if (patients.hasMore) {
            console.log('has more!');
            page++;
            loadAll();
          } else {
            localSubject.next(allPatients);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }
}
