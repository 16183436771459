const API_PATH = 'https://api.medable.com/selectorthodme/v2';
const AWS_BUCKET_PATH = '';

export const ENDPOINTS = {
  AWS_BUCKET_PATH: `${AWS_BUCKET_PATH}`,

  auth: {
    login: `${API_PATH}/accounts/login`,
    logout: `${API_PATH}/accounts/me/logout`,
    requestPasswordReset: `${API_PATH}/accounts/request-password-reset`,
    resetPassword: `${API_PATH}/accounts/reset-password`,
  },

  forms: {
    patients: `${API_PATH}/c_patients`,
    accounts: `${API_PATH}/accounts`,
    all_accounts: `${API_PATH}/views/c_accountnames`,
    account_locations: `${API_PATH}/c_fitter_locations`,
    insuranceLocations: `${API_PATH}/c_insurance_locations`,
    inventoryInsuranceLoc: `${API_PATH}/c_hcpcs_insurance_locs`,
    physicians: `${API_PATH}/c_physcians`,
    orderForms: `${API_PATH}/c_order_forms`,
    orderSignatures: `${API_PATH}/c_order_signature`,
    orderModifierForms: `${API_PATH}/c_order_modifier_form`,
    orders: `${API_PATH}/c_medable_orders`,
    locations: `${API_PATH}/c_locations_`,
    locationGroups: `${API_PATH}/c_location_groups`,
    locationsCin7: `${API_PATH}/routes/cin7/v1/contacts?fields=id,type,company,firstName,postalAddress1,postalCity,postalPostCode,postalState,postalCountry&where=(Type = 'Branch')&rows={250}`,
    allFitters: `${API_PATH}/routes/ortho_all_fitters`,
    allAccounts: `${API_PATH}/routes/ortho_all_accounts`,
    supplierCin7: `${API_PATH}/routes/cin7/v1/contacts`,
    branchesCin7: `${API_PATH}/routes/cin7/v1/branches`,
    supplier: `${API_PATH}/c_contact`,
    inventories: `${API_PATH}/c_product`,
    hcpcs_codes: `${API_PATH}/c_hcpcs_codes`,
    inventoriesCin7: `${API_PATH}/routes/cin7/v1/products`,
    insurances: `${API_PATH}/c_insurances`,
    icd10s: `${API_PATH}/c_icd_10s`,
    providers: `${API_PATH}/c_physcians`,
    providerLocations: `${API_PATH}/c_provider_location`,
    forms: `${API_PATH}/c_forms`,
    inventoryForms: `${API_PATH}/c_inventory_forms`,
    orderLineItems: `${API_PATH}/c_order_item`,
    questions: `${API_PATH}/c_questions`,
    modifiers: `${API_PATH}/c_modifiers`,
    externalAccessCode: `${API_PATH}/c_external_access_code`,
    modifierHcpcs: `${API_PATH}/c_modifier_hcpcs_codes`,
    modifierInsurances: `${API_PATH}/c_modifier_insurances`,
    modifierQuestions: `${API_PATH}/c_modifier_questions`,
    modifierQuestionHcpcs: `${API_PATH}/c_modifier_question_hcpcs_codes`,
    modifierQuestionInsurances: `${API_PATH}/c_modifier_question_insurances`,
    modifierQuestionResponseOptions: `${API_PATH}/c_modifier_question_response_options`,
    synchronizationDate: `${API_PATH}/c_synchronization`,
    inventoryLocation: `${API_PATH}/c_product_location`,
    salesOrderCin7: `${API_PATH}/routes/cin7/v1/SalesOrders`,
    creditNote: `${API_PATH}/routes/cin7/v1/CreditNotes`,
    payment: `${API_PATH}/routes/cin7/v1/Payments`,
    stock: `${API_PATH}/c_product_location`,
    stockCin7: `${API_PATH}/routes/cin7/v1/stock`,
    inventoriesLocationRoute: `${API_PATH}/routes/locationInventories`,
    deleteInventoriesLocationRoute: `${API_PATH}/routes/deleteAllStocks`,
  },
};
