import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveStateService } from '../../../services/active-state/active-state.service';

@Component({
  selector: 'app-location-groups-main',
  templateUrl: './location-groups-main.component.html',
  styleUrls: ['./location-groups-main.component.css'],
})
export class LocationGroupsMainComponent implements OnInit {
  stateName = '';
  modalInstance: any;
  locationGroupId: string;
  locationGroup: any;

  private sub;

  constructor(private state: ActiveStateService, private router: Router) {}

  ngOnInit() {
    this.stateName = this.state.name;
  }

  gotoCreateLocationGroups() {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
    this.router.navigate(['forms/locationGroups/new']);
  }
}
