import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable } from 'rxjs';

import { PopupComponent } from '../popup/popup.component';

import { CONSTANTS } from '../../../config/constants';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormService } from '../../services/form/form.service';
import { FormsService } from '../../services/forms/forms.service';
import { LocationService } from '../../services/location/location.service';
import { SharedService } from '../../services/shared/shared.service';

import { debounceTime, map } from 'rxjs/operators';
import { ModifierService } from '../../services/modifier/modifier.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {
  hasRole: boolean = false;
  showCreateBUtton: boolean = false;
  viewForm: boolean = false;
  notEditable: boolean = false;
  createOrEdit: boolean = false;
  indexPage: boolean = false;
  editingQuestions: boolean;

  hcpcs_codes: any[];
  allMedableLocations: any[];
  locationsAdded: any[] = [];
  results: any[];
  questions: any[] = [];
  allInsurances: any[] = [];
  formId: any;
  form: any = {};

  hcpcs_codes_added: any[] = [];
  insurances_added: any[] = [];

  languageOptions: any[] = [
    {
      label: 'English',
      value: 'eng',
    },
    {
      label: 'Spanish',
      value: 'esp',
    },
  ];

  chosenLanguage: any = this.languageOptions[0];

  constants = CONSTANTS;

  flow = {
    files: [],
  };
  questionNew = {
    text: '',
    error: false,
  };

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private formService: FormService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private formsService: FormsService,
    private modifierService: ModifierService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.formId = params['id'] ? params['id'] : '0';
      this.hasRole = this.authService.getAuthorizedUserRole();
      // If index, we show the create button.
      this.indexPage = this.state.is('forms.formsIndex');

      // If edit or view, load form data
      if (this.state.is('forms.formsView')) {
        this.getForm();
        this.getQuestions();

        if (this.state.is('forms.formsView')) {
          this.viewForm = true;
          this.notEditable = true;
        }
      } else if (
        this.state.is('forms.formsNew') ||
        this.state.is('forms.formsEdit')
      ) {
        // If create or edit, get all hcpcs_codes
        this.getAllHcpcsCodesMedable();
        this.getAllMedableLocations();
        this.getAllInsurancesMedable();
        this.createOrEdit = true;
        if (this.state.is('forms.formsEdit')) {
          this.getForm();
          this.getQuestions();
        }
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getForm() {
    this.formService.getForm(this.formId).subscribe((form) => {
      this.form = {
        c_name: form.c_name,
        c_template_name: form.c_template_name,
        c_hcpcs_codes:
          form.c_hcpcs_codes != undefined ? form.c_hcpcs_codes : [],
        c_insurances: form.c_insurances != undefined ? form.c_insurances : [],
        c_locations: form.c_locations != undefined ? form.c_locations : [],
      };

      if (form.c_base_name) {
        this.form['c_base_name'] = form.c_base_name;
      }

      if (form.c_language) {
        this.form['c_language'] = form.c_language;

        this.languageOptions.map((lo, idx) => {
          if (lo.label.toLowerCase() == form.c_language.toLowerCase()) {
            this.chosenLanguage = lo;
          }
        });
      }

      console.log('This.form: ', this.form);
      if (form.c_all_hcpcs_codes) {
        this.form.c_all_hcpcs_codes = form.c_all_hcpcs_codes;
      }

      if (form.c_contains_signature) {
        this.form.c_contains_signature = form.c_contains_signature;
      }

      if (form.c_hcpcs_codes != undefined) {
        this.hcpcs_codes_added = form.c_hcpcs_codes.map(
          (code) => code.c_hcpcs_code
        );
      }

      if (form.c_insurances != undefined) {
        this.insurances_added = form.c_insurances.map((ins) => ins.c_insurance);
      }

      if (form.c_locations != undefined) {
        this.locationsAdded = form.c_locations.map((loc) => loc.c_location);
      }
    });
  }

  getQuestions() {
    this.formsService
      .get('questions', '?where={"c_form":"' + this.formId + '"}')
      .subscribe((questions) => {
        this.questions = questions.data;
      });
  }

  allCodesChange() {
    console.log('This Form: ', this.form);
  }

  toggleContainsSignature() {
    // if (!this.form.c_contains_signature) {
    //   this.form.c_contains_signature = true;
    // } else {
    //   this.form.c_contains_signature = false;
    // }
    console.log(this.form);
  }

  showForm(formId) {
    this.formService.getFormContent(this.formId).subscribe((form) => {
      this.formService.getFormJSON(form.url).subscribe((docDefinition) => {
        docDefinition = JSON.stringify(docDefinition);
        console.log('initial docdef: ', docDefinition);
        //docDefinition = docDefinition.replace("#PATIE", '');
        if (this.form.c_name.indexOf('ABN') !== -1) {
          docDefinition = docDefinition.replace(
            '##OPTION_1##',
            this.constants.IMG_NOT_CHECKED
          );
          docDefinition = docDefinition.replace(
            '##OPTION_2##',
            this.constants.IMG_NOT_CHECKED
          );
          docDefinition = docDefinition.replace(
            '##OPTION_3##',
            this.constants.IMG_NOT_CHECKED
          );
        } else if (this.form.c_name.indexOf('MAE') !== -1) {
          docDefinition = docDefinition.replace('##STEP_1_1##', 'noBorders');
          docDefinition = docDefinition.replace('##STEP_1_2##', 'noBorders');
          docDefinition = docDefinition.replace('##STEP_1_3##', 'noBorders');
          docDefinition = docDefinition.replace(
            '##STEP_2_1##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace(
            '##STEP_2_2##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace(
            '##STEP_3##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace(
            '##STEP_4##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace(
            '##STEP_5##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace(
            '##STEP_6##',
            this.constants.MAE_NONE
          );
          docDefinition = docDefinition.replace('frooming', 'grooming');
        } else if (this.form.c_name.indexOf('Clinic') !== -1) {
          for (let i = 0; i < 15; i++) {
            docDefinition = docDefinition.replace(
              `##GOAL_OPTION_${i}##`,
              'not_checked'
            )
          }
          for (let i = 0; i < 15; i++) {
            docDefinition = docDefinition.replace(
              `##MEDICAL_JUSTIFICATION_OPTION_${i}##`,
              'not_checked'
            )
          }
          for (let i = 0; i < 15; i++) {
            docDefinition = docDefinition.replace(
              `##MODIFICATION_OPTION_${i}##`,
              'not_checked'
            )
          }
          for (let i = 0; i < 15; i++) {
            docDefinition = docDefinition.replace(
              `##DISPENSED_OPTION_${i}##`,
              'not_checked'
            )
          }
          docDefinition = docDefinition.replace(
            `##LOWER_NO_OPTION##`,
            'not_checked'
          )
          docDefinition = docDefinition.replace(
            `##LOWER_YES_OPTION##`,
            'not_checked'
          )
        } else {
          let questions = '';

          for (let i = 0; i < this.questions.length; i++) {
            let question = {
              stack: [
                {
                  text: this.questions[i].c_text,
                  styles: 'margins',
                },
                {
                  ul: [],
                  style: 'margins',
                },
              ],
              style: 'margins',
            };

            if (this.questions[i].c_response_options.length > 0) {
              for (
                let j = 0;
                j < this.questions[i].c_response_options.length;
                j++
              ) {
                question.stack[1]['ul'].push({
                  text: this.questions[i].c_response_options[j],
                  styles: 'margins',
                });
              }
              if (this.form.c_name.indexOf('DVT') !== -1) {
                question.stack[1]['ul'].push({
                  text: 'Total points: [...]',
                  styles: 'margins',
                  bold: true,
                });
              }
            }

            questions += questions !== '' ? ',' : '';
            questions += JSON.stringify(question);
          }

          if (this.form.c_name.indexOf('DVT') !== -1) {
            const score = {
              text: 'Total score: [...]',
              style: 'margins',
              bold: true,
            };
            questions += questions !== '' ? ',' : '';
            questions += JSON.stringify(score);
          }

          if (this.questions.length) {
            docDefinition = docDefinition.replace('"##QUESTIONS##"', questions);
          } else {
            docDefinition = docDefinition.replace(
              '"##QUESTIONS##",',
              questions
            );
          }
        }
        console.log('Docdef: ', docDefinition);
        this.formService.populateForm(docDefinition);
      });
    });
  }

  fileEvent(files: FileList) {
    let file = files.item(0);
    this.flow.files.push(file);
    console.log('File: ', file);
  }

  addQuestion() {
    const newQuestion = {
      c_text: this.questionNew.text,
      c_form: this.formId,
      c_multi_select: false,
      c_required: false,
      c_response_type: 'text',
      c_response_options: [],
      edit: true,
      optionNew: '',
    };

    this.questions.push(newQuestion);
    this.questionNew.text = '';

    console.log('this.questions: ', this.questions);
  }

  addOption(index) {
    this.questions[index].c_response_options.push(
      this.questions[index].optionNew
    );
    this.questions[index].optionNew = '';
    if (this.questions[index].error) {
      delete this.questions[index].error;
    }
  }

  addLocation(location) {
    this.locationsAdded.push(location);
  }

  removeLocation(index) {
    this.locationsAdded.splice(index, 1);
  }

  removeQuestion(index) {
    this.showPopupDelete().then((response) => {
      if (response.selected == 'LEFT') {
        this.doRemoveQuestion(index);
      }
    });
  }

  doRemoveQuestion(index) {
    if (this.questions[index]._id) {
      this.questions[index].delete = true;
    } else {
      this.questions.splice(index, 1);
    }

    const editCnt = this.questions.filter((q) => q.edit && !q.delete).length;
    if (!editCnt) {
      this.editingQuestions = false;
    }
    console.log('this.questions: ', this.questions);
  }

  removeOption(indexQ, indexOpt) {
    this.questions[indexQ].c_response_options.splice(indexOpt, 1);
  }

  finishEditQuestion(index) {
    if (
      this.questions[index].c_response_type === 'check' &&
      this.questions[index].c_response_options.length == 0
    ) {
      this.questions[index].error = true;
    } else {
      delete this.questions[index].error;
      this.questions[index].edit = false;
    }

    const editCnt = this.questions.filter((q) => q.edit && !q.delete).length;
    if (!editCnt) {
      this.editingQuestions = false;
    }
  }

  editQuestion(index) {
    this.questions[index].edit = true;
  }

  getAllHcpcsCodesMedable() {
    this.formService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
      console.log('AllHcpcsCodesL ', allCodes);
      let testCode = allCodes.filter((ac) => ac.c_hcpcs_code === 'L3925');
      console.log('testCode: ', testCode);
      this.hcpcs_codes = allCodes;
    });
  }

  getAllMedableLocations() {
    this.locationService.getAllMedableLocations().subscribe((allLocations) => {
      console.log('All Medable Locations Loaded... ', allLocations);
      this.allMedableLocations = allLocations;
    });
  }

  addHcpcsCode(code) {
    // this.form.c_hcpcs_code = {_id : code._id};
    this.hcpcs_codes_added.push(code);
    console.log('codes: ', this.hcpcs_codes_added);
  }

  addInsurance(insurance) {
    this.insurances_added.push(insurance);
  }

  removeHcpcsCode(index) {
    this.hcpcs_codes_added.splice(index, 1);
  }

  removeInsurance(index) {
    this.insurances_added.splice(index, 1);
  }

  getAllInsurancesMedable() {
    this.modifierService
      .getAllInsurancesMedable()
      .subscribe((allInsurances) => {
        console.log('All Insurances: ', allInsurances);
        this.allInsurances = allInsurances;
      });
  }

  saveQuestions() {
    const requests = [];
    // If no questions
    if (this.questions.length == 0) {
      this.gotoViewForm(this.formId);
      return;
    }

    // Save questions
    this.questions.map((question) => {
      // Handle deleted questions
      if (question.delete) {
        const deleteReq = this.formService.deleteQuestion(question._id);
        requests.push(deleteReq);
      } else {
        // New/Edited
        const questionCopy = this.formsService.removeExtraInfo({ ...question });
        questionCopy.c_form = this.formId;
        delete questionCopy.edit;
        delete questionCopy.optionNew;
        // Handle editted questions
        if (questionCopy._id) {
          const questionId = questionCopy._id;
          delete questionCopy._id;
          const editRequest = this.formService.editQuestion(
            questionCopy,
            questionId
          );
          requests.push(editRequest);
        } else {
          // Handle new questions
          const createRequest = this.formService.createQuestion(questionCopy);
          requests.push(createRequest);
        }
      }
    });

    forkJoin(requests).subscribe((responses) => {
      console.log('All questions processed!', responses);
      this.gotoViewForm(this.formId);
    });
  }

  onSubmit() {
    this.editingQuestions = false;
    this.questions.forEach((element, index) => {
      if (element.edit && !element.delete) {
        this.editingQuestions = true;
        return;
      }
    });

    if (this.editingQuestions) {
      return;
    }

    this.form.c_hcpcs_codes = this.hcpcs_codes_added.map((code) => {
      return {
        c_hcpcs_code: code._id,
      };
    });

    this.form.c_insurances = this.insurances_added.map((insurance) => {
      return {
        c_insurance: insurance._id,
      };
    });

    this.form.c_locations = this.locationsAdded.map((location) => {
      return {
        c_location: location._id,
      };
    });

    this.form['c_language'] = this.chosenLanguage.label;
    if (this.flow.files.length) {
      this.form.c_template_name = this.flow.files[0].name;
      // console.log("Submitting form: ", this.form);
      // this.form.c_language = this.chosenLanguage.value;
      console.log('updatingforms: ', { ...this.form });
      if (this.state.is('forms.formsEdit')) {
        // this.form['c_language'] = this.chosenLanguage['value'];
        this.formService
          .editForm(this.form, this.formId, this.flow.files[0])
          .subscribe(() => {
            this.saveQuestions();
          });
        console.log('edit');
      } else {
        // console.log("flowFiles: ", this.flow.files[0]);
        this.formService
          .createForm(this.form, this.flow.files[0])
          .subscribe((data) => {
            this.formId = data._id;
            this.saveQuestions();
          });
      }
    } else {
      this.formService.editForm(this.form, this.formId).subscribe(() => {
        this.saveQuestions();
      });
      console.log('edit');
    }
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const codes = this.hcpcs_codes
            .filter(
              (v) =>
                v.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          console.log('codes: ', codes);
          this.results = codes;
          return codes.length ? codes : [];
        }
      })
    );
  };

  searchHcpcs() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.hcpcs_codes.filter(
              (v) =>
                v.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.hcpcs_codes_added.filter(
                  (code) =>
                    code.c_hcpcs_code
                      .toLocaleLowerCase()
                      .indexOf(v.c_hcpcs_code.toLowerCase()) > -1
                ).length == 0
            );

            return codes.length ? codes.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchInsurance() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.allInsurances.filter(
              (v) =>
                v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                -1 &&
                this.insurances_added.filter((ia) => ia._id === v._id)
                  .length === 0
            );
            console.log('Insurances filtered: ', insurances);
            return insurances.length ? insurances.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchLocation() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const locations = this.allMedableLocations.filter(
              (loc) =>
                loc.c_company.toLowerCase().indexOf(term.toLowerCase()) != -1 &&
                this.locationsAdded.filter((al) => al._id == loc._id).length ==
                0
            );

            return locations.length ? locations.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  cancel() {
    if (this.formId == 0) {
      this.router.navigate(['forms/forms/index']);
    } else {
      this.gotoViewForm(this.formId);
    }
  }

  deleteForm() {
    // Show confirmationpopup
    this.showPopupDelete().then((response) => {
      console.log('aaa');
      console.log('response: ', response);
      if (response.selected == 'LEFT') {
        console.log('Delete ', this.formId);
        this.doDeleteForm();
      }
    });
  }

  downloadTemplate(formId: string) {
    this.formService.getFormContent(formId).subscribe((form) => {
      fetch(form.url)
        .then(res => res.json())
        .then((out) => {
          var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(out));
          var downloadAnchorNode = document.createElement('a');
          downloadAnchorNode.setAttribute("href", dataStr);
          downloadAnchorNode.setAttribute("download", this.form.c_template_name);
          document.body.appendChild(downloadAnchorNode); // required for firefox
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
        })
    });
  }

  doDeleteForm() {
    let expectedDeletes = 0;
    let deletes = 0;
    this.questions.map((q) => {
      if (q._id) {
        expectedDeletes++;
      }
    });

    this.formService.deleteForm(this.formId).subscribe((df) => {
      console.log('df: ', df);
      if (this.questions.length == 0) {
        this.gotoFormsIndex();
      } else {
        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i]._id) {
            this.formService
              .deleteQuestion(this.questions[i]._id)
              .subscribe((r) => {
                deletes++;
                console.log('deleted q: ', r);
                if (deletes == expectedDeletes) {
                  this.gotoFormsIndex();
                }
              });
          }
        }
      }
    });
  }

  showPopupDelete() {
    const info = {
      subtitle: 'forms.confirm.deleteStrong',
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
      // inputConfirm:true
    };

    let modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  // codeFormatter = (x: {c_hcpcs_code: string}) => x.c_hcpcs_code;
  hcpcsCodeFormatter = (x: { c_hcpcs_code: string }) => '';
  insuranceFormatter = (x: { c_insurance_name: string }) => '';
  locationFormatter = (x: { c_company: string }) => '';

  gotoViewForm(formId) {
    this.router.navigate(['forms/forms/:id/view', { id: formId }]);
  }

  goToEditForm(formId) {
    this.router.navigate(['forms/forms/:id/edit', { id: formId }]);
  }

  gotoFormsIndex() {
    this.router.navigate(['forms/forms/index']);
  }

  goToCreateForm() {
    this.router.navigate(['forms/forms/new']);
  }
}
