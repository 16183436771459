import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class ProviderService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createProvider(provider: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.providers}`,
      provider,
      this.httpOptions
    );
  }

  editProvider(providerId: string, provider: any): Observable<any> {
    delete provider.acl;
    return this.http.put<any>(
      `${this.API.providers}/${providerId}`,
      provider,
      this.httpOptions
    );
  }

  getProvider(providerId: string): Observable<any> {
    const query = 'expand[]=c_billing_providers.c_provider';
    return this.http.get<any>(
      `${this.API.providers}/${providerId}?${query}`,
      this.httpOptions
    );
  }

  getProviders(): Observable<any> {
    return this.http.get<any>(
      `${this.API.providers}?limit=100&sort={"_id" : -1}`,
      this.httpOptions
    );
  }

  getProvidersFiltered(query: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.providers}?${query}`,
      this.httpOptions
    );
  }

  deleteProvider(providerId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.providers}/${providerId}`,
      this.httpOptions
    );
  }

  getAllProviders(page: number): Observable<any> {
    return this.http.get<any>(
      `${this.API.providers}?limit=1000&skip=${page * 1000}`,
      this.httpOptions
    );
  }

  getAllProvidersMedable() {
    const chunck = 1000,
      localSubject = new Subject(),
      self = this;

    let page = 0,
      allProviders = [];

    function loadAll() {
      const query = `limit=${chunck}&skip=${page * chunck}`,
        url = `${self.API.providers}?${query}`;

      self.http.get<any>(url, self.httpOptions).subscribe((response) => {
        allProviders = allProviders.concat(response.data);

        if (response.hasMore) {
          page++;
          loadAll();
          // console.log("Has more providers...");
        } else {
          console.log('All providers loaded');
          localSubject.next(allProviders);
        }
      });
    }

    loadAll();

    return localSubject;
  }

  getAllProvidersMedableArray() {
    const chunck = 1000,
      localSubject = new Subject(),
      self = this;

    let page = 0,
      allProviders = [];

    function loadAll() {
      const query = `limit=${chunck}&skip=${page * chunck}`,
        url = `${self.API.providers}?${query}`;

      self.http.get<any>(url, self.httpOptions).subscribe((response) => {
        allProviders = allProviders.concat(response.data);

        if (response.hasMore) {
          page++;
          loadAll();
          // console.log("Has more providers...");
        } else {
          // console.log("All providers loaded");
          localSubject.next({ array: allProviders });
        }
      });
    }

    loadAll();

    return localSubject;
  }

  sendImageFile(file: any, providerId: string): Observable<any> {
    return this.http.put<any>(
      `${this.API.providers}/${providerId}/c_signature`,
      { content: file.name },
      this.httpOptions
    );
  }

  getImageContent(providerId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.providers}/${providerId}/c_signature/content`,
      this.httpOptions
    );
  }

  searchProviders(input: string): Observable<any> {
    const inputSplit = input.split(' ');
    const search = [];
    inputSplit.map((text) => {
      search.push('{"c_name.c_first_name":{"$regex":"/' + text + '/i"}}');
      search.push('{"c_name.c_last_name":{"$regex":"/' + text + '/i"}}');
    });

    return this.http.get<any>(
      `${this.API.providers}?limit=1000&where={"$or":[${search.join(
        ','
      )}]}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  searchProviderNPI(npi: number): Observable<any> {
    return this.http.get<any>(
      `${this.API.providers}?where={"c_npi":${npi}}`,
      this.httpOptions
    );
  }
}
