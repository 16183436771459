<!-- show list form -->
<app-insurance-list
  class="ListContainer"
  [ngClass]="{ hiddenList: somethingToShow }"
></app-insurance-list>

<div class="forms-form" *ngIf="!somethingToShow">
  <!-- show create & edit form -->
  <div *ngIf="showCreateEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ 'insurances.title' | lang }}</h1>
        <div class="pull-right" *ngIf="notEditable">
          <button
            *ngIf="hasRole.admin"
            class="button delete-button"
            (click)="deleteInsurance(insuranceId)"
          >
            {{ 'insurances.delete' | lang }}
          </button>
          <button
            *ngIf="hasRole.admin"
            class="button"
            (click)="goToEditInsurance(insuranceId)"
          >
            {{ 'insurances.edit' | lang }}
          </button>
        </div>
      </div>
    </div>

    <form
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      class="step"
      novalidate
    >
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-xs-12"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <label>
              {{ 'insurances.name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="name"
              [(ngModel)]="insurance.c_insurance_name"
              type="text"
              required
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'insurances.phone_number' | lang }}</label>
            <input
              name="phone_number"
              [(ngModel)]="insurance.c_phone_number"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'insurances.fax_number' | lang }}</label>
            <input
              name="fax_number"
              [(ngModel)]="insurance.c_fax_number"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" *ngIf="!notEditable">
            <button
              class="button next-button"
              type="submit"
              [disabled]="!f.form.valid"
            >
              {{
                isEditMode
                  ? ('insurances.save' | lang)
                  : ('insurances.submit' | lang)
              }}
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="insuranceSubmited">
      <p>{{ 'insurances.submited' | lang }}</p>
    </div>
  </div>

  <!-- show view form -->
  <div *ngIf="!showCreateEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ insurance.c_insurance_name }}</h1>
        <div class="pull-right" *ngIf="notEditable">
          <button
            *ngIf="hasRole.admin"
            class="button delete-button"
            (click)="showPopupDelete()"
          >
            {{ 'insurances.delete' | lang }}
          </button>
          <button
            *ngIf="hasRole.admin"
            class="button edit-button"
            (click)="goToEditInsurance(insuranceId)"
          >
            {{ 'insurances.edit' | lang }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="first-info-container">
        <ul>
          <li>
            <label>{{ 'insurances.insurance_info' | lang }}</label>
          </li>
          <li>
            <label>{{ 'views.phone_number' | lang }}</label>
            <p>
              {{ insurance.c_phone_number ? insurance.c_phone_number : '-' }}
            </p>
          </li>
          <li>
            <label>{{ 'views.fax_number' | lang }}</label>
            <p>{{ insurance.c_fax_number ? insurance.c_fax_number : '-' }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- show index form -->
<div class="forms-form" *ngIf="somethingToShow">
  <button
    *ngIf="hasRole.admin"
    type="button"
    class="button create-button"
    (click)="goToCreateInsurance()"
  >
    {{ 'insurances.insurance-form-new.title' | lang }}
  </button>
</div>
