<app-modifier-list class="ListContainer"></app-modifier-list>

<!-- Index -->

<!-- View Modifier -->
<div class="forms-form" *ngIf="viewModifier">
  <!-- Titile -->
  <div class="col-xs-12">
    <h1>{{ 'modifier.title' | lang }}</h1>
    <div class="pull-right">
      <button
        type="button"
        *ngIf="hasRole.admin"
        class="button delete-button"
        (click)="deleteModifier()"
      >
        {{ 'modifier.delete' | lang }}
      </button>
      <button
        type="button"
        *ngIf="hasRole.admin"
        class="button create-button"
        (click)="goToEditModifier(modifierId)"
      >
        {{ 'modifier.edit' | lang }}
      </button>
    </div>
  </div>

  <!-- First Row Containing Name -->
  <div class="row">
    <div class="first-info-container">
      <ul>
        <li class="hidden"></li>
        <li>
          <label>Name:</label>
          <p>{{ modifier.c_name }}</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="row separate-top">
    <div class="col-xs-12">
      <label>Is Warning? ({{ modifier.c_warning ? 'yes' : 'no' }})</label>
    </div>
  </div>

  <!-- Modifier Hcpcs Codes List -->
  <div class="row separate-top">
    <div class="col-xs-12">
      <label>
        {{ 'modifier.hcpcs_codes' | lang }}
        {{
          modifier.c_all_hcpcs_codes != undefined &&
          modifier.c_all_hcpcs_codes == true
            ? 'All'
            : hcpcs_code_list.length === 0
            ? '(None)'
            : ''
        }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="let code of hcpcs_code_list; let i = index"
        >
          <div class="ll-list-item-text">{{ code.c_hcpcs_code }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modifier Insurances List  -->
  <div class="row separate-top">
    <div class="col-xs-12">
      <label>
        {{ 'modifier.insurances' | lang
        }}{{ insurances_list.length == 0 ? ' (All)' : '' }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="let insurance of insurances_list; let i = index"
        >
          <div class="ll-list-item-text">{{ insurance.c_insurance_name }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Auto-Populate  -->
  <div class="row separate-top">
    <div class="col-xs-12">
      <label>
        Auto Populate Insurances
        {{
          modifier.c_auto_populate_insurance == undefined ||
          modifier.c_auto_populate_insurance.length === 0
            ? '(None)'
            : ''
        }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="
            let insurance of modifier.c_auto_populate_insurance;
            let api = index
          "
        >
          <div class="ll-list-item-text">
            {{ insurance.c_insurance.c_insurance_name }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separate-top">
    <div class="col-xs-12">
      <h3>Questions</h3>
      <!-- Question List -->
      <div
        class="col-xs-12 ll-view-question-container separate-top"
        *ngFor="let question of modifier_questions; let qi = index"
      >
        <!-- Question text -->
        <div class="col-xs-12">
          <label>{{ 'modifier.questions.text' | lang }}</label>
          <p class="ll-blue-bold">{{ question.c_question_text }}</p>
        </div>
        <!-- Question HCPCS List -->
        <div class="col-xs-12 ll-small-st">
          <label>
            Question HCPCS Codes:
            {{
              question.hcpcs_codes != undefined &&
              question.hcpcs_codes.length == 0
                ? '(All)'
                : ''
            }}
          </label>
          <div class="col-xs-12 ll-list-item-container">
            <div
              class="ll-list-item-noteditable"
              *ngFor="let qHcpcs of question.hcpcs_codes; let qhi = index"
            >
              <div class="ll-list-item-text">{{ qHcpcs.c_hcpcs_code }}</div>
            </div>
          </div>
        </div>
        <!-- Insurances List -->
        <div class="col-xs-12 ll-small-st">
          <label>
            Question Insurance Providers:
            {{
              question.insurances != undefined &&
              question.insurances.length == 0
                ? '(All)'
                : ''
            }}
          </label>
          <div class="col-xs-12 ll-list-item-container">
            <div
              class="ll-list-item-noteditable"
              *ngFor="let qIns of question.insurances; let qii = index"
            >
              <div class="ll-list-item-text">{{ qIns.c_insurance_name }}</div>
            </div>
          </div>
        </div>

        <!-- Response Options -->
        <!-- Response Option Values -->
        <div class="col-xs-12 separate-top">
          <label>{{ 'modifier.question.response.options' | lang }}</label>
        </div>
        <div
          class="col-xs-12"
          *ngFor="let response of question.c_response_options"
        >
          <!-- Response Options Container -->
          <div class="col-xs-12">
            <!-- Text -->
            <div class="col-xs-12">
              <label>{{ 'modifier.question.option.text' | lang }}</label>
              <p class="ll-blue-bold">{{ response.c_option_text }}</p>
            </div>
            <!-- Attached Forms -->
            <div class="col-xs-12 ll-small-st">
              <label>
                {{ 'modifier.question.option.attach.form' | lang }}
                {{
                  response.c_attaching_forms != undefined &&
                  response.c_attaching_forms.length === 0
                    ? ' (None)'
                    : ''
                }}
              </label>
              <div class="col-xs-12 ll-list-item-container">
                <div
                  class="ll-list-item-noteditable"
                  *ngFor="let aF of response.c_attaching_forms; let afi = index"
                >
                  <div class="ll-list-item-text">{{ aF.c_name }}</div>
                </div>
              </div>
            </div>

            <!-- Attached Modifiers -->
            <div class="col-xs-12 ll-small-st">
              <label>
                {{ 'modifier.question.option.attach.modifier' | lang }}
                {{
                  response.c_attaching_modifiers != undefined &&
                  response.c_attaching_modifiers.length === 0
                    ? ' (None)'
                    : ''
                }}
              </label>
              <div class="col-xs-12 ll-list-item-container">
                <div
                  class="ll-list-item-noteditable"
                  *ngFor="
                    let aF of response.c_attaching_modifiers;
                    let afi = index
                  "
                >
                  <div class="ll-list-item-text">{{ aF.c_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 ll-hr-delimiter">
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Create/Edit Modifier -->

<div class="forms-form" *ngIf="createOrEdit">
  <!-- Title -->
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ 'modifier.title' | lang }}</h1>
    </div>
  </div>

  <!-- Create/ Edit Form -->
  <form
    class="step"
    name="form"
    (ngSubmit)="f.form.valid && onSubmit()"
    #f="ngForm"
    novalidate
  >
    <div class="container-fluid">
      <!-- Name -->
      <div class="row">
        <div class="col-xs-12">
          <label>
            {{ 'modifier.form.name' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            placeholder="{{ 'modifier.placeholder.name' | lang }}"
            name="name"
            [(ngModel)]="modifier.c_name"
            type="text"
            required
            [disabled]="notEditable"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label>Is Warning</label>
          <input
            (change)="warningChange()"
            name="is_warning"
            type="checkbox"
            class="radio-button ll-custom-checkbox"
            [(ngModel)]="modifier.c_warning"
          />
        </div>
      </div>

      <!-- HCPCS Code List -->
      <div class="row">
        <div class="col-xs-12">
          <label>{{ 'modifier.hcpcs_codes' | lang }}</label>
          <label>-All</label>
          <input
            (change)="allCodesChange()"
            name="all_codes_checl"
            type="checkbox"
            class="radio-button ll-custom-checkbox"
            [(ngModel)]="modifier.c_all_hcpcs_codes"
          />
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let code of hcpcs_code_list; let i = index"
            >
              <div class="ll-list-item-text">{{ code.c_hcpcs_code }}</div>
              <div
                class="ll-list-item-delete"
                (click)="removeHcpcsCode(i, hcpcs_code_list)"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HCPCS Code Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>{{ 'modifier.hcpcs.add' | lang }}</label>
          <!-- Autocomplete hcpcs input -->
          <input
            placeholder="{{ 'modifier.placeholder.hcpcs' | lang }}"
            name="searchHcpcsCode"
            id="autocomplete_hcpcs_code"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchHcpcsModifier()"
            [resultTemplate]="autocompleteHcpcsCodes"
            [inputFormatter]="hcpcsCodeFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteHcpcsCodes let-r="result" let-t="term">
            <li (click)="addHcpcsCode(r, hcpcs_code_list)">
              {{ r.c_hcpcs_code }}
            </li>
          </ng-template>
        </div>
      </div>

      <!-- Insurances List -->
      <div class="row">
        <div class="col-xs-12">
          <label>
            {{ 'modifier.insurances' | lang
            }}{{ insurances_list.length == 0 ? ' (All)' : '' }}
          </label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let insurance of insurances_list; let i = index"
            >
              <div class="ll-list-item-text">
                {{ insurance.c_insurance_name }}
              </div>
              <div
                class="ll-list-item-delete"
                (click)="removeInsurance(i, insurances_list)"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Insurance Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>{{ 'modifier.insurances.add' | lang }}</label>
          <!-- Autocomplete insurance input -->
          <input
            placeholder="{{ 'modifier.placeholder.insurance' | lang }}"
            name="searchInsurance"
            id="autocomplete_insurance"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchInsuranceModifier()"
            [resultTemplate]="autocompleteInsurances"
            [inputFormatter]="insuranceFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteInsurances let-r="result" let-t="term">
            <li (click)="addInsurance(r, insurances_list)">
              {{ r.c_insurance_name }}
            </li>
          </ng-template>
        </div>
      </div>

      <!-- Auto-Populate Insurance -->
      <div class="row">
        <div class="col-xs-12">
          <label>
            Auto-Populate Insurances
            {{ autoPopulateInsurances.length == 0 ? '(None)' : '' }}
          </label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let insurance of autoPopulateInsurances; let api = index"
            >
              <div class="ll-list-item-text">
                {{ insurance.c_insurance_name }}
              </div>
              <div
                class="ll-list-item-delete"
                (click)="removeInsurance(api, autoPopulateInsurances)"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Insurance Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>Add Auto-Populate Insurance</label>
          <!-- Autocomplete insurance input -->
          <input
            placeholder="{{ 'modifier.placeholder.insurance' | lang }}"
            name="searchInsurance"
            id="autocomplete_insurance_auto"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchInsuranceAuto()"
            [resultTemplate]="autocompleteInsurancesAuto"
            [inputFormatter]="insuranceFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteInsurancesAuto let-r="result" let-t="term">
            <li (click)="addInsurance(r, autoPopulateInsurances)">
              {{ r.c_insurance_name }}
            </li>
          </ng-template>
        </div>
      </div>

      <!-- Questions -->

      <!-- Title -->
      <div class="row">
        <div class="col-xs-12">
          <h2>{{ 'modifier.questions.title' | lang }}</h2>
        </div>
      </div>

      <!-- Added Question List -->
      <div
        class="col-xs-12 ll-added-question-list"
        *ngIf="modifier_questions.length > 0"
      >
        <div
          class="col-xs-12 ll-added-question-item"
          *ngFor="let mq of modifier_questions; let i = index"
        >
          <!-- Question Text -->
          <div class="row">
            <div class="col-xs-9">
              <label>{{ 'modifier.questions.text' | lang }}</label>
              <input
                placeholder="{{ 'modifier.placeholder.question.text' | lang }}"
                type="text"
                [(ngModel)]="mq.c_question_text"
                name="{{ 'question_text_' + i }}"
                [disabled]="!mq.editable"
              />
            </div>
            <!-- QUestion tools (edit/delete) -->
            <div class="col-xs-3">
              <div class="ll-mq-list-tools">
                <button
                  type="button"
                  class="finish-button button"
                  (click)="finishEditQuestionAdded(i)"
                  [hidden]="!mq.editable"
                >
                  Finish editing
                </button>
                <button
                  type="button"
                  class="edit-pen"
                  (click)="editQuestionAdded(i)"
                >
                  <div></div>
                </button>
                <button
                  type="button"
                  class="delete-trash"
                  (click)="removeQuestionAdded(i)"
                >
                  <div></div>
                </button>
              </div>
            </div>
          </div>

          <!-- Added Hcpcs Codes To Listed Question -->
          <div class="col-xs-12">
            <!-- Hcpcs Code List for question -->
            <div class="row">
              <div class="col-xs-12">
                <label>
                  {{ 'modifier.question.hcpcs_codes' | lang
                  }}{{
                    mq.hcpcs_codes != undefined && mq.hcpcs_codes.length == 0
                      ? ' (All)'
                      : ''
                  }}
                </label>
                <div class="col-xs-12 ll-item-list-container">
                  <div
                    class="ll-list-item"
                    *ngFor="let code of mq.hcpcs_codes; let x = index"
                    class="{{
                      mq.editable == false
                        ? 'll-disabled-list-item'
                        : 'll-list-item'
                    }}"
                  >
                    <div class="ll-list-item-text">{{ code.c_hcpcs_code }}</div>
                    <div
                      class="ll-list-item-delete"
                      (click)="removeHcpcsCode(x, mq.hcpcs_codes, mq.editable)"
                    >
                      x
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Hcpcs code input for question -->
            <div class="row" *ngIf="mq.editable">
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>{{ 'modifier.question.hcpcs.add' | lang }}</label>
                <input
                  placeholder="{{ 'modifier.placeholder.hcpcs' | lang }}"
                  name="searchHcpcsCodeQm"
                  id="autocomplete_hcpcs_code_q"
                  type="text"
                  class="highlight"
                  [disabled]="!mq.editable"
                  [ngbTypeahead]="
                    searchHcpcsWrapper(modifier_questions[i].hcpcs_codes)
                  "
                  [resultTemplate]="autocompleteHcpcsCodesQuestion"
                  [inputFormatter]="hcpcsCodeFormatter"
                />
                <!-- Template for suggested hcpcs codes -->
                <ng-template
                  #autocompleteHcpcsCodesQuestion
                  let-r="result"
                  let-t="term"
                >
                  <li (click)="addHcpcsCode(r, mq.hcpcs_codes)">
                    {{ r.c_hcpcs_code }}
                  </li>
                </ng-template>
              </div>
            </div>
          </div>

          <!-- Added Insurances To Listed Question -->
          <div class="col-xs-12">
            <!-- Insurances List for question -->
            <div class="row">
              <div class="col-xs-12">
                <label>
                  {{ 'modifier.question.insurances' | lang
                  }}{{
                    mq.insurances != undefined && mq.insurances.length == 0
                      ? ' (All)'
                      : ''
                  }}
                </label>
                <div class="col-xs-12 ll-item-list-container">
                  <div
                    class="ll-list-item"
                    *ngFor="let ins of mq.insurances; let y = index"
                    class="{{
                      mq.editable == false
                        ? 'll-disabled-list-item'
                        : 'll-list-item'
                    }}"
                  >
                    <div class="ll-list-item-text">
                      {{ ins.c_insurance_name }}
                    </div>
                    <div
                      class="ll-list-item-delete"
                      (click)="removeInsurance(y, mq.insurances, mq.editable)"
                    >
                      x
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Insurances input for question -->
            <div class="row" *ngIf="mq.editable">
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>{{ 'modifier.question.insurances.add' | lang }}</label>
                <input
                  placeholder="{{ 'modifier.placeholder.insurance' | lang }}"
                  name="searchInsuranceQ"
                  id="autocomplete_insurance_q"
                  type="text"
                  class="highlight"
                  [disabled]="!mq.editable"
                  [ngbTypeahead]="
                    searchInsuranceWrapper(modifier_questions[i].insurances)
                  "
                  [resultTemplate]="autocompleteInsQuestion"
                  [inputFormatter]="insuranceFormatter"
                />
                <!-- Template for suggested insurances -->
                <ng-template
                  #autocompleteInsQuestion
                  let-r="result"
                  let-t="term"
                >
                  <li (click)="addHcpcsCode(r, mq.insurances)">
                    {{ r.c_insurance_name }}
                  </li>
                </ng-template>
              </div>
            </div>
          </div>

          <!-- Response Options -->
          <div class="row">
            <div class="col-xs-12">
              <label>{{ 'modifier.question.response.options' | lang }}</label>
            </div>
            <!-- Response Options List -->
            <div
              class="col-xs-12"
              *ngFor="let rep_opt of mq.c_response_options; let j = index"
            >
              <!-- Resp Option Text -->
              <div class="col-xs-11">
                <label>{{ 'modifier.question.option.text' | lang }}</label>
                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.text' | lang
                  }}"
                  type="text"
                  [(ngModel)]="rep_opt.c_option_text"
                  name="{{ 'list_question_response_text_' + i + '_' + j }}"
                  [disabled]="!mq.editable"
                />
              </div>
              <div class="col-xs-1">
                <label></label>
                <div class="ll-tools-container" [hidden]="!mq.editable">
                  <button
                    type="button"
                    class="delete-trash"
                    (click)="removeOptionAdded(i, j)"
                  >
                    <div></div>
                  </button>
                </div>
              </div>
              <!-- Added Attaching Form Autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.form' | lang }}
                  {{ rep_opt.c_attaching_forms.length == 0 ? ' (None)' : '' }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="{{
                        mq.editable == false
                          ? 'll-disabled-list-item'
                          : 'll-list-item'
                      }}"
                      *ngFor="
                        let addedForm of rep_opt.c_attaching_forms;
                        let fi = index
                      "
                    >
                      <div class="ll-list-item-text">
                        {{ addedForm.c_name }}
                      </div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttForm(fi, rep_opt, mq.editable)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  *ngIf="mq.editable"
                  placeholder="{{
                    'modifier.placeholder.question.response.form' | lang
                  }}"
                  name="{{ 'searchForm_' + i + '_' + j }}"
                  id="{{ 'autocomplete_form_' + i + '_' + j }}"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="searchFormWrapper(rep_opt.c_attaching_forms)"
                  [resultTemplate]="autocompleteFormAdded"
                  [inputFormatter]="formFormatter"
                  [disabled]="!mq.editable"
                />
                <ng-template #autocompleteFormAdded let-r="result" let-t="term">
                  <li (click)="attachForm(r, rep_opt)">{{ r.c_name }}</li>
                </ng-template>
              </div>
              <!-- Added Attaching modifier autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.modifier' | lang }}
                  {{
                    rep_opt.c_attaching_modifiers.length == 0 ? ' (None)' : ''
                  }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="{{
                        mq.editable == false
                          ? 'll-disabled-list-item'
                          : 'll-list-item'
                      }}"
                      *ngFor="
                        let addedModifier of rep_opt.c_attaching_modifiers;
                        let mi = index
                      "
                    >
                      <div class="ll-list-item-text">
                        {{ addedModifier.c_name }}
                      </div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttModifier(mi, rep_opt, mq.editable)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  *ngIf="mq.editable"
                  placeholder="{{
                    'modifier.placeholder.question.response.modifier' | lang
                  }}"
                  name="{{ 'searchModifier_' + i + '_' + j }}"
                  id="{{ 'autocomplete_modifier_' + i + '_' + j }}"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="searchModifier"
                  [resultTemplate]="autocompleteModifierAdded"
                  [inputFormatter]="modifierFormatter"
                  [disabled]="!mq.editable"
                />
                <ng-template
                  #autocompleteModifierAdded
                  let-r="result"
                  let-t="term"
                >
                  <li (click)="attachModifier(r, rep_opt)">{{ r.c_name }}</li>
                </ng-template>
              </div>
            </div>

            <!-- Add New Response Option -->
            <div class="col-xs-12" *ngIf="mq.editable">
              <div class="col-xs-12">
                <label>{{ 'modifier.question.option.text' | lang }}</label>
                <input
                  placeholder="{{
                    'modifier.placeholder.new.response.option' | lang
                  }}"
                  type="text"
                  [(ngModel)]="mq.newOption.c_option_text"
                  name="{{ 'new_question_response_text_' + i }}"
                />
              </div>
              <!-- Attaching form autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.form' | lang }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="ll-list-item"
                      *ngFor="
                        let newForm of mq.newOption.c_attaching_forms;
                        let nfi = index
                      "
                    >
                      <div class="ll-list-item-text">{{ newForm.c_name }}</div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttForm(nfi, mq.newOption)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.form' | lang
                  }}"
                  name="{{'new_searchForm_' + i"
                  id="{{ 'new_autocomplete_form_' + i"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="searchFormWrapperNew(i)"
                  [resultTemplate]="newAutocompleteForm"
                  [inputFormatter]="formFormatter"
                />
                <ng-template #newAutocompleteForm let-r="result" let-t="term">
                  <li (click)="attachForm(r, mq.newOption)">{{ r.c_name }}</li>
                </ng-template>
              </div>
              <!-- Attaching modifier autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.modifier' | lang }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="ll-list-item"
                      *ngFor="
                        let newModifier of mq.newOption.c_attaching_modifiers;
                        let nmi = index
                      "
                    >
                      <div class="ll-list-item-text">
                        {{ newModifier.c_name }}
                      </div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttModifier(nmi, mq.newOption)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.modifier' | lang
                  }}"
                  name="{{ 'new_searchModifier' + i }}"
                  id="{{ 'new_autocomplete_modifier' + i }}"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="searchModifierWrapperNew(i)"
                  [resultTemplate]="newAutocompleteModifier"
                  [inputFormatter]="modifierFormatter"
                />
                <ng-template
                  #newAutocompleteModifier
                  let-r="result"
                  let-t="term"
                >
                  <li (click)="attachModifier(r, mq.newOption)">
                    {{ r.c_name }}
                  </li>
                </ng-template>
              </div>
              <div class="col-xs-3">
                <button
                  class="button next-button"
                  id="add-q-resp-option"
                  [disabled]="
                    mq.newOption.c_option_text != '' &&
                    mq.newOption.c_option_text != undefined
                      ? false
                      : true
                  "
                  (click)="addOptionToAddedQuestion(i, mq.newOption)"
                >
                  ADD OPTION
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Question Input -->
      <div class="row">
        <div class="col-xs-12">
          <label>{{ 'modifier.questions.text' | lang }}</label>
          <input
            placeholder="{{ 'modifier.placeholder.question.text' | lang }}"
            type="text"
            [(ngModel)]="modifier_question.c_question_text"
            name="question_text"
          />
        </div>
      </div>

      <!-- Hcpcs Code List for question -->
      <div class="row">
        <div class="col-xs-12">
          <label>
            {{ 'modifier.question.hcpcs_codes' | lang
            }}{{ hcpcs_code_question_list.length == 0 ? ' (All)' : '' }}
          </label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let code of hcpcs_code_question_list; let k = index"
            >
              <div class="ll-list-item-text">{{ code.c_hcpcs_code }}</div>
              <div
                class="ll-list-item-delete"
                (click)="removeHcpcsCode(k, hcpcs_code_question_list)"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Hcpcs code input for question -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>{{ 'modifier.question.hcpcs.add' | lang }}</label>
          <input
            placeholder="{{ 'modifier.placeholder.hcpcs' | lang }}"
            name="searchHcpcsCodeQ"
            id="autocomplete_hcpcs_code_q"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchHcpcsQList()"
            [resultTemplate]="autocompleteHcpcsCodesQuestion"
            [inputFormatter]="hcpcsCodeFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template
            #autocompleteHcpcsCodesQuestion
            let-r="result"
            let-t="term"
          >
            <li (click)="addHcpcsCode(r, hcpcs_code_question_list)">
              {{ r.c_hcpcs_code }}
            </li>
          </ng-template>
        </div>
      </div>

      <!-- Insurance Providers List for question -->
      <div class="row">
        <div class="col-xs-12">
          <label>
            {{ 'modifier.question.insurances' | lang
            }}{{ insurance_question_list.length == 0 ? ' (All)' : '' }}
          </label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let insurance of insurance_question_list; let m = index"
            >
              <div class="ll-list-item-text">
                {{ insurance.c_insurance_name }}
              </div>
              <div
                class="ll-list-item-delete"
                (click)="removeInsurance(m, insurance_question_list)"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Insurance code input for question -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>{{ 'modifier.question.insurances.add' | lang }}</label>
          <input
            placeholder="{{ 'modifier.placeholder.insurance' | lang }}"
            name="searchInsuranceQ"
            id="autocomplete_insurance_q"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchInsuranceQlist()"
            [resultTemplate]="autocompleteInsurancesQuestion"
            [inputFormatter]="insuranceFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template
            #autocompleteInsurancesQuestion
            let-r="result"
            let-t="term"
          >
            <li (click)="addInsurance(r, insurance_question_list)">
              {{ r.c_insurance_name }}
            </li>
          </ng-template>
        </div>
      </div>

      <!-- Question Response options -->
      <div class="row">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>{{ 'modifier.question.response.options' | lang }}</label>
          </div>

          <!-- ***Response Options*** -->
          <div class="row" *ngIf="response_options.length > 0">
            <div
              class="col-xs-12"
              *ngFor="let r_opt of response_options; let i = index"
            >
              <div class="col-xs-11">
                <label>{{ 'modifier.question.option.text' | lang }}</label>
                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.text' | lang
                  }}"
                  type="text"
                  [(ngModel)]="r_opt.c_option_text"
                  name="{{ 'question_response_text_' + i }}"
                />
              </div>
              <div class="col-xs-1">
                <label></label>
                <div class="ll-tools-container">
                  <button
                    type="button"
                    class="delete-trash"
                    (click)="removeOptionListed(i)"
                  >
                    <div></div>
                  </button>
                </div>
              </div>
              <!-- Attaching form autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.form' | lang }}
                  {{ r_opt.c_attaching_forms.length === 0 ? ' (None)' : '' }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="ll-list-item"
                      *ngFor="
                        let addedForm of r_opt.c_attaching_forms;
                        let fi = index
                      "
                    >
                      <div class="ll-list-item-text">
                        {{ addedForm.c_name }}
                      </div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttForm(fi, r_opt)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.form' | lang
                  }}"
                  name="{{ 'searchForm_' + i }}"
                  id="{{ 'autocomplete_form_' + i }}"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="searchFormWrapper(r_opt.c_attaching_forms)"
                  [resultTemplate]="autocompleteFormQlist"
                  [inputFormatter]="formFormatter"
                />
                <ng-template #autocompleteFormQlist let-r="result" let-t="term">
                  <li (click)="attachForm(r, response_options[i])">
                    {{ r.c_name }}
                  </li>
                </ng-template>
              </div>
              <!-- Attaching modifier autocomplete -->
              <div class="col-xs-12 hcpcs_autocomplete">
                <label>
                  {{ 'modifier.question.option.attach.modifier' | lang }}
                  {{
                    r_opt.c_attaching_modifiers.length === 0 ? ' (None)' : ''
                  }}
                </label>

                <div class="col-xs-12 ll-separate-bottom">
                  <div class="col-xs-12 ll-item-list-container">
                    <div
                      class="ll-list-item"
                      *ngFor="
                        let addedModifier of r_opt.c_attaching_modifiers;
                        let mi = index
                      "
                    >
                      <div class="ll-list-item-text">
                        {{ addedModifier.c_name }}
                      </div>
                      <div
                        class="ll-list-item-delete"
                        (click)="removeAttModifier(mi, r_opt)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  placeholder="{{
                    'modifier.placeholder.question.response.modifier' | lang
                  }}"
                  name="{{ 'searchModifier_' + i }}"
                  id="{{ 'autocomplete_modifier_' + i }}"
                  type="text"
                  class="highlight"
                  [ngbTypeahead]="
                    searchModifierWrapper(r_opt.c_attaching_modifiers)
                  "
                  [resultTemplate]="autocompleteModifierList"
                  [inputFormatter]="modifierFormatter"
                />
                <ng-template
                  #autocompleteModifierList
                  let-r="result"
                  let-t="term"
                >
                  <li (click)="attachModifier(r, response_options[i])">
                    {{ r.c_name }}
                  </li>
                </ng-template>
              </div>
            </div>
          </div>

          <!-- ***Response Option*** -->
          <div class="col-xs-12">
            <label>{{ 'modifier.question.option.text' | lang }}</label>
            <input
              placeholder="{{
                'modifier.placeholder.question.response.text' | lang
              }}"
              type="text"
              [(ngModel)]="response_option.c_option_text"
              name="question_response_text"
            />
          </div>
          <!-- Attaching form autocomplete -->
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>
              {{ 'modifier.question.option.attach.form' | lang }}
              {{
                response_option.c_attaching_forms != undefined &&
                response_option.c_attaching_forms.length == 0
                  ? ' (None)'
                  : ''
              }}
            </label>
            <!-- Attached Form List -->
            <div class="col-xs-12 ll-separate-bottom">
              <div class="col-xs-12 ll-item-list-container">
                <div
                  class="ll-list-item"
                  *ngFor="
                    let form of response_option.c_attaching_forms;
                    let i = index
                  "
                >
                  <div class="ll-list-item-text">{{ form.c_name }}</div>
                  <div
                    class="ll-list-item-delete"
                    (click)="removeAttForm(i, response_option)"
                  >
                    x
                  </div>
                </div>
              </div>
            </div>

            <input
              placeholder="{{
                'modifier.placeholder.question.response.form' | lang
              }}"
              name="searchForm"
              id="autocomplete_form"
              type="text"
              class="highlight"
              [ngbTypeahead]="searchFormRopt"
              [resultTemplate]="autocompleteForm"
              [inputFormatter]="formFormatter"
            />
            <ng-template #autocompleteForm let-r="result" let-t="term">
              <li (click)="attachForm(r, response_option)">{{ r.c_name }}</li>
            </ng-template>
          </div>
          <!-- Attaching modifier autocomplete -->
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>
              {{ 'modifier.question.option.attach.modifier' | lang }}
              {{
                response_option.c_attaching_modifiers != undefined &&
                response_option.c_attaching_modifiers.length == 0
                  ? ' (None)'
                  : ''
              }}
            </label>

            <div class="col-xs-12 ll-separate-bottom">
              <div class="col-xs-12 ll-item-list-container">
                <div
                  class="ll-list-item"
                  *ngFor="
                    let modifier of response_option.c_attaching_modifiers;
                    let i = index
                  "
                >
                  <div class="ll-list-item-text">{{ modifier.c_name }}</div>
                  <div
                    class="ll-list-item-delete"
                    (click)="removeAttModifier(i, response_option)"
                  >
                    x
                  </div>
                </div>
              </div>
            </div>

            <input
              name="searchModifier"
              placeholder="{{
                'modifier.placeholder.question.response.modifier' | lang
              }}"
              id="autocomplete_modifier"
              type="text"
              class="highlight"
              [ngbTypeahead]="searchModifierRopt"
              [resultTemplate]="autocompleteModifier"
              [inputFormatter]="modifierFormatter"
            />
            <ng-template #autocompleteModifier let-r="result" let-t="term">
              <li (click)="attachModifier(r, response_option)">
                {{ r.c_name }}
              </li>
            </ng-template>
          </div>
          <div class="col-xs-3 ll-btn-to-left-container">
            <button
              class="button next-button"
              id="add-q-resp-option"
              [disabled]="
                response_option.c_option_text != '' &&
                response_option.c_option_text != undefined
                  ? false
                  : true
              "
              (click)="addOption()"
            >
              ADD OPTION
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <button
            class="button next-button"
            (click)="addQuestion()"
            [disabled]="
              modifier_question.c_question_text != '' &&
              modifier_question.c_question_text != undefined &&
              response_options.length > 0
                ? false
                : true
            "
          >
            SAVE QUESTION
          </button>
        </div>
      </div>

      <!-- Submit btn -->
      <div class="row" [hidden]="notEditable">
        <div class="col-xs-12">
          <button
            class="button next-button"
            type="submit"
            [disabled]="!f.form.valid || modifierNotUpdatable()"
          >
            {{ createMode ? 'CREATE MODIFIER' : 'UPDATE MODIFIER' }}
          </button>
          <button type="button" class="button back-button" (click)="cancel()">
            {{ 'forms.cancel' | lang }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
