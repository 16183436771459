import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class Icd10Service {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createIcd10(icd10: any): Observable<any> {
    return this.http.post<any>(`${this.API.icd10s}`, icd10, this.httpOptions);
  }

  editIcd10(icd10Id: string, icd10: any): Observable<any> {
    delete icd10.acl;
    return this.http.put<any>(
      `${this.API.icd10s}/${icd10Id}`,
      icd10,
      this.httpOptions
    );
  }

  getIcd10(icd10Id: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.icd10s}/${icd10Id}`,
      this.httpOptions
    );
  }

  getIcd10s(): Observable<any> {
    return this.http.get<any>(`${this.API.icd10s}?limit=20`, this.httpOptions);
  }

  deleteIcd10(icd10Id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.icd10s}/${icd10Id}`,
      this.httpOptions
    );
  }

  searchIcd10s(input: string): Observable<any> {
    console.log('input: ', input);
    const inputSplit = input.split(' ');
    const search = [];

    inputSplit.map((text) => {
      search.push('{"c_icd_10_code":{"$regex":"/' + text + '/i"}}');
      search.push('{"c_description":{"$regex":"/' + text + '/i"}}');
    });

    return this.http.get<any>(
      `${this.API.icd10s}?limit=1000&where={"$or":[${search.join(
        ','
      )}]}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  searchIcd10sInput(input: string): Observable<any> {
    console.log('input: ', input);
    const inputSplit = input.split(' ');
    const search = [];

    inputSplit.map((text) => {
      search.push('{"c_icd_10_code":{"$regex":"/' + text + '/i"}}');
      search.push('{"c_description":{"$regex":"/' + text + '/i"}}');
    });

    if (input == '') {
      return new Observable((observer) => {
        observer.next();
      });
    }

    return this.http.get<any>(
      `${this.API.icd10s}?limit=1000&where={"$or":[${search.join(
        ','
      )}]}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  getAllIcd10s(): Observable<any> {
    const self = this;
    const chunck = 15000;

    let page = 0;
    const localSubject = new Subject();
    let allIcd10s = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.icd10s}?limit=${chunck}&skip=` + page * chunck,
          self.httpOptions
        )
        .subscribe((response) => {
          allIcd10s = allIcd10s.concat(response.data);
          console.log('icds: ', allIcd10s);
          console.log('rsp: ', response);
          if (response.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allIcd10s);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }
}
