import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { Icd10Service } from '../../services/icd10/icd10.service';

@Component({
  selector: 'app-icd10-list',
  templateUrl: './icd10-list.component.html',
  styleUrls: ['./icd10-list.component.css'],
})
export class Icd10ListComponent implements OnInit {
  icd10s: any = [];
  stateCreate = false;
  hasRole: any;
  searchIcd10: any;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private icd10Service: Icd10Service,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.state.is('forms.icd10sCreate')) {
      this.stateCreate = true;
    }

    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getIcd10s('');
  }

  getIcd10s(input) {
    if (!input || input === '') {
      this.icd10Service.getIcd10s().subscribe(
        (icd10s) => {
          this.icd10s = this.sortCodes(icd10s.data);
          console.log('Codes: ', this.icd10s);
        },
        (error) => {
          console.error(error.reason);
        }
      );
    } else {
      this.icd10Service.searchIcd10s(input).subscribe((icd10s) => {
        const search = input.split(' ');
        // filter to only get the ones that completely match the input
        this.icd10s = icd10s.data.filter((icd10) => {
          const names = icd10.c_icd_10_code + ' ' + icd10.c_description;
          const matches = search.filter((text) => names.indexOf(text) !== -1);
          return matches.length === search.length;
        });
        // keep the first 20 to list
        this.icd10s.slice(0, 20);
      });
    }
  }

  goToViewIcd10(icd10Id) {
    this.router.navigate(['forms/icd10s/:id/view', { id: icd10Id }]);
  }

  goToCreateIcd10() {
    this.router.navigate(['forms/icd10s/new']);
  }

  sortCodes(codes) {
    const sorted = codes.sort((a, b) => {
      const aStrRaw = a.c_icd_10_code + a.c_description;
      const aStr = aStrRaw.replace(/\s+/gim, '').toLowerCase();

      const bStrRaw = b.c_icd_10_code + b.c_description;
      const bStr = bStrRaw.replace(/\s+/gim, '').toLowerCase();
      console.log('aStr: ', aStr, ' bStr: ', bStr);
      if (aStr < bStr) {
        return -1;
      }
      if (aStr > bStr) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }
}
