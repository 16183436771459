import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { PopupComponent } from '../popup/popup.component';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { InsuranceService } from '../../services/insurance/insurance.service';
import { LocationService } from '../../services/location/location.service';
import { ProviderService } from '../../services/provider/provider.service';
import { SharedService } from '../../services/shared/shared.service';
import { StockService } from '../../services/stock/stock.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
})
export class LocationComponent implements OnInit {
  location: any;
  locationId: string;

  providers: any[];
  providersList: any[];
  providersData: any[];
  snapshotProviders: any[];
  providersListIds: any[];

  stocks: any[];
  searchStock: any[];

  fitters: any[];
  fittersList: any[];
  insurancesList: any[] = [];
  snapshotFitters: any[];
  fittersListIds: any[];
  allInsurances: any[];

  fittersAutocomplete: any;
  providersAutocomplete: any;
  insurancesAutocomplete: any;

  hasRole: any;

  showEditForm = false;
  isEditMode = false;
  notEditable = false;
  somethingToShow = false;
  image: any;
  imageUrl: string;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private stockService: StockService,
    private formsService: FormsService,
    private providerService: ProviderService,
    private insuranceService: InsuranceService
  ) {
    this.goToViewLocation = this.goToViewLocation.bind(this);
    this.goToBack = this.goToBack.bind(this);
    this.updateLocations = this.updateLocations.bind(this);
    this.getProvidersList = this.getProvidersList.bind(this);
    this.getProviders = this.getProviders.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.location = {
      c_address: '',
      c_location_name: '',
      c_phone: '',
    };
    this.getFitters = this.getFitters.bind(this);
    this.getFittersList = this.getFittersList.bind(this);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.analyzeCin7Locations();

      this.locationId = params['id'] ? params['id'] : '0';

      this.hasRole = this.authService.getAuthorizedUserRole();

      // If index, we show the update button.
      this.somethingToShow = this.state.is('forms.locationsIndex');

      this.getRelatedInsurances();

      // in case of view of the locations, disable editing
      if (this.state.is('forms.locationsView')) {
        this.notEditable = true;
        this.getLocation();
        this.getProviders();
        this.getProvidersList();
        this.getLast10Stocks();
        this.getFittersList();
      }

      if (this.state.is('forms.locationsEdit')) {
        this.showEditForm = true;
        this.isEditMode = true;
        this.notEditable = true;
        this.getLocation();
        this.getProviders();
        this.getAllInsurances();
        this.getProvidersList(() => {
          this.getProviders(() => {
            this.updateProviders();
          });
        });
        this.getLast10Stocks();
        this.getFittersList(() => {
          this.getFitters(() => {
            this.updateFitters();
          });
        });
        this.getFitters();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  analyzeCin7Locations() {
    console.log('analizing Locations...');
  }

  updateLocations() {
    const observables = [];
    const self = this;

    const locPromise = this.locationService.getExpectedLocationsFromCin7();
    observables.push(locPromise);

    const contactPromise = this.locationService.getMedableContacts(0);
    observables.push(contactPromise);

    const medableLocationsPromise = this.locationService.getLocations(0);
    observables.push(medableLocationsPromise);

    const branchesPromise = this.locationService.getCin7Branches();
    observables.push(branchesPromise);

    forkJoin(observables).subscribe((responses) => {
      // console.log("Responses: ", responses);
      const cin7Contacts = this.updateObjStructure(responses[0]['locations']);
      const dateId = responses[0]['dateId'];
      const medableContacts = responses[1];
      const medableLocations = responses[2];
      const cin7Brances = this.updateObjStructure(responses[3]);
      //console.log("Cin7 Branches? ", this.updateObjStructure(cin7Brances));

      this.checkForNewLocations(
        cin7Contacts,
        medableContacts,
        medableLocations,
        dateId,
        cin7Brances
      );
    });
  }

  isProperValue(value) {
    if (value == undefined) {
      return false;
    }

    if (value == null) {
      return false;
    }

    if (value == '') {
      return false;
    }

    return true;
  }

  checkForNewLocations(
    cin7Contacts,
    medableContacts,
    medableLocations,
    dateId,
    cin7Brances
  ) {
    // cin7Contacts = cin7Contacts.filter(cc => cc.c_cin7_id == 186);
    console.log('Checking New Locations');
    const promises = [];

    console.log('Cin7 Contacts: ', cin7Contacts);
    console.log('MedableCOntact: ', medableContacts);
    const desertContact = medableContacts.filter(
      (mc) => parseInt(mc.c_cin7_id) == 217
    );
    console.log('Desert Contact: ', desertContact);
    console.log('Cin7Brances: ', cin7Brances);

    const newBranches = cin7Brances.filter((branch) => {
      const existsContact =
        medableContacts.filter(
          (mc) => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)
        ).length > 0;
      const existsLocation =
        medableLocations.filter(
          (mc) => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)
        ).length > 0;
      return !(existsContact || existsLocation);
    });

    const existingBranches = cin7Brances.filter((branch) => {
      // const existsContact = medableContacts.filter( mc => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)).length > 0
      const existsLocation =
        medableLocations.filter(
          (mc) => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)
        ).length > 0;

      // console.log("Existing Locations for: ", branch, " are: ", medableLocations.filter(mc => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)))
      // console.log("Existing Contacts for: ", branch, " are: ", medableContacts.filter(mc => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)))
      let rocketId = '';

      if (existsLocation) {
        rocketId = medableLocations.filter(
          (mc) => parseInt(mc.c_cin7_id) == parseInt(branch.c_cin7_id)
        )[0]._id;
        branch['rocketId'] = rocketId;
        return true;
      }
    });

    const updateAbleBranches = existingBranches
      .filter((eb) => {
        if (eb.c_delivery_address) {
          const deliveryAddress = eb.c_delivery_address,
            hasCity = this.isProperValue(deliveryAddress.c_city),
            hasZip = this.isProperValue(deliveryAddress.c_postal_code),
            hasState = this.isProperValue(deliveryAddress.c_state),
            hasAddress1 = this.isProperValue(
              deliveryAddress.c_street_address_1
            ),
            hasAddress2 = this.isProperValue(
              deliveryAddress.c_street_address_2
            );

          return hasCity && hasZip && hasState && (hasAddress1 || hasAddress2);
        }
      })
      .map((branch) => {
        //branch['c_type'] = "Branch",
        delete branch.c_type;
        branch['c_contact_type'] = 4;

        return branch;
      });

    console.log('existingCin7Branches: ', existingBranches);
    console.log('updateAbleBranches: ', updateAbleBranches);

    const creatableNewBranches = newBranches
      .filter((nb) => {
        if (nb.c_delivery_address) {
          const deliveryAddress = nb.c_delivery_address,
            hasCity = this.isProperValue(deliveryAddress.c_city),
            hasZip = this.isProperValue(deliveryAddress.c_postal_code),
            hasState = this.isProperValue(deliveryAddress.c_state),
            hasAddress1 = this.isProperValue(
              deliveryAddress.c_street_address_1
            ),
            hasAddress2 = this.isProperValue(
              deliveryAddress.c_street_address_2
            );

          return hasCity && hasZip && hasState && (hasAddress1 || hasAddress2);
        }
      })
      .map((branch) => {
        //branch['c_type'] = "Branch",
        delete branch.c_type;
        branch['c_contact_type'] = 4;

        return branch;
      });

    console.log('New Creatable Branches: ', creatableNewBranches);
    // Creating new createable branches
    creatableNewBranches.map((newBranch) => {
      const promise = this.locationService.createLocation(newBranch);
      promises.push(promise);
    });

    // Update Existing UpdateAble Branches
    updateAbleBranches.map((existingBranch) => {
      const locId = existingBranch.rocketId;
      delete existingBranch.rocketId;
      // console.log("upd id: ", locId)
      console.log('updating Obj: ', existingBranch);
      const promise = this.locationService.editLocation(locId, existingBranch);
      promises.push(promise);
    });

    // return;
    const testLoc = {
      matching: [],
      new: [],
    };
    cin7Contacts.map((cin7) => {
      if (cin7.c_type === 'Branch') {
        const medLoc = medableLocations.find(
          (obj) => parseInt(obj.c_cin7_id) == parseInt(cin7.c_cin7_id)
        );
        delete cin7.c_type;
        if (medLoc) {
          const promise = this.locationService.editLocation(medLoc._id, cin7);
          console.log('Branch: Editing Location: ', cin7);
          promises.push(promise);
        } else {
          console.log('Branch: Creating Location: ', cin7);
          const promise = this.locationService.createLocation(cin7);
          promises.push(promise);
        }
      } else if (cin7.c_type === 'Supplier') {
        const medCon = medableContacts.find(
          (obj) => parseInt(obj.c_cin7_id) == parseInt(cin7.c_cin7_id)
        );
        delete cin7.c_type;
        if (medCon) {
          if (cin7.c_address.c_postal_city != null) {
            console.log('Supplier: Editing Contact: ', cin7);
            const promise = this.locationService.editContact(medCon._id, cin7);
            promises.push(promise);
          }
        } else {
          if (
            cin7.c_contact &&
            cin7.c_contact.c_address &&
            cin7.c_contact.c_address.c_postal_city
          ) {
            console.log('Supplier: Creating Contact: ', cin7);
            const promise = this.locationService.createContact(cin7);
            promises.push(promise);
          }
        }
      } else if (cin7.c_type == 4) {
        // console.log("What?: ", cin7);
        const medLoc = medableLocations.find(
          (obj) => parseInt(obj.c_cin7_id) == parseInt(cin7.c_cin7_id)
        );

        if (medLoc) {
          testLoc.matching.push(cin7);
          delete cin7.c_type;
          console.log('Editing Location(4)', cin7);
          const promise = this.locationService.editLocation(medLoc._id, cin7);
          promises.push(promise);
        } else {
          testLoc.new.push(cin7);
          if (cin7.c_type == 4) {
            console.log('Creating Location (4)', cin7);
            delete cin7.c_type;
            const promise = this.locationService.createLocation(cin7);
            promises.push(promise);
          }
        }
      }
    });

    // return;
    console.log('Locations Status: ', testLoc);
    // return;
    if (cin7Contacts.length) {
      const lastContact = cin7Contacts[cin7Contacts.length - 1];
      if (dateId) {
        const datePromise = this.locationService.updateSynchronizationDate(
          dateId,
          lastContact.modifiedDate,
          lastContact.id
        );
        promises.push(datePromise);
      } else {
        const datePromise = this.locationService.createSynchronizationDate(
          lastContact.modifiedDate,
          lastContact.id
        );
        promises.push(datePromise);
      }
    }

    forkJoin(promises).subscribe((responses) => {
      console.log('API Responses: ', responses);
    });
  }

  insuranceSelected(insuranceObj) {
    console.log('Insurance Selected: ', insuranceObj);
    if (insuranceObj.deleted) {
      delete insuranceObj.deleted;
    } else {
      insuranceObj['new'] = true;
      this.insurancesList.push(insuranceObj);
    }
  }

  updateObjStructure(locations) {
    const updatedLocations = [];

    const desertLoc = locations.filter((loc) => loc.id == 217);
    console.log('Desert Loc: ', desertLoc);
    for (let i = 0; i < locations.length; i++) {
      // console.log(locations[i].type);
      const medableStructure = {
        c_cin7_id: locations[i].id,
        c_company: locations[i].company,
        c_type: locations[i].type,
        c_first_name: locations[i].firstName,
        c_last_name: locations[i].lastName || '-',
        c_job_title: locations[i].jobTitle || '-',
        c_contact_type: locations[i].type,
        c_phone: locations[i].phone
          ? locations[i].phone.replace(/[^0-9]/g, '')
          : '',
        c_address: {
          c_postal_address: locations[i].postalAddress1,
          c_postal_city: locations[i].postalCity,
          c_postal_state: locations[i].postalState,
          c_postal_country: locations[i].postalCountry,
          c_postal_code: locations[i].postalPostCode,
        },
        c_delivery_address: {
          c_street_address_1: locations[i].address1,
          c_street_address_2: locations[i].address2,
          c_city: locations[i].city,
          c_state: locations[i].state,
          c_postal_code: locations[i].postCode,
        },
      };

      updatedLocations.push(medableStructure);
    }

    const initial = [...updatedLocations];
    console.log('Initial Cin7: ', initial);

    return updatedLocations;
  }
  onSubmit() {
    this.updateReferences();
    // if (this.state.is('forms.locationsEdit')) {
    //     this.goToViewLocation();
    // }
  }

  updateReferences() {
    const promisesList = [];
    // Process providers
    if (this.image !== undefined) {
      this.locationService
        .sendImageFile(this.image, this.locationId)
        .subscribe((resp) => {
          this.formsService
            .sendFileAWS(this.image, resp.data)
            .subscribe((awsRsp) => {
              console.log('Logo Saving Response: ', awsRsp);
            });
        });
    }

    // Process providers
    for (let i = 0; i < this.providersList.length; i++) {
      const provider = this.providersList[i];
      if (provider.deleted && provider._id) {
        const dpPromise = this.locationService.delete(
          'providerLocations',
          provider._id
        );
        promisesList.push(dpPromise);
      } else if (provider.created) {
        // delete provider.created;
        // console.log("provider:", provider);
        const locationProvider = {
          c_location: this.locationId,
          c_physician: provider.c_physician._id,
        };
        // console.log("Provider:", provider);
        const cpPromise = this.locationService.createProviderReference(
          locationProvider
        );
        promisesList.push(cpPromise);
      }
    }

    // Process fitters
    for (let i = 0; i < this.fittersList.length; i++) {
      const fitter = this.fittersList[i];
      if (fitter.deleted && fitter._id) {
        const dfPromise = this.locationService.delete(
          'account_locations',
          fitter._id
        );
        promisesList.push(dfPromise);
      } else if (fitter.created) {
        const locationFitter = {
          c_location: this.locationId,
          c_fitter: fitter.c_fitter._id,
        };
        const cfPromise = this.locationService.createFitterReference(
          locationFitter
        );
        promisesList.push(cfPromise);
      }
    }

    // Process Insurances
    for (let i = 0; i < this.insurancesList.length; i++) {
      const insuranceObj = this.insurancesList[i];

      if (insuranceObj.insLocId && insuranceObj.deleted) {
        const diPromise = this.locationService.delete(
          'insuranceLocations',
          insuranceObj.insLocId
        );
        promisesList.push(diPromise);
      } else if (!insuranceObj.insLocId && insuranceObj.created) {
        const insuranceRef = {
          c_insurance: insuranceObj._id,
          c_location: this.locationId,
        };

        const ciPromise = this.locationService.createInsuranceReference(
          insuranceRef
        );
        promisesList.push(ciPromise);
      }
    }

    if (!promisesList.length) {
      this.goToBack();
    } else {
      forkJoin(promisesList).subscribe((responses) => {
        console.log('Promise Responses: ', responses);
        this.goToBack();
      });
    }
  }

  getLocation() {
    this.locationService.getLocation(this.locationId).subscribe((location) => {
      this.location = {
        c_location_name: location.c_company,
        // c_address: (location.c_address) ? {
        //   c_street_address_1: (location.c_address.c_postal_address) ? location.c_address.c_postal_address : '',
        //   c_city: (location.c_address.c_postal_city) ? location.c_address.c_postal_city : '',
        //   c_state: (location.c_address.c_postal_state) ? location.c_address.c_postal_state : '',
        //   c_postal_code: (location.c_address.c_postal_code) ? location.c_address.c_postal_code : '',
        //   c_country: (location.c_address.c_postal_country) ? location.c_address.c_postal_country : ''
        // } : {
        //   c_street_address_1: '',
        //   c_country: '',
        //   c_city: '',
        //   c_state: '',
        //   c_postal_code: ''
        // },
        c_address: location.c_delivery_address
          ? {
              c_street_address_1: location.c_delivery_address.c_street_address_1
                ? location.c_delivery_address.c_street_address_1
                : '',
              c_city: location.c_delivery_address.c_city
                ? location.c_delivery_address.c_city
                : '',
              c_state: location.c_delivery_address.c_state
                ? location.c_delivery_address.c_state
                : '',
              c_postal_code: location.c_delivery_address.c_postal_code
                ? location.c_delivery_address.c_postal_code
                : '',
              c_country: location.c_delivery_address.c_country
                ? location.c_delivery_address.c_country
                : '',
            }
          : {
              c_street_address_1: '',
              c_country: '',
              c_city: '',
              c_state: '',
              c_postal_code: '',
            },
        c_phone: location.c_phone ? location.c_phone : '-',
      };

      console.log('this location: ', location);
    });
  }

  deleteImage() {
    this.image = undefined;
    this.imageUrl = '';
  }

  handleFileInput(event: any) {
    console.log('Changed!');
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0];

      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getProviders(cb = () => {}) {
    this.providerService.getAllProviders(0).subscribe((providers) => {
      this.providers = providers;
      this.providersData = providers.data;
      this.snapshotProviders = this.providersData.slice(0);
      // console.log("Providers: ", providers);
      cb();
    });
  }

  updateProviders() {
    this.providersData = this.snapshotProviders.slice(0);
    this.providersListIds = this.providersList.map((provider) => {
      if (provider.deleted) {
        return;
      }
      return provider.c_physician._id;
    });
    this.providersData = this.providersData.filter(
      this.removeProviderList.bind(this)
    );
  }

  removeProviderList(provider) {
    return this.providersListIds.indexOf(provider._id) === -1;
  }

  removeInsurance(ins) {
    // this.insurancesList.splice(index, 1);
    delete ins.new;
    ins['deleted'] = true;
  }

  insuranceFocusout() {
    setTimeout(() => {
      this.insurancesAutocomplete = '';
    }, 500);
  }

  getProvidersList(cb = () => {}) {
    this.formsService
      .get(
        'providerLocations',
        '?paths[]=c_physician&where={"c_location":{"$in": ["' +
          this.locationId +
          '"]}}&expand[]=c_physician'
      )
      .subscribe((providers) => {
        this.providersList = providers.data;

        // for (let i = 0; i < this.providersList.length; i++) {
        //   this.providersList[i];
        // }

        console.log('providersL:', this.providersList);
        cb();
      });
  }

  getRelatedInsurances() {
    console.log('locationId: ', this.locationId);
    const path = 'paths[]=c_insurance',
      filter = `?paths[]=c_insurance&where={"c_location": "${this.locationId}"}`,
      expand = 'expand[]=c_insurance',
      query = `?${path}&${filter}&${expand}`;

    this.formsService
      .get('insuranceLocations', query)
      .subscribe((insuranceLocations) => {
        // console.log("RelatedInsurances Loaded: ", insuranceLocations)
        this.insurancesList = [];
        insuranceLocations.data.map((insLoc) => {
          const insObj = insLoc.c_insurance;
          insObj['insLocId'] = insLoc._id;
          this.insurancesList.push(insObj);
        });
        console.log('Related Insurances Loaded: ', this.insurancesList);
      });
  }

  getAllInsurances() {
    this.allInsurances = [];
    console.log('Loading all insurances from medable');
    this.insuranceService.getAllInsurances().subscribe((allInsurances) => {
      this.allInsurances = allInsurances;
      console.log('All insurances from Medable loaded...', this.allInsurances);
    });
  }

  updateFitters() {
    this.fitters = this.snapshotFitters.slice(0);
    this.fittersListIds = this.fittersList.map((fitter) => {
      if (fitter.deleted) {
        return;
      }
      return fitter.c_fitter._id;
    });

    this.fitters = this.fitters.filter(this.removeFittersList.bind(this));
  }

  removeFittersList(fitter) {
    return this.fittersListIds.indexOf(fitter._id) === -1;
  }

  getFitters(cb = () => {}) {
    this.formsService
      .get('all_accounts', '?paths[]=name&paths[]=email&limit=10000')
      .subscribe((fitters) => {
        this.fitters = fitters.data;
        this.snapshotFitters = this.fitters.slice(0);
        cb();
      });
  }

  getFittersList(cb = () => {}) {
    this.formsService
      .get(
        'account_locations',
        '?paths[]=c_fitter&where={"c_location":{"$in": ["' +
          this.locationId +
          '"]}}&expand[]=c_fitter'
      )
      .subscribe((fitters) => {
        this.fittersList = fitters.data;
        cb();
      });
  }

  goToViewLocation() {
    console.log('Goto View');
    this.router.navigate(['forms/locations/:id/view', { id: this.locationId }]);
  }

  gotoLocationsIndex() {
    this.router.navigate(['forms/locations/index']);
  }

  goToEditLocation() {
    this.router.navigate(['forms/locations/:id/edit', { id: this.locationId }]);
  }

  cancel() {
    if (this.location.c_location_name) {
      const info = {
        title: 'forms.confirm.title',
        subtitle: 'forms.confirm.subtitle',
        btnLeft: 'NO',
        btnLeftClass: 'no',
        btnRight: 'YES',
        btnRightClass: 'yes',
      };
      let modalInstance = this.modalService.open(PopupComponent);
      this.sharedService.setPopupInfo(info);
      modalInstance.result.then(this.goToBack);
    } else {
      this.goToBack();
    }
  }

  goToBack() {
    if (this.locationId === '0') {
      this.router.navigate(['forms/locations/index']);
    } else {
      this.router.navigate([
        'forms/locations/:id/view',
        { id: this.locationId },
      ]);
    }
  }

  getLast10Stocks() {
    this.stockService.getLast10Stocks(this.locationId).subscribe((invs) => {
      this.stocks = invs.data;
    });
  }

  getStocks(input) {
    this.stockService.searchStock(input, this.locationId).subscribe((stock) => {
      this.stocks = stock.data;
    });
  }

  removeProvider(provider) {
    delete provider.created;
    provider.deleted = true;
    // const index: number = this.providersList.indexOf(provider);
    // if (index !== -1) {
    //   this.providersList.splice(index, 1);
    // }
    this.updateProviders();
  }

  removeFitter(fitter) {
    delete fitter.created;
    fitter.deleted = true;
    // const index: number = this.fittersList.indexOf(fitter);
    // if (index !== -1) {
    //   this.fittersList.splice(index, 1);
    // }
    this.updateFitters();
  }

  onFitterSelected() {
    if (typeof this.fittersAutocomplete === 'object') {
      delete this.fittersAutocomplete.deleted;
      let createElement = {
        c_fitter: this.fittersAutocomplete,
        // _id: this.fittersAutocomplete._id,
        object: 'c_fitter_location',
        created: true,
      };
      this.fittersList.push(createElement);
      this.fittersAutocomplete = '';
      this.updateFitters();
    }
  }

  onProviderSelected() {
    if (typeof this.providersAutocomplete === 'object') {
      delete this.providersAutocomplete.deleted;
      let createElement = {
        c_physician: this.providersAutocomplete,
        // _id: this.providersAutocomplete._id,
        object: 'c_provider_location',
        created: true,
      };
      this.providersList.push(createElement);
      this.providersAutocomplete = '';
      this.updateProviders();
    }
  }

  searchFitters = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const email = this.fitters
            .filter(
              (v) => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const firstname = this.fitters
            .filter(
              (v) => v.name.first.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const lastname = this.fitters
            .filter(
              (v) => v.name.last.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          return email.length
            ? email
            : lastname.length
            ? lastname
            : firstname.length
            ? firstname
            : [];
        }
      })
    );
  };

  searchInsurances = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          // const email = this.fitters.filter(v => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
          const founcInsurances = this.allInsurances
            .filter((ins) => {
              const match =
                  ins.c_insurance_name
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
                addedIns = this.insurancesList.filter((i) => !i.deleted),
                alreadyAdded = addedIns.filter((i) => i._id == ins._id).length;

              if (match && !alreadyAdded) {
                return true;
              }
            })
            .slice(0, 10);
          return founcInsurances.length ? founcInsurances : [];
        }
      })
    );
  };

  formatterFitters = (x: { email: string }) => x.email;
  formatterInsurances = (x: { c_insurance_name: string }) => x.c_insurance_name;

  searchProviders = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const firstname = this.providersData
            .filter(
              (v) =>
                v.c_name.c_first_name
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const lastname = this.providersData
            .filter(
              (v) =>
                v.c_name.c_last_name.toLowerCase().indexOf(term.toLowerCase()) >
                -1
            )
            .slice(0, 10);
          return firstname.length ? firstname : lastname.length ? lastname : [];
        }
      })
    );
  };

  formatterProviders = (x: { c_name: any }) =>
    x.c_name.c_first_name + ' ' + x.c_name.c_last_name;
}
