<div class="auth">
  <div class="auth-container">
    <img
      class="ortho-logo"
      src="/assets/images/ortho-logo.png"
      style="width: 162px; height: 92px"
    />
    <router-outlet></router-outlet>
  </div>
</div>
