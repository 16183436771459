<div class="login">
  <div class="auth-form_container">
    <form
      class="form"
      name="form"
      (ngSubmit)="f.form.valid && login()"
      #f="ngForm"
      novalidate
    >
      <div class="alert alert-error" *ngIf="errorMessage">
        <p>{{ errorMessage }}</p>
      </div>
      <div
        class="form-content"
        [ngClass]="{ 'form-content-custom_message': hasCustomMessage }"
      >
        <div class="auth-form_container-welcome_message-wrapper">
          <h3>{{ 'login.form.title' | lang }}</h3>
        </div>
        <div class="auth-form_container-inputs-wrapper">
          <div
            class="form-row"
            [ngClass]="{ 'first-child-modificated': tokenRequired }"
          >
            <input
              class="rounded"
              name="email"
              placeholder="{{ 'login.form.placeholder.email' | lang }}"
              [(ngModel)]="user.email"
              type="email"
              required
            />
          </div>
          <div class="form-row">
            <input
              class="rounded"
              name="password"
              placeholder="{{ 'login.form.placeholder.password' | lang }}"
              [(ngModel)]="user.password"
              type="password"
              required
            />
            <button class="submit-btn" type="submit">
              <img src="/assets/images/arrow-right.png" />
            </button>
          </div>
          <div class="form-row" *ngIf="tokenRequired">
            <input
              class="rounded"
              placeholder="{{ 'login.form.placeholder.token' | lang }}"
              [(ngModel)]="user.location.verificationToken"
              type="text"
              required
            />
            <button class="submit-btn" type="submit"></button>
          </div>
        </div>
      </div>
      <div class="form-actions">
        <a id="fogot_password_link" [routerLink]="['/forgot-password']">
          {{ 'auth.form.label.forgot_password' | lang }}
        </a>
      </div>
    </form>
  </div>
</div>
