import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  class: string;
  page?: string;
  roles?: string[];
}

export const ROUTES: RouteInfo[] = [
  {
    path: '/reporting',
    title: 'menu.reporting',
    class: 'icon-left dashboard',
    page: 'reporting',
    roles: ['admin', 'executive'],
  },
  {
    path: '/externalAccess',
    title: 'menu.external.access',
    class: 'icon-left dashboard',
    page: 'externalAccess',
    roles: ['admin'],
  },
  {
    path: '/bulk',
    title: 'menu.bulk.operations',
    class: 'icon-left dashboard',
    page: 'bulk',
    roles: ['admin'],
  },
  {
    path: '/dashboard',
    title: 'menu.dashboard',
    class: 'icon-left dashboard',
    page: 'dashboard',
    roles: ['standard'],
  },
  {
    path: '/forms/orders/index',
    title: 'menu.orders',
    class: 'icon-left orders',
    page: 'orders',
    roles: ['standard'],
  },
  {
    path: '/forms/providers/index',
    title: 'menu.providers',
    class: 'icon-left providers',
    page: 'providers',
    roles: ['standard'],
  },
  {
    path: '/forms/locationGroups/index',
    title: 'menu.locationGroups',
    class: 'icon-left locations',
    page: 'locationGroups',
    roles: ['admin'],
  },
  {
    path: '/forms/locations/index',
    title: 'menu.locations',
    class: 'icon-left locations',
    page: 'locations',
    roles: ['standard'],
  },
  {
    path: '/forms/inventories/index',
    title: 'menu.inventory',
    class: 'icon-left inventory',
    page: 'inventories',
    roles: ['standard'],
  },
  {
    path: '/forms/patients/index',
    title: 'menu.patients',
    class: 'icon-left patients',
    page: 'patients',
    roles: ['standard'],
  },
  {
    path: '/forms/insurances/index',
    title: 'menu.insurances',
    class: 'icon-left insurances',
    page: 'insurances',
    roles: ['standard'],
  },
  {
    path: '/forms/forms/index',
    title: 'menu.forms',
    class: 'icon-left forms_',
    page: 'forms',
    roles: ['standard'],
  },
  {
    path: '/forms/fitters/index',
    title: 'menu.fitters',
    class: 'icon-left patients',
    page: 'fitters',
    roles: ['standard'],
  },
  {
    path: '/forms/icd10s/index',
    title: 'menu.icd10',
    class: 'icon-left icd10',
    page: 'icd10s',
    roles: ['standard'],
  },
  {
    path: '/forms/modifiers/index',
    title: 'menu.modifiers',
    class: 'icon-left orders',
    page: 'modifiers',
    roles: ['standard'],
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  user_name: any = [];
  urlPage = '';
  hasRole: any = {};
  isSidebarOpen$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private sidebarService: SidebarService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urls = val.url.split('/').filter((url) => url !== '');
        // console.log("Urls: ", urls);
        if (urls.length === 1) {
          this.urlPage = urls[0];
        } else {
          this.urlPage = urls[1];
        }
        // console.log("UrlPage = ", this.urlPage);
      }
    });
  }

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.isSidebarOpen$ = this.sidebarService.isOpen$;
    // Don't show first 2 items if user is not admin
    // if(!this.hasRole.admin) {
    //   this.menuItems = ROUTES.filter(menuItem => menuItem.role == 'standard');
    // } else {
    //   this.menuItems = ROUTES.filter(menuItem => menuItem);
    // }

    if (this.hasRole.admin) {
      this.menuItems = ROUTES;
    } else if (this.hasRole.executive) {
      this.menuItems = ROUTES.filter((item) => {
        if (
          this.hasInArray(item.roles, 'executive') ||
          this.hasInArray(item.roles, 'standard')
        ) {
          return true;
        }
      });
    } else {
      this.menuItems = ROUTES.filter((item) =>
        this.hasInArray(item.roles, 'standard')
      );
    }
    // this.getUser();
  }

  hasInArray(array, value) {
    let exists = false;

    array.map((item) => {
      if (item === value) {
        exists = true;
      }
    });
    return exists;
  }

  getUser() {
    this.authService.get('accounts').subscribe(
      (account) => {
        if (account.data[0] !== undefined) {
          this.user_name = account.data[0].name;
        } else {
          alert('Your session has expired. Please login again');
        }
      },
      (error) => {
        console.log(error.data);
      }
    );
  }

  logout() {
    this.authService.logout().subscribe((data) => {
      console.log(data);
    });
    this.router.navigate(['/login']);
  }

  goToMain() {
    this.router.navigate(['/dashboard']);
  }
}
