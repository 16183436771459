<div class="forms-form" style="width: calc(100% - 30px)">
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ 'dashboard.title' | lang }}</h1>
      <div class="pull-right">
        <button
          type="button"
          class="button edit-button"
          (click)="exportDashboard()"
          [disabled]="orderLineItemsFilter.length == 0"
        >
          {{ 'dashboard.export' | lang }}
        </button>
      </div>
    </div>
  </div>

  <!-- Orders Form -->
  <form
    name="dashboard"
    #dashboard="ngForm"
    (ngSubmit)="dashboard.form.valid && getOrders()"
    novalidate
  >
    <!-- Order Date Filters + Search -->
    <div class="row">
      <div class="col-xs-12 ll-mb-20">
        <label>{{ 'dashboard.dos_label' | lang }}</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min date"
            angular-mydatepicker
            name="minDate"
            [(ngModel)]="minDateModel"
            [options]="dpOptions"
            #dpMin="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max date"
            angular-mydatepicker
            name="maxDate"
            [(ngModel)]="maxDateModel"
            [options]="dpOptions"
            #dpMax="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-2 col-md-1">
        <button
          type="submit"
          class="button next-button ll-m2-auto ll-no-float"
          [disabled]="!searchFormValid()"
        >
          SEARCH
        </button>
      </div>
    </div>

    <!-- Billed Order Date Filters -->
    <div class="row ll-mt-20">
      <div class="col-xs-12">
        <label>Billed Date Range:</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min (Billed) date"
            angular-mydatepicker
            name="minBilledDate"
            [(ngModel)]="minBilledDateModel"
            [options]="dpOptions"
            #dpMinB="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinB.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinB.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max (Billed) date"
            angular-mydatepicker
            name="maxBilledDate"
            [(ngModel)]="maxBilledDateModel"
            [options]="dpOptions"
            #dpMaxB="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxB.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxB.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="row">
      <!-- SKU  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Status:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="selectedStatus" name="statusDropdown">
              <option *ngFor="let fs of filterStatuses" [ngValue]="fs">
                {{ fs }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- END Orders Form -->

  <div class="row separate-top">
    <ul class="dashboard-table">
      <li>
        <ul class="ll-table-header">
          <li>{{ 'dashboard.order' | lang }}</li>
          <li>{{ 'dashboard.date' | lang }}</li>
          <li>{{ 'dashboard.status' | lang }}</li>
          <li>Billed Date</li>
          <li>Claim ID</li>
          <li>Patient MRN</li>
          <li>Patient Name</li>
          <li>{{ 'dashboard.creator.name' | lang }}</li>
          <li>{{ 'dashboard.fitter.name' | lang }}</li>
          <li>Ordering Provider</li>
          <li>{{ 'dashboard.billingProvider.name' | lang }}</li>
          <li>{{ 'dashboard.hcpcs' | lang }}</li>
          <li>{{ 'dashboard.item_name' | lang }}</li>
          <li>{{ 'dashboard.quantity' | lang }}</li>
          <li>{{ 'dashboard.size' | lang }}</li>
          <li>{{ 'dashboard.vendor' | lang }}</li>
          <li>{{ 'dashboard.location' | lang }}</li>
          <li>{{ 'dashboard.insurance' | lang }}</li>
          <li>{{ 'dashboard.modifiers' | lang }}</li>
          <li>{{ 'dashboard.warnings' | lang }}</li>
          <li>{{ 'dashboard.charge_out_cost' | lang }}</li>
          <li>{{ 'dashboard.allowable_cost' | lang }}</li>
          <li>{{ 'dashboard.patient_payment' | lang }}</li>
          <li>{{ 'dashboard.sales_tax' | lang }}</li>
        </ul>
      </li>
      <div *ngIf="orderLineItemsFilter.length && lineItemsLoaded">
        <li
          *ngFor="let line of orderLineItemsFilter"
        >
          <ul>
            <li
              (click)="goToViewOrder(line.order._id)"
              class="clickable-item"
            >{{ line.order.c_number_id }}</li>
            <li>{{ line.order.display_order_date }}</li>
            <li>{{ line.order.status }}</li>
            <li>{{ line.order.display_billed_date }}</li>
            <li>{{ line.order.c_claim_id ? line.order.c_claim_id : '-' }}</li>
            <li
              (click)="goToViewPatient(line.order.c_patient._id)"
              class="clickable-item"
            >{{ line.order.c_patient.c_mrn }}</li>
            <li
              (click)="goToViewPatient(line.order.c_patient._id)"
              class="clickable-item"
            >
              {{
                line.order.c_patient.c_name.c_first_name +
                  ' ' +
                  line.order.c_patient.c_name.c_last_name
              }}
            </li>
            <li>{{ line.order.creatorName }}</li>
            <li>{{ line.order.fitterName }}</li>
            <li>{{ line.order.providerName }}</li>
            <li>{{ line.order.billingProviderName }}</li>
            <li>{{ line.c_selected_hcpcs || line.c_product.c_hcpcs_code }}</li>
            <li>{{ line.c_product.c_name }}</li>
            <li>{{ line.c_quantity }}</li>
            <li>
              {{
                line.c_product.cpy_product_option != undefined
                  ? line.c_product.cpy_product_option.c_size
                  : '-'
              }}
            </li>
            <li>
              {{
                line.c_product.c_supplier_id != undefined
                  ? line.c_product.c_supplier_id.c_company
                  : '-'
              }}
            </li>
            <li>
              {{
                line.order.c_provider_location.c_location != undefined
                  ? line.order.c_provider_location.c_location.c_company
                  : '-'
              }}
            </li>
            <li>{{ line.order.c_insurance?.c_insurance_name ? line.order.c_insurance.c_insurance_name : "-" }}</li>
            <li>{{ line.modifiers }}</li>
            <li>{{ line.warnings }}</li>
            <li>{{ '$' + line.c_charge_out_cost }}</li>
            <li>{{ '$' + line.c_allowable_cost }}</li>
            <li>{{ '$' + line.c_self_pay_cost }}</li>
            <li>{{ '$' + line.c_sales_tax }}</li>
          </ul>
        </li>
      </div>

      <li
        *ngIf="!orderLineItemsFilter.length && lineItemsLoaded"
        class="ll-pp-background"
      >
        <label>{{ 'dashboard.no_results' | lang }}</label>
      </li>
    </ul>
  </div>

  <div class="row paginator" *ngIf="pageCount.length > 1 && lineItemsLoaded">
    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="prevPage()"
      [disabled]="actualPage == 0"
    >
      <i class="glyphicon glyphicon-chevron-left"></i>
    </button>

    <ul>
      <div *ngIf="pageCount.length <= 7">
        <div *ngFor="let n of pageCount; let i = index" class="ll-page-number">
          <li
            [ngClass]="{ active: i == actualPage }"
            class="pagination-numbers"
            (click)="goToPage(i)"
          >
            {{ i + 1 }}
          </li>
        </div>
      </div>

      <div *ngIf="pageCount.length > 7">
        <li
          [ngClass]="{ active: actualPage == 0 }"
          class="pagination-numbers"
          (click)="goToPage(0)"
        >
          {{ 1 }}
        </li>
        <li class="dots" *ngIf="actualPage >= 4">...</li>
        <span *ngFor="let n of pageCount; let i = index">
          <li
            *ngIf="
              i - actualPage <= 2 &&
              actualPage - i <= 2 &&
              i != 0 &&
              i != pageCount.length - 1
            "
            [ngClass]="{ active: i == actualPage }"
            class="pagination-numbers"
            (click)="goToPage(i)"
          >
            {{ i + 1 }}
          </li>
        </span>
        <li class="dots" *ngIf="pageCount.length - actualPage > 4">...</li>
        <li
          ngClass="{'active': actualPage == pageCount.length - 1}"
          class="pagination-numbers"
          (click)="goToPage(pageCount.length - 1)"
        >
          {{ pageCount.length }}
        </li>
      </div>
    </ul>

    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="nextPage()"
      [disabled]="actualPage == pageCount.length - 1"
    >
      <i class="glyphicon glyphicon-chevron-right"></i>
    </button>
  </div>
</div>
