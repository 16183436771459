import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { LocationGroupsService } from '../../../services/location-groups/location-groups.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-location-groups-create',
  templateUrl: './location-groups-create.component.html',
  styleUrls: ['./location-groups-create.component.css'],
})
export class LocationGroupsCreateComponent implements OnInit {
  locationGroup: any = {};
  errorMessageContainer: any = {
    exists: false,
    message: '',
  };

  allLocations: any[] = [];
  associatedLocations: any[] = [];
  locationInput = '';

  constructor(
    private locationGroupsService: LocationGroupsService,
    private locationService: LocationService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadAllLocations();
  }

  loadAllLocations() {
    this.locationService.getAllMedableLocations().subscribe((allLocations) => {
      this.allLocations = allLocations.map((loc) => {
        return {
          _id: loc._id,
          c_company: loc.c_company,
        };
      });
    });
  }

  onSubmit() {
    this.locationGroupsService
      .checkDuplicateByName(this.locationGroup.c_group_name, 1)
      .subscribe((duplicate) => {
        const alreadyCreated = duplicate.data.length;
        if (alreadyCreated) {
          this.showErrorMessage();
        } else {
          this.createLocation();
        }
      });
  }

  createLocation() {
    this.locationGroupsService
      .createLocationGroup(this.locationGroup)
      .subscribe((created) => {
        this.addCategoryToLocations(created._id);
      });
  }

  showErrorMessage() {
    this.errorMessageContainer = {
      exists: true,
      message: '*Location Group Name Already Exists*',
    };
  }

  searchLocation() {
    const search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const locations = this.allLocations.filter((location) => {
              const locationMatches =
                  location.c_company.toLowerCase().indexOf(term.toLowerCase()) >
                  -1,
                alreadyAdded =
                  this.associatedLocations.filter(
                    (assoc) => assoc._id == location._id
                  ).length > 0;
              if (locationMatches && !alreadyAdded) {
                return true;
              }
            });

            return locations.length ? locations.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  addLocation(location) {
    this.associatedLocations.push(location);
    this.locationInput = '';
  }

  removeLocation(locationIdx) {
    this.associatedLocations.splice(locationIdx, 1);
  }

  addCategoryToLocations(groupId) {
    const promises = [];

    this.associatedLocations.map((location) => {
      const promise = this.updateLocation(location._id, groupId);
      promises.push(promise);
    });

    forkJoin(promises).subscribe((responses) => {
      this.gotoViewLocationGroup(groupId);
    });
  }

  updateLocation(locationId, groupId) {
    const localSubject = new Subject();
    const object = { c_location_group: groupId };

    this.locationService.editLocation(locationId, object).subscribe(() => {
      localSubject.next(1);
      localSubject.complete();
    });

    return localSubject;
  }

  locationsFormatter = (x: { c_company: string }) => '';

  gotoViewLocationGroup(locationGroupId) {
    this.router.navigate([
      `forms/locationGroups/:id/view`,
      { id: locationGroupId },
    ]);
  }
}
