import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-custom-access-route',
  templateUrl: './custom-access-route.component.html',
  styleUrls: ['./custom-access-route.component.css'],
})
export class CustomAccessRouteComponent implements OnInit {
  oldPassword = '';
  oldPasswordVisible = false;
  newPassword = '';
  newPasswordVisible = false;
  confirmNewPassword = '';
  confirmNewPasswordVisible = false;
  errorMessage = '';
  successMessage = '';
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  // Submit form
  onSubmit() {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.formHasErrors()) {
      return;
    }

    this.attemptChangingPassword();
  }

  // Check if form has errors & display them
  formHasErrors() {
    if (this.newPassword != this.confirmNewPassword) {
      this.errorMessage = 'New password fields do not match';
      return true;
    } else if (this.newPassword == this.oldPassword) {
      this.errorMessage = 'New password needs to be different than the old one';
      return true;
    }
    this.errorMessage = '';
    return false;
  }

  // Try to get old password object based on entered value
  attemptChangingPassword() {
    this.authService
      .findExternalAccessCode(this.oldPassword)
      .subscribe((eac) => {
        if (!eac.data.length) {
          this.errorMessage = 'Incorrect old password';
          return;
        } else {
          this.changePassword(eac.data[0]);
        }
      });
  }

  // Change old password
  changePassword(passwordObject) {
    this.authService
      .updateExternalAccessCode(passwordObject._id, {
        c_access_code: this.newPassword,
      })
      .subscribe((updated) => {
        this.successMessage = 'Password Succesfully Updated!';
      });
  }

  toggleInputType(input) {
    switch (input) {
      case 'oldPassword':
        this.oldPasswordVisible = !this.oldPasswordVisible;
        break;

      case 'newPassword':
        this.newPasswordVisible = !this.newPasswordVisible;
        break;

      case 'confirmNewPassword':
        this.confirmNewPasswordVisible = !this.confirmNewPasswordVisible;
        break;
    }
  }

  returnInputType(input) {
    switch (input) {
      case 'oldPassword':
        return this.oldPasswordVisible ? 'text' : 'password';

      case 'newPassword':
        return this.newPasswordVisible ? 'text' : 'password';

      case 'confirmNewPassword':
        return this.confirmNewPasswordVisible ? 'text' : 'password';
    }
  }
}
