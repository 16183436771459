import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class DataService {
  API: any = ENDPOINTS.auth;
  API_FORMS: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': CONSTANTS.MEDABLE_CLIENT_KEY,
    }),
  };

  constructor(private http: HttpClient) {}

  // --- Get ---
  getAllPatients(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.patients}`);
  }

  getPatient(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.patients}/${id}`);
  }

  getAllAccounts(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.accounts}`);
  }

  getAccount(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.accounts}/${id}`);
  }

  getAllAccountNames(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.all_accounts}`);
  }

  getAccountName(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.all_accounts}/${id}`);
  }

  getAllAccountLocations(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.account_locations}`);
  }

  getAccountLocation(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.account_locations}/${id}`);
  }

  getAllInventoryInsuranceLocs(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryInsuranceLoc}`);
  }

  getInventoryInsuranceLoc(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryInsuranceLoc}/${id}`);
  }

  getAllPhysicians(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.physicians}`);
  }

  getPhysician(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.physicians}/${id}`);
  }

  getAllOrderForms(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orderForms}`);
  }

  getOrderForm(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orderForms}/${id}`);
  }

  getAllOrders(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orders}`);
  }

  getOrder(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orders}/${id}`);
  }

  getAllLocations(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.locations}`);
  }

  getLocation(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.locations}/${id}`);
  }

  getAllLocationsCin7(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.locationsCin7}`);
  }

  getLocationCin7(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.locationsCin7}/${id}`);
  }

  getAllSuppliersCin7(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.supplierCin7}`);
  }

  getSupplierCin7(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.supplierCin7}/${id}`);
  }

  getAllSuppliers(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.supplier}`);
  }

  getSupplier(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.supplier}/${id}`);
  }

  getAllInventories(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventories}`);
  }

  getInventory(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventories}/${id}`);
  }

  getAllInventoriesCin7(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoriesCin7}`);
  }

  getInventoryCin7(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoriesCin7}/${id}`);
  }

  getAllInsurances(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.insurances}`);
  }

  getInsurance(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.insurances}/${id}`);
  }

  getAllIcd10s(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.icd10s}`);
  }

  getIcd10s(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.icd10s}/${id}`);
  }

  getAllProviders(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.providers}`);
  }

  getProvider(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.providers}/${id}`);
  }

  getAllProviderLocations(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.providerLocations}`);
  }

  getProviderLocation(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.providerLocations}/${id}`);
  }

  getAllForms(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.forms}`);
  }

  getForm(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.forms}/${id}`);
  }

  getAllInventoryForms(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryForms}`);
  }

  getInventoryForm(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryForms}/${id}`);
  }

  getAllOrderLineItems(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orderLineItems}`);
  }

  getOrderLineItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.orderLineItems}/${id}`);
  }

  getAllQuestions(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.questions}`);
  }

  getQuestion(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.questions}/${id}`);
  }

  getAllModifiers(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.modifiers}`);
  }

  getModifier(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.modifiers}/${id}`);
  }

  getAllSynchronizationDate(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.synchronizationDate}`);
  }

  getSynchronizationDate(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.synchronizationDate}/${id}`);
  }

  getAllInventoryLocations(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryLocation}`);
  }

  getInventoryLocation(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoryLocation}/${id}`);
  }

  getAllSalesOrderCin7(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.salesOrderCin7}`);
  }

  getSalesOrderCin7(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.salesOrderCin7}/${id}`);
  }

  getAllStocks(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.stock}`);
  }

  getStock(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.stock}/${id}`);
  }

  getAllStockCin7(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.stockCin7}`);
  }

  getStockCin7(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.stockCin7}/${id}`);
  }

  getAllInventoriesLocationRoute(): Observable<any> {
    return this.http.get<any>(`${this.API_FORMS.inventoriesLocationRoute}`);
  }

  getInventoriesLocationRoute(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.API_FORMS.inventoriesLocationRoute}/${id}`
    );
  }

  // --- Create ---
  addPatients(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.patients}`,
      data,
      this.httpOptions
    );
  }

  addAccounts(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.accounts}`,
      data,
      this.httpOptions
    );
  }

  addAllAccounts(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.all_accounts}`,
      data,
      this.httpOptions
    );
  }

  addAccountLocation(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.account_locations}`,
      data,
      this.httpOptions
    );
  }

  addInventoryInsuranceLoc(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventoryInsuranceLoc}`,
      data,
      this.httpOptions
    );
  }

  addPhysicians(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.physicians}`,
      data,
      this.httpOptions
    );
  }

  addOrderForms(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.orderForms}`,
      data,
      this.httpOptions
    );
  }

  addOrders(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.orders}`,
      data,
      this.httpOptions
    );
  }

  addLocations(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.locations}`,
      data,
      this.httpOptions
    );
  }

  addLocationsCin7(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.locationsCin7}`,
      data,
      this.httpOptions
    );
  }

  addSupplierCin7(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.supplierCin7}`,
      data,
      this.httpOptions
    );
  }

  addSupplier(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.supplier}`,
      data,
      this.httpOptions
    );
  }

  addInventories(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventories}`,
      data,
      this.httpOptions
    );
  }

  addInventoriesCin7(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventoriesCin7}`,
      data,
      this.httpOptions
    );
  }

  addInsurances(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.insurances}`,
      data,
      this.httpOptions
    );
  }

  addIcd10s(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.icd10s}`,
      data,
      this.httpOptions
    );
  }

  addProviders(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.providers}`,
      data,
      this.httpOptions
    );
  }

  addProviderLocations(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.providerLocations}`,
      data,
      this.httpOptions
    );
  }

  addForms(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.forms}`,
      data,
      this.httpOptions
    );
  }

  addInventoryForms(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventoryForms}`,
      data,
      this.httpOptions
    );
  }

  addOrderLineItems(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.orderLineItems}`,
      data,
      this.httpOptions
    );
  }

  addQuestions(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.questions}`,
      data,
      this.httpOptions
    );
  }

  addModifiers(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.modifiers}`,
      data,
      this.httpOptions
    );
  }

  addSynchronizationDate(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.synchronizationDate}`,
      data,
      this.httpOptions
    );
  }

  addInventoryLocation(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventoryLocation}`,
      data,
      this.httpOptions
    );
  }

  addSalesOrderCin7(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.salesOrderCin7}`,
      data,
      this.httpOptions
    );
  }

  addStock(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.stock}`,
      data,
      this.httpOptions
    );
  }

  addStockCin7(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.stockCin7}`,
      data,
      this.httpOptions
    );
  }

  addInventoriesLocationRoute(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.API_FORMS.inventoriesLocationRoute}`,
      data,
      this.httpOptions
    );
  }

  // --- Update ---
  updatePatients(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.patients}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateAccounts(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.accounts}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateAllAccounts(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.all_accounts}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateAccountLocation(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.account_locations}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventoryInsuranceLoc(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventoryInsuranceLoc}/${id}`,
      data,
      this.httpOptions
    );
  }

  updatePhysicians(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.physicians}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateOrderForms(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.orderForms}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateOrders(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.orders}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateLocations(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.locations}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateLocationsCin7(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.locationsCin7}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateSupplierCin7(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.supplierCin7}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateSupplier(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.supplier}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventories(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventories}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventoriesCin7(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventoriesCin7}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInsurances(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.insurances}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateIcd10s(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.icd10s}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateProviders(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.providers}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateProviderLocations(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.providerLocations}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateForms(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.forms}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventoryForms(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventoryForms}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateOrderLineItems(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.orderLineItems}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateQuestions(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.questions}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateModifiers(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.modifiers}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateSynchronizationDate(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.synchronizationDate}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventoryLocation(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventoryLocation}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateSalesOrderCin7(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.salesOrderCin7}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateStock(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.stock}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateStockCin7(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.stockCin7}/${id}`,
      data,
      this.httpOptions
    );
  }

  updateInventoriesLocationRoute(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.inventoriesLocationRoute}/${id}`,
      data,
      this.httpOptions
    );
  }

  // --- Delete ---
  deletePatients(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.patients}/${id}`,
      this.httpOptions
    );
  }

  deleteAccounts(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.accounts}/${id}`,
      this.httpOptions
    );
  }

  deleteAllAccounts(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.all_accounts}/${id}`,
      this.httpOptions
    );
  }

  deleteAccountLocation(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.account_locations}/${id}`,
      this.httpOptions
    );
  }

  deleteInventoryInsuranceLoc(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventoryInsuranceLoc}/${id}`,
      this.httpOptions
    );
  }

  deletePhysicians(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.physicians}/${id}`,
      this.httpOptions
    );
  }

  deleteOrderForms(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.orderForms}/${id}`,
      this.httpOptions
    );
  }

  deleteOrders(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.orders}/${id}`,
      this.httpOptions
    );
  }

  deleteLocations(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.locations}/${id}`,
      this.httpOptions
    );
  }

  deleteLocationsCin7(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.locationsCin7}/${id}`,
      this.httpOptions
    );
  }

  deleteSupplierCin7(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.supplierCin7}/${id}`,
      this.httpOptions
    );
  }

  deleteSupplier(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.supplier}/${id}`,
      this.httpOptions
    );
  }

  deleteInventories(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventories}/${id}`,
      this.httpOptions
    );
  }

  deleteInventoriesCin7(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventoriesCin7}/${id}`,
      this.httpOptions
    );
  }

  deleteInsurances(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.insurances}/${id}`,
      this.httpOptions
    );
  }

  deleteIcd10s(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.icd10s}/${id}`,
      this.httpOptions
    );
  }

  deleteProviders(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.providers}/${id}`,
      this.httpOptions
    );
  }

  deleteProviderLocations(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.providerLocations}/${id}`,
      this.httpOptions
    );
  }

  deleteForms(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.forms}/${id}`,
      this.httpOptions
    );
  }

  deleteInventoryForms(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventoryForms}/${id}`,
      this.httpOptions
    );
  }

  deleteOrderLineItems(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.orderLineItems}/${id}`,
      this.httpOptions
    );
  }

  deleteQuestions(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.questions}/${id}`,
      this.httpOptions
    );
  }

  deleteModifiers(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.modifiers}/${id}`,
      this.httpOptions
    );
  }

  deleteSynchronizationDate(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.synchronizationDate}/${id}`,
      this.httpOptions
    );
  }

  deleteInventoryLocation(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventoryLocation}/${id}`,
      this.httpOptions
    );
  }

  deleteSalesOrderCin7(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.salesOrderCin7}/${id}`,
      this.httpOptions
    );
  }

  deleteStock(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.stock}/${id}`,
      this.httpOptions
    );
  }

  deleteStockCin7(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.stockCin7}/${id}`,
      this.httpOptions
    );
  }

  deleteInventoriesLocationRoute(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.inventoriesLocationRoute}/${id}`,
      this.httpOptions
    );
  }

  deleteAllInventoriesLocationRoute(): Observable<any> {
    return this.http.delete<any>(
      `${this.API_FORMS.deleteInventoriesLocationRoute}`,
      this.httpOptions
    );
  }
}
