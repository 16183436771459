<div
  class="sidebar-wrapper MenuContainer"
  [ngClass]="{ open: isSidebarOpen$ | async }"
>
  <img
    src="../../assets/images/ortho-logo.svg"
    class="logo"
    (click)="goToMain()"
  />
  <ul>
    <li class="active" *ngFor="let menuItem of menuItems; let i = index">
      <a
        [routerLink]="[menuItem.path]"
        class="{{ menuItem.page == urlPage ? 'active' : '' }}"
      >
        <div class="{{ menuItem.class }}"></div>
        <label>{{ menuItem.title | lang }}</label>
      </a>
    </li>
  </ul>
  <!-- <ul>
    <li>
      <a href="https://account.eligible.com/users/sign_in" target="_blank">{{ 'menu.eligible' | lang }}</a>
    </li>
		<li>
      <a href="https://www.nhxsconnect.com/eobResolve/login.aspx?ReturnUrl=/eobresolve/Default.aspx&brandid=2" target="_blank">{{ 'menu.pricer' | lang }}</a>
    </li>
  </ul> -->

  <div class="user">
    <label>
      {{ user_name.first }} {{ user_name.last }}
      <div class="logout" (click)="logout()"></div>
    </label>
  </div>
</div>
