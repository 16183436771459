<!-- show list form -->
<app-icd10-list
  class="ListContainer"
  [ngClass]="{ hiddenList: somethingToShow }"
></app-icd10-list>

<div class="forms-form" *ngIf="!somethingToShow">
  <!-- show create & edit form -->
  <div *ngIf="showCreateEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ 'icd10s.edit.title' | lang }}</h1>
        <div class="pull-right" *ngIf="notEditable">
          <button
            *ngIf="
              hasRole.admin ||
              hasRole.executive ||
              hasRole.site_manager ||
              hasRole.fitter
            "
            class="button delete-button"
            (click)="showPopupDelete()"
          >
            {{ 'icd10s.delete' | lang }}
          </button>
          <button
            *ngIf="
              hasRole.admin ||
              hasRole.executive ||
              hasRole.site_manager ||
              hasRole.fitter
            "
            class="button"
            (click)="goToEditIcd10(icd10Id)"
          >
            {{ 'icd10s.edit' | lang }}
          </button>
        </div>
      </div>
    </div>

    <form
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      class="step"
      novalidate
    >
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-xs-12"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <label>
              {{ 'icd10s.code' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="code"
              [(ngModel)]="icd10.c_icd_10_code"
              type="text"
              required
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'icd10s.description' | lang }}</label>
            <input
              name="description"
              [(ngModel)]="icd10.c_description"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" *ngIf="!notEditable">
            <button
              class="button next-button"
              type="submit"
              [disabled]="!f.form.valid"
            >
              {{
                isEditMode ? ('icd10s.save' | lang) : ('icd10s.submit' | lang)
              }}
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- show view form -->
  <div *ngIf="!showCreateEditForm">
    <div class="col-xs-12">
      <h1>{{ icd10.c_icd_10_code }}</h1>
      <div class="pull-right" *ngIf="notEditable">
        <button
          *ngIf="
            hasRole.admin ||
            hasRole.executive ||
            hasRole.site_manager ||
            hasRole.fitter
          "
          class="button delete-button"
          (click)="showPopupDelete()"
        >
          {{ 'icd10s.delete' | lang }}
        </button>
        <button
          *ngIf="
            hasRole.admin ||
            hasRole.executive ||
            hasRole.site_manager ||
            hasRole.fitter
          "
          class="button edit-button"
          (click)="goToEditIcd10(icd10Id)"
        >
          {{ 'icd10s.edit' | lang }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="first-info-container">
        <ul>
          <li class="hidden"></li>
          <li>
            <label>{{ 'icd10s.description' | lang }}</label>
            <p>{{ icd10.c_description ? icd10.c_description : '-' }}</p>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- show index form -->
<div class="forms-form" *ngIf="somethingToShow">
  <button
    *ngIf="hasRole.admin || hasRole.site_manager || hasRole.fitter"
    type="button"
    class="button create-button"
    (click)="goToCreateIcd10()"
  >
    {{ 'icd10s.icd10-form-new.title' | lang }}
  </button>
</div>
