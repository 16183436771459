import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  info: any = {};

  confirmText = '';
  errorDelete = false;

  constructor(
    private activeModal: NgbActiveModal,
    private shared: SharedService
  ) {}

  ngOnInit() {
    this.shared.currentPopupInfo.subscribe((info) => (this.info = info));
  }

  left() {
    if (
      (this.info.inputConfirm && this.confirmText.toLowerCase() !== 'delete') ||
      !this.info.inputConfirm
    ) {
      this.activeModal.close({ selected: 'RIGHT', data: this.info.data });
    } else {
      this.errorDelete = true;
    }
  }

  right() {
    if (
      (this.info.inputConfirm && this.confirmText.toLowerCase() === 'delete') ||
      !this.info.inputConfirm
    ) {
      this.activeModal.close({ selected: 'LEFT', data: this.info.data });
    } else {
      this.errorDelete = true;
    }
  }

  center() {
    if (
      (this.info.inputConfirm && this.confirmText.toLowerCase() !== 'delete') ||
      !this.info.inputConfirm
    ) {
      this.activeModal.close({ selected: 'CENTER', data: this.info.data });
    } else {
      this.errorDelete = true;
    }
  }

  fourth() {
    if (!this.info.inputConfirm) {
      this.activeModal.close({ selected: 'FOURTH', data: this.info.data });
    } else {
      this.errorDelete = true;
    }
  }
}
