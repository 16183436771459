import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css'],
})
export class InvoiceViewComponent implements OnInit {
  constructor() {}
  @Input() invoiced: string;
  @Input() invoiceNr: string;

  ngOnInit() {}
}
