<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchLocation"
      class="search-input"
      placeholder="search by company"
      (keyup)="$event.keyCode == 13 && getLocations(searchLocation)"
    />
    <button
      type="button"
      class="btn-link"
      (click)="getLocations(searchLocation)"
    >
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <ul>
    <li *ngFor="let location of locations">
      <label (click)="goToViewLocation(location._id)">
        {{ location.c_company }}
      </label>
    </li>
  </ul>
</div>
