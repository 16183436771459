import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth/auth.service';
import { LocationGroupsService } from '../../../services/location-groups/location-groups.service';

@Component({
  selector: 'app-location-groups-list',
  templateUrl: './location-groups-list.component.html',
  styleUrls: ['./location-groups-list.component.css'],
})
export class LocationGroupsListComponent implements OnInit {
  hasRole: any;
  modalInstance: any;
  locationGroupList: any[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private locationGroupsService: LocationGroupsService
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.loadLocationGroups();
  }

  gotoCreateLocationGroups() {
    // if(this.modalInstance){
    //   this.modalInstance.close();
    // }
    this.router.navigate(['forms/locationGroups/new']);
  }

  loadLocationGroups() {
    this.locationGroupsService
      .getLocationGroups()
      .subscribe((locationGroups) => {
        console.log('LocationGroups: ', locationGroups);
        this.locationGroupList = locationGroups.data;
      });
  }

  gotoViewLocationGroup(locationGroupId) {
    this.router.navigate([
      `forms/locationGroups/:id/view`,
      { id: locationGroupId },
    ]);
  }
}
