import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class ModifierService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;
  constants = CONSTANTS;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  getModifiers(input: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.modifiers}?limit=50&where={"c_name" : {"$regex" : "/` +
        input +
        `/i"}}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  getModifier(modifierId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.modifiers}/${modifierId}?expand[]=c_auto_populate_insurance.c_insurance`,
      this.httpOptions
    );
  }

  createModifier(modifier: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifiers}`,
      modifier,
      this.httpOptions
    );
  }

  updateModifier(modifierId: string, modifier: any): Observable<any> {
    delete modifier.acl;
    return this.http.put<any>(
      `${this.API.modifiers}/${modifierId}`,
      modifier,
      this.httpOptions
    );
  }

  createModifierHcpcs(modifierHcpcs: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierHcpcs}`,
      modifierHcpcs,
      this.httpOptions
    );
  }

  deleteModifierHcpcs(modifierId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.modifierHcpcs}/${modifierId}`,
      this.httpOptions
    );
  }

  deleteModifierInsurances(modifierId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.modifierInsurances}/${modifierId}`,
      this.httpOptions
    );
  }

  createModifierInsurance(modifierInsurance: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierInsurances}`,
      modifierInsurance,
      this.httpOptions
    );
  }

  createModifierQuestion(modifierQuestion: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierQuestions}`,
      modifierQuestion,
      this.httpOptions
    );
  }

  createModifierQuestionResponseOption(responseOption: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierQuestionResponseOptions}`,
      responseOption,
      this.httpOptions
    );
  }

  getModifierQuestionResponseOptions(
    modifierId: string,
    questionId: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.API.modifierQuestionResponseOptions}?expand[]=c_attaching_form_list.c_attaching_form&expand[]=c_attaching_modifier_list.c_attaching_modifier&where={"$and" : [{"c_modifier" : "` +
        modifierId +
        `"}, {"c_question" : "` +
        questionId +
        `"}]}`,
      this.httpOptions
    );
  }

  deleteModifierQuestion(questionId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.modifierQuestions}/${questionId}`,
      this.httpOptions
    );
  }

  getModifierQuestions(modifierId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.modifierQuestions}?expand[]=c_response_options.c_attaching_modifier&expand[]=c_response_options.c_attaching_form&where={"c_modifier": "` +
        modifierId +
        `"}`,
      this.httpOptions
    );
  }

  getModifierQuestionHcpcs(modifierId: string, questionId: string) {
    return this.http.get<any>(
      `${this.API.modifierQuestionHcpcs}?expand[]=c_hcpcs_code&where={"$and":[{"c_modifier": "` +
        modifierId +
        `"}, {"c_modifier_question" : "` +
        questionId +
        `"}]}`,
      this.httpOptions
    );
  }

  getModifierQuestionInsurances(modifierId: string, questionId: string) {
    return this.http.get<any>(
      `${this.API.modifierQuestionInsurances}?expand[]=c_insurance&where={"$and":[{"c_modifier": "` +
        modifierId +
        `"}, {"c_modifier_question" : "` +
        questionId +
        `"}]}`,
      this.httpOptions
    );
  }

  createModifierQuestionHcpcs(modifierQuestionHcpcs: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierQuestionHcpcs}`,
      modifierQuestionHcpcs,
      this.httpOptions
    );
  }

  createModifierQuestionInsurance(
    modifierQuestionInsurance: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.API.modifierQuestionInsurances}`,
      modifierQuestionInsurance,
      this.httpOptions
    );
  }

  getModifierHcpcs(modifierId: string): Observable<any> {
    const self = this;
    let allCodes = [];
    let promise = new Subject();
    let page = 0;
    const filter = `expand[]=c_hcpcs_code&where={"c_modifier" : "` + modifierId + `"}`;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.modifierHcpcs}?${filter}&limit=100&skip=` + page * 100,
          self.httpOptions
        )
        .subscribe((response) => {
          allCodes = allCodes.concat(response.data);
          if (response.hasMore) {
            page++;
            loadAll();
          } else {
            promise.next({ data: allCodes });
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getModifierInsurances(modifierId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.modifierInsurances}?expand[]=c_insurance&where={"c_modifier" : "` +
        modifierId +
        `"}`,
      this.httpOptions
    );
  }

  deleteModifier(modifierId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.modifiers}/${modifierId}`,
      this.httpOptions
    );
  }

  // Return Object containing all the information related to modifier
  getEntireModifier(modifierId: string): Observable<any> {
    let localSubject = new Subject();
    let baseObject = {};
    const allRequests = [];

    allRequests.push(this.getModifier(modifierId));
    allRequests.push(this.getModifierHcpcs(modifierId));
    allRequests.push(this.getModifierInsurances(modifierId));
    allRequests.push(this.getEntireModifierQuestions(modifierId));

    forkJoin(allRequests).subscribe((response) => {
      // All requests done.
      baseObject = {
        _id: response[0]['_id'],
        c_name: response[0]['c_name'],
        c_warning: response[0]['c_warning'],
        c_hcpcs_codes: response[1]['data'].map((rd) => rd.c_hcpcs_code),
        c_insurances: response[2]['data'].map((rd) => rd.c_insurance),
        questions: response[3],
        c_all_hcpcs_codes: response[0]['c_all_hcpcs_codes'],
        c_auto_populate_insurance: response[0]['c_auto_populate_insurance'],
      };

      localSubject.next(baseObject);
      localSubject.complete();
    });

    return localSubject;
  }

  // Return list of modifier's questions containing all the infor related to modifier's questions
  getEntireModifierQuestions(modifierId: string): Observable<any> {
    let localSubject = new Subject();
    let expectedRequests = -1;
    let questionList = [];
    // Get all questions of given modifier
    this.getModifierQuestions(modifierId).subscribe((response) => {
      if (!response.data.length) {
        localSubject.next([]);
        localSubject.complete();
      }
      expectedRequests = response.data.length;
      // For every question, get all the information related and push them into questionList array
      response.data.map((mQuestion) => {
        this.getEntireModifierQuestion(modifierId, mQuestion).subscribe(
          (entireQuestion) => {
            questionList.push(entireQuestion);
            checkRemainig();
          }
        );
      });
    });
    // Return response only when all async requests are done
    function checkRemainig() {
      expectedRequests--;
      if (expectedRequests == 0) {
        localSubject.next(questionList);
        localSubject.complete();
      }
    }

    return localSubject;
  }

  // Get all the information related to modifier's question
  getEntireModifierQuestion(
    modifierId: string,
    question: any
  ): Observable<any> {
    let localSubject = new Subject();
    let baseObject = {
      _id: question._id,
      c_question_text: question.c_question_text,
    };
    const allRequests = [];

    allRequests.push(this.getModifierQuestionHcpcs(modifierId, question._id));
    allRequests.push(
      this.getModifierQuestionInsurances(modifierId, question._id)
    );
    allRequests.push(
      this.getModifierQuestionResponseOptions(modifierId, question._id)
    );

    forkJoin(allRequests).subscribe((response) => {
      baseObject['c_hcpcs_codes'] = response[0]['data'].map(
        (rd) => rd.c_hcpcs_code
      );
      baseObject['c_insurances'] = response[1]['data'].map(
        (rd) => rd.c_insurance
      );
      baseObject['response_options'] = response[2]['data'].map((rd) => {
        return {
          c_response_option_text: rd.c_response_option_text,
          c_attaching_form_list: rd.c_attaching_form_list.map(
            (af) => af.c_attaching_form
          ),
          c_attaching_modifier_list: rd.c_attaching_modifier_list.map(
            (am) => am.c_attaching_modifier
          ),
        };
      });

      localSubject.next(baseObject);
      localSubject.complete();
    });

    return localSubject;
  }

  getAllHcpcsCodesMedable(): Observable<any> {
    const self = this;
    let allCodes = [];
    let promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allCodes = allCodes.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getInventories!');
            promise.next(allCodes);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getAllInsurancesMedable(): Observable<any> {
    const self = this;
    let allInsurances = [];
    let promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.insurances}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allInsurances = allInsurances.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getAllInsurances!');
            promise.next(allInsurances);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getAllFormsMedable(): Observable<any> {
    const self = this;
    let allForms = [];
    let promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.forms}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allForms = allForms.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getAllForms!');
            promise.next(allForms);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getAllModifiersMedable(): Observable<any> {
    const self = this;
    let allModifiers = [];
    let promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.modifiers}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allModifiers = allModifiers.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getAllModifiers!');
            promise.next(allModifiers);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  deleteHcpcsCode(modifierId: string, data): Observable<any> {
    return this.http.patch<any>(
      `${this.API.modifiers}/${modifierId}`,
      data,
      this.httpOptions
    );
  }
}
