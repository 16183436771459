import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css'],
})
export class LocationListComponent implements OnInit {
  locations: any = [];
  stateEdit = false;
  hasRole: any;
  searchLocation: any;
  locationIds: any = [];

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private locationService: LocationService,
    private router: Router,
    private formsService: FormsService,
  ) {}

  ngOnInit() {
    if (this.state.is('forms.locationsEdit')) {
      this.stateEdit = true;
    }

    this.hasRole = this.authService.getAuthorizedUserRole();
    if (this.noMoreThanSiteManager(this.hasRole)) {
      this.formsService.get('accounts').subscribe((currentAccount) => {
        const accId = currentAccount.data[0]._id;
        const lQuery = '?where={"c_fitter": "' + accId + '"}&limit=1000';
        this.formsService
          .get('account_locations', lQuery)
          .subscribe((account_locations) => {
            this.locationIds = account_locations.data.map(
              (al) => `"${al.c_location._id}"`
            );
            this.getLocations('');
          });
      });
    } else {
      this.getLocations('');
    }
  }

  noMoreThanSiteManager(rolesObject) {
    return (
      !rolesObject.admin && !rolesObject.executive && rolesObject.site_manager
    );
  }

  getLocations(input) {
    let queryParam = '';
    if (this.locationIds.length) {
      queryParam = `{"_id": {"$in": [${this.locationIds}]}}`;
    }

    this.locationService.searchLocations(input, queryParam).subscribe(
      (locations) => {
        this.locations = this.sortLocations(locations.data);
      },
      (error) => {
        console.error(error.reason);
      }
    );
  }

  goToViewLocation(locationId) {
    this.router.navigate(['forms/locations/:id/view', { id: locationId }]);
  }

  sortLocations(locations) {
    const sorted = locations.sort((a, b) => {
      const aName = a.c_company.trim().toLowerCase();
      const bName = b.c_company.trim().toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }
}
