import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidebarService {
  private isOpenSubject$ = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject$.asObservable();

  constructor() {}

  toggle() {
    this.isOpenSubject$.next(!this.isOpenSubject$.value);
  }
}
