import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class AuthService {
  user: any = null;
  API: any = ENDPOINTS.auth;
  API_FORMS: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  setAuthorizedUser(user) {
    this.user = user;
    // this.$rootScope.user_roles = this.user.roles;
    window.localStorage.setItem('authenticated', 'true');
    window.localStorage.setItem(
      'authenticated-user',
      JSON.stringify(this.user)
    );
  }

  getAuthorizedUser() {
    const authenticated = window.localStorage.getItem('authenticated');
    if (authenticated && authenticated === 'true') {
      // console.log("This user: ", this.user);
      return (
        this.user ||
        JSON.parse(window.localStorage.getItem('authenticated-user'))
      );
    }
    return null;
  }

  getAuthorizedUserRole() {
    const user = this.getAuthorizedUser();
    console.log("🚀 ~ file: auth.service.ts ~ line 50 ~ AuthService ~ getAuthorizedUserRole ~ user", user)
    const toReturn: any = {};
    if (user?.roles) {
      toReturn.admin =
      user.roles.indexOf(CONSTANTS.ADMIN) != -1 ||
      user.roles.indexOf(CONSTANTS.DEVELOPER) != -1 ||
      user.roles.indexOf(CONSTANTS.SUPPORT) != -1;
      toReturn.fitter = user.roles.indexOf(CONSTANTS.FITTER) != -1;
      toReturn.executive = user.roles.indexOf(CONSTANTS.EXECUTIVE) != -1;
      toReturn.site_manager = user.roles.indexOf(CONSTANTS.SITE_MANAGER) != -1;
    }
    console.log("🚀 ~ file: auth.service.ts ~ line 52 ~ AuthService ~ getAuthorizedUserRole ~ toReturn", toReturn)
    return toReturn;
  }

  login(userCredentials): Observable<any> {
    const params = JSON.stringify({
      email: userCredentials.email,
      password: userCredentials.password,
      location: {
        verificationToken: userCredentials.location
          ? userCredentials.location.verificationToken
          : '',
      },
    });
    return this.http.post<any>(`${this.API.login}`, params, this.httpOptions);
  }

  logout(): Observable<any> {
    this.user = null;
    window.localStorage.removeItem('authenticated');
    window.localStorage.removeItem('authenticated-user');
    return this.http.post<any>(`${this.API.logout}`, '', this.httpOptions);
  }

  requestPasswordReset(userEmail): Observable<any> {
    const params = JSON.stringify({
      email: userEmail,
    });
    return this.http.post<any>(
      `${this.API.requestPasswordReset}`,
      params,
      this.httpOptions
    );
  }

  resetPassword(passwordResetToken, userPassword): Observable<any> {
    const params = JSON.stringify({
      token: passwordResetToken,
      password: userPassword,
    });
    return this.http.post<any>(
      `${this.API.resetPassword}`,
      params,
      this.httpOptions
    );
  }

  get(destination, query = ''): Observable<any> {
    return this.http.get<any>(
      `${this.API_FORMS[destination] + query}`,
      this.httpOptions
    );
  }

  // setExternalAccessCode(accessCode): Observable<any> {
  //   return this.http.post<any>(`${this.API_FORMS.externalAccessCode}`, accessCode, this.httpOptions);
  // }

  findExternalAccessCode(accessCode: string): Observable<any> {
    const query = `where={"c_access_code": "${accessCode}"}&limit=1`;
    return this.http.get<any>(
      `${this.API_FORMS.externalAccessCode}?${query}`,
      this.httpOptions
    );
  }

  updateExternalAccessCode(
    codeId: string,
    newAccessCode: any
  ): Observable<any> {
    return this.http.put<any>(
      `${this.API_FORMS.externalAccessCode}/${codeId}`,
      newAccessCode,
      this.httpOptions
    );
  }
}
