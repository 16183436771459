<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchInsurance"
      class="search-input"
      placeholder="search by name"
      (keyup)="$event.keyCode == 13 && getInsurances(searchInsurance)"
    />
    <button
      type="button"
      class="btn-link"
      (click)="getInsurances(searchInsurance)"
    >
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <div class="container-button">
    <a
      class="button create"
      *ngIf="hasRole.admin && !stateCreate"
      (click)="goToCreateInsurance()"
    >
      {{ 'insurances.insurance-form-new.title' | lang }}
    </a>
  </div>
  <ul>
    <li *ngFor="let insurance of insurances">
      <label (click)="goToViewInsurance(insurance._id)">
        {{ insurance.c_insurance_name }}
      </label>
    </li>
  </ul>
</div>
