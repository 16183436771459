<div class="forgot-password">
  <div class="auth-form_container">
    <form (ngSubmit)="onSubmit()">
      <div class="form-content">
        <div *ngIf="!passwordResetRequested">
          <div class="auth-form_container-welcome_message-wrapper">
            <h3>{{ 'forgot-password.form.title' | lang }}</h3>
          </div>
          <div class="auth-form_container-inputs-wrapper">
            <div class="form-row">
              <input
                class="rounded"
                name="email"
                placeholder="{{
                  'forgot-password.form.placeholder.email' | lang
                }}"
                [(ngModel)]="email"
                type="email"
                required
              />
              <button class="submit-btn" type="submit">
                <img src="/assets/images/arrow-right.png" />
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="passwordResetRequested" class="confirmation-message">
          <div class="form-content">
            <div class="auth-form_container-welcome_message-wrapper">
              <h3>{{ 'forgot-password.message.success' | lang }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="form-actions">
        <a [routerLink]="['/login']">
          <i class="arrow-left"></i>
          {{ 'auth.label.go_back_to_login' | lang }}
        </a>
      </div>
    </form>
  </div>
</div>
