import { Injectable } from '@angular/core';
import { IMyDate, IMyDateModel } from 'angular-mydatepicker';
import dateFnsFormat from 'date-fns/format';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  toDatepickerFormat(date: Date): IMyDate {

    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate(),
    };
  }

  toDate(myDate: IMyDate) {
    return new Date(myDate.year, myDate.month - 1, myDate.day);
  }

  toUTCDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  toDatepickerModel(date: Date): IMyDateModel {
    return {
      isRange: false,
      singleDate: {
        date: this.toDatepickerFormat(date),
      },
    };
  }

  format(dateModel: IMyDateModel, dateFormat = 'yyyy-MM-dd') {
    if (dateModel) {
      if (dateModel.singleDate.jsDate) {
        return dateFnsFormat(dateModel.singleDate.jsDate, dateFormat);
      } else {
        return dateFnsFormat(
          this.toDate(dateModel.singleDate.date),
          dateFormat
        );
      }
    }
    return null;
  }
}
