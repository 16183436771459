import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';

import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { AuthComponent } from './components/auth/auth.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormsComponent } from './components/forms/forms.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { FittersComponent } from './components/fitters/fitters.component';
import { Icd10ListComponent } from './components/icd10-list/icd10-list.component';
import { Icd10Component } from './components/icd10/icd10.component';
import { InsuranceListComponent } from './components/insurance-list/insurance-list.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { LocationListComponent } from './components/location-list/location-list.component';
import { LocationComponent } from './components/location/location.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { PatientComponent } from './components/patient/patient.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProviderListComponent } from './components/provider-list/provider-list.component';
import { ProviderComponent } from './components/provider/provider.component';

import { LanguagePipe } from './pipes/language/language.pipe';

import { ActiveStateService } from './services/active-state/active-state.service';
import { AlertService } from './services/alert/alert.service';
import { AuthService } from './services/auth/auth.service';
import { DataService } from './services/data/data.service';
import { FittersService } from './services/fitters/fitters.service';
import { FormService } from './services/form/form.service';
import { FormsService } from './services/forms/forms.service';
import { HcpcsService } from './services/hcpcs/hcpcs.service';
import { Icd10Service } from './services/icd10/icd10.service';
import { InsuranceService } from './services/insurance/insurance.service';
import { LocationService } from './services/location/location.service';
import { ProviderService } from './services/provider/provider.service';
import { RuleService } from './services/rule/rule.service';
import { SharedService } from './services/shared/shared.service';
import { SidebarService } from './services/sidebar/sidebar.service';
import { StockService } from './services/stock/stock.service';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { BulkOperationsComponent } from './components/bulk-operations/bulk-operations.component';
import { CustomAccessRouteComponent } from './components/custom-access-route/custom-access-route.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DatepickerPopupComponent } from './components/datepicker-popup/datepicker-popup.component';
import { FormListComponent } from './components/form-list/form-list.component';
import { FormComponent } from './components/form/form.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LocationGroupsCreateComponent } from './components/location-groups/location-groups-create/location-groups-create.component';
import { LocationGroupsEditComponent } from './components/location-groups/location-groups-edit/location-groups-edit.component';
import { LocationGroupsIndexComponent } from './components/location-groups/location-groups-index/location-groups-index.component';
import { LocationGroupsListComponent } from './components/location-groups/location-groups-list/location-groups-list.component';
import { LocationGroupsMainComponent } from './components/location-groups/location-groups-main/location-groups-main.component';
import { LocationGroupsViewComponent } from './components/location-groups/location-groups-view/location-groups-view.component';
import { ModifierListComponent } from './components/modifier-list/modifier-list.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderComponent } from './components/order/order.component';
import { InvoiceViewComponent } from './components/order/parts/invoice-view/invoice-view.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { getErrorHandler } from './errorHandler';
import { ArraysortPipe } from './pipes/arraysort/arraysort.pipe';
import { KeysPipe } from './pipes/keyspipe/keyspipe.pipe';
import { InventoryService } from './services/inventory/inventory.service';
import { LoadingInterceptor } from './services/loading/loading.interceptor';
import { LoadingService } from './services/loading/loading.service';
import { LocationGroupsService } from './services/location-groups/location-groups.service';
import { ModifierService } from './services/modifier/modifier.service';
import { OrderService } from './services/order/order.service';
import { OrderpdfService } from './services/orderpdf/orderpdf.service';
import { PatientService } from './services/patient/patient.service';
import { SupplierService } from './services/supplier/supplier.service';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AlertComponent,
    FormsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    LanguagePipe,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PopupComponent,
    Icd10Component,
    Icd10ListComponent,
    InsuranceComponent,
    InsuranceListComponent,
    ProviderComponent,
    ProviderListComponent,
    LocationComponent,
    LocationListComponent,
    FittersComponent,
    PatientComponent,
    PatientListComponent,
    DatepickerPopupComponent,
    InventoryListComponent,
    InventoryComponent,
    FormComponent,
    FormListComponent,
    KeysPipe,
    ModifierComponent,
    ModifierListComponent,
    OrderComponent,
    OrderListComponent,
    CustomPopupComponent,
    DashboardComponent,
    ArraysortPipe,
    ReportingComponent,
    CustomAccessRouteComponent,
    BulkOperationsComponent,
    LocationGroupsMainComponent,
    LocationGroupsListComponent,
    LocationGroupsIndexComponent,
    LocationGroupsViewComponent,
    LocationGroupsEditComponent,
    LocationGroupsCreateComponent,
    InvoiceViewComponent,
    LoadingScreenComponent,
  ],
  entryComponents: [PopupComponent, CustomPopupComponent],
  imports: [
    NgbModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    OrderModule,
    Angular2SignaturepadModule,
    BarecodeScannerLivestreamModule,
    ZXingScannerModule,
    AngularMyDatePickerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: getErrorHandler(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    AlertService,
    AuthService,
    ActiveStateService,
    DataService,
    SharedService,
    FormsService,
    Icd10Service,
    InsuranceService,
    ProviderService,
    LocationService,
    StockService,
    FittersService,
    PatientService,
    InventoryService,
    SupplierService,
    FormService,
    ModifierService,
    RuleService,
    OrderService,
    OrderpdfService,
    LanguagePipe,
    ArraysortPipe,
    LocationGroupsService,
    HcpcsService,
    SidebarService,
    LoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
