<div class="forms-form" style="width: calc(100% - 30px)">
  <div class="row">
    <div class="col-xs-12">
      <h1>Filter Orders</h1>
      <div class="pull-right">
        <button
          type="button"
          class="button edit-button"
          (click)="exportDashboardItems()"
          [disabled]="formattedLineItems.length == 0"
        >
          {{ 'dashboard.export' | lang }}
        </button>
      </div>
    </div>
  </div>

  <!-- Orders Form -->
  <form
    name="dashboard"
    #dashboard="ngForm"
    (ngSubmit)="dashboard.form.valid && getTableItems()"
    novalidate
  >
    <!-- Order Date Filters  -->
    <div class="row">
      <div class="col-xs-12">
        <label>Created Date</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min date"
            angular-mydatepicker
            name="minDate"
            [(ngModel)]="minDateModel"
            [options]="dpOptions"
            #dpMin="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max date"
            angular-mydatepicker
            name="maxDate"
            [(ngModel)]="maxDateModel"
            [options]="dpOptions"
            #dpMax="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Billed Date Filters -->

    <!-- <div class="row ll-mt-20"> -->
    <!-- <div class="col-xs-12">
          <label>Billed Date Range:</label>
        </div> -->

    <!-- Date Input - minDate -->
    <!-- <div class="col-xs-12 col-sm-5 col-md-3">
          
          <div class="input-group datepicker-group">
            <input class="form-control datepicker-input" style="float:none" placeholder="Select min (Billed) date" ngx-mydatepicker name="minBilledDate"
                    [(ngModel)]="minBilledDate" [options]="dpGeneral" #dpMinB="ngx-mydatepicker"/>
  
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="dpMinB.clearDate()">
                    <i class="glyphicon glyphicon-remove"></i>
                </button>
                <button type="button" class="btn btn-default" (click)="dpMinB.toggleCalendar()">
                    <i class="glyphicon glyphicon-calendar"></i>
                </button>
            </span>
          </div>
        </div> -->

    <!-- Date Input - maxDate -->
    <!-- <div class="col-xs-12 col-sm-5 col-md-3">
          
          <div class="input-group datepicker-group">
            <input class="form-control datepicker-input" style="float:none" placeholder="Select max (Billed) date" ngx-mydatepicker name="maxBilledDate"
                    [(ngModel)]="maxBilledDate" [options]="dpGeneral" #dpMaxB="ngx-mydatepicker"/>
  
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="dpMaxB.clearDate()">
                    <i class="glyphicon glyphicon-remove"></i>
                </button>
                <button type="button" class="btn btn-default" (click)="dpMaxB.toggleCalendar()">
                    <i class="glyphicon glyphicon-calendar"></i>
                </button>
            </span>
          </div>
        </div> -->

    <!-- </div> -->

    <!-- Location Filter Listing -->
    <div class="row">
      <!-- Location  -->
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Location Groups:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="col-xs-12 ll-item-list-container">
              <div
                class="ll-list-item"
                *ngFor="let locationGroup of addedLocationGroups; let i = index"
              >
                <div class="ll-list-item-text">
                  {{ locationGroup.c_group_name }}
                </div>
                <div
                  class="ll-list-item-delete"
                  (click)="removeLocationGroup(i)"
                >
                  x
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Location Groups -->
    <!-- Location  -->
    <div class="col-sm-6 col-md-3">
      <div class="row">
        <div class="col-xs-12 ll-mt-20">
          <label>Location Groups:</label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
          <input
            type="text"
            name="searchLocationGroup"
            placeholder="Select Location Group"
            id="autocomplete_location_group"
            class="highlight"
            [ngModel]="selectedLocationGroup"
            (focus)="locationGroupInputFocus()"
            (focusout)="locationGroupInputFocusOut()"
            [ngbTypeahead]="searchLocationGroupWrapper()"
            [resultTemplate]="autocompleteLocationGroupList"
            [inputFormatter]="locationGroupFormatter"
          />
          <ng-template
            #autocompleteLocationGroupList
            let-r="result"
            let-t="term"
          >
            <li (click)="selectLocationGroup(r)">{{ r.c_group_name }}</li>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="row">
      <!-- SKU  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Status:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="selectedStatus" name="statusDropdown">
              <option *ngFor="let fs of filterStatuses" [ngValue]="fs">
                {{ fs }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Invoiced:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="invoicedStatus" name="invoiceDropdown">
              <option *ngFor="let is of invoiceStatuses" [ngValue]="is.label">
                {{ is.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Button -->
    <div class="row">
      <div class="col-xs-12 col-sm-2 col-md-2">
        <button
          type="submit"
          class="button next-button ll-ml-0 ll-no-float"
          [ngClass]="{ 'small-txt': !allDataLoaded }"
          [disabled]="!searchFormValid() || !allDataLoaded"
        >
          {{ allDataLoaded ? 'SEARCH' : 'Please wait...' }}
        </button>
      </div>
    </div>
  </form>
  <!-- END Orders Form -->

  <hr />

  <!-- Update Orders Section -->
  <div class="row">
    <div class="col-xs-12">
      <h1>Update Orders</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-6">
      <input
        type="text"
        placeholder="Enter Invoice Number"
        [(ngModel)]="gInvoiceNumber"
      />
    </div>
    <div class="col-xs-12 custom-err-msg-container">
      <p>
        <span class="error-message">{{ errorMessage }}</span>
      </p>
      <p>
        <span class="progress-message">{{ progressMessage }}</span>
      </p>
      <p>
        <span class="success-message">{{ successMessage }}</span>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="pull-left">
      <div class="button next-button" (click)="updateCheckedLines()">
        UPDATE CHECKED
      </div>
      <div class="button next-button" (click)="checkAllLineItems()">
        CHECK ALL
      </div>
      <div class="button next-button" (click)="uncheckAllLineItems()">
        UNCHECK ALL
      </div>
    </div>
  </div>

  <!-- <div class="row">
      <div class="col-xs-6 col-md-3">
        <div class="button next-button">UPDATE CHECKED</div>
      </div>
    </div> -->

  <div class="row separate-top">
    <ul class="reporting-table">
      <li>
        <ul class="ll-table-header">
          <li>Checked</li>
          <li>Invoiced</li>
          <li>Invoice Number</li>
          <li>{{ 'dashboard.order' | lang }}</li>
          <li>{{ 'dashboard.system_date' | lang }}</li>
          <li>{{ 'dashboard.date' | lang }}</li>
          <li>{{ 'dashboard.status' | lang }}</li>
          <li>{{ 'dashboard.billed_date' | lang }}</li>
          <li>{{ 'dashboard.claim_id' | lang }}</li>
          <li>{{ 'dashboard.patient_mrn' | lang }}</li>
          <li>{{ 'dashboard.patient_name' | lang }}</li>
          <li>{{ 'dashboard.creator.name' | lang }}</li>
          <li>{{ 'dashboard.fitter.name' | lang }}</li>
          <li>{{ 'dashboard.billingProvider.name' | lang }}</li>
          <li>{{ 'dashboard.hcpcs' | lang }}</li>
          <li>{{ 'dashboard.item_name' | lang }}</li>
          <li>{{ 'dashboard.product_option_code' | lang }}</li>
          <li>{{ 'dashboard.quantity' | lang }}</li>
          <li>{{ 'dashboard.size' | lang }}</li>
          <li>{{ 'dashboard.vendor' | lang }}</li>
          <li>{{ 'orders.order-form.label.orderingProvider' | lang }}</li>
          <li>{{ 'dashboard.location' | lang }}</li>
          <li>{{ 'dashboard.primary_insurance' | lang }}</li>
          <li>{{ 'dashboard.primary_insurance_id' | lang }}</li>
          <li>{{ 'dashboard.secondary_insurance' | lang }}</li>
          <li>{{ 'dashboard.secondary_insurance_id' | lang }}</li>
          <li>{{ 'dashboard.charge_out_cost' | lang }}</li>
          <li>{{ 'dashboard.allowable_cost' | lang }}</li>
          <li>{{ 'dashboard.patient_payment' | lang }}</li>
          <li>{{ 'dashboard.sales_tax' | lang }}</li>
          <li>{{ 'dashboard.modifiers' | lang }}</li>
          <li>{{ 'dashboard.warnings' | lang }}</li>
          <!-- <li>{{ 'dashboard.icds' | lang }}</li> -->
          <li>orderID</li>
          <li>itemId</li>
          <li>{{ 'dashboard.lastModified' | lang }}</li>
        </ul>
      </li>
      <!-- <div *ngIf="orderLineItemsFilter.length && lineItemsLoaded">
          <li *ngFor="let line of orderLineItemsFilter" (click)="goToViewOrder(line.order._id)" class="clickable-item">
            <ul>
              <li>{{ line.order.c_number_id }}</li>
              <li>{{ line.order.display_order_date}}</li>
              <li>{{ line.order.status }} </li>
              <li>{{ line.order.display_billed_date }}</li>
              <li>{{ line.order.c_claim_id ? line.order.c_claim_id : '-'}}</li>
              <li>{{ line.order.c_patient.c_mrn }}</li>
              <li>{{ line.order.c_patient.c_name.c_first_name + ' ' + line.order.c_patient.c_name.c_last_name }}</li>
              <li>{{ line.order.creatorName }}</li>
              <li>{{ line.order.fitterName }}</li>
              <li>{{ line.c_product.c_hcpcs_code }}</li>
              <li>{{ line.c_product.c_name }}</li>
              <li>{{ line.c_product_option_code ? line.c_product_option_code : '-'}}</li>
              <li>{{ line.c_quantity }}</li>
              <li>{{ line.c_product.cpy_product_option != undefined ? line.c_product.cpy_product_option.c_size : '-' }}</li>
              <li>{{ line.c_product.c_supplier_id != undefined ? line.c_product.c_supplier_id.c_company : '-' }}</li>
              <li>{{ line.order.providerName ? line.order.providerName : '-'}}</li>
              <li>{{ line.order.c_provider_location.c_location != undefined ? line.order.c_provider_location.c_location.c_company : '-' }}</li>
              <li>{{ line.order.c_insurance.c_insurance_name }}</li>
              <li>{{ line.order.c_primary_insurance_id_number ? line.order.c_primary_insurance_id_number : '-' }}</li>
              <li>{{ line.order.c_secondary_insurance ? line.order.c_secondary_insurance.c_insurance_name : '-' }}</li>
              <li>{{ line.order.c_secondary_insurance_id_number ? line.order.c_secondary_insurance_id_number : '-' }}</li>
              <li>{{ '$' + line.c_charge_out_cost }}</li>
              <li>{{ '$' + line.c_allowable_cost }}</li>
              <li>{{ '$' + line.c_self_pay_cost }}</li>
              <li>{{ '$' + line.c_sales_tax }}</li>
              <li> {{ line.modifiers }} </li>
              <li>{{ line.icds_expanded.length ? line.icds_expanded.join(' | ') : '-' }}</li>
            </ul>
          </li>
        </div> -->
      <div *ngIf="lineItemsPage.length">
        <li *ngFor="let line of lineItemsPage; let idx = index">
          <ul>
            <li>
              <input
                type="checkbox"
                name="{{ 'mcheck_' + idx }}"
                class="radio-button"
                [(ngModel)]="line.checked"
              />
            </li>
            <li>{{ line.invoiced }}</li>
            <li>{{ line.invoiceNumber }}</li>
            <li>{{ line.order.numberId }}</li>
            <li>{{ line.order.systemDate }}</li>
            <li>{{ line.order.display_order_date }}</li>
            <li>{{ line.order.status }}</li>
            <li>{{ line.order.display_billed_date }}</li>
            <li>{{ line.order.claimId }}</li>
            <li>{{ line.order.patient.mrn }}</li>
            <li>{{ line.order.patient.fullName }}</li>
            <li>{{ line.order.creatorName }}</li>
            <li>{{ line.order.fitterName }}</li>
            <li>{{ line.order.billingProviderName }}</li>
            <li>{{ line.product.hcpcs }}</li>
            <li>{{ line.product.name }}</li>
            <li>{{ line.product.optionCode }}</li>
            <li>{{ line.quantity }}</li>
            <li>{{ line.product.size }}</li>
            <li>{{ line.product.vendor }}</li>
            <li>{{ line.order.providerName }}</li>
            <li>{{ line.order.providerLocation }}</li>
            <li>{{ line.order.primaryInsurance }}</li>
            <li>{{ line.order.primaryInsId }}</li>
            <li>{{ line.order.secondaryInsurance }}</li>
            <li>{{ line.order.secondaryInsId }}</li>
            <li>{{ '$' + line.chargeOutCost }}</li>
            <li>{{ '$' + line.allowableCost }}</li>
            <li>{{ '$' + line.selfPay }}</li>
            <li>{{ '$' + line.salesTax }}</li>
            <li>{{ line.modifiers }}</li>
            <li>{{ line.warnings }}</li>
            <!-- <li>{{ line.icds}}</li> -->
            <li>{{ line.order.id }}</li>
            <li>{{ line.itemId }}</li>
            <li>{{ line.order.lastModified }}</li>
          </ul>
        </li>
      </div>

      <li
        *ngIf="!orderLineItemsFilter.length && lineItemsLoaded"
        class="ll-pp-background"
      >
        <label>{{ 'dashboard.no_results' | lang }}</label>
      </li>
    </ul>
  </div>

  <!-- <div class="row paginator" *ngIf="pageCount.length > 1 && lineItemsLoaded">
      <button type="button" class="btn btn-default btn-sm paginator-buttons" (click)="prevPage()" [disabled]="actualPage == 0">
        <i class="glyphicon glyphicon-chevron-left"></i>
      </button>
  
      <ul>
        <div *ngIf="pageCount.length <= 7">
          <div *ngFor="let n of pageCount; let i=index" class="ll-page-number">
            <li [ngClass]="{'active': i == actualPage}" class="pagination-numbers" (click)="goToPage(i)">{{i + 1}}</li>
          </div>
        </div>
  
        <div *ngIf="pageCount.length > 7">
            <li [ngClass]="{'active': actualPage == 0}" class="pagination-numbers" (click)="goToPage(0)">{{1}}</li>
            <li class="dots" *ngIf="actualPage >= 4">...</li>
            <span *ngFor="let n of pageCount; let i=index" >
              <li *ngIf="i - actualPage <= 2 && actualPage - i <= 2 && i != 0 && i != pageCount.length - 1" [ngClass]="{'active': i == actualPage}" class="pagination-numbers" (click)="goToPage(i)">{{i + 1}}</li>
            </span>
            <li class="dots" *ngIf="pageCount.length - actualPage > 4">...</li>
            <li ngClass="{'active': actualPage == pageCount.length - 1}" class="pagination-numbers" (click)="goToPage(pageCount.length - 1)">{{pageCount.length}}</li>
        </div>
      </ul>
  
      <button type="button" class="btn btn-default btn-sm paginator-buttons" (click)="nextPage()" [disabled]="actualPage == pageCount.length - 1">
        <i class="glyphicon glyphicon-chevron-right"></i>
      </button>
  
    </div> -->
  <div class="row paginator" *ngIf="gPages > 0">
    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="prevPage()"
      [disabled]="actualPage == 1"
    >
      <i class="glyphicon glyphicon-chevron-left"></i>
    </button>

    <ul>
      <div *ngIf="gPageList.length <= 7">
        <div *ngFor="let n of gPageList; let i = index" class="ll-page-number">
          <li
            [ngClass]="{ active: i == actualPage - 1 }"
            class="pagination-numbers"
            (click)="loadPage(i + 1)"
          >
            {{ i + 1 }}
          </li>
        </div>
      </div>

      <div *ngIf="gPageList.length > 7">
        <li
          [ngClass]="{ active: actualPage == 1 }"
          class="pagination-numbers"
          (click)="loadPage(1)"
        >
          {{ 1 }}
        </li>

        <li class="dots" *ngIf="actualPage >= 4">...</li>

        <span *ngFor="let n of gPageList; let i = index">
          <li
            *ngIf="
              i + 1 - actualPage <= 2 &&
              actualPage - (i + 1) <= 2 &&
              i + 1 != 1 &&
              i + 1 != gPageList.length
            "
            [ngClass]="{ active: i + 1 == actualPage }"
            class="pagination-numbers"
            (click)="loadPage(i + 1)"
          >
            {{ i + 1 }}
          </li>
        </span>

        <li class="dots" *ngIf="gPageList.length - actualPage > 5">...</li>

        <li
          [ngClass]="{ active: actualPage == gPageList.length }"
          class="pagination-numbers"
          (click)="loadPage(gPageList.length)"
        >
          {{ gPageList.length }}
        </li>
      </div>
    </ul>

    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="nextPage()"
      [disabled]="actualPage == gPageList.length"
    >
      <i class="glyphicon glyphicon-chevron-right"></i>
    </button>
  </div>
</div>
