<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="patientName"
      class="search-input mb10"
      placeholder="search by patient name"
      (keyup)="$event.keyCode == 13 && getPatientsByInputs()"
    />
    <input
      [(ngModel)]="patientMrn"
      class="search-input mb10"
      placeholder="search by patient MRN"
      (keyup)="$event.keyCode == 13 && getPatientsByInputs()"
    />

    <div class="input-group datepicker-group datepicker-listcontainer mb10">
      <input
        class="form-control datepicker-input"
        style="float: none"
        angular-mydatepicker
        name="patient_search_dob"
        [(ngModel)]="dobModel"
        [options]="dpOptions"
        #psDob="angular-mydatepicker"
        placeholder="search patient dob"
      />

      <span class="input-group-btn">
        <button
          type="button"
          class="btn btn-default"
          (click)="psDob.clearDate()"
        >
          <i class="glyphicon glyphicon-remove"></i>
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="psDob.toggleCalendar()"
        >
          <i class="glyphicon glyphicon-calendar"></i>
        </button>
      </span>
    </div>

    <button type="button" class="btn-link" (click)="getPatientsByInputs()">
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>
  <!-- Serch Button -->
  <div class="container-button">
    <a class="button create" (click)="getPatientsByInputs()">Search Patients</a>
  </div>
  <div class="container-button">
    <a
      *ngIf="
        showCreateButton &&
        (hasRole.admin ||
          hasRole.executive ||
          hasRole.fitter ||
          hasRole.site_manager)
      "
      class="button create"
      (click)="goToCreatePatient()"
    >
      {{ 'patients.patient-form-new.title' | lang }}
    </a>
  </div>
  <ul>
    <li *ngFor="let patient of patients">
      <label (click)="goToViewPatient(patient._id)">
        {{ patient.c_mrn }} - {{ patient.c_name.c_first_name }}
        {{ patient.c_name.c_last_name }}
      </label>
    </li>
  </ul>
</div>
