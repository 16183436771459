import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-groups-index',
  templateUrl: './location-groups-index.component.html',
  styleUrls: ['./location-groups-index.component.css'],
})
export class LocationGroupsIndexComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
