<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchModifier"
      class="search-input"
      placeholder="search modifier"
      (keyup)="$event.keyCode == 13 && getModifiers(searchModifier)"
    />
    <button
      type="button"
      class="btn-link"
      (click)="getModifiers(searchModifier)"
    >
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <div class="container-button" *ngIf="!createOrEdit">
    <a
      class="button create"
      *ngIf="hasRole.admin && !stateCreate"
      (click)="goToCreateModifier()"
    >
      {{ 'modifier.create' | lang }}
    </a>
  </div>

  <ul>
    <li *ngFor="let modifier of modifiers | orderBy: '-created'">
      <label (click)="gotoViewModifier(modifier._id)">
        {{ modifier.c_name }}
      </label>
    </li>
  </ul>
</div>
