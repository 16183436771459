import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css'],
})
export class PatientListComponent implements OnInit {
  patients: any = [];
  stateEdit = false;
  hasRole: any;
  showCreateButton = true;
  searchPatient: any;

  patientName = '';
  patientMrn = '';

  dobModel: IMyDateModel;

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
  };


  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private patientService: PatientService,
    private router: Router,
    private dateService: DateService
  ) {}

  ngOnInit() {
    if (this.state.is('forms.patientsEdit')) {
      this.stateEdit = true;
    }

    if (this.state.is('forms.patientsCreate')) {
      this.showCreateButton = false;
    }

    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getPatients('');
  }

  getPatients(input) {
    // if (!(this.state.is('forms.patientsHistory') && this.state.is('forms.patientsHistory') && input == '')) {
    // }

    this.patientService.searchPatients(input).subscribe(
      (patients) => {
        this.patients = this.sortPatients(patients.data);
        console.log('Patients: ', this.patients);
      },
      (error) => {
        console.error(error.reason);
      }
    );
  }

  goToViewPatient(patientId) {
    this.router.navigate(['forms/patients/:id/view', { id: patientId }]);
  }

  goToCreatePatient() {
    this.router.navigate(['forms/patients/new']);
  }

  sortPatients(patients) {
    const sorted = patients.sort((a, b) => {
      const aStrRaw =
        a.c_name.c_first_name.trim() +
        a.c_name.c_last_name.trim() +
        a.c_mrn.trim();
      const aStr = aStrRaw.trim().replace(/\s+/gim, '').toLowerCase();
      const bStrRaw =
        b.c_name.c_first_name.trim() +
        b.c_name.c_last_name.trim() +
        b.c_mrn.trim();
      const bStr = bStrRaw.trim().replace(/\s+/gim, '').toLowerCase();

      if (aStr < bStr) {
        return -1;
      }
      if (aStr > bStr) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }

  // Additional Filters
  getPatientsByInputs() {
    const patientsFilter = this.buildPatientsFilter();
    this.patientService
      .getPatientsByFilter(patientsFilter)
      .subscribe((patients) => {
        console.log('Returned Patients: ', patients);
        this.patients = patients;
      });
  }

  buildPatientsFilter() {
    const filterList = [
      this.buildPatientNameFilter(),
      this.buildPatientMrnFilter(),
      this.buildPatientDobFilter(),
    ];

    if (filterList.filter((f) => f != '').length === 0) {
      return '';
    }

    const and = `"$and": [${filterList.filter((f) => f != '').join(',')}]`;
    const filter = `where={${and}}`;
    console.log('Filter: ', filter);
    return filter;
  }

  buildPatientMrnFilter() {
    let filter = '';
    if (this.patientMrn === '') {
      return filter;
    }

    filter = `{"c_mrn": "${this.patientMrn}"}`;

    return filter;
  }

  buildPatientDobFilter() {
    if (!this.dobModel) {
      return '';
    }

    const parsedDob = this.dateService.format(this.dobModel);
    const filter = `{"c_dob": "${parsedDob}"}`;
    return filter;
  }

  buildPatientNameFilter() {
    if (this.patientName === '') {
      return this.patientName;
    }
    const splittedName = this.patientName
      .split(' ')
      .filter((pn) => pn != '')
      .slice(0, 2);

    let condition = '';

    if (splittedName.length === 1) {
      const singleName = splittedName[0];
      const conditions = [
        `{"c_name.c_first_name": {"$regex":"/${singleName}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${singleName}/i"}}`,
      ];
      condition = `{"$or": [${conditions.join(',')}]}`;
    } else {
      const conditions = [
        `{"c_name.c_first_name": {"$regex":"/${splittedName[0]}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${splittedName[1]}/i"}}`,
        `{"c_name.c_first_name": {"$regex":"/${splittedName[1]}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${splittedName[0]}/i"}}`,
      ];

      const set1 = conditions.slice(0, 2).join(',');
      const set2 = conditions.slice(2, 4).join(',');

      condition = `{"$or": [{"$and": [${set1}]}, {"$and": [${set2}]}]}`;
    }

    return condition;
  }
}
