import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class StockService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  getStocks(): Observable<any> {
    return this.http.get<any>(`${this.API.stock}`, this.httpOptions);
  }

  getStock(locationId: string, optionsId: string): Observable<any> {
    const code = encodeURIComponent(optionsId);

    const locationFilter = `{"c_location": "${locationId}"}`,
      codeFilter = `{"c_product_option_code":"${code}"}`,
      where = `where={"$and":[${codeFilter},${locationFilter}]}`,
      query = `${this.API.stock}?${where}`;
    return this.http.get<any>(query, this.httpOptions);
  }

  getStockFromCin7(code: string): Observable<any> {
    console.log('entered getstockfrom cin7', code);
    const rCode = encodeURIComponent(code);
    console.log('Rcode: ', rCode);
    const query = `${this.API.stockCin7}?where=code LIKE '${rCode}'`;
    console.log('Query: ', query);
    return this.http.get<any>(query, this.httpOptions);
  }

  insertStock(newStock: any): Observable<any> {
    return this.http.post<any>(`${this.API.stock}`, newStock, this.httpOptions);
  }

  updateStock(newStock: any, stockId: string): Observable<any> {
    return this.http.put<any>(
      `${this.API.stock}/${stockId}`,
      newStock,
      this.httpOptions
    );
  }

  get1000Stock(): Observable<any> {
    return this.http.get<any>(
      `${this.API.stockCin7}?limit=1000`,
      this.httpOptions
    );
  }

  getLast10Stocks(locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.stock}?limit=10&where={"c_location": "${locationId}"}&sort={"c_modified_date": -1}&expand[]=c_product`,
      this.httpOptions
    );
  }

  deleteStock(stockId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.stock}/${stockId}`,
      this.httpOptions
    );
  }

  searchStock(input: any, locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.stock}?limit=10&where={"$and":[{"c_product_option_code":{"$regex":"/${input}/i"}},{"c_location":"${locationId}"}]}&sort={"_id": -1}&expand[]=c_product`,
      this.httpOptions
    );
  }
}
