import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { forkJoin, Subject } from 'rxjs';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormService } from '../../services/form/form.service';
import { FormsService } from '../../services/forms/forms.service';
import { OrderService } from '../../services/order/order.service';
import { PatientService } from '../../services/patient/patient.service';
import { SharedService } from '../../services/shared/shared.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styles: ['.header {position: relative !important;}'],
})
export class PatientComponent implements OnInit {
  patientId: string;
  showForm: boolean;
  hasRole: any;
  somethingToShow = false;
  duplicateError = false;
  patient: any;

  patientSubmited: boolean;
  viewPatient = false;
  hasOrders = false;

  selectedDob: any;
  isEditMode = false;
  patientHistory = false;

  patientOrders: any[] = [];
  modifierForms: any[] = [];
  patientOrdersTmp: any[] = [];

  dobModel: IMyDateModel;

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
  };

  allFitters: any[];

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private patientService: PatientService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private formsService: FormsService,
    private orderService: OrderService,
    private formService: FormService,
    private dateService: DateService
  ) {
    this.goToCreatePatient = this.goToCreatePatient.bind(this);
    this.patient = {
      c_name: {},
      c_address: {},
    };
  }

  ngOnInit() {
    this.getAllFitters();
    this.sub = this.route.params.subscribe((params) => {
      this.patientId = params.id ? params.id : '0';
      this.hasRole = this.authService.getAuthorizedUserRole();
      // If index, we show the create button.
      this.somethingToShow = this.state.is('forms.patientsIndex');

      if (this.state.is('forms.patientsCreate')) {
        this.showForm = true;
      }

      if (this.state.is('forms.patientsEdit')) {
        this.viewPatient = false;
        this.isEditMode = true;
        this.getPatient(() => {
          this.showEditForm();
        });
      }

      if (this.state.is('forms.patientsView')) {
        this.getPatient();
        this.getPatientOrders();
      }

      if (this.state.is('forms.patientsHistory')) {
        this.getPatient();
        this.getPatientOrders();
        this.patientHistory = true;
      }
    });
  }

  getPatientOrders() {
    this.hasOrders = false;
    this.orderService.getPatientsOrders(this.patientId).subscribe((orders) => {
      if (orders.data.length) {
        this.hasOrders = true;
      }
      this.getOrderLineItems(orders.data);
    });
  }

  getAllFitters() {
    this.formsService.get('allFitters', '').subscribe((allFitters) => {
      // console.log("All Fitters Loaded: ", allFitters);
      this.allFitters = allFitters.data;
    });
  }

  medableDisplayDate(medableDate) {
    const dateObj = new Date(medableDate);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();

    const displayMonth = month < 10 ? `0${month}` : month;
    const displayDay = day < 10 ? `0${day}` : day;

    return `${displayMonth}/${displayDay}/${year}`;
  }

  getOrderLineItems(orders) {
    const requests = [];

    orders.map((order) => {
      const baseObject = { ...order };
      console.log('BaseObject: ', baseObject);
      baseObject.display_date = this.medableDisplayDate(
        baseObject.c_order_date
      );
      const dataLoaded = this.getPatientOrderData(order._id, baseObject);
      requests.push(dataLoaded);
    });

    forkJoin(requests).subscribe(() => {
      this.patientOrders = this.sortOrders(this.patientOrdersTmp);
    });
  }

  sortOrders(orders) {
    return orders.sort(function (a, b) {
      const aNr = a.c_number_id;
      const bNr = b.c_number_id;

      if (aNr > bNr) {
        return -1;
      }
      if (aNr < bNr) {
        return 1;
      }
      return 0;
    });
  }

  getPatientOrderData(orderId, baseObject) {
    const localSubject = new Subject();
    const requests = [];
    const orderLineItemsLoaded = this.getPatientOrderLineItems(
      orderId,
      baseObject
    );
    requests.push(orderLineItemsLoaded);
    const orderFormsLoaded = this.getPatientOrderForms(orderId, baseObject);
    requests.push(orderFormsLoaded);

    forkJoin(requests).subscribe(() => {
      this.patientOrdersTmp.push(baseObject);
      localSubject.next();
      localSubject.complete();
    });

    return localSubject;
  }

  getPatientOrderForms(orderId, baseObject) {
    const localSubject = new Subject();
    const filter = 'where={"c_medable_order":"' + orderId + '"}';
    const expand =
      'expand[]=c_form&expand[]=c_responses.c_question&expand[]=c_product';
    const query = '?' + filter + '&' + expand;
    baseObject.orderForms = [];
    this.formsService
      .get('orderModifierForms', query)
      .subscribe((modifierForms) => {
        modifierForms.data.map((mff) => {
          baseObject.orderForms.push({
            c_modifier_form: {
              c_form: mff.c_form,
            },
            c_product: mff.c_product,
            c_responses: mff.c_responses,
            _id: mff._id,
          });
        });
        localSubject.next();
        localSubject.complete();
      });

    return localSubject;
  }

  getPatientOrderLineItems(orderId, baseObject) {
    const localSubject = new Subject();
    this.orderService.getOrderLineItems(orderId).subscribe((orderLineItems) => {
      baseObject.orderLineItems = orderLineItems.data;
      localSubject.next();
      localSubject.complete();
    });
    return localSubject;
  }

  // Navigate to create patient page
  goToCreatePatient() {
    this.router.navigate(['forms/patients/new']);
  }

  cancel() {
    this.router.navigate(['forms/patients/index']);
  }

  onSubmit() {
    this.duplicateError = false;
    /* only allow to create a patient if there aren't duplicated users */
    if (this.state.is('forms.patientsCreate')) {
      this.patientService
        .searchExistingPatient(this.patient)
        .subscribe((search) => {
          if (search.data.length) {
            this.showDuplicateError();
          } else {
            this.createPatient();
          }
        });
    } else {
      this.editPatient();
    }
  }

  showEditForm() {
    this.showForm = true;
  }

  createPatient() {
    const patient = {
      ...this.patient,
      c_dob: this.dateService.format(this.dobModel),
    };
    console.log('create patient: ', patient);
    this.patientService.createPatient(patient).subscribe((data) => {
      this.patientSubmited = true;
      this.goToViewPatient(data._id);
    });
  }

  editPatient() {
    const patient = {
      ...this.patient,
      c_dob: this.dateService.format(this.dobModel),
    };
    console.log('Editted Patient: ', patient);
    this.patientService
      .editPatient(this.patientId, patient)
      .subscribe((data) => {
        this.patientSubmited = true;
        this.goToViewPatient(data._id);
      });
  }

  showDuplicateError() {
    this.duplicateError = true;
  }

  goToViewPatient(patientId) {
    // console.log("viewPatient");
    this.router.navigate(['forms/patients/:id/view', { id: patientId }]);
  }

  gotoViewPatientHistory(patientId) {
    this.router.navigate([
      'forms/patients/history/:id/view',
      { id: patientId },
    ]);
  }

  getPatient(cb = () => {}) {
    /* only allow to create a patient if there aren't duplicated users */
    this.patientService.getPatient(this.patientId).subscribe((patient) => {
      // let parseDobToDate = new Date(patient.c_dob);
      // var userTimezoneOffset = parseDobToDate.getTimezoneOffset() * 60000;

      this.dobModel = this.dateService.toDatepickerModel(
        new Date(patient.c_dob)
      );
      this.patient = {
        c_name: {
          c_first_name: patient.c_name.c_first_name,
          c_last_name: patient.c_name.c_last_name,
        },
        c_mrn: patient.c_mrn,
        c_address: patient.c_address
          ? {
              c_street_address_1: patient.c_address.c_street_address_1
                ? patient.c_address.c_street_address_1
                : '',
              c_street_address_2: patient.c_address.c_street_address_2
                ? patient.c_address.c_street_address_2
                : '',
              c_city: patient.c_address.c_city ? patient.c_address.c_city : '',
              c_state: patient.c_address.c_state
                ? patient.c_address.c_state
                : '',
              c_postal_code: patient.c_address.c_postal_code
                ? patient.c_address.c_postal_code
                : '',
            }
          : {
              c_street_address_1: '',
              c_street_address_2: '',
              c_city: '',
              c_state: '',
              c_postal_code: '',
            },
        c_phone_number: patient.c_phone_number ? patient.c_phone_number : '',
      };

      console.log('This Patient ', this.patient);

      cb();
    });

    if (this.state.is('forms.patientsView')) {
      this.viewPatient = true;
    }
  }

  // Delete pacient
  deletePatient() {
    // Show confirmationpopup
    this.showPopupDelete().then((response) => {
      console.log('response: ', response);
      if (response.selected == 'LEFT') {
        console.log('Delete ', this.patientId);
        this.patientService.deletePatient(this.patientId).subscribe((data) => {
          this.gotoPatientsIndex();
        });
      }
    });
  }

  gotoPatientsIndex() {
    this.router.navigate(['forms/patients/index']);
  }

  showPopupDelete() {
    const info = {
      subtitle: 'forms.confirm.deleteStrong',
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
      // inputConfirm:true
    };

    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  showPopupLeave() {
    const info = {
      subtitle: 'forms.confirm.title',
      btnLeft: 'NO',
      btnLeftClass: 'delete-button',
      btnRight: 'YES',
      btnRightClass: 'yes',
      // inputConfirm:true
    };

    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  gotoEditPatient(patientId) {
    // console.log(patientId);
    this.viewPatient = false;
    this.router.navigate(['forms/patients/:id/edit', { id: patientId }]);
  }

  populateModifierForm(orderIndex, formIndex) {
    console.log(
      'Populating orderForm! orderIndex: ',
      orderIndex,
      ' formIndex: ',
      formIndex
    );
    const orderForm = this.patientOrders[orderIndex].orderForms[formIndex];
    console.log('OrderForm: ', orderForm);
    const inventoryId = orderForm.c_product._id;
    const formName = orderForm.c_modifier_form.c_form.c_name;
    const formId = orderForm.c_modifier_form.c_form._id;
    const orderLine = this.patientOrders[orderIndex].orderLineItems.filter(
      (obj) => obj.c_product._id === inventoryId
    )[0];
    const filteredOptions = orderLine.c_product.c_product_options.filter(
      (opt) => opt.c_code === orderLine.c_product_option_code
    );
    orderLine.c_product.cpy_product_option = filteredOptions[0];

    this.formService.getPDFForm(false, formId, formName, this.allFitters, {
      order: this.patientOrders[orderIndex],
      orderForm,
      orderLine,
    });
  }
}
