<div class="col-xs-12">
  <div class="col-xs-6">
    <h2>{{ locationGroup.c_group_name }}</h2>
  </div>
  <div class="pull-right">
    <button class="button delete-button" (click)="deleteLocationGroup()">
      DELETE GROUP
    </button>
    <button
      class="button edit-button"
      (click)="goToEditLocationGroup(locationGroupId)"
    >
      EDIT GROUP
    </button>
  </div>
</div>

<div class="col-xs-12 mt-30">
  <label>Locations in this group:</label>
  <div class="col-xs-12 ll-item-list-container" *ngIf="locationsInGroup.length">
    <div
      class="ll-list-item-noteditable"
      *ngFor="let location of locationsInGroup; let i = index"
    >
      <div class="ll-list-item-text">{{ location.c_company }}</div>
    </div>
  </div>
  <div class="col-xs-12" *ngIf="!locationsInGroup.length">
    <p>None</p>
  </div>
</div>
