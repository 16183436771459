<!-- show list form -->
<app-provider-list
  class="ListContainer"
  [ngClass]="{ hiddenList: somethingToShow }"
></app-provider-list>

<div class="forms-form" *ngIf="!somethingToShow">
  <!-- show create & edit form -->
  <div *ngIf="showCreateEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ 'providers.title' | lang }}</h1>
      </div>
    </div>

    <form
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      class="step"
      novalidate
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label>{{ 'providers.preffix' | lang }}</label>
            <input
              name="preffix"
              [(ngModel)]="provider.c_preffix"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-md-6"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <label>
              {{ 'providers.name.first_name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="first"
              [(ngModel)]="provider.c_name.c_first_name"
              type="text"
              required
              [disabled]="notEditable"
            />
          </div>
          <div
            class="col-xs-12 col-md-6"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <label>
              {{ 'providers.name.last_name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="last"
              [(ngModel)]="provider.c_name.c_last_name"
              type="text"
              required
              [disabled]="notEditable"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label>
              {{ 'providers.NPI' | lang }}
              <span *ngIf="npiValidateError" class="span-npi-validate-error">
                {{ 'forms.error.npi_validate' | lang }}
              </span>
            </label>
            <input
              name="npi"
              [(ngModel)]="provider.c_npi"
              type="text"
              [disabled]="notEditable"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <label>{{ 'providers.suffix' | lang }}</label>
            <input
              name="suffix"
              [(ngModel)]="provider.c_suffix"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>

        <!-- Billing Provider List -->
        <div class="row" *ngIf="billingProviders.length">
          <div class="col-xs-12">
            <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
            <div class="col-xs-12 ll-item-list-container">
              <div
                class="ll-list-item"
                *ngFor="let provider of billingProviders; let i = index"
              >
                <div class="ll-list-item-text">{{ provider.displayName }}</div>
                <div
                  class="ll-list-item-delete"
                  (click)="removeProvider(i)"
                  [ngClass]="{ 'grey-text': !billingProviderCanBeAdded }"
                >
                  x
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Billing provider Input -->
        <div class="row" *ngIf="billingProviderCanBeAdded">
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>Add {{ 'dashboard.billingProvider.name' | lang }}</label>
            <!-- Autocomplete hcpcs input -->
            <input
              placeholder="Search provider"
              name="searchProvider"
              id="autocomplete_provider"
              type="text"
              class="highlight"
              [ngbTypeahead]="searchProviderWrapper()"
              [resultTemplate]="autocompleteProvider"
              [inputFormatter]="providerFormatter"
            />
            <!-- Template for suggested hcpcs codes -->
            <ng-template #autocompleteProvider let-r="result" let-t="term">
              <li (click)="addProvider(r)">{{ r.displayName }}</li>
            </ng-template>
          </div>
        </div>

        <div class="row separate-top">
          <div class="col-xs-12 col-md-6 text-center">
            <div class="fileUpload button back-button" *ngIf="!notEditable">
              <span>{{ 'providers.select_file' | lang }}</span>
              <input
                type="file"
                class="upload"
                id="file"
                (change)="handleFileInput($event)"
              />
            </div>
            <label *ngIf="image">{{ 'providers.selected_file' | lang }}</label>
            <!-- <img *ngIf="image" style="width: 150px;" /> -->
            <img [src]="imageUrl" *ngIf="image" style="width: 150px" />
            <button
              class="button delete-button"
              type="button"
              (click)="deleteImage()"
              *ngIf="image && !notEditable"
              style="float: right"
            >
              &times;
            </button>
          </div>
        </div>
        <div class="row" *ngIf="!notEditable">
          <div class="col-xs-12">
            <span *ngIf="duplicatedError" class="span-duplicated-error">
              {{ 'forms.error.repeated_provider' | lang }}
            </span>
            <button
              class="button next-button"
              type="submit"
              [disabled]="!f.form.valid"
            >
              {{
                isEditMode
                  ? ('providers.save' | lang)
                  : ('providers.submit' | lang)
              }}
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="providerSubmited">
      <p>{{ 'providers.submited' | lang }}</p>
    </div>
  </div>

  <!-- show view form -->
  <div *ngIf="!showCreateEditForm">
    <div class="col-xs-12">
      <h1>
        {{ provider.c_preffix ? provider.c_preffix + '. ' : ' '
        }}{{ provider.c_name.c_first_name }} {{ provider.c_name.c_last_name
        }}{{ provider.c_suffix ? ', ' + provider.c_suffix : ' ' }}
      </h1>
      <div class="pull-right" *ngIf="notEditable">
        <button
          *ngIf="hasRole.admin"
          class="button delete-button"
          (click)="showPopupDelete()"
        >
          {{ 'providers.delete' | lang }}
        </button>
        <button
          *ngIf="hasRole.admin || hasRole.executive || hasRole.site_manager"
          class="button edit-button"
          (click)="goToEditProvider(providerId)"
        >
          {{ 'providers.edit' | lang }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="first-info-container">
        <ul>
          <li class="hidden"></li>
          <li>
            <label>{{ 'providers.NPI' | lang }}</label>
            <p>{{ provider.c_npi ? provider.c_npi : '-' }}</p>
          </li>
          <li>
            <label>{{ 'providers.preffix' | lang }}</label>
            <p>{{ provider.c_preffix ? provider.c_preffix : '-' }}</p>
          </li>
          <li>
            <label>{{ 'providers.name.first_name' | lang }}</label>
            <p>{{ provider.c_name ? provider.c_name.c_first_name : '-' }}</p>
          </li>
          <li>
            <label>{{ 'providers.name.last_name' | lang }}</label>
            <p>{{ provider.c_name ? provider.c_name.c_last_name : '-' }}</p>
          </li>
          <li>
            <label>{{ 'providers.suffix' | lang }}</label>
            <p>{{ provider.c_suffix ? provider.c_suffix : '-' }}</p>
          </li>
          <li></li>
        </ul>
      </div>
    </div>

    <div class="row mb10 separate-top" *ngIf="billingProviders.length">
      <div class="col-xs-12">
        <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
        <div class="col-xs-12 ll-item-list-container">
          <div
            class="ll-list-item-noteditable"
            *ngFor="let provider of billingProviders; let i = index"
          >
            <div class="ll-list-item-text">{{ provider.displayName }}</div>
            <!-- <div class="ll-list-item-delete" (click)="removeHcpcsCode(i)" >x</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- show index form -->
<div class="forms-form" *ngIf="somethingToShow">
  <button
    *ngIf="hasRole.admin || hasRole.executive || hasRole.site_manager"
    type="button"
    class="button create-button"
    (click)="updateProviders()"
  >
    Update Providers
  </button>
</div>
