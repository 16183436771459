import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { environment } from './../environments/environment';

class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn:
        'https://1df71502cc3b47b5a39fec9955776a6f@o89790.ingest.sentry.io/5499996',
      environment: environment.name,
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  }

  handleError(error) {
    // const eventId =
    Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}
export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
