<div>
  <div class="col-xs-2">
    <p>{{ invoiced ? 'Invoiced' : 'Not Invoiced' }}</p>
  </div>
  <div class="col-xs-8">
    <p>
      <label class="black_txt no_greedy_width">Invoice Nr.:</label>
      <label class="no_greedy_width">{{ invoiceNr || '-' }}</label>
    </p>
  </div>
</div>
