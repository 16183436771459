<div class="popup-content">
  <div class="custom-popup-content"></div>
  <!-- Header -->
  <div class="modal-header">
    <h3 *ngIf="info.title">{{ info.title }}</h3>
    <h4 *ngIf="info.subtitle" class="modal-title">{{ info.subtitle }}</h4>
  </div>

  <div class="modal-body" *ngIf="info.question !== undefined">
    <span>{{ info.question }}</span>
  </div>

  <div class="modal-footer">
    <div *ngFor="let option of info.options" class="ll-popup-option-wrapper">
      <button type="button" class="button" (click)="selectedOption(option)">
        {{ option.c_response_option_text }}
      </button>
    </div>
  </div>
</div>
<!-- /.modal-content -->
