import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class InsuranceService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createInsurance(insurance: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.insurances}`,
      insurance,
      this.httpOptions
    );
  }

  editInsurance(insuranceId: string, insurance: any): Observable<any> {
    delete insurance.acl;
    return this.http.put<any>(
      `${this.API.insurances}/${insuranceId}`,
      insurance,
      this.httpOptions
    );
  }

  getInsurance(insuranceId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.insurances}/${insuranceId}`,
      this.httpOptions
    );
  }

  getInsurances(): Observable<any> {
    return this.http.get<any>(`${this.API.insurances}`, this.httpOptions);
  }

  deleteInsurance(insurancesId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.insurances}/${insurancesId}`,
      this.httpOptions
    );
  }

  searchInsurances(input: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.insurances}?limit=20&where={"c_insurance_name":{"$regex":"/${input}/i"}}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  getAllInsurances(): Observable<any> {
    const self = this;
    const chunck = 1000;
    const localSubject = new Subject();

    let page = 0;
    let allInsurances = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.insurances}?limit=${chunck}&skip=` + page * chunck,
          self.httpOptions
        )
        .subscribe((response) => {
          allInsurances = allInsurances.concat(response.data);
          // console.log("response: ", response);
          if (response.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allInsurances);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getInsurancesBasedOnLocations(locationList: string[]): Observable<any> {
    const expand = `expand[]=c_location&expand[]=c_insurance`,
      filter = `where={"c_location": {"$in": [${locationList}]}}`,
      query = `?${expand}&${filter}`;

    return this.http.get<any>(
      `${this.API.insuranceLocations}${query}`,
      this.httpOptions
    );
  }
}
