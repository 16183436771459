import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import addDays from 'date-fns/addDays';
import * as moment from 'moment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormsService } from '../../services/forms/forms.service';
import { LocationGroupsService } from '../../services/location-groups/location-groups.service';
import { OrderService } from '../../services/order/order.service';

declare var require: any;

@Component({
  selector: 'app-bulk-operations',
  templateUrl: './bulk-operations.component.html',
  styleUrls: ['./bulk-operations.component.css'],
})
export class BulkOperationsComponent implements OnInit {
  gInvoiceNumber = '';

  orderLineItemsFilter: any[] = [];

  progressMessage: string = '';

  selectedLocation: any = {
    c_company: '',
  };

  errorMessage: string = '';
  successMessage: string = '';

  selectedLocationGroup: any = {
    c_group_name: '',
  };

  invoicedStatus = 'No';

  invoiceStatuses = [
    {
      label: 'Yes',
    },
    {
      label: 'No',
    },
    {
      label: 'Any',
    },
  ];

  selectedInsurance: any = {
    c_insurance_name: '',
  };
  insuranceSelected: boolean = false;
  locationSelected: boolean = false;
  locationGroupSelected: boolean = false;
  hcpcsSelected: boolean = false;
  allDataLoaded: boolean = false;

  allProducts: any;

  allHcpcsCodes: any;
  allUsers: any;
  userSelected: boolean = false;
  selectedCreator: any = {
    firstName: '',
    lastName: '',
    email: '',
  };

  selectedHcpcs: any = {
    c_hcpcs_code: '',
  };

  productFilterIds: any = [];

  selectedFitter: any = {
    firstName: '',
    lastName: '',
    email: '',
  };

  filterProviderLocationIds: string[] = [];

  sku: string = '';

  fitterSelecrted: boolean = false;

  filteredOrderIds: string[];
  initialLoadingDone: boolean = false;

  icdsLoaded: boolean = false;
  allIcdCodes: any;

  allProviderLocations: any;
  allLocations: any;
  allLocationGroups: any;
  addedLocationGroups: any[] = [];
  allInsurances: any;
  selectedProviderLocation: any[] = [];
  filterStatuses: string[] = ['any', 'dispatched', 'draft', 'billed'];
  selectedStatus: string = this.filterStatuses[0];

  formattedLineItems: any[] = [];
  lineItemsPage: any[] = [];

  pageList: any[];

  minDateModel: IMyDateModel;
  maxDateModel: IMyDateModel;

  hasRole: any = {};

  gItemsPerPage: number = 10;
  gPages: number = 0;
  gPageList: any[] = [];

  lineItemsLoaded: boolean = false;

  filesaver = require('file-saver');
  ordersToProcess: number = -1;
  pageCount: any[] = [];
  exportData: any[] = [];
  exportDataOrder: any = [];
  csvHeader: any[] = [];

  count: number = 0;
  actualPage: number = 0;

  locations: any[] = [];
  orders: any[] = [];
  prevMaxId: any[] = [];
  locationItems: any[] = [];

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    disableSince: this.dateService.toDatepickerFormat(addDays(new Date(), 1)),
  };

  constructor(
    private authService: AuthService,
    private formsService: FormsService,
    private languagePipe: LanguagePipe,
    private router: Router,
    private orderService: OrderService,
    private locationGroupService: LocationGroupsService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    // this.getAllIcds();
    this.getAllProviderLocations(); // This function will continue to call a series of functions that will load the data displayed.
    // this.getAllLocations();
    // this.getAllInsurances();
    this.getAllUsers();
    // this.getAllHcpcsCodes();
    // this.getAllProducts();
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.initializeDate();

    // this.markItemsAsInvoiced();

    // this.loadOrdersToInvoice();
  }

  getAllProducts() {
    this.orderService.getAllProducts().subscribe((prods) => {
      this.allProducts = prods;
      // console.log("All Products Loaded: ", this.allProducts);
    });
  }

  getAllLocationGroups() {
    this.locationGroupService.getLocationGroups().subscribe((groups) => {
      this.allLocationGroups = groups.data;
      // console.log("All LocationGroups loaded: ", groups.data)
      this.getTableItems(false);
    });
  }

  getAllHcpcsCodes() {
    this.orderService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
      this.allHcpcsCodes = allCodes[0].data;
      // console.log("All hcpocsCodes Medable: ", this.allHcpcsCodes);
    });
  }

  getAllInsurances() {
    this.orderService.getAllInsurances().subscribe((insurances) => {
      // console.log("All Insurances Loaded: ", insurances);
      this.allInsurances = insurances;
    });
  }

  getAllUsers() {
    this.formsService.get('allFitters', '').subscribe((allUsers) => {
      // console.log("All Users Loaded: ", allUsers);
      this.allUsers = allUsers.data;
    });
  }

  getAllLocations() {
    this.orderService.getAllLocations().subscribe((locations) => {
      this.allLocations = locations;
    });
  }

  getAllProviderLocations() {
    this.orderService.getAllProviderLocations().subscribe((pLocations) => {
      // console.log("All Provider Locations: ", pLocations);
      this.allProviderLocations = pLocations;
      this.getAllLocationGroups();
    });
  }

  getAllIcds() {
    this.orderService.getAllIcdCodes().subscribe((icds) => {
      this.allIcdCodes = icds;
      this.icdsLoaded = true;
      // console.log("All icd codes loaded...", this.allIcdCodes);
      this.getTableItems(false);
    });
  }

  initializeDate() {
    const today = new Date();
    this.minDateModel = this.dateService.toDatepickerModel(today);
    this.maxDateModel = this.dateService.toDatepickerModel(today);
  }

  generateFileNameMin() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'
    if (this.minDateModel) {
      fnm = this.dateService.format(this.minDateModel, 'MM_dd_yyyy');
    }

    return fnm;
  }

  generateFileNameMax() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'
    if (this.maxDateModel) {
      fnm = this.dateService.format(this.maxDateModel, 'MM_dd_yyyy');
    }
    return fnm;
  }

  getOrders(paginator = 0, specificPage = false) {
    this.lineItemsLoaded = false;
    const minDate = this.dateService.format(this.minDateModel);
    const maxDate = this.dateService.format(this.maxDateModel);
    let skip = 0;
    const sort = 'sort={"_id": -1}';

    if (specificPage) {
      skip = paginator * 10;
    } else {
      skip = this.actualPage * 10;
    }

    const limit = `limit=10&skip=${skip}`;

    const filterList = [
      `{"created" : {"$gte" : "${minDate}"}}`,
      `{"created" : {"$lte" : "${maxDate}"}}`,
    ];
    const expandFields = [
      'creator',
      'c_patient',
      'c_insurance',
      'c_provider_location.c_physician',
      'c_provider_location.c_location',
      'c_secondary_insurance',
      'c_designated_fitter',
      'c_fitter',
    ];

    const filters =
      'where={"$and":[' +
      filterList.filter((filter) => filter != '').join(',') +
      ']}';
    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const query = `?${filters}&${expands}`;

    this.orderLineItemsFilter = [];
    this.orders = [];

    this.formsService
      .get('orders', query + `&${sort}&${limit}`)
      .subscribe((orders) => {
        let ordersData = orders.data.filter((od) => !od.c_deleted);
        // console.log("OrdersData: ", ordersData);
        this.ordersToProcess = ordersData.length;
        ordersData.map((order) => {
          const orderClone = { ...order };
          orderClone.c_order_date = new Date(orderClone.c_order_date);
          orderClone.c_billed_date = orderClone.c_billed_date
            ? new Date(orderClone.c_billed_date)
            : null;
          orderClone.c_patient.c_dob = new Date(orderClone.c_patient.c_dob);
          orderClone['status'] = this.getOrderStatus(order);
          orderClone['creatorName'] = order.c_fitter
            ? this.getOrderCreator(order)
            : '-';
          orderClone['fitterName'] = order.c_designated_fitter
            ? this.getDesignatedFitter(order)
            : '-';
          this.orders.push(orderClone);
          this.getOrderLineItems(orderClone._id, this.orders.length - 1);
        });

        this.orders.map((pOrd) => {
          // console.log("Porders: ", pOrd);
          pOrd.display_order_date = this.medableDateToDisplay(
            pOrd.c_order_date
          );
          pOrd.display_billed_date = pOrd.c_billed_date
            ? this.medableDateToDisplay(pOrd.c_billed_date)
            : '-';
        });

        if (!this.ordersToProcess) {
          this.lineItemsLoaded = true;
        }
      });

    if (paginator === 0) {
      const groupBy = 'group={"_id": "object", "count":{"$count": "_id"}}';
      this.formsService
        .get('orders', query + '&' + groupBy)
        .subscribe((count) => {
          // console.log("Count: ", count);
          this.count = count.data[0] ? count.data[0].count : 0;
          this.pageCount = new Array(Math.ceil(this.count / 10));
          this.actualPage = 0;
          this.prevMaxId = [];
        });
    }
  }

  // Getting orderlineitems based on the filters
  getTableItems(search: boolean = true, paginator = 0, specificPage = false) {
    this.successMessage = '';
    this.errorMessage = '';
    this.progressMessage = '';
    // Open Loading indicator
    this.formattedLineItems = [];

    // console.log("Selected Status is: ", this.selectedStatus);
    // After Order ids are loaded
    this.getOrdersBasedonFilters().subscribe((orders) => {
      // console.log("Orders Loaded");
      if (!orders.length) {
        // console.log("Fin.");
        this.formattedLineItems = [];
        this.lineItemsPage = [];
        this.gPageList = [];
        this.gPages = 0;
        this.allDataLoaded = true;
      } else {
        this.getFilteredLineItems().subscribe((items) => {
          // console.log("Fin.");
          this.allDataLoaded = true;
        });
      }
    });
  }

  getFilteredLineItems(): Observable<any> {
    const localSubject = new Subject();
    const requests = [];
    // console.log("OrderIds: ", this.filteredOrderIds);
    this.loadChunckData().subscribe((loadedLines) => {
      localSubject.next(loadedLines);
      localSubject.complete();
    });

    return localSubject;
  }

  buildLineItemsFilter(filteredIds) {
    if (!this.hcpcsSelected) {
      this.productFilterIds = [];
    }
    const conditions = [`{"c_medable_order": {"$in": [${filteredIds}]}}`];
    // let filter = `where={"c_medable_order": {"$in": [${filteredIds}]}}`;
    // if (this.productFilterIds.length) {
    //   filter = `${filter}`
    // }

    // if (this.productFilterIds.length) {
    //   const hcpcsFilter = `{"c_product" : {"$in": [${this.productFilterIds}]}}`;
    //   conditions.push(hcpcsFilter);
    // }

    const filter = `where={"$and": [${conditions.join(',')}]}`;

    return filter;
  }

  loadChunckData() {
    const chunckSize = 200;
    const reqLimit = 2000;
    const chuncks = Math.ceil(this.filteredOrderIds.length / chunckSize);
    const requests = [];
    const localSubject = new Subject();

    for (let i = 0; i < chuncks; i++) {
      const filteredIds = this.filteredOrderIds
          .splice(0, chunckSize)
          .map((oi) => `"${oi['_id']}"`),
        filter = this.buildLineItemsFilter(filteredIds),
        expands = [
          'expand[]=c_medable_order',
          'expand[]=c_medable_order.c_patient',
          'expand[]=c_product',
          'expand[]=c_medable_order.c_designated_fitter',
          'expand[]=c_medable_order.c_fitter',
          'expand[]=c_product.c_supplier_id',
          'expand[]=c_medable_order.c_provider_location',
          'expand[]=c_medable_order.c_provider_location.c_physician',
          'expand[]=c_medable_order.c_provider_location.c_location',
          'expand[]=c_modifier.c_modifier_ref',
          'expand[]=c_medable_order.c_billing_provider',
        ],
        // paths = [
        //   'paths[]=c_medable_order',
        //   'paths[]=c_product',
        //   'paths[]=c_insurance_name',
        //   'paths[]=c_allowable_cost',
        //   'paths[]=c_sales_tax',
        //   'paths[]=c_self_pay_cost',
        //   'paths[]=c_quantity',
        //   'paths[]=c_charge_out_cost',
        //   'paths[]=c_modifier',
        //   'paths[]=c_icd_10',
        //   'paths[]=c_product_option_code'
        // ],
        expand = expands.join('&'),
        // path = paths.join('&'),
        limit = `limit=${reqLimit}`,
        query = `?${filter}&${expand}&${limit}`;
      // console.log("FilterIds: ", filteredIds);
      const request = this.formsService.get('orderLineItems', query);

      requests.push(request);
    }

    forkJoin(requests).subscribe((results) => {
      // console.log("Line Results: ", results);
      this.formatLineResults(results);
      localSubject.next(results);
      localSubject.complete();
    });

    return localSubject;
  }

  addFormattedLine(line) {
    const formattedLine = this.buildFormattedLine(line);
    formattedLine['checked'] = false;
    this.formattedLineItems.push(formattedLine);
  }

  checkAllLineItems() {
    this.formattedLineItems.map((line) => {
      line.checked = true;
    });
  }

  uncheckAllLineItems() {
    this.formattedLineItems.map((line) => {
      line.checked = false;
    });
  }

  updateCheckedLines() {
    // console.log("Updating checked lines...")
    const linesToUpdate = [];
    let now = new Date(Date.now()).toISOString();

    this.formattedLineItems.map((fLine) => {
      if (fLine.checked) {
        const updateObj = {
          id: fLine.itemId,
          value: {
            c_invoiced: true,
            c_invoice_number: this.gInvoiceNumber,
            c_last_modified: now,
          },
        };

        linesToUpdate.push(updateObj);
      }
    });

    if (this.gInvoiceNumber == '') {
      // console.log("No invoice number provided...")
      this.successMessage = '';
      this.progressMessage = '';
      this.errorMessage = '*Please enter an invoice number';
      return;
    }
    if (!linesToUpdate.length) {
      this.successMessage = '';
      this.progressMessage = '';
      this.errorMessage = '*No line item was checked';
      return;
    }
    // console.log("List to update: ", linesToUpdate)
    this.errorMessage = '';
    this.progressMessage = '';
    this.successMessage = '';

    let remainig = linesToUpdate.length;
    let updatedItems = 0;
    this.progressMessage = `Updated ${updatedItems} items, remaining ${remainig} items`;

    linesToUpdate.map((lu) => {
      this.orderService
        .editOrderLineItem(lu.value, lu.id)
        .subscribe((updated) => {
          // console.log("Line succesfully updated: ", updated);
          remainig--;
          updatedItems++;

          this.formattedLineItems.map((fl) => {
            if (fl.itemId == lu.id) {
              (fl.invoiced = true), (fl.invoiceNr = this.gInvoiceNumber);
            }
          });

          this.progressMessage = `Updated ${updatedItems} items, remaining ${remainig} items`;
          if (remainig == 0) {
            this.successMessage =
              'Succesfully updated all line items. Initiating Download...';
            this.exportDashboardItems();
          }
        });
    });
  }

  formatLineResults(results) {
    // console.log("Formatting Line Results: ", results);

    this.buildProviderLocationFilter();

    // console.log("ProviderLocationIds: ", this.filterProviderLocationIds)

    const filterSku = this.sku.toLowerCase();
    const filterHcpcs = this.selectedHcpcs.c_hcpcs_code;
    results.map((res) => {
      res.data
        .filter((ln) => !ln.c_deleted)
        .map((line) => {
          if (this.addedLocationGroups.length) {
            // If locationgroup was selectd, we have to apply filter on the line
            const pLid = line.c_medable_order.c_provider_location._id;
            const selectedPLoc = this.filterProviderLocationIds.filter(
              (fpl) => fpl == pLid
            ).length;

            if (selectedPLoc) {
              this.addByFilteringStatus(line);
            }
          } else {
            this.addByFilteringStatus(line);
          }

          // // Apply Sku Filter
          // if (this.sku == '' || (this.sku != '' && line.c_product_option_code.toLowerCase() == filterSku)) {
          //   // Apply Hcpcs Filter
          //   if (!this.hcpcsSelected || (this.hcpcsSelected && line.c_product.c_hcpcs_code == filterHcpcs)) {
          //     // Apply LocationGroup Filter

          //   }
          // }
        });
    });

    this.gPages = Math.ceil(
      this.formattedLineItems.length / this.gItemsPerPage
    );
    // console.log("LineItems: ", this.formattedLineItems);
    // console.log("Total Pages: ", this.gPages);
    this.gPageList = [];
    for (let i = 0; i < this.gPages; i++) {
      const tmp = {
        pageNr: i + 1,
      };
      this.gPageList.push(tmp);
    }

    // console.log("pageList: ", this.gPageList);

    this.actualPage = 1;
    this.loadPage(1);
    // console.log("Formatted Line Results: ", this.formattedLineItems);
  }

  addByFilteringStatus(line) {
    // this.addFormattedLine(line)

    // if (!line.c_invoiced) {
    //   this.addFormattedLine(line)
    // }

    if (this.invoicedStatus != 'Any') {
      const invoiced = this.invoicedStatus == 'Yes' ? true : false;
      const lineInvoiced = line.c_invoiced == true ? true : false;

      if (lineInvoiced == invoiced) {
        this.addFormattedLine(line);
      }
    } else {
      this.addFormattedLine(line);
    }
  }

  buildProviderLocationFilter() {
    this.filterProviderLocationIds = [];
    this.allProviderLocations.map((pLoc) => {
      const locationGrId = pLoc.c_location.c_location_group
        ? pLoc.c_location.c_location_group._id
        : null;

      this.addedLocationGroups.map((lGroup) => {
        if (lGroup._id == locationGrId) {
          this.filterProviderLocationIds.push(pLoc._id);
        }
      });
    });
  }

  loadPage(pageNumber) {
    this.actualPage = pageNumber;
    const itemsPerPage = 10;
    this.lineItemsPage = this.formattedLineItems.slice(
      (pageNumber - 1) * itemsPerPage,
      pageNumber * itemsPerPage
    );
    // console.log("Loading Page: ", this.lineItemsPage);
  }

  // 2018-12-08T21:20:19.865Z
  displaySystemDate(medableDateString) {
    const s = medableDateString.split(/[^\d]+/);
    // console.log("SPLITTED DATE:" , s);
    const year = s[0],
      month = s[1],
      day = s[2],
      hours = s[3],
      minutes = s[4],
      seconds = s[5];

    return `${month}/${day}/${year}`;
  }

  getOrderCreatorName(medableOrder) {
    //`${medableOrder.c_fitter.name.first} ${medableOrder.c_fitter.name.last}`
    const orderCreatorId = medableOrder.creator._id;
    const orderCreatorUser = this.getUserBasedOnId(orderCreatorId);
    //console.log("OrderCreator: ", orderCreatorId);
    let creatorName = '-';
    if (orderCreatorUser) {
      creatorName = `${orderCreatorUser.firstName} ${orderCreatorUser.lastName}`;
    }
    return creatorName;
  }

  getUserBasedOnId(userId) {
    //console.log("searching userId: ", userId, " in userList: ", this.allUsers);
    const user = this.allUsers.filter((u) => u.id == userId)[0];
    return user;
  }

  buildFormattedLine(line) {
    const medableOrder = line.c_medable_order;
    const orderPatient = medableOrder.c_patient;
    const lineProduct = line.c_product;
    const orderPhysician = medableOrder.c_provider_location.c_physician;
    //console.log("RawLine: ", line);
    const fLine = {
      quantity: line.c_quantity,
      chargeOutCost: line.c_charge_out_cost,
      allowableCost: line.c_allowable_cost,
      selfPay: line.c_self_pay_cost,
      salesTax: line.c_sales_tax,
      modifiers: this.generateLineModifiers(line),
      warnings: this.generateLineWarnings(line),
      icds: this.generateLineIcds(line),
      order: {
        id: medableOrder._id,
        systemDate: this.displaySystemDate(medableOrder.created),
        numberId: medableOrder.c_number_id,
        display_order_date: this.medableDateToDisplay(
          new Date(medableOrder.c_order_date)
        ),
        display_billed_date: medableOrder.c_billed_date
          ? this.medableDateToDisplay(new Date(medableOrder.c_billed_date))
          : '-',
        status: this.getOrderStatus(medableOrder),
        claimId: medableOrder.c_claim_id ? medableOrder.c_claim_id : '-',
        creatorName: this.getOrderCreatorName(medableOrder),
        fitterName: medableOrder.c_designated_fitter
          ? `${medableOrder.c_designated_fitter.name.first}${medableOrder.c_designated_fitter.name.last}`
          : '-',
        billingProviderName: medableOrder.c_billing_provider
          ? this.getBillingProviderName(medableOrder)
          : '-',
        providerName: this.getProvider(orderPhysician),
        providerLocation: this.getProviderLocation(medableOrder),
        primaryInsurance: line.c_insurance_name,
        primaryInsId: medableOrder.c_primary_insurance_id_number
          ? medableOrder.c_primary_insurance_id_number
          : '-',
        secondaryInsurance: medableOrder.c_secondary_insurance
          ? medableOrder.c_secondary_insurance.c_insurance_name
          : '-',
        secondaryInsId: medableOrder.c_secondary_insurance_id_number
          ? medableOrder.c_secondary_insurance_id_number
          : '-',
        patient: {
          mrn: orderPatient.c_mrn,
          fullName: orderPatient.c_name
            ? `${orderPatient.c_name.c_first_name} ${orderPatient.c_name.c_last_name}`
            : '-',
        },
        lastModified: medableOrder.c_last_modified
          ? moment(medableOrder.lastModified).format('MM/DD/YYYY - HH:mm:ss')
          : '-',
      },
      product: {
        hcpcs: lineProduct.c_hcpcs_code,
        name: lineProduct.c_name,
        optionCode: line.c_product_option_code,
        size: this.getProductSize(line),
        vendor:
          lineProduct.c_supplier_id != undefined
            ? line.c_product.c_supplier_id.c_company
            : '-',
      },
      itemId: line._id,
      invoiced: line.c_invoiced,
      invoiceNumber: line.c_invoice_number != '' ? line.c_invoice_number : '-',
    };

    return fLine;
  }

  // getBillingProviderName(medableOrder) {
  //   console.log("MedableOrder: ", medableOrder);
  //   return '-';
  // }

  getBillingProviderName(order) {
    const preffix = order.c_billing_provider.c_preffix
        ? `${order.c_billing_provider.c_preffix} `
        : ``,
      firstName = order.c_billing_provider.c_name.c_first_name,
      lastName = order.c_billing_provider.c_name.c_last_name,
      suffix = order.c_billing_provider.c_suffix
        ? ` ${order.c_billing_provider.c_suffix}`
        : '';

    return `${preffix}${firstName} ${lastName}${suffix}`;
  }

  exportDashboardItems() {
    this.csvHeader = [
      this.languagePipe.transform('dashboard.order'),
      this.languagePipe.transform('dashboard.system_date'),
      this.languagePipe.transform('dashboard.date'),
      this.languagePipe.transform('dashboard.status'),
      this.languagePipe.transform('dashboard.billed_date'),
      this.languagePipe.transform('dashboard.claim_id'),
      this.languagePipe.transform('dashboard.mrn'),
      this.languagePipe.transform('dashboard.patient_name'),
      this.languagePipe.transform('dashboard.creator.name'),
      this.languagePipe.transform('dashboard.fitter.name'),
      this.languagePipe.transform('dashboard.hcpcs'),
      this.languagePipe.transform('dashboard.item_name'),
      this.languagePipe.transform('dashboard.product_option_code'),
      this.languagePipe.transform('dashboard.quantity'),
      this.languagePipe.transform('dashboard.size'),
      this.languagePipe.transform('dashboard.vendor'),
      this.languagePipe.transform('orders.order-form.label.orderingProvider'),
      this.languagePipe.transform('dashboard.billingProvider.name'),
      this.languagePipe.transform('dashboard.location'),
      this.languagePipe.transform('dashboard.primary_insurance'),
      this.languagePipe.transform('dashboard.primary_insurance_id'),
      this.languagePipe.transform('dashboard.secondary_insurance'),
      this.languagePipe.transform('dashboard.secondary_insurance_id'),
      this.languagePipe.transform('dashboard.charge_out_cost'),
      this.languagePipe.transform('dashboard.allowable_cost'),
      this.languagePipe.transform('dashboard.self_pay_cost'),
      this.languagePipe.transform('dashboard.sales_tax'),
      this.languagePipe.transform('dashboard.modifiers'),
      this.languagePipe.transform('dashboard.warnings'),
      this.languagePipe.transform('dashboard.icds'),
      this.languagePipe.transform('dashboard.orderId'),
      this.languagePipe.transform('dashboard.itemId'),
      this.languagePipe.transform('dashboard.invoiced'),
      this.languagePipe.transform('dashboard.invoiceNr'),
      this.languagePipe.transform('dashboard.lastModified'),
    ];
    // const min = (this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : '';
    // const max = this.maxDate.date.month + 1 + '_' + this.maxDate.date.day + '_' + this.maxDate.date.year;
    // const csvName = 'Export ' + min + ' - ' + max + '.csv';
    const min = this.generateFileNameMin(),
      max = this.generateFileNameMax(),
      csvName = 'Export ' + min + ' - ' + max + '.csv';
    this.exportData = [];
    this.exportDataOrder = [];
    const self = this;

    const fileTxt = this.formattedLineItems.map((item) => {
      const order = item.order;
      const product = item.product;
      const orderPatient = item.order.patient;
      const oli = [
        order.numberId ? '"' + order.numberId + '"' : '-',
        order.systemDate ? '"' + order.systemDate + '"' : '-',
        order.display_order_date ? '"' + order.display_order_date + '"' : '-',
        order.status ? '"' + order.status + '"' : '-',
        order.display_billed_date ? '"' + order.display_billed_date + '"' : '-',
        order.claimId ? order.claimId.replace(/"/g, "''") : '-',
        orderPatient.mrn
          ? '"' + orderPatient.mrn.replace(/"/g, "''") + '"'
          : '-',
        orderPatient.fullName
          ? '"' + orderPatient.fullName.replace(/"/g, "''") + '"'
          : '-',
        order.creatorName ? '"' + order.creatorName + '"' : '-',
        order.fitterName ? '"' + order.fitterName + '"' : '-',
        product.hcpcs ? '"' + product.hcpcs.replace(/"/g, "''") + '"' : '-',
        product.name ? '"' + product.name.replace(/"/g, "''") + '"' : '-',
        product.optionCode
          ? '"' + product.optionCode.replace(/"/g, "''") + '"'
          : '-',
        item.quantity ? '"' + item.quantity + '"' : '-',
        product.size != undefined
          ? '"' + product.size.replace(/"/g, "''") + '"'
          : '-',
        product.vendor ? '"' + product.vendor.replace(/"/g, "''") + '"' : '-',
        order.providerName
          ? '"' + order.providerName.replace(/"/g, "''") + '"'
          : '-',
        order.billingProviderName
          ? '"' + order.billingProviderName.replace(/"/g, "''") + '"'
          : '-',
        order.providerLocation
          ? '"' + order.providerLocation.replace(/"/g, "''") + '"'
          : '-',
        order.primaryInsurance
          ? '"' + order.primaryInsurance.replace(/"/g, "''") + '"'
          : '-',
        order.primaryInsId
          ? '"' + order.primaryInsId.replace(/"/g, "''") + '"'
          : '-',
        order.secondaryInsurance
          ? '"' + order.secondaryInsurance.replace(/"/g, "''") + '"'
          : '-',
        order.secondaryInsId
          ? '"' + order.secondaryInsId.replace(/"/g, "''") + '"'
          : '-',
        item.chargeOutCost ? '"' + `$${item.chargeOutCost}` + '"' : '-',
        item.allowableCost ? '"' + `$${item.allowableCost}` + '"' : '-',
        item.selfPay ? '"' + `$${item.selfPay}` + '"' : '-',
        item.salesTax ? '"' + `$${item.salesTax}` + '"' : '-',
        item.modifiers ? '"' + item.modifiers + '"' : '-',
        item.warnings ? '"' + item.warnings + '"' : '-',
        item.icds ? '"' + item.icds + '"' : '-',
        item.order.id ? '"' + item.order.id + '"' : '-',
        item.itemId ? '"' + item.itemId + '"' : '-',
        '"' + item.invoiced + '"',
        item.invoiceNr ? '"' + item.invoiceNr + '"' : '-',
        order.lastModified ? `"${order.lastModified}"` : '-',
      ];

      return oli.join(',');
    });

    let mappedTxt = fileTxt.join('\n');
    mappedTxt = self.csvHeader.join(',') + '\n' + mappedTxt;
    const file = new Blob([mappedTxt], { type: 'text/plain;charset=utf-8' });
    file['name'] = csvName;
    this.filesaver.saveAs(file);

    // console.log("Getting Export Data...");
    // this.getExportData().subscribe(() => {
    //   console.log("Data Ready");
    //   let fileTxt = self.exportData.map(function(obj) {
    //     let order = self.exportDataOrder.filter(function(ord) {
    //       return (ord._id === obj.c_medable_order._id);
    //     })[0];
    //     console.log("obj: ", obj);
    //     console.log("order: ", order);
    //     let oli = [
    //       (order.c_number_id) ? ("\"" + order.c_number_id + "\"") : '-',
    //       (order.c_order_date) ? ("\"" + self.medableDateToDisplay(new Date(order.c_order_date))  + "\"") : '-',
    //       (order.status) ? ("\"" + order.status + "\"") : '-',
    //       (order.c_billed_date) ? ("\"" + self.medableDateToDisplay(new Date(order.c_billed_date)) + "\"") : '-',
    //       (order.c_claim_id) ? order.c_claim_id.replace(/"/g, "''") : '-',
    //       (order.c_patient.c_mrn) ? ("\"" + order.c_patient.c_mrn.replace(/"/g, "''") + "\"") : '-',
    //       (order.c_patient.c_name) ? ("\"" + order.c_patient.c_name.c_first_name.replace(/"/g, "''") + ' ' + order.c_patient.c_name.c_last_name.replace(/"/g, "''") + "\"") : '-',
    //       (order.status) ? ("\"" + order.creatorName + "\"") : '-',
    //       (order.status) ? ("\"" + order.fitterName + "\"") : '-',
    //       (obj.c_product.c_hcpcs_code) ? ("\"" + obj.c_product.c_hcpcs_code.replace(/"/g, "''") + "\"") : '-',
    //       (obj.c_product.c_name) ? ("\"" + obj.c_product.c_name.replace(/"/g, "''") + "\"") : '-',
    //       (obj.c_product_option_code) ? ("\"" + obj.c_product_option_code.replace(/"/g, "''") + "\"") : '-',
    //       (obj.c_quantity) ? ("\"" + obj.c_quantity + "\"") : '-',
    //       (obj.c_product.cpy_product_option != undefined) ? (obj.c_product.cpy_product_option.c_size != undefined ? "\"" + obj.c_product.cpy_product_option.c_size.replace(/"/g, "''") + "\"" : "-") : '-',
    //       (obj.c_product.c_supplier_id) ? ("\"" + obj.c_product.c_supplier_id.c_company.replace(/"/g, "''") + "\"") : '-',
    //       (order.providerName) ? ("\"" + order.providerName.replace(/"/g, "''") + "\"") : '-',
    //       (order.c_provider_location) ? ((order.c_provider_location.c_location) ? ("\"" + order.c_provider_location.c_location.c_company.replace(/"/g, "''") + "\"") : '-') : '-',
    //       (order.c_insurance) ? ("\"" + order.c_insurance.c_insurance_name.replace(/"/g, "''") + "\"") : '-',
    //       (order.c_primary_insurance_id_number) ? ("\"" + order.c_primary_insurance_id_number.replace(/"/g, "''") + "\"") : '-',
    //       (order.c_secondary_insurance) ? ("\"" + order.c_secondary_insurance.c_insurance_name.replace(/"/g, "''") + "\"") : '-',
    //       (order.c_secondary_insurance_id_number) ? ("\"" + order.c_secondary_insurance_id_number.replace(/"/g, "''") + "\"") : '-',
    //       (obj.c_charge_out_cost) ? ("\"" + obj.c_charge_out_cost + "\"") : '-',
    //       (obj.c_allowable_cost) ? ("\"" + obj.c_allowable_cost + "\"") : '-',
    //       (obj.c_self_pay_cost) ? ("\"" + obj.c_self_pay_cost + "\"") : '-',
    //       (obj.c_sales_tax) ? ("\"" + obj.c_sales_tax + "\"") : '-',
    //       (obj.c_modifier) ? ("\"" + obj.c_modifier.filter(m => m.c_checked).map(m => m.c_modifier).join(' | ') + "\""): '-',
    //       (obj.icds_expanded) ? ("\"" + obj.icds_expanded.join(' | ') + "\"") : '-'
    //     ];

    //     return oli.join(',');
    //   });

    //   let mappedTxt = fileTxt.join('\n');
    //   mappedTxt = self.csvHeader.join(',') + '\n' + mappedTxt;
    //   const file = new Blob([mappedTxt], {type: "text/plain;charset=utf-8"});
    //   file['name'] = csvName;
    //   this.filesaver.saveAs(file);
    // });
  }

  getProductSize(line) {
    const product = line.c_product;
    if (!product.c_product_options) {
      return '-';
    } else {
      const sizeObj = product.c_product_options.filter(
        (opt) => opt.c_code == line.c_product_option_code
      )[0];
      const size = sizeObj != null ? sizeObj.c_size : '-';
      // console.log("option: ", option);
      return size;
    }
  }

  // if (orderLineItemObject.c_product.c_product_options != undefined) {
  //   orderLineItemObject.c_product.cpy_product_option = orderLineItemObject
  //                                                     .c_product
  //                                                     .c_product_options
  //                                                     .filter(opt => opt.c_code === orderLineItemObject.c_product_option_code)[0];

  // generateLineModifiers
  generateLineWarnings(line) {
    // console.log("Line Warnings: ", line.c_modifier);
    const warnings = line.c_modifier
      .filter((m) => m.c_checked && m.c_modifier_ref.c_warning)
      .map((m) => m.c_modifier)
      .join(', ');
    return warnings;
  }

  generateLineModifiers(line) {
    // console.log("Line Modifier: ", line.c_modifier);
    const modifiers = line.c_modifier
      .filter((m) => m.c_checked && !m.c_modifier_ref.c_warning)
      .map((m) => m.c_modifier)
      .join(', ');
    return modifiers;
  }

  generateLineIcds(line) {
    return 'ommited';
    // const icdCodes = [];
    // line.c_icd_10.map( icdId => {
    //   const icdCode = this.allIcdCodes.filter(icd => icd._id == icdId).map(icd => icd.c_icd_10_code)[0];
    //   icdCodes.push(icdCode);
    // })

    // return icdCodes.join(", ");
  }

  getProvider(physician) {
    const prefix = physician.c_preffix ? `${physician.c_preffix} ` : '';
    const firstName = physician.c_name.c_first_name;
    const lastName = physician.c_name.c_last_name;
    const suffix = physician.c_suffix ? ` ${physician.c_suffix}` : '';

    return `${prefix}${firstName} ${lastName}${suffix}`;
  }

  getProviderLocation(order) {
    if (!order.c_provider_location.c_location) {
      return '-';
    } else {
      return order.c_provider_location.c_location.c_company;
    }
  }

  getOrdersBasedonFilters(): Observable<any> {
    const localSubject = new Subject();
    const ordersQuery = this.buildOrderFilterQuery();
    // Get the medable ids of all the orders based on the filters
    this.orderService.getAllOrderIds(ordersQuery).subscribe((orderIds) => {
      console.log('All orderIds Loaded: ', { ...orderIds });
      this.filteredOrderIds = orderIds.filter((oid) => !oid.c_deleted);
      localSubject.next(this.filteredOrderIds);
      localSubject.complete();
    });

    return localSubject;
  }

  lateFilterOrders(orders) {
    if (this.selectedStatus == 'any') {
      return orders;
    }

    return orders.filter((ord) => {
      if (
        this.selectedStatus == 'dispatched' &&
        !ord.c_billed_date &&
        !ord.c_draft
      ) {
        return true;
      }

      if (this.selectedStatus == 'draft' && ord.c_draft) {
        return true;
      }

      if (
        this.selectedStatus == 'billed' &&
        ord.c_billed_date &&
        !ord.c_draft
      ) {
        return true;
      }
    });
  }

  addStatusFilter(filters) {
    switch (this.selectedStatus) {
      case 'dispatched':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": false}`);
        break;

      case 'draft':
        filters.push(`{"c_draft": true}`);
        break;

      case 'billed':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": true}`);
        break;
    }
  }

  buildFilterList() {
    // console.log("Billed date filters -  Min: ", this.minBilledDate, " Max: ", this.maxBilledDate);
    const filters = [];
    if (this.minDateModel && this.maxDateModel) {
      const minDateFormatted = this.dateService.format(this.minDateModel);
      const maxDateFormatted = this.dateService.format(this.maxDateModel);
      filters.push(`{"created": {"$gte" : "${minDateFormatted}T00:00:00"}}`);
      filters.push(`{"created": {"$lte" : "${maxDateFormatted}T23:59:59"}}`);
    }

    // filters.push(`{"c_draft": false}`);

    /*const filters = [
      `{"c_order_date": {"$gte" : "${minDateFormatted}"}}`,
      `{"c_order_date": {"$lte" : "${maxDateFormatted}"}}`,
    ]*/

    if (this.selectedStatus != 'any') {
      this.addStatusFilter(filters);
    }

    if (this.selectedProviderLocation.length) {
      const locationFilter = `{"c_provider_location": {"$in": [${this.selectedProviderLocation}]}}`;
      filters.push(locationFilter);
    }

    if (this.selectedInsurance._id) {
      const insuranceFilter = `{"c_insurance" : "${this.selectedInsurance._id}"}`;
      filters.push(insuranceFilter);
    }

    if (this.selectedCreator.id) {
      const creatorFilter = `{"c_fitter" : "${this.selectedCreator.id}"}`;
      filters.push(creatorFilter);
    }

    if (this.selectedFitter.id) {
      const fitterFilter = `{"c_designated_fitter" : "${this.selectedFitter.id}"}`;
      filters.push(fitterFilter);
    }

    return filters;
  }

  buildOrderFilterQuery() {
    const filterList = this.buildFilterList();
    const condition = `where={"$and": [${filterList.join(',')}]}`;
    const extra = ``;
    const query = `${condition}`;
    // console.log("Orders Filter: ", query);
    return query;
  }

  getOrderStatus(order) {
    const status = order.c_draft
      ? 'Draft'
      : order.c_billed_date
      ? 'Billed'
      : 'Dispatched';
    return status;
  }

  getOrderCreator(order) {
    const creatorName = order.c_fitter.name;
    const firstName = creatorName.first;
    const lastName = creatorName.last;

    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  getDesignatedFitter(order) {
    const creatorName = order.c_designated_fitter.name;
    const firstName = creatorName.first;
    const lastName = creatorName.last;

    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  medableDateToDisplay(medableDate) {
    // console.log("medblDate: ", medableDate.getUTCDate());
    const day =
      medableDate.getUTCDate() < 10
        ? `0${medableDate.getUTCDate()}`
        : medableDate.getUTCDate();
    const month =
      medableDate.getUTCMonth() + 1 < 10
        ? `0${medableDate.getUTCMonth() + 1}`
        : medableDate.getUTCMonth() + 1;
    const year = medableDate.getUTCFullYear();

    const dateString = `${month}/${day}/${year}`;
    // console.log("DateString: ", dateString);
    return dateString;
  }

  goToViewOrder(orderId) {
    this.router.navigate(['forms/orders/:id/view', { id: orderId }]);
  }

  getOrderLineItems(orderId, orderIndex) {
    const expandFields = [
      'c_product',
      'c_product.c_supplier_id',
      'c_modifier.c_modifier_ref',
    ];

    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const filter = `where={"c_medable_order" : "${orderId}"}`;
    const query = `?${filter}&${expands}`;

    this.orderService.getOrderLineWithICD(query).subscribe((orderLineItems) => {
      orderLineItems.data.map((orderLineItem) => {
        const orderLineItemObject = { ...orderLineItem };
        orderLineItemObject.order = this.orders[orderIndex];
        const oPhysician =
          orderLineItemObject.order.c_provider_location.c_physician;
        const ppfx = oPhysician.c_preffix ? `${oPhysician.c_preffix} ` : '';
        const pfn = oPhysician.c_name.c_first_name;
        const pln = oPhysician.c_name.c_last_name;
        const psfx = oPhysician.c_suffix ? ` ${oPhysician.c_suffix}` : '';
        orderLineItemObject.order[
          'providerName'
        ] = `${ppfx}${pfn} ${pln}${psfx}`;
        orderLineItemObject['modifiers'] = '-';
        const modifiers = orderLineItemObject.c_modifier.filter(
          (m) => m.c_checked
        );
        if (modifiers.length) {
          orderLineItemObject['modifiers'] = modifiers
            .map((m) => m.c_modifier)
            .join(' | ');
        }

        if (!orderLineItemObject.c_sales_tax) {
          orderLineItemObject.c_sales_tax = 0;
        }
        if (orderLineItemObject.c_product.c_product_options != undefined) {
          orderLineItemObject.c_product.cpy_product_option = orderLineItemObject.c_product.c_product_options.filter(
            (opt) => opt.c_code === orderLineItemObject.c_product_option_code
          )[0];
        }

        if (orderLineItemObject.c_secondary_insurance) {
          // console.log("ORder with secondary insurance: ", orderLineItemObject);
        }
        this.orderLineItemsFilter.push(orderLineItemObject);
      });
      this.ordersToProcess--;

      this.lineItemsLoaded = true;
      // console.log("Loaded Order List: ", this.orderLineItemsFilter);
    });
  }

  dateToMedableFormat(datePickerFormat) {
    const year = datePickerFormat.date.year;
    const month =
      datePickerFormat.date.month >= 10
        ? datePickerFormat.date.month
        : '0' + datePickerFormat.date.month;
    const day =
      datePickerFormat.date.day >= 10
        ? datePickerFormat.date.day
        : '0' + datePickerFormat.date.day;

    return year + '-' + month + '-' + day;
  }

  searchFormValid() {
    return this.minDateModel && this.maxDateModel;
  }

  getLocationFitter() {
    // console.log("Getting LocationFitters");

    this.formsService.get('accounts').subscribe((account) => {
      const fitterId = account.data[0]._id;
      // console.log("FitterID: ", fitterId);

      // get the locations of the user
      this.formsService
        .get(
          'account_locations',
          '?paths[]=c_location._id&where={"c_fitter":"' + fitterId + '"}'
        )
        .subscribe((locations) => {
          let locationsId = locations.data.map(function (obj) {
            return '"' + obj.c_location._id + '"';
          });
          // get the provider-locations id with the locations where the user belongs to
          this.formsService
            .get(
              'providerLocations',
              '?paths[]=_id&where={"c_location._id":{"$in":[' +
                locationsId +
                ']}}'
            )
            .subscribe((providerLocations) => {
              this.locations = providerLocations.data.map(function (obj) {
                return '"' + obj._id + '"';
              });
              // console.log("this locations: ", this.locations);
              this.getOrders();
            });
        });
    });
  }

  nextPage() {
    // this.actualPage++;
    // this.getOrders(1);
    this.loadPage(this.actualPage + 1);
  }

  prevPage() {
    // this.actualPage--;
    // this.getOrders(-1);
    this.loadPage(this.actualPage - 1);
  }

  goToPage(pageNumber) {
    this.actualPage = pageNumber;
    this.getOrders(pageNumber, true);
  }

  exportDashboard() {
    this.csvHeader = [
      this.languagePipe.transform('dashboard.order'),
      this.languagePipe.transform('dashboard.date'),
      this.languagePipe.transform('dashboard.status'),
      this.languagePipe.transform('dashboard.billed_date'),
      this.languagePipe.transform('dashboard.claim_id'),
      this.languagePipe.transform('dashboard.mrn'),
      this.languagePipe.transform('dashboard.patient_name'),
      this.languagePipe.transform('dashboard.creator.name'),
      this.languagePipe.transform('dashboard.fitter.name'),
      this.languagePipe.transform('dashboard.hcpcs'),
      this.languagePipe.transform('dashboard.item_name'),
      this.languagePipe.transform('dashboard.product_option_code'),
      this.languagePipe.transform('dashboard.quantity'),
      this.languagePipe.transform('dashboard.size'),
      this.languagePipe.transform('dashboard.vendor'),
      this.languagePipe.transform('dashboard.provider'),
      this.languagePipe.transform('dashboard.location'),
      this.languagePipe.transform('dashboard.primary_insurance'),
      this.languagePipe.transform('dashboard.primary_insurance_id'),
      this.languagePipe.transform('dashboard.secondary_insurance'),
      this.languagePipe.transform('dashboard.secondary_insurance_id'),
      this.languagePipe.transform('dashboard.charge_out_cost'),
      this.languagePipe.transform('dashboard.allowable_cost'),
      this.languagePipe.transform('dashboard.self_pay_cost'),
      this.languagePipe.transform('dashboard.sales_tax'),
      this.languagePipe.transform('dashboard.modifiers'),
      this.languagePipe.transform('dashboard.icds'),
    ];
    const min = this.dateService.format(this.minDateModel, 'MM_dd_yyyy') || '';
    const max = this.dateService.format(this.maxDateModel, 'MM_dd_yyyy') || '';

    // console.log("Exporting Min: ", min, " Exporting Max", max);
    const csvName = 'Export ' + min + ' - ' + max + '.csv';

    this.exportData = [];
    this.exportDataOrder = [];

    const self = this;

    // console.log("Getting Export Data...");
    this.getExportData().subscribe(() => {
      // console.log("Data Ready");
      let fileTxt = self.exportData.map(function (obj) {
        let order = self.exportDataOrder.filter(function (ord) {
          return ord._id === obj.c_medable_order._id;
        })[0];
        // console.log("obj: ", obj);
        // console.log("order: ", order);
        let oli = [
          order.c_number_id ? '"' + order.c_number_id + '"' : '-',
          order.c_order_date
            ? '"' +
              self.medableDateToDisplay(new Date(order.c_order_date)) +
              '"'
            : '-',
          order.status ? '"' + order.status + '"' : '-',
          order.c_billed_date
            ? '"' +
              self.medableDateToDisplay(new Date(order.c_billed_date)) +
              '"'
            : '-',
          order.c_claim_id ? order.c_claim_id.replace(/"/g, "''") : '-',
          order.c_patient.c_mrn
            ? '"' + order.c_patient.c_mrn.replace(/"/g, "''") + '"'
            : '-',
          order.c_patient.c_name
            ? '"' +
              order.c_patient.c_name.c_first_name.replace(/"/g, "''") +
              ' ' +
              order.c_patient.c_name.c_last_name.replace(/"/g, "''") +
              '"'
            : '-',
          order.status ? '"' + order.creatorName + '"' : '-',
          order.status ? '"' + order.fitterName + '"' : '-',
          obj.c_product.c_hcpcs_code
            ? '"' + obj.c_product.c_hcpcs_code.replace(/"/g, "''") + '"'
            : '-',
          obj.c_product.c_name
            ? '"' + obj.c_product.c_name.replace(/"/g, "''") + '"'
            : '-',
          obj.c_product_option_code
            ? '"' + obj.c_product_option_code.replace(/"/g, "''") + '"'
            : '-',
          obj.c_quantity ? '"' + obj.c_quantity + '"' : '-',
          obj.c_product.cpy_product_option != undefined
            ? obj.c_product.cpy_product_option.c_size != undefined
              ? '"' +
                obj.c_product.cpy_product_option.c_size.replace(/"/g, "''") +
                '"'
              : '-'
            : '-',
          obj.c_product.c_supplier_id
            ? '"' +
              obj.c_product.c_supplier_id.c_company.replace(/"/g, "''") +
              '"'
            : '-',
          order.providerName
            ? '"' + order.providerName.replace(/"/g, "''") + '"'
            : '-',
          order.c_provider_location
            ? order.c_provider_location.c_location
              ? '"' +
                order.c_provider_location.c_location.c_company.replace(
                  /"/g,
                  "''"
                ) +
                '"'
              : '-'
            : '-',
          order.c_insurance
            ? '"' + order.c_insurance.c_insurance_name.replace(/"/g, "''") + '"'
            : '-',
          order.c_primary_insurance_id_number
            ? '"' +
              order.c_primary_insurance_id_number.replace(/"/g, "''") +
              '"'
            : '-',
          order.c_secondary_insurance
            ? '"' +
              order.c_secondary_insurance.c_insurance_name.replace(/"/g, "''") +
              '"'
            : '-',
          order.c_secondary_insurance_id_number
            ? '"' +
              order.c_secondary_insurance_id_number.replace(/"/g, "''") +
              '"'
            : '-',
          obj.c_charge_out_cost ? '"' + obj.c_charge_out_cost + '"' : '-',
          obj.c_allowable_cost ? '"' + obj.c_allowable_cost + '"' : '-',
          obj.c_self_pay_cost ? '"' + obj.c_self_pay_cost + '"' : '-',
          obj.c_sales_tax ? '"' + obj.c_sales_tax + '"' : '-',
          obj.c_modifier
            ? '"' +
              obj.c_modifier
                .filter((m) => m.c_checked)
                .map((m) => m.c_modifier)
                .join(' | ') +
              '"'
            : '-',
          obj.icds_expanded ? '"' + obj.icds_expanded.join(' | ') + '"' : '-',
        ];

        return oli.join(',');
      });

      let mappedTxt = fileTxt.join('\n');
      mappedTxt = self.csvHeader.join(',') + '\n' + mappedTxt;
      const file = new Blob([mappedTxt], { type: 'text/plain;charset=utf-8' });
      file['name'] = csvName;
      this.filesaver.saveAs(file);
    });
  }

  getExportData() {
    const localSubject = new Subject();
    let allOrders = [];
    const chunck = 10;
    const minDate = this.dateService.format(this.minDateModel);
    const maxDate = this.dateService.format(this.maxDateModel);
    const sort = 'sort={"_id": -1}';
    let page = 0;
    const self = this;

    const filterList = [
      `{"created" : {"$gte" : "${minDate}"}}`,
      `{"created" : {"$lte" : "${maxDate}"}}`,
      this.hasRole.admin || this.hasRole.executive
        ? ''
        : `{"c_provider_location": {"$in":['${this.locations}']}}`,
    ];
    const expandFields = [
      'creator',
      'c_patient',
      'c_insurance',
      'c_provider_location.c_physician',
      'c_provider_location.c_location',
      'c_secondary_insurance',
      'c_designated_fitter',
      'c_fitter',
    ];

    const filters =
      'where={"$and":[' +
      filterList.filter((filter) => filter != '').join(',') +
      ']}';
    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const query = `?${filters}&${expands}&${sort}`;

    function loadChunck() {
      self.formsService
        .get('orders', query + `&limit=${chunck}&skip=${page * chunck}`)
        .subscribe((orders) => {
          const ids = [];
          orders.data.map((order) => {
            const orderObj = { ...order };
            const oPhysician = orderObj.c_provider_location.c_physician;
            const ppfx = oPhysician.c_preffix ? `${oPhysician.c_preffix} ` : '';
            const pfn = oPhysician.c_name.c_first_name;
            const pln = oPhysician.c_name.c_last_name;
            const psfx = oPhysician.c_suffix ? ` ${oPhysician.c_suffix}` : '';
            orderObj['providerName'] = `${ppfx}${pfn} ${pln}${psfx}`;
            orderObj['status'] = self.getOrderStatus(order);
            orderObj['creatorName'] = order.c_fitter
              ? self.getOrderCreator(order)
              : '-';
            orderObj['fitterName'] = order.c_designated_fitter
              ? self.getDesignatedFitter(order)
              : '-';
            orderObj.c_order_date = new Date(orderObj.c_order_date);
            orderObj.c_patient.c_dob = new Date(orderObj.c_patient.c_dob);
            ids.push(`"${orderObj._id}"`);
            self.exportDataOrder.push(orderObj);
          });

          self.getOrderLineItemsExport(ids).subscribe(() => {
            if (orders.hasMore) {
              page++;
              loadChunck();
            } else {
              localSubject.next();
              localSubject.complete();
            }
          });
        });
    }

    loadChunck();
    return localSubject;
  }

  getOrderLineItemsExport(ids) {
    const localSubject = new Subject();
    const expands = 'expand[]=c_product&expand[]=c_product.c_supplier_id';
    const filter = `where={"c_medable_order":{"$in":[${ids}]}}`;
    const query = `?${filter}&${expands}`;

    this.orderService.getOrderLineWithICD(query).subscribe((data) => {
      data = data.data;
      data.map((line) => {
        if (line.c_product.c_product_options != undefined) {
          line.c_product.cpy_product_option = line.c_product.c_product_options.filter(
            (opt) => opt.c_code === line.c_product_option_code
          )[0];
        } else {
          line.c_product.cpy_product_option = '-';
        }
      });
      this.exportData = this.exportData.concat(data);

      localSubject.next(data);
      localSubject.complete();
    });

    return localSubject;
  }

  searchLocationGroupWrapper() {
    let searchLocationGroup = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          // console.log("searching: ", term);
          if (term === '') {
            return [];
          } else {
            const locationGroups = this.allLocationGroups.filter(
              (locationGroup) => {
                const matches =
                    locationGroup.c_group_name
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1,
                  alreadyAdded =
                    this.addedLocationGroups.filter(
                      (li) => li._id == locationGroup._id
                    ).length > 0;

                if (matches && !alreadyAdded) {
                  return true;
                }
              }
            );
            // console.log("Found Locations: ", locationGroups);
            return locationGroups.length ? locationGroups : [];
          }
        })
      );
    };

    return searchLocationGroup;
  }

  // searchHcpcsWrapper

  searchHcpcsWrapper() {
    let searchHcpcs = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.allHcpcsCodes.filter(
              (code) =>
                code.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            // console.log("Found Codes: ", codes);
            return codes.length ? codes : [];
          }
        })
      );
    };

    return searchHcpcs;
  }

  searchInsuranceWrapper() {
    let searchInsurance = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.allInsurances.filter(
              (ins) =>
                ins.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                -1
            );
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return searchInsurance;
  }

  searchCreatorWrapper() {
    let searchCreator = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const email = this.allUsers.filter(
              (user) =>
                user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const firstName = this.allUsers.filter(
              (user) =>
                user.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const lastName = this.allUsers.filter(
              (user) =>
                user.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );

            // console.log("Found Users: ", email.length ? email : firstName.length ? firstName : lastName.length ? lastName : []);
            return email.length
              ? email
              : firstName.length
              ? firstName
              : lastName.length
              ? lastName
              : [];
          }
        })
      );
    };

    return searchCreator;
  }

  searchFitterWrapper() {
    let searchFitter = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const email = this.allUsers.filter(
              (user) =>
                user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const firstName = this.allUsers.filter(
              (user) =>
                user.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const lastName = this.allUsers.filter(
              (user) =>
                user.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );

            // console.log("Found Users: ", email.length ? email : firstName.length ? firstName : lastName.length ? lastName : []);
            return email.length
              ? email
              : firstName.length
              ? firstName
              : lastName.length
              ? lastName
              : [];
          }
        })
      );
    };

    return searchFitter;
  }

  locationGroupInputFocus() {
    this.selectedLocationGroup = {
      c_company: '',
    };
    this.locationGroupSelected = false;
  }

  locationGroupInputFocusOut() {
    setTimeout(() => {
      if (!this.locationGroupSelected) {
        this.selectedLocationGroup = {
          c_company: '',
        };
      }
    }, 500);
  }

  hcpcsInputFocus() {
    this.selectedHcpcs = {
      c_hcpcs_code: '',
    };
    this.hcpcsSelected = false;
  }

  hcpcsInputFocusOut() {
    setTimeout(() => {
      if (!this.hcpcsSelected) {
        this.selectedHcpcs = {
          c_hcpcs_code: '',
        };
      }
    }, 500);
  }

  buildHcpcsFilter(hcpcs) {
    const productIds = this.allProducts
      .filter((p) => p.c_hcpcs_code == hcpcs.c_hcpcs_code)
      .map((prod) => `"${prod._id}"`);

    this.productFilterIds = productIds;
    // console.log("Filtered productIds: ", productIds);
  }

  selectHcpcs(hcpcs) {
    this.buildHcpcsFilter(hcpcs);
    this.selectedHcpcs = hcpcs;
    this.hcpcsSelected = true;
  }

  userInputFocus() {
    this.selectedCreator = {
      firstName: '',
      lastName: '',
      email: '',
    };
    this.userSelected = false;
  }

  userInputFocusOut() {
    setTimeout(() => {
      if (!this.userSelected) {
        this.selectedCreator = {
          firstName: '',
          lastName: '',
          email: '',
        };
      }
    }, 500);
  }

  selectUser(user) {
    this.selectedCreator = user;
    this.userSelected = true;
  }

  fitterInputFocus() {
    this.selectedFitter = {
      firstName: '',
      lastName: '',
      email: '',
    };
    this.userSelected = false;
  }

  fitterInputFocusOut() {
    setTimeout(() => {
      if (!this.fitterSelecrted) {
        this.selectedFitter = {
          firstName: '',
          lastName: '',
          email: '',
        };
      }
    }, 500);
  }

  selectFitter(fitter) {
    this.selectedFitter = fitter;
    this.fitterSelecrted = true;
  }

  selectLocationGroup(locationGroup) {
    this.addedLocationGroups.push(locationGroup);
    // this.buildProviderLocationIds();
    //this.selectedLocation = location;
    //this.locationSelected = true;
    // console.log("Current LocationItems: ", this.locationItems);
    // console.log("Added LocationGroups: ", this.addedLocationGroups)
  }

  removeLocationGroup(index) {
    this.addedLocationGroups.splice(index, 1);
  }

  insuranceInputFocus() {
    this.selectedInsurance = {
      c_insurance_name: '',
    };
    this.insuranceSelected = false;
  }

  insuranceInputFocusOut() {
    setTimeout(() => {
      if (!this.insuranceSelected) {
        this.selectedInsurance = {
          c_insurance_name: '',
        };
      }
    }, 500);
  }

  selectInsurance(insurance) {
    // this.buildProviderLocationIds(location);
    this.selectedInsurance = insurance;
    this.insuranceSelected = true;
  }

  buildProviderLocationIds() {
    // console.log("Building ids for location: ", location);
    let providerLocationIds = [];
    // const locationId = location._id;
    // this.selectedProviderLocation = this.allProviderLocations
    //                                     .filter( pLoc =>
    //                                       pLoc.c_location._id == locationId
    //                                     ).map( pLoc =>
    //                                       `"${pLoc._id}"`
    //                                     );
    this.locationItems.map((li) => {
      const addedLocationIds = this.allProviderLocations
        .filter((pLoc) => pLoc.c_location._id == li._id)
        .map((pLoc) => `"${pLoc._id}"`);
      providerLocationIds = providerLocationIds.concat(addedLocationIds);
    });

    this.selectedProviderLocation = providerLocationIds;
    // console.log("Selected Provider Locations: ", providerLocationIds);
  }

  locationGroupFormatter = (x: { c_group_name: string }) => x.c_group_name;

  insuranceFormatter = (x: { c_insurance_name: string }) => x.c_insurance_name;

  creatorFormatter = (x: {
    firstName: string;
    lastName: string;
    id?: string;
  }) => (x.id ? `${x.firstName} ${x.lastName}` : '');

  fitterFormatter = (x: { firstName: string; lastName: string; id?: string }) =>
    x.id ? `${x.firstName} ${x.lastName}` : '';

  hcpcsFormatter = (x: { c_hcpcs_code: string }) => x.c_hcpcs_code;

  loadOrdersToInvoice() {
    console.log('*** Loading orders to invoice...');

    //let test = ["5e34b7dbb6733c0100d3d57b", "5e3499f6c979130100648c9d"];

    // First batch of lineItems
    let batch1 = [
      '5e54839455a83c0100212d00',
      '5e5483944417df0100ab13b6',
      '5e547a97a6d06901000ebf69',
      '5e547a97a6d06901000ebf67',
      '5e4d74a49d820601001d5f6d',
      '5e4d74a49d820601001d5f6b',
      '5e4d74a49d820601001d5f68',
      '5e4d74a454d6830100a5fb82',
      '5e4d74a420ecf50100f1aec6',
      '5e4d74a41205aa01005390f2',
      '5e4d7354ec72f40100092794',
      '5e4d73542c9d7c0100864289',
      '5e59880353afe50100bf01f5',
      '5e5981b955a83c0100b266ab',
      '5e5965638f2d2501003536a3',
      '5e59630cfc82930100a82987',
      '5e5946b0d838f10100d32108',
      '5e5839a704ff830100aed946',
      '5e582f8874dbab01003d1b4b',
      '5e5805fc04ff830100a5d551',
      '5e57f94174dbab010032b084',
      '5e57f22ad14f600100482591',
      '5e57ec34a6d06901006f8dd0',
      '5e5716c574dbab01001abf95',
      '5e56fe318f2d250100eb4f70',
      '5e56fcedd14f6001002e16c3',
      '5e56f125fc829301005f65c8',
      '5e56edeefc829301005f3c6a',
      '5e56ededfc829301005f3c68',
      '5e56ec448f2d250100ea5ac7',
      '5e56db1474dbab0100161fbb',
      '5e56bfa553afe501006d663d',
      '5e56bdc0d838f1010089d204',
      '5e56bdb2a6d069010050e541',
      '5e56bcd404ff83010081f832',
      '5e56baed31b6ce01009a07ae',
      '5e56b8b9a711f30100dc7000',
      '5e56b7bb3ba74f0100bd8a9a',
      '5e569f8fa6d06901004a7735',
      '5e569ad974dbab01000ae435',
      '5e55aa688f2d250100c3d561',
      '5e559f1fa6d069010030ada4',
      '5e559d41d14f60010005fc9e',
      '5e559aa6ffb9b901002720a5',
      '5e5590f8a6d06901002fdd5e',
      '5e5579b504ff8301005e510c',
      '5e55712e4417df0100c8d619',
      '5e55705b55a83c01003c936e',
      '5e556c0bd14f600100006722',
      '5e5568ae8da56a0100b51a21',
      '5e5559b18f2d250100b8311c',
      '5e5559b18f2d250100b8311a',
      '5e55561c55a83c0100368a0a',
      '5e55474aa6d0690100243322',
      '5e5463024417df0100a76979',
      '5e545769fc82930100148854',
      '5e545769fc82930100148852',
      '5e54575404ff8301003be042',
      '5e5457456564cf010023281c',
      '5e5457446564cf010023281a',
      '5e5456e58f2d2501009e069c',
      '5e545337d14f600100e0a10a',
      '5e5452f24417df0100a6e64a',
      '5e54268b06476e01000a0ee5',
      '5e54204f93429301008e2995',
      '5e5418d9aac30d010029d35a',
      '5e541866a6d0690100044097',
      '5e541866a6d0690100044095',
      '5e5411f91a96fc0100d45d30',
      '5e54068955a83c010010e97d',
      '5e54031555a83c010010aca7',
      '5e53f6da02f5bf0100c7d7c3',
      '5e5167af0e92b40100d0825e',
      '5e50ab62934293010028aea3',
      '5e508c140e92b40100b913ae',
      '5e5052136564cf01009c4b59',
      '5e503a5a06476e0100920646',
      '5e5036042c905601009f28ad',
      '5e62cf7de72e5b0100aff8e8',
      '5e501fa52c9056010099a168',
      '5e501fa52c9056010099a166',
      '5e50174c6564cf01008ed3b8',
      '5e5012fc29b3120100beb3cf',
      '5e4f52e404ff830100a4bd3c',
      '5e4f52e304ff830100a4bd3a',
      '5e4f5050f322c901009bcb53',
      '5e4f1be30245360100c8c35c',
      '5e4f1be30245360100c8c35a',
      '5e4eff8b1205aa0100758c68',
      '5e4efc332b5fb601000c7357',
      '5e4ef9ac82b5480100add069',
      '5e4ef59a54d6830100cc38b1',
      '5e4ef26f9d820601003e361f',
      '5e4ed36864e4eb0100283638',
      '5e4ecf6382b5480100a8bf7e',
      '5e4eca951205aa01006f33b7',
      '5e4ec82520ecf501000d4f25',
      '5e4eb4c22b5fb6010004d557',
      '5e4eb1f4ec72f4010023d217',
      '5e4ea6128876680100082cd1',
      '5e4dd2462c9d7c010090c3c0',
      '5e4dd001b6733c010096370f',
      '5e4dc75a64e4eb0100107266',
      '5e4dbe60cd511e010072528a',
      '5e4dae12e62f840100fedaa8',
      '5e4da1b2cd511e0100705c2c',
      '5e4d9f0054d6830100ae37cb',
      '5e4d8421455b94010038a16b',
      '5e4d8421455b94010038a169',
      '5e4d62bd54d6830100a43338',
      '5e4c83192c9d7c010071f5c4',
      '5e4c7bcc2b5fb60100d61058',
      '5e4c58f17b673a0100cdb480',
      '5e4c58f17b673a0100cdb47e',
      '5e4c568edbf42a01009651e0',
      '5e59c5d704ff830100dae0a6',
      '5e59ac14a711f301003a4d02',
      '5e5980cffc82930100ac5cfb',
      '5e596f0f0309730100797581',
      '5e59686f8f2d25010035e612',
      '5e594d2e8f2d2501002e46d7',
      '5e593ab153afe50100b3057a',
      '5e584d7d6564cf01009e591c',
      '5e584afd74dbab01003ebc65',
      '5e584afd6564cf01009e3445',
      '5e5831d0d838f10100b61dc0',
      '5e58257955a83c01008b45f9',
      '5e5818920309730100522576',
      '5e580f759342930100fb52e0',
      '5e58016b8f2d2501000703c6',
      '5e58000c8f2d25010006ead4',
      '5e57f94a8f2d2501000674a4',
      '5e57e4748f2d25010003b806',
      '5e57e4748f2d25010003b803',
      '5e573155a6d06901005b8d05',
      '5e56fbd574dbab01001845ab',
      '5e56f8d253afe5010072d2ed',
      '5e5973c955a83c0100b13b20',
      '5e584c3c53afe5010099a545',
      '5e5836100309730100552069',
      '5e5831a453afe50100983e9d',
      '5e5819376564cf010099d19f',
      '5e56f28804ff83010087ef81',
      '5e56f09ba6d0690100567bab',
      '5e56ecf96564cf0100744172',
      '5e56e9f68f2d250100ea486f',
      '5e56e62ad838f101008ed7cb',
      '5e56df6ed14f6001002c7992',
      '5e56dd3674dbab01001651bf',
      '5e56d9e255a83c0100650a74',
      '5e56d9b455a83c01006509ea',
      '5e56d7b0a711f30100e2d725',
      '5e56d5fb53afe5010070bb09',
      '5e56d36c55a83c0100647909',
      '5e56d36c55a83c0100647907',
      '5e56ccba9342930100d994f7',
      '5e56cbb98f2d250100e7bf44',
      '5e56c25d8f2d250100e6175e',
      '5e56bd8653afe501006c9df8',
      '5e56ba52fc8293010059147b',
      '5e56b5d6a6d06901004de150',
      '5e56b5d6a6d06901004de14e',
      '5e56b46fa6d06901004d5bd8',
      '5e56b0c9a711f30100d93aab',
      '5e56b00ba6d06901004bd93c',
      '5e56b00ba6d06901004bd93a',
      '5e56ae4c55a83c01005c371b',
      '5e56ac6f74dbab01000c144a',
      '5e56a32353afe5010066779f',
      '5e56a1ec74dbab01000b6741',
      '5e56a168fc8293010053d818',
      '5e56a0a59342930100d07989',
      '5e56a0a59342930100d07987',
      '5e569cb3d838f1010083b661',
      '5e569c96d14f600100210e8a',
      '5e569af9d14f60010020e877',
      '5e56998304ff8301007b9996',
      '5e56959ea6d0690100496ac7',
      '5e56934a55a83c010059aee1',
      '5e55f133ad05dd0100d64ab3',
      '5e55df37fc829301003e4fbb',
      '5e55b61b9342930100b8068e',
      '5e55b61b8f2d250100c487a8',
      '5e55af7574dbab0100f162c7',
      '5e55af7574dbab0100f162c5',
      '5e55ad309342930100b78fa3',
      '5e55ad309342930100b78fa1',
      '5e55a4cd8f2d250100c39d15',
      '5e55a3904417df0100ccfb12',
      '5e559cfd4417df0100ccbe84',
      '5e559c8574dbab0100f07fc7',
      '5e559bb774dbab0100f07963',
      '5e5597906564cf01004a2877',
      '5e5596db55a83c0100408a8b',
      '5e55942504ff83010060620f',
      '5e5593386564cf010049cac9',
      '5e55933704ff8301006058cc',
      '5e55933704ff8301006058ca',
      '5e5592aead05dd0100cf9f56',
      '5e558d9ca6d06901002fc150',
      '5e558d3aa6d06901002fb3fb',
      '5e5588da55a83c01003f9ee3',
      '5e5588da55a83c01003f9ee1',
      '5e5587134417df0100cb8b49',
      '5e5585b98f2d250100c19c6a',
      '5e55814bad05dd0100ce7ce1',
      '5e55814bad05dd0100ce7cdf',
      '5e55814b6564cf0100483f3b',
      '5e557bdca6d06901002e565e',
      '5e557a47a6d06901002e2ca9',
      '5e557a474417df0100ca7b0f',
      '5e557a474417df0100ca7b0b',
      '5e5576c2fc8293010036645c',
      '5e55760afc829301003650f6',
      '5e55760afc829301003650f4',
      '5e55760afc829301003650f2',
      '5e55758b9342930100b37d07',
      '5e5573d555a83c01003d4c41',
      '5e5572aba6d06901002cf6c5',
      '5e557261a6d06901002cf3bb',
      '5e55718d04ff8301005d37c9',
      '5e556e914417df0100c82c45',
      '5e556e9104ff8301005c6cd2',
      '5e556e9104ff8301005c6cd0',
      '5e556e274417df0100c81b44',
      '5e556c788f2d250100bd9d0e',
      '5e556bd96564cf01004472d0',
      '5e556bd904ff8301005b9ed8',
      '5e556bd904ff8301005b9ec0',
      '5e556b8574dbab0100eb27e7',
      '5e5569238f2d250100bcbc0b',
      '5e5566c66564cf0100428639',
      '5e5566c604ff8301005a08e4',
      '5e556430d14f600100fd737e',
      '5e55630189028c0100f021e8',
      '5e5561f0fc8293010030f04a',
      '5e555c3ce8ff0a0100d02668',
      '5e5555b24417df0100c1f079',
      '5e5554cba6d0690100258957',
      '5e55549affb9b901001bad40',
      '5e55549a8f2d250100b7c9b7',
      '5e55529c04ff83010055e33f',
      '5e554f16fc829301002eaebd',
      '5e554f16fc829301002eaebb',
      '5e554d5f4417df0100c15d07',
      '5e554ccba6d069010024e751',
      '5e5549b755a83c01003536cd',
      '5e55489ed14f600100f9857b',
      '5e55481274dbab0100e4f30d',
      '5e5546e304ff830100546dd2',
      '5e55462afc829301002db46c',
      '5e55462afc829301002db46a',
      '5e5545eb55a83c010034c726',
      '5e55407655a83c010033ea2f',
      '5e549f0255a83c010022b2d1',
      '5e549da94417df0100ac7a7b',
      '5e549da84417df0100ac7a79',
      '5e549a364417df0100ac58c1',
      '5e5498affc829301001a02f1',
      '5e549322d14f600100e58b5f',
      '5e5490ca4417df0100abe0b4',
      '5e5490ca4417df0100abe0b2',
      '5e5486f993429301009748da',
      '5e547d7274dbab0100d0d4c4',
      '5e547a0155a83c01002093f2',
      '5e5477b84417df0100aa2b9d',
      '5e5474a08f2d250100a104d6',
      '5e5472b555a83c01001fdd79',
      '5e546d73d14f600100e33110',
      '5e54616b04ff8301003c37e8',
      '5e545ef94417df0100a749b0',
      '5e545e9674dbab0100cd1bf4',
      '5e5456e8934293010093092f',
      '5e5456cde8ff0a0100b5ad89',
      '5e54567efc82930100148452',
      '5e545522d14f600100e0a8e6',
      '5e54517f6564cf010022f828',
      '5e544dabffb9b90100019c8c',
      '5e544da58f2d2501009da034',
      '5e544b60a6d06901000a5ef6',
      '5e54499b4417df0100a6788d',
      '5e54486104ff8301003b2e56',
      '5e54465f9342930100924ece',
      '5e5445af6564cf0100225616',
      '5e544322fc829301001391c2',
      '5e5442f24417df0100a61344',
      '5e543a6b55a83c01001b2a29',
      '5e5434b855a83c01001ab540',
    ];

    // Batch 2 of orderItems
    let batch2 = [
      '5e5426d82c9056010015221d',
      '5e5422958f2d25010099f0ae',
      '5e542239a6d069010006c042',
      '5e541e98a6d069010005f1ce',
      '5e541baba6d0690100052375',
      '5e5410c048398f01004d25e5',
      '5e54103ccfa12601004797c5',
      '5e540f2faac30d010025c196',
      '5e5409602c905601000d4ed8',
      '5e54060c02f5bf0100c99604',
      '5e5405aba6d0690100fff8ec',
      '5e5402c355a83c010010ab70',
      '5e5400f6a6d0690100ff7b09',
      '5e53fd3555a83c0100105414',
      '5e53fbf5aac30d0100241309',
      '5e53f74602f5bf0100c7f0f7',
      '5e53f2e3f322c9010026020a',
      '5e53efe006476e0100ff67f7',
      '5e53ece0a6d0690100fcc442',
      '5e515bda0245360100082774',
      '5e5093d00245360100f407a5',
      '5e5091f406476e01009ad672',
      '5e507472f322c90100c05658',
      '5e505fdf934293010023ac0a',
      '5e5055256564cf01009c8451',
      '5e504fbe934293010022a625',
      '5e503c02f322c90100bb742d',
      '5e502bad93429301001e500d',
      '5e5014e96564cf01008e9544',
      '5e5012720e92b40100a66985',
      '5e500abb0e92b40100a5eee3',
      '5e500144f322c90100aec020',
      '5e4ff8c006476e0100850e08',
      '5e4f5a9d06476e0100740517',
      '5e4f5a9d06476e0100740515',
      '5e4f43510245360100cd3cd7',
      '5e4f43510245360100cd3cd5',
      '5e4f4183a6d069010076e29f',
      '5e4f3e928acd520100fd8427',
      '5e4f3cc00e92b401008fd5ec',
      '5e4f3cc006476e010072d16f',
      '5e4f31d16564cf010074c63a',
      '5e4f293206476e0100710af8',
      '5e4f25b72c905601007e55a4',
      '5e4f201bf322c90100976136',
      '5e4f1ec1f322c90100970ffb',
      '5e4f18909342930100fe643b',
      '5e4f1056a6d069010072120f',
      '5e4f1056a6d069010072120d',
      '5e4f0cc82c905601007bac55',
      '5e4f08c904ff8301009e9deb',
      '5e4f047faac30d010090815a',
      '5e4f046bc96ad70100692a9e',
      '5e4f032bcc27550100d571bc',
      '5e4efe7a1c9885010081edf4',
      '5e4ef7adb3833e01004ddbce',
      '5e4ef75fb6733c0100b19725',
      '5e4ef56482b5480100ad9046',
      '5e4ee11d455b9401005482e3',
      '5e4ee0aa455b9401005477ae',
      '5e4edc98e62f84010017cf9e',
      '5e4ed5111c988501007e8e9d',
      '5e4ed297cd511e010088fbb9',
      '5e4ed0e1dbf42a0100cfad3c',
      '5e4ecf9f455b94010051b4a1',
      '5e4eccdeb3833e010048cd0c',
      '5e4eca5f20ecf501000dfa13',
      '5e4eca4e1c988501007ae3ed',
      '5e4ec982cd511e01008577a3',
      '5e4ec957b6733c0100aa2e53',
      '5e4ec7c7455b9401004f2712',
      '5e4ec2fc2cf9f701004054d4',
      '5e4ec1b8e62f8401001294bc',
      '5e4ebaccdbf42a0100cba060',
      '5e4eb99523d2e60100aa9ecb',
      '5e4eb8d3455b9401004e869d',
      '5e4eaa2e54d6830100c253ae',
      '5e4ea688e62f8401001030c9',
      '5e4ea688e62f8401001030c7',
      '5e4e04462cf9f70100311d8e',
      '5e4e04452cf9f70100311d8c',
      '5e4dfafcec72f40100167bc4',
      '5e4df422e62f840100034fb4',
      '5e4df09f20ecf50100fe55fa',
      '5e4deac81232a4010062a19e',
      '5e4de3c082b54801009679e6',
      '5e4ddecdec72f40100151496',
      '5e4dd629e62f8401000199a8',
      '5e4dcfd9cd511e0100736d21',
      '5e4dc07720ecf50100faf586',
      '5e4dbc1e455b9401003d211b',
      '5e4dbc1e455b9401003d2119',
      '5e4dbb66ec72f401001276e1',
      '5e4dbac2ec72f40100126977',
      '5e4dba64b6733c0100949e2d',
      '5e4dba648876680100f88d4a',
      '5e4dba648876680100f88d48',
      '5e4db9d7ec72f40100125a8a',
      '5e4db9d782b5480100930794',
      '5e4db89f54d6830100aff00a',
      '5e4db88d54d6830100afeff6',
      '5e4db88d23d2e6010097a7e9',
      '5e4db88d23d2e6010097a7b5',
      '5e4db58b455b9401003ce157',
      '5e4db5032cf9f701002bb8f6',
      '5e4db1a164e4eb01000f5ad6',
      '5e4dad1fb3833e010034172a',
      '5e4daaa99d8206010024e935',
      '5e4da8d9dbf42a0100b7f15c',
      '5e4da818dbf42a0100b7f0c7',
      '5e4da7272b5fb60100f27902',
      '5e4da60054d6830100aedc34',
      '5e4da3edcd511e0100708669',
      '5e4da29523d2e60100963efb',
      '5e4da08dcd511e0100704327',
      '5e4d9cf382b5480100914d5a',
      '5e4d981f1c98850100639df5',
      '5e4d89b2455b940100396f80',
      '5e4d85782cf9f70100277d89',
      '5e4d847854d6830100ab1dc9',
      '5e4d7efe2c9d7c0100894c80',
      '5e4d7c2cdbf42a0100b222e6',
      '5e4d79b01205aa010054be6a',
      '5e4d79071205aa0100548431',
      '5e4d7742ec72f40100098a1b',
      '5e4d75f6dbf42a0100b00e02',
      '5e4d6f0682b54801008a376b',
      '5e4d6eb21c988501005b3a95',
      '5e4d6eae8876680100efbd23',
      '5e4d6a462c9d7c0100856681',
      '5e4d6862b6733c010089e5fa',
      '5e4d67a2dbf42a0100aec55e',
      '5e4d65f5b6733c01008992d7',
      '5e4d60a10913160100d5f12e',
      '5e4d5ec620ecf50100ef7464',
      '5e4d5b651c9885010058a001',
      '5e4d58ece62f840100f26457',
      '5e4cab6520ecf50100dfdea0',
      '5e4ca72a2cf9f70100112c19',
      '5e4ca72a2cf9f70100112c17',
      '5e4c94ed455b94010022c68d',
      '5e4c92a42c9d7c0100735007',
      '5e4c90759d820601000afab2',
      '5e4c907464e4eb0100f3e4ee',
      '5e4c8fc8cd511e010056c02d',
      '5e4c8cf12c9d7c010072ea48',
      '5e4c84e52cf9f701000df73a',
      '5e4c7ebb82b5480100758898',
      '5e4c73547b673a0100d0eb27',
      '5e4c73547b673a0100d0eb25',
      '5e4c70b67b673a0100d0b277',
      '5e4c6d8d9d8206010007909b',
      '5e4c6b132c9d7c01006f879f',
      '5e4c68971c98850100438b26',
      '5e4c64dcb6733c01007282bb',
      '5e4c63e19d8206010006a819',
      '5e4c61f168f31a01007043a9',
      '5e4c5f5754d68301008d2af4',
      '5e4c5ec09d8206010005ff4b',
      '5e4c5ccfcd511e010051a3fb',
      '5e4c5ac8cd511e0100516db9',
      '5e4c5aa568f31a01006f25c8',
      '5e4c5895e62f840100dcb339',
      '5e4c57dd9d8206010005363e',
      '5e4c56512c9d7c01006d03a4',
      '5e4c55a91c98850100410eb1',
      '5e4c55060b575f0100a49bcc',
      '5e1e5d55c4dd86010099d2fb',
      '5e1def945efef3010042991a',
      '5e1cbaa3cf70530100064372',
      '5e18d2c10fe375010058d8fb',
      '5e18ca7639d9c80100f6e1a4',
      '5e18ad62cf705301008a2137',
      '5e59d3c7fc82930100b2815d',
      '5e59d25604ff830100db6e70',
      '5e59cda374dbab01006970b1',
      '5e59cda374dbab01006970af',
      '5e59c9f453afe50100c3f159',
      '5e59c38f53afe50100c39312',
      '5e59b9a574dbab01006825f1',
      '5e59b4b6a6d0690100a7e590',
      '5e59b24d55a83c0100b6a574',
      '5e59af6553afe50100c190c1',
      '5e59ad1053afe50100c1442a',
      '5e59a07d55a83c0100b47b97',
      '5e59a07d55a83c0100b47b95',
      '5e5969cd53afe50100bc0948',
      '5e5964709342930100222b38',
      '5e595caa55a83c0100ac4b5b',
      '5e595bf4fc82930100a5e9d5',
      '5e595ad553afe50100b7f80a',
      '5e59592c8f2d25010030eabd',
      '5e5957d1d838f10100d5fe16',
      '5e5955dbfc82930100a37f21',
      '5e5954630309730100718e22',
      '5e594f9693429301001ca3d2',
      '5e594f4593429301001c9867',
      '5e594c82a711f301002b6b1b',
      '5e594c3dd838f10100d3a81a',
      '5e59495cfc82930100a22465',
      '5e59461cd838f10100d31465',
      '5e5945d653afe50100b4192e',
      '5e5945a574dbab01005904bc',
      '5e5936e455a83c0100a60b0c',
      '5e592e78d838f10100cf696b',
      '5e586ce5d838f10100bb485c',
      '5e5867cb934293010003d83c',
      '5e5855776564cf01009ec295',
      '5e5852e9d838f10100b7e1f5',
      '5e5852e9d838f10100b7e1f3',
      '5e58502653afe5010099bdc7',
      '5e58443f030973010055cc87',
      '5e5841e9fc8293010086ac72',
      '5e5841e9030973010055b7c2',
      '5e583c3653afe5010098c5ca',
      '5e5839b855a83c01008ce564',
      '5e58381474dbab01003dcace',
      '5e582e14a711f301000dbe5c',
      '5e582c5104ff830100adff00',
      '5e5829b28f2d250100107707',
      '5e58200da711f301000bfcc2',
      '5e58200da711f301000bfcc0',
      '5e581d019342930100fe03d0',
      '5e581bb0d838f10100b3a237',
      '5e581a388f2d2501000e8807',
      '5e5816058f2d2501000df5d2',
      '5e58145374dbab010039ed5e',
      '5e580edb53afe501009301d2',
      '5e580d14a711f3010007a9b8',
      '5e580b96fc829301007fa7fb',
      '5e57ffa9a6d0690100717d13',
      '5e57fcb5d14f600100491faf',
      '5e57fbc9d14f600100491884',
      '5e57f67b53afe501008d0a80',
      '5e57f65ca711f3010001e295',
      '5e57f597a711f3010001cde7',
      '5e57e8e604ff830100a1d8bd',
      '5e57e72674dbab0100306ea9',
      '5e57e1d555a83c01007ef593',
      '5e57e1d555a83c01007ef591',
      '5e57da9555a83c01007d502c',
      '5e573ffc9342930100e16574',
      '5e5737c9d838f1010094ad55',
      '5e57364f8f2d250100f02658',
      '5e5733d9d838f10100948547',
      '5e572e9cd14f6001003244d8',
      '5e572e9cd14f6001003244d6',
      '5e572b1604ff8301008d106b',
      '5e57243ba6d06901005aa3c0',
      '5e57204653afe5010076844e',
      '5e570476d14f6001002e6e72',
      '5e57047274dbab010018c0ad',
      '5e57027555a83c01006736d1',
      '5e57006b6564cf0100750406',
      '5e56ff226564cf010074f622',
      '5e56fa9c53afe5010072e998',
      '5e56fa9c53afe5010072e996',
      '5e56f5bbd14f6001002ddd23',
      '5e56f5bbd14f6001002ddd21',
      '5e56f4dba711f30100e49b28',
      '5e56b35d663a6001002f1a9d',
      '5e56b2b0a711f30100d9b5e2',
      '5e56b05304ff8301007d790a',
      '5e56aff774dbab01000c402f',
      '5e56aff774dbab01000c402d',
      '5e56ac526564cf0100688562',
      '5e56ab6fa711f30100d8d11f',
      '5e56ab6f6564cf01006877a4',
      '5e569a2b74dbab01000add5d',
      '5e55da43ad05dd0100d52b37',
      '5e558f36a6d06901002fd3aa',
      '5e557059ad05dd0100cc5e92',
      '5e5567b0ffb9b901002057a0',
      '5e5551766564cf01003e0a77',
      '5e5542dbfc829301002d434e',
      '5e548deed14f600100e559e7',
      '5e548a1c04ff8301004094c4',
      '5e5465fa6564cf0100241404',
      '5e545ccee8ff0a0100b5e1bd',
      '5e545bd3ffb9b90100023b1f',
      '5e5433f2ffb9b90100002340',
      '5e541b2702453601005e0466',
      '5e540eb4f322c9010028dab4',
      '5e50a02f06476e01009ba3ad',
      '5e50885306476e01009a3aaf',
      '5e507063a6d069010099a090',
      '5e506f27aac30d0100bb1669',
      '5e5007656564cf01008d593d',
      '5e4f223f9342930100ffc943',
      '5e4f165004ff8301009f6789',
      '5e4efc0eb6733c0100b1c5db',
      '5e4efa431c9885010081b562',
      '5e4ef4b22b5fb601000bfb5e',
      '5e4eef71455b940100559c95',
      '5e4ed5f582b5480100aa6f51',
      '5e4ec74b2b5fb6010005d256',
      '5e4dc5012c9d7c01008f7aef',
      '5e4daf0a54d6830100af6a15',
      '5e4dab69cd511e01007103fa',
      '5e4d6b911c988501005ae607',
      '5e4d69ea64e4eb0100059952',
      '5e4d6306455b940100334db0',
      '5e4d63061c9885010059d938',
      '5e4d63051c9885010059d936',
      '5e4c664554d68301008de941',
      '5e4c63a6b3833e0100140872',
      '5e4c6198e62f840100ddd410',
      '5e18a67c39d9c80100f09640',
    ];

    // let batch1Processed = batch1.map(id => `"${id}"`).join();
    // let query = `?where={"$and": [{"_id": {"$in": [${batch1Processed}]}}, {"c_invoiced": false}]}&limit=10000`;
    // // Load orders
    // this.formsService.get("orderLineItems", query).subscribe(res => {
    //   console.log("***===Batch1 Orders to invoice loaded: ", res);
    //   res.data.forEach(item => {
    //     //console.log("### Item is: ", item);
    //     let upd = {
    //       id: item._id,
    //       value: {
    //         c_invoiced: true,
    //         c_invoice_number: "TOI02292020"
    //       }
    //     };

    //     //Make request
    //     // this.orderService
    //     //   .editOrderLineItem(upd.value, upd.id)
    //     //   .subscribe(uRes => {
    //     //     console.log("###***--- LineItem: ", item, " updated => ", uRes);
    //     //   });
    //   });
    // });

    // let batch2Processed = batch2.map(id => `"${id}"`).join();
    // let query2 = `?where={"$and": [{"_id": {"$in": [${batch2Processed}]}}, {"c_invoiced": false}]}&limit=10000`;
    // // Load orders
    // this.formsService.get("orderLineItems", query2).subscribe(res => {
    //   console.log("***===Batch2 Orders to invoice loaded: ", res);
    //   res.data.forEach(item => {
    //     //console.log("### Item is: ", item);
    //     let upd = {
    //       id: item._id,
    //       value: {
    //         c_invoiced: true,
    //         c_invoice_number: "TOI02292020"
    //       }
    //     };

    //     //Make request
    //     this.orderService
    //       .editOrderLineItem(upd.value, upd.id)
    //       .subscribe(uRes => {
    //         console.log("###***--- LineItem: ", item, " updated => ", uRes);
    //       });
    //   });
    // });

    // let testProcessed = test.map(id => `"${id}"`).join()
    // let testQuery = `?where={"$and": [{"_id": {"$in": [${testProcessed}]}}, {"c_invoiced": false}]}&limit=10000&expand[]=c_medable_order`
    // this.formsService.get("orderLineItems", testQuery).subscribe( res => {
    //   console.log("***===TEST Orders to invoice loaded: ", res)
    //   console.log("******Updating lineItems...")
    //   // Update lineItems
    //   res.data.forEach(item => {
    //     console.log("### Item is: ", item)
    //     let upd = {
    //       id: item._id,
    //       value: {
    //         c_invoiced: true,
    //         c_invoice_number: "01312020"
    //       }
    //     }

    //     // Make request
    //     this.orderService.editOrderLineItem(upd.value, upd.id).subscribe( uRes => {
    //       console.log("###***--- LineItem: ", item, " updated => ", uRes)
    //     })
    //   })
    // })
  }

  /**
   * Function to manually mark specific orderItems as invoiced, and add an invoice number to them.
   */
  markItemsAsInvoiced = () => {
    // Set invoice number
    let invoiceNr = '';

    // Set ids of the lineItems that need to be marked as invoiced
    // Note: If the number of items is big it is recommended to split them into smallert batches.
    let batch = [];

    // console.log("##** -- Marking Items (Batch): ", batch);

    // Map itemIds to update promises.
    let promises = batch.map((s) =>
      this.orderService.editOrderLineItem(
        { c_invoiced: true, c_invoice_number: invoiceNr },
        s
      )
    );

    // Run all updates in parrallel & console log response
    forkJoin(promises).subscribe((res) => {
      console.log('##** -- Marking Items (Response): ', res);
    });
  };
}
