<!-- LocationGroups -->
<h2>Edit Location Group</h2>

<!-- Form for creating locationGroup -->
<form name="form" (ngSubmit)="onSubmit()" #f="ngForm" class="step" novalidate>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <label>LocationGroup Name*</label>
        <input
          [ngClass]="{ 'red-border': errorMessageContainer.exists }"
          name="groupName"
          [(ngModel)]="locationGroup.c_group_name"
          type="text"
          required
        />
      </div>
    </div>
    <div class="col-xs-12 text-center" *ngIf="errorMessageContainer.exists">
      <label class="error-message">{{ errorMessageContainer.message }}</label>
    </div>
    <div class="col-xs-12">
      <div class="row">
        <label>Associated Locations:</label>
        <div class="col-xs-12 ll-item-list-container">
          <div
            class="ll-list-item"
            *ngFor="let location of associatedLocations; let i = index"
          >
            <div class="ll-list-item-text">{{ location.c_company }}</div>
            <div class="ll-list-item-delete" (click)="removeLocation(i)">x</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Location Autocomplete Container -->
    <!-- <div class="row"> -->
    <div class="row">
      <div class="col-xs-12 col-md-6 hcpcs_autocomplete">
        <label>Add Location</label>
        <!-- Autocomplete Location input -->
        <input
          placeholder="Add Location"
          name="searchLocation"
          id="autocomplete_location"
          type="text"
          class="highlight"
          [ngbTypeahead]="searchLocation()"
          [resultTemplate]="autocompleteLocations"
          [inputFormatter]="locationsFormatter"
        />
        <!-- Template for suggested locations -->
        <ng-template #autocompleteLocations let-r="result" let-t="term">
          <li (click)="addLocation(r)">{{ r.c_company }}</li>
        </ng-template>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <div class="col-xs-12 flex-display">
    <button
      class="button cancel-button back-button"
      type="button"
      (click)="gotoViewLocationGroup(locationGroupId)"
    >
      CANCEL
    </button>
    <button class="button next-button">UPDATE</button>
  </div>
</form>
