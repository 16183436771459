<div class="reset-password">
  <div class="auth-form_container">
    <form (ngSubmit)="onSubmit()">
      <div class="alert alert-error" *ngIf="errorMessage">
        <p>{{ errorMessage }}</p>
      </div>
      <div class="form-content">
        <div *ngIf="!passwordResetted">
          <div class="auth-form_container-welcome_message-wrapper">
            <h3>{{ 'reset-password.form.title' | lang }}</h3>
          </div>
          <div class="auth-form_container-inputs-wrapper">
            <div class="form-row">
              <input
                class="rounded"
                name="password"
                placeholder="{{
                  'reset-password.form.placeholder.password' | lang
                }}"
                [(ngModel)]="password"
                type="password"
                required
              />
            </div>
            <div class="form-row">
              <input
                class="rounded"
                name="confirmPassword"
                placeholder="{{
                  'reset-password.form.placeholder.confirmPassword' | lang
                }}"
                [(ngModel)]="confirmPassword"
                type="password"
                required
              />
              <button class="submit-btn" type="submit">
                <img src="/assets/images/arrow-right.png" />
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="passwordResetted" class="confirmation-message">
          <div class="form-content">
            <div class="auth-form_container-welcome_message-wrapper">
              <h3>{{ 'reset-password.message.success' | lang }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="form-actions">
        <a [routerLink]="['/login']">
          <i class="arrow-left"></i>
          {{ 'auth.label.go_back_to_login' | lang }}
        </a>
      </div>
    </form>
  </div>
</div>
