<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchIcd10"
      class="search-input"
      placeholder="search by code or description"
      (keyup)="$event.keyCode == 13 && getIcd10s(searchIcd10)"
    />
    <button type="button" class="btn-link" (click)="getIcd10s(searchIcd10)">
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <div class="container-button">
    <a
      *ngIf="
        (hasRole.admin ||
          hasRole.executive ||
          hasRole.site_manager ||
          hasRole.fitter) &&
        !stateCreate
      "
      class="button create"
      (click)="goToCreateIcd10()"
    >
      {{ 'icd10s.icd10-form-new.title' | lang }}
    </a>
  </div>
  <ul>
    <li *ngFor="let icd10 of icd10s">
      <label (click)="goToViewIcd10(icd10._id)">
        {{ icd10.c_icd_10_code }} - {{ icd10.c_description }}
      </label>
    </li>
  </ul>
</div>
