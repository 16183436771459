import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class InventoryService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createInventory(inventory: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.inventories}`,
      inventory,
      this.httpOptions
    );
  }

  editInventory(inventoryId: string, inventory: any): Observable<any> {
    delete inventory.acl;
    return this.http.put<any>(
      `${this.API.inventories}/${inventoryId}`,
      inventory,
      this.httpOptions
    );
  }

  getInventory(inventoryId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.inventories}/${inventoryId}?expand[]=c_supplier_id`,
      this.httpOptions
    );
  }

  deleteInventory(inventoryId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.inventories}/${inventoryId}`,
      this.httpOptions
    );
  }

  getCin7Inventory(cin7Id: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.inventoriesCin7}?rows=1&where=id=${cin7Id}`,
      this.httpOptions
    );
  }

  searchInventories(input: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.inventories}?limit=100&where={"$or":[{"c_name":{"$regex":"/` +
        input +
        `/i"}}, {"c_product_options.c_code":{"$regex":"/` +
        input +
        `/i"}}]}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  getSynchronizationDate(): Observable<any> {
    return this.http.get<any>(
      `${this.API.synchronizationDate}?limit=1`,
      this.httpOptions
    );
  }

  getHcpcsCodes(): Observable<any> {
    const self = this;
    const promise = new Subject();
    let allInv = [];
    let page = 1;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventoriesCin7}?rows=50&page=` + page + `&order=id ASC`,
          self.httpOptions
        )
        .subscribe((response) => {
          // console.log("loadAll: getOutdatedInventoriesFromCin7: ", response);
          allInv = allInv.concat(response.data);

          if (response.data.length == 50) {
            console.log('has more');
            page++;
            loadAll();
          } else {
            promise.next(allInv);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  insertHcpcsCode(code): Observable<any> {
    return this.http.post<any>(
      `${this.API.hcpcs_codes}`,
      code,
      this.httpOptions
    );
  }

  getHcpcsCodesMedable(): Observable<any> {
    return this.http.get<any>(
      `${this.API.hcpcs_codes}?limit=1000`,
      this.httpOptions
    );
  }

  getInventories(page): Observable<any> {
    const self = this;
    let allInv = [];
    const promise = new Subject();
    console.log('Entered: getInventories!');
    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventories}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allInv = allInv.concat(response.data);
          if (response.hasMore) {
            // console.log("hasMore");
            page++;
            loadAll();
          } else {
            console.log('Finished: getInventories!', allInv);
            promise.next(allInv);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getOutdatedInventoriesFromCin7(date): Observable<any> {
    const self = this;
    const promise = new Subject();
    let allInv = [];
    let page = 1;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventoriesCin7}?rows=250&page=` +
            page +
            `&where=modifiedDate>'` +
            date +
            `'&order=modifiedDate,id ASC`,
          self.httpOptions
        )
        .subscribe((response) => {
          // console.log("loadAll: getOutdatedInventoriesFromCin7: ", response);
          allInv = allInv.concat(response.data);

          if (response.data.length == 250) {
            // console.log("outdated has more");
            page++;
            loadAll();
          } else {
            promise.next(allInv);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  testInvFromCin7(): Observable<any> {
    const page = 1;
    return this.http.get<any>(
      `${this.API.inventoriesCin7}?rows=10&page=` +
        page +
        `'&order=modifiedDate,id ASC`,
      this.httpOptions
    );
  }

  /**
   * Function to load inactive items from cin7.
   */
  cin7GetInactiveItems(): Observable<any> {
    return this.http.get<any>(
      `${this.API.inventoriesCin7}?where=(status='Inactive')&rows=250`,
      this.httpOptions
    );
  }

  /**
   * Function to load a page of products from cin7
   */
  getCin7ProdPage(page): Observable<any> {
    const rows = 250;
    return this.http.get<any>(
      `${this.API.inventoriesCin7}?fields=id,status&rows=${rows}&page=${page}`,
      this.httpOptions
    );
  }

  getInventoriesFromCin7(page): Observable<any> {
    const self = this;
    const promise = new Subject();
    let allInv = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventoriesCin7}?rows=25&page=` +
            page +
            '&order=modifiedDate,id ASC',
          self.httpOptions
        )
        .pipe(delay(300))
        .subscribe((response) => {
          console.log('hasMore...');
          allInv = allInv.concat(response.data);

          if (response.data.length == 25) {
            console.log("outdated has more");
            page++;
            loadAll();
          } else {
            console.log("All inventories from cin7 loaded...", allInv);
            promise.next(allInv);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  getExpectedInventoriesFromCin7(): Observable<any> {
    const promise = new Subject();
    const self = this;
    // console.log("Entered: getExpectedInventoriesFromCin7!");
    this.getSynchronizationDate().subscribe((date) => {
      // console.log("getExpectedInventoriesFromCin7->date: ", date);
      // if(date.data.length && date.data[0].c_inventory_synchronization_date) {
      //     self.getOutdatedInventoriesFromCin7(date.data[0].c_inventory_synchronization_date).subscribe( invs => {
      //         // console.log("Finished: getExpectedInventoriesFromCin7!", invs);
      //         promise.next({
      //             inventories: invs,
      //             dateId: date.data[0]._id
      //         });

      //         promise.complete();
      //     });
      // } else {
      //     self.getInventoriesFromCin7(1).subscribe(invs => {
      //         // console.log("Finished: getExpectedInventoriesFromCin7!", invs);
      //         promise.next({
      //             inventories: invs,
      //             dateId: (date.data.length) ? date.data._id : undefined
      //         });
      //     });
      // }
      self.getInventoriesFromCin7(1).subscribe((invs) => {
        // console.log("Finished: getExpectedInventoriesFromCin7!", invs);
        promise.next({
          inventories: invs,
          dateId: date.data.length ? date.data._id : undefined,
        });
        promise.complete();
      });
    });

    return promise;
  }

  findInventoryBySuppliers(suppliers: any[]) {
    const query = `where={"c_supplier_id": {"$in": [${suppliers}]}}`;
    return this.http.get<any>(
      `${this.API.inventories}?${query}`,
      this.httpOptions
    );
  }

  updateSynchronizationDate(dateId, syncDate, syncId): Observable<any> {
    const data = {
      c_inventory_synchronization_date: syncDate,
      c_inventory_synchronization_id: syncId,
    };

    return this.http.put<any>(
      `${this.API.synchronizationDate}/${dateId}`,
      data,
      this.httpOptions
    );
  }

  createSynchronizationDate(syncDate, syncId): Observable<any> {
    const data = {
      c_inventory_synchronization_date: syncDate,
      c_inventory_synchronization_id: syncId,
    };

    return this.http.post<any>(
      `${this.API.synchronizationDate}`,
      data,
      this.httpOptions
    );
  }

  /**
   * Function to get the location of the product
   */
  getProductLocation(prodId: string): Observable<any> {
    const filter = `{"c_product" : "${prodId}"}`,
      expand = 'expand[]=c_location',
      limit = 'limit=10000',
      q = `where=${filter}&${expand}&${limit}`;

    return this.http.get<any>(`${this.API.stock}?${q}`, this.httpOptions);
  }

  /**
   * Function to load all products from medable
   */
  getAllMedableProducts(): Observable<any> {
    console.log('Loading all medable products');
    const localSubject = new Subject();
    const chunck = 1000;
    let allProducts = [];
    const self = this;
    let page = 0;
    const fields =
      'paths[]=_id&paths[]=c_cin7_id&paths[]=c_status&paths[]=c_deleted&paths[]=c_hcpcs_code';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventories}?skip=${
            chunck * page
          }&limit=${chunck}&${fields}`,
          self.httpOptions
        )
        .subscribe((products) => {
          allProducts = allProducts.concat(products.data);
          if (products.hasMore) {
            console.log('Prod Has More...');
            page++;

            loadAll();
          } else {
            // console.log("All Orders Loaded");
            localSubject.next(allProducts);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }
}
