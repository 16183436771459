import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationGroupsService } from '../../../services/location-groups/location-groups.service';
import { SharedService } from '../../../services/shared/shared.service';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'app-location-groups-view',
  templateUrl: './location-groups-view.component.html',
  styleUrls: ['./location-groups-view.component.css'],
})
export class LocationGroupsViewComponent implements OnInit {
  locationGroupId: string;
  locationGroup: any = {};
  locationsInGroup: any[] = [];

  private sub;

  constructor(
    private route: ActivatedRoute,
    private locationGroupsService: LocationGroupsService,
    private modalService: NgbModal,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // this.openModal();
    this.sub = this.route.params.subscribe((params) => {
      this.locationGroupId = params.id ? params.id : '0';
      this.loadLocationGroup();
      console.log('GroupId: ', this.locationGroupId);
      // console.log("StateName: ", this.stateName);
      // this.actionBasedOnState();
    });
  }

  loadLocationGroup() {
    this.locationGroupsService
      .getLocationGroup(this.locationGroupId)
      .subscribe((locationGroup) => {
        console.log('LocationGroup: ', locationGroup);
        this.locationGroup = locationGroup;

        this.locationGroupsService
          .getLocationsByGroupId(this.locationGroupId)
          .subscribe((locations) => {
            // console.log("Locations in this group: ", locations);
            this.locationsInGroup = locations.data;
          });
      });
  }

  goToEditLocationGroup(locationGroupId) {
    this.router.navigate([
      `forms/locationGroups/:id/edit`,
      { id: locationGroupId },
    ]);
  }

  deleteLocationGroup() {
    // console.log("Draft? ", draft);
    const title = 'orders.popup.cancel.draft.title';
    const text = 'locationGroups.popup.delete.text';

    this.showPopupWindow(title, text).then((response) => {
      if (response.selected === 'LEFT') {
        this.locationGroupsService
          .deleteLocationGroup(this.locationGroupId)
          .subscribe((deleted) => {
            // this.goto
            console.log('Go to index...');
            this.gotoLocationGroupsIndex();
          });
      }
    });
  }

  showPopupWindow(popupTitle, popupText) {
    const info = {
      title: popupTitle,
      subtitle: popupText,
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };

    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  gotoLocationGroupsIndex() {
    this.router.navigate([`forms/locationGroups/index`]);
  }
}
