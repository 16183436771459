import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { ModifierService } from '../../services/modifier/modifier.service';

@Component({
  selector: 'app-modifier-list',
  templateUrl: './modifier-list.component.html',
  styleUrls: ['./modifier-list.component.css'],
})
export class ModifierListComponent implements OnInit {
  hasRole: any;
  modifier: any;
  modifiers: any[];

  createOrEdit = false;

  searchModifier: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modifierService: ModifierService,
    private state: ActiveStateService
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getModifiers('');

    if (this.state.is('forms.modifiersCreate')) {
      this.createOrEdit = true;
    }
  }

  getModifiers(input: string) {
    // console.log('searchModifier: ', this.searchModifier);
    this.modifierService.getModifiers(input).subscribe((modifiers) => {
      this.modifiers = this.sortModifiers(modifiers.data);
      // console.log('returned modifiers: ', modifiers);
    });
  }

  gotoViewModifier(modifierId) {
    this.router.navigate(['forms/modifiers/:id/view', { id: modifierId }]);
  }

  goToCreateModifier() {
    this.router.navigate(['forms/modifiers/new']);
  }

  sortModifiers(unsorted) {
    return unsorted.sort((a, b) => {
      const aName = a.c_name.trim().toLowerCase();
      const bName = b.c_name.trim().toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }

      return 0;
    });
  }
}
