<div class="popup-content">
  <div class="modal-header">
    <h3 *ngIf="info.title">{{ info.title | lang }}</h3>
    <h4 *ngIf="info.subtitle" class="modal-title">
      {{ info.subtitle | lang }}
    </h4>
  </div>
  <div class="modal-body" *ngIf="info.question !== undefined">
    <span>{{ info.question }}</span>
  </div>
  <div class="modal-body" *ngIf="info.filename !== undefined">
    <span>
      Are you sure the file
      <strong class="strong-blue">{{ info.filename }}</strong>
      have the information for
      <strong class="strong-blue">{{ info.objectToParse }}</strong>
      ?
    </span>
  </div>
  <div class="modal-body" *ngIf="info.inputConfirm">
    <p *ngIf="errorDelete" style="color: red">
      {{ 'forms.confirm_delete_text.error' | lang }}
    </p>
    <input
      type="text"
      name="delete"
      [(ngModel)]="confirmText"
      placeholder="{{ 'forms.confirm_delete_text' | lang }}"
    />
  </div>
  <div class="modal-footer" *ngIf="!info.btnCenter">
    <button
      type="button"
      class="button {{ info.btnThirdClass }}"
      style="width: 20%; display: inline-block; margin-left: calc(20% - 6px)"
      (click)="center()"
      *ngIf="info.btnThird"
    >
      {{ info.btnThird }}
    </button>
    <button
      type="button"
      class="button {{ info.btnLeftClass }}"
      style="width: 20%; display: inline-block"
      (click)="left()"
    >
      {{ info.btnLeft }}
    </button>
    <button
      type="button"
      class="button {{ info.btnRightClass }}"
      style="width: 20%; display: inline-block"
      (click)="right()"
    >
      {{ info.btnRight }}
    </button>
  </div>
  <div class="modal-footer" *ngIf="info.btnCenter">
    <button
      type="button"
      class="button button-center {{ info.btnCenterClass }}"
      (click)="left()"
    >
      {{ info.btnCenter }}
    </button>
  </div>
</div>
<!-- /.modal-content -->
