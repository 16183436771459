import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { ProviderService } from '../../services/provider/provider.service';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css'],
})
export class ProviderListComponent implements OnInit {
  providers: any = [];
  stateCreate = false;
  hasRole: any;
  accountId: string;
  fitterLocations: any[];
  admin = false;
  searchProvider: any;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private providerService: ProviderService,
    private router: Router,
    private formsService: FormsService
  ) {}

  ngOnInit() {
    if (this.state.is('forms.providersCreate')) {
      this.stateCreate = true;
    }

    this.hasRole = this.authService.getAuthorizedUserRole();
    if (this.hasRole.admin || this.hasRole.executive) {
      this.admin = true;
    }
    this.getProviders('');
  }

  getProviders(input) {
    if (this.admin) {
      this.getProvidersAdmin(input);
    } else {
      this.getProvidersBasic(input);
    }
  }

  getProvidersAdmin(input) {
    const self = this;
    console.log('Get Providers for admin');
    if (!input || input === '') {
      this.providerService.getProviders().subscribe(
        (providers) => {
          this.providers = this.sortProviders(providers.data);
        },
        (error) => {
          console.error(error.reason);
        }
      );
    } else {
      this.providerService.searchProviders(input).subscribe((providers) => {
        const search = input.split(' ');
        // filter to only get the ones that completely match the input
        this.providers = providers.data.filter((provider) => {
          const names =
            provider.c_name.c_first_name + ' ' + provider.c_name.c_last_name;
          const matches = search.filter((text) => names.indexOf(text) !== -1);
          return matches.length === search.length;
        });
        // keep the first 20 to list
        this.providers.slice(0, 20);
      });
    }
  }

  getProvidersBasic(input) {
    // First get the current accountId
    this.formsService.get('accounts').subscribe((account) => {
      this.accountId = account.data[0]._id;
      const expapnds = '&expand[]=c_location';
      const query = '?where={"c_fitter": "' + this.accountId + '"}' + expapnds;
      // Get locations linked to current account
      this.formsService
        .get('account_locations', query)
        .subscribe((account_locations) => {
          this.fitterLocations = account_locations.data;
          this.getFilteredProviders(input);
        });
    });
  }

  getFilteredProviders(input: string) {
    const expands = ['expand[]=c_physician.c_name', 'expand[]=c_location'];
    const locationIds = this.fitterLocations.map(
      (fl) => `"${fl.c_location._id}"`
    );
    const locationFilter = `{"c_location": {"$in": [${locationIds.join(
      ','
    )}]}}`;

    if (input === '') {
      this.getProvidersByLocation(locationFilter, expands);
    } else {
      this.getProvidersByLocationAndName(input, locationFilter, expands);
    }
  }

  getProvidersByLocation(locationFilter: string, expands: string[]) {
    const query = `?where=${locationFilter}&${expands.join('&')}`;

    this.formsService
      .get('providerLocations', query)
      .subscribe((filteredProviders) => {
        this.distinctProviders(filteredProviders.data);
      });
  }

  getProvidersByLocationAndName(
    input: string,
    locationFilter: string,
    expands: string[]
  ) {
    const inputFilter = this.generateInputFilter(input);
    const providersQuery = `?where=${inputFilter}`;
    // Get providers for given input
    this.formsService
      .get('providers', providersQuery)
      .subscribe((providers) => {
        const providerIds = providers.data.map((p) => `"${p._id}"`);
        const providersFilter = `{"c_physician": {"$in": [${providerIds.join(
          ','
        )}]}}`;
        const pLocationFilter = `where={"$and": [${locationFilter},${providersFilter}]}`;
        const pLocationQuery = `?${pLocationFilter}&${expands.join('&')}`;
        // Get the filtered Locations
        this.formsService
          .get('providerLocations', pLocationQuery)
          .subscribe((plf) => {
            this.distinctProviders(plf.data);
          });
      });
  }

  sortProviders(providers) {
    console.log('Providers to sort: ', providers);
    const sorted = providers.sort(function (a, b) {
      const aPrefix = a.c_preffix ? a.c_preffix.trim() : '';
      const aSuffix = a.c_suffix ? a.c_suffix.trim() : '';
      const bPrefix = b.c_preffix ? b.c_preffix.trim() : '';
      const bSuffix = b.c_suffix ? b.c_suffix.trim() : '';

      const aStrRaw =
        aPrefix +
        a.c_name.c_first_name.trim() +
        a.c_name.c_last_name.trim() +
        aSuffix;
      const aStr = aStrRaw.replace(/\s+/gim, '').toLowerCase().trim();
      const bStrRaw =
        bPrefix +
        b.c_name.c_first_name.trim() +
        b.c_name.c_last_name.trim() +
        bSuffix;
      const bStr = bStrRaw.replace(/\s+/gim, '').toLowerCase().trim();

      if (aStr < bStr) {
        return -1;
      }
      if (aStr > bStr) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }

  goToViewProvider(providerId) {
    this.router.navigate(['forms/providers/:id/view', { id: providerId }]);
  }

  goToCreateProvider() {
    this.router.navigate(['forms/providers/new']);
  }

  generateInputFilter(input) {
    const inputs = input
      .split(' ')
      .filter((i) => i != '')
      .splice(0, 2);
    console.log('Inputs: ', inputs);
    let fc = [];
    let sc = [];
    if (inputs.length == 2) {
      fc = [
        `{"c_name.c_first_name":{"$regex":"/${inputs[0]}/i"}}`,
        `{"c_name.c_last_name":{"$regex":"/${inputs[1]}/i"}}`,
      ];
      sc = [
        `{"c_name.c_first_name":{"$regex":"/${inputs[1]}/i"}}`,
        `{"c_name.c_last_name":{"$regex":"/${inputs[0]}/i"}}`,
      ];
    } else {
      fc = [`{"c_name.c_first_name":{"$regex":"/${inputs[0]}/i"}}`];
      sc = [`{"c_name.c_last_name":{"$regex":"/${inputs[0]}/i"}}`];
    }

    const inputFilter = `{"$or": [{"$and": [${fc.join(
      ','
    )}]},{"$and":[${sc.join(',')}]}]}`;

    return inputFilter;
  }

  distinctProviders(providersLocations) {
    const distinctProviders = [];
    providersLocations.map((pl) => {
      const added = distinctProviders.filter(
        (dp) => dp._id === pl.c_physician._id
      ).length;
      if (added == 0) {
        distinctProviders.push(pl.c_physician);
      }
    });

    this.providers = this.sortProviders(distinctProviders);
  }
}
