import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupComponent } from '../popup/popup.component';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { Icd10Service } from '../../services/icd10/icd10.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-icd10',
  templateUrl: './icd10.component.html',
  styleUrls: ['./icd10.component.css'],
  inputs: ['IsCreateEnabled', 'IsEditEnabled'],
})
export class Icd10Component implements OnInit {
  icd10: any = {};
  icd10Id: string;

  hasRole: any;

  showCreateEditForm = false;
  isEditMode = false;
  notEditable = false;
  somethingToShow = false;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private icd10Service: Icd10Service,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService
  ) {
    this.deleteIcd10 = this.deleteIcd10.bind(this);
    this.goToViewIcd10 = this.goToViewIcd10.bind(this);
    this.goToBack = this.goToBack.bind(this);
  }

  ngOnInit() {
    // let id = this.route.snapshot.paramMap.get('id');
    this.sub = this.route.params.subscribe((params) => {
      this.icd10Id = params.id ? params.id : '0';

      this.hasRole = this.authService.getAuthorizedUserRole();

      // If index, we show the create button.
      this.somethingToShow = this.state.is('forms.icd10sIndex');

      if (this.somethingToShow) {
        return;
      }

      //  if edit or view, load the icd10 data
      if (this.state.is('forms.icd10sCreate')) {
        this.showCreateEditForm = true;
      } else {
        this.getIcd10();
      }

      // in case of view of the icd10, disable editing
      if (this.state.is('forms.icd10sView')) {
        this.notEditable = true;
      }

      if (this.state.is('forms.icd10sEdit')) {
        this.showCreateEditForm = true;
        this.isEditMode = true;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSubmit() {
    if (this.state.is('forms.icd10sCreate')) {
      this.createIcd10();
    } else if (this.state.is('forms.icd10sEdit')) {
      this.editIcd10();
    }
  }

  createIcd10() {
    this.icd10Service.createIcd10(this.icd10).subscribe((data) => {
      this.icd10Id = data._id;
      setTimeout(this.goToViewIcd10, 1000);
    });
  }

  editIcd10() {
    this.icd10Service.editIcd10(this.icd10Id, this.icd10).subscribe(() => {
      setTimeout(this.goToViewIcd10, 1000);
    });
  }

  getIcd10() {
    this.icd10Service.getIcd10(this.icd10Id).subscribe((icd10) => {
      this.icd10 = {
        c_description: icd10.c_description ? icd10.c_description : '',
        c_icd_10_code: icd10.c_icd_10_code,
      };
    });
  }

  deleteIcd10() {
    this.icd10Service.deleteIcd10(this.icd10Id).subscribe(() => {
      this.router.navigate(['forms/icd10s/index']);
    });
  }

  showPopupDelete() {
    const info = {
      subtitle: 'forms.confirm.delete',
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };
    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    modalInstance.result.then(this.deleteIcd10);
  }

  goToCreateIcd10() {
    this.router.navigate(['forms/icd10s/new']);
  }

  goToEditIcd10() {
    this.router.navigate(['forms/icd10s/:id/edit', { id: this.icd10Id }]);
    // this.router.navigateByUrl(`forms/icd10s/${this.icd10Id}/edit`);
  }

  goToViewIcd10(icd10Id = this.icd10Id) {
    this.router.navigate(['forms/icd10s/:id/view', { id: icd10Id }]);
  }

  cancel() {
    if (this.icd10.c_description || this.icd10.c_icd_10_code) {
      const info = {
        title: 'forms.confirm.title',
        subtitle: 'forms.confirm.subtitle',
        btnLeft: 'NO',
        btnLeftClass: 'no',
        btnRight: 'YES',
        btnRightClass: 'yes',
      };
      const modalInstance = this.modalService.open(PopupComponent);
      this.sharedService.setPopupInfo(info);
      modalInstance.result.then(this.goToBack);
    } else {
      this.goToBack();
    }
  }

  goToBack() {
    if (this.icd10Id === '0') {
      this.router.navigate(['forms/icd10s/index']);
    } else {
      this.router.navigate(['forms/icd10s/:id/view', { id: this.icd10Id }]);
    }
  }
}
