import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { InsuranceService } from '../../services/insurance/insurance.service';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.css'],
})
export class InsuranceListComponent implements OnInit {
  insurances: any = [];
  stateCreate = false;
  hasRole: any;
  searchInsurance: any;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private insuranceService: InsuranceService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.state.is('forms.insurancesCreate')) {
      this.stateCreate = true;
    }

    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getInsurances('');
  }

  getInsurances(input) {
    this.insuranceService.searchInsurances(input).subscribe(
      (insurances) => {
        this.insurances = this.sortInsurances(insurances.data);
      },
      (error) => {
        console.error(error.reason);
      }
    );
  }

  sortInsurances(insurances) {
    const sorted = insurances.sort((a, b) => {
      const aName = a.c_insurance_name.trim().toLowerCase();
      const bName = b.c_insurance_name.trim().toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }

  goToViewInsurance(insuranceId) {
    this.router.navigate(['forms/insurances/:id/view', { id: insuranceId }]);
  }

  goToCreateInsurance() {
    this.router.navigate(['forms/insurances/new']);
  }
}
