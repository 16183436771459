import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  user_name: any = [];
  showLogout = false;
  isSidebarOpen$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private sidebarService: SidebarService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getUser();
    this.loadScript();
    this.isSidebarOpen$ = this.sidebarService.isOpen$;
  }

  loadScript() {
    console.log('preparing to load...');
    const node = document.createElement('script');
    node.id = 'ze-snippet';
    node.src =
      'https://static.zdassets.com/ekr/snippet.js?key=5ea9741a-8969-4d67-afd1-fb47fea5e6be'; // insert url in between quotes
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    // const authContainer = document.getElementsByClassName("auth-container")[0];
    // const leftOffset = (window.innerWidth/2) - 80;
    // const topOffset = (authContainer.getBoundingClientRect().top * 2) + authContainer.clientHeight
    const node2 = document.createElement('script');
    node2.id = 'ze-snippet2';
    // insert url in between quotes
    node2.type = 'text/javascript';
    node2.async = true;
    node2.charset = 'utf-8';
    node2.text =
      "window.zESettings = {webWidget: {launcher: {label: {'*': 'Help',}},position: { horizontal: 'right', vertical: 'top' },offset: { horizontal: '200px', vertical: '5px' },color: { theme: '#2277ff', launcherText: '#fff', }}};";
    document.getElementsByTagName('head')[0].appendChild(node2);

    // console.log("window width: ", window.innerWidth)

    // console.log("AuthContainer top: ", authContainer.getBoundingClientRect().top);
    // console.log("AuthContainer Height: ", authContainer.scrollHeight)
  }

  getUser() {
    this.authService.get('accounts').subscribe(
      (account) => {
        if (account.data[0] !== undefined) {
          this.user_name = account.data[0].name;
        } else {
          alert('Your session has expired. Please login again');
        }
      },
      (error) => {
        console.log(error.data);
      }
    );
  }

  logout() {
    this.authService.logout().subscribe((data) => {
      console.log(data);
    });
    this.router.navigate(['/login']);
  }

  mouseLeave() {
    this.showLogout = false;
  }

  onShowLogout() {
    this.showLogout = !this.showLogout;
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }
}
