import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.css'],
})
export class CustomPopupComponent implements OnInit {
  info: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private shared: SharedService
  ) {}

  ngOnInit() {
    this.shared.currentPopupInfo.subscribe((info) => (this.info = info));
    // console.log("Popup Info: ", this.info);
  }

  selectedOption(option) {
    console.log('--Option Selected!', option);
    this.activeModal.close({ selected: option });
  }
}
