import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, map } from 'rxjs/operators';

import { PopupComponent } from '../popup/popup.component';

import { forkJoin, Observable } from 'rxjs';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { ProviderService } from '../../services/provider/provider.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css'],
})
export class ProviderComponent implements OnInit {
  provider: any = {
    c_name: {
      c_first_name: '',
      c_last_name: '',
    },
    c_npi: '',
    c_suffix: '',
    c_preffix: '',
  };

  billingProviders: any[] = [];
  providersForSearch: any;

  providerId: string;

  image: File = undefined;
  imageUrl: string = '';
  hasRole: any;

  showCreateEditForm: boolean = false;
  isEditMode: boolean = false;
  notEditable: boolean = false;
  somethingToShow: boolean = false;
  duplicatedError: boolean = false;
  npiValidateError: boolean = false;
  providerSubmited: boolean = false;
  billingProviderCanBeAdded: boolean = false;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private providerService: ProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private formsService: FormsService
  ) {
    this.deleteProvider = this.deleteProvider.bind(this);
    this.goToViewProvider = this.goToViewProvider.bind(this);
    this.goToBack = this.goToBack.bind(this);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.providerId = params['id'] ? params['id'] : '0';

      this.hasRole = this.authService.getAuthorizedUserRole();

      // If index, we show the create button.
      this.somethingToShow = this.state.is('forms.providersIndex');
      // this.processMedableProviders();
      if (this.somethingToShow) {
        return;
      }

      //  if edit or view, load the providers data
      if (this.state.is('forms.providersCreate')) {
        this.getProvidersForSearch();
        this.showCreateEditForm = true;
      } else {
        this.getProvider();
      }

      // in case of view of the providers, disable editing
      if (this.state.is('forms.providersView')) {
        this.notEditable = true;
      }

      if (this.state.is('forms.providersEdit')) {
        this.showCreateEditForm = true;
        this.isEditMode = true;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getProvidersForSearch() {
    this.providerService.getAllProvidersMedable().subscribe((allProviders) => {
      console.log('All providers: ', allProviders);
      this.providersForSearch = allProviders;

      this.filterSearchProviders(this.provider.c_suffix);
    });
  }

  processMedableProviders() {
    this.providerService
      .getAllProvidersMedableArray()
      .subscribe((allProviders) => {
        console.log('All Medable Providers: ', allProviders);
        const mdProviders = allProviders['array'].filter((p) => {
          const isMd = p.c_suffix.toLowerCase().trim() == 'dpm';

          if (
            isMd &&
            (!p.c_billing_providers || !p.c_billing_providers.length)
          ) {
            return true;
          }
        });
        console.log('dpmProviders: ', mdProviders);
        const objToUpdate = mdProviders.map((p) => {
          return {
            id: p._id,
            c_billing_providers: [{ c_provider: p._id }],
          };
        });

        console.log('To Update: ', objToUpdate);
        const updateSet = [...objToUpdate].splice(0, 40);
        console.log('UpdateSet: ', updateSet);

        // updateSet.map( up => {
        //   this.providerService.editProvider(up.id, {c_billing_providers: up.c_billing_providers}).subscribe( upd => {
        //     console.log("Provider Updated: ", upd);
        //   })
        // })
      });
  }

  updateProviders() {
    const updateRequests = [];
    this.providerService
      .getAllProvidersMedableArray()
      .subscribe((allProviders) => {
        console.log('All medable providers loaded...', allProviders);
        const needsToUpdate = allProviders['array']
          .filter((providerObj) => {
            const suffix = (providerObj.c_suffix
              ? providerObj.c_suffix
              : ''
            ).toLowerCase();
            const needsCheck =
              suffix == 'md' || suffix == 'dpm' || suffix == 'do';
            if (
              (!providerObj.c_billing_providers ||
                !providerObj.c_billing_providers.length) &&
              needsCheck
            ) {
              return true;
            }
          })
          .map((p) => {
            return {
              id: p._id,
              c_billing_providers: [{ c_provider: p._id }],
            };
          });
        console.log('needs to update: ', needsToUpdate);

        needsToUpdate.map((up) => {
          const updateReq = this.providerService.editProvider(up.id, {
            c_billing_providers: up.c_billing_providers,
          });
          updateRequests.push(updateReq);
        });

        if (!updateRequests.length) {
          return;
        }

        forkJoin(updateRequests).subscribe((responses) => {
          console.log('All providers update: ', responses);
        });
      });
  }

  searchProviderWrapper() {
    let searchProvider = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            console.log('Search Term: ', term);
            const searchTerms = term
                .split(' ')
                .map((trm) => trm.toLowerCase().trim()),
              matchedProviders = [];

            this.providersForSearch.map((provider) => {
              const nameObj = provider.c_name,
                searchString = ` ${nameObj.c_first_name} ${nameObj.c_last_name} `,
                foundTerms = searchTerms.filter(
                  (st) => searchString.toLowerCase().indexOf(`${st}`) > -1
                ).length,
                allMatch = foundTerms == searchTerms.length;

              if (allMatch) {
                provider.displayName = this.buildProviderDisplayName(provider);
                const alreadyAdded = this.billingProviders.filter(
                  (bp) => bp._id == provider._id
                ).length;
                if (!alreadyAdded) {
                  matchedProviders.push(provider);
                }
              }
            });

            return matchedProviders.length ? matchedProviders.slice(0, 10) : [];
          }
        })
      );
    };

    return searchProvider;
  }

  addProvider(provider) {
    console.log('Provider: ', provider);
    this.billingProviders.push(provider);
    console.log('BillingProviders List: ', this.billingProviders);
  }

  removeProvider(position) {
    if (this.billingProviderCanBeAdded) {
      this.billingProviders.splice(position, 1);
    }
  }

  buildProviderDisplayName(provider) {
    const nameObj = provider.c_name,
      prefix = provider.c_preffix ? `${provider.c_preffix} ` : '',
      firstName = nameObj.c_first_name,
      lastName = nameObj.c_last_name,
      c_suffix = provider.c_suffix ? ` ${provider.c_suffix}` : '',
      displayName = `${prefix}${firstName} ${lastName}${c_suffix}`;

    return displayName;
  }

  handleFileInput(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0];
      console.log('Signature uploaded: ', this.image);
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
        console.log('Image Url: ', this.imageUrl);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    if (!this.provider.c_npi) {
      this.confirmSubmit();
      return;
    }

    if (!isNaN(this.provider.c_npi) && this.provider.c_npi.length === 10) {
      // check if the provider is a duplicate
      if (this.state.is('forms.providersCreate')) {
        this.providerService
          .searchProviderNPI(this.provider.c_npi)
          .subscribe((data) => {
            if (data.data.length) {
              this.duplicatedError = true;
            } else {
              this.confirmSubmit();
            }
          });
      } else {
        this.confirmSubmit();
      }
    } else {
      this.npiValidateError = true;
    }
  }

  confirmSubmit() {
    this.duplicatedError = false;
    if (this.state.is('forms.providersCreate')) {
      this.createProvider();
    } else if (this.state.is('forms.providersEdit')) {
      this.editProvider();
    }
  }

  createProvider() {
    this.provider.c_billing_providers = this.billingProviders.map((bp) => {
      return { c_provider: bp._id };
    });
    this.providerService.createProvider(this.provider).subscribe((data) => {
      console.log('This Provider: ', this.provider);
      if (this.image !== undefined) {
        this.providerService.sendImageFile(this.image, data._id).subscribe(
          (file) => {
            this.formsService.sendFileAWS(this.image, file.data).subscribe(
              (res) => {
                console.log('success ' + res.data);
              },
              (error) => {
                console.log('failed ', error);
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
      }
      this.providerSubmited = true;
      setTimeout(() => this.goToViewProvider(data._id), 1000);
    });
  }

  editProvider() {
    delete this.provider.displayName;
    this.provider.c_billing_providers = this.billingProviders.map((bp) => {
      return { c_provider: bp._id };
    });
    console.log('Billing Providers Sent: ', this.provider.c_billing_providers);
    this.providerService
      .editProvider(this.providerId, this.provider)
      .subscribe((data) => {
        console.log('Provider updated: ', data);
        if (this.image !== undefined) {
          this.providerService
            .sendImageFile(this.image, data._id)
            .subscribe((file) => {
              this.formsService
                .sendFileAWS(this.image, file.data)
                .subscribe((res) => {
                  console.log('Res: ', res);
                });
            });
        }
        this.providerSubmited = true;
        setTimeout(() => this.goToViewProvider(data._id), 1000);
      });
  }

  getProvider() {
    this.providerService.getProvider(this.providerId).subscribe((provider) => {
      this.billingProviders = provider.c_billing_providers.map((bp) => {
        bp.c_provider.displayName = this.buildProviderDisplayName(
          bp.c_provider
        );
        return bp.c_provider;
      });
      console.log('Raw Provider: ', provider);

      this.getProvidersForSearch();

      this.provider = {
        c_name: {
          c_first_name: provider.c_name.c_first_name,
          c_last_name: provider.c_name.c_last_name,
        },
        c_npi: provider.c_npi ? provider.c_npi : '',
        c_suffix: provider.c_suffix ? provider.c_suffix : '',
        c_preffix: provider.c_preffix ? provider.c_preffix : '',
      };

      if (
        !(
          this.provider.c_suffix.toLowerCase().trim() == 'md' ||
          this.provider.c_suffix.toLowerCase().trim() == 'do' ||
          this.provider.c_suffix.toLowerCase().trim() == 'dpm'
        )
      ) {
        this.billingProviderCanBeAdded = true;
      }
      console.log('Provider: ', this.provider);

      if (provider.c_signature) {
        this.providerService
          .getImageContent(this.providerId)
          .subscribe((image) => {
            this.imageUrl = image.url;
          });
      }
    });
  }

  filterSearchProviders(suffix) {
    if (
      suffix.toLowerCase().trim() == 'md' &&
      this.state.is('forms.providersEdit')
    ) {
      this.providersForSearch = [];
    } else {
      this.providersForSearch = this.providersForSearch.filter(
        (p) =>
          p.c_suffix.toLowerCase().trim() == 'md' ||
          p.c_suffix.toLowerCase().trim() == 'do' ||
          p.c_suffix.toLowerCase().trim() == 'dpm'
      );
    }
  }

  deleteProvider() {
    this.providerService.deleteProvider(this.providerId).subscribe(() => {
      this.router.navigate(['forms/providers/index']);
    });
  }

  showPopupDelete() {
    const info = {
      subtitle: 'forms.confirm.delete',
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };
    let modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    modalInstance.result.then(this.deleteProvider);
  }

  goToCreateProvider() {
    this.router.navigate(['forms/providers/new']);
  }

  goToEditProvider(providerId) {
    this.router.navigate(['forms/providers/:id/edit', { id: providerId }]);
    // this.router.navigateByUrl(`forms/providers/${this.providerId}/edit`);
  }

  goToViewProvider(providerId) {
    this.router.navigate(['forms/providers/:id/view', { id: providerId }]);
  }

  cancel() {
    if (this.provider.c_name) {
      const { c_first_name, c_last_name } = this.provider.c_name;

      if (c_first_name !== undefined || c_last_name !== undefined) {
        this.openDialog();
      }
    } else if (
      this.provider.c_npi !== undefined ||
      this.provider.c_suffix !== undefined ||
      this.provider.c_preffix !== undefined
    ) {
      this.openDialog();
    } else {
      this.goToBack();
    }
  }

  openDialog() {
    const info = {
      title: 'forms.confirm.title',
      subtitle: 'forms.confirm.subtitle',
      btnLeft: 'NO',
      btnLeftClass: 'no',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };
    let modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);
    modalInstance.result.then(this.goToBack);
  }

  goToBack() {
    if (this.providerId === '0') {
      this.router.navigate(['forms/providers/index']);
    } else {
      this.router.navigate([
        'forms/providers/:id/view',
        { id: this.providerId },
      ]);
    }
  }

  deleteImage() {
    this.image = undefined;
    this.imageUrl = '';
  }

  providerFormatter = () => '';
}
