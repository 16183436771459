import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';
import { FormsService } from '../../services/forms/forms.service';
import { Icd10Service } from '../../services/icd10/icd10.service';
import { LocationService } from '../../services/location/location.service';
import { ProviderService } from '../../services/provider/provider.service';
@Injectable()
export class OrderService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;
  orderLineItems: any[];
  hasSignature: boolean = false;
  hasLogo: boolean = false;

  externalAccessEndpoint: string = 'https://orders.dmeposrocket.com';

  globalDoc = {};
  modifierFormList: any[];
  order: any;
  warrantyForm: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  noCredentialOptions = {
    headers: new HttpHeaders({
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: false,
  };

  httpAux = {
    headers: new HttpHeaders({
      // 'Content-Type':  'image/png',
    }),
    withCredentials: false,
    responseType: 'blob' as 'json',
  };

  constructor(
    private http: HttpClient,
    private locationService: LocationService,
    private providerService: ProviderService,
    private icd10Service: Icd10Service,
    private formsService: FormsService
  ) {}

  searchOrders(query: string, input: string): Observable<any> {
    const inputSplit = input.split(' ');
    const search = [];

    inputSplit.map((text) => {
      search.push('{"c_number_id": ' + text + '}');
    });

    return this.http.get<any>(
      `${this.API.orders}?limit=1000&where={"$or":[` +
        search.join(',') +
        `]}&` +
        query,
      this.httpOptions
    );
  }

  createOrder(order: any): Observable<any> {
    if (!order.c_draft) {
      order['c_created_timestamp'] = new Date().getTime();
    }
    // order['c_last_modified'] = new Date(Date.now()).toISOString();
    return this.http.post<any>(`${this.API.orders}`, order, this.httpOptions);
  }

  createOrderLineItem(orderLineItem: any): Observable<any> {
    console.log('Creating orderlineitem: ', orderLineItem);
    orderLineItem.c_modifier.map((mod) => {
      delete mod.c_warning;
    });
    return this.http.post<any>(
      `${this.API.orderLineItems}`,
      orderLineItem,
      this.httpOptions
    );
  }

  editOrder(order: any, orderId: string): Observable<any> {
    delete order.acl;
    return this.http.put<any>(
      `${this.API.orders}/${orderId}`,
      order,
      this.httpOptions
    );
  }

  addFlagToOrder(order: any, orderId: string): Observable<any> {
    delete order.acl;
    return this.http.put<any>(
      `${this.API.orders}/${orderId}`,
      order,
      this.httpOptions
    );
  }

  editOrderLineItem(
    orderLineItem: any,
    orderLineItemId: string
  ): Observable<any> {
    delete orderLineItem.acl;
    delete orderLineItem.c_last_modified;

    return this.http.put<any>(
      `${this.API.orderLineItems}/${orderLineItemId}`,
      orderLineItem,
      this.httpOptions
    );
  }

  editModifierOrderLineItem(
    modifier: any,
    orderLineItemId: string,
    modifierId: string
  ) {
    delete modifier.acl;
    return this.http.put<any>(
      `${this.API.orderLineItems}/${orderLineItemId}/c_modifier/${modifierId}`,
      modifier,
      this.httpOptions
    );
  }

  getPatientsOrders(patientId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orders}?where={"c_patient": "${patientId}"}&expand[]=creator&expand[]=c_patient&expand[]=c_insurance&expand[]=c_provider_location.c_physician&expand[]=c_provider_location.c_location`,
      this.httpOptions
    );
  }

  getOrderLineItems(orderId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orderLineItems}?where={"c_medable_order" : "` +
        orderId +
        `"}&expand[]=c_product`,
      this.httpOptions
    );
  }

  getOrderForm(orderFormId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orderForms}/${orderFormId}?expand[]=c_inventory_form.c_product&expand[]=c_inventory_form.c_form&expand[]=c_responses.c_question`,
      this.httpOptions
    );
  }

  getRuleForm(ruleFormId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orderModifierForms}/${ruleFormId}?expand[]=c_form&expand[]=c_product&expand[]=c_responses.c_question`,
      this.httpOptions
    );
  }

  deleteOrderForm(orderFormId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.orderForms}/${orderFormId}`,
      this.httpOptions
    );
  }

  deleteModifierForm(modifierFormId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.orderModifierForms}/${modifierFormId}`,
      this.httpOptions
    );
  }

  getOrderFormResponse(
    orderFormId: string,
    responseId: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.API.orderForms}/${orderFormId}/c_responses/${responseId}?expand[]=c_question`,
      this.httpOptions
    );
  }

  createOrderForm(orderForm: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.orderForms}`,
      orderForm,
      this.httpOptions
    );
  }

  editOrderForm(orderForm: any, orderFormId: string): Observable<any> {
    delete orderForm.acl;
    return this.http.put<any>(
      `${this.API.orderForms}/${orderFormId}`,
      orderForm,
      this.httpOptions
    );
  }

  createModifierForm(modifierForm: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.orderModifierForms}`,
      modifierForm,
      this.httpOptions
    );
  }

  editModifierForm(modifierForm: any, modifierFormId: string): Observable<any> {
    delete modifierForm.acl;
    return this.http.put<any>(
      `${this.API.orderModifierForms}/${modifierFormId}`,
      modifierForm,
      this.httpOptions
    );
  }

  createOrderFormResponse(response: any, orderFormId: string): Observable<any> {
    return this.http.post<any>(
      `${this.API.orderForms}/${orderFormId}/c_responses`,
      response,
      this.httpOptions
    );
  }

  editOrderFormResponse(
    response: any,
    orderFormId: string,
    responseId: string
  ): Observable<any> {
    delete response.acl;
    return this.http.put<any>(
      `${this.API.orderForms}/${orderFormId}/c_responses/${responseId}`,
      response,
      this.httpOptions
    );
  }

  deleteOrder(orderId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.orders}/${orderId}`,
      this.httpOptions
    );
  }

  deleteOrderLineItems(orderLineId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.orderLineItems}/${orderLineId}`,
      this.httpOptions
    );
  }

  updateSalesOrderCin7(order: any, lineItems: any, newLocation) {
    console.log('updating in cin7 Order: ', order);
    console.log('New location: ', newLocation);
    // return;
    const localSubject = new Subject();
    let salesOrder = {
      id: order.c_cin7_so_id,
      invoiceDate: order.c_order_date,
      createdDate: order.c_order_date,
      branchId: Number(newLocation),
      lineItems: [],
    };

    // if (order.c_billed_date) {
    //   salesOrder["dispatchedDate"] = order.c_billed_date;
    // } else {
    //   salesOrder["dispatchedDate"] = order.c_order_date;
    // }

    // Jessica asked to always use the order_date in cin7
    salesOrder['dispatchedDate'] = order.c_order_date;

    console.log('Lineitems: ', lineItems);
    // return

    // Buid cin7 salesOrder lineItems list, provide medable's internal lineItemId in the lineComments field

    for (let i = 0; i < lineItems.length; i++) {
      let medableItem = lineItems[i];
      // let product = medableItem.c_product;
      let cin7Item = {
        // "id": Number(product.c_cin7_id),

        code: medableItem.c_product_option_code,
        qty: medableItem.c_quantity,
        lineComments: medableItem._id,
      };

      // If medable lineitem has c_cin7_order_item_id, provide it to cin7 in the id field
      if (medableItem.c_cin7_order_item_id) {
        cin7Item['id'] = Number(medableItem.c_cin7_order_item_id);
      }

      salesOrder.lineItems.push(cin7Item);
    }

    console.log('Updating Cin7: ', salesOrder);
    console.log('Updating Cin7 String: ', JSON.stringify(salesOrder));

    // Void cin7 order's dispatched date
    this.voidCin7DispatchedDate(order.c_cin7_so_id).subscribe((ovrsp) => {
      console.log('Order dispatch voided response: ', ovrsp);

      // Update cin7 order with new data
      this.http
        .put<any>(`${this.API.salesOrderCin7}`, [salesOrder], this.httpOptions)
        .subscribe((response) => {
          console.log('Updating cin7 response: ', response);

          // Get cin7 order to save lineItem ids
          this.getSalesOrderCin7(order.c_cin7_so_id).subscribe((cin7OrdRsp) => {
            let svdCin7Items = cin7OrdRsp.data.lineItems;
            console.log('Cin7 lineitems: ', svdCin7Items);
            // Update lineItems from medable with cin7 LineItemIds
            let mdbItemUpdateReqs = [];

            if (svdCin7Items) {
              for (let idx = 0; idx < svdCin7Items.length; idx++) {
                const element = svdCin7Items[idx];
                let cin7LineItemId = element.id,
                  medableLineItemId = element.lineComments,
                  updateBody = { c_cin7_order_item_id: cin7LineItemId };
                console.log('Editing lineItem: ', medableLineItemId);
                console.log('Body: ', updateBody);
                let updateReq = this.editOrderLineItem(
                  updateBody,
                  medableLineItemId
                );
                mdbItemUpdateReqs.push(updateReq);
              }
            } else {
              localSubject.next(response);
              localSubject.complete();
            }

            forkJoin(mdbItemUpdateReqs).subscribe((mdbItemsUpdated) => {
              console.log('Medable lineItems updated: ', mdbItemsUpdated);
              localSubject.next(response);
              localSubject.complete();
            });
          });
        });
    });

    return localSubject;
  }

  /**
   * Get deleted line item details for a given order
   */
  getDeletedItems = (orderId: string): Observable<any> => {
    let idFilter = `{"c_medable_order" : "${orderId}"}`,
      deletedFilter = `{"c_deleted": true}`,
      filters = `{"$and": [${idFilter}, ${deletedFilter}]}`,
      where = `where=${filters}`;

    return this.http.get<any>(
      `${this.API.orderLineItems}?${where}&expand[]=c_product`,
      this.httpOptions
    );
  };

  voidCin7DispatchedDate(cin7OrderId: string): Observable<any> {
    let salesOrder = {
      id: cin7OrderId,
      dispatchedDate: '',
    };

    console.log('Voiding salesOrder String: ', JSON.stringify(salesOrder));

    return this.http.put<any>(
      `${this.API.salesOrderCin7}`,
      [salesOrder],
      this.httpOptions
    );
  }

  deleteSalesOrderCin7(cin7Id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.salesOrderCin7}/${cin7Id}`,
      this.httpOptions
    );
  }

  getSalesOrderCin7(cin7Id: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.salesOrderCin7}/${cin7Id}`,
      this.httpOptions
    );
  }

  generateRandomRefId = () => {
    const time = new Date().getTime();
    const a = Math.round(Math.random() * time);
    const b = Math.round(Math.random() * 123456789);

    const final = 'ROCK-157-' + a + b;

    if (final.length > 30) {
      const sliced = final.slice(0, 30);
      return sliced;
    }
    return final;
  };

  createSalesOrderCin7(
    orderId: string,
    order: any,
    lineItems: any,
    location: any
  ): Observable<any> {
    let localSubject = new Subject();
    console.log('Order: ', order);
    // Create cin7 sales order object
    let saleOrder = {
      invoiceDate: order.c_order_date,
      createdDate: order.c_order_date,
      stage: 'Dispatched',
      // branchId: 156,
      branchId: Number(location.c_cin7_id),
      company: order.c_patient.c_mrn,
      // firstName: order.c_patient.c_name.c_first_name,
      memberEmail: 'smasler@selectortho.net',
      // lastName: order.c_patient.c_name.c_last_name,
      lineItems: [],
      // Additional fields for testing provided by cin7 staff
      trackingCode: 'sample string',
      freightTotal: 1.0,
      InternalComments: '45',
      IsApproved: true,
      // End Additional fields for testing provided by cin7 staff
      customerOrderNo: order.c_number_id,
      billingCompany:
        order.c_selected_insurances.primary.document.c_insurance_name,
      deliveryFirstName:
        order.c_selected_provider.c_physician.c_name.c_first_name,
      deliveryLastName:
        order.c_selected_provider.c_physician.c_name.c_last_name,
      deliveryCompany: order.c_selected_provider.c_location.c_company,
      reference: this.generateRandomRefId(),
    };

    saleOrder['dispatchedDate'] = order.c_order_date;

    // Add Products to sin7 salesorder object
    // console.log('Order LineItems: ', lineItems);
    for (let i = 0; i < lineItems.length; i++) {
      let medableItem = lineItems[i];
      let product = medableItem.c_product;
      let cin7Item = {
        // id: Number(product.c_cin7_id),
        // code: product.cpy_product_option.c_code,
        code: medableItem.c_product_option_code,
        qty: medableItem.c_quantity,
        lineComments: medableItem._id,
        // qty_dispatch: medableItem.c_quantity
      };
      saleOrder.lineItems.push(cin7Item);
    }

    console.log('### Obj to send SalesOrder Cin7: ', saleOrder);

    // Submit request to create salesOrder in Cin7
    this.http
      .post<any>(`${this.API.salesOrderCin7}`, [saleOrder], this.httpOptions)
      .pipe(delay(750))
      .subscribe(
        (response) => {
          console.log('*****Cin7 Order Response: ', response);
          console.log(
            '*****Cin7 Order Data array: ',
            response.data,
            response.data.length
          );
          if (response.data.length) {
            console.log('*****Cin7 Order Data 0: ', response.data[0]);
            response = response.data[0];
            let cin7data = {
              c_cin7_so_id: response.id,
              c_cin7_so_code: response.code,
            };
            // console.log('cin7data from cin7: ', cin7data);

            // Update order in medable, saving the cin7 id & code
            this.editOrder(cin7data, orderId)
              .pipe(delay(250))
              .subscribe((resp) => {
                console.log('Order cin7 details added', resp);

                // Get request to get created cin7 order and it's item ids
                this.getSalesOrderCin7(response.id)
                  .pipe(delay(750))
                  .subscribe((cin7Order) => {
                    console.log('Cin7 order is: ', cin7Order);

                    let cin7LineItems = cin7Order.data.lineItems;
                    console.log('Cin7 lineitems: ', cin7LineItems);
                    // Update lineItems from medable with cin7 LineItemIds
                    let mdbItemUpdateReqs = [];

                    for (let idx = 0; idx < cin7LineItems.length; idx++) {
                      const element = cin7LineItems[idx];
                      let cin7LineItemId = element.id,
                        medableLineItemId = element.lineComments,
                        updateBody = { c_cin7_order_item_id: cin7LineItemId };
                      console.log('Editing lineItem: ', medableLineItemId);
                      console.log('Body: ', updateBody);
                      let updateReq = this.editOrderLineItem(
                        updateBody,
                        medableLineItemId
                      );
                      mdbItemUpdateReqs.push(updateReq);
                    }

                    forkJoin(mdbItemUpdateReqs).subscribe((mdbItemsUpdated) => {
                      console.log(
                        'Medable lineItems updated: ',
                        mdbItemsUpdated
                      );
                      localSubject.next(resp.data);
                    });
                  });
              });
          } else {
            /// SHOW POPUP WITH ERROR OR RETRY
            console.log('!!! NO DATA FROM CIN7');
          }
        },

        (error) => {
          /// SHOW POPUP WITH ERROR OR RETRY
          console.log('!!! CIN7 ERROR:', error);
          if (error.data) console.log('!!! CIN 7 ERROR DATA: ', error.data);
          if (error.message)
            console.log('!!! CIN 7 ERROR DATA: ', error.message);
        }
      );

    return localSubject;
  }

  /**
   * Function to create a credit note in cin7
   */
  createOrderCreditNoteCin7 = (data: any): Observable<any> => {
    let creditNote = data;

    console.log('Credit note provided string: ', JSON.stringify(creditNote));
    return this.http.post<any>(
      `${this.API.creditNote}`,
      [creditNote],
      this.httpOptions
    );
  };

  /**
   * Function to create a pyment in cin7
   */
  createCin7Payment = (
    creditNoteId: string,
    orderCode: string
  ): Observable<any> => {
    console.log('Provided order id is: ', creditNoteId);
    let now = moment().toISOString(),
      route = `${this.API.payment}`,
      opt = this.httpOptions;
    let paymentObj = {
      createdDate: now,
      modifiedDate: now,
      paymentDate: now,
      amount: 0.0,
      method: ' No Payment Required',
      // orderRef: orderCode,
      orderId: Number(creditNoteId),
      IsAuthorized: true,
    };

    console.log(
      'Provided payment payload string: ',
      JSON.stringify(paymentObj)
    );

    return this.http.post<any>(route, [paymentObj], opt);
  };

  /**
   * Function to get a credit note from cin7
   */
  getCin7CreditNote = (id: string): Observable<any> => {
    return this.http.get<any>(
      `${this.API.creditNote}?page=1`,
      this.httpOptions
    );
  };

  /**
   * Function to create a new credit note in Cin7.
   */
  createCin7CreditNote = (creditNote): Observable<any> => {
    let url = this.API.creditNote,
      body = [creditNote],
      opts = this.httpOptions;

    console.log('Create Item DEL credit note: ', JSON.stringify(body));

    return this.http.post<any>(url, body, opts);
  };

  /**
   * Function to update a creditNode in Cin7.
   */
  updateCin7CreditNote = (creditNote): Observable<any> => {
    let url = this.API.creditNote,
      body = [creditNote],
      opts = this.httpOptions;

    console.log('Updating cin7 credit note: ', JSON.stringify(creditNote));

    return this.http.put<any>(url, body, opts);
  };

  /**
   * Function to update a credit note in cin7
   */
  updateCreditNoteCin7 = (data: any): Observable<any> => {
    let creditNote = data;

    console.log('Credit note provided: ', creditNote);
    return this.http.put<any>(
      `${this.API.creditNote}`,
      [creditNote],
      this.httpOptions
    );
  };

  /**
   * Function to approove a cin7 creditnote
   */
  approoveCin7CreditNote = (id: any): Observable<any> => {
    // console.log("Credit note provided: ", creditNote);
    let creditNote = {
      id,
      IsApproved: true,
      CompletedDate: moment().toISOString(),
    };

    console.log('CreditNote approoved string is: ', JSON.stringify(creditNote));
    return this.http.put<any>(
      `${this.API.creditNote}`,
      [creditNote],
      this.httpOptions
    );
  };

  getInventoriesLocation(
    physician: any,
    page: number,
    path: string = null
  ): Observable<any> {
    let localSubject = new Subject();
    const self = this;
    let allInv = [];

    function loadAll() {
      let url =
        self.API.inventoryLocation +
        '?limit=1000&skip=' +
        page * 1000 +
        '&where={"c_location" : "' +
        physician.c_location._id +
        '"}&expand[]=c_location&expand[]=c_product.c_modifier_doc.c_modifier&expand[]=c_product';

      if (path) {
        url += '&path[]=' + path;
      }

      self.http.get<any>(url, self.httpOptions).subscribe((response) => {
        // console.log("Product_response: ", response.data);
        allInv = allInv.concat(response.data);

        if (response.hasMore) {
          page++;
          loadAll();
        } else {
          localSubject.next(allInv);
        }
      });
    }
    loadAll();
    return localSubject;
  }

  checkIcd10Exists(icd10Id: string): Observable<any> {
    return this.http.get(
      `${this.API.icd10s}?where={"_id" : "${icd10Id}"}`,
      this.httpOptions
    );
  }

  getOrdersForPatients(patientIds: string[]): Observable<any> {
    const expands = 'expand[]=c_provider_location&expand[]=c_patient';
    const filter = `where={"c_patient._id": {"$in": [${patientIds}]}}`;
    const sort = 'sort={"c_order_date": -1}';
    return this.http.get<any>(
      `${this.API.orders}?${filter}&${expands}&${sort}`,
      this.httpOptions
    );
  }

  getOrdersForPatientsAndLocations(
    patientIds: string[],
    locationIds: string[]
  ): Observable<any> {
    const filters = [
      `{"c_patient._id": {"$in": [${patientIds}]}}`,
      `{"c_provider_location._id": {"$in": [${locationIds}]}}`,
    ];
    const expands = 'expand[]=c_patient';
    const where = `where={"$and": [${filters.join(',')}]}&limit=10000`;
    const query = `${where}&${expands}`;
    return this.http.get<any>(`${this.API.orders}?${query}`, this.httpOptions);
  }

  getOrderByNumberId(filter: string): Observable<any> {
    const expands = 'expand[]=c_patient';
    const sort = 'sort={"c_order_date": -1}';
    return this.http.get<any>(
      `${this.API.orders}?${filter}&${expands}&${sort}`,
      this.httpOptions
    );
  }

  getLatestOrdersByLocation(locationIds: any[]) {
    const expands = `expand[]=c_patient`;
    const filters = [`{"c_provider_location._id": {"$in": [${locationIds}]}}`];

    const where = `where={"c_provider_location._id": {"$in": [${locationIds}]}}`;
    const sort = 'sort={"c_order_date": -1}';
    const query = `${where}&${expands}&${sort}`;

    return this.http.get<any>(`${this.API.orders}?${query}`, this.httpOptions);
  }

  getLatestOrdersAll(): Observable<any> {
    // Deleted Patients: 5b4cccb00ae20b0100427d0e | "5b6b38c07868b80100c4bd4a" | "5b69cdd87868b80100c3452e" | "5b6b3b257868b80100c4c32d"
    const where = 'where={"c_deleted_test": {"$ne": 1}}';
    const expands = 'expand[]=c_patient';
    const sort = 'sort={"c_order_date": -1}';
    const query = `${expands}&${sort}`;

    return this.http.get<any>(`${this.API.orders}?${query}`, this.httpOptions);
  }

  getOrdersByPatient(patientId: string): Observable<any> {
    const where = `where={"c_patient": "${patientId}"}`;
    const query = `${where}`;

    return this.http.get<any>(`${this.API.orders}?${query}`, this.httpOptions);
  }

  getAllOrdersMedable(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    const self = this;
    let page = 0;
    let allOrders = [];

    const query = 'expand[]=c_patient';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orders}?${query}&limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((ords) => {
          allOrders = allOrders.concat(ords);
          if (ords.hasMore) {
            console.log('Has More...');
            console.log('Currently loaded: ', allOrders.length);
            page++;
            loadAll();
          } else {
            console.log('All Loaded!');
            localSubject.next(allOrders);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getAllOrdersMedableNew(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 5000;
    const self = this;
    let page = 0;
    let allOrders = [];

    const query = 'paths[]=c_deleted';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orders}?${query}&limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((ords) => {
          allOrders = allOrders.concat(ords);
          if (ords.hasMore) {
            console.log('Has More...');
            console.log('Currently loaded: ', allOrders.length);
            page++;
            loadAll();
          } else {
            console.log('All Loaded!');
            localSubject.next(allOrders);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getAllOrdersMedableWSupervisors(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    const self = this;
    let page = 0;
    let allOrders = [];

    const query =
      'paths[]=c_provider_location&paths[]=c_billing_provider&expand[]=c_provider_location.c_physician';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orders}?${query}&limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((ords) => {
          allOrders = allOrders.concat(ords.data);
          if (ords.hasMore) {
            console.log('Has More...');
            // console.log("Currently loaded: ", allOrders.length);
            page++;
            loadAll();
          } else {
            // console.log("All Loaded!")
            localSubject.next({ array: allOrders });
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getAllOrdersMedablePartial(query: string): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    const self = this;
    let page = 0;
    let allOrders = [];

    // const query = 'paths[]=c_patient';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orders}?${query}&limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((ords) => {
          allOrders = allOrders.concat(ords.data);
          if (ords.hasMore) {
            console.log('Has More...');
            console.log('Currently loaded: ', allOrders.length);
            page++;
            loadAll();
          } else {
            console.log('All Loaded!');
            localSubject.next(allOrders);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getAllHcpcsCodesMedable(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    const self = this;
    let page = 0;
    let allCodes = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((codes) => {
          allCodes = allCodes.concat(codes);
          if (codes.hasMore) {
            console.log('Has More...');
            console.log('Currently loaded: ', allCodes.length);
            page++;
            loadAll();
          } else {
            console.log('All Loaded!');
            localSubject.next(allCodes);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getOrderLineWithICD(query: string): Observable<any> {
    const localSubject = new Subject();
    const promises = [];
    const self = this;

    this.http
      .get<any>(`${this.API.orderLineItems}${query}`, this.httpOptions)
      .subscribe((res) => {
        res.data.map((line) => {
          const req = self.populateIcdObject(line, line.c_icd_10);
          promises.push(req);
        });

        if (promises.length) {
          forkJoin(promises).subscribe(() => {
            localSubject.next(res);
            localSubject.complete();
          });
        } else {
          localSubject.next(res);
          localSubject.complete();
        }
      });

    return localSubject;
  }

  populateIcdObject(object, icdIds): Observable<any> {
    const localSubject = new Subject();
    // console.log("sent ocds: ", icdIds);
    // return;
    const query = `?where={"_id": {"$in": [${icdIds.map(
      (icd) => `"${icd}"`
    )}]}}`;

    this.formsService.get('icd10s', query).subscribe((icds) => {
      // console.log("Expanded icds: ", icds);
      object['icds_expanded'] = icds.data.map((icd) => icd.c_icd_10_code);
      localSubject.next(icds);
      localSubject.complete();
    });

    return localSubject;
  }

  getAllOrderIds(query, cb = null): Observable<any> {
    const localSubject = new Subject();
    const chunk = 100;
    let allIds = [];
    const self = this;
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orders}?${query}&skip=${chunk * page}&limit=${chunk}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allIds = allIds.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More...');
            page++;

            if (cb != null) {
              cb(page);
            }

            loadAll();
          } else {
            // console.log("All Orders Loaded");
            localSubject.next(allIds);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllOrderItems(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    let allIds = [];
    const self = this;
    let page = 0;
    const query =
      'expand[]=c_medable_order&paths[]=c_medable_order.c_provider_location';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orderLineItems}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allIds = allIds.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More...');
            page++;
            loadAll();
          } else {
            console.log('All OrderItems Loaded');
            localSubject.next(allIds);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllOrderItemsDebug(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    let allIds = [];
    const self = this;
    let page = 0;
    const query =
      'expand[]=c_medable_order&paths[]=c_medable_order.c_last_modified&paths[]=c_order_last_modified';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.orderLineItems}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allIds = allIds.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More...');
            page++;
            loadAll();
          } else {
            console.log('All OrderItems Loaded');
            localSubject.next(allIds);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllIcdCodes() {
    const localSubject = new Subject();
    const chunck = 10000;
    let allIcds = [];
    const self = this;
    let page = 0;
    const query = 'paths[]=c_icd_10_code';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.icd10s}?${query}&skip=${chunck * page}&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allIcds = allIcds.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More...');
            page++;
            loadAll();
          } else {
            console.log('All Icds Loaded');
            localSubject.next(allIcds);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllProviderLocations() {
    const localSubject = new Subject();
    const chunck = 10000;
    let allProviderLocations = [];
    const self = this;
    let page = 0;
    const query = 'expand[]=c_location&paths[]=c_location';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.providerLocations}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allProviderLocations = allProviderLocations.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More pLocations...');
            page++;
            loadAll();
          } else {
            // console.log("All pLocations Loaded");
            localSubject.next(allProviderLocations);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllProviderLocationsByLocation(locationId: string) {
    const localSubject = new Subject();
    const chunck = 10000;
    let allProviderLocations = [];
    const self = this;
    let page = 0;
    const query = `where={"c_location": "${locationId}"}&expand[]=c_location&paths[]=c_location`;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.providerLocations}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((orders) => {
          allProviderLocations = allProviderLocations.concat(orders.data);
          if (orders.hasMore) {
            console.log('Has More pLocations...');
            page++;
            loadAll();
          } else {
            console.log('All pLocations Loaded');
            localSubject.next({ data: allProviderLocations });
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  // getAllLocations
  getAllLocations() {
    const localSubject = new Subject();
    const chunck = 10000;
    let allLocations = [];
    const self = this;
    let page = 0;
    const query = '';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.locations}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((locations) => {
          allLocations = allLocations.concat(locations.data);
          if (locations.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLocations);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllInsurances() {
    const localSubject = new Subject();
    const chunck = 10000;
    let allInsurances = [];
    const self = this;
    let page = 0;
    const query = '';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.insurances}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((insurances) => {
          allInsurances = allInsurances.concat(insurances.data);
          if (insurances.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allInsurances);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  // inventories
  getAllProducts() {
    const localSubject = new Subject();
    const chunck = 10000;
    let allProducts = [];
    const self = this;
    let page = 0;
    const query = '';

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.inventories}?${query}&skip=${
            chunck * page
          }&limit=${chunck}`,
          self.httpOptions
        )
        .subscribe((products) => {
          allProducts = allProducts.concat(products.data);
          if (products.hasMore) {
            console.log('Has More Products...');
            page++;
            loadAll();
          } else {
            localSubject.next(allProducts);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getOrderExternalDocumentContent(orderId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orders}/${orderId}/c_order_document`,
      this.httpOptions
    );
  }

  saveOrderExternalDocument(file: any, orderId: string): Observable<any> {
    return this.http.post<any>(
      `${this.API.orders}/${orderId}/c_order_document`,
      { content: file.name },
      this.httpOptions
    );
  }

  removeOrderExternalDocument(id: any, orderId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.orders}/${orderId}/c_order_document/${id}`,
      this.httpOptions
    );
  }

  saveOrderSignature(file: any, orderId: string): Observable<any> {
    return this.http.put<any>(
      `${this.API.orders}/${orderId}/c_order_signature`,
      { content: file.name },
      this.httpOptions
    );
  }

  removeOrderSignature(orderId: string): Observable<any> {
    let body = {
      op: 'unset',
      value: {
        c_order_signature: 1,
      },
    };
    return this.http.patch<any>(
      `${this.API.orders}/${orderId}`,
      body,
      this.httpOptions
    );
  }

  getOrderSignatureContent(orderId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orders}/${orderId}/c_order_signature/content`,
      this.httpOptions
    );
  }

  //orderModifierForms
  saveMfSignature(file: any, mfId: string): Observable<any> {
    return this.http.put<any>(
      `${this.API.orderModifierForms}/${mfId}/c_form_signature`,
      { content: file.name },
      this.httpOptions
    );
  }

  getMfSignatureContent(mfId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.orderModifierForms}/${mfId}/c_form_signature/content`,
      this.httpOptions
    );
  }

  getAllDispatchedOrders(): Observable<any> {
    let page = 0;
    let localSubject = new Subject();
    const self = this;
    let allOrd = [];

    function loadAll() {
      let url = self.API.orders + '?limit=1000&skip=' + page * 1000;

      self.http.get<any>(url, self.httpOptions).subscribe((response) => {
        allOrd = allOrd.concat(response.data);

        if (response.hasMore) {
          console.log('has more orders...');
          page++;
          loadAll();
        } else {
          localSubject.next(allOrd);
        }
      });
    }
    loadAll();
    return localSubject;
  }

  deleteOrderFromExternalAccess(orderId: string): Observable<any> {
    return this.http.get<any>(
      `${this.externalAccessEndpoint}?action=deleteOrder&orderId=${orderId}`
    );
  }
}
