import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable, Subject } from 'rxjs';
import { FormsService } from '../forms/forms.service';
import { LocationService } from '../location/location.service';
import { ProviderService } from '../provider/provider.service';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { DOCUMENT } from '@angular/common';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class FormService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;
  constants = CONSTANTS;
  document = DOCUMENT;

  hasSignature: boolean = false;
  hasLogo: boolean = false;

  allFitters = [];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  noCredentialOptions = {
    headers: new HttpHeaders({
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: false,
  };

  constructor(
    private http: HttpClient,
    private formsService: FormsService,
    private providerService: ProviderService,
    private locationService: LocationService
  ) { }

  createForm(form: any, file: any): Observable<any> {
    let localSubject = new Subject();

    this.http
      .post<any>(`${this.API.forms}`, form, this.httpOptions)
      .subscribe((response) => {
        console.log('response: ', response);
        this.sendFormFile(file, response._id);
        localSubject.next(response);
        localSubject.complete();
      });

    return localSubject;
  }

  sendFormFile(file: any, formId: string): Observable<any> {
    let localSubject = new Subject();
    let data = {
      content: file.name,
    };

    this.http
      .put<any>(
        `${this.API.forms}/${formId}/c_template`,
        data,
        this.httpOptions
      )
      .subscribe((response) => {
        console.log('sendFormFile: ', response);
        this.formsService.sendFileAWS(file, response.data).subscribe((rs) => {
          console.log('sendFileAWS: ', rs);
        });
        localSubject.next(1);
        localSubject.complete();
      });

    return localSubject;
  }

  editForm(form: any, formId: string, file = null): Observable<any> {
    delete form.acl;
    let localSubject = new Subject();
    delete form.acl;
    // console.log("Updating Form: ", form);
    this.http
      .put<any>(`${this.API.forms}/${formId}`, form, this.httpOptions)
      .subscribe((response) => {
        if (file !== null) {
          this.sendFormFile(file, formId);
        }
        console.log('Updated: ', response);
        localSubject.next(response.data);
        localSubject.complete();
      });

    return localSubject;
  }

  getForm(formId: string): Observable<any> {
    const expands = [
      'expand[]=c_hcpcs_codes.c_hcpcs_code',
      'expand[]=c_insurances.c_insurance',
      'expand[]=c_locations.c_location',
    ];

    return this.http.get<any>(
      `${this.API.forms}/${formId}?${expands.join('&')}`,
      this.httpOptions
    );
  }

  deleteForm(formId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.forms}/${formId}`,
      this.httpOptions
    );
  }

  getForms(): Observable<any> {
    return this.http.get(`${this.API.forms}`, this.httpOptions);
  }

  createQuestion(question: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.questions}`,
      question,
      this.httpOptions
    );
  }

  editQuestion(question: any, questionId: string): Observable<any> {
    delete question.acl;
    return this.http.put<any>(
      `${this.API.questions}/${questionId}`,
      question,
      this.httpOptions
    );
  }

  deleteQuestion(questionId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.questions}/${questionId}`,
      this.httpOptions
    );
  }

  getPDFForm(
    formSignature,
    formId: string,
    formName: string,
    fitters: Array<Object>,
    dataForm: any
  ): Observable<any> {
    let localSubject = new Subject();
    this.allFitters = fitters;
    console.log('FormName: ', formName);
    this.http
      .get<any>(
        `${this.API.forms}?where={"_id":"` + formId + `"}`,
        this.httpOptions
      )
      .subscribe((response) => {
        console.log('Response: ', response);
        this.getFormContentPopulate(
          formSignature,
          formName,
          response.data[0]._id,
          dataForm
        );
        localSubject.next(1);
        localSubject.complete();
      });

    return localSubject;
  }

  getFormContentPopulate(
    formSignature,
    formName: string,
    formId: string,
    dataForm: any
  ): Observable<any> {
    let localSubject = new Subject();

    this.http
      .get<any>(
        `${this.API.forms}/${formId}/c_template/content`,
        this.httpOptions
      )
      .subscribe((response) => {
        // console.log("Response: ", response);
        this.getFormJSONPopulate(
          formSignature,
          formName,
          response.url,
          dataForm
        );
        localSubject.next(1);
        localSubject.complete();
      });

    return localSubject;
  }

  getFormJSONPopulate(
    formSignature,
    formName: string,
    urlJSON: string,
    dataForm: any
  ): Observable<any> {
    let localSubject = new Subject();

    this.http
      .get<any>(urlJSON, this.noCredentialOptions)
      .subscribe((response) => {
        console.log(' DataForm: ', dataForm);
        const dd = JSON.stringify(response);
        console.log('dd: ', dd);
        if (formName.indexOf('ABN') !== -1) {
          this.getABNForm(formSignature, dd, dataForm);
        } else if (formName.indexOf('MAE') !== -1) {
          this.getMAEForm(formSignature, dd, dataForm);
        } else if (formName.indexOf('LMN') !== -1) {
          this.getLMNForm(formSignature, dd, dataForm);
        } else if (formName.toLowerCase().indexOf('notice') !== -1) {
          this.getNoticeForm(formSignature, dd, dataForm);
        } else if (formName.toLowerCase().indexOf('receipt') !== -1) {
          this.getReceiptForm(formSignature, dd, dataForm);
        } else if (formName.toLowerCase().indexOf('indian') !== -1) {
          this.getIndianHealthForm(formSignature, dd, dataForm);
        } else if (formName.toLowerCase().indexOf('clinic') !== -1) {
          this.getClinicDocumentationForm(formSignature, dd, dataForm);
        } else {
          this.getWarrantyForm(formSignature, dd, dataForm);
        }

        localSubject.next(response.data);
      });

    return localSubject;
  }

  populateForm(docDefinition) {
    console.log('populating form: ', docDefinition);
    const tmpDoc = JSON.parse(docDefinition);
    delete tmpDoc.defaultStyle;
    pdfMake.createPdf(tmpDoc).open();
  }

  /**
   * Change req by Jessica on ~ 13 March 2020
   * For the ABN form, pull in all hcpcs codes from the order & change the "D. ${HCPCS_CODE}" syntaxt to => "equipment"
   */
  getABNForm(formSignature, docDefinition, dataForm) {
    console.log('*** ABN DataForm: ', dataForm);
    const clinicName = this.getClinicName(dataForm),
      clinicLocation = this.buildClinicLocation(
        dataForm.order.c_provider_location.c_location
      ),
      clinicPhone = this.getClinicPhoneFromLocation(
        dataForm.order.c_provider_location.c_location
      ),
      options = {
        op1: this.getAbnOptionImg(dataForm, 0),
        op2: this.getAbnOptionImg(dataForm, 1),
        op3: this.getAbnOptionImg(dataForm, 2),
      };

    // Replace Notifier
    docDefinition = docDefinition.replace(
      '##NOTIFIER##',
      this.getOrderCreatorName(dataForm)
    );

    // Replace signature placeholder
    if (formSignature) {
      docDefinition = docDefinition.replace(
        '"##PATIENT_SIGNATURE##"',
        JSON.stringify({ image: formSignature, fit: [100, 30] })
      );
    } else {
      docDefinition = docDefinition.replace('##PATIENT_SIGNATURE##', '');
    }

    // Replace Clinic & Patient details
    docDefinition = docDefinition.replace(
      '##CLINIC_NAME##',
      clinicName.replace(/\r?\n|\r/g, ' ')
    );
    docDefinition = docDefinition.replace('##CLINIC_PHONE##', clinicPhone);
    docDefinition = docDefinition.replace(
      '"##CLINIC_ADDRESS##"',
      JSON.stringify(clinicLocation)
    );
    docDefinition = docDefinition.replace(
      '##PATIENT_NAME##',
      this.getPatientName(dataForm.order)
    );
    docDefinition = docDefinition.replace(
      '##ID_NUMBER##',
      dataForm.order.c_patient.c_mrn
    );

    // Replace HCPCS Code placeholder with "equipment"
    docDefinition = docDefinition.replace(
      new RegExp('##HCPC_CODE##', 'g'),
      'equipment'
    );
    // Replace HCPCS Codes placeholder with hcpcs code list and mofifiers
    let hcpcsList = this.getAbnHcpcsList(dataForm);
    docDefinition = docDefinition.replace(
      new RegExp('##HCPC_CODES##', 'g'),
      hcpcsList
    );

    //console.log("****--- ABN HCPCS LIST: ", hcpcsList)

    //docDefinition = (dataForm.orderForm.c_responses[0].c_response[0] === "'0'") ? docDefinition.replace("##REASON##", dataForm.orderForm.c_responses[0].c_question.c_response_options[0]) : docDefinition.replace('##REASON##', dataForm.orderForm.c_responses[0].c_question.c_response_options[1]);
    docDefinition = docDefinition.replace(
      '##REASON##',
      this.getOptionResponse(dataForm)
    );
    docDefinition = docDefinition.replace(
      '##ESTIMATED_COST##',
      dataForm.orderForm.c_responses[1].c_response[0]
    );
    docDefinition = docDefinition.replace('##OPTION_1##', options.op1);
    docDefinition = docDefinition.replace('##OPTION_2##', options.op2);
    docDefinition = docDefinition.replace('##OPTION_3##', options.op3);
    const date = new Date(dataForm.order.c_order_date);
    const formattedDate = this.formatMedableDate(dataForm.order.c_order_date);
    docDefinition = docDefinition.replace('##DATE##', formattedDate);

    this.populateForm(docDefinition);
  }

  /**
   * ABN - Helper
   * Function to get image (checked / unchecked) for ABN form options
   */
  getAbnOptionImg = (dataForm, optionIdx) => {
    if (dataForm.orderForm.c_responses[2].c_response[0] == `'${optionIdx}'`) {
      return this.constants.IMG_CHECKED;
    }

    return this.constants.IMG_NOT_CHECKED;
  };

  /**
   * ABN - Helper
   * Function to get HCPCS list with their modifiers for the ABN form.
   */
  getAbnHcpcsList = (dataForm) => {
    console.log('***--- ABN DataForm: ', dataForm);
    // console.
    // Only pull in codes from lines that have the GA modifier checked
    // This means that "Yes" was selected to the question of the user has received similar items in the past.
    //let filteredCodes = dataForm.lineI

    //let firstCode = this.getAttacherHcpcsAbn(dataForm)
    let codeList = dataForm.lineItems
      .filter((line) => {
        let hasGa = this.hasGaModifier(line),
          prod = line.c_product,
          code = prod.c_hcpcs_code;
        //notFirst = line._id !== firstCode.lineId

        return hasGa;
      })
      .map((line) => {
        let prod = line.c_product,
          code = line.c_selected_hcpcs || prod.c_hcpcs_code,
          mod = this.getLtRtModifier(line.c_modifier),
          string = mod ? `${code} - ${mod}` : code;

        return string;
      });

    //codeList.unshift(firstCode.string)

    return codeList.join('; ');
  };

  /**
   * ABN - Helper
   * Function to get the HCPCS code and RT/LT modifiers from the item that
   * attached the ABN form to the order
   */
  getAttacherHcpcsAbn = (dataForm) => {
    let orderLine = dataForm.orderLine,
      prod = orderLine.c_product,
      hcpcs = prod.c_hcpcs_code,
      mod = this.getLtRtModifier(orderLine.c_modifier),
      string = mod ? `${hcpcs} - ${mod}` : hcpcs;

    return {
      lineId: orderLine._id,
      code: hcpcs,
      string,
    };
  };

  /**
   * ABN - Helper
   * Function to check if a line has GA modifier checked
   */
  hasGaModifier = (lineItem) => {
    let filtered = lineItem.c_modifier.filter((m) => {
      let mod = (m.c_modifier || '').toLowerCase(),
        isGa = mod === 'ga',
        isChecked = m.c_checked;
      return isGa && isChecked;
    });

    return filtered.length > 0;
  };

  /**
   * Helper Modifier
   * Function to get LT or RT modifier from list if they are checked
   */
  getLtRtModifier = (modifiers) => {
    let filtered = modifiers.filter((m) => {
      let mod = (m.c_modifier || '').toLowerCase(),
        isLt = mod === 'lt',
        isRt = mod === 'rt',
        checked = m.c_checked;
      return (isLt || isRt) && checked;
    });

    return (filtered[0] || {}).c_modifier || '';
  };

  /**
   * Generic Helper
   * Function to get order creator name
   */
  getOrderCreatorName = (dataForm) => {
    if (dataForm.order.creator.name != undefined) {
      return `${dataForm.order.creator.name.first} ${dataForm.order.creator.name.last}`;
    }

    return '-';
  };

  getOptionResponse(dataForm) {
    const selectedOptionIndex = parseInt(
      dataForm.orderForm.c_responses[0].c_response[0].replace("'", '')
    ),
      selectedOptionText =
        dataForm.orderForm.c_responses[0].c_question.c_response_options[
        selectedOptionIndex
        ];

    // console.log("SelectedOptionIndex: ", dataForm.orderForm.c_responses[0].c_response[0]);
    console.log(
      'Selected Options: ',
      dataForm.orderForm.c_responses[0].c_question.c_response_options[2]
    );
    return selectedOptionText;
  }

  formatMedableDate(medableDate) {
    const d = medableDate.split(/\D/);
    const year = d[0];
    const month = d[1];
    const day = d[2];

    return `${month}/${day}/${year}`;
  }

  getDataContainingParts(searchTerms, warrantyForm) {
    let response = '-';
    const questionIndex = this.getQuestionIndexContainingParts(
      searchTerms,
      warrantyForm
    );

    if (questionIndex == -1) {
      return response;
    }
    const question = warrantyForm.c_responses[questionIndex],
      responseType = question.c_question.c_response_type;

    if (responseType == 'text') {
      response = question.c_response[0];
      return response;
    } else if (responseType == 'check') {
      const responseIndex = parseInt(question.c_response[0].replace("'", ''));
      const response = question.c_question.c_response_options[responseIndex];
      return response;
    }
    return response;
  }

  getQuestionIndexContainingParts(searchTerms, warrantyForm) {
    let index = -1;
    const questionList = warrantyForm.c_responses;
    questionList.map((question, idx) => {
      const termsFound = searchTerms.filter(
        (st) => question.c_question.c_text.toLowerCase().indexOf(st) > -1
      ).length;
      if (termsFound == searchTerms.length) {
        index = idx;
      }
    });
    return index;
  }

  extractClinicName(warrantyForm) {
    return this.getDataContainingParts(['clinic', 'name'], warrantyForm);
  }

  extractClinicAbbreviation(warrantyForm) {
    return this.getDataContainingParts(
      ['clinic', 'abbreviation'],
      warrantyForm
    );
  }

  extractClinicLocation(warrantyForm) {
    return this.getDataContainingParts(['clinic', 'location'], warrantyForm);
  }

  extractClinicState(warrantyForm) {
    return this.getDataContainingParts(['address', 'state'], warrantyForm);
  }

  extractClinicCity(warrantyForm) {
    return this.getDataContainingParts(['address', 'city'], warrantyForm);
  }

  extractClinicPostCode(warrantyForm) {
    return this.getDataContainingParts(
      ['address', 'postal', 'code'],
      warrantyForm
    );
  }

  extractClinicPhone(warrantyForm) {
    return this.getDataContainingParts(['phone', 'number'], warrantyForm);
  }

  extractClinicStreet(warrantyForm) {
    return this.getDataContainingParts(['address', 'street'], warrantyForm);
  }

  getReceiptForm(formSignature, docDefinition, dataForm) {
    console.log('Receipt Form: ', JSON.parse(docDefinition));
    console.log('dataForm: ', dataForm);

    let payedItems = this.getItemsWithPayment(dataForm.lineItems),
      formatedItems = this.formatItemsForReceipt(payedItems, dataForm.order),
      doc = JSON.parse(docDefinition),
      itemsStack = doc.content[3].stack.concat(formatedItems);

    doc.content[3].stack = itemsStack;

    this.populateReceiptDetails(doc, dataForm.order);
    this.populateReceiptTotal(doc, payedItems);

    let stringified = JSON.stringify(doc);

    const hasLogo = dataForm.order.c_provider_location.c_location.c_clinic_logo;

    if (!hasLogo) {
      docDefinition = stringified.replace('##CLINIC_LOGO##', '');

      this.populateForm(docDefinition);
      return;
    }

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const logoUrlPromise = this.locationService.getImageContent(
      dataForm.order.c_provider_location.c_location._id
    );
    logoUrlPromise.subscribe((logoUrl) => {
      const imgReq = this.http.get<any>(logoUrl.url, httpAux);
      imgReq.subscribe((img) => {
        const processedImgReq = this.processfileImg(img, 'logo', [
          false,
          false,
          false,
          false,
        ]);
        processedImgReq.subscribe((processedImg) => {
          doc.content[0].table.body[1][0] = processedImg;
          docDefinition = JSON.stringify(doc);

          this.populateForm(docDefinition);
          return;
        });
      });
    });

    // this.populateForm(stringified);
  }

  buildReceiptPaymentDetails = (items) => {
    let details = {};

    if (!items.length) {
      return '';
    }

    let method = items[0].c_payment_method,
      checkNr = items[0].c_check_number || '-',
      cardType = items[0].c_card_type || '-',
      cardNr = items[0].c_card_number || '-',
      cardCode = items[0].c_approval_code || '-';

    switch (method) {
      case 1:
        details = [
          {
            text: 'Payment Method: Cash',
          },
        ];
        break;

      case 2:
        details = [
          {
            text: 'Payment Method: Check',
          },
          {
            text: `Check Number: ${checkNr}`,
          },
        ];

        break;

      case 3:
        details = [
          {
            text: 'Payment Method: Card',
          },
          {
            text: `Card Type: ${cardType}, Card Number: ${cardNr}, Approval Code: ${cardCode}`,
          },
        ];
        break;

      default:
        details = [
          {
            text: 'Payment Method: Cash',
          },
        ];
    }

    return {
      border: [false, true, false, false],
      stack: details,
    };
  };

  populateReceiptTotal = (form, items) => {
    let paymentDetails = this.buildReceiptPaymentDetails(items),
      totalPaid = items
        .map((item) => item.c_self_pay_cost)
        .reduce((a, b) => a + b, 0);

    let totalDetails = {
      margin: [0, 15, 0, 0],
      table: {
        widths: [450, 60],
        body: [
          [
            {
              border: [false, false, false, true],
              text: 'Payment Method',
            },
            {
              border: [false, false, false, true],
              text: 'Total Paid:',
              bold: true,
            },
          ],
          [
            paymentDetails,
            {
              border: [false, true, false, false],
              text: this.currencyFormat(totalPaid),
            },
          ],
        ],
      },
    };

    form.content.push(totalDetails);
  };

  timestampToReadable = (timestamp) => {
    if (!timestamp) {
      return '';
    }

    const ts = parseInt(timestamp);
    const date = new Date(ts),
      mo =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1,
      d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      y = date.getFullYear(),
      h = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();

    // return `${mo}/${d}/${y}, ${h}:${min}:${sec}`;
    return `${mo}/${d}/${y}, ${h}:${min}`;
  };

  populateReceiptDetails = (form, order) => {
    let orderDate = this.formatMedableDate(order.c_order_date),
      orderNumber = order.c_number_id,
      patientName = this.getPatientName(order),
      patientMRN = this.getPatientMRN(order),
      location = order.c_provider_location.c_location,
      clinicName = location.c_company,
      clinicPhone = location.c_phone,
      clinicAddress = this.buildClinicLocation(location, 'left');

    // console.log("Location: ", location)
    // console.log("clinicPhone: ", clinicPhone)
    form.content[0].table.body[0][0].text = clinicName;
    form.content[0].table.body[2][0] = clinicAddress;
    form.content[0].table.body[3][0].text = clinicPhone;
    form.content[2].table.body[0][1].text = orderDate;
    form.content[2].table.body[1][1].text = orderNumber;
    form.content[2].table.body[2][1].text = patientName;

    //add mrn to receipt pdf
    let pMRNBody = [{ text: 'MRN', border: [false, false, false, false], margin: [0, 10, 0, 0] }, { text: patientMRN, border: [false, false, false, true], margin: [0, 10, 0, 0] }];
    form.content[2].table.body.push(pMRNBody);
    if (order.c_created_timestamp) {
      form.footer.stack[1] = {
        text: this.timestampToReadable(order.c_created_timestamp),
        margin: [30, 0, 0, 0],
      };
    } else {
      form.footer.stack[1] = {
        text: '##ORDER_DATE## , ##TIMESTAMP##',
        margin: [30, 0, 0, 0],
      };
    }
  };

  getPatientName = (order) => {
    let pName = order.c_patient.c_name,
      firstName = pName.c_first_name,
      lastName = pName.c_last_name;

    return `${firstName} ${lastName}`;
  };

  getPatientMRN = (order) => {
    let pMRN = order.c_patient.c_mrn;
    return pMRN;
  }

  getItemsWithPayment = (lineItems) => {
    return lineItems.filter((line) => line.c_self_pay_cost > 0);
  };

  currencyFormat = (number, currency = '$') => {
    let val = Math.round(number * 100) / 100,
      rsp = `${currency}${parseFloat(val.toString()).toFixed(2)}`;

    return rsp;
  };

  formatItemsForReceipt = (lineItems, order) => {
    return lineItems.map((item) => {
      let product = item.c_product,
        quantity = item.c_quantity,
        tax = this.currencyFormat(item.c_sales_tax),
        cost = this.currencyFormat(item.c_self_pay_cost),
        productOption = product.cpy_product_option,
        productName = product.c_name,
        hcpcs = item.c_selected_hcpcs || product.c_hcpcs_code,
        sku = productOption.c_code,
        size = productOption.c_size;

      let obj = {
        margin: [0, 0, 0, 0],
        table: {
          widths: [300, 60, 60, 70],
          body: [
            [
              {
                border: [false, false, false, true],
                stack: [
                  {
                    margin: [0, 5, 0, 0],
                    text: `${productName} - ${size}`,
                  },
                  {
                    margin: [0, 5, 0, 0],
                    text: `${hcpcs} ${sku}`,
                  },
                ],
              },
              {
                border: [false, false, false, true],
                margin: [0, 20, 0, 0],
                text: quantity,
              },
              {
                border: [false, false, false, true],
                margin: [0, 20, 0, 0],
                text: tax,
              },
              {
                border: [false, false, false, true],
                margin: [0, 20, 0, 0],
                text: cost,
              },
            ],
          ],
        },
      };

      return obj;
    });
  };

  getWarrantyForm(formSignature, docDefinition, dataForm) {
    // const date = new Date(dataForm.order.c_order_date);
    // const rsp = dataForm.orderForm.c_responses;
    console.log('warrantyFormObj: ', dataForm.warrantyForm);
    let warrantyFormObj = dataForm.warrantyForm,
      clinicName = this.extractClinicName(warrantyFormObj),
      clinicAbbreviation = this.extractClinicAbbreviation(warrantyFormObj),
      clinicLocation = this.extractClinicLocation(warrantyFormObj),
      clinicState = this.extractClinicState(warrantyFormObj),
      clinicCity = this.extractClinicCity(warrantyFormObj),
      clinicPostCode = this.extractClinicPostCode(warrantyFormObj),
      clinicPhone = this.extractClinicPhone(warrantyFormObj),
      clinicStreet = this.extractClinicStreet(warrantyFormObj);

    if (!clinicName) {
      clinicName = warrantyFormObj.c_responses[0].c_response[0];
    }
    if (!clinicAbbreviation) {
      clinicAbbreviation = warrantyFormObj.c_responses[1].c_response[0];
    }
    // console.log("Clinic Name is: ", clinicName);
    // console.log("Clinic Abbreviation is: ", clinicAbbreviation);
    // console.log("Clinic Location is: ", clinicLocation);
    // console.log("Clinic State is: ", clinicState);
    // console.log("Clinic City is: ", clinicCity);
    // console.log("Clinic PostCode is: ", clinicPostCode);
    // console.log("Clinic Phone is: ", clinicPhone);
    // console.log("Clinic Street is: ", clinicStreet);

    docDefinition = docDefinition.replace(
      '##CLINIC_NAME##',
      clinicName ? clinicName.replace(/\r?\n|\r/g, ' ') : '-'
    );
    docDefinition = docDefinition.replace(
      new RegExp('##CLINIC_ABBREVIATION##', 'g'),
      clinicAbbreviation ? clinicAbbreviation : '-'
    );
    docDefinition = docDefinition.replace(
      '##CLINIC_LOCATION##',
      clinicLocation
    );
    docDefinition = docDefinition.replace('##CLINIC_CITY##', clinicCity);
    docDefinition = docDefinition.replace('##CLINIC_PHONE##', clinicPhone);
    docDefinition = docDefinition.replace('##CLINIC_STATE##', clinicState);
    docDefinition = docDefinition.replace('##CLINIC_ZIP##', clinicPostCode);
    docDefinition = docDefinition.replace('##CLINIC_STREET##', clinicStreet);
    // if (dataForm.orderForm.c_responses.length === 2 || dataForm.orderForm.c_responses.length === 0) {
    //   docDefinition = docDefinition.replace("##CLINIC_LOCATION##", '');
    //   docDefinition = docDefinition.replace("##CLINIC_CITY##", '');
    //   docDefinition = docDefinition.replace("##CLINIC_PHONE##", '');
    //   docDefinition = docDefinition.replace("##CLINIC_STATE##", '');
    //   docDefinition = docDefinition.replace("##CLINIC_ZIP##", '');
    //   docDefinition = docDefinition.replace("##CLINIC_STREET##", '');
    // } else if (dataForm.orderForm.c_responses.length > 2) {
    //   docDefinition = docDefinition.replace("##CLINIC_LOCATION##", rsp[2] ? rsp[2].c_response[0] : '-');
    //   docDefinition = docDefinition.replace("##CLINIC_STATE##", rsp[3] ? rsp[3].c_response[0] : '-');
    //   docDefinition = docDefinition.replace("##CLINIC_CITY##", rsp[4] ? rsp[4].c_response[0] : '-');
    //   docDefinition = docDefinition.replace("##CLINIC_ZIP##", rsp[5] ? rsp[5].c_response[0] : '-');
    //   docDefinition = docDefinition.replace("##CLINIC_PHONE##", rsp[6] ? this.formatPhone(rsp[6].c_response[0]) : '-');
    //   docDefinition = docDefinition.replace("##CLINIC_STREET##", rsp[7] ? rsp[7].c_response[0] : '-');
    // }

    this.populateForm(docDefinition);
  }

  formatPhone(rawNumber) {
    console.log('rawnumber: ', rawNumber);
    const length = rawNumber.length;
    const domestic = rawNumber.substring(0, 10);
    const xt = `xt: ${rawNumber.substring(10, length)}`;

    return `${domestic.substring(0, 3)}-${domestic.substring(
      3,
      6
    )}-${domestic.substring(6, 10)} ${xt}`;
  }

  getMAEForm(formSignature, docDefinition, dataForm) {
    console.log('Populating MAE form', docDefinition);
    docDefinition = docDefinition.replace(
      '##PATIENT_NAME##',
      dataForm.order.c_patient.c_name.c_first_name +
      ' ' +
      dataForm.order.c_patient.c_name.c_last_name
    );
    docDefinition = docDefinition.replace(
      '##PATIENT_MRN##',
      dataForm.order.c_patient.c_mrn
    );
    const date = new Date(dataForm.order.c_order_date);
    docDefinition = docDefinition.replace(
      '##DATE##',
      this.formatMedableDate(dataForm.order.c_order_date)
    );

    const responses = dataForm.orderForm.c_responses;

    // STEP 1
    if (responses[0].c_response[0] === "'0'") {
      docDefinition = docDefinition.replace('##STEP_1_2##', 'noBorders');
      docDefinition = docDefinition.replace('##STEP_1_3##', 'noBorders');
    } else if (responses[0].c_response[0] === "'1'") {
      docDefinition = docDefinition.replace('##STEP_1_1##', 'noBorders');
      docDefinition = docDefinition.replace('##STEP_1_3##', 'noBorders');
    } else {
      docDefinition = docDefinition.replace('##STEP_1_1##', 'noBorders');
      docDefinition = docDefinition.replace('##STEP_1_2##', 'noBorders');
    }

    console.log('Responses:', responses);
    console.log('Replacing steps');
    // STEP 2
    if (responses[1]) {
      const step2_1 =
        !responses[1].c_response || responses[1].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[1].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_2_1##', step2_1);
    } else {
      docDefinition.replace('##STEP_2_1##', '');
    }

    if (responses[2]) {
      const step2_2 =
        !responses[2].c_response || responses[2].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[2].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_2_2##', step2_2);
    } else {
      docDefinition = docDefinition.replace('##STEP_2_2##', '');
    }

    // STEP 3
    if (responses[3]) {
      const step3 =
        !responses[3].c_response || responses[3].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[3].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_3##', step3);
    } else {
      docDefinition = docDefinition.replace(
        '##STEP_3##',
        this.constants.MAE_NONE
      );
    }

    // STEP 4
    if (responses[4]) {
      console.log('Replacing Step 4 yes');
      const step4 =
        !responses[4].c_response || responses[4].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[4].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_4##', step4);
    } else {
      console.log('Replacing step 4 no');
      docDefinition = docDefinition.replace(
        '##STEP_4##',
        this.constants.MAE_NONE
      );
    }

    // STEP 5
    if (responses[5]) {
      const step5 =
        !responses[5].c_response || responses[5].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[5].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_5##', step5);
    } else {
      docDefinition = docDefinition.replace(
        '##STEP_5##',
        this.constants.MAE_NONE
      );
    }

    // STEP 6
    if (responses[6]) {
      const step6 =
        !responses[6].c_response || responses[6].c_response.length === 0
          ? this.constants.MAE_NONE
          : responses[6].c_response[0] === "'0'"
            ? this.constants.MAE_YES
            : this.constants.MAE_NO;
      docDefinition = docDefinition.replace('##STEP_6##', step6);
    } else {
      docDefinition = docDefinition.replace(
        '##STEP_6##',
        this.constants.MAE_NONE
      );
    }

    this.populateForm(docDefinition);
  }

  buildClinicLocation(locationObj: any, alignment = 'center', border = false) {
    console.log('Location: ', locationObj);
    const empty = {
      text: ' ',
    };
    if (!locationObj.c_delivery_address) {
      return empty;
    }
    const addr = locationObj.c_delivery_address;
    const street = addr.c_street_address_1;
    const city = addr.c_city;
    const state = addr.c_state;
    const zip = addr.c_postal_code;

    if (!street && !city && !zip && !state) {
      return empty;
    } else if (street && (city || zip || state)) {
      const dCity = city ? city : ' ';
      const dZip = zip ? zip : ' ';
      const dState = state ? state : ' ';

      const displayAddress = {
        stack: [
          {
            text: street,
            alignment,
          },
          {
            text: [dCity + ', ', dState, dZip],
            alignment,
          },
        ],
      };

      if (!border) {
        displayAddress['border'] = [false, false, false, false];
      }

      return displayAddress;
    } else if (!street && (city || zip || state)) {
      const dCity = city ? city : ' ';
      const dZip = zip ? zip : ' ';
      const dState = state ? state : ' ';

      const displayAddress = {
        text: [dCity, dState, dZip],
        alignment: 'center',
      };

      return displayAddress;
    }
  }

  uderlineQuestion(form) {
    // Form names for which question should be underlined.

    const uNeeded = ['bone stim lmn'];
    const formName = form.c_modifier_form.c_form.c_name;
    let match = false;
    uNeeded.map((words) => {
      const wSplitted = words.split(' ');
      const wCount = wSplitted.length;
      const matches = wSplitted.filter(
        (word) => formName.toLowerCase().indexOf(word.toLowerCase()) > -1
      ).length;

      if (matches >= wCount) {
        match = true;
      }
    });

    return match;
  }

  getProviderNameFromOrder = (order) => {
    const providerObj = order.c_provider_location.c_physician,
      nameObj = providerObj.c_name,
      fName = nameObj.c_first_name,
      lName = nameObj.c_last_name,
      suffix = providerObj.c_suffix,
      fullName = `${fName} ${lName} ${suffix}`;

    return fullName;
  };

  /**
   * Function to get the billing/supervising provider's name.
   * If no billing/supervising provider available, the provider name will be returned.
   */
  getBillingProviderNameFromOrder = (order) => {
    const provider = order.c_provider_location.c_physician,
      billingProvider = order.c_billing_provider,
      providerObj = billingProvider || provider,
      nameObj = providerObj.c_name,
      fName = nameObj.c_first_name,
      lName = nameObj.c_last_name,
      suffix = providerObj.c_suffix,
      fullName = `${fName} ${lName} ${suffix}`;

    return fullName;
  };

  getProviderNPIFromOrder = (order) =>
    order.c_provider_location.c_physician.c_npi;

  /**
   * Function to get the billing/supervising provider's NPI from the order object.
   * If no billing/supervising provider available, the provider's npi will
   *    be returned.
   */
  getBillingProviderNPI = (order) => {
    const provider = order.c_provider_location.c_physician,
      billingProvider = order.c_billing_provider,
      providerObj = billingProvider || provider,
      npi = providerObj.c_npi;

    return npi;
  };

  buildClinicLocationAsString = (location) => {
    const dAddr = location.c_delivery_address;
    if (!dAddr) {
      return '';
    }

    const street = dAddr.c_street_address_1 ? dAddr.c_street_address_1 : '',
      city = dAddr.c_city ? dAddr.c_city : '',
      zip = dAddr.c_postal_code ? dAddr.c_postal_code : '',
      state = dAddr.c_state ? dAddr.c_state : '',
      fullAddr = `${street} ${city} ${zip} ${state}`;
    console.log('*** Full address street: ', fullAddr);
    return fullAddr;
  };

  getClinicPhoneFromLocation = (location) =>
    location.c_phone ? location.c_phone : ' - ';

  optionChecked = (optionIdx, checkedOptions) =>
    checkedOptions.filter((o) => o == optionIdx).length > 0;

  // *** Note, for this form, instead of the provider's details,
  // *** we'll populate the billing providers details (Req by Jessica on Feb 27, 2020)
  getIndianHealthForm(formSignature, docDefinition, dataForm) {
    const imgUrlRequests = [];
    const imgDataRequests = [];
    const imgsProcessed = [];
    const emptyLogoObj = {
      text: '',
      border: [false, false, false, false],
    };

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const order = dataForm.order,
      clinicName = this.getClinicName(dataForm),
      patientName = this.getPatientName(order),
      patientMrn = order.c_patient.c_mrn,
      hcpcs = dataForm.orderLine.c_product.c_hcpcs_code,
      prodName = dataForm.orderLine.c_product.c_name,
      dos = this.formatMedableDate(dataForm.order.c_order_date),
      providerFullName = this.getBillingProviderNameFromOrder(order),
      providerNpi = this.getBillingProviderNPI(order),
      clinicAddress = this.buildClinicLocationAsString(
        order.c_provider_location.c_location
      ),
      clinicPhone = this.getClinicPhoneFromLocation(
        order.c_provider_location.c_location
      ),
      addressAndPhone = `${clinicAddress} , Phone: ${clinicPhone}`;

    console.log('***FORM ORDER***', order);
    console.log('***Clinic address & Phone***', addressAndPhone);
    docDefinition = docDefinition.replace(
      '##CLINIC_NAME##',
      clinicName.replace(/\r?\n|\r/g, ' ')
    );
    docDefinition = docDefinition.replace('##PATIENT_NAME##', patientName);
    docDefinition = docDefinition.replace('##PATIENT_MRN##', patientMrn);
    docDefinition = docDefinition.replace('##HCPCS_CODE##', hcpcs);
    docDefinition = docDefinition.replace('##PRODUCT_DESCRIPTION##', prodName);
    docDefinition = docDefinition.replace('##DATE_OF_SERVICE##', dos);
    docDefinition = docDefinition.replace('##DATE_OF_SERVICE##', dos);
    docDefinition = docDefinition.replace(
      '##PROVIDER_NAME_AND_SUFFIX##',
      providerFullName
    );
    docDefinition = docDefinition.replace('##Provider NPI##', providerNpi);
    docDefinition = docDefinition.replace(
      '##CLINIC_ADDsiRESS_AND_PHONE##',
      addressAndPhone
    );

    if (dataForm.orderLine.c_icd_10.length) {
      docDefinition = docDefinition.replace(
        '##ICD_10##',
        dataForm.orderLine.c_icd_10[0].c_icd_10_code
      );
      docDefinition = docDefinition.replace(
        '##ICD_10_DEFINITION##',
        dataForm.orderLine.c_icd_10[0].c_description
      );
    } else {
      docDefinition = docDefinition.replace('##ICD_10##', '');
      docDefinition = docDefinition.replace('##ICD_10_DEFINITION##', '');
    }

    // For this form, check for billingProvider signature instead of physician signature
    const billingProvider = dataForm.order.c_billing_provider || {}; // To prevent throwing error in case of billing provider missing
    const hasSignature = billingProvider.c_signature;
    const hasLogo = dataForm.order.c_provider_location.c_location.c_clinic_logo;
    const docDefJson = JSON.parse(docDefinition);
    const responsesFormatted = dataForm.orderForm.c_responses[0].c_response.map(
      (r) => parseInt(r.replace('"', ''))
    );

    docDefJson.content[8].stack.map((resOpt, idx) => {
      const imgObj = {
        border: [false, false, false, false],
        margin: [0, 0, 10, 0],
        image: this.optionChecked(idx, responsesFormatted)
          ? this.constants.IMG_CHECKED
          : this.constants.IMG_NOT_CHECKED,
        fit: [11, 11],
      };

      resOpt.table.body[0][0] = imgObj;
    });

    docDefinition = JSON.stringify(docDefJson);

    // No logo + No Signature
    if (!hasSignature && !hasLogo) {
      docDefinition = docDefinition.replace('##PHISICIAN_SIGNATURE##', ' ');
      docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
      this.populateForm(docDefinition);
    } else {
      // Has Signature
      if (billingProvider.c_signature) {
        this.hasSignature = true;
        const signatureUrl = this.providerService.getImageContent(
          billingProvider._id
        );
        imgUrlRequests.push(signatureUrl);
      }
      // Has logo
      if (dataForm.order.c_provider_location.c_location.c_clinic_logo) {
        docDefinition = docDefinition.replace('##PHISICIAN_SIGNATURE##', ' ');
        this.hasLogo = true;
        const logoUrl = this.locationService.getImageContent(
          dataForm.order.c_provider_location.c_location._id
        );
        imgUrlRequests.push(logoUrl);
      }
      // When all requests to get image url load
      forkJoin(imgUrlRequests).subscribe((responses) => {
        // Create array witht the list of requests to get images
        imgUrlRequests.map((imgData, index) => {
          const imgReq = this.http.get<any>(responses[index]['url'], httpAux);
          imgDataRequests.push(imgReq);
        });
        // When all images are loaded
        forkJoin(imgDataRequests).subscribe((imgs) => {
          if (!imgs.length) {
            docDefinition = docDefinition.replace(
              '##PHISICIAN_SIGNATURE##',
              ' '
            );
            docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
            this.populateForm(docDefinition);
          } else {
            // console.log("Imgs: ", imgs);
            imgs.map((imgObj, index) => {
              let imgType = '';
              if (imgs.length == 2) {
                if (index == 0) {
                  imgType = 'signature2';
                } else {
                  imgType = 'logo';
                }
              } else {
                if (this.hasSignature) {
                  imgType = 'signature2';
                } else {
                  imgType = 'logo';
                }
              }

              // const imgType = index === 0 && this.hasSignature ? 'signature' : 'logo';
              // console.log("ImgObj: ", imgObj);
              const processedImg = this.processfileImg(imgObj, imgType);
              imgsProcessed.push(processedImg);
            });

            if (!imgsProcessed.length) {
              docDefinition = docDefinition.replace(
                '##PHISICIAN_SIGNATURE##',
                ' '
              );
              docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
              this.populateForm(docDefinition);
            } else {
              forkJoin(imgsProcessed).subscribe((imgPrc) => {
                if (imgsProcessed.length == 2) {
                  console.log('Has both');
                  if (imgPrc[0]) {
                    docDefinition = docDefinition.replace(
                      '"##PHISICIAN_SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                  }
                  if (imgPrc[1]) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[1])
                    );
                  }
                } else {
                  console.log('Has one');
                  if (imgPrc[0] && this.hasLogo) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      '""'
                    );
                  } else if (imgPrc[0] && this.hasSignature) {
                    docDefinition = docDefinition.replace(
                      '"##PHISICIAN_SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(emptyLogoObj)
                    );
                  }
                }
                this.populateForm(docDefinition);
              });
            }
          }
        });
      });
    }
    // End Logo & Signature section
  }

  getClinicDocumentationForm(formSignature, docDefinition, dataForm) {
    const order = dataForm.order,
      orderForm = dataForm.orderForm,
      orderLine = dataForm.orderLine,
      patientName = this.getPatientName(order),
      patientMrn = order.c_patient.c_mrn,
      dos = this.formatMedableDate(dataForm.order.c_order_date),
      providerFullName = this.getBillingProviderNameFromOrder(order);
    docDefinition = docDefinition.replace('##PATIENT_NAME##', patientName);
    docDefinition = docDefinition.replace('##DIAGNOSIS##', `${orderLine.c_icd_10[0] ? orderLine.c_icd_10[0].c_icd_10_code : ''}${orderLine.c_icd_10[1] ? (', ' + orderLine.c_icd_10[1].c_icd_10_code) : ''}`)
    docDefinition = docDefinition.replace('##MRN##', patientMrn);
    docDefinition = docDefinition.replace('##DATE_DELIVERED##', dos);
    docDefinition = docDefinition.replace(
      '##PHYSICIAN##',
      providerFullName
    );
    
    const productOption = orderLine.c_product.cpy_product_option,
        productName = orderLine.c_product.c_name,
        hcpcs = orderLine.c_product.c_hcpcs_code,
        sku = productOption.c_code,
        size = productOption.c_size
    docDefinition = docDefinition.replace('##PRODUCT_ISSUED##',  `\\n${productName}\\n ${size} | ${sku}\\n ${hcpcs}`);
    docDefinition = docDefinition.replace('##OTHER_GOALS##', '');
    docDefinition = docDefinition.replace('##OTHER_MEDICAL_JUSTIFICATIONS##', '');
    docDefinition = docDefinition.replace('##OTHER_MODIFICATIONS_ADJUSTMENTS##', '');
  
    for (let i = 0; i < 15; i++) {
      if (orderForm.c_responses[0].c_response.map(Number).indexOf(i) !== -1) {
        docDefinition = docDefinition.replace(
          `##GOAL_OPTION_${i}##`,
          'checked'
        )
      } else {
        docDefinition = docDefinition.replace(
          `##GOAL_OPTION_${i}##`,
          'not_checked'
        )
      }
    }

    for (let i = 0; i < 15; i++) {
      if (orderForm.c_responses[1].c_response.map(Number).indexOf(i) !== -1) {
        docDefinition = docDefinition.replace(
          `##MEDICAL_JUSTIFICATION_OPTION_${i}##`,
          'checked'
        )
      } else {
        docDefinition = docDefinition.replace(
          `##MEDICAL_JUSTIFICATION_OPTION_${i}##`,
          'not_checked'
        )
      }
    }

    for (let i = 0; i < 15; i++) {
      if (orderForm.c_responses[2].c_response.map(Number).indexOf(i) !== -1) {
        docDefinition = docDefinition.replace(
          `##MODIFICATION_OPTION_${i}##`,
          'checked'
        )
      } else {
        docDefinition = docDefinition.replace(
          `##MODIFICATION_OPTION_${i}##`,
          'not_checked'
        )
      }
    }

    for (let i = 0; i < 15; i++) {
      if (parseInt(orderForm.c_responses[3].c_response[0].replace("'", '')) === i) {
        docDefinition = docDefinition.replace(
          `##DISPENSED_OPTION_${i}##`,
          'checked'
        )
      } else {
        docDefinition = docDefinition.replace(
          `##DISPENSED_OPTION_${i}##`,
          'not_checked'
        )
      }
    }

    if (orderForm.c_responses[4].c_response[0] === "'1'") {
      docDefinition = docDefinition.replace(
        `##LOWER_YES_OPTION##`,
        'checked'
      );
      docDefinition = docDefinition.replace(
        `##LOWER_NO_OPTION##`,
        'not_checked'
      );
    }

    if (orderForm.c_responses[4].c_response[0] === "'0'") {
      docDefinition = docDefinition.replace(
        `##LOWER_NO_OPTION##`,
        'checked'
      );
      docDefinition = docDefinition.replace(
        `##LOWER_YES_OPTION##`,
        'not_checked'
      );
    }

    docDefinition = docDefinition.replace(
      `##REASON_NO_SUPPORT##`,
      orderForm.c_responses[5].c_response[0].replace(/\r?\n|\r/g, ' ')
    )

    docDefinition = docDefinition.replace(
      `##ADDITIONAL_COMMENTS##`,
      orderForm.c_responses[6].c_response[0].replace(/\r?\n|\r/g, ' ')
    );

    const fitter = this.allFitters.find((fitter) => fitter.id === order.c_designated_fitter._id) ?? { firstName: "", lastName: "" };

    docDefinition = docDefinition.replace(
      `##FITTER_NAME##`,
      `${fitter.firstName} ${fitter.lastName}`
    );

    docDefinition = docDefinition.replace(
      `##FITTER_SIGNATURE##`,
      ``
    );

    const hasLogo = order.c_provider_location.c_location.c_clinic_logo;

    if (!hasLogo) {
      docDefinition = docDefinition.replace('##CLINIC_LOGO##', '');

      this.populateForm(docDefinition);
      return;
    }

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const logoUrlPromise = this.locationService.getImageContent(
      dataForm.order.c_provider_location.c_location._id
    );
    logoUrlPromise.subscribe((logoUrl) => {
      const imgReq = this.http.get<any>(logoUrl.url, httpAux);
      const doc = JSON.parse(docDefinition);
      imgReq.subscribe((img) => {
        const processedImgReq = this.processfileImg(img, 'logo', [
          false,
          false,
          false,
          false,
        ]);
        processedImgReq.subscribe((processedImg: Object) => {
          doc.content[0].columns[0] = { ...processedImg, "width": 80 };
          docDefinition = JSON.stringify(doc);
          this.populateForm(docDefinition);
          return;
        });
      });
    });
  }

  getLMNForm(formSignature, docDefinition, dataForm) {
    // console.log("Initial Docdef: ", docDefinition);
    const imgUrlRequests = [];
    const imgDataRequests = [];
    const imgsProcessed = [];

    const diagnosisObj = {
      text: [
        'Diagnosis: ',
        '##ICD_10_1## ',
        '##ICD_10_2## ',
        '##ICD_10_3## ',
        '##ICD_10_4## ',
      ],
    };

    const dob = new Date(dataForm.order.c_patient.c_dob);
    const date = new Date(dataForm.order.c_order_date);
    let provider = dataForm.order.c_provider_location.c_physician.c_preffix
      ? dataForm.order.c_provider_location.c_physician.c_preffix + '. '
      : '';
    provider +=
      dataForm.order.c_provider_location.c_physician.c_name.c_first_name +
      ' ' +
      dataForm.order.c_provider_location.c_physician.c_name.c_last_name;
    provider += dataForm.order.c_provider_location.c_physician.c_suffix
      ? ',' + dataForm.order.c_provider_location.c_physician.c_suffix
      : '';
    const clinicLocation = this.buildClinicLocation(
      dataForm.order.c_provider_location.c_location
    );
    const primaryInsurance = dataForm.order.c_insurance.c_insurance_name;
    const primaryInsuranceId = dataForm.order.c_primary_insurance_id_number
      ? dataForm.order.c_primary_insurance_id_number
      : '-';
    const clinicName = this.getClinicName(dataForm);

    // if (dataForm.hcpcsList && dataForm.hcpcsList.length) {
    //   hcpcsList.map( hcpcObj => {

    //   });
    // }

    const hcpcsContent = this.generateHcpcsContent(dataForm);

    docDefinition = JSON.parse(docDefinition);
    docDefinition.content[3].stack = docDefinition.content[3].stack.splice(
      0,
      8
    );
    docDefinition.content[3].stack[7] = diagnosisObj;
    if (hcpcsContent.length) {
      docDefinition.content[4].stack[1] = {
        stack: hcpcsContent,
      };
    }
    // console.log("LMNContent: ", JSON.parse(docDefinition));
    // if (formSignature) {
    //   this.addPSignaturetoDocDef(docDefinition, formSignature);
    // }
    docDefinition = JSON.stringify(docDefinition);
    docDefinition = docDefinition.replace(
      '"##CLINIC_ADDRESS##"',
      JSON.stringify(clinicLocation)
    );
    docDefinition = docDefinition.replace(
      '##PRIMARY_INSURANCE##',
      primaryInsurance
    );
    docDefinition = docDefinition.replace(
      '##PRIMARY_INSURANCE_ID##',
      primaryInsuranceId
    );
    docDefinition = docDefinition.replace(
      new RegExp('##PATIENT_NAME##', 'g'),
      dataForm.order.c_patient.c_name.c_first_name +
      ' ' +
      dataForm.order.c_patient.c_name.c_last_name
    );
    docDefinition = docDefinition.replace(
      '##PATIENT_MRN##',
      dataForm.order.c_patient.c_mrn
    );
    docDefinition = docDefinition.replace(
      '##PATIENT_DOB##',
      dob.getUTCMonth() + 1 + '/' + dob.getUTCDate() + '/' + dob.getUTCFullYear()
    );
    docDefinition = docDefinition.replace('##PROVIDER_NAME##', provider);
    docDefinition = docDefinition.replace(
      '##NPI##',
      dataForm.order.c_provider_location.c_physician.c_npi
    );
    docDefinition = docDefinition.replace(
      new RegExp('##HCPSC_CODE##', 'g'),
      dataForm.orderLine.c_selected_hcpcs ||
      dataForm.orderLine.c_product.c_hcpcs_code
    );
    // docDefinition = docDefinition.replace(new RegExp("##HCPSC_CODE##", 'g'), '');
    // docDefinition = docDefinition.replace("##HCPSC_CODE##", `- ${dataForm.orderLine.c_product.c_name.replace('"', '')} - ${dataForm.orderLine.c_product.cpy_product_option.c_size.replace('"', '')}`);

    docDefinition = docDefinition.replace(
      '##HCPSC_DESCRIPTION##',
      `- ${dataForm.orderLine.c_product.c_name.replace(
        '"',
        ''
      )} - ${dataForm.orderLine.c_product.cpy_product_option.c_size.replace(
        '"',
        ''
      )}`
    );
    // docDefinition = docDefinition.replace("##HCPSC_DESCRIPTION##", '- ');
    // docDefinition = docDefinition.replace("##ICD_10_1##", (dataForm.orderLine.c_icd_10[0]) ? dataForm.orderLine.c_icd_10[0].c_icd_10_code : '');
    // docDefinition = docDefinition.replace("##ICD_10_2##", (dataForm.orderLine.c_icd_10[1]) ? dataForm.orderLine.c_icd_10[1].c_icd_10_code : '');
    // docDefinition = docDefinition.replace("##ICD_10_3##", (dataForm.orderLine.c_icd_10[2]) ? dataForm.orderLine.c_icd_10[2].c_icd_10_code : '');
    // docDefinition = docDefinition.replace("##ICD_10_4##", (dataForm.orderLine.c_icd_10[3]) ? dataForm.orderLine.c_icd_10[3].c_icd_10_code : '');
    for (let i = 0; i < 4; i++) {
      const identifier = `##ICD_10_${i + 1}##`;
      let replacement = '';
      if (dataForm.orderLine.c_icd_10[i]) {
        if (dataForm.orderLine.c_icd_10[i + 1]) {
          replacement = `${dataForm.orderLine.c_icd_10[i].c_icd_10_code} |`;
        } else {
          replacement = `${dataForm.orderLine.c_icd_10[i].c_icd_10_code}`;
        }
      }

      docDefinition = docDefinition.replace(identifier, replacement);
    }
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_1##',
      dataForm.orderLine.c_product.c_related_hcpcs &&
        dataForm.orderLine.c_product.c_related_hcpcs[0]
        ? dataForm.orderLine.c_product.c_related_hcpcs[0]
        : ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_2##',
      dataForm.orderLine.c_product.c_related_hcpcs &&
        dataForm.orderLine.c_product.c_related_hcpcs[1]
        ? dataForm.orderLine.c_product.c_related_hcpcs[1]
        : ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_3##',
      dataForm.orderLine.c_product.c_related_hcpcs &&
        dataForm.orderLine.c_product.c_related_hcpcs[2]
        ? dataForm.orderLine.c_product.c_related_hcpcs[2]
        : ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_4##',
      dataForm.orderLine.c_product.c_related_hcpcs &&
        dataForm.orderLine.c_product.c_related_hcpcs[3]
        ? dataForm.orderLine.c_product.c_related_hcpcs[3]
        : ''
    );
    docDefinition = docDefinition.replace(
      '##CLINIC_NAME##',
      clinicName.replace(/\r?\n|\r/g, ' ')
    );
    docDefinition = docDefinition.replace(
      '##ORDER_DATE##',
      this.formatMedableDate(dataForm.order.c_order_date)
    );

    if (dataForm.orderForm.c_modifier_form.c_form.c_name.indexOf('DVT') == -1) {
      this.populateClassicLmnForm(docDefinition, dataForm);
      return;
    } else {
      this.populateDvtLmnForm(docDefinition, dataForm);
      return;
    }
  }

  getClinicName(dataForm) {
    const {
      c_last_name,
      c_company,
    } = dataForm.order.c_provider_location.c_location;

    return (
      (dataForm.warrantyForm &&
        this.extractClinicName(dataForm.warrantyForm)) ||
      c_last_name ||
      c_company ||
      ''
    );
  }

  /**
   * Helper - Notice Form.
   * Function to get the list of all the additional hcpcs codes from the
   * order and their product names & sizes
   */
  getAdditionalHchcpsNotice = (dataForm) => {
    let orderLine = dataForm.orderLine,
      filteredLines = dataForm.lineItems.filter(
        (line) => line._id != orderLine._id
      );

    // Map list of filtered lines into form: "${HCPCS_CODE} - ${PROD_NAME} - ${PROD_SIZE}"
    let mapped = filteredLines.map((line) => {
      let prod = line.c_product,
        code = prod.c_hcpcs_code,
        name = prod.c_name.replace('"', ''),
        size = ((prod.cpy_product_option || {}).c_size || '').replace('"', ''),
        string = `${code} - ${name} - ${size}`;

      return string;
    });

    return mapped;
  };

  getNoticeForm(formSignature, docDefinition, dataForm) {
    console.log('###Populating notice form...', dataForm);

    let item = dataForm.orderLine,
      product = dataForm.orderLine.c_product,
      hcpcs = item.c_selected_hcpcs || product.c_hcpcs_code;

    const clinicName = this.getClinicName(dataForm);

    console.log('dataForm: ', dataForm);
    const date = new Date(dataForm.order.c_order_date);
    const patientName = `${dataForm.order.c_patient.c_name.c_first_name} ${dataForm.order.c_patient.c_name.c_last_name}`;
    const patientMrn = dataForm.order.c_patient.c_mrn;
    console.log('patientName: ', patientName);
    console.log('dataform: ', dataForm);
    const formattedDate = this.formatMedableDate(dataForm.order.c_order_date);
    const clinicLocation = this.buildClinicLocation(
      dataForm.order.c_provider_location.c_location
    );

    docDefinition = docDefinition.replace('##ORDER_DATE##', formattedDate);
    docDefinition = docDefinition.replace('##PATIENT_NAME##', patientName);
    docDefinition = docDefinition.replace('##PATIENT_MRN##', patientMrn);
    docDefinition = docDefinition.replace('##CLINIC_NAME##', clinicName);
    docDefinition = docDefinition.replace(
      new RegExp('##HCPSC_CODE##', 'g'),
      hcpcs
    );
    docDefinition = docDefinition.replace(
      '##HCPSC_DESCRIPTION##',
      `- ${dataForm.orderLine.c_product.c_name.replace(
        '"',
        ''
      )} - ${dataForm.orderLine.c_product.cpy_product_option.c_size.replace(
        '"',
        ''
      )}`
    );
    docDefinition = docDefinition.replace(
      '"##CLINIC_ADDRESS##"',
      JSON.stringify(clinicLocation)
    );

    // Replace HCPCS_ADD_X placeholders with the other hcpcs codes from the order
    let otherCodes = this.getAdditionalHchcpsNotice(dataForm);
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_1##',
      otherCodes[0] || ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_2##',
      otherCodes[1] || ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_3##',
      otherCodes[2] || ''
    );
    docDefinition = docDefinition.replace(
      '##HCPCS_ADD_4##',
      otherCodes[3] || ''
    );
    // docDefinition = docDefinition.replace("##HCPCS_ADD_1##", (dataForm.orderLine.c_product.c_related_hcpcs && dataForm.orderLine.c_product.c_related_hcpcs[0]) ? dataForm.orderLine.c_product.c_related_hcpcs[0] : '');
    // docDefinition = docDefinition.replace("##HCPCS_ADD_2##", (dataForm.orderLine.c_product.c_related_hcpcs && dataForm.orderLine.c_product.c_related_hcpcs[1]) ? dataForm.orderLine.c_product.c_related_hcpcs[1] : '');
    // docDefinition = docDefinition.replace("##HCPCS_ADD_3##", (dataForm.orderLine.c_product.c_related_hcpcs && dataForm.orderLine.c_product.c_related_hcpcs[2]) ? dataForm.orderLine.c_product.c_related_hcpcs[2] : '');
    // docDefinition = docDefinition.replace("##HCPCS_ADD_4##", (dataForm.orderLine.c_product.c_related_hcpcs && dataForm.orderLine.c_product.c_related_hcpcs[3]) ? dataForm.orderLine.c_product.c_related_hcpcs[3] : '');
    if (!formSignature) {
      docDefinition = docDefinition.replace('##PATIENT_SIGNATURE##', '');
    }
    const hcpcsContent = this.generateHcpcsContent(dataForm);
    const docdefObj = JSON.parse(docDefinition);
    console.log('DocDefObj: ', docdefObj);
    //console.log("hcpcs content: ", hcpcsContent)
    const responses = dataForm.orderForm.c_responses;
    //console.log("Form responses: ", responses)
    // op1: (dataForm.orderForm.c_responses[2].c_response[0] == "'0'") ? this.constants.IMG_CHECKED : this.constants.IMG_NOT_CHECKED,
    if (responses.length && responses[0]) {
      const respIdx = parseInt(responses[0].c_response[0].replace(`'`, ''));
      if (respIdx == 0) {
        docdefObj.content[10].table.body[0][0].text = 'X';
        docdefObj.content[10].table.body[0][2].text = '';
      } else if (respIdx == 1) {
        docdefObj.content[10].table.body[0][0].text = '';
        docdefObj.content[10].table.body[0][2].text = 'X';
      } else {
        docdefObj.content[10].table.body[0][0].text = '';
        docdefObj.content[10].table.body[0][2].text = '';
      }
      //console.log("First Question Resp: ", respIdx)
    } else {
      docdefObj.content[10].table.body[0][0].text = '';
      docdefObj.content[10].table.body[0][2].text = '';
    }

    if (responses.length && responses[1]) {
      const responseIndexes = responses[1].c_response.map((rsp) =>
        parseInt(rsp.replace(`'`, ''))
      ),
        responseTexts = responseIndexes.map((rIdx) => {
          const responseText = responses[1].c_question.c_response_options[rIdx];
          return {
            italics: true,
            text: responseText,
          };
        });

      docdefObj.content[12].stack = responseTexts;
    } else {
      docdefObj.content[12].stack = [];
    }

    const hcpcsStack = [];
    let currentContainer = docdefObj.content[8].stack;
    if (hcpcsContent.length) {
      hcpcsStack.push({ ...currentContainer[0] });
      currentContainer = hcpcsStack;
    } else {
      hcpcsStack.push({ ...currentContainer[0] });
      hcpcsStack.push({ ...currentContainer[1] });
      docdefObj.content[8].stack = hcpcsStack;
    }

    if (formSignature) {
      console.log('Form has signature...', formSignature);
      //docDefinition = docDefinition.replace('"##PATIENT_SIGNATURE##"', JSON.stringify({image: formSignature, fit:[100, 30]}));
      docdefObj.content[14].table.body[0][1] = {
        image: formSignature,
        fit: [100, 30],
        border: [false, false, false, true],
      };
      docDefinition = JSON.stringify(docdefObj);
    } else {
      // console.log("Form has no signature...", formSignature)
      docDefinition = docDefinition.replace('##PATIENT_SIGNATURE##', '');
    }

    // docDefinition = docDefinition.replace('##PATIENT_SIGNATURE##', '');

    const hasLogo = dataForm.order.c_provider_location.c_location.c_clinic_logo;
    console.log('hasLogo: ', hasLogo);
    if (!hasLogo) {
      docDefinition = docDefinition.replace('##CLINIC_LOGO##', '');

      this.populateForm(docDefinition);
      return;
    }

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const logoUrlPromise = this.locationService.getImageContent(
      dataForm.order.c_provider_location.c_location._id
    );
    logoUrlPromise.subscribe((logoUrl) => {
      console.log('logoUrl: ', logoUrl);
      const imgReq = this.http.get<any>(logoUrl.url, httpAux);
      imgReq.subscribe((img) => {
        console.log('imgReq: ', img);
        const processedImgReq = this.processfileImg(img, 'logo');
        processedImgReq.subscribe((processedImg) => {
          console.log('Processed img: ', processedImg);
          docdefObj.content[0].table.body[0][0] = processedImg;
          docDefinition = JSON.stringify(docdefObj);

          this.populateForm(docDefinition);
          return;
        });
      });
    });
  }

  addPSignaturetoDocDef(docDefinition, formSignature) {
    const signObject = {
      table: {
        widths: [100],
        heights: [20, 30],
        body: [
          [
            {
              text: 'Patient Signature:',
              border: [false, false, false, false],
            },
          ],
          [
            {
              image: formSignature,
              fit: [100, 30],
              border: [false, false, false, false],
            },
          ],
        ],
      },
    };

    docDefinition.content.push(signObject);
    //console.log("Adding Signature");
    // docDefinition.content.push(
    //   {
    //     image: formSignature,
    //     fit: [30, 200]
    //   }
    // );
  }

  generateHcpcsContent(dataForm) {
    const hcpcsContainer = [];
    console.log('DataForm: ', dataForm);
    dataForm.hcpcsList
      .filter((hcObj) => hcObj.c_hcpcs_code)
      .map((hcpcsObj) => {
        let code = hcpcsObj.c_hcpcs_code.c_hcpcs_code;
        const codeProduct = dataForm.lineItems
          .filter((line) => line.c_product.c_hcpcs_code == code)
          .map((line) => {
            // Check if code is multiple
            if (code.indexOf('/') > -1) {
              // Set select hcpcs code as code if present
              if ((dataForm.orderLine || {}).c_selected_hcpcs) {
                code = dataForm.orderLine.c_selected_hcpcs;
              }
            }
            const productName = line.c_product.c_name;
            const productSize = line.c_product.cpy_product_option.c_size;
            const temp = {
              text: `${code} - ${productName} - ${productSize}`,
            };

            hcpcsContainer.push(temp);
          });
      });

    console.log('Str: ', JSON.stringify(hcpcsContainer));
    return hcpcsContainer;
  }

  returnDvtQuestionStrucrure(responses, i) {
    const question = {
      margin: [0, 25, 0, 0],
      unbreakable: 'true',
      stack: [
        {
          table: {
            widths: [200],
            body: [
              [
                {
                  text: responses[i].c_question.c_text,
                  color: '#ffffff',
                },
              ],
            ],
          },
          layout: {
            fillColor: '#4286f4',
          },
        },
        {
          table: {
            widths: [200],
            body: [
              [
                {
                  ul: [],
                },
              ],
            ],
          },
        },
      ],
    };

    return question;
  }

  populateDvtLmnForm(docDefinition, dataForm) {
    console.log('Populating DVT');
    const emptyLogoObj = {
      text: '',
      border: [false, false, false, false],
    };
    const imgUrlRequests = [];
    const imgDataRequests = [];
    const imgsProcessed = [];
    const responses = dataForm.orderForm.c_responses;
    let questions = '';
    let dvtPointsTotal = 0;

    const dvtQuestions = {
      columns: [
        {
          stack: [],
        },
        {
          stack: [],
        },
      ],
    };

    for (let i = 0; i < responses.length; i++) {
      const question = this.returnDvtQuestionStrucrure(responses, i);
      // in case it is the DTV LMN form, it the answers generate points
      let dvtPointsPartial = 0;
      let sumPoints = 0;

      if (
        dataForm.orderForm.c_inventory_form == undefined &&
        dataForm.orderForm.c_modifier_form.c_form.c_name.indexOf('DVT') !== -1
      ) {
        if (responses[i].c_question.c_text.indexOf('1') !== -1) {
          sumPoints = 1;
        } else if (responses[i].c_question.c_text.indexOf('2') !== -1) {
          sumPoints = 2;
        } else if (responses[i].c_question.c_text.indexOf('3') !== -1) {
          sumPoints = 3;
        } else if (responses[i].c_question.c_text.indexOf('5') !== -1) {
          sumPoints = 5;
        }
      }

      if (responses[i].c_question.c_response_options.length > 0) {
        for (let j = 0; j < responses[i].c_response.length; j++) {
          const selectedIdx = parseInt(
            responses[i].c_response[j].replace("'", '')
          );
          const qDoc = {
            text: responses[i].c_question.c_response_options[selectedIdx],
          };
          question['stack'][1].table.body[0][0]['ul'].push(qDoc);
          if (sumPoints > 0) {
            dvtPointsPartial += sumPoints;
          }
        }
        if (sumPoints > 0) {
          dvtPointsTotal += dvtPointsPartial;

          question['stack'][1].table.body[0][0]['ul'].push({
            margin: [0, 15, 0, 0],
            text: 'Total points: ' + dvtPointsPartial,
            bold: true,
          });
        }
      } else {
        question['stack'][1].table.body[0][0]['ul'].push({
          text: responses[i].c_response[0],
        });
      }

      if (i % 2 == 0) {
        dvtQuestions.columns[0].stack.push(question);
      } else {
        dvtQuestions.columns[1].stack.push(question);
      }
    }

    const score = {
      text: 'Total Risk Factor Score: ' + dvtPointsTotal,
      margin: [0, 20, 50, 0],
      alignment: 'right',
    };
    dvtQuestions.columns[1].stack.push(score);

    console.log('Questions: ', questions);
    if (responses.length) {
      docDefinition = docDefinition.replace(
        '"##QUESTIONS##"',
        JSON.stringify(dvtQuestions)
      );
    } else {
      docDefinition = docDefinition.replace(
        '"##QUESTIONS##",',
        JSON.stringify(dvtQuestions)
      );
    }

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const hasSignature =
      dataForm.order.c_provider_location.c_physician.c_signature;
    const hasLogo = dataForm.order.c_provider_location.c_location.c_clinic_logo;

    // No logo + No Signature
    if (!hasSignature && !hasLogo) {
      docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
      docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
      this.populateForm(docDefinition);
    } else {
      // Has Signature
      if (dataForm.order.c_provider_location.c_physician.c_signature) {
        this.hasSignature = true;
        const signatureUrl = this.providerService.getImageContent(
          dataForm.order.c_provider_location.c_physician._id
        );
        imgUrlRequests.push(signatureUrl);
      }
      // Has logo
      if (dataForm.order.c_provider_location.c_location.c_clinic_logo) {
        this.hasLogo = true;
        const logoUrl = this.locationService.getImageContent(
          dataForm.order.c_provider_location.c_location._id
        );
        imgUrlRequests.push(logoUrl);
      }
      // When all requests to get image url load
      forkJoin(imgUrlRequests).subscribe((responses) => {
        // Create array witht the list of requests to get images
        imgUrlRequests.map((imgData, index) => {
          const imgReq = this.http.get<any>(responses[index]['url'], httpAux);
          imgDataRequests.push(imgReq);
        });
        // When all images are loaded
        forkJoin(imgDataRequests).subscribe((imgs) => {
          if (!imgs.length) {
            docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
            docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
            this.populateForm(docDefinition);
          } else {
            // console.log("Imgs: ", imgs);
            imgs.map((imgObj, index) => {
              let imgType = '';
              if (imgs.length == 2) {
                if (index == 0) {
                  imgType = 'signature';
                } else {
                  imgType = 'logo';
                }
              } else {
                if (this.hasSignature) {
                  imgType = 'signature';
                } else {
                  imgType = 'logo';
                }
              }

              // const imgType = index === 0 && this.hasSignature ? 'signature' : 'logo';
              // console.log("ImgObj: ", imgObj);
              const processedImg = this.processfileImg(imgObj, imgType);
              imgsProcessed.push(processedImg);
            });

            if (!imgsProcessed.length) {
              docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
              docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
              this.populateForm(docDefinition);
            } else {
              forkJoin(imgsProcessed).subscribe((imgPrc) => {
                if (imgsProcessed.length == 2) {
                  console.log('Has both');
                  if (imgPrc[0]) {
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                  }
                  if (imgPrc[1]) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[1])
                    );
                  }
                } else {
                  console.log('Has one');
                  if (imgPrc[0] && this.hasLogo) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      '""'
                    );
                  } else if (imgPrc[0] && this.hasSignature) {
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(emptyLogoObj)
                    );
                  }
                }
                this.populateForm(docDefinition);
              });
            }
          }
        });
      });
    }
  }

  populateClassicLmnForm(docDefinition, dataForm) {
    const emptyLogoObj = {
      text: '',
      border: [false, false, false, false],
    };
    console.log('Populate Classic LMN');
    const imgUrlRequests = [];
    const imgDataRequests = [];
    const imgsProcessed = [];
    let questions = '';
    const responses = dataForm.orderForm.c_responses;
    for (let i = 0; i < responses.length; i++) {
      const question = {
        stack: [
          {},
          {
            ul: [],
            style: 'margins',
          },
        ],
        style: 'margins',
      };

      let questionText = {};

      if (this.uderlineQuestion(dataForm.orderForm)) {
        questionText = {
          text: responses[i].c_question.c_text,
          style: 'margins',
          decoration: 'underline',
        };
      } else {
        questionText = {
          text: responses[i].c_question.c_text,
          style: 'margins',
        };
      }

      question.stack[0] = questionText;

      if (responses[i].c_question.c_response_options.length > 0) {
        for (let j = 0; j < responses[i].c_response.length; j++) {
          const selectedIdx = parseInt(
            responses[i].c_response[j].replace("'", '')
          );
          const qDoc = {
            text: responses[i].c_question.c_response_options[selectedIdx],
          };
          question.stack[1]['ul'].push(qDoc);
        }
      } else {
        question.stack[1]['ul'].push({ text: responses[i].c_response[0] });
      }

      questions += questions === '' ? '' : ',';
      questions += JSON.stringify(question);
    }

    if (responses.length) {
      docDefinition = docDefinition.replace('"##QUESTIONS##"', questions);
    } else {
      docDefinition = docDefinition.replace('"##QUESTIONS##",', questions);
    }

    let httpAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'image/png',
      }),
      withCredentials: false,
      responseType: 'blob' as 'json',
    };

    const hasSignature =
      dataForm.order.c_provider_location.c_physician.c_signature;
    const hasLogo = dataForm.order.c_provider_location.c_location.c_clinic_logo;
    // No logo + No Signature
    if (!hasSignature && !hasLogo) {
      docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
      docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
      this.populateForm(docDefinition);
    } else {
      // Has Signature
      if (dataForm.order.c_provider_location.c_physician.c_signature) {
        this.hasSignature = true;
        const signatureUrl = this.providerService.getImageContent(
          dataForm.order.c_provider_location.c_physician._id
        );
        imgUrlRequests.push(signatureUrl);
      }
      // Has logo
      if (dataForm.order.c_provider_location.c_location.c_clinic_logo) {
        this.hasLogo = true;
        const logoUrl = this.locationService.getImageContent(
          dataForm.order.c_provider_location.c_location._id
        );
        imgUrlRequests.push(logoUrl);
      }
      // When all requests to get image url load
      forkJoin(imgUrlRequests).subscribe((responses) => {
        // Create array witht the list of requests to get images
        imgUrlRequests.map((imgData, index) => {
          const imgReq = this.http.get<any>(responses[index]['url'], httpAux);
          imgDataRequests.push(imgReq);
        });
        // When all images are loaded
        forkJoin(imgDataRequests).subscribe((imgs) => {
          if (!imgs.length) {
            docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
            docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
            this.populateForm(docDefinition);
          } else {
            imgs.map((imgObj, index) => {
              let imgType = '';
              if (imgs.length == 2) {
                if (index == 0) {
                  imgType = 'signature';
                } else {
                  imgType = 'logo';
                }
              } else {
                if (this.hasSignature) {
                  imgType = 'signature';
                } else {
                  imgType = 'logo';
                }
              }

              const processedImg = this.processfileImg(imgObj, imgType);
              imgsProcessed.push(processedImg);
            });

            if (!imgsProcessed.length) {
              docDefinition = docDefinition.replace('"##SIGNATURE##"', '""');
              docDefinition = docDefinition.replace('"##CLINIC_LOGO##"', '""');
              this.populateForm(docDefinition);
            } else {
              forkJoin(imgsProcessed).subscribe((imgPrc) => {
                if (imgsProcessed.length == 2) {
                  console.log('Has both');
                  if (imgPrc[0]) {
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                  }
                  if (imgPrc[1]) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[1])
                    );
                  }
                } else {
                  console.log('Has one');
                  if (imgPrc[0] && this.hasLogo) {
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      '""'
                    );
                  } else if (imgPrc[0] && this.hasSignature) {
                    docDefinition = docDefinition.replace(
                      '"##SIGNATURE##"',
                      JSON.stringify(imgPrc[0])
                    );
                    docDefinition = docDefinition.replace(
                      '"##CLINIC_LOGO##"',
                      JSON.stringify(emptyLogoObj)
                    );
                  }
                }
                this.populateForm(docDefinition);
              });
            }
          }
        });
      });
    }
  }

  processfileImg(fileImg, type, border = [false, false, true, false]) {
    const localSubject = new Subject();
    const reader = new FileReader();
    reader.readAsDataURL(fileImg);
    reader.onloadend = function () {
      const image = {
        image: reader.result,
      };

      switch (type) {
        case 'signature':
          image['width'] = 100;
          image['margin'] = [20, 20, 0, 0];
          break;
        case 'signature2':
          image['width'] = 100;
          image['margin'] = [20, 0, 0, 0];
          break;
        case 'logo':
          image['fit'] = [70, 30];
          image['margin'] = [0, 0, 0, 0];
          image['border'] = border;
        default:
          break;
      }

      localSubject.next(image);
      localSubject.complete();
    };

    return localSubject;
  }

  searchForms(input: string) {
    return this.http.get<any>(
      `${this.API.forms}?limit=20&where={"c_name" : {"$regex" : "/` +
      input +
      `/i"}}&sort={"_id": -1}`,
      this.httpOptions
    );
  }

  getFormContent(formId): Observable<any> {
    return this.http.get<any>(
      `${this.API.forms}/${formId}/c_template/content`,
      this.httpOptions
    );
  }

  getFormJSON(urlJson: string): Observable<any> {
    let httpOptionsAux = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json; charset=UTF-8',
        'Medable-Client-Key': this.medableClientKey,
      }),
      withCredentials: false,
    };

    return this.http.get<any>(urlJson, httpOptionsAux);
  }

  /**
   * Function to add a new hcpcs code.
   */
  createHcpcsCode(code): Observable<any> {
    return this.http.post<any>(
      `${this.API.hcpcs_codes}`,
      code,
      this.httpOptions
    );
  }

  getAllHcpcsCodesMedable(): Observable<any> {
    const self = this;
    let allCodes = [];
    let promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allCodes = allCodes.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getInventories!');
            promise.next(allCodes);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  updateHcpcsCode(id: string, code): Observable<any> {
    return this.http.put<any>(
      `${this.API.hcpcs_codes}/${id}`,
      code,
      this.httpOptions
    );
  }

  getAllForms(): Observable<any> {
    let allForms = [];
    const chunck = 1000;
    const localSubject = new Subject();
    let page = 0;
    const self = this;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allForms = allForms.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getInventories!');
            localSubject.next(allForms);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllFormsMedable(): Observable<any> {
    let allForms = [];
    const chunck = 1000;
    const localSubject = new Subject();
    let page = 0;
    const self = this;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.forms}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allForms = allForms.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getAllForms!');
            localSubject.next(allForms);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }

  getAllInventory(): Observable<any> {
    let allForms = [];
    const chunck = 1000;
    const localSubject = new Subject();
    let page = 0;
    const self = this;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allForms = allForms.concat(response.data);
          if (response.hasMore) {
            console.log('hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: getInventories!');
            localSubject.next(allForms);
            localSubject.complete();
          }
        });
    }

    loadAll();

    return localSubject;
  }
}
