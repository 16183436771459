import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class LocationGroupsService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createLocationGroup(locationGroup: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.locationGroups}`,
      locationGroup,
      this.httpOptions
    );
  }

  editLocationGroup(
    locationGropId: string,
    locationGroup: any
  ): Observable<any> {
    delete locationGroup.acl;
    return this.http.put<any>(
      `${this.API.locationGroups}/${locationGropId}`,
      locationGroup,
      this.httpOptions
    );
  }

  getLocationGroup(locationGropId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.locationGroups}/${locationGropId}`,
      this.httpOptions
    );
  }

  getLocationGroups(): Observable<any> {
    return this.http.get<any>(
      `${this.API.locationGroups}?limit=200&sort={"_id" : -1}`,
      this.httpOptions
    );
  }

  deleteLocationGroup(locationGropId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.locationGroups}/${locationGropId}`,
      this.httpOptions
    );
  }

  //return this.http.get<any>(`${this.API.patients}?limit=20&where={"c_mrn":{"$regex":"/` + input + `/i"}}&sort={"_id": -1}`, this.httpOptions);

  checkDuplicateByName(groupName: string, limit: number): Observable<any> {
    //&where={"c_group_name":{"$regex":"/` + input + `/i"}}&sort={"_id": -1}
    // const filter = 'where={"c_group_name":{"$regex":"/` + input + `/i"}}'
    const filter = `where={"c_group_name":{"$regex":"/${groupName}/i"}}`,
      limitStr = `limit=${limit}`,
      query = `${filter}&${limitStr}`;

    return this.http.get<any>(
      `${this.API.locationGroups}?${query}`,
      this.httpOptions
    );
  }

  getLocationsByGroupId(groupId: string): Observable<any> {
    const filter = `{"c_location_group": "${groupId}"}`,
      limit = `limit=1000`,
      query = `where=${filter}&${limit}`;

    return this.http.get<any>(
      `${this.API.locations}?${query}`,
      this.httpOptions
    );
  }
}
