import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { SignaturePad } from 'angular2-signaturepad';
import { addDays } from 'date-fns';
import * as moment from 'moment';
import * as Sentry from '@sentry/browser';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  delay
} from 'rxjs/operators';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormService } from '../../services/form/form.service';
import { FormsService } from '../../services/forms/forms.service';
import { Icd10Service } from '../../services/icd10/icd10.service';
import { InsuranceService } from '../../services/insurance/insurance.service';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { ModifierService } from '../../services/modifier/modifier.service';
import { OrderService } from '../../services/order/order.service';
import { OrderpdfService } from '../../services/orderpdf/orderpdf.service';
import { PatientService } from '../../services/patient/patient.service';
import { SharedService } from '../../services/shared/shared.service';
import { StockService } from '../../services/stock/stock.service';
import { CustomPopupComponent } from '../custom-popup/custom-popup.component';
import { PopupComponent } from '../popup/popup.component';
import { specificItemIds } from './specificItemIds';
import javascriptBarcodeReader from 'javascript-barcode-reader';
// const javascriptBarcodeReader = require('javascript-barcode-reader');

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
})
export class OrderComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @ViewChild(BarecodeScannerLivestreamComponent)
  barecodeScanner: BarecodeScannerLivestreamComponent;

  barcodeValue;

  // ngAfterViewInit() {
  //     this.barecodeScanner.start();
  // }

  onValueChanges(result) {
    this.barcodeValue = result.codeResult.code;
  }

  signaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 350,
    canvasHeight: 80,
  };

  httpAux = {
    headers: new HttpHeaders({
      // 'Content-Type':  'image/png',
    }),
    withCredentials: false,
    responseType: 'blob' as 'json',
  };

  language: string = 'eng';

  languageLabels = {
    eng: {
      dosLabel: 'orders.order-form.label.date',
    },
    esp: {
      dosLabel: 'orders.order-form.label.date.esp',
    },
  };

  testLabelStr: string = 'orders.order-form.label.date';

  orderId: string;
  orderFormId: string;
  accountId: string;
  loadingProducts: string = 'Please wait! Loading Products...';
  orderSignatureUrl: string = '';
  orderExternalDocumentUrl: string = '';
  orderExternalDocumentSrc: SafeResourceUrl = '';
  externalDocuments: any[] = [];
  newExternalDocument: any = null;
  orderSignatureSrc: SafeResourceUrl = '';
  orderSignatureReader: any;
  patientSignature: File;
  ruleFormSignature: string = '';
  gLastModified: any;

  step: number = 1;

  hasRole: any;

  popup: any = {};
  question: any = {};
  mfQuestion: any = {};
  chosenLocation: any = {};
  chosenBillingProvider: any = {};

  allHcpcsMedable: any[] = [];

  mfSignatureList: any[] = [];

  orderLineItems: any[] = [];
  orderForms: any[] = [];
  allPatients: any[] = [];
  allInsurances: any[] = [];
  availableInsurances: any[] = [];
  fitterLocations: any[] = [];
  physicians: any[] = [];
  products: any[] = [];
  searching: any[] = [];
  allModifiers: any[] = [];
  allModifiersTmp: any[] = [];
  allForms: any[] = [];
  modifierForms: any[] = [];
  availableLocations: any[] = [];
  modifierFormsDeleted: any[] = [];
  spliceModifiersIdx: any[] = [];

  invoiced: boolean = false;
  invoiceNr: string = '';

  indexPage: boolean = false;
  viewOrder: boolean = false;
  showOrder: boolean = false;
  notEditable: boolean = false;
  editOrder: boolean = false;
  createOrEdit: boolean = false;
  createMode: boolean = false;
  patientEditable: boolean = false;
  patientSelected: boolean = false;
  insuranceSelected: boolean = false;
  physicianSelected: boolean = false;
  searchFailed: boolean = false;
  searchPatientsFailed: boolean = false;
  productsLoaded: boolean = false;
  duplicatedError: boolean = false;
  editingOrderForm: boolean = false;
  editingRuleForm: boolean = false;
  multipleLocations: boolean = false;
  orderVoided: boolean = false;
  orderSigned: boolean = false;
  orderhasSignature: boolean = false;
  changeOrderSignature: boolean = false;
  shalDisplaySignaturePad: boolean = false;
  changeRuleFormSignature: boolean = false;
  multipleBillingProviders: boolean = false;
  searchingPatient: boolean = false;
  displayOrderDateTollTop: boolean = false;

  receiptAssociatedToClinic: boolean = false;
  showDeleteOrderReason: boolean = false;
  showOrderDeleteReasonError: boolean = false;

  itemPaymentMethod: any = null;

  orderModifierForms: any[] = [];

  languageOptions: any[] = [
    {
      label: 'English',
      value: 'eng',
    },
    {
      label: 'Spanish',
      value: 'esp',
    },
  ];

  chosenLanguage: any = this.languageOptions[0];

  tstX = 'Test';

  availableBillingProviders: any;

  selectedBillingProvider: any = {};

  patientListInput: any = {
    c_name: {
      c_first_name: '',
      c_last_name: '',
    },
    c_mrn: '',
  };

  order: any = {
    c_patient: {
      c_name: {
        c_first_name: '',
      },
    },
    c_provider_location: {
      c_physician: {
        c_name: {},
      },
      c_location: {},
    },
    c_insurance: {},
    c_draft: false,
  };

  selectedPhysician: any = {
    c_physician: {
      c_name: {},
    },
    c_location: {},
  };
  locationSelected: boolean = false;
  selectedFitter: any = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  };
  fitterSelected: boolean = false;

  selectedInsurances: any = {
    primary: {
      insuranceSelected: false,
    },
    secondary: {
      insuranceSelected: false,
    },
  };

  stepQuestion: any = {
    form: 0,
    question: -1,
  };

  mfStepQuestion: any = {
    form: 0,
    question: -1,
  };

  allFormsMedable: any;

  startScan: boolean = false;

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    disableSince: this.dateService.toDatepickerFormat(addDays(new Date(), 1)),
  };

  orderDateModel: IMyDateModel = null;
  orderedDateModel: IMyDateModel = null;
  billedDateModel: IMyDateModel = null;
  dateOfBirthModel: IMyDateModel = null;

  allFitters: any[];

  orderDeletionReason: string = '';

  continueModal: boolean = false;
  scannedNotFound: string = '';

  // Will hold the order's cin7 ref - this is a separate variable for standalone editing of the order's cin7 ref
  ordCin7SoRef = '';

  /**
   * For each operation that involves removing items from an order or deleting the orderitself,
   * we'll create a new creditNote in cin7, with the items affected by the operation.
   */
  itemsForCN: any[];

  validationError = {
    error: false,
    message: '',
  };

  disableSubmitButtons: boolean = false;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private orderService: OrderService,
    private sharedService: SharedService,
    private formsService: FormsService,
    private orderPDF: OrderpdfService,
    private patientService: PatientService,
    private insuranceService: InsuranceService,
    private icd10Service: Icd10Service,
    private modifierService: ModifierService,
    private formService: FormService,
    private http: HttpClient,
    private stockService: StockService,
    private dateService: DateService
  ) {
    this.searchIcdWrapper = this.searchIcdWrapper.bind(this);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      //this.loadExampleCreditNote();

      this.getCurrentUser();

      // this.fixOrder();

      // Use this to get all the itemsIds  of orders which contain  specific itemIds
      // this.getAllItemIdsfromSpecificOnes();

      this.getAllFitters();
      this.getAllHcpcsMedable();
      this.orderId = params['id'] ? params['id'] : '0';
      this.orderFormId = params['formId'] ? params['formId'] : '0';
      this.hasRole = this.authService.getAuthorizedUserRole();
      this.setCurrentDate();
      this.showOrder = false;

      setTimeout(() => {
        // Index Page
        if (this.state.is('forms.ordersIndex')) {
          // this.getBadOrder();
          this.indexPage = true;
          // this.addOrderDateToOrderItems();
        } else if (this.state.is('forms.ordersView')) {
          // If view state
          this.viewOrder = true;
          this.notEditable = true;
          this.getOrder();
        } else if (this.state.is('forms.ordersEdit')) {
          // Edit state
          // this.getSpecificRuleForm();
          this.getAllFormsMedable();
          this.viewOrder = false;
          this.notEditable = false;
          this.editOrder = true;
          this.getOrder();
          // this.getAllPatients();
          this.getAllInsurances();
          this.getAllModifiers();
          this.getFitterLocations();
          this.createOrEdit = true;
        } else if (this.state.is('forms.ordersCreate')) {
          // Create State
          // this.openModal();

          // setTimeout(() => {
          //   this.continueModal = false;
          //   this.closeModal();
          // }, 2000);
          // this.getAllPatients();
          this.getUsersLocations();
          this.getAllInsurances();
          this.getAllModifiers();
          // this.getAllForms();
          this.getAllFormsMedable();
          this.createMode = true;
          this.createOrEdit = true;
          const today = this.dateService.toUTCDate(new Date());

          this.orderDateModel = this.dateService.toDatepickerModel(today);
          this.orderedDateModel = this.dateService.toDatepickerModel(today);
        } else if (this.state.is('forms.ruleFormsEdit')) {
          // Edit RulesForm State
          this.editingRuleForm = true;
          this.getOrder();
          this.getSpecificRuleForm();
        }
      });

      // Process all orders:
      this.processOrders();
    });
  }

  handleFileInputUpload = async () => {
    let fileToBlob = async (doc) => new Blob([new Uint8Array(await doc.arrayBuffer())], {type: doc.type });
    const sBlob = await fileToBlob(this.newExternalDocument.files[0]);

    let reader = new FileReader();
    reader.readAsDataURL(sBlob);
    reader.onloadend = function () {
      const base64data = reader.result;
    };

    const sFile = new File([sBlob], this.newExternalDocument.files[0].name);

    this.orderService
      .saveOrderExternalDocument(sFile, this.orderId)
      .subscribe(
        (file) => {
          this.externalDocuments.push(file.data[ file.data.length - 1 ])
          this.formsService
            .sendFileAWS(sFile, file.data[ file.data.length - 1 ])
            .subscribe(
              (res) => {
                this.cleanInputFile(res.data);
              },
              (error) => {
                this.cleanInputFile(error);
              }
            );
        },
        (error) => {
          this.cleanInputFile(error);
        }
      );
  }

  removeOrderExternalDocument = (id: string) => {
    this.orderService
    .removeOrderExternalDocument(id, this.orderId)
    .subscribe(
      response => {
        this.externalDocuments = this.externalDocuments.filter(doc => id !== doc._id)
      },
      error => {
        console.log('remove error', error)
      }
    )
  }

  getOrderExternalDocument() {
    const self = this;
    this.http
      .get<any>(this.orderExternalDocumentUrl, this.httpAux)
      .subscribe((awsDoc) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          self.orderExternalDocumentSrc = reader.result;
        };
        reader.readAsDataURL(awsDoc);
      });
  }

  openOrderExternalDocument(docId) {
    this.orderService
      .getOrderExternalDocumentContent(this.orderId)
      .subscribe((response) => {
        const result = response.data.reduce((acc, document) => {
          return {...acc, [document._id]: document}
        }, {})

        if (result[docId].url) {
          window.open(result[docId].url)
        } else {
          const info = {
            subtitle: 'orders.order-external-document.error',
            btnCenter: 'OK',
            btnCenterClass: 'center-button',
          };

          this.modalService.open(PopupComponent);
          this.sharedService.setPopupInfo(info);
        }
      }, error => console.log('error', error))
  }

  cleanInputFile = (result) => {
    this.newExternalDocument.value = null;
    this.newExternalDocument = null;
  }

  fixOrder = () => {
    // let soCode = "ROCK-157-15929515257",
    //   ordId = "5ef282e5bcc9a60100b07633";
    // console.log("Adding so code: ", soCode, " to order w id: ", ordId);
    // this.orderService
    //   .editOrder({ c_cin7_so_code: soCode }, ordId)
    //   .subscribe((upd) => {
    //     console.log("Order Updated: ", upd);
    //   });
  };

  /**
   * Function to update the order's cin7 ref on demand.
   */
  updateOrderCin7SoRef = () => {
    console.log("Updating order's cin7 so ref: ", this.ordCin7SoRef);

    let title = 'orders.update.cin7.ref.title',
      text = `orders.update.cin7.ref.text`;
    // Display delete confirmation popup
    this.showPopupWindow(title, text).then((response) => {
      if (response.selected === 'LEFT') {
        // Confirmed update.
        let body = { c_cin7_so_code: this.ordCin7SoRef },
          __id = this.order._id;
        this.orderService.editOrder(body, __id).subscribe((upd) => {
          console.log('Ref updated: ', upd);
          // this.closeModal();
          console.log('Goind to view order...');
          window.location.reload();
        });
      }
    });
  };

  getCurrentUser = () => {
    let user = this.authService.getAuthorizedUser();
    // console.log("********Current user is: ", user);
  };

  addOrderDateToOrderItems() {
    // console.log("Loading all medable orderItems...")
    this.orderService.getAllOrderItemsDebug().subscribe((allItems) => {
      // console.log("All Medable OrderItems: ", allItems)
      const needsToUpdate = allItems.filter(
          (item) => !item.c_order_last_modified
        ),
        updateBatch = needsToUpdate.slice(0, 2000);
      // console.log("Needs to be updated: ", needsToUpdate)
      // console.log("Update Batch: ", updateBatch)
      updateBatch.map((itemObj) => {
        const updateObj = {
          c_order_last_modified: itemObj.c_medable_order.c_last_modified,
        };
        const itemId = itemObj._id;
        updateObj['c_order_last_modified'] = this.gLastModified;
        this.orderService
          .editOrderLineItem(updateObj, itemId)
          .subscribe((updated) => {
            // console.log("Item Updated: ", updated)
          });
      });
    });
  }

  displayToolTop() {
    this.displayOrderDateTollTop = true;
  }

  hideToolTip() {
    this.displayOrderDateTollTop = false;
  }

  // getBadOrder() {
  //   console.log("Loading order w id: 5c893ad6fb12400100636503" );
  //   this.formsService.get('orders', '/5c893ad6fb12400100636503?expand[]=c_patient').subscribe( ord => {
  //     console.log("Order Loaded: ", ord);
  //   })
  //   console.log("Deleting order...");
  //   this.orderService.deleteOrder("5c893ad6fb12400100636503").subscribe( dor => {
  //     console.log("Dor: ", dor);
  //   })
  // }

  getProductBasedOnCode = (code) => {
    const tmpCode = '703055-PL';
    // console.log("AllProducts: ", this.products)
    const scannedProduct = this.products.filter(
      (pLoc) => pLoc.c_product.cpy_product_option.c_code == code
    );
    // console.log("ScannedProduct: ", scannedProduct)
    if (!scannedProduct.length) {
      this.scannedNotFound = `Product with code "${code}" not found.`;
      return;
    }

    this.addProduct(scannedProduct[0]);
  };

  scanSuccessHandler = (event) => {
    // console.log("scansuccess", event);
    this.getProductBasedOnCode(event);
    this.startScan = false;
  };
  scanErrorHandler = (event) => {
    // console.log("scanerror");
  };

  toggleScanning = () => {
    // this.startScan.idx = idx
    this.scannedNotFound = '';
    this.startScan = !this.startScan;
    // console.log("startscan: ", this.startScan)
  };

  setCurrentDate() {
    const currentDate = new Date();
    // console.log("CurrentDate");
  }

  changeSignature() {
    this.changeOrderSignature = true;
  }

  cancelSignChange() {
    this.changeOrderSignature = false;
  }

  fixBinary(bin) {
    let length = bin.length;
    let buf = new ArrayBuffer(length);
    let arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  enableChangeRFSignature() {
    this.changeRuleFormSignature = true;
  }

  disableChangeRFSignature() {
    this.changeRuleFormSignature = false;
    this.mfSignatureList = [];
  }

  drawComplete() {
    const signature = this.signaturePad
      .toDataURL()
      .replace('data:image/png;base64,', '');
      const binary = this.fixBinary(atob(signature));
      const sBlob = new Blob([binary], { type: 'image/png' });
      let reader = new FileReader();
      reader.readAsDataURL(sBlob);
      reader.onloadend = function () {
        const base64data = reader.result;
      };
      const sFile = new File([sBlob], 'orderSignature.png');
      this.patientSignature = sFile;
      this.orderSigned = true;
  }

  drawCompleteMf(currentForm) {
    const signature = this.signaturePad
        .toDataURL()
        .replace('data:image/png;base64,', ''),
      binary = this.fixBinary(atob(signature)),
      sBlob = new Blob([binary], { type: 'image/png' }),
      reader = new FileReader(),
      sFile = new File([sBlob], 'orderMfSignature.png'),
      currentFomId = currentForm.c_modifier_form.c_form._id,
      temp = {
        formId: currentForm.c_modifier_form.c_form._id,
        mfSignature: sFile,
      };

    let exists = false;

    this.mfSignatureList.map((mfs) => {
      if (mfs.formId == temp.formId) {
        exists = true;
        mfs.mfSignature = temp.mfSignature;
      }
    });

    if (!exists) {
      this.mfSignatureList.push(temp);
    }
  }

  drawClearMf(currentForm) {
    const currentFormId = currentForm.c_modifier_form.c_form._id;
    let spliceIdx = -1;
    this.mfSignatureList.map((sObj, idx) => {
      if (sObj.formId == currentFormId) {
        spliceIdx = idx;
      }
    });

    if (spliceIdx > -1) {
      this.mfSignatureList.splice(spliceIdx, 1);
    }
    this.signaturePad.clear();
    // console.log("MfSignatureList: ", this.mfSignatureList);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  drawClear() {
    // console.log("Clearing signature");
    this.signaturePad.clear();
    this.orderSigned = false;
  }

  getAllFitters() {
    this.formsService.get('allFitters', '').subscribe((allFitters) => {
      // console.log("All Fitters Loaded: ", allFitters);
      this.allFitters = allFitters.data;
    });
  }

  getAllHcpcsMedable() {
    // console.log("Getting all hcpcs codes from medable");
    this.orderService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
      // console.log("All codes from medable loaded: ", allCodes);
      this.allHcpcsMedable = allCodes[0].data;
      // console.log("All hcpcs: ", this.allHcpcsMedable);
    });
  }

  // getSpecificOrderForm() {
  //   this.orderService
  //     .getOrderForm(this.orderFormId)
  //     .subscribe((specificOrderForm) => {
  //       this.orderForms.push(specificOrderForm);

  //       for (let k = 0; k < this.orderForms.length; k++) {
  //         for (let i = 0; i < this.orderForms[k].c_responses.length; i++) {
  //           this.getResponses(k, i);
  //         }
  //       }

  //       this.nextFormQuestion();
  //     });
  // }

  getSpecificRuleForm() {
    // console.log("Editing RuleForms");
    this.orderService.getRuleForm(this.orderFormId).subscribe((mff) => {
      // console.log("Specific Ruleforms: ", mff)
      if (mff.c_form_signature) {
        this.ruleFormSignature = '';
        // console.log("Form has signature");
        this.loadRuleFormSignature(mff).subscribe(() => {
          this.continueLoadingCurrentMF(mff);
        });
      } else {
        this.continueLoadingCurrentMF(mff);
      }
    });
  }

  loadRuleFormSignature(mff): Observable<any> {
    const localSubject = new Subject();
    const self = this;
    this.orderService.getMfSignatureContent(mff._id).subscribe((image) => {
      const formSignatureUrl = image.url;
      this.http
        .get<any>(formSignatureUrl, this.httpAux)
        .subscribe((awsImage) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            self.ruleFormSignature = reader.result.toString();
            // console.log("Form Signature Loaded: ", self.ruleFormSignature);
            localSubject.next();
            localSubject.complete();
          };
          reader.readAsDataURL(awsImage);
        });
    });
    return localSubject;
  }

  continueLoadingCurrentMF(mff) {
    this.modifierForms = [];
    this.modifierForms.push({
      c_modifier_form: {
        c_form: mff.c_form,
      },
      c_product: mff.c_product,
      c_responses: mff.c_responses,
      _id: mff._id,
    });

    for (let k = 0; k < this.modifierForms.length; k++) {
      for (let i = 0; i < this.modifierForms[k].c_responses.length; i++) {
        this.getResponsesMf(k, i);
      }
    }
    this.nextModifierFormQuestion();
  }

  medableDisplayDate(medableDate) {
    const dateObj = new Date(medableDate);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();

    const displayMonth = month < 10 ? `0${month}` : month;
    const displayDay = day < 10 ? `0${day}` : day;

    return `${displayMonth}/${displayDay}/${year}`;
  }

  getOrderSignature() {
    const self = this;
    this.http
      .get<any>(this.orderSignatureUrl, this.httpAux)
      .subscribe((awsImage) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          self.orderSignatureSrc = reader.result;
        };
        reader.readAsDataURL(awsImage);
      });
  }

  getOrder() {
    this.emptyOrder();
    this.orderLineItems = [];
    const expands = [
        'creator',
        'c_patient',
        'c_insurance',
        'c_provider_location.c_physician',
        'c_provider_location.c_physician.c_billing_providers.c_provider',
        'c_provider_location.c_location',
        'c_secondary_insurance',
        'c_billing_provider',
        'c_external_document'
      ],
      expandStr = `expand[]=${expands.join('&expand[]=')}`,
      qStr = `?${expandStr}`;

    // console.log("Expands: ", expandStr);
    //const qStr = "?expand[]=creator&expand[]=c_patient&expand[]=c_insurance&expand[]=c_provider_location.c_physician&expand[]=c_provider_location.c_location&expand[]=c_secondary_insurance";

    this.formsService
      .get('orders', '/' + this.orderId + qStr)
      .subscribe((data) => {
        // this.checkForReceiptForm(data._id);
        if (data.c_order_signature) {
          // console.log("Order Has Signature")
          this.orderhasSignature = true;
          this.orderService
            .getOrderSignatureContent(data._id)
            .subscribe((image) => {
              this.orderSignatureUrl = image.url;
              this.getOrderSignature();
            });
        } else {
          this.orderhasSignature = false;
        }

        if (data.c_order_document) {
          this.externalDocuments = data.c_order_document;
        }

        console.log('OrderMedable: ', data);

        // Grab order's cin7 ref.
        this.ordCin7SoRef = data.c_cin7_so_code;
        console.log('OrderRef is: ', this.ordCin7SoRef);

        this.order = data;

        this.orderDateModel = this.dateService.toDatepickerModel(
          new Date(data.c_order_date)
        );
        if (data.c_ordered_date) {
          this.orderedDateModel = this.dateService.toDatepickerModel(
            new Date(data.c_ordered_date)
          );
        }
        if (data.c_billed_date) {
          this.billedDateModel = this.dateService.toDatepickerModel(
            new Date(data.c_billed_date)
          );

          this.isOrderVoided(data);
        }

        this.order.c_patient = this.formsService.removeExtraInfo(
          this.order.c_patient
        );
        this.dateOfBirthModel = this.dateService.toDatepickerModel(
          new Date(this.order.c_patient.c_dob)
        );

        this.order.c_fitter = this.order.c_fitter._id;
        // this.order.c_billed_date = this.parseToDatePicker(this.order.c_billed_date);;
        this.selectedPhysician = this.order.c_provider_location;
        this.selectedInsurances.primary.document = this.order.c_insurance;
        this.patientListInput = this.order.c_patient;
        // console.log("This ORder: ", this.order);

        this.availableBillingProviders = this.order.c_provider_location.c_physician.c_billing_providers.map(
          (bp) => {
            bp.c_provider.displayName = this.buildProviderDisplayName(
              bp.c_provider
            );
            return bp;
          }
        );

        if (this.order.c_billing_provider) {
          // console.log("BillingProvider: ", this.order.c_billing_provider);
          this.selectedBillingProvider = this.order.c_billing_provider;
          this.chosenBillingProvider = this.order.c_billing_provider;
          this.chosenBillingProvider.displayName = this.buildProviderDisplayName(
            this.chosenBillingProvider
          );
          this.selectedBillingProvider.displayName = this.buildProviderDisplayName(
            this.selectedBillingProvider
          );
          //this.chosenBillingProvider = this.selectedBillingProvider;
        }

        if (this.availableBillingProviders.length > 1) {
          this.multipleBillingProviders = true;
        }

        if (this.order.c_secondary_insurance) {
          this.selectedInsurances.secondary.document = this.order.c_secondary_insurance;
        }

        if (this.order.c_primary_insurance_id_number) {
          this.selectedInsurances.primary.id = this.order.c_primary_insurance_id_number;
        }

        if (this.order.c_secondary_insurance_id_number) {
          this.selectedInsurances.secondary.id = this.order.c_secondary_insurance_id_number;
        }

        if (this.state.is('forms.ordersEdit')) {
          this.getProducts();
        }
      });

    this.getOrderLineItems();
    if (!this.state.is('forms.ruleFormsEdit')) {
      // this.getOrderForms();
      this.getModifierForms();
    }

    this.showOrder = true;
  }

  isOrderVoided(order) {
    // console.log("Checking if order is voided...");
    this.orderService
      .getSalesOrderCin7(order.c_cin7_so_id)
      .subscribe((cin7Order) => {
        //console.log("Got Checking Result: ", cin7Order);
        if (cin7Order.data.status == 'VOID') {
          //console.log("Order is voided");
          this.orderVoided = true;
        }
      });
  }

  // getOrderForms() {
  //   const filter = 'where={"c_medable_order":"' + this.orderId + '"}';
  //   const expand =
  //     "expand[]=c_inventory_form.c_product&expand[]=c_inventory_form.c_form&expand[]=c_responses.c_question";
  //   const query = "?" + filter + "&" + expand;

  //   this.formsService.get("orderForms", query).subscribe((orderForms) => {
  //     console.log("OrderForms: ", orderForms);
  //     this.orderForms = orderForms.data;

  //     for (let k = 0; k < this.orderForms.length; k++) {
  //       for (let i = 0; i < this.orderForms[k].c_responses.length; i++) {
  //         this.getResponses(k, i);
  //       }
  //     }
  //   });
  // }

  // getResponses(k, i) {
  //   const response = this.orderForms[k].c_responses[i];

  //   if (
  //     response.c_question.c_response_type === "text" ||
  //     !response.c_question.c_multi_select
  //   ) {
  //     if (response.c_response && response.c_response.length > 0) {
  //       this.orderForms[k].c_responses[i].c_question.response =
  //         response.c_response[0];
  //     }
  //   } else {
  //     for (let j = 0; j < response.c_question.c_response_options.length; j++) {
  //       this.orderForms[k].c_responses[i].c_question.c_response_options[j] = {
  //         text: response.c_question.c_response_options[j],
  //         checked: response.c_response.indexOf(j.toString()) !== -1,
  //       };
  //     }
  //   }
  // }

  getResponsesMf(k, i) {
    const response = this.modifierForms[k].c_responses[i];
    if (
      response.c_question.c_response_type === 'text' ||
      !response.c_question.c_multi_select
    ) {
      if (response.c_response && response.c_response.length > 0) {
        this.modifierForms[k].c_responses[i].c_question.response =
          response.c_response[0];
      }
    } else {
      for (let j = 0; j < response.c_question.c_response_options.length; j++) {
        this.modifierForms[k].c_responses[i].c_question.c_response_options[
          j
        ] = {
          text: response.c_question.c_response_options[j],
          checked: response.c_response.indexOf(j.toString()) !== -1,
        };
      }
    }
  }

  getFormsCodeByItem = (itemId) => {
    if (!itemId) {
      return '';
    }

    let line = this.orderLineItems.filter(
      (oli) => oli.c_line_internal_id == itemId
    )[0];

    let code = (line || {}).c_selected_hcpcs || '';

    return code;
  };

  getModifierForms() {
    const filter = 'where={"c_medable_order":"' + this.orderId + '"}';
    const expand =
      'expand[]=c_form&expand[]=c_responses.c_question&expand[]=c_product&expand=c_attached_hcpcs_codes.c_hcpcs_code';
    const query = '?' + filter + '&' + expand;
    this.modifierForms = [];
    this.formsService
      .get('orderModifierForms', query)
      .subscribe((modifierForms) => {
        console.log("Raw Order's modifierForms: ", modifierForms);
        modifierForms.data.map((mff) => {
          if (
            mff.c_form.c_base_name &&
            mff.c_form.c_base_name.toLowerCase().indexOf('receipt') != -1
          ) {
            console.log('Has Receip Form!!!');
            this.receiptAssociatedToClinic = true;
          }

          let displayHcpcs = this.getFormsCodeByItem(mff.c_item_internal_id);

          this.modifierForms.push({
            c_modifier_form: {
              c_form: mff.c_form,
            },
            c_form_signature: mff.c_form_signature,
            displayHcpcs,
            c_product: mff.c_product,
            c_responses: mff.c_responses,
            c_attached_hcpcs_codes: mff.c_attached_hcpcs_codes,
            _id: mff._id,
          });
        });

        for (let k = 0; k < this.modifierForms.length; k++) {
          for (let i = 0; i < this.modifierForms[k].c_responses.length; i++) {
            this.getResponsesMf(k, i);
          }
        }
      });
  }

  getAllForms() {
    this.allForms = [];
    this.formService.getAllForms().subscribe((allForms) => {
      allForms.map((form) => {
        this.allForms[form._id] = form;
        // console.log("All forms from Medable loaded...", this.allForms)
      });
    });
  }

  getAllFormsMedable() {
    this.allFormsMedable = [];

    this.formService.getAllFormsMedable().subscribe((afm) => {
      this.allFormsMedable = afm;
      // console.log("All forms from Medable loaded...", this.allFormsMedable)
    });
  }

  getFitterLocations() {
    this.formsService.get('accounts').subscribe((account) => {
      this.accountId = account.data[0]._id;
      this.order.c_fitter = this.accountId;

      const tmpFitter = '5c2fcfc0da237101007ddc25';
      const expapnds = '&expand[]=c_location';
      let query = '';
      // Temporary condition. If logged in with Joe's account, load a random fitter that has locations available.
      // Else - load fitters from current accountId
      // if (this.accountId === "5a2b175534a5290100b998d6" && false) {
      //   query = '?where={"c_fitter": "' + tmpFitter + '"}' + expapnds;
      // } else {
      query = '?where={"c_fitter": "' + this.accountId + '"}' + expapnds;
      // }

      this.formsService
        .get('account_locations', query)
        .subscribe((account_locations) => {
          this.fitterLocations = account_locations.data;
          // console.log("AccountLocations: ", account_locations);
          this.getPhysicians();
        });
    });
  }

  getPhysicians() {
    // console.log("Getting physicians... ");
    // console.log("FitterLocations: ", this.fitterLocations);
    this.physicians = [];
    const expands =
      'expand[]=c_physician&expand[]=c_location&expand[]=c_physician.c_billing_providers.c_provider';
    let filter = '';
    let query = '';

    for (let index = 0; index < this.fitterLocations.length; index++) {
      filter =
        'where={"c_location" : "' +
        this.fitterLocations[index].c_location._id +
        '"}';
      query = '?' + filter + '&' + expands;

      this.formsService
        .get('providerLocations', query)
        .subscribe((physicians) => {
          this.physicians = this.physicians.concat(
            physicians.data.filter((physician) => physician)
          );
          // console.log("Physicians: ", this.physicians);
        });
    }
  }

  getProducts(checkModifiers = false) {
    this.loadingProducts = 'Please wait...loading products...';
    this.productsLoaded = false;
    this.products = [];
    // console.log("Getting products for physycian: ", this.selectedPhysician)
    this.orderService
      .getInventoriesLocation(this.selectedPhysician, 0)
      .subscribe((response) => {
        this.products = [];
        const self = this;

        this.orderLineItems.map((obj, index) => {
          if (obj.c_product.cpy_product_option != undefined) {
            if (
              !response.length ||
              response
                .map((rsp) => rsp.c_product_option_code)
                .indexOf(obj.c_product.cpy_product_option.c_code) === -1
            ) {
              self.removeProductResponse({ data: index });
            }
          }
        });

        response.map((invLoc) => {
          // console.log("invLog: ", invLoc);
          if (invLoc.c_product.c_product_options) {
            const prod = { ...invLoc };
            const option = invLoc.c_product.c_product_options.filter(
              (opt) => opt.c_code === prod.c_product_option_code
            );

            // Check if product is inactive.
            // console.log("PROD: ")
            let prodInactive =
              ((prod.c_product || {}).c_status || '') === 'inactive';

            let prodDeleted = (prod.c_product || {}).c_deleted || false;

            if (option.length && !prodInactive && !prodDeleted) {
              prod.c_product.cpy_product_option = {
                c_size: option[0].c_size,
                c_color: option[0].c_color,
                c_code: option[0].c_code,
                _id: option[0]._id,
              };
              self.products.push(prod);
            }
          }
        });

        this.productsLoaded = true;
        // console.log("All Products Loaded...", this.products)
        this.loadingProducts = '';

        // if (checkModifiers) {
        //   for (let i = 0; i < this.orderLineItems.length; i++) {
        //     if (!this.orderLineItems[i].delete) {
        //       this.getModifiers(i);
        //     }
        //   }
        // }
      });
  }

  secondStepValid() {
    return !(
      this.selectedInsurances.primary.insuranceSelected &&
      this.physicianSelected &&
      this.fitterSelected &&
      (this.locationSelected || !this.multipleLocations) &&
      this.selectedInsurances.primary.id
    );
  }

  getOrderLineItems() {
    this.formsService
      .get(
        'orderLineItems',
        '?where={"c_medable_order": "' +
          this.orderId +
          '"}&expand[]=c_product&expand[]=c_product.c_modifier_doc.c_modifier&expand[]=c_modifier.c_modifier_ref'
      )
      .subscribe((data) => {
        data = data.data;

        for (let i = 0; i < data.length; i++) {
          const filteredOptions = data[i].c_product.c_product_options.filter(
            (opt) => opt.c_code === data[i].c_product_option_code
          );
          // console.log("FilteredOptions: ", filteredOptions);
          if (data[i].c_sales_tax == null || data[i].c_sales_tax == '') {
            data[i].c_sales_tax = 0;
          }

          // data[i]['c_mayment_method'] = 0;
          data[i].c_product.cpy_product_option = filteredOptions[0];
          this.orderLineItems.push(this.formsService.removeExtraInfo(data[i]));

          if (data[i].c_icd_10 !== undefined) {
            const icd10s = data[i].c_icd_10;
            data[i].c_icd_10 = [];
            this.getIcd10s(icd10s, i);
          }
        }

        console.log('OrderLineItems: ', this.orderLineItems);
      });
  }

  getIcd10s(icd10s, lineIdex) {
    for (let i = 0; i < icd10s.length; i++) {
      this.orderService.checkIcd10Exists(icd10s[i]).subscribe((exists) => {
        if (exists.data.length > 0) {
          this.formsService.get('icd10s', '/' + icd10s[i]).subscribe((data) => {
            this.orderLineItems[lineIdex].c_icd_10.push(data);
          });
        }
      });
    }
  }

  // Iterate trough all modifiers and attach/populate them based on rules
  addModifiers(lineIndex) {
    // console.log("attaching Modifiers!");
    const line = this.orderLineItems[lineIndex];
    line.c_modifier_details = [];
    line.c_only_modifiers = [];

    this.allModifiersTmp.map((modifier) => {
      const matchingInsurances =
        this.countMatchingInsurances(modifier).length > 0;
      const matchingCodes =
        this.countMatchingHcpcs(lineIndex, modifier).length > 0;
      const alreadyAdded = this.modifierAdded(lineIndex, modifier).length > 0;
      const allCodes = modifier.c_all_hcpcs_codes;
      const autoPopulate = this.shouldAutopopulate(modifier);
      const noInsurance = modifier.c_insurances.length === 0;
      const noQuestions = modifier.questions.length === 0;
      // console.log("Modifier: ", modifier);

      if (
        (allCodes && noInsurance && noQuestions) ||
        ((allCodes || matchingCodes) && autoPopulate && !alreadyAdded)
      ) {
        this.populateSpecificModifier(lineIndex, modifier);
      } else if (
        allCodes ||
        (matchingCodes && !autoPopulate && !alreadyAdded)
      ) {
        this.attachSpecificModifier(lineIndex, modifier);
      }
    });

    this.askQuestions(lineIndex);
  }

  shouldAutopopulate(modifier) {
    // console.log("Autopop Modif: ", modifier)
    if (!modifier.c_auto_populate_insurance) {
      return false;
    }

    const matchingInsurance = modifier.c_auto_populate_insurance.filter(
      (ins) =>
        ins.c_insurance._id === this.selectedInsurances.primary.document._id
    );

    return matchingInsurance.length > 0;
  }

  modifierAdded(lineIndex, modifier) {
    const alreadyAdded = this.orderLineItems[
      lineIndex
    ].c_modifier_details.filter((em) => em._id === modifier._id);

    return alreadyAdded;
  }

  countMatchingHcpcs(lineIndex, modifier) {
    const product = this.orderLineItems[lineIndex].c_product;
    // console.log("Product: ", product, " codes: ", modifier.c_hcpcs_codes);
    const matchingCodes = modifier.c_hcpcs_codes.filter(
      (code) => code.c_hcpcs_code == product.c_hcpcs_code
    );

    return matchingCodes;
  }

  countMatchingInsurances(modifier) {
    const selectedInsurance = this.selectedInsurances.primary.document._id;
    const matchingInsurances = modifier.c_insurances.filter(
      (insurance) => insurance._id === selectedInsurance
    );

    return matchingInsurances;
  }

  attachSpecificModifier(lineIndex, modifier) {
    const line = this.orderLineItems[lineIndex];
    //console.log("Attaching spec modifier: ", modifier);
    line.c_modifier_details.push({ ...modifier });
    if (!modifier.c_warning) {
      line.c_only_modifiers.push({ ...modifier });
    }
    // console.log("Line Modifiers:", line.c_modifier_details);
    line.c_modifier.push({
      c_modifier: modifier.c_name,
      c_checked: false,
      c_modifier_ref: modifier._id,
      c_warning: modifier.c_warning,
    });
    // console.log("Line Modifier: ", line.c_modifier);
  }

  populateSpecificModifier(lineIndex, modifier) {
    const line = this.orderLineItems[lineIndex];
    const detailObj = { ...modifier };
    const spliceIdx = [];

    // console.log("Populating spc modifier")
    detailObj.c_checked = true;
    line.c_modifier_details.push(detailObj);
    if (!modifier.c_warning) {
      line.c_only_modifiers.push(detailObj);
    }
    // console.log("Modifier details: ", line.c_modifier_details);
    line.c_modifier.push({
      c_modifier: modifier.c_name,
      c_checked: true,
      c_modifier_ref: modifier._id,
      c_warning: modifier.c_warning,
    });
  }

  canAttachModifier(lineIndex, modifier) {
    const matchingInsurances =
      this.countMatchingInsurances(modifier).length > 0;
    const matchingCodes =
      this.countMatchingHcpcs(lineIndex, modifier).length > 0;
    const alreadyAdded = this.modifierAdded(lineIndex, modifier).length > 0;
    const noCodes = modifier.c_hcpcs_codes.length === 0;

    return !alreadyAdded && (matchingCodes || noCodes);
  }

  canPopulateModifier(lineIndex, modifier) {
    const matchingInsurances = modifier.c_insurances.filter(
      (insurance) =>
        insurance._id === this.selectedInsurances.primary.document._id
    );
    const matchingHcpcs = modifier.c_hcpcs_codes.filter(
      (code) =>
        code.c_hcpcs_code ===
        this.orderLineItems[lineIndex].c_product.c_hcpcs_code
    );

    return matchingInsurances.length && matchingHcpcs.length;
  }

  // Ask Modifiers Questions
  askQuestions(lineIndex) {
    const lineModifiers = this.orderLineItems[lineIndex].c_modifier_details;

    // console.log("Modifiers: ", lineModifiers);
    const modifiersWithQuestions = lineModifiers.filter((modifier, mIndex) => {
      if (!modifier.c_checked) {
        return true;
      }
    });

    // console.log("Modifiers w questions: ", modifiersWithQuestions);
    this.askModifierQuestion(lineIndex, modifiersWithQuestions, 0, 0, 270);
  }

  // Ask Given Questions
  askModifierQuestion(
    lineIndex,
    modifiers,
    modifierIndex,
    questionIndex,
    delay
  ) {
    // No more modifiers with questions
    if (!modifiers[modifierIndex]) {
      return;
    }

    // No more questions for this modifier
    if (!modifiers[modifierIndex].questions[questionIndex]) {
      this.askModifierQuestion(
        lineIndex,
        modifiers,
        modifierIndex + 1,
        0,
        delay
      );
      return;
    }

    // if (!this.canAskQuestion(modifiers, modifierIndex, questionIndex)) {
    //   this.askModifierQuestion(lineIndex, modifiers, modifierIndex + 1, 0, delay);
    //   return;
    // }

    this.displayModifierQuestion(
      lineIndex,
      modifiers,
      modifierIndex,
      questionIndex,
      delay
    );
  }

  // canAskQuestion(modifiers, modifierIndex, questionIndex) {
  //   const modifier = modifiers[modifierIndex];
  //   const question = modifier.questions[questionIndex]
  // }

  displayModifierQuestion(
    lineIndex,
    modifiers,
    modifierIndex,
    questionIndex,
    delay
  ) {
    setTimeout(() => {
      // console.log("Line: ", this.orderLineItems[lineIndex]);
      const modifier = modifiers[modifierIndex];
      const question = modifier.questions[questionIndex];
      // If question is for all or selected insurance
      // console.log("Question: ", question);
      const matchingInsurance =
        question.c_insurances.filter(
          (qi) => qi._id === this.selectedInsurances.primary.document._id
        ).length > 0;

      const matchingHcpcs =
        question.c_hcpcs_codes.filter(
          (code) =>
            code.c_hcpcs_code ===
            this.orderLineItems[lineIndex].c_product.c_hcpcs_code
        ).length > 0;
      const noInsurance = question.c_insurances.length === 0;
      const noHcpcs = question.c_hcpcs_codes.length === 0;

      if ((noInsurance || matchingInsurance) && (noHcpcs || matchingHcpcs)) {
        const popupInfo = {
          title: 'Modifier - ' + modifier.c_name,
          subtitle: question.c_question_text,
          options: question.response_options,
        };

        this.customPopupWindow(popupInfo).then((response) => {
          this.processQuestionResponse(
            modifier._id,
            lineIndex,
            modifiers,
            response
          );
          this.askModifierQuestion(
            lineIndex,
            modifiers,
            modifierIndex,
            questionIndex + 1,
            delay
          );
        });
      } else {
        this.askModifierQuestion(
          lineIndex,
          modifiers,
          modifierIndex,
          questionIndex + 1,
          delay
        );
      }
    }, delay);
  }

  checkSpecificModifier(lineIndex, modifier) {
    const line = this.orderLineItems[lineIndex];
    // console.log("Line: ", line);

    line.c_modifier.map((mdf) => {
      if (mdf.c_modifier_ref === modifier._id) {
        mdf.c_checked = true;
      }
    });

    line.c_modifier_details.map((mdf) => {
      if (mdf._id === modifier._id) {
        mdf.c_checked = true;
      }
    });
  }

  modifierAttached(lineIndex, modifier) {
    const line = this.orderLineItems[lineIndex];
    // console.log("Line Modifiers: ", line.c_modfier);
    const exists = line.c_modifier_details.filter(
      (mdf) => mdf._id === modifier._id
    ).length;

    return exists > 0;
  }

  hasAplicableQuestions(entireModifier, lineIndex) {
    const product = this.orderLineItems[lineIndex].c_product;
    const insurance = this.selectedInsurances.primary.document;
    let qMatchingCodes = 0;
    let qMatchingInsurances = 0;
    // console.log("Checking for applicable questions: ");
    // console.log("Entire Modifier: ", entireModifier);
    // console.log("product: ", product);
    // console.log("insurance: ", insurance);
    entireModifier.questions.map((mQuestion) => {
      mQuestion.c_hcpcs_codes.map((qHcpcs) => {
        if (qHcpcs.c_hcpcs_code === product.c_hcpcs_code) {
          qMatchingCodes++;
        }
      });

      mQuestion.c_insurances.map((qInsurance) => {
        if (qInsurance._id === insurance._id) {
          qMatchingInsurances++;
        }
      });

      if (mQuestion.c_insurances.length == 0) {
        qMatchingInsurances++;
      }

      if (mQuestion.c_hcpcs_codes.length == 0) {
        qMatchingCodes++;
      }
    });

    if (qMatchingCodes > 0 && qMatchingInsurances > 0) {
      return true;
    } else {
      return false;
    }
  }

  // Adding Forms based on question response
  processQuestionResponse(modifierId, lineIndex, modifiers, response) {
    // Process Attaching Modifiers
    response.selected.c_attaching_modifier_list.map((attachingModifier) => {
      const entireModifier = this.allModifiers[attachingModifier._id];
      // console.log("Entire Modifier: ", entireModifier);
      if (
        this.hasAplicableQuestions(entireModifier, lineIndex) &&
        entireModifier._id != modifierId
      ) {
        // console.log("Has questions, not the same");
        this.attachSpecificModifier(lineIndex, attachingModifier);
        modifiers.push(entireModifier);
      } else if (entireModifier._id != modifierId) {
        // console.log("NO questions, different modifier");
        if (this.modifierAttached(lineIndex, attachingModifier)) {
          // console.log("Already attached... Checking!")
          this.checkSpecificModifier(lineIndex, attachingModifier);
        } else {
          // console.log("Not attached... adding!");
          this.populateSpecificModifier(lineIndex, attachingModifier);
        }
      } else if (entireModifier._id === modifierId) {
        // console.log("Same Modifier!");

        this.orderLineItems[lineIndex].c_modifier_details.map((modifier) => {
          if (modifier._id === entireModifier._id) {
            // console.log("Found to check!");
            modifier.c_checked = true;
          }
        });

        this.orderLineItems[lineIndex].c_modifier.map((m) => {
          // console.log("???", m);
          if (m.c_modifier_ref === entireModifier._id) {
            // console.log("Fount u: ", m);
            m.c_checked = true;
          }
        });
      }
    });

    // Process Attaching Forms
    // console.log("Attaching forms: ", response.selected.c_attaching_form_list);
    response.selected.c_attaching_form_list.map((attachingForm) => {
      const alreadyAdded = this.modifierForms.filter(
        (mf) =>
          mf.c_modifier_form.c_form._id === attachingForm._id && !mf.deleted
      );
      const formLanguage = attachingForm.c_language;
      let orderLanguage = '';
      // determine order language
      if (this.state.is('forms.ordersCreate')) {
        orderLanguage = this.chosenLanguage.label.toLowerCase();
      } else if (this.state.is('forms.ordersEdit')) {
        orderLanguage = this.order.c_language.toLowerCase();
      }
      // const orderLanguage = this.chosenLanguage.label.toLowerCase()
      const formBaseName = attachingForm.c_base_name;
      let populateOtherLanguage = false;
      let olForm = null;
      let addedBaseName = false;

      if (formBaseName) {
        const present = this.modifierForms.filter(
          (mf) =>
            mf.c_modifier_form.c_form.c_base_name != undefined &&
            mf.c_modifier_form.c_form.c_base_name.toLowerCase() ==
              formBaseName.toLowerCase() &&
            !mf.deleted
        );

        if (present.length) {
          addedBaseName = true;
        }
      }
      // console.log("All forms loaded? ", this.allFormsMedable)
      if (
        formBaseName &&
        formLanguage &&
        formLanguage.toLowerCase() != orderLanguage
      ) {
        // console.log("formBaseName (question): ", formBaseName)
        // console.log("formLanguage (question): ", formLanguage)
        // console.log("orderLanguage (question): ", orderLanguage)
        const olForms = this.allFormsMedable.filter((f) => {
          // console.log("f: ", f)
          if (
            f.c_language &&
            f.c_language.toLowerCase() == orderLanguage &&
            f.c_base_name.toLowerCase() == formBaseName.toLowerCase()
          ) {
            return true;
          }
        });

        // console.log("olForms: ", olForms)

        if (olForms.length) {
          olForm = olForms[0];
          const olFormAlreadyAdded = this.modifierForms.filter(
            (olmf) =>
              olmf.c_modifier_form.c_form._id == olForm._id && !olmf.deleted
          );

          if (!olFormAlreadyAdded.length) {
            // console.log("Same form in different language available(question response) => Attaching...");
            populateOtherLanguage = true;
          }
        }
      }

      if (!alreadyAdded.length && !populateOtherLanguage && !addedBaseName) {
        let itemByIdx = this.orderLineItems[this.orderLineItems.length - 1],
          itemHcpcs = itemByIdx.c_selected_hcpcs;
        console.log('Item by idx: ', itemByIdx);
        // console.log("Form not added, adding form( based on question response): ", attachingForm);
        this.modifierForms.push({
          c_modifier_form: {
            c_form: attachingForm,
          },
          c_selected_hcpcs: itemHcpcs || '',
          c_product: this.orderLineItems[this.orderLineItems.length - 1]
            .c_product,
        });
        this.getModifierFormQuestions(
          attachingForm._id,
          this.modifierForms.length - 1
        );
      } else if (populateOtherLanguage && !addedBaseName) {
        this.modifierForms.push({
          c_modifier_form: {
            c_form: olForm,
          },
          c_product: this.orderLineItems[this.orderLineItems.length - 1]
            .c_product,
        });
        this.getModifierFormQuestions(
          olForm._id,
          this.modifierForms.length - 1
        );
      }
    });
  }

  // Attaching ruleforms based on hcpcs & insurances
  getRuleForms(lineIndex) {
    // console.log("Getting Rule Forms");
    const product = this.orderLineItems[lineIndex].c_product;
    const filter = `where={"c_hcpcs_code.c_hcpcs_code":"${product.c_hcpcs_code}"}`;
    const expand =
      'expand[]=c_hcpcs_codes.c_hcpcs_code&expand[]=c_insurances.c_insurance&expand[]=c_locations.c_location';
    const query = `?${expand}`;

    this.formsService.get('forms', query).subscribe((allForms) => {
      console.log('Allforms: ', allForms);
      allForms.data.map((form) => {
        if (form.c_hcpcs_codes != undefined) {
          const matchingHcpcs = form.c_hcpcs_codes.filter(
            (codeObj) =>
              codeObj.c_hcpcs_code.c_hcpcs_code.toLowerCase() ===
              product.c_hcpcs_code.toLowerCase()
          );
          const matchingInsurance =
            form.c_insurances.filter(
              (ins) =>
                ins.c_insurance._id ===
                this.selectedInsurances.primary.document._id
            ).length > 0;

          const matchingLocation =
            form.c_locations.filter(
              (loc) =>
                loc.c_location._id === this.selectedPhysician.c_location._id
            ).length > 0;

          // console.log("Order's location: ", this.selectedPhysician)

          const allCodes = form.c_all_hcpcs_codes
            ? form.c_all_hcpcs_codes
            : false;
          const alreadyAdded =
            this.modifierForms.filter(
              (mf) => mf.c_modifier_form.c_form._id == form._id && !mf.deleted
            ).length > 0;
          const allInsurances = form.c_insurances.length === 0;
          const allLocations = form.c_locations.length === 0;
          const formLanguage = form.c_language;
          let orderLanguage = '';

          if (this.state.is('forms.ordersCreate')) {
            orderLanguage = this.chosenLanguage.label.toLowerCase();
          } else if (this.state.is('forms.ordersEdit')) {
            orderLanguage = this.order.c_language.toLowerCase();
          }

          const formBaseName = form.c_base_name;
          let populateOtherLanguage = false;
          let olForm = null;
          // Check if a form with the same base name is already added
          let addedBaseName = false;

          if (formBaseName) {
            const present = this.modifierForms.filter(
              (mf) =>
                mf.c_modifier_form.c_form.c_base_name &&
                mf.c_modifier_form.c_form.c_base_name.toLowerCase() ==
                  formBaseName.toLowerCase() &&
                !mf.deleted
            );

            if (present.length) {
              addedBaseName = true;
            }
          }
          // console.log("formBaseName: ", formBaseName)
          // console.log("formLanguage: ", formLanguage)
          // console.log("orderLanguage: ", orderLanguage)
          // Check if form language is different from order language and add form with the good language
          if (
            formBaseName &&
            formLanguage &&
            formLanguage.toLowerCase() != orderLanguage
          ) {
            const olForms = allForms.data.filter(
              (f) =>
                f.c_language &&
                (f.c_language || '').toLowerCase() == orderLanguage &&
                (f.c_base_name || '').toLowerCase() ==
                  formBaseName.toLowerCase()
            );

            if (olForms.length) {
              olForm = olForms[0];
              const olFormAlreadyAdded = this.modifierForms.filter(
                (olmf) =>
                  olmf.c_modifier_form.c_form._id == olForm._id && !olmf.deleted
              );

              if (!olFormAlreadyAdded.length) {
                // console.log("Same form in different language available(hcpcs & insurance based...) => Attaching...");
                populateOtherLanguage = true;
              }
            }
          }

          if (
            !alreadyAdded &&
            (matchingHcpcs.length || allCodes) &&
            (matchingLocation || allLocations) &&
            (matchingInsurance || allInsurances) &&
            !populateOtherLanguage &&
            !addedBaseName
          ) {
            console.log('##ADDING FORM TO ORDER: ', form);
            console.log('###ITEM: ', this.orderLineItems[lineIndex]);
            this.modifierForms.push({
              c_modifier_form: {
                c_form: form,
              },
              c_product: product,
              c_selected_hcpcs:
                this.orderLineItems[lineIndex].c_selected_hcpcs || '',
              c_item_internal_id: this.orderLineItems[lineIndex]
                .c_line_internal_id,
            });
            console.log('Attached form to order: ', this.modifierForms);
            this.handleReceiptForm(form);
            this.getModifierFormQuestions(
              form._id,
              this.modifierForms.length - 1
            );
          } else if (
            populateOtherLanguage &&
            !alreadyAdded &&
            (matchingHcpcs.length || allCodes) &&
            (matchingLocation || allLocations) &&
            (matchingInsurance || allInsurances) &&
            !addedBaseName
          ) {
            // console.log(
            //   "** OTHER LANGUAGE Adding form to order: ",
            //   olForm.c_name
            // );

            this.modifierForms.push({
              c_modifier_form: {
                c_form: olForm,
              },
              c_product: product,
              c_selected_hcpcs:
                this.orderLineItems[lineIndex].c_selected_hcpcs || '',
              c_item_internal_id: this.orderLineItems[lineIndex]
                .c_line_internal_id,
            });

            console.log('Added Modifieder form: ', this.modifierForms);
            this.handleReceiptForm(olForm);
            this.getModifierFormQuestions(
              olForm._id,
              this.modifierForms.length - 1
            );
          } else {
            let checkForms = [
              '5c2d0864243694010084569a',
              '5c2d086f60fd4201008ffc3e',
              '5c2d086f60fd4201008ffc40',
              '5d5b0868404f5c01000f163c',
            ];
          }
        }
      });
    });
  }

  handleReceiptForm = (form) => {
    // console.log("Attaching Form: ", form)
    let formName = form.c_name.toLowerCase(),
      isReceiptForm = formName.indexOf('receipt') > -1;

    if (isReceiptForm) {
      // console.log("*****Receipt Form Added...");
      this.receiptAssociatedToClinic = true;
    }
  };

  getFormHcpcsList(form) {
    const matchingCodesList = [];
    const allCodes = form.c_all_hcpcs_codes ? form.c_all_hcpcs_codes : false;

    // console.log("Current OrderLineItems: ", this.orderLineItems);

    this.orderLineItems
      .filter((oli) => !oli.deleted)
      .map((oli) => {
        const productCode = oli.c_product.c_hcpcs_code;
        const productCodeId = this.allHcpcsMedable
          .filter((c) => c.c_hcpcs_code == productCode)
          .map((co) => co._id)[0];
        const permitsAllCodes = form.c_all_hcpcs_codes
          ? form.c_all_hcpcs_codes
          : false;
        const isMatchingCode =
          form.c_hcpcs_codes.filter(
            (fco) => fco.c_hcpcs_code.c_hcpcs_code == productCode
          ).length > 0;
        const alreadyAdded =
          matchingCodesList.filter((mco) => mco.c_hcpcs_code == productCodeId)
            .length > 0;

        if ((permitsAllCodes || isMatchingCode) && !alreadyAdded) {
          matchingCodesList.push({
            c_hcpcs_code: productCodeId,
          });
        }
      });

    // console.log("MathingCodes: ", matchingCodesList);

    return matchingCodesList;
  }

  canAttachRuleForm(form) {
    const alreadyAdded = this.modifierForms.filter(
      (mf) => mf.c_modifier_form.c_form._id === form._id
    );
    // console.log("Can attach: ", form);
    return alreadyAdded.length === 0;
  }

  getModifierFormQuestions(formId, formIndex) {
    this.modifierForms[formIndex].c_responses = [];
    const filter = 'where={"c_form":"' + formId + '"}';
    const query = '?' + filter;

    this.formsService.get('questions', query).subscribe((questions) => {
      questions.data.map((question) => {
        this.modifierForms[formIndex].c_responses.push({
          c_question: question,
        });
      });
    });
  }

  formAttachable(form) {
    const alreadyAdded =
      this.orderForms.filter(
        (af) => af.c_inventory_form.c_form._id === form._id
      ).length > 0;

    if (!alreadyAdded) {
      return true;
    }

    return false;
  }

  displayModifier(modifier) {
    console.log('Modif: ', modifier);
  }

  attachModifier(lineIndex, modifiers, attachingModifier) {
    const attachingEntireModifier = {
      ...this.allModifiers[attachingModifier._id],
    };
    // console.log("Adding:", attachingEntireModifier);
    // attachingEntireModifier.c_checked = true;
    this.orderLineItems[lineIndex].c_modifier_details.push(
      attachingEntireModifier
    );
    this.orderLineItems[lineIndex].c_modifier.push({
      c_modifier: attachingEntireModifier.c_name,
      c_checked: false,
      c_modifier_ref: attachingEntireModifier._id,
    });
    // console.log("Line: ", this.orderLineItems[lineIndex]);
    if (this.allModifiers[attachingModifier._id].questions.length) {
      modifiers.push(attachingEntireModifier);
    }
  }

  modifierAttachable(modifiers, attachingModifier) {
    // Modifier is attachable if:
    // 1.) It is no already present in the modifiers list &&
    // 2.) Is applicable for all Insurances OR It has the selected insurance
    const entireModifier = { ...this.allModifiers[attachingModifier._id] };
    const existingModifiers = modifiers.filter(
      (existingModifier) => existingModifier._id === entireModifier._id
    );
    const forAllInsurances = entireModifier.c_insurances.length === 0;
    const matchingInsurances = entireModifier.c_insurances.filter(
      (ami) => ami._id === this.selectedInsurances.primary.document._id
    );

    return (
      !existingModifiers.length &&
      (forAllInsurances || matchingInsurances.length)
    );
  }

  getAllPatients() {
    // console.log("Initiating loading all patients...");
    this.continueModal = true;
    //this.openModal();
    this.patientService.getAllPatients().subscribe((response) => {
      this.allPatients = response;
      this.continueModal = false;
      // console.log("All Patients Loaded!", this.allPatients);
    });
  }

  getAllInsurances() {
    this.insuranceService.getAllInsurances().subscribe((response) => {
      // console.log("All Insurances: ", this.allInsurances);
      this.allInsurances = response;
    });
  }

  getUsersLocations() {
    const reqList = [];
    this.formsService.get('accounts').subscribe((account) => {
      let fitterId = account.data[0]._id,
        resource = 'account_locations',
        paths = `paths[]=c_location`,
        expand = `expand[]=c_location`,
        filter = `where={"c_fitter" : "${fitterId}"}`,
        query = `?${paths}&${expand}&${filter}`;

      this.formsService.get(resource, query).subscribe((locations) => {
        // console.log("User's Locations: ", locations)
        const locationIds = locations.data.map(
          (obj) => `"${obj.c_location._id}"`
        );
        // console.log("User's location ids: ", locationIds)

        this.insuranceService
          .getInsurancesBasedOnLocations(locationIds)
          .subscribe((insurances) => {
            // console.log("Available insurances: ", insurances)
            this.availableInsurances = insurances.data.map(
              (obj) => obj.c_insurance
            );
          });
      });
    });
  }

  getAllModifiers() {
    this.allModifiers = [];
    this.modifierService.getAllModifiersMedable().subscribe((modifiers) => {
      let expectedModifiers = modifiers.length;

      modifiers.map((modifier) => {
        this.modifierService
          .getEntireModifier(modifier._id)
          .subscribe((entireModifier) => {
            this.allModifiers[modifier._id] = entireModifier;
            this.allModifiersTmp.push(entireModifier);
            expectedModifiers--;
          });
      });
    });
  }

  selectPatient(patient) {
    this.order.c_patient = patient;
    this.dateOfBirthModel = this.dateService.toDatepickerModel(
      new Date(patient.c_dob)
    );
    this.patientSelected = true;
  }

  emptyOrder() {
    this.order = {
      c_number_id: ' ',
      c_patient: {
        c_name: {
          c_first_name: '',
        },
      },
      c_provider_location: {
        c_physician: {
          c_name: {},
        },
        c_location: {},
      },
      c_insurance: {},
    };
  }

  removeProductResponse(answer) {
    if (this.orderLineItems[answer.data]._id) {
      this.orderLineItems[answer.data].delete = true;
    } else {
      const inventoryId = this.orderLineItems[answer.data].c_product._id;
      this.orderLineItems.splice(answer.data, 1);

      this.orderForms = this.orderForms.filter(function (obj) {
        return obj.c_inventory_form.c_product._id !== inventoryId;
      });
    }
  }

  populateOrder() {
    if (this.orderhasSignature) {
      console.log('Populating order w signature');
      this.orderService
        .getOrderSignatureContent(this.orderId)
        .subscribe((image) => {
          this.orderSignatureUrl = image.url;
          const self = this;
          this.http
            .get<any>(this.orderSignatureUrl, this.httpAux)
            .subscribe((awsImage) => {
              const reader = new FileReader();
              reader.onloadend = function () {
                self.orderSignatureSrc = reader.result;
                self.orderPDF.populateOrderFinal(
                  self.orderSignatureSrc,
                  self.order,
                  self.orderForms,
                  self.orderLineItems.filter((oi) => !oi.c_deleted),
                  self.modifierForms
                );
              };
              reader.readAsDataURL(awsImage);
            });
        });
    } else {
      console.log('Populating order no signature');
      this.orderPDF.populateOrderFinal(
        false,
        this.order,
        this.orderForms,
        this.orderLineItems.filter((oi) => !oi.c_deleted),
        this.modifierForms
      );
    }
  }

  printAllDocs() {
    this.populateOrder();
    this.modifierForms.map((f, i) => {
      this.populateModifierForm(i);
    });
  }

  goToEditOrder(orderId) {
    this.router.navigate(['forms/orders/:id/edit', { id: orderId }]);
  }

  goToViewOrder(orderId) {
    this.router.navigate(['forms/orders/:id/view', { id: orderId }]);
  }

  goToEditOrderForm(orderId, formId) {
    this.router.navigate([
      'forms/orders/:id/edit/orderForm',
      { id: orderId, formId },
    ]);
  }

  goToEditRuleForm(orderId, formId) {
    this.router.navigate([
      'forms/orders/:id/edit/ruleForm',
      { id: orderId, formId },
    ]);
  }

  gotoOrdersIndex() {
    this.router.navigate(['/forms/orders/index']);
  }

  goToCreateOrder() {
    this.router.navigate(['forms/orders/new']);
  }

  searchPatientListWrapper() {
    let searchPatientList = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const patients = this.allPatients.filter(
              (v) => v.c_mrn.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            return patients.length ? patients.slice(0, 10) : [];
          }
        })
      );
    };

    return searchPatientList;
  }

  searchPatientListWrapper2() {
    let searchPatient = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.searchingPatient = true;
        }),
        switchMap((term) =>
          this.patientService
            .searchPatients(term)
            .pipe(
              map((response) => {
                // console.log("search results patient...", response)
                return response.data.sort( (a,b) => a.c_mrn.length - b.c_mrn.length);
              })
            )
            .pipe(
              tap((icds) => {
                this.searchPatientsFailed = false;
              }),
              catchError(() => {
                this.searchPatientsFailed = true;
                return new Observable((observer) => {
                  observer.next();
                });
              })
            )
        ),
        tap(() => (this.searchingPatient = false))
      );
    };

    return searchPatient;
  }

  searchFitterWrapper() {
    let searchFitterList = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const firstName = this.allFitters.filter((fitter) => {
              if (fitter.firstName) {
                return (
                  fitter.firstName.toLowerCase().indexOf(term.toLowerCase()) >
                  -1
                );
              } else return;
            });
            const lastName = this.allFitters.filter((fitter) => {
              if (fitter.lastName) {
                return (
                  fitter.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
                );
              } else return;
            });
            const email = this.allFitters.filter((fitter) => {
              if (fitter.email) {
                return (
                  fitter.email.toLowerCase().indexOf(term.toLowerCase()) > -1
                );
              } else return;
            });
            return (email.length
              ? email
              : lastName.length
              ? lastName
              : firstName.length
              ? firstName
              : []
            ).slice(0, 50);
          }
        })
      );
    };

    return searchFitterList;
  }

  searchIcdWrapper(excludeIdx) {
    let searchIcd = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.searching[excludeIdx] = true;
        }),
        switchMap((term) =>
          this.icd10Service
            .searchIcd10sInput(term)
            .pipe(
              map((response) => {
                return response.data
                  .filter(
                    (icd10) =>
                      this.orderLineItems[excludeIdx].c_icd_10.filter(
                        (addedIcd) => addedIcd._id == icd10._id
                      ).length == 0
                  )
                  .slice(0, 10);
              })
            )
            .pipe(
              tap((icds) => {
                this.searchFailed = false;
              }),
              catchError(() => {
                this.searchFailed = true;
                return new Observable((observer) => {
                  observer.next();
                });
              })
            )
        ),
        tap(() => (this.searching[excludeIdx] = false))
      );
    };

    return searchIcd;
  }

  searchPhysicianWrapper() {
    //console.log("test");
    let searchPatientList = (text$: Observable<string>) => {
      //console.log("searching privders...");
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            let search_terms = term.split(' ');
            // console.log("Search term: ", search_terms);
            // console.log("this.physicians: ", this.physicians);
            let physicians = this.physicians.filter(
              (v) =>
                search_terms.filter(
                  (st) =>
                    v.c_physician.c_name.c_first_name
                      .toLowerCase()
                      .indexOf(st.toLowerCase()) > -1 && st != ''
                ).length +
                  search_terms.filter(
                    (st) =>
                      v.c_physician.c_name.c_last_name
                        .toLowerCase()
                        .indexOf(st.toLowerCase()) > -1 && st != ''
                  ).length >=
                search_terms.length
            );

            // console.log("Filtered phisicians: ", physicians);
            let distincts = [];

            physicians.map((physician) => {
              let exists = 0;
              distincts.map((dPhysician) => {
                if (dPhysician.c_physician._id == physician.c_physician._id) {
                  exists = 1;
                }
              });

              if (!exists) {
                distincts.push(physician);
              }
            });
            // console.log("Distinct: ", distincts.slice(0, 10));
            return distincts.length ? distincts.slice(0, 10) : [];
          }
        })
      );
    };

    return searchPatientList;
  }

  searchInsuranceWrapper() {
    let searchInsurances = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.allInsurances.filter(
              (v) =>
                v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                -1
            );
            return insurances.length ? insurances.slice(0, 10) : [];
          }
        })
      );
    };

    return searchInsurances;
  }

  searchProductWrapper() {
    // console.log("searching product");
    let searchProduct = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const products = this.products.filter(
              (v) =>
                (v.c_product.cpy_product_option.c_code
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1 ||
                  v.c_product.c_name
                    .toLowerCase()
                    .indexOf(term.toLocaleLowerCase()) > -1) &&
                (true ||
                  (v.c_product.c_modifier_doc != undefined &&
                    v.c_product.c_modifier_doc.length > 0 &&
                    v.c_product.c_modifier_doc.filter(
                      (md) => md.c_modifier != undefined
                    ).length > 0))
            );
            // console.log("Found products: ", products);
            return products.length ? products.slice(0, 10) : [];
          }
        })
      );
    };

    return searchProduct;
  }

  icdFormatter = () => '';
  productFormatter = () => '';

  patientListFormatter = (x: {
    c_mrn: string;
    c_name: {
      c_first_name: string;
      c_last_name: string;
    };
  }) => {
    if (x.c_mrn === '') {
      return '';
    } else {
      return x.c_mrn + ' ' + x.c_name.c_first_name + ' ' + x.c_name.c_last_name;
    }
  };

  insuranceFormatter = (x: { c_insurance_name: string }) => x.c_insurance_name;

  physicianFormatter = (x: {
    c_physician: {
      c_name: {
        c_first_name: string;
        c_last_name: string;
      };
      c_preffix: string;
      c_suffix: string;
    };
  }) =>
    x.c_physician.c_name.c_first_name
      ? x.c_physician.c_preffix +
        ' ' +
        x.c_physician.c_name.c_first_name +
        ' ' +
        x.c_physician.c_name.c_last_name +
        ' ' +
        x.c_physician.c_suffix
      : '';

  fitterFormatter = (x: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  }) => (x.id != '' ? `${x.firstName} ${x.lastName}` : '');

  /**
   * Add product to the order.
   * @param product - Product object.
   */
  addProduct(product) {
    // console.log("Adding product: ", product);

    let prodObj = product.c_product,
      prodHCPCS = prodObj.c_hcpcs_code,
      multipleHCPCS = prodHCPCS.indexOf('/') > -1,
      availableHCPCS = prodHCPCS.split('/');

    let lineId = moment().utc().valueOf();

    // Check if prod has multiple hcpcs codes.
    const orderLineNew = {
      c_quantity: 1,
      c_allowable_cost: 0.0,
      c_self_pay_cost: 0.0,
      c_charge_out_cost: 0.0,
      c_sales_tax: 0.0,
      c_product: prodObj,
      c_modifier: [],
      c_insurance_name: this.selectedInsurances.primary.document
        .c_insurance_name,
      c_icd_10: [],
      c_product_option_code: prodObj.cpy_product_option.c_code,
      edit: true,
      c_invoiced: false,
      c_invoice_number: '',
      c_payment_method: 0,
      c_check_number: '',
      c_card_type: '',
      c_card_number: '',
      c_approval_code: '',
      c_multiple_hcpcs: multipleHCPCS,
      availableHCPCS,
      c_selected_hcpcs: '',
      c_line_internal_id: lineId,
    };

    this.orderLineItems.push(orderLineNew);
    this.addModifiers(this.orderLineItems.length - 1);
    this.getRuleForms(this.orderLineItems.length - 1);
    // console.log("Lines: ", this.orderLineItems);
  }

  // populateOrderForm(index) {
  //   console.log("Index: ", index);
  //   const inventoryId = this.orderForms[index].c_inventory_form.c_product._id;
  //   // console.log("InventoryId: ", this.orderForms[index].c_inventory_form);
  //   this.formService.getPDFForm(this.orderForms[index].c_inventory_form.c_form.c_name, {
  //     order: this.order,
  //     orderForm: this.orderForms[index],
  //     orderLine: this.orderLineItems.filter( obj => (obj.c_product._id === inventoryId))[0]
  //   });
  // }

  isNoticeForm = (modifierForm) => {
    const formName = modifierForm.c_form.c_name;

    if (
      formName.toLowerCase().indexOf('notice') > -1 &&
      formName.toLowerCase().indexOf('patient') > -1
    ) {
      return true;
    }
    return false;
  };

  isABNForm = (modifierForm) => {
    const formName = modifierForm.c_form.c_name;

    if (formName.toLowerCase().indexOf('abn') > -1) {
      return true;
    }
    return false;
  };

  populateModifierForm(index) {
    // console.log("Getting Patient Warranty Form: ");
    const pWarrantyForm = this.returnPatientWarrantyForm();
    // console.log("warrantyForm: ", pWarrantyForm);
    const printingForm = this.modifierForms[index];
    // console.log("Printing form: ", printingForm);
    const inventoryId = printingForm.c_product._id;
    let orderLine = this.orderLineItems
      .filter((oi) => !oi.c_deleted)
      .filter((obj) => obj.c_product._id === inventoryId)[0];

    // If there is no orderItem with the form's product id, it was most likelly, swapped with another item, for ABN and Patient Warranty forms we can just add any item from the item list to replace it
    if (
      (!orderLine || orderLine == undefined) &&
      (this.isNoticeForm(printingForm.c_modifier_form) ||
        this.isABNForm(printingForm.c_modifier_form))
    ) {
      orderLine = this.orderLineItems.filter((oi) => !oi.c_deleted)[0];
    }

    const hcpcsCodeList = printingForm.c_attached_hcpcs_codes;
    const formName = this.modifierForms[index].c_modifier_form.c_form.c_name;
    const formId = this.modifierForms[index].c_modifier_form.c_form._id;

    if (printingForm.c_form_signature) {
      this.loadRuleFormSignature(printingForm).subscribe(() => {
        //console.log("Form Signature Loaded: ", this.ruleFormSignature);
        this.continuePrintingForm(
          this.ruleFormSignature,
          formId,
          formName,
          index,
          orderLine,
          hcpcsCodeList,
          pWarrantyForm
        );
      });
    } else {
      this.continuePrintingForm(
        false,
        formId,
        formName,
        index,
        orderLine,
        hcpcsCodeList,
        pWarrantyForm
      );
    }
  }

  // findItemMatchingForm = (printingForm) => {
  //   console.log("Printing form: ", printingForm)
  // }

  continuePrintingForm(
    formSignature,
    formId,
    formName,
    index,
    orderLine,
    hcpcsCodeList,
    pWarrantyForm
  ) {
    this.formService.getPDFForm(formSignature, formId, formName, this.allFitters, {
      order: this.order,
      orderForm: this.modifierForms[index],
      orderLine,
      // Filter lineItems to avoid deleted lines.
      lineItems: this.orderLineItems.filter((oi) => !oi.c_deleted),
      hcpcsList: hcpcsCodeList,
      warrantyForm: pWarrantyForm,
    });
  }

  cardNrChanged = (e, line) => {
    console.log('Event: ', e);
    console.log('Line: ', line);
    if (line.c_card_number.length >= 4) {
      console.log('card nr: ', line.c_card_number);
      e.preventDefault();
      return false;
    }

    // if (e.length > 4) {
    //   // console.log("length bigger")
    //   // this.orderLineItems[k].c_card_number = e.substring(0, 4);
    //   // e = e.substring(0, 4);
    //   // console.log("Card nr Model: ", this.orderLineItems[k].c_card_number)

    //   console.log("line: ", line)
    //   return;
    // }
  };

  returnPatientWarrantyForm() {
    const warrantyForm = this.modifierForms.filter(
      (mf) =>
        mf.c_modifier_form.c_form.c_name.toLowerCase().indexOf('patient') >
          -1 &&
        mf.c_modifier_form.c_form.c_name.toLowerCase().indexOf('warranty') > -1
    )[0];

    return warrantyForm;
  }

  // getFormQuestions(formId, orderFormIndex) {
  //   this.orderForms[orderFormIndex].c_responses = [];
  //   const filter = 'where={"c_form":"' + formId + '"}';
  //   const query = "?" + filter;
  //   this.formsService.get("questions", query).subscribe((questions) => {
  //     // console.log("Form Questions: ", questions);
  //     questions.data.map((question) => {
  //       this.orderForms[orderFormIndex].c_responses.push({
  //         c_question: question,
  //       });
  //     });
  //   });
  // }

  removeProduct(index) {
    const title = 'orders.popup.remove.line.title';
    const subtitle = 'orders.popup.remove.line.text';
    // console.log("***This modifierforms: ", this.modifierForms);

    this.showPopupWindow(title, subtitle).then((response) => {
      if (response.selected === 'LEFT') {
        if (!this.orderLineItems[index]._id) {
          this.orderLineItems.splice(index, 1);
        } else {
          if (this.order.c_draft) {
            this.proceedToRemoveProduct(index);
          } else {
            let line = this.orderLineItems[index];
            line['showDeleteReasonPopup'] = true;
          }
          // this.proceedToRemoveProduct(index);
          // this.modifierForms.map(mf => {
          //   if (mf.c_product._id == this.orderLineItems[index].c_product._id) {
          //     mf.deleted = true;
          //     this.modifierFormsDeleted.push(mf);
          //   }
          // });
          // =======
          //           this.modifierForms.map(mf => {
          //             if (
          //               mf.c_product._id == this.orderLineItems[index].c_product._id &&
          //               !this.formAnyHcpcsCode(mf)
          //             ) {
          //               mf.deleted = true;
          //               this.modifierFormsDeleted.push(mf);
          //             }
          //           });
          // >>>>>>> laszlo-staging
          // console.log("Removed product: ", this.orderLineItems[index]);
          // console.log("Forms after removing product: ", this.modifierForms);
        }
      }
    });
  }

  hideItemDeleteReason = (index) => {
    delete this.orderLineItems[index]['showDeleteReasonPopup'];
  };

  confirmRemoveItem = (index) => {
    let item = this.orderLineItems[index];
    if (!item.c_delete_reason) {
      item['itemDeleteReasonError'] = true;
      return;
    }

    delete item['showDeleteReasonPopup'];
    this.proceedToRemoveProduct(index);
    // console.log("Removing item: ", item);
  };

  proceedToRemoveProduct = (index) => {
    this.orderLineItems[index].deleted = true;

    this.removeProductsModifierForms(index);
  };

  removeProductsModifierForms(index) {
    const rProductId = this.orderLineItems[index].c_product._id;
    this.modifierForms.map((mf) => {
      let linkedToProd = mf.c_product._id == rProductId,
        remains = this.isSameProductInOrder(rProductId, index);
      if (linkedToProd && !remains) {
        console.log('Will dete form: ', mf);
        mf.deleted = true;
        this.modifierFormsDeleted.push(mf);
      }
    });
    console.log('Removed product: ', this.orderLineItems[index]);
    console.log('Forms after removing product: ', this.modifierForms);
  }

  isSameProductInOrder(removedId, removedIdx) {
    let exists = false;

    for (let i = 0; i < this.orderLineItems.length; i++) {
      let product = this.orderLineItems[i];
      if (product.c_product._id == removedId && i != removedIdx) {
        exists = true;
      }
    }

    return exists;
  }
  // =======
  //   /**
  //    * Function to determine if a modifier form can remain attached to any hcpcs code.
  //    * In this case, even if the item that attached the form to the order gets removed
  //    * the form should stay attached
  //    */
  //   formAnyHcpcsCode = modifierForm => {
  //     console.log("*** ModifierForm: ", modifierForm);
  //     let form = (modifierForm.c_modifier_form || {}).c_form || {},
  //       anyHcpcs = form.c_all_hcpcs_codes;

  //     console.log("*** Form: ", form);
  //     console.log("*** For any: ", anyHcpcs);
  //     return anyHcpcs;
  //   };
  // >>>>>>> laszlo-staging

  addIcd10OrderLine(index, item) {
    this.orderLineItems[index].c_icd_10.push(item);
  }

  removeIcd10OrderLine(lineIdex, icdIndex) {
    this.orderLineItems[lineIdex].c_icd_10.splice(icdIndex, 1);
  }

  cancel() {
    const title = 'orders.popup.cancel.title';
    const subtitle = 'orders.popup.cancel.text';
    this.showPopupWindow(title, subtitle).then((response) => {
      if (response.selected === 'LEFT') {
        this.gotoOrdersIndex();
      }
    });
  }

  showPopupWindow(popupTitle, popupText) {
    const info = {
      title: popupTitle,
      subtitle: popupText,
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };

    let modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  customPopupWindow(info) {
    let modalInstance = this.modalService.open(CustomPopupComponent);
    this.sharedService.setPopupInfo(info);
    return modalInstance.result;
  }

  physicianInputFocus() {
    this.selectedPhysician = {
      c_physician: {
        c_name: {
          c_first_name: '',
          c_last_name: '',
        },
        c_preffix: '',
        c_suffix: '',
      },
      c_location: {
        c_company: '',
      },
    };

    this.physicianSelected = false;
    this.locationSelected = false;
    this.chosenLocation = {};
  }

  fitterInputFocus() {
    this.selectedPhysician = {
      c_physician: {
        c_name: {
          c_first_name: '',
          c_last_name: '',
        },
        c_preffix: '',
        c_suffix: '',
      },
      c_location: {
        c_company: '',
      },
    };

    this.physicianSelected = false;
  }

  designatedFitterInputFocus() {
    this.selectedFitter = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
    };
    this.fitterSelected = false;
  }

  designatedFitterInputFocusOut() {
    if (!this.fitterSelected) {
      this.selectedFitter = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
      };
    }
  }

  physicianInputFocusOut() {
    setTimeout(() => {
      if (!this.physicianSelected) {
        this.selectedPhysician = {
          c_physician: {
            c_name: {
              c_first_name: '',
              c_last_name: '',
            },
            c_preffix: '',
            c_suffix: '',
          },
          c_location: {
            c_company: '',
          },
        };
      }
    }, 500);
  }

  patientListInputFocus() {
    this.patientListInput = {
      c_name: {
        c_first_name: '',
        c_last_name: '',
      },
      c_mrn: '',
      c_dob: '',
    };

    this.order.c_patient = this.patientListInput;
    this.patientSelected = false;
  }

  patientListInputFocusOut() {
    setTimeout(() => {
      if (!this.patientSelected) {
        this.patientListInput = {
          c_name: {
            c_first_name: '',
            c_last_name: '',
          },
          c_mrn: '',
        };
      }
    }, 500);
  }

  insuranceInputFocus(insuranceType) {
    this.selectedInsurances[insuranceType].document = {};
    this.selectedInsurances[insuranceType].insuranceSelected = false;
  }

  selectInsurance(insurance, insuranceType) {
    this.selectedInsurances[insuranceType].insuranceSelected = true;
    this.selectedInsurances[insuranceType].document = { ...insurance };
  }

  insuranceInputFocusOut(insuranceType) {
    setTimeout(() => {
      if (!this.selectedInsurances[insuranceType].insuranceSelected) {
        this.selectedInsurances[insuranceType].document = {};
      }
    }, 500);
  }

  selectFitter(fitter) {
    // console.log("Selected Fitter", fitter);
    this.selectedFitter = fitter;
    this.fitterSelected = true;
  }

  // fitterInputFocus() {

  // }

  selectPhysician(physician) {
    this.selectedPhysician = physician;
    this.physicianSelected = true;
    this.formatLocations(physician);
    // this.getProducts();
  }

  formatLocations(physician) {
    // console.log("Formatting Locations: ", physician);
    const availableLocations = this.physicians.filter(
      (p) => p.c_physician._id === physician.c_physician._id
    );

    this.availableBillingProviders = physician.c_physician.c_billing_providers.map(
      (bp) => {
        bp.c_provider.displayName = this.buildProviderDisplayName(
          bp.c_provider
        );
        return bp;
      }
    );
    // console.log("BillingProviders: ", this.availableBillingProviders);
    if (availableLocations.length > 1) {
      this.multipleLocations = true;
      this.availableLocations = [...availableLocations];
      // this.chosenLocation = physician;
    } else {
      this.multipleLocations = false;
      this.getProducts();
    }

    if (this.availableBillingProviders.length > 1) {
      this.multipleBillingProviders = true;
    } else if (this.availableBillingProviders.length == 1) {
      this.multipleBillingProviders = false;
      this.selectedBillingProvider = this.availableBillingProviders[0].c_provider;
      this.selectedBillingProvider.displayName = this.buildProviderDisplayName(
        this.selectedBillingProvider
      );
    }

    // console.log("Available p. locations: ", availableLocations);
  }

  buildProviderDisplayName(provider) {
    if (!provider.c_name) {
      return '-';
    }
    const nameObj = provider.c_name,
      prefix = provider.c_preffix ? `${provider.c_preffix} ` : '',
      firstName = nameObj.c_first_name,
      lastName = nameObj.c_last_name,
      c_suffix = provider.c_suffix ? ` ${provider.c_suffix}` : '',
      displayName = `${prefix}${firstName} ${lastName}${c_suffix}`;

    return displayName;
  }

  locationChosen() {
    // console.log("New location: ", this.chosenLocation);
    this.selectedPhysician = this.chosenLocation;
    this.locationSelected = true;
    this.getProducts();
  }

  billingProviderChosen() {
    //console.log("New location: ", this.chosenBillingProvider);
    this.selectedBillingProvider = this.chosenBillingProvider;
  }

  editOrderLine(index) {
    this.orderLineItems[index].edit = true;
  }

  finishEditOrderLine(index) {
    this.orderLineItems[index].edit = false;
  }

  checkForErrorsStepOne() {
    /* first check if the patient was selected from the search input */
    if (this.patientSelected) {
      this.duplicatedError = false;
      this.nextStep();
      return;
    }

    const currentPatient = this.order.c_patient;
    currentPatient.c_dob = this.dateService.format(this.dateOfBirthModel);
    this.patientService
      .searchExistingPatient(currentPatient)
      .subscribe((response) => {
        if (response.data.length) {
          this.duplicatedError = true;
        } else {
          this.duplicatedError = false;
          this.nextStep();
        }
      });
  }

  validateMultipleHCPCS = () => {
    let orderItems = this.orderLineItems.filter((oi) => !oi.deleted);
    console.log('This orderLineItems: ', orderItems);

    // Items that have multiple hcpcs codes and none was selected
    let invalidItems = orderItems.filter(
      (oi) => oi.c_multiple_hcpcs && oi.c_selected_hcpcs === ''
    );

    console.log('Invalid items: ', invalidItems);

    let hasInvalidItems = invalidItems.length > 0;

    if (hasInvalidItems) {
      this.validationError = {
        error: true,
        message: 'Please select HCPCS code where multiple available.',
      };
    } else {
      this.validationError = {
        error: false,
        message: '',
      };
    }
    return !hasInvalidItems;
  };

  nextStep() {
    console.log('Next step clicked...');
    console.log('Current step: ', this.step);

    let incrementStep = true;
    // Do necessary validation before incrementing step
    switch (this.step) {
      case 3:
        if (!this.validateMultipleHCPCS()) {
          incrementStep = false;
        }
        break;
    }

    // Don't go to the next step if there is a validation error.
    if (!incrementStep) {
      return;
    }

    this.step++;

    // Do necessary operations after incrementing step
    switch (this.step) {
      case 2:
        this.getAllInsurances();
        this.getFitterLocations();
        break;

      // case 3:

      //   if (!this.productsLoaded) {
      //     this.openModal(300);
      //     this.getProducts();
      //   }

      //   break;

      default:
        break;
    }
  }

  previousStep() {
    this.step--;
  }

  primaryInsuranceTyping() {
    const prevValue = this.selectedInsurances.primary.id;
    this.selectedInsurances.primary.id =
      prevValue && prevValue.replace(/[^a-z\d\s-]/i, '');
  }

  secondaryInsuranceTyping() {
    const prevValue = this.selectedInsurances.secondary.id;
    this.selectedInsurances.secondary.id =
      prevValue && prevValue.replace(/[^a-z\d\s-]/i, '');
  }

  onSubmit() {
    this.disableSubmitButtons = true;
    if (this.state.is('forms.ordersCreate')) {
      this.createOrder();
    } else if (this.state.is('forms.ordersEdit')) {
      this.updateOrder();
    }
  }

  draft(state: string) {
    this.disableSubmitButtons = true;
    console.log('Saving order as draft...');
    this.order.c_draft = true;
    if (state == 'create') {
      // console.log("Creating Draft Order");
      this.createOrder(true);
    } else if (state == 'edit') {
      // console.log("Editing Draft Order");
      this.updateOrder(true);
    } else if (state == 'publish') {
      // console.log("Publishing Draft");
      this.publishDraft();
    } else {
      // console.log("State ", state , " is not threated");
    }
  }

  publishDraft() {
    // console.log("Order to update - Raw: ", this.order);
    const orderToUpdate = this.formsService.removeExtraInfo({ ...this.order });
    delete orderToUpdate._id;
    delete orderToUpdate.c_fitter;
    orderToUpdate.c_patient = this.order.c_patient._id;
    orderToUpdate.c_order_date = this.dateService.format(this.orderDateModel);
    orderToUpdate.c_ordered_date = this.dateService.format(
      this.orderedDateModel
    );
    orderToUpdate.c_fitter = this.accountId;
    orderToUpdate.c_provider_location = this.selectedPhysician._id;
    orderToUpdate.c_insurance = this.selectedInsurances.primary.document._id;
    orderToUpdate.c_draft = false;
    delete orderToUpdate.c_designated_fitter;
    delete orderToUpdate.c_order_signature;

    orderToUpdate['c_created_timestamp'] = new Date().getTime();

    if (this.selectedBillingProvider._id) {
      orderToUpdate.c_billing_provider = this.selectedBillingProvider._id;
    }

    if (this.billedDateModel) {
      orderToUpdate.c_billed_date = this.dateService.format(
        this.billedDateModel
      );
      orderToUpdate['c_dispatched'] = true;
    }

    if (this.selectedInsurances.primary.id != undefined) {
      orderToUpdate[
        'c_primary_insurance_id_number'
      ] = this.selectedInsurances.primary.id;
    }

    if (this.selectedInsurances.secondary.id != undefined) {
      orderToUpdate[
        'c_secondary_insurance_id_number'
      ] = this.selectedInsurances.secondary.id;
    }

    if (this.selectedInsurances.secondary.document != undefined) {
      orderToUpdate[
        'c_secondary_insurance'
      ] = this.selectedInsurances.secondary.document._id;
    }

    orderToUpdate['c_language'] = this.chosenLanguage.label;
    this.gLastModified = new Date(Date.now()).toISOString();
    orderToUpdate['c_last_modified'] = this.gLastModified;

    let currentUid = this.authService.getAuthorizedUser()._id;
    orderToUpdate['c_last_modified_by'] = currentUid;
    console.log('Order to update: ', orderToUpdate);
    this.orderService
      .editOrder(orderToUpdate, this.orderId)
      .subscribe((response) => {
        // console.log("orderToUpdate: ", orderToUpdate);
        if (this.changeOrderSignature) {
          this.saveChangedOrderSignature();
        }
        const linesToUpdate = [
          ...this.orderLineItems.filter((oi) => !oi.deleted),
        ];

        const cin7OrdObj = { ...response };
        cin7OrdObj.c_patient = { ...this.order.c_patient };
        cin7OrdObj.c_selected_insurances = this.selectedInsurances;
        cin7OrdObj.c_selected_provider = this.selectedPhysician;

        // Update order lineItems
        this.updateOrderLineItems().subscribe((updRsp) => {
          console.log('Order lineItems updated: ', updRsp);
          let savedLines = updRsp['data'].filter((itm) => itm._id);

          this.orderService
            .createSalesOrderCin7(
              this.orderId,
              cin7OrdObj,
              savedLines,
              this.selectedPhysician.c_location
            )
            .subscribe((cin7Rsp) => {
              console.log('Cin7 Publish Draft Response: ', cin7Rsp);
              this.continueUpdateOrderFlow();
            });
        });
        this.disableSubmitButtons = false;
        // =======
        //         this.orderService
        //           .createSalesOrderCin7(
        //             this.orderId,
        //             cin7OrdObj,
        //             this.orderLineItems,
        //             this.selectedPhysician.c_location
        //           )
        //           .subscribe(cin7Rsp => {
        //             // console.log("Cin7 Publish Draft Response: ", cin7Rsp);
        //             this.continueUpdateOrderFlow();
        //           });
        // >>>>>>> laszlo-staging
      });
  }

  updateOrder(draft: boolean = false) {
    // console.log("Order to update - Raw: ", this.order);
    const orderToUpdate = this.formsService.removeExtraInfo({ ...this.order });
    delete orderToUpdate._id;
    delete orderToUpdate.c_fitter;
    delete orderToUpdate.c_billing_provider;

    orderToUpdate.c_patient = this.order.c_patient._id;
    orderToUpdate.c_order_date = this.dateService.format(this.orderDateModel);
    orderToUpdate.c_ordered_date = this.dateService.format(
      this.orderedDateModel
    );
    orderToUpdate.c_fitter = this.accountId;
    orderToUpdate.c_provider_location = this.selectedPhysician._id;
    orderToUpdate.c_insurance = this.selectedInsurances.primary.document._id;
    delete orderToUpdate.c_order_signature;
    delete orderToUpdate.c_designated_fitter;

    // if no signature, mark it as removed
    if (this.changeOrderSignature && !this.patientSignature) {
      orderToUpdate['c_signature_removed'] = true;
    } else if (this.saveChangedOrderSignature) {
      orderToUpdate['c_signature_removed'] = false;
    }

    if (this.selectedBillingProvider._id) {
      orderToUpdate.c_billing_provider = this.selectedBillingProvider._id;
    }

    if (this.billedDateModel) {
      orderToUpdate.c_billed_date = this.dateService.format(
        this.billedDateModel
      );
      if (!draft) {
        orderToUpdate['c_dispatched'] = true;
      }
    } else {
      orderToUpdate.c_billed_date = null;
      orderToUpdate['c_dispatched'] = false;
    }

    if (this.selectedInsurances.primary.id != undefined) {
      orderToUpdate[
        'c_primary_insurance_id_number'
      ] = this.selectedInsurances.primary.id;
    }

    if (this.selectedInsurances.secondary.id != undefined) {
      orderToUpdate[
        'c_secondary_insurance_id_number'
      ] = this.selectedInsurances.secondary.id;
    }

    if (this.selectedInsurances.secondary.document != undefined) {
      orderToUpdate[
        'c_secondary_insurance'
      ] = this.selectedInsurances.secondary.document._id;
    }

    this.gLastModified = new Date(Date.now()).toISOString();
    orderToUpdate['c_last_modified'] = this.gLastModified;

    let currentUid = this.authService.getAuthorizedUser()._id;
    orderToUpdate['c_last_modified_by'] = currentUid;

    this.orderService
      .editOrder(orderToUpdate, this.orderId)
      .subscribe((response) => {
        let exp =
          'expand[]=c_provider_location&expand[]=c_provider_location.c_location';
        this.formsService
          .get('orders', `/${this.order._id}?${exp}`)
          .subscribe((oupdtR) => {
            console.log('***ORder Updated: ', oupdtR);
            let newLocation = oupdtR.c_provider_location.c_location.c_cin7_id;
            //console.log("Location id: ")
            if (this.changeOrderSignature) {
              this.saveChangedOrderSignature();
            }
            const linesToUpdate = [
              ...this.orderLineItems.filter((oi) => !oi.deleted),
            ];
            // return;

            // Update orderLinteItems
            this.updateOrderLineItems(draft).subscribe((updRsp) => {
              if (draft) {
                // If draft, continue flow
                this.continueUpdateOrderFlow();
              } else {
                // If not draft, send update to cin7
                let updatedOrdItems = updRsp['data'].filter((itm) => itm._id);
                console.log('***Updated OrderItems: ', updatedOrdItems);
                // Update salesorder in cin7
                this.orderService
                  .updateSalesOrderCin7(response, updatedOrdItems, newLocation)
                  .subscribe((cin7Updated) => {
                    if (this.itemsForCN.length) {
                      this.handleCin7ItemDeletion().subscribe(() => {
                        this.continueUpdateOrderFlow();
                      });
                    } else {
                      console.log('****************No items were deleted...');
                      this.continueUpdateOrderFlow();
                    }
                  });
              }
              this.disableSubmitButtons = false;
            });
          });
      });
  }

  // In case an item was deleted.
  handleCin7ItemDeletion = () => {
    let localSubject = new Subject();

    let orderId = this.order._id,
      orderCode = this.order.c_cin7_so_id;

    console.log("****Order's lineItems for CN: ", this.itemsForCN);

    // Get Order's deleted items.
    this.orderService.getDeletedItems(orderId).subscribe((lines) => {
      // Generate list of lineItems for the creditNode.
      let lineItems = this.itemsForCN.map((line) => ({
        productId: line.c_product.c_cin7_id,
        code: line.c_product_option_code,
        qty: -1 * line.c_quantity,
      }));

      // Create Draft Credit Note body.
      let draftCN = {
        salesReference: orderCode,
        memberEmail: 'smasler@selectortho.net',
        branchId: this.order.c_provider_location.c_location.c_cin7_id,
        company: this.order.c_patient.c_mrn,
        isApproved: false,
        creditNoteDate: moment().toISOString(),
        lineItems,
      };

      // Create Draft Credit Note in Cin7.
      this.orderService.createCin7CreditNote(draftCN).subscribe((draftCNR) => {
        let cnId = draftCNR.data[0].id,
          cnCode = draftCNR.data[0].code;

        // Create a Payment in Cin7 for the Draft Credit Note.
        this.orderService.createCin7Payment(cnId, orderCode).subscribe((pR) => {
          // Approved Credit Note body.
          let cnBody = {
            id: cnId,
            approved: true,
            completedDate: moment().toISOString(),
          };

          // Update and approve Draft Credit Note in Cin7.
          this.orderService.updateCin7CreditNote(cnBody).subscribe((cnData) => {
            // Update order in Medable with creditNote id and code

            let ordData = {
              c_credit_node_id: cnId,
              c_credit_node_code: cnCode,
              c_deleted_items: lineItems.length > 0,
            };
            this.orderService.editOrder(ordData, orderId).subscribe((rdR) => {
              localSubject.next();
              localSubject.complete();
            });
          });
        });
      });
    });

    return localSubject;
  };

  // createCin7CreditNoteForItems = updatedLines => {
  //   let localSubject = new Subject();

  //   let filteredLines = updatedLines.filter(ul => ul.c_deleted);
  //   const orderCin7Id = this.order.c_cin7_so_id;

  //   // Get detail of deleted line items from the order for the credit note
  //   this.orderService
  //     .getDeletedLineDetailsOrder(this.order._id)
  //     .subscribe(itemDetails => {
  //       console.log("**--Deleted item details: ", itemDetails);
  //       console.log("**--Order Detials:", this.order);

  //       let existingNodeId = this.order.c_credit_node_id;

  //       // Create credit note details
  //       let salesRef = orderCin7Id,
  //         lineItems = itemDetails.data.map(line => ({
  //           productId: line.c_product.c_cin7_id,
  //           code: line.c_product_option_code,
  //           qty: -1 * line.c_quantity
  //         }));

  //       let creditNote = {
  //         salesReference: salesRef,
  //         memberEmail: "smasler@selectortho.net",
  //         lineItems: lineItems,
  //         branchId: this.order.c_provider_location.c_location.c_cin7_id,
  //         company: this.order.c_patient.c_mrn,
  //         isApproved: false
  //         // billingCompany: this.order.c_insurance.c_insurance_name
  //       };

  //       if (existingNodeId) {
  //         creditNote["id"] = Number(existingNodeId);
  //       } else {
  //         let nowIso = moment().toISOString();
  //         creditNote["creditNoteDate"] = nowIso;
  //         //creditNote["completedDate"] = nowIso;
  //       }

  //       console.log("Credit note Payload: ", creditNote);

  //       if (existingNodeId) {
  //         this.orderService
  //           .updateCreditNoteCin7(creditNote)
  //           .subscribe(unRsp => {
  //             localSubject.next(unRsp);
  //             localSubject.complete();
  //           });
  //         // .subscribe(cnRsp => {
  //         //   console.log("-------Update credit note rsp", cnRsp);
  //         //   this.continueUpdateOrderFlow();
  //         // });
  //       } else {
  //         this.orderService
  //           .createOrderCreditNoteCin7(creditNote)
  //           .subscribe(cnRsp => {
  //             localSubject.next(cnRsp);
  //             localSubject.complete();
  //           });
  //         // .subscribe(cnRsp => {
  //         //   console.log("--------Create credit note rsp: ", cnRsp);
  //         //   let data = {
  //         //     c_credit_node_id: cnRsp.data[0].id,
  //         //     c_credit_node_code: cnRsp.data[0].code
  //         //   };

  //         // Req to save the credit notes's id & code to the order
  //         // this.orderService
  //         //   .editOrder(data, this.order._id)
  //         //   .subscribe(cnAddedRsp => {
  //         //     this.continueUpdateOrderFlow();
  //         //   });
  //         //   this.continueUpdateOrderFlow();
  //         // });
  //       }
  //       //return;

  //       // Req to create a credit note in cin7 for the deleted items
  //     });
  //   //console.log("Filtered Lines: ", filteredLines);
  //   return localSubject;
  //   // return this.orderService.createOrderCreditNoteCin7(creditNote);
  // };

  saveChangedOrderSignature() {
    console.log('Patient signature is: ', this.patientSignature);
    if (this.patientSignature) {
      console.log('Updating order signature');

      this.updateOrderSignature();
    }
    // } else {
    //   console.log("Removing order signature");
    //   this.removeOrderSignature();
    // }
  }

  // removeOrderSignature() {
  //   this.orderService.removeOrderSignature(this.orderId).subscribe(res => {
  //     console.log("Signature removed.");
  //   });
  // }

  updateOrderSignature() {
    // console.log("OrderSignature Needs Changing");

    this.orderService
      .saveOrderSignature(this.patientSignature, this.orderId)
      .subscribe(
        (file) => {
          this.formsService
            .sendFileAWS(this.patientSignature, file.data)
            .subscribe(
              (res) => {
                // console.log('Order Signature saved AWS ' + res.data);
                // this.continueCreationFlow(response, draft);
              },
              (error) => {
                // console.log('Order Signature AWS error? ', error);
                // this.continueCreationFlow(response, draft);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }

  continueUpdateOrderFlow() {
    if (this.hasRemainingForms()) {
      this.step = 5;
      this.nextModifierFormQuestion();
    } else {
      this.goToViewOrder(this.orderId);
    }
  }

  hasRemainingForms() {
    const mfCpy = { ...this.modifierForms };
    // console.log("Current ModifierForms: ", mfCpy);
    // this.modifierForms.map( (mf, mfi) => {
    //   this.modifierFormsDeleted.map( mfd => {
    //     if (mfd === mf._id) {
    //       const alreadyMarked = this.modifierForms.filter( mff => (mff._id == mfd && mff.deleted)).length;
    //       if (alreadyMarked == 0) {
    //         mf.deleted = true;
    //       }
    //     }
    //   })
    // });
    this.modifierForms = this.modifierForms.filter((mf) => !mf.deleted);
    return this.modifierForms.length;
  }

  updateOrderLineItemsNew() {
    const localSubject = new Subject();
    const requests = [];
    const lines = [...this.orderLineItems];
    const linesToDelete = lines.filter((line) => line.deleted);
    const linesToUpdate = lines.filter((line) => !line.deleted);

    linesToDelete.map((line) => {
      const productId = line.c_product._id;
      const lineDeleted = this.orderService.deleteOrderLineItems(line._id);
      requests.push(lineDeleted);
      const ruleFormDeleted = this.deleteRuleFormNew(productId);
    });

    return localSubject;
  }

  deleteRuleFormNew(productId) {
    const localSubject = new Subject();
    const formsToDelete = this.modifierForms.filter(
      (mf) => mf.c_product._id === productId
    );
    const requests = [];
    formsToDelete.map((formToDelete) => {
      if (this.formCanStay(formToDelete)) {
        this.swapFormProduct(formToDelete);
      } else {
        const formDeleted = this.orderService.deleteModifierForm(
          formToDelete._id
        );
        requests.push(formDeleted);
      }
    });

    return localSubject;
  }

  formCanStay(form) {
    // console.log("Form can stay? ", form);
    // console.log("OrderLinItems: ", this.orderLineItems);
    return true;
  }

  swapFormProduct(form) {}

  deleteRuleForms() {
    this.modifierFormsDeleted.map((mfd) => {
      this.orderService.deleteModifierForm(mfd._id).subscribe((mdfd) => {
        // console.log("Modifier form succesfully deleted!", mdfd);
      });
    });
  }

  updateOrderLineItems(draft: boolean = false) {
    let deleteDate = moment().format('YYYY-MM-DD');
    let liRequests = [];
    let localSubject = new Subject();
    const orderLinesToUpdate = [...this.orderLineItems];

    // Remove ruleForms associated to deleted items
    this.deleteRuleForms();

    // Lines to be deleted
    orderLinesToUpdate
      .filter((line) => line.deleted)
      .map((lineToDelete) => {
        const productId = lineToDelete.c_product._id;

        let delReq = this.orderService.editOrderLineItem(
          {
            c_deleted: true,
            c_delete_reason: lineToDelete.c_delete_reason,
            c_delete_date: deleteDate,
            c_order_last_modified: this.gLastModified,
          },
          lineToDelete._id
        );
        // For drafts, we need to completelu delete the orderlineitems not just mark them as deleted
        if (draft) {
          delReq = this.orderService.deleteOrderLineItems(lineToDelete._id);
        }

        liRequests.push(delReq);
      });

    this.itemsForCN = orderLinesToUpdate.filter(
      (line) => line.deleted && line._id
    );

    const linesToUpdate = [];
    // Lines that won't be deleted
    orderLinesToUpdate
      .filter((line) => !line.deleted)
      .map((lineToEdit) => {
        // Edit Modifiers
        lineToEdit.c_modifier.map((lineModifier) => {
          const modifier = {
            c_modifier: lineModifier.c_modifier,
            c_checked: lineModifier.c_checked,
          };

          if (lineToEdit._id) {
            this.orderService
              .editModifierOrderLineItem(
                modifier,
                lineToEdit._id,
                lineModifier._id
              )
              .subscribe((editted) => {
                // console.log("Modifier Editted: ", editted);
              });
          }
        });

        const lineCopy = { ...lineToEdit };

        lineCopy.c_icd_10 = lineToEdit.c_icd_10.map((lineIcd) => {
          return lineIcd._id;
        });

        lineCopy.c_modifier = lineToEdit.c_modifier.map((lm) => {
          return {
            c_modifier: lm.c_modifier,
            c_checked: lm.c_checked,
            c_modifier_ref: lm.c_modifier_ref,
          };
        });
        lineCopy.c_medable_order = this.orderId;
        lineCopy.c_product = lineToEdit.c_product._id;

        if (lineCopy.c_quantity < 1) {
          lineCopy.c_quantity = 1;
        }

        delete lineCopy.c_modifier_details;
        delete lineCopy.c_only_modifiers;
        delete lineCopy.availableHCPCS;
        delete lineCopy.delete;
        console.log('LineCopy: ', lineCopy);
        linesToUpdate.push(lineCopy);
      });

    linesToUpdate.map((lineObj) => {
      delete lineObj.edit;
      delete lineObj.availableHCPCS;
      lineObj['c_order_last_modified'] = this.gLastModified;
      if (lineObj._id) {
        const lineItemId = lineObj._id;
        delete lineObj._id;
        delete lineObj.c_modifier;
        let editReq = this.orderService.editOrderLineItem(lineObj, lineItemId);
        liRequests.push(editReq);
      } else {
        let createReq = this.orderService.createOrderLineItem(lineObj);
        liRequests.push(createReq);
      }
    });

    forkJoin(liRequests).subscribe((lrResp) => {
      localSubject.next({
        data: lrResp,
      });
      localSubject.complete();
    });

    return localSubject;
  }

  createOrder(draft: boolean = false) {
    const query = '?group={"_id" : "object", "count" : {"$max":"c_number_id"}}';
    this.formsService.get('orders', query).subscribe((response) => {
      const count = response.data.length ? response.data[0].count : 0;

      if (this.patientSelected) {
        this.registerOrder(count, draft);
      } else {
        this.registerPatient(this.order.c_patient).subscribe(
          (createdPatient) => {
            this.order.c_patient._id = createdPatient._id;
            this.registerOrder(count, draft);
          }
        );
      }
    });
  }

  registerOrder(registeredOrders, draft) {
    const formattedOrder = this.formatOrder(this.order, registeredOrders);
    formattedOrder['c_language'] = this.chosenLanguage.label;
    //console.log("Mock Order Creation");
    let currentUid = this.authService.getAuthorizedUser()._id;
    formattedOrder['c_last_modified_by'] = currentUid;
    console.log('Register order Service ####');

    this.orderService
      .createOrder(formattedOrder)
      .pipe(delay(750))
      .subscribe((response) => {
        this.orderId = response._id;
        if (this.orderSigned) {
          // console.log("Order is signed!");
          this.orderService
            .saveOrderSignature(this.patientSignature, this.orderId)
            .pipe(delay(500))
            .subscribe(
              (file) => {
                // console.log("Sainvg Patient Signature: ", this.patientSignature);
                // console.log("Order Signature Saved Medable: ", file);
                this.formsService
                  .sendFileAWS(this.patientSignature, file.data)
                  .subscribe(
                    (res) => {
                      // console.log('Order Signature saved AWS ' + res.data);
                      this.continueCreationFlow(response, draft);
                    },
                    (error) => {
                      // console.log('Order Signature AWS error? ', error);
                      this.continueCreationFlow(response, draft);
                    }
                  );
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.continueCreationFlow(response, draft);
        }
      });
  }

  continueCreationFlow(response, draft) {
    // console.log("Continuing order creation flow");
    this.saveOrderLineItems(response._id, response)
      .pipe(delay(350))
      .subscribe((savedLines) => {
        const cin7OrdObj = { ...response };
        cin7OrdObj.c_patient = { ...this.order.c_patient };
        cin7OrdObj.c_selected_insurances = this.selectedInsurances;
        cin7OrdObj.c_selected_provider = this.selectedPhysician;

        console.log('Save order lines: ', savedLines);

        if (draft) {
          this.viewOrLoadForms(savedLines, response);
        } else {
          this.orderService
            .createSalesOrderCin7(
              this.orderId,
              cin7OrdObj,

              savedLines['results'],

              this.selectedPhysician.c_location
            )
            .subscribe((cin7Rsp) => {
              this.viewOrLoadForms(savedLines, response);
            });
        }
      });
  }

  viewOrLoadForms(savedLines, response) {
    this.disableSubmitButtons = false;
    if (savedLines['completed']) {
      this.goToViewOrder(response._id);
    } else {
      this.nextStep();
      this.nextModifierFormQuestion();
    }
  }

  // nextFormQuestion() {
  //   this.closeModal(100);
  //   // console.log("SelectedLocation: ", this.selectedPhysician);
  //   if (this.question) {
  //     if (this.question.c_required) {
  //       if (
  //         (this.question.c_response_type === "text" ||
  //           !this.question.c_multi_select) &&
  //         this.question.response === undefined
  //       ) {
  //         this.question.error = true;
  //         return;
  //       }
  //       if (
  //         this.question.c_response_type !== "text" &&
  //         this.question.c_multi_select
  //       ) {
  //         let i = 0;
  //         while (
  //           i < this.question.c_response_options.length &&
  //           !this.question.c_response_options[i].checked
  //         ) {
  //           i++;
  //         }

  //         if (i === this.question.c_response_options.length) {
  //           this.question.error = true;
  //           return;
  //         }
  //       }
  //     }

  //     delete this.question.error;

  //     // MAE form steps manager,
  //     if (
  //       this.orderForms[this.stepQuestion.form].c_inventory_form.c_form
  //         .c_name === "MAE"
  //     ) {
  //       // in case the answer of first question of step 2 was yes, don't show the second question of step 2
  //       if (
  //         this.stepQuestion.question === 1 &&
  //         this.question.response === "0"
  //       ) {
  //         this.stepQuestion.question = 2;
  //       }
  //       // if you are in step 3 or more, and you aswer yes, you have to stop
  //       if (this.stepQuestion.question >= 3 && this.question.response === "0") {
  //         this.stepQuestion.question = 6;
  //       }
  //     }
  //   }

  //   this.stepQuestion.question++;

  //   while (
  //     !this.orderForms[this.stepQuestion.form].c_responses ||
  //     this.stepQuestion.question >=
  //       this.orderForms[this.stepQuestion.form].c_responses.length
  //   ) {
  //     this.stepQuestion.question = 0;
  //     this.stepQuestion.form++;

  //     // in case all the forms where answered, finish
  //     if (this.stepQuestion.form >= this.orderForms.length) {
  //       // this.saveOrderForms();
  //       return;
  //     }
  //   }

  //   const question = this.orderForms[this.stepQuestion.form].c_responses[
  //     this.stepQuestion.question
  //   ].c_question;
  //   question.form = this.orderForms[
  //     this.stepQuestion.form
  //   ].c_inventory_form.c_form.c_name;
  //   question.inventory = this.orderForms[
  //     this.stepQuestion.form
  //   ].c_inventory_form.c_product.c_hcpcs_code;

  //   if (
  //     question.c_multi_select &&
  //     question.c_response_options[0].checked === undefined
  //   ) {
  //     for (let i = 0; i < question.c_response_options.length; i++) {
  //       question.c_response_options[i] = {
  //         text: question.c_response_options[i],
  //         checked: false,
  //       };
  //     }
  //   }

  //   this.question = question;
  // }

  previousFormQuestion() {
    delete this.question.error;
    this.stepQuestion.question--;
    // in case we have to go to the previous
    if (this.stepQuestion.question < 0) {
      this.stepQuestion.question = 0;
      this.stepQuestion.form--;
    }

    // MAE form steps manager,
    if (
      this.orderForms[this.stepQuestion.form].c_inventory_form.c_form.c_name ===
      'MAE'
    ) {
      // in case the answer of first question of step 2 was yes, don't show the second question of step 2
      while (
        this.stepQuestion.question > 0 &&
        this.orderForms[this.stepQuestion.form].c_responses[
          this.stepQuestion.question
        ].c_question.response === undefined
      ) {
        this.stepQuestion.question--;
      }
    }

    const question = this.orderForms[this.stepQuestion.form].c_responses[
      this.stepQuestion.question
    ].c_question;
    question.form = this.orderForms[
      this.stepQuestion.form
    ].c_inventory_form.c_form.c_name;
    question.inventory = this.orderForms[
      this.stepQuestion.form
    ].c_inventory_form.c_product.c_hcpcs_code;

    this.question = question;
  }

  previousModifierFormQuestion() {
    delete this.mfQuestion.error;
    this.mfStepQuestion.question--;
    // in case we have to go to the previous
    if (this.mfStepQuestion.question < 0) {
      this.mfStepQuestion.question = 0;
      this.mfStepQuestion.form = this.findPreviousFormWithQuestions(
        this.mfStepQuestion.form
      );
    }

    console.log('*** This modifierForms: ', this.modifierForms);
    console.log('*** Form index: ', this.mfStepQuestion.form);
    console.log('*** Resp index: ', this.mfStepQuestion.question);

    const question = this.modifierForms[this.mfStepQuestion.form].c_responses[
      this.mfStepQuestion.question
    ].c_question;
    question.form = this.modifierForms[
      this.mfStepQuestion.form
    ].c_modifier_form.c_form.c_name;

    this.mfQuestion = question;
  }

  findPreviousFormWithQuestions(currFormIdx) {
    let formIdx = currFormIdx;
    for (let i = currFormIdx - 1; i >= 0; i--) {
      let form = this.modifierForms[i],
        displayQuestions = (form.c_responses || []).filter((r) => {
          let display = (r.c_question || {}).display;
          return display;
        });

      console.log('---Checking for format idx: ', i);
      console.log('---Form is: ', form);
      console.log('---Form has questions: ', displayQuestions);

      if (displayQuestions.length > 0) {
        formIdx = i;
        break;
      }
    }

    return formIdx;
  }

  isThisLastFormQuestion() {
    const currentQuestionNr = this.mfStepQuestion.question;
    const currentFormsQuestions = this.modifierForms[this.mfStepQuestion.form]
      .c_responses.length;
    const formName = this.modifierForms[this.mfStepQuestion.form]
      .c_modifier_form.c_form.c_name;
    const warrantyForm = formName.toLowerCase().indexOf('warranty') > -1;

    if (
      currentQuestionNr + 1 == currentFormsQuestions ||
      (warrantyForm && currentQuestionNr == 1)
    ) {
      return true;
    } else {
      return false;
    }
  }

  paymentModelChanged = (e, idx) => {
    // console.log("Payment model changed...", e);
    // this.orderLineItems[idx]['payment'] = {
    //   value: e && parseInt(e) > 0
    // }
  };

  paymentMethodChange = (e, idx) => {
    // console.log("mayment method model change: ", e)
  };

  shallDisplaySignaturePad() {
    const isLastQuestion = this.isThisLastFormQuestion();
    //console.log("Is Last Question? ", )
    const formContainsSignature = this.modifierForms[this.mfStepQuestion.form]
      .c_modifier_form.c_form.c_contains_signature;
    if (isLastQuestion && formContainsSignature) {
      return true;
    }

    return false;
  }

  /**
   * ABN - Helper
   * Function to check if given question is ABN form's estimated cost.
   */
  isABNEstiamteQuestion = (question) => {
    console.log('___+++ CHECKED QUESTION: ', question);
    if (!question) {
      return false;
    }

    if (!question.c_text) {
      return false;
    }

    if (
      question.c_text.toLowerCase() === 'estimated cost' &&
      question.c_response_options === 'text'
    ) {
      return true;
    }

    return false;
  };

  nextModifierFormQuestion() {
    // // console.log("This Location: ", this.selectedPhysician);
    // console.log("*** *** *** --- --- --- NextMFQuestion: ", this.mfQuestion);
    // if (this.isABNEstiamteQuestion(this.mfQuestion)) {
    //   this.mfQuestion["response"] = 100;
    // }
    if (this.mfQuestion) {
      if (this.mfQuestion.c_required) {
        if (
          (this.mfQuestion.c_response_type === 'text' ||
            !this.mfQuestion.c_multi_select) &&
          this.mfQuestion.response === undefined
        ) {
          this.mfQuestion.error = true;
          return;
        }
        if (
          this.mfQuestion.c_response_type !== 'text' &&
          this.mfQuestion.c_multi_select
        ) {
          let i = 0;
          while (
            i < this.mfQuestion.c_response_options.length &&
            !this.mfQuestion.c_response_options[i].checked
          ) {
            i++;
          }

          if (i === this.mfQuestion.c_response_options.length) {
            this.mfQuestion.error = true;
            return;
          }
        }
      }

      delete this.mfQuestion.error;
    }

    this.mfStepQuestion.question++;

    Sentry.setContext(`modifierForms`, this.modifierForms);
    Sentry.setContext(`mfStepQuestion`, this.mfStepQuestion);

    while (
      this.modifierForms[this.mfStepQuestion.form] &&
      (!this.modifierForms[this.mfStepQuestion.form].c_responses ||
        this.mfStepQuestion.question >=
          this.modifierForms[this.mfStepQuestion.form].c_responses.length)
    ) {
      this.mfStepQuestion.question = 0;
      this.mfStepQuestion.form++;

      // in case all the forms where answered, finish
      if (this.mfStepQuestion.form >= this.modifierForms.length) {
        this.saveModifierForms();
        return;
      }
    }

    const question = this.modifierForms[this.mfStepQuestion.form].c_responses[
      this.mfStepQuestion.question
    ].c_question;
    question.form = this.modifierForms[
      this.mfStepQuestion.form
    ].c_modifier_form.c_form.c_name;

    if (
      question.c_multi_select &&
      question.c_response_options[0].checked === undefined
    ) {
      for (let i = 0; i < question.c_response_options.length; i++) {
        question.c_response_options[i] = {
          text: question.c_response_options[i],
          checked: false,
        };
      }
    }
    let hidden = false;
    this.mfQuestion = question;
    // console.log("This mfQuestion: ", this.mfQuestion);
    // console.log("This ModifierForms: ", this.modifierForms);
    // console.log("Current Form's Index: ", this.mfStepQuestion.form);
    // console.log("Current Form's question nr: ", this.modifierForms[this.mfStepQuestion.form].c_responses.length);
    // console.log("This Question's nr is: ", this.mfStepQuestion.question);
    // const lastFormQuestion = this.isThisLastFormQuestion();
    // console.log("Is this last Question? :", lastFormQuestion);
    this.shalDisplaySignaturePad = this.shallDisplaySignaturePad();
    // console.log("Should DIsplay signaturepad? ", this.shalDisplaySignaturePad);
    // Populating Warranty Form
    if (this.mfQuestion.form.toLowerCase().indexOf('warranty') > -1) {
      // console.log("Auto populating warranty form");
      const sl = this.selectedPhysician.c_location;
      // Autocomplete Location
      if (this.mfQuestion.c_text.toLowerCase().indexOf('name') > -1) {
        const qLastName = sl ? sl.c_last_name : '--';
        this.mfQuestion.response = qLastName;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (
        this.mfQuestion.c_text.toLowerCase().indexOf('abbreviation') > -1
      ) {
        const qJobTitle = sl ? sl.c_job_title : '--';
        this.mfQuestion.response = qJobTitle;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (
        this.mfQuestion.c_text.toLowerCase().indexOf('location') > -1
      ) {
        // this.mfQuestion.hidden = true;
        const qLocation = sl ? sl.c_company : '-';
        this.mfQuestion.response = qLocation;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (this.mfQuestion.c_text.toLowerCase().indexOf('state') > -1) {
        // this.mfQuestion.hidden = true;
        const qState = sl.c_delivery_address
          ? sl.c_delivery_address.c_state
            ? sl.c_delivery_address.c_state
            : '-'
          : '-';
        this.mfQuestion.response = qState;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (this.mfQuestion.c_text.toLowerCase().indexOf('city') > -1) {
        // this.mfQuestion.hidden = true;
        // console.log("City: ", )
        const qCity = sl.c_delivery_address
          ? sl.c_delivery_address.c_city
            ? sl.c_delivery_address.c_city
            : '-'
          : '-';
        this.mfQuestion.response = qCity;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (
        this.mfQuestion.c_text.toLowerCase().indexOf('postal') > -1 &&
        question.c_text.toLowerCase().indexOf('code') > -1
      ) {
        // this.mfQuestion.hidden = true;
        const qZip = sl.c_delivery_address
          ? sl.c_delivery_address.c_postal_code
            ? sl.c_delivery_address.c_postal_code
            : '-'
          : '-';
        this.mfQuestion.response = qZip;
        hidden = true;
        this.nextModifierFormQuestion();
      } else if (this.mfQuestion.c_text.toLowerCase().indexOf('phone') > -1) {
        // this.mfQuestion.hidden = true;
        const qPhone = sl.c_phone ? sl.c_phone : '-';
        this.mfQuestion.response = qPhone;
        this.nextModifierFormQuestion();
      } else if (this.mfQuestion.c_text.toLowerCase().indexOf('street') > -1) {
        hidden = true;
        let qStreet = '';
        if (sl.c_delivery_address) {
          const da = sl.c_delivery_address;
          qStreet = da.c_street_address_1
            ? da.c_street_address_1
            : da.c_street_address_2
            ? da.c_street_address_2
            : '-';
        } else {
          qStreet = '-';
        }
        this.mfQuestion.response = qStreet;
        this.nextModifierFormQuestion();
      } else {
        // console.log("What?");
      }
    }
    if (!hidden) {
      this.mfQuestion.display = true;
      // console.log("I am visible: ", this.mfQuestion);
    }

    // Autocomplete Estimated Cost on ABN form
    // Check if ABN form
    console.log('***--- Current Modofier Form Question: ', this.mfQuestion);
    if (this.mfQuestion.form.toLowerCase().indexOf('abn') > -1) {
      // Check if estimated cost
      if (
        this.mfQuestion.c_text.toLowerCase().indexOf('estimated') > -1 &&
        this.mfQuestion.c_text.toLowerCase().indexOf('cost') > -1
      ) {
        this.mfQuestion.response = this.getTotalAllowable();
      }
    }
  }

  /** ABN - Helper.
   * Calculate total allowable cost for all the items from the order
   */
  getTotalAllowable = () => {
    console.log('**** Order LineItems: ', this.orderLineItems);
    let total = this.orderLineItems
      .filter((oi) => this.lineHasModifier(oi.c_modifier, 'ga'))
      .map((oi) => oi.c_allowable_cost || 0)
      .reduce((a, b) => a + b, 0);
    return total;
  };

  /**
   * Helper
   * Function to check if a lineItem has a certain modifier checked
   */
  lineHasModifier = (lineModifiers, modifier) => {
    let filtered = lineModifiers.filter((m) => {
      let hasModifier =
          (m.c_modifier || '').toLowerCase() === modifier.toLowerCase(),
        isChecked = m.c_checked;

      return hasModifier && isChecked;
    });

    return filtered.length > 0;
  };

  saveModifierForms() {
    console.log('## Saving ModifierForms...');
    const requests = [];
    //console.log("Forms to save: ", this.modifierForms);
    this.modifierForms.map((modifierForm) => {
      // Create order form to be saved
      console.log('ModifierForm Saving: ', modifierForm);
      const modifierFormObj = {
        c_form: modifierForm.c_modifier_form.c_form._id,
        c_medable_order: this.orderId,
        c_responses: [],
        c_selected_hcpcs_code: modifierForm.c_selected_hcpcs || '',
        c_product: modifierForm.c_product._id,
        c_attached_hcpcs_codes: this.getFormHcpcsList(
          modifierForm.c_modifier_form.c_form
        ),
        c_item_internal_id: modifierForm.c_item_internal_id,
      };

      // Go through the question to save the responses
      if (modifierForm.c_responses) {
        modifierForm.c_responses.map((response) => {
          const question = response.c_question;
          const responseObj = {
            c_question: question._id,
            c_response: [],
          };

          // in case the response is a text, I save it
          if (question.response !== undefined) {
            responseObj.c_response.push(question.response.toString());
          } else if (question.c_multi_select) {
            question.c_response_options.map((rOption, rOptionIndex) => {
              if (rOption.checked) {
                responseObj.c_response.push(rOptionIndex.toString());
              }
            });
          }

          modifierFormObj.c_responses.push(responseObj);
        });
      }

      if (modifierForm._id) {
        requests.push(this.editModifierForm(modifierFormObj, modifierForm._id));
      } else {
        requests.push(this.createModifierForm(modifierFormObj));
      }
    });

    forkJoin(requests).subscribe((mfOperationResponses) => {
      //console.log("MfOperationResponses: ", mfOperationResponses);
      this.saveMfSignatures(mfOperationResponses).subscribe(() => {
        this.goToViewOrder(this.orderId);
      });
    });
  }

  saveMfSignatures(operationResponses): Observable<any> {
    // console.log("Whole Object: ", operationResponses);
    const localSubject = new Subject();
    const sRequests = [];
    const responsesWSignature = operationResponses.filter((or) => {
      const hasSignature =
        this.mfSignatureList.filter((mfso) => mfso.formId == or.c_form._id)
          .length > 0;
      return hasSignature;
    });

    if (!responsesWSignature.length) {
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }

    responsesWSignature.map((or) => {
      const mfSignatures = this.mfSignatureList.filter(
        (mfs) => mfs.formId == or.c_form._id
      );
      // console.log("Saving signature: mfId: ", or._id, " signature: ", mfSignatures[0].mfSignature)
      sRequests.push(this.saveMfSignature(or._id, mfSignatures[0].mfSignature));
    });

    forkJoin(sRequests).subscribe((signsSaved) => {
      // console.log("All MF Signatures were saved.", signsSaved);
      localSubject.next(signsSaved);
      localSubject.complete();
    });

    return localSubject;
  }

  saveMfSignature(mfId, signature) {
    const localSubject = new Subject();
    //c_form_signature
    this.orderService.saveMfSignature(signature, mfId).subscribe(
      (file) => {
        this.formsService.sendFileAWS(signature, file.data).subscribe(
          (res) => {
            // console.log('MF Signature saved AWS ' + res.data);
            localSubject.next(res.data);
            localSubject.complete();
          },
          (error) => {
            // console.log('MF Signature AWS error? ', error);
            localSubject.next(error);
            localSubject.complete();
          }
        );
      },
      (error) => {
        console.log(error);
        localSubject.next(error);
        localSubject.complete();
      }
    );

    return localSubject;
  }

  createModifierForm(modifierForm) {
    const localSubject = new Subject();

    this.orderService.createModifierForm(modifierForm).subscribe((response) => {
      localSubject.next(response);
      localSubject.complete();
    });

    return localSubject;
  }

  editModifierForm(modifierForm, modifierFormId) {
    const localSubject = new Subject();
    this.orderService
      .editModifierForm(modifierForm, modifierFormId)
      .subscribe((rsp) => {
        localSubject.next(rsp);
        localSubject.complete();
      });

    return localSubject;
  }

  saveOrderForms() {
    console.log('###Saving order forms...');
    const requests = [];
    this.orderForms.map((orderForm) => {
      // Create order form to be saved
      const orderFormObj = {
        c_inventory_form: orderForm.c_inventory_form._id,
        c_medable_order: this.orderId,
        c_responses: [],
      };
      // Go through the question to save the responses
      orderForm.c_responses.map((response) => {
        const question = response.c_question;
        const responseObj = {
          c_question: question._id,
          c_response: [],
        };
        if (response._id) {
          responseObj['_id'] = response._id;
        }

        // in case the response is a text, I save it
        if (question.response !== undefined) {
          responseObj.c_response.push(question.response.toString());
        } else if (question.c_multi_select) {
          question.c_response_options.map((rOption, rOptionIndex) => {
            if (rOption.checked) {
              responseObj.c_response.push(rOptionIndex.toString());
            }
          });
        }
        orderFormObj.c_responses.push(responseObj);
      });

      if (orderForm._id) {
        requests.push(this.editOrderForm(orderFormObj, orderForm._id));
      } else {
        requests.push(this.createOrderForm(orderFormObj));
      }
    });

    forkJoin(requests).subscribe(() => {
      if (this.modifierForms.length) {
        this.nextStep();
        this.nextModifierFormQuestion();
      } else {
        this.goToViewOrder(this.orderId);
      }
    });
  }

  editOrderForm(orderForm, orderFormId) {
    const localSubject = new Subject();

    this.orderService
      .editOrderForm(orderForm, orderFormId)
      .subscribe((response) => {
        localSubject.next(response);
        localSubject.complete();
      });

    return localSubject;
  }

  createOrderForm(orderForm) {
    const localSubject = new Subject();

    this.orderService.createOrderForm(orderForm).subscribe((response) => {
      localSubject.next(response);
      localSubject.complete();
    });

    return localSubject;
  }

  saveOrderLineItems(orderId, savedOrder) {
    const localSubject = new Subject();
    const requests = [];
    const processedItems = this.orderLineItems.map((item) =>
      this.formatOrderLineItem(item, orderId, savedOrder)
    );

    processedItems.map((item) => {
      item['c_order_last_modified'] = this.gLastModified;
      requests.push(this.orderService.createOrderLineItem(item));
    });

    forkJoin(requests).subscribe((results) => {
      if (!this.modifierForms.length) {
        localSubject.next({
          completed: true,
          results,
        });
        localSubject.complete();
      } else {
        localSubject.next({
          completed: false,
          results,
        });
        localSubject.complete();
      }
    });

    return localSubject;
  }

  formatOrderLineItem(orderLineItem, orderId, savedOrder) {
    console.log('**** saved order: ', savedOrder);

    // Get fields from order that need to be synced to the item
    let orderDos = savedOrder.c_order_date,
      orderBilled = savedOrder.c_billed_date,
      ordProvLocation = savedOrder.c_provider_location._id,
      ordFitter = savedOrder.c_fitter._id,
      ordDesFitter = savedOrder.c_designated_fitter._id,
      ordInsurance = savedOrder.c_insurance._id;

    const formattedItem = { ...orderLineItem };
    delete formattedItem.availableHCPCS;
    formattedItem.c_quantity =
      orderLineItem.c_quantity === undefined || orderLineItem < 0
        ? 1
        : orderLineItem.c_quantity;
    formattedItem.c_medable_order = orderId;
    formattedItem.c_product = orderLineItem.c_product._id;
    formattedItem.c_icd_10 = orderLineItem.c_icd_10.map((icd) => icd._id);
    delete formattedItem.c_modifier_details;
    delete formattedItem.c_only_modifiers;
    delete formattedItem.edit;

    if (!formattedItem.c_icd_10.length) {
      delete formattedItem.c_icd_10;
    }

    if (!formattedItem.c_modifier.length) {
      delete formattedItem.c_modifier;
    }

    // // Add needed fields from the saved order
    // // DOS
    // if (orderDos) {
    //   formattedItem.c_order_dos = orderDos;
    // }

    // // Billed Date
    // if (orderBilled) {
    //   formattedItem.c_order_billed_date = orderBilled;
    // }

    // // Location
    // if (ordProvLocation) {
    //   formattedItem.c_order_provider_location = ordProvLocation;
    // }

    // // Fitter
    // if (ordFitter) {
    //   formattedItem.c_order_fitter = ordFitter;
    // }

    // // desFitter
    // if (ordDesFitter) {
    //   formattedItem.c_designated_fitter = ordDesFitter;
    // }

    // // Insurance
    // if (ordInsurance) {
    //   formattedItem.c_order_insurance = ordInsurance;
    // }

    return formattedItem;
  }

  formatOrder(order, registeredOrders) {
    const formattedOrder = {
      c_number_id: registeredOrders + 1,
      c_order_date: this.dateService.format(this.orderDateModel),
      c_patient: order.c_patient._id,
      c_insurance: this.selectedInsurances.primary.document._id,
      c_fitter: this.accountId,
      c_provider_location: this.selectedPhysician._id,
      c_notes: order.c_notes,
      c_draft: order.c_draft,
    };

    if (this.orderedDateModel) {
      formattedOrder['c_ordered_date'] = this.dateService.format(
        this.orderedDateModel
      );
    }

    if (this.selectedBillingProvider._id) {
      formattedOrder['c_billing_provider'] = this.selectedBillingProvider._id;
    }

    if (this.selectedInsurances.primary.id != undefined) {
      formattedOrder[
        'c_primary_insurance_id_number'
      ] = this.selectedInsurances.primary.id;
    }

    if (this.selectedInsurances.secondary.id != undefined) {
      formattedOrder[
        'c_secondary_insurance_id_number'
      ] = this.selectedInsurances.secondary.id;
    }

    if (this.selectedInsurances.secondary.document != undefined) {
      formattedOrder[
        'c_secondary_insurance'
      ] = this.selectedInsurances.secondary.document._id;
    }

    if (this.selectedFitter.id != '') {
      formattedOrder['c_designated_fitter'] = this.selectedFitter.id;
    }

    if (this.billedDateModel) {
      formattedOrder['c_billed_date'] = this.dateService.format(
        this.billedDateModel
      );
      if (!order.c_draft) {
        formattedOrder['c_dispatched'] = true;
      }
    } else {
      formattedOrder['c_dispatched'] = false;
    }

    this.gLastModified = new Date(Date.now()).toISOString();
    formattedOrder['c_last_modified'] = this.gLastModified;

    // if (this.order.c_billed_date != undefined && this.order.c_billed_date.date && )

    return formattedOrder;
  }

  formatDateInput(event: FocusEvent, modelName: string) {
    const valueProcessed = (event.target as HTMLInputElement).value.replace(
      /[^\d]/g,
      ''
    );

    const month = valueProcessed.substring(0, 2);
    const day = valueProcessed.substring(2, 4);
    const year = valueProcessed.substring(4, 8);

    this[modelName] = this.dateService.toDatepickerModel(
      new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
    );
  }

  registerPatient(orderPatient) {
    const patient = {
      ...orderPatient,
      c_dob: this.dateService.format(this.dateOfBirthModel),
    };

    delete patient._id;
    return this.patientService.createPatient(patient);
  }

  /**
   * Function to delete the current order
   */
  deleteOrder = (draft) => {
    const draftTitle = 'orders.popup.cancel.draft.title',
      orderTitle = 'orders.popup.delete.title',
      draftText = 'orders.popup.cancel.draft.text',
      orderText = 'orders.popup.delete.text',
      title = draft ? draftTitle : orderTitle,
      text = draft ? draftText : orderText;

    // Display delete confirmation popup
    this.showPopupWindow(title, text).then((response) => {
      if (response.selected === 'LEFT') {
        // Change (19.04.2020) - Don't actually delete orders (published) from db, only mark them as deleted
        if (!draft) {
          // If published order, display another popup with a mandatory delete reason
          this.showDeleteOrderReason = true;
        } else {
          // If the order is a draft, delete it
          this.deleteDraft();
        }
      }
    });
  };

  hideDeleteOrderReason = () => {
    this.showDeleteOrderReason = false;
    this.showOrderDeleteReasonError = false;
  };

  /**
   * Function to mark a published order as deleted, and update in cin7 accordingly.
   */
  proceedToDeleteOrder = () => {
    // If no delete reason provided, display error in the popup and exit.
    if (!this.orderDeletionReason || this.orderDeletionReason.trim() === '') {
      this.showOrderDeleteReasonError = true;
      return;
    }

    // Hide delete reason popup, and open loading.
    this.showDeleteOrderReason = false;
    // Get current order's cin7 Id & code
    let orderCode = this.order.c_cin7_so_code,
      orderId = this.order._id;

    // Handle all Cin7 related calls.
    this.handleOrderDeletionCin7(orderCode).subscribe((res) => {
      let cnId = res['cnId'],
        cnCode = res['cnCode'];

      // Handle Medable related calls.
      this.markOrderDeleted(cnId, cnCode, orderId).subscribe((delR) => {
        // Navigate to the orders index page.
        this.gotoOrdersIndex();
      });
    });
  };

  /**
   * Function to handle the cin7 flow during an order deletion.
   * Flow: 1.) Create Draft Credit Note,
   *       2.) Create a Payment for the craft Credit Note.
   *       3.) Approve Credit Note.
   *       4.) Return Credit Note Id and Code.
   */
  handleOrderDeletionCin7 = (orderCode) => {
    // Because of all the promises, we use return a Subject() and once the
    // calls finished, we return the response by calling .next() on the subject.
    let localSubject = new Subject();

    // Create draft creditNote in cin7 for this order and it's items.
    this.createOrderCN().subscribe((cnRsp) => {
      let cnId = cnRsp.data[0].id,
        cnCode = cnRsp.data[0].code;

      console.log('***ODL: Create order cn response: ', cnRsp);
      // Create a payment in cin7 for the draft creditNote.
      this.orderService.createCin7Payment(cnId, orderCode).subscribe((pmR) => {
        // Generate credit note approved body.
        let cnBody = {
          id: cnId,
          approved: true,
          completedDate: moment().toISOString(),
        };

        console.log('***ODL: Create payment response: ', pmR);

        // Update credit note in cin7 to approve it.
        this.orderService.updateCin7CreditNote(cnBody).subscribe((cnAppr) => {
          console.log('***ODL: credinot approoved rsp: ', cnAppr);
          // All Cin7 related calls finished, return cn id & code.
          localSubject.next({ cnId, cnCode });
          localSubject.complete();
        });
      });
    });

    return localSubject;
  };

  /**
   * Function to mark order as deleted.
   * Flow: 1.) Get current user Id & time.
   *       2.) Add order deleted date to the order's lineItems.
   *       3.) Update Order with order deleted date and creditNote id & code.
   */
  markOrderDeleted = (cnId, cnCode, orderId) => {
    let localSubject = new Subject();
    // Get detals for delete stats
    let currentUid = this.authService.getAuthorizedUser()._id,
      deleteDate = moment().format('YYYY-MM-DD');

    // Update order's items and add the delete date to them.
    this.addOrderDeletedDateToItems(deleteDate).then(() => {
      // Update order in Medable and add delete stats & creditNote details.
      let data = {
        c_deleted: true,
        c_delete_reason: this.orderDeletionReason,
        c_last_modified_by: currentUid,
        c_delete_date: deleteDate,
        c_credit_node_id: cnId,
        c_credit_node_code: cnCode,
        c_last_modified: deleteDate,
      };

      this.orderService.editOrder(data, orderId).subscribe((delR) => {
        localSubject.next();
        localSubject.complete();
      });
    });

    return localSubject;
  };

  /**
   * Function to create a draft credit note in cin7 for the current order
   * and it's items that were not deleted yet.
   */
  createOrderCN = () => {
    console.log('***ODL: This Order: ', this.order);
    // Generate list of creditNote lineItems.
    let lineItems = this.orderLineItems
      .filter((line) => !line.c_deleted)
      .map((line) => ({
        productId: line.c_product.c_cin7_id,
        code: line.c_product_option_code,
        qty: -1 * line.c_quantity,
      }));

    // Generate Credit Note Body.
    let creditNote = {
      salesReference: this.order.c_cin7_so_code,
      memberEmail: 'smasler@selectortho.net',
      branchId: this.order.c_provider_location.c_location.c_cin7_id,
      company: this.order.c_patient.c_mrn,
      lineItems,
      isApproved: false,
      creditNoteDate: moment().toISOString(),
    };

    // Return the service function which makes the actual call to the cin7 API.
    return this.orderService.createCin7CreditNote(creditNote);
  };

  /**
   * Function to add order delete date to the order's items
   */
  addOrderDeletedDateToItems = (deleteDate) => {
    return new Promise((resolve) => {
      let promises = this.orderLineItems.map((item) =>
        this.orderService.editOrderLineItem(
          { c_order_delete_date: deleteDate },
          item._id
        )
      );

      forkJoin(promises).subscribe((rsp) => {
        resolve({ success: true, data: rsp });
      });
    });
  };

  deleteDraft = () => {
    this.orderService.deleteOrder(this.order._id).subscribe((del) => {
      console.log('Draft deleted: ', del);
      this.gotoOrdersIndex();
    });
  };

  checkProdPrice(price, index, field) {
    const maxPrice = 1000000;

    if (price > maxPrice) {
      this.orderLineItems[index][field] = maxPrice;
    }
  }

  getProductStock = (productLocation) => {
    if (productLocation.availiableStock) {
      return;
    }

    productLocation.loadingStock = true;
    // console.log("Product: ", productLocation)
    const cin7Id = productLocation.c_product.c_cin7_id
        ? productLocation.c_product.c_cin7_id
        : null,
      optionCode = productLocation.c_product_option_code,
      branchId = productLocation.c_location.c_cin7_id;

    if (!(cin7Id && optionCode && branchId)) {
      productLocation.availiableStock = '-';
      productLocation.loadingStock = false;
      return;
    }
    // console.log("productLocation: ", productLocation)

    // this.inventoryService.getCin7Inventory(cin7Id).subscribe( cin7Data => {
    //   console.log("cin7data: ", cin7Data)
    //   if (!(cin7Data.data && cin7Data.data.length && cin7Data.data[0].productOptions) ) {
    //     productLocation.availiableStock = '-';
    //     productLocation.loadingStock = false
    //     return;
    //   }

    //   const productOption = cin7Data.data[0].productOptions.filter( po =>
    //     po.code == optionCode
    //   )

    //   if (! (productOption.length && productOption[0].stockAvailable)) {
    //     productLocation.availiableStock = '-';
    //     productLocation.loadingStock = false
    //     return;
    //   }

    //   const stock = productOption[0].stockAvailable
    //   productLocation.availiableStock = stock;
    //   productLocation.loadingStock = false;

    // })
    this.stockService.getStockFromCin7(optionCode).subscribe((stocks) => {
      if (!stocks.data) {
        productLocation.availiableStock = '-';
        productLocation.loadingStock = false;
        return;
      }
      // console.log("Actual stocks: ", stocks)
      const stocksAvailable = stocks.data.filter(
        (stk) => stk.branchId == branchId
      );

      if (!stocksAvailable.length) {
        productLocation.availiableStock = '-';
        productLocation.loadingStock = false;
        return;
      }

      const stockAvailable = stocksAvailable[0].stockOnHand;
      // console.log("Stock available: ", stockAvailable)
      productLocation.availiableStock = ` ${stockAvailable}`;
      productLocation.loadingStock = false;
    });
  };

  readURL(file) {
    const localSubject = new Subject();
    let reader = new FileReader();

    reader.onload = function (e) {
      // console.log("File readed: ", e.target)
      localSubject.next(e.target);
      localSubject.complete();
    };

    reader.readAsDataURL(file);
    return localSubject;
  }

  getBarcodeFromImage = function (imgOrId) {
    let UPC_SET = {
      '3211': '0',
      '2221': '1',
      '2122': '2',
      '1411': '3',
      '1132': '4',
      '1231': '5',
      '1114': '6',
      '1312': '7',
      '1213': '8',
      '3112': '9',
    };
    let doc = document,
      img = 'object' == typeof imgOrId ? imgOrId : doc.getElementById(imgOrId),
      canvas = doc.createElement('canvas'),
      ctx = canvas.getContext('2d'),
      width = img.width,
      height = img.height,
      numLines = 3,
      slineStep = height / (numLines + 1);
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0);
    while (numLines--) {
      let pxLine = ctx.getImageData(0, slineStep * numLines, width, 2).data,
        sum = [],
        min = 0,
        max = 0;
      for (let row = 0; row < 2; row++) {
        for (let col = 0; col < width; col++) {
          let i = (row * width + col) * 4,
            g = (pxLine[i] * 3 + pxLine[i + 1] * 4 + pxLine[i + 2] * 2) / 9,
            s = sum[col];
          pxLine[i] = pxLine[i + 1] = pxLine[i + 2] = g;
          sum[col] = g + (undefined == s ? 0 : s);
        }
      }
      for (let i = 0; i < width; i++) {
        let s = (sum[i] = sum[i] / 2);
        if (s < min) {
          min = s;
        }
        if (s > max) {
          max = s;
        }
      }
      let pivot = min + (max - min) / 2,
        bmp = [];
      for (let col = 0; col < width; col++) {
        let matches = 0;
        for (let row = 0; row < 2; row++) {
          if (pxLine[(row * width + col) * 4] > pivot) {
            matches++;
          }
        }
        bmp.push(matches > 1);
      }
      let curr = bmp[0],
        count = 1,
        lines = Array();
      for (let col = 0; col < width; col++) {
        if (bmp[col] == curr) {
          count++;
        } else {
          lines.push(count);
          count = 1;
          curr = bmp[col];
        }
      }
      let code = '',
        bar = Math.round((lines[1] + lines[2] + lines[3]) / 3),
        u = UPC_SET;
      // console.log("Lines: ", lines)
      for (let i = 1, l = lines.length; i < l; i++) {
        let group;
        if (code.length < 6) {
          group = lines.slice(i * 4, i * 4 + 4);
        } else {
          group = lines.slice(i * 4 + 5, i * 4 + 9);
        }
        let digits =
            '' +
            Math.round(group[0] / bar) +
            Math.round(group[1] / bar) +
            Math.round(group[2] / bar) +
            Math.round(group[3] / bar),
          invert =
            '' +
            Math.round(group[3] / bar) +
            Math.round(group[2] / bar) +
            Math.round(group[1] / bar) +
            Math.round(group[0] / bar);
        code += u[digits] || u[invert] || 'X';
        if (12 == code.length) {
          break;
        }
      }

      // console.log("Code from function: ", code)
      if (-1 == code.indexOf('X')) {
        return code;
      }
    }
    return false;
  };

  /**
   * Function to process all the orders.
   *  - Operation, add deleted flag false to all the orders that currently don't have it
   */
  processOrders = () => {
    // console.log("LOading all orders from Medable...");
    // this.orderService.getAllOrdersMedableNew().subscribe(ord => {
    //   // Get all orders from Medable
    //   let allOrders = [];
    //   [...ord].forEach(o => {
    //     allOrders = allOrders.concat(o.data);
    //   });
    //   console.log("*** All Medable orders loaded: ", allOrders);
    //   // Filter only for orders that don't have the c_deleted flag.
    //   let ordersWithoutFlag = allOrders.filter(
    //     ao => ao.c_deleted === undefined
    //   );
    //   console.log("Orders without flag: ", ordersWithoutFlag);
    //   // Create first batch to update
    //   let batch = ordersWithoutFlag.slice(0, 1000);
    //   console.log("First batch to update: ", batch);
    //   batch.forEach(b => {
    //     //console.log("Batch item: ", b);
    //     this.orderService
    //       .editOrder({ c_deleted: false }, b._id)
    //       .subscribe(updated => {
    //         console.log("order updated: ", updated);
    //       });
    //   });
    // });
  };

  loadExampleCreditNote = () => {
    console.log('Loading credit note from cin7...');
    this.orderService.getCin7CreditNote('1141').subscribe((cin7Note) => {
      console.log('*** Cin7 Credit Note: ', cin7Note);
    });
  };

  fileEvent(event) {
    // console.log("event: ", event)
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log('url: ', URL.createObjectURL(event.target.files[0]));

      let reader = new FileReader();
      reader.onload = (ev: any) => {
        const localUrl = ev.target.result;
        //console.log()
        let img = new Image();
        const self = this;
        img.onload = function () {
          const barcode = self.getBarcodeFromImage(this);
          // console.log("barcode: ", barcode)
          javascriptBarcodeReader(
            img /* Image file Path || {data: pixelArray, width, height} || HTML5 Canvas ImageData */,
            {
              barcode: 'code-2of5',
              type: 'industrial', //optional type
            }
          )
            .then((code) => {
              // console.log("Code: ", code)
            })
            .catch((err) => {
              // console.log("code erro: ", err)
            });
        };
        img.src = localUrl;

        //console.log("bardode: ", barcode)
      };
      //   const localUrl = event.target.result;
      //   console.log("localurl: ", localUrl)

      //   console.log("Barcode: ", barcode)
      //   // javascriptBarcodeReader(
      //   //         localUrl.replace(/^data:image\/(png|jpg);base64,/, "") /* Image file Path || {data: pixelArray, width, height} || HTML5 Canvas ImageData */,
      //   //         {
      //   //           barcode: 'code-2of5',
      //   //           type: 'industrial', //optional type
      //   //         }
      //   //       ).then(code => {
      //   //           console.log("Code: ", code)
      //   //         })
      //   //         .catch(err => {
      //   //           console.log("code erro: ", err)
      //   //         })
      // }
      reader.readAsDataURL(event.target.files[0]);
    }
    //   const file = files.item(0),
    //         path = URL.createObjectURL(file)
    //   // this.flow.files.push(file);
    //   console.log("File: ", file);
    //   console.log("Path: ", path)
    //   this.readURL(file).subscribe( result => {
    //     console.log("Image path: ", result)
    //     const imagePath = result['result'];
    //     javascriptBarcodeReader(
    //       "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/UPC-A-036000291452.svg/1200px-UPC-A-036000291452.svg.png" /* Image file Path || {data: pixelArray, width, height} || HTML5 Canvas ImageData */,
    //       {
    //         barcode: 'code-2of5',
    //         type: 'industrial', //optional type
    //       }
    //     ).then(code => {
    //         console.log("Code: ", code)
    //       })
    //       .catch(err => {
    //         console.log("code erro: ", err)
    //       })
    //   })

    // }
  }

  /**
   * This function should be used for the external access script.
   * If you have certain itemIds that are missing from the external access db
   * you can use this function to load all their orderIds, then use those orderIds
   * to load all the items ids from those orders and use them for the sync script.
   */
  getAllItemIdsfromSpecificOnes = () => {
    console.log('Loading all itemIds based on a specific set...');

    // Split the itemIds into smaller batches (if we have a lot of ids, the query will be too long resulting in an err)
    const batchSize = 100,
      specificItemsCnt = specificItemIds.length,
      batches = Math.ceil(specificItemsCnt / batchSize);

    // Will hold the batches to load the distinct orderIds.
    const specItemBatches = [];

    // Add batches to the specItemBatches array.
    for (let i = 0; i < batches; i++) {
      let batch = specificItemIds.splice(0, batchSize);
      specItemBatches.push(batch);
    }

    // Load all orderIds of the specific items.
    let requests = [];

    // Build batched requests array
    for (let i = 0; i < specItemBatches.length; i++) {
      let batchItemIds = specItemBatches[i],
        idsJoined = batchItemIds.map((id) => `"${id}"`).join(),
        idsFilter = `{"_id" : {"$in" : [${idsJoined}]}}`,
        where = `?where=${idsFilter}`,
        path = `paths[]=c_medable_order`,
        query = `${where}&${path}`;

      requests.push(this.formsService.get('orderLineItems', query));
    }

    // Run all requests
    forkJoin(requests).subscribe((res) => {
      let allOrderIds = [];
      // Build array of all order ids
      res.forEach((r) => {
        let _ids = r['data'].map((d) => d.c_medable_order._id);
        allOrderIds = allOrderIds.concat(_ids);
      });

      // Build list of distinct order ids
      let distinctOrderIds = [];
      allOrderIds.forEach((id) => {
        let added = distinctOrderIds.indexOf(id) > -1;
        if (!added) {
          distinctOrderIds.push(id);
        }
      });

      // Load all item ids of the distinct orderIds
      // Split these requests into batches as well
      let oidBatchSize = 100,
        oidBatchCnt = Math.ceil(distinctOrderIds.length / oidBatchSize),
        oidBatches = [];

      for (let j = 0; j < oidBatchCnt; j++) {
        const batch = distinctOrderIds.splice(0, oidBatchSize);
        oidBatches.push(batch);
      }

      //console.log("Oid Batches: ", oidBatches);

      let oidRequests = oidBatches.map((b) => {
        let filter = `{"c_medable_order" : {"$in" : [${b
            .map((b) => `"${b}"`)
            .join()}]}}`,
          path = `paths[]=_id`,
          limit = `limit=1000`,
          query = `?where=${filter}&${path}&${limit}`;

        return this.formsService.get('orderLineItems', query);
      });

      forkJoin(oidRequests).subscribe((itemIdsRes) => {
        console.log('ItemIds res: ', itemIdsRes);
        let idsResBatches = itemIdsRes.map((itmdR) => ({
          obj: itmdR,
          string: `[${itmdR.data.map((x) => `"${x._id}"`).join()}]`,
        }));

        let completeString = idsResBatches
          .map((o, idx) => `${idx + 1}.)\n${o.string}`)
          .join('\n');
        console.log('CompleteString: ', completeString);
      });
    });
  };
}
