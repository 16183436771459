import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class FormsService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;
  AWS_BUCKET_PATH: string = ENDPOINTS.AWS_BUCKET_PATH;

  httpOptions0 = {
    headers: new HttpHeaders({
      // 'Content-Type':  undefined
    }),
    withCredentials: false,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) { }

  sendFileAWS(file: File, response: any): Observable<any> {
    const fields = response.uploads[0].fields;

    const data = {};
    fields.map(function (obj) {
      data[obj.key] = obj.value;
    });

    const fd = new FormData();
    fd.append('x-amz-credential', data['x-amz-credential']);
    fd.append('x-amz-date', data['x-amz-date']);
    fd.append(
      'x-amz-server-side-encryption',
      data['x-amz-server-side-encryption']
    );
    fd.append('x-amz-signature', data['x-amz-signature']);
    fd.append('x-amz-algorithm', data['x-amz-algorithm']);
    fd.append('success_action_status', data['success_action_status']);
    fd.append('content-type', data['content-type']);
    fd.append('key', data['key']);
    fd.append('policy', data['policy']);
    fd.append('file', file);

    return this.http.post<any>(
      `${response.uploads[0].uploadUrl}`,
      fd,
      this.httpOptions0
    );
  }

  get(destination: string, query = ''): Observable<any> {
    const responseObservable = this.http.get<any>(
      `${this.API[destination] + query}`,
      this.httpOptions
    );
    return responseObservable.pipe(
      tap((response) => Sentry.setContext(`${destination}-response`, response.data)
      )
    );
  }

  delete(urlName: string, _id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API[urlName]}/${_id}`,
      this.httpOptions
    );
  }

  removeExtraInfo(obj: any) {
    delete obj.creator;
    delete obj.created;
    delete obj.favorite;
    delete obj.object;
    delete obj.owner;
    delete obj.shared;
    delete obj.access;
    delete obj.updated;
    delete obj.updater;
    delete obj.accessRoles;
    delete obj.c_order_document;
    return obj;
  }
}
