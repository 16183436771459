import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class HcpcsService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;
  constants = CONSTANTS;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  /**
   * Function to load all the hcpcs codes from medable
   */
  getAllHcpcsCodesMedable(): Observable<any> {
    const self = this;
    let allCodes = [];
    const promise = new Subject();
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.hcpcs_codes}?limit=1000&skip=` + page * 1000,
          self.httpOptions
        )
        .subscribe((response) => {
          allCodes = allCodes.concat(response.data);
          if (response.hasMore) {
            console.log('#HCPCS: hasMore');
            page++;
            loadAll();
          } else {
            console.log('Finished: loading HCPCS codes!');
            promise.next(allCodes);
            promise.complete();
          }
        });
    }

    loadAll();
    return promise;
  }

  /**
   * Function to create a new hcpcs code.
   */
  createHcpcsCpde(code): Observable<any> {
    const route = this.API.hcpcs_codes,
      opts = this.httpOptions;
    return this.http.post<any>(route, code, opts);
  }
}
