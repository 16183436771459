import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { LocationGroupsService } from '../../../services/location-groups/location-groups.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-location-groups-edit',
  templateUrl: './location-groups-edit.component.html',
  styleUrls: ['./location-groups-edit.component.css'],
})
export class LocationGroupsEditComponent implements OnInit {
  locationGroup: any = {};
  errorMessageContainer: any = {
    exists: false,
    message: '',
  };

  locationGroupId: string;
  //locationsInGroup: any[] = [];

  allLocations: any[] = [];
  associatedLocations: any[] = [];
  initialAssociatedLocationIds: any[] = [];
  locationInput = '';

  private sub;

  constructor(
    private locationGroupsService: LocationGroupsService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.loadAllLocations();
      this.locationGroupId = params.id ? params.id : '0';
      this.loadLocationGroup();
      console.log('GroupId: ', this.locationGroupId);
      // console.log("StateName: ", this.stateName);
      // this.actionBasedOnState();
    });
  }

  loadAllLocations() {
    this.locationService.getAllMedableLocations().subscribe((allLocations) => {
      this.allLocations = allLocations.map((loc) => {
        return {
          _id: loc._id,
          c_company: loc.c_company,
        };
      });
    });
  }

  searchLocation() {
    const search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const locations = this.allLocations.filter((location) => {
              const locationMatches =
                  location.c_company.toLowerCase().indexOf(term.toLowerCase()) >
                  -1,
                alreadyAdded =
                  this.associatedLocations.filter(
                    (assoc) => assoc._id == location._id
                  ).length > 0;
              if (locationMatches && !alreadyAdded) {
                return true;
              }
            });

            return locations.length ? locations.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  addLocation(location) {
    this.associatedLocations.push(location);
    this.locationInput = '';
  }

  removeLocation(locationIdx) {
    this.associatedLocations.splice(locationIdx, 1);
  }

  loadLocationGroup() {
    this.locationGroupsService
      .getLocationGroup(this.locationGroupId)
      .subscribe((locationGroup) => {
        console.log('LocationGroup: ', locationGroup);
        this.locationGroup = {
          c_group_name: locationGroup.c_group_name,
        };

        this.locationGroupsService
          .getLocationsByGroupId(this.locationGroupId)
          .subscribe((locations) => {
            // console.log("Locations in this group: ", locations);
            this.associatedLocations = locations.data;
            locations.data.map((loc) => {
              this.initialAssociatedLocationIds.push(loc._id);
            });
            console.log(
              'InitialAssociatedLocations: ',
              this.initialAssociatedLocationIds
            );
          });
      });
  }

  locationsFormatter = (x: { c_company: string }) => '';

  onSubmit() {
    this.checkDuplicateLocation().subscribe((duplicate) => {
      console.log('Duplicate? ', duplicate);
      if (!duplicate) {
        this.updateLocationGroup();
      } else {
        console.log('Duplicate Name');
        this.errorMessageContainer = {
          exists: true,
          message: '*Name Already Taken*',
        };
      }
    });
  }

  checkDuplicateLocation(): Observable<any> {
    const localSubject = new Subject();

    this.locationGroupsService
      .checkDuplicateByName(this.locationGroup.c_group_name, 10000)
      .subscribe((duplicates) => {
        const test = duplicates.data.filter((lGroup) => {
          console.log('Lgroup: ', lGroup);
          console.log('this groupName: ', this.locationGroup.c_group_name);
          const duplicate =
              lGroup.c_group_name.toLowerCase() ==
              this.locationGroup.c_group_name.toLowerCase(),
            sameDoc = lGroup._id == this.locationGroupId ? true : false;

          if (duplicate && !sameDoc) {
            return true;
          }
        });

        localSubject.next(test.length > 0);
        localSubject.complete();
      });

    return localSubject;
  }

  updateLocationGroup() {
    console.log('Updating locationGroup');
    const locationsToRemove = this.initialAssociatedLocationIds.filter(
      (locId) =>
        this.associatedLocations.filter((aLoc) => aLoc._id == locId).length == 0
    );
    console.log('Locations to remove: ', locationsToRemove);

    this.manageLocationsInGroup(locationsToRemove).subscribe(() => {
      this.updateLocationGroupObject();
    });
  }

  manageLocationsInGroup(locationsToRemove): Observable<any> {
    const localSubject = new Subject(),
      promises = [];

    locationsToRemove.map((locId) => {
      const obj = {
        op: 'unset',
        value: {
          c_location_group: 1,
        },
      };

      const promise = this.locationService.unsetLocationCategory(locId, obj);
      promises.push(promise);
    });

    this.associatedLocations.map((loc) => {
      const promise = this.locationService.editLocation(loc._id, {
        c_location_group: this.locationGroupId,
      });
      promises.push(promise);
    });

    forkJoin(promises).subscribe((responses) => {
      console.log('Locations Editted: ', responses);

      localSubject.next(responses);
      localSubject.complete();
    });

    return localSubject;
  }

  updateLocationGroupObject() {
    this.locationGroupsService
      .editLocationGroup(this.locationGroupId, this.locationGroup)
      .subscribe((updated) => {
        console.log('LocationGroup Updated: ', updated);
        this.gotoViewLocationGroup(this.locationGroupId);
      });
  }

  gotoViewLocationGroup(locationGroupId) {
    this.router.navigate([
      `forms/locationGroups/:id/view`,
      { id: locationGroupId },
    ]);
  }
}
