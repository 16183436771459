import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { addDays } from 'date-fns';
import { Subject } from 'rxjs';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormsService } from '../../services/forms/forms.service';
import { OrderService } from '../../services/order/order.service';
// import * as FileSaver from 'file-saver';;
declare var require: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  orderLineItemsFilter: any[] = [];

  minDateModel: IMyDateModel;
  maxDateModel: IMyDateModel;
  minBilledDateModel: IMyDateModel;
  maxBilledDateModel: IMyDateModel;

  hasRole: any = {};

  filterStatuses: string[] = ['any', 'dispatched', 'draft', 'billed'];
  selectedStatus: string = this.filterStatuses[0];

  lineItemsLoaded: boolean = false;

  filesaver = require('file-saver');
  ordersToProcess: number = -1;
  pageCount: any[] = [];
  exportData: any[] = [];
  exportDataOrder: any = [];
  csvHeader: any[] = [];

  allUsers: any;

  count: number = 0;
  actualPage: number = 0;

  locations: any[] = [];
  orders: any[] = [];
  prevMaxId: any[] = [];

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    disableSince: this.dateService.toDatepickerFormat(addDays(new Date(), 1)),
  };

  constructor(
    private authService: AuthService,
    private formsService: FormsService,
    private languagePipe: LanguagePipe,
    private router: Router,
    private orderService: OrderService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.initializeDate();
    this.getAllUsers();

    // this.getAllProvidersWSupervisors();
    // this.addBillingProvidersToOrders();

    if (this.hasRole.admin || this.hasRole.executive) {
      this.getOrders();
    } else {
      this.getLocationFitter();
    }
  }

  addBillingProvidersToOrders() {
    this.orderService.getAllOrdersMedableWSupervisors().subscribe((res) => {
      // console.log("All orders: ", res);
      const filteredLvl1 = res.array.filter((ord) => {
        const bPrvd = ord.c_provider_location.c_physician.c_billing_providers;
        if (bPrvd && bPrvd.length == 1 && !ord.c_billing_provider) {
          return true;
        }
      });
      // console.log("Filtered lvl1: ", filteredLvl1)
      const listToUpdate = filteredLvl1.map((ordObj) => {
        return {
          id: ordObj._id,
          obj: {
            c_billin_provider_added_by_scrips: true,
            c_billing_provider:
              ordObj.c_provider_location.c_physician.c_billing_providers[0]
                .c_provider._id,
          },
        };
      });

      // console.log("List to update: ", listToUpdate)
      const chunckToUpdate = listToUpdate.splice(0, 2000);
      // console.log("Chunk to update: ", chunckToUpdate)
      chunckToUpdate.map((toUpdate) => {
        this.orderService
          .editOrder(toUpdate.obj, toUpdate.id)
          .subscribe((updated) => {
            // console.log("Order succesfully updated...", updated)
          });
      });
    });
  }

  initializeDate() {
    const today = this.dateService.toUTCDate(new Date());
    this.minDateModel = this.dateService.toDatepickerModel(today);
    this.maxDateModel = this.dateService.toDatepickerModel(today);
  }

  addBilledFilters(filters) {
    if (this.minBilledDateModel) {
      const minBiledDateFormatted = this.dateService.format(
        this.minBilledDateModel
      );
      const filterString = `{"c_billed_date": {"$gte" : "${minBiledDateFormatted}"}}`;
      filters.push(filterString);
    }

    if (this.maxBilledDateModel) {
      const maxBilledDateFormatted = this.dateService.format(
        this.maxBilledDateModel
      );
      const filterString = `{"c_billed_date": {"$lte" : "${maxBilledDateFormatted}"}}`;
      filters.push(filterString);
    }
  }

  addOrderDateFilters(filters) {
    // `{"c_order_date" : {"$gte" : "${minDate}"}}`,
    // `{"c_order_date" : {"$lte" : "${maxDate}"}}`,
    if (this.minDateModel) {
      const minDateFormatted = this.dateService.format(this.minDateModel);
      const filterString = `{"c_order_date": {"$gte" : "${minDateFormatted}"}}`;
      filters.push(filterString);
    }

    if (this.maxDateModel) {
      const maxDateFormatted = this.dateService.format(this.maxDateModel),
        filterString = `{"c_order_date": {"$lte" : "${maxDateFormatted}"}}`;
      filters.push(filterString);
    }
  }

  addStatusFilter(filters) {
    switch (this.selectedStatus) {
      case 'dispatched':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": false}`);
        break;

      case 'draft':
        filters.push(`{"c_draft": true}`);
        break;

      case 'billed':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": true}`);
        break;
    }
  }

  getOrders(paginator = 0, specificPage = false) {
    this.lineItemsLoaded = false;
    let skip = 0;
    const sort = 'sort={"_id": -1}';

    if (specificPage) {
      skip = paginator * 10;
    } else {
      skip = this.actualPage * 10;
    }

    const limit = `limit=10&skip=${skip}`;

    const filterList = [
      this.hasRole.admin || this.hasRole.executive
        ? ''
        : `{"c_provider_location": {"$in":[${this.locations}]}}`,
    ];

    this.addOrderDateFilters(filterList);
    this.addBilledFilters(filterList);

    if (this.selectedStatus != 'any') {
      this.addStatusFilter(filterList);
    }

    const expandFields = [
      'creator',
      'c_patient',
      'c_insurance',
      'c_provider_location.c_physician',
      'c_provider_location.c_location',
      'c_designated_fitter',
      'c_fitter',
      'c_billing_provider',
    ];

    const filters =
      'where={"$and":[' +
      filterList.filter((filter) => filter != '').join(',') +
      ']}';
    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const query = `?${filters}&${expands}`;

    this.orderLineItemsFilter = [];
    this.orders = [];

    this.formsService
      .get('orders', query + `&${sort}&${limit}`)
      .subscribe((orders) => {
        this.ordersToProcess = orders.data.filter(
          (ord) => !ord.c_deleted
        ).length;
        orders.data
          .filter((ord) => !ord.c_deleted)
          .map((order) => {
            const orderClone = { ...order };
            orderClone.c_order_date = new Date(orderClone.c_order_date);
            orderClone.c_billed_date = orderClone.c_billed_date
              ? new Date(orderClone.c_billed_date)
              : null;
            orderClone.c_patient.c_dob = new Date(orderClone.c_patient.c_dob);
            orderClone['status'] = this.getOrderStatus(order);
            orderClone['creatorName'] = order.c_fitter
              ? this.getOrderCreator(order)
              : '-';
            orderClone['fitterName'] = order.c_designated_fitter
              ? this.getDesignatedFitter(order)
              : '-';
            orderClone['billingProviderName'] = order.c_billing_provider
              ? this.getBillingProviderName(order)
              : '-';
            this.orders.push(orderClone);
            this.getOrderLineItems(orderClone._id, this.orders.length - 1);
          });

        this.orders.map((pOrd) => {
          // console.log("Porders: ", pOrd);
          pOrd.display_order_date = this.medableDateToDisplay(
            pOrd.c_order_date
          );
          pOrd.display_billed_date = pOrd.c_billed_date
            ? this.medableDateToDisplay(pOrd.c_billed_date)
            : '-';
        });

        if (!this.ordersToProcess) {
          this.lineItemsLoaded = true;
        }
      });

    if (paginator === 0) {
      const groupBy = 'group={"_id": "object", "count":{"$count": "_id"}}';
      this.formsService
        .get('orders', query + '&' + groupBy)
        .subscribe((count) => {
          // console.log("Count: ", count);
          this.count = count.data[0] ? count.data[0].count : 0;
          this.pageCount = new Array(Math.ceil(this.count / 10));
          this.actualPage = 0;
          this.prevMaxId = [];
        });
    }
  }

  getOrderCreator(order) {
    interface User {
      firstName: string;
      lastName: string;
    }

    let creatorName: User;
    this.allUsers.forEach((user) => {
      if (user.id === order.creator._id) {
        creatorName = { ...user };
        console.log(creatorName);
      }
    });
    const firstName = creatorName.firstName;
    const lastName = creatorName.lastName;

    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  getAllUsers() {
    this.formsService.get('allFitters', '').subscribe((allUsers) => {
      console.log('All Users Loaded');
      this.allUsers = allUsers.data;
    });
  }

  getDesignatedFitter(order) {
    const creatorName = order.c_designated_fitter.name;
    const firstName = creatorName.first;
    const lastName = creatorName.last;

    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  getBillingProviderName(order) {
    const preffix = order.c_billing_provider.c_preffix
        ? `${order.c_billing_provider.c_preffix} `
        : ``,
      firstName = order.c_billing_provider.c_name.c_first_name,
      lastName = order.c_billing_provider.c_name.c_last_name,
      suffix = order.c_billing_provider.c_suffix
        ? ` ${order.c_billing_provider.c_suffix}`
        : '';

    return `${preffix}${firstName} ${lastName}${suffix}`;
  }

  getOrderStatus(order) {
    const status = order.c_draft
      ? 'Draft'
      : order.c_billed_date
      ? 'Billed'
      : 'Dispatched';

    return status;
  }

  medableDateToDisplay(medableDate) {
    // console.log("medblDate: ", medableDate.getUTCDate());
    const day =
      medableDate.getUTCDate() < 10
        ? `0${medableDate.getUTCDate()}`
        : medableDate.getUTCDate();
    const month =
      medableDate.getUTCMonth() + 1 < 10
        ? `0${medableDate.getUTCMonth() + 1}`
        : medableDate.getUTCMonth() + 1;
    const year = medableDate.getUTCFullYear();

    const dateString = `${month}/${day}/${year}`;
    // console.log("DateString: ", dateString);
    return dateString;
  }

  goToViewOrder(orderId) {
    const url = `/forms/orders/:id/view;id=${orderId}`
    window.open(url, '_blank');
  }

  goToViewPatient(patientId) {
    const url = `/forms/patients/:id/view;id=${patientId}`
    window.open(url, '_blank');
  }

  getOrderLineItems(orderId, orderIndex) {
    const expandFields = [
      'c_product',
      'c_product.c_supplier_id',
      'c_modifier.c_modifier_ref',
    ];

    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const filter = `where={"c_medable_order" : "${orderId}"}`;
    const query = `?${filter}&${expands}`;

    this.orderService.getOrderLineWithICD(query).subscribe((orderLineItems) => {
      //console.log("OrderLineItems: ", orderLineItems);
      // Filter to exclude deleted orderItems
      orderLineItems.data
        .filter((oi) => !oi.c_deleted)
        .map((orderLineItem) => {
          const orderLineItemObject = { ...orderLineItem };
          orderLineItemObject.order = this.orders[orderIndex];
          const oPhysician =
            orderLineItemObject.order.c_provider_location.c_physician;
          const ppfx = oPhysician.c_preffix ? `${oPhysician.c_preffix} ` : '';
          const pfn = oPhysician.c_name.c_first_name;
          const pln = oPhysician.c_name.c_last_name;
          const psfx = oPhysician.c_suffix ? ` ${oPhysician.c_suffix}` : '';
          orderLineItemObject.order[
            'providerName'
          ] = `${ppfx}${pfn} ${pln}${psfx}`;
          orderLineItemObject['modifiers'] = '-';
          // console.log("OrderLineItemObj: ", orderLineItemObject.c_modifier);
          const modifiers = orderLineItemObject.c_modifier.filter(
            (m) => m.c_checked && !m.c_modifier_ref.c_warning
          );
          const warnings = orderLineItemObject.c_modifier.filter(
            (m) => m.c_modifier_ref.c_warning && m.c_checked
          );
          if (modifiers.length) {
            orderLineItemObject['modifiers'] = modifiers
              .map((m) => m.c_modifier)
              .join(' | ');
          }
          if (warnings.length) {
            orderLineItemObject['warnings'] = warnings
              .map((w) => w.c_modifier)
              .join(' | ');
          }

          if (!orderLineItemObject.c_sales_tax) {
            orderLineItemObject.c_sales_tax = 0;
          }
          if (orderLineItemObject.c_product.c_product_options != undefined) {
            orderLineItemObject.c_product.cpy_product_option = orderLineItemObject.c_product.c_product_options.filter(
              (opt) => opt.c_code === orderLineItemObject.c_product_option_code
            )[0];
          }

          if (orderLineItemObject.c_secondary_insurance) {
            // console.log("ORder with secondary insurance: ", orderLineItemObject);
          }
          this.orderLineItemsFilter.push(orderLineItemObject);
        });
      this.ordersToProcess--;

      this.lineItemsLoaded = true;
      // console.log("Loaded Order List: ", this.orderLineItemsFilter);
    });
  }

  searchFormValid() {
    const valid =
      (this.minDateModel && this.maxDateModel) ||
      (this.minBilledDateModel && this.maxBilledDateModel);
    return valid;
  }

  getLocationFitter() {
    // console.log("Getting LocationFitters");

    this.formsService.get('accounts').subscribe((account) => {
      const fitterId = account.data[0]._id;
      // console.log("FitterID: ", fitterId);

      // get the locations of the user
      this.formsService
        .get(
          'account_locations',
          '?paths[]=c_location._id&where={"c_fitter":"' + fitterId + '"}'
        )
        .subscribe((locations) => {
          let locationsId = locations.data.map(function (obj) {
            return '"' + obj.c_location._id + '"';
          });
          // get the provider-locations id with the locations where the user belongs to
          this.formsService
            .get(
              'providerLocations',
              '?paths[]=_id&where={"c_location._id":{"$in":[' +
                locationsId +
                ']}}&limit=1000'
            )
            .subscribe((providerLocations) => {
              this.locations = providerLocations.data.map(function (obj) {
                return `"${obj._id}"`;
              });
              // console.log("this locations: ", this.locations);
              this.getOrders();
            });
        });
    });
  }

  nextPage() {
    this.actualPage++;
    this.getOrders(1);
  }

  prevPage() {
    this.actualPage--;
    this.getOrders(-1);
  }

  goToPage(pageNumber) {
    this.actualPage = pageNumber;
    this.getOrders(pageNumber, true);
  }

  generateFileNameMin() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'
    if (this.minDateModel) {
      fnm = this.dateService.format(this.minDateModel, 'MM_dd_yyyy');
    } else if (this.minBilledDateModel) {
      fnm = this.dateService.format(this.minBilledDateModel, 'MM_dd_yyyy');
    }

    return fnm;
  }

  generateFileNameMax() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'
    if (this.maxDateModel) {
      fnm = this.dateService.format(this.maxDateModel, 'MM_dd_yyyy');
    } else if (this.maxBilledDateModel) {
      fnm = this.dateService.format(this.maxBilledDateModel, 'MM_dd_yyyy');
    }

    return fnm;
  }

  /**
   * Helper
   * Function to get insurance name from order
   */
  getInsuranceName = (order) => {
    if (!order.c_insurance) {
      return '-';
    }

    if (!order.c_insurance.c_insurance_name) {
      return '-';
    }

    return order.c_insurance.c_insurance_name.replace(/"/g, "''");
  };

  /**
   * Function to get an item's HCPCS.
   * If multiple available return choosen one, if not, return the product's
   */
  getItemHCPCS = (item) => item.c_selected_hcpcs || item.c_product.c_hcpcs_code;

  /**
   * Function to return the order's date
   */
  getOrderDate = (order) => {
    if (!order.c_order_date) {
      return '-';
    }

    return this.medableDateToDisplay(new Date(order.c_order_date));
  };

  /**
   * Function to return the order's billed date if available.
   */
  getOrderBilledDate = (order) => {
    if (!order.c_billed_date) {
      return '-';
    }

    return this.medableDateToDisplay(new Date(order.c_billed_date));
  };

  /**
   * Function to get the patient's MRN.
   */
  getPatientMRN = (order) => {
    if (!order.c_patient || !order.c_patient.c_mrn) {
      return '-';
    }

    return order.c_patient.c_mrn;
  };

  /**
   * Function to get the patient's full name.
   */
  getPatientFullName = (order) => {
    if (!order.c_patient || !order.c_patient.c_name) {
      return '-';
    }

    let nameObj = order.c_patient.c_name,
      first = nameObj.c_first_name.replace(/"/g, "''"),
      last = nameObj.c_last_name.replace(/"/g, "''"),
      full = `${first} ${last}`;

    return full;
  };

  /**
   * Function to get product name.
   */
  getProductName = (item) => {
    if (!item.c_product || !item.c_product.c_name) {
      return '-';
    }

    return item.c_product.c_name.replace(/"/g, "''");
  };

  /**
   * Function to get the product's size.
   */
  getProductSize = (item) => {
    let prod = item.c_product || {},
      option = prod.cpy_product_option || {},
      size = option.c_size || '-';

    return size.replace(/"/g, "''");
  };

  /**
   * Function to get produtc's supplier company name.
   */
  getSupplierCompany = (item) => {
    let prod = item.c_product,
      supplId = prod.c_supplier_id;

    if (!supplId) {
      return '-';
    }

    return supplId.c_company.replace(/"/g, "''");
  };

  /**
   * Function to get the product location's company name.
   */
  getLocationCompanyName = (order) => {
    let provLocation = order.c_provider_location || {},
      location = provLocation.c_location || {},
      company = (location.c_company || '-').replace(/"/g, "''");

    return company;
  };

  /**
   * Function to get the order's modifiers
   */
  getModifiers = (item, warning = false) => {
    if (!item.c_modifier) {
      return '-';
    }

    let filtered = [];
    if (warning) {
      filtered = item.c_modifier.filter(
        (m) => m.c_checked && m.c_modifier_ref.c_warning
      );
    } else {
      filtered = item.c_modifier.filter(
        (m) => m.c_checked && !m.c_modifier_ref.c_warning
      );
    }

    return filtered.map((m) => m.c_modifier).join(' | ');
  };

  exportDashboard() {
    this.csvHeader = [
      this.languagePipe.transform('dashboard.order'),
      this.languagePipe.transform('dashboard.date'),
      this.languagePipe.transform('dashboard.status'),
      this.languagePipe.transform('dashboard.billed_date'),
      this.languagePipe.transform('dashboard.claim_id'),
      this.languagePipe.transform('dashboard.mrn'),
      this.languagePipe.transform('dashboard.patient_name'),
      this.languagePipe.transform('dashboard.creator.name'),
      this.languagePipe.transform('dashboard.fitter.name'),
      this.languagePipe.transform('orders.order-form.label.orderingProvider'),
      this.languagePipe.transform('dashboard.billingProvider.name'),
      this.languagePipe.transform('dashboard.hcpcs'),
      this.languagePipe.transform('dashboard.item_name'),
      this.languagePipe.transform('dashboard.quantity'),
      this.languagePipe.transform('dashboard.size'),
      this.languagePipe.transform('dashboard.vendor'),
      this.languagePipe.transform('dashboard.location'),
      this.languagePipe.transform('dashboard.insurance'),
      this.languagePipe.transform('dashboard.modifiers'),
      this.languagePipe.transform('dashboard.warnings'),
      this.languagePipe.transform('dashboard.charge_out_cost'),
      this.languagePipe.transform('dashboard.allowable_cost'),
      this.languagePipe.transform('dashboard.patient_payment'),
      this.languagePipe.transform('dashboard.sales_tax'),
    ];

    const min = this.generateFileNameMin(),
      max = this.generateFileNameMax(),
      csvName = 'Export ' + min + ' - ' + max + '.csv',
      self = this;

    this.exportData = [];
    this.exportDataOrder = [];

    // const self = this;

    // console.log("Getting Export Data...");
    this.getExportData().subscribe(() => {
      // console.log("Data Ready");
      let fileTxt = self.exportData.map(function (obj) {
        let order = self.exportDataOrder.filter(function (ord) {
          return ord._id === obj.c_medable_order._id;
        })[0];
        // console.log("obj: ", obj);
        // console.log("order: ", order);
        let oli = [
          `"${order.c_number_id || '-'}"`,
          `"${self.getOrderDate(order)}"`,
          `"${order.status || '-'}"`,
          `"${self.getOrderBilledDate(order)}"`,
          `"${order.c_claim_id || '-'}"`,
          `"${self.getPatientMRN(order)}"`,
          `"${self.getPatientFullName(order)}"`,
          `"${order.status ? order.creatorName || '-' : '-'}"`,
          `"${order.status ? order.fitterName || '-' : '-'}"`,
          `"${order.providerName || '-'}"`,
          `"${order.billingProviderName || '-'}"`,
          `"${self.getItemHCPCS(obj) || '-'}"`,
          `"${self.getProductName(obj)}"`,
          `"${obj.c_quantity || '-'}"`,
          `"${self.getProductSize(obj)}"`,
          `"${self.getSupplierCompany(obj)}"`,
          `"${self.getLocationCompanyName(order)}"`,
          `"${order.c_insurance ? self.getInsuranceName(order) : '-'}"`,
          `"${self.getModifiers(obj)}"`,
          `"${self.getModifiers(obj, true)}"`,
          `"${obj.c_charge_out_cost || '-'}"`,
          `"${obj.c_allowable_cost || '-'}"`,
          `"${obj.c_self_pay_cost || '-'}"`,
          `"${obj.c_sales_tax || '-'}"`,
        ];

        return oli.join(',');
      });

      let mappedTxt = fileTxt.join('\n');
      mappedTxt = self.csvHeader.join(',') + '\n' + mappedTxt;
      const file = new Blob([mappedTxt], { type: 'text/plain;charset=utf-8' });
      file['name'] = csvName;
      this.filesaver.saveAs(file);
    });
  }

  getExportData() {
    const localSubject = new Subject();
    let allOrders = [];
    const chunck = 100;
    // const minDate = this.dateToMedableFormat(this.minDate);
    // const maxDate = this.dateToMedableFormat(this.maxDate);
    const sort = 'sort={"_id": -1}';
    let page = 0;
    const self = this;

    const filterList = [
      this.hasRole.admin || this.hasRole.executive
        ? ''
        : `{"c_provider_location": {"$in":[${this.locations}]}}`,
    ];

    this.addOrderDateFilters(filterList);
    this.addBilledFilters(filterList);

    const expandFields = [
      'creator',
      'c_patient',
      'c_insurance',
      'c_provider_location.c_physician',
      'c_provider_location.c_location',
      'c_designated_fitter',
      'c_fitter',
      'c_billing_provider',
    ];

    const filters =
      'where={"$and":[' +
      filterList.filter((filter) => filter != '').join(',') +
      ']}';
    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const query = `?${filters}&${expands}&${sort}`;

    function loadChunck() {
      self.formsService
        .get('orders', query + `&limit=${chunck}&skip=${page * chunck}`)
        .subscribe((orders) => {
          console.log(
            `**-_-Batch for page ${page} loaded, ${orders.data.length} orders.`
          );
          const ids = [];
          // Filter to exclude deleted orders
          orders.data
            .filter((ord) => !ord.c_deleted)
            .map((order) => {
              const orderObj = { ...order };

              const oPhysician = orderObj.c_provider_location.c_physician;
              const ppfx = oPhysician.c_preffix
                ? `${oPhysician.c_preffix} `
                : '';
              const pfn = oPhysician.c_name.c_first_name;
              const pln = oPhysician.c_name.c_last_name;
              const psfx = oPhysician.c_suffix ? ` ${oPhysician.c_suffix}` : '';
              orderObj.c_order_date = new Date(orderObj.c_order_date);
              orderObj.c_patient.c_dob = new Date(orderObj.c_patient.c_dob);
              orderObj['status'] = self.getOrderStatus(order);
              orderObj['creatorName'] = order.c_fitter
                ? self.getOrderCreator(order)
                : '-';
              orderObj['fitterName'] = order.c_designated_fitter
                ? self.getDesignatedFitter(order)
                : '-';
              orderObj['providerName'] = `${ppfx}${pfn} ${pln}${psfx}`;
              orderObj['billingProviderName'] = order.c_billing_provider
                ? self.getBillingProviderName(order)
                : '-';
              ids.push(`"${orderObj._id}"`);
              self.exportDataOrder.push(orderObj);
            });

          self.getOrderLineItemsExport(ids).subscribe(() => {
            if (orders.hasMore) {
              page++;
              loadChunck();
            } else {
              localSubject.next();
              localSubject.complete();
            }
          });
        });
    }

    loadChunck();
    return localSubject;
  }

  getOrderLineItemsExport(ids) {
    const localSubject = new Subject();
    const expands =
      'expand[]=c_product&expand[]=c_product.c_supplier_id&expand[]=c_modifier.c_modifier_ref';
    const filter = `where={"c_medable_order":{"$in":[${ids}]}}`;
    const query = `?${filter}&${expands}`;

    this.formsService
      .get('orderLineItems', query + '&limit=1000')
      .subscribe((data) => {
        data = data.data.filter((ln) => !ln.c_deleted);
        // console.log("ExportLineItems: ", data);
        // Filter to only export lineitems that are not deleted
        data.map((line) => {
          if (line.c_product.c_product_options != undefined) {
            line.c_product.cpy_product_option = line.c_product.c_product_options.filter(
              (opt) => opt.c_code === line.c_product_option_code
            )[0];
          } else {
            line.c_product.cpy_product_option = '-';
          }
        });
        this.exportData = this.exportData.concat(data);

        localSubject.next(data);
        localSubject.complete();
      });

    return localSubject;
  }
}
