import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormsService } from '../../services/forms/forms.service';
import { OrderService } from '../../services/order/order.service';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})
export class OrderListComponent implements OnInit {
  stateCreate = false;

  orders: any[] = [];
  locations: any[] = [];
  hasRole: any;

  patientName = '';
  patientDobModel: IMyDateModel;
  patientMrn = '';
  orderNumber: string;

  searchOrder = '';

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private state: ActiveStateService,
    private formsService: FormsService,
    private orderService: OrderService,
    private patientService: PatientService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();

    // this.syncItemsToORders();

    // if(this.hasRole.admin || this.hasRole.executive)
    this.getLocationFitter();
    if (this.state.is('forms.ordersCreate')) {
      this.stateCreate = true;
    }

    this.getLatestOrders();
  }

  buildOrderNrFilter() {
    if (!this.orderNumber || this.orderNumber === '') {
      return '';
    }

    return `where={"c_number_id": ${this.orderNumber}}`;
  }

  buildPatientsFilter() {
    const filterList = [
      this.buildPatientNameFilter(),
      this.buildPatientMrnFilter(),
      this.buildPatientDobFilter(),
    ];

    if (filterList.filter((f) => f != '').length === 0) {
      return '';
    }

    const and = `"$and": [${filterList.filter((f) => f != '').join(',')}]`;
    const filter = `where={${and}}`;

    return filter;
  }

  buildPatientMrnFilter() {
    let filter = '';
    if (this.patientMrn === '') {
      return filter;
    }

    filter = `{"c_mrn": "${this.patientMrn}"}`;

    return filter;
  }

  buildPatientDobFilter() {
    if (!this.patientDobModel) {
      return '';
    }

    const patientDobFormatted = this.dateService.format(
      this.patientDobModel
    );

    const filter = `{"c_dob": "${patientDobFormatted}"}`;
    return filter;
  }

  buildPatientNameFilter() {
    if (this.patientName === '') {
      return this.patientName;
    }
    const splittedName = this.patientName
      .split(' ')
      .filter((pn) => pn != '')
      .slice(0, 2);

    let condition = '';

    if (splittedName.length === 1) {
      const singleName = splittedName[0];
      const conditions = [
        `{"c_name.c_first_name": {"$regex":"/${singleName}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${singleName}/i"}}`,
      ];
      condition = `{"$or": [${conditions.join(',')}]}`;
    } else {
      const conditions = [
        `{"c_name.c_first_name": {"$regex":"/${splittedName[0]}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${splittedName[1]}/i"}}`,
        `{"c_name.c_first_name": {"$regex":"/${splittedName[1]}/i"}}`,
        `{"c_name.c_last_name": {"$regex":"/${splittedName[0]}/i"}}`,
      ];

      const set1 = conditions.slice(0, 2).join(',');
      const set2 = conditions.slice(2, 4).join(',');

      condition = `{"$or": [{"$and": [${set1}]}, {"$and": [${set2}]}]}`;
    }

    return condition;
  }

  getLocationFitter() {
    this.formsService.get('accounts').subscribe((account) => {
      const fitterId = account.data[0]._id;
      // const fitterId = "5c2fcfc04b590801002a10c5";
      // get the locations of the user
      this.formsService
        .get(
          'account_locations',
          '?paths[]=c_location._id&where={"c_fitter":"' + fitterId + '"}'
        )
        .subscribe((locations) => {
          // console.log("Fitter's Locations: ", locations);
          const locationsId = locations.data.map((obj) => {
            return '"' + obj.c_location._id + '"';
          });
          // get the provider-locations id with the locations where the user belongs to
          this.formsService
            .get(
              'providerLocations',
              '?paths[]=_id&where={"c_location._id":{"$in":[' +
                locationsId +
                ']}}&limit=1000'
            )
            .subscribe((providerLocations) => {
              // console.log("This fitter's providerLocations: ", providerLocations);
              this.locations = providerLocations.data.map((obj) => {
                return '"' + obj._id + '"';
              });
              // console.log("This Locations: ", this.locations);
            });
        });
    });
  }

  goToViewOrder(orderId) {
    this.router.navigate(['forms/orders/:id/view', { id: orderId }]);
  }

  goToCreateOrder() {
    this.router.navigate(['forms/orders/new']);
  }

  searchOrdersByInput() {
    const patientsFilter = this.buildPatientsFilter();
    const orderNrFilter = this.buildOrderNrFilter();

    if (patientsFilter === '' && orderNrFilter === '') {
      return;
    } else if (patientsFilter != '') {
      // console.log("patient search ")
      this.patientService
        .getPatientsByFilter(patientsFilter)
        .subscribe((patients) => {
          const patientIds = patients.map((p) => `"${p._id}"`);
          if (this.hasRole.admin || this.hasRole.executive) {
            this.getOrdersByPatients(patientIds);
            // this.getOrdersByPatientsAndLocation(patientIds);
          } else {
            this.getOrdersByPatientsAndLocation(patientIds);
          }
        });
    } else if (orderNrFilter != '') {
      // console.log("Oly order nr")
      this.orderService
        .getOrderByNumberId(orderNrFilter)
        .subscribe((orders) => {
          orders.data.map((order) => {
            order.c_order_date_show = this.medableDisplayDate(
              order.c_order_date
            );
            order.created_display_date = this.medableDisplayDate(order.created);
          });
          this.orders = orders.data;
          // console.log("orders by nr id loaded: ", orders.data)
        });
    }
  }

  getLatestOrders() {
    // this.hasRole.admin || this.hasRole.executive &&
    if (this.hasRole.admin || this.hasRole.executive) {
      this.getLatestOrdersAll();
    } else {
      this.getLatestOrdersByLocation();
    }
  }

  getLatestOrdersAll() {
    this.orderService.getLatestOrdersAll().subscribe((initOrders) => {
      initOrders.data.map((ord) => {
        ord.c_order_date_show = this.medableDisplayDate(ord.c_order_date);
      });
      this.orders = initOrders.data;
    });
  }

  getLatestOrdersByLocation() {
    this.formsService.get('accounts').subscribe((account) => {
      const fitterId = account.data[0]._id;
      // const fitterId = "5bb3867ae277470100386736";
      // get the locations of the user
      this.formsService
        .get(
          'account_locations',
          '?paths[]=c_location._id&where={"c_fitter":"' + fitterId + '"}'
        )
        .subscribe((locations) => {
          const locationsId = locations.data.map((obj) => {
            return '"' + obj.c_location._id + '"';
          });
          // get the provider-locations id with the locations where the user belongs to
          this.formsService
            .get(
              'providerLocations',
              '?paths[]=_id&where={"c_location._id":{"$in":[' +
                locationsId +
                ']}}&limit=1000'
            )
            .subscribe((providerLocations) => {
              this.locations = providerLocations.data.map((obj) => {
                return '"' + obj._id + '"';
              });
              this.orderService
                .getLatestOrdersByLocation(this.locations)
                .subscribe((initOrders) => {
                  initOrders.data.map((order) => {
                    order.c_order_date_show = this.medableDisplayDate(
                      order.c_order_date
                    );
                    order.created_display_date = this.medableDisplayDate(
                      order.created
                    );
                  });
                  this.orders = initOrders.data;
                });
            });
        });
    });
  }

  getOrdersByPatientsAndLocation(patientIds) {
    // console.log("Getting orders for locations: ", this.locations);
    this.orderService
      .getOrdersForPatientsAndLocations(patientIds, this.locations)
      .subscribe((fOrders) => {
        // console.log("Orders By Location: ", fOrders);
        fOrders.data.map((order) => {
          order.c_order_date_show = this.medableDisplayDate(order.c_order_date);
          order.created_display_date = this.medableDisplayDate(order.created);
        });

        this.orders = fOrders.data.sort(function (a, b) {
          const dateA = new Date(a.c_order_date).getTime();
          const dateB = new Date(b.c_order_date).getTime();
          const cDateA = new Date(new Date(a.created).toDateString()).getTime();
          const cDateB = new Date(new Date(b.created).toDateString()).getTime();
          // console.log("Created(raw): ", a.created, " Created(Date): ", new Date(new Date(a.created).toDateString()).getTime() );

          if (cDateA < cDateB) {
            return 1;
          }
          if (cDateA > cDateB) {
            return -1;
          }
          if (dateA < dateB) {
            return 1;
          }
          if (dateA > dateB) {
            return -1;
          }

          return 0;
        });

        if (this.orderNumber) {
          // console.log("this order number filtering: ", this.orderNumber)
          this.orders = this.orders.filter(
            (o) => parseInt(o.c_number_id) === parseInt(this.orderNumber)
          );
        }
        // console.log("Listed Orders: ", this.orders);
      });
  }

  getOrdersByPatients(patientIds) {
    this.orderService.getOrdersForPatients(patientIds).subscribe((fOrders) => {
      // console.log("Found ORders: ", fOrders);
      fOrders.data.map((order) => {
        order.c_order_date_show = this.medableDisplayDate(order.c_order_date);
        order.created_display_date = this.medableDisplayDate(order.created);
      });

      this.orders = fOrders.data.sort(function (a, b) {
        const dateA = new Date(a.c_order_date).getTime();
        const dateB = new Date(b.c_order_date).getTime();
        const cDateA = new Date(new Date(a.created).toDateString()).getTime();
        const cDateB = new Date(new Date(b.created).toDateString()).getTime();
        // console.log("Created(raw): ", a.created, " Created(Date): ", new Date(new Date(a.created).toDateString()).getTime() );

        if (cDateA < cDateB) {
          return 1;
        }
        if (cDateA > cDateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }

        return 0;
      });
      if (this.orderNumber) {
        // console.log("this order number filtering: ", this.orderNumber)
        this.orders = this.orders.filter(
          (o) => parseInt(o.c_number_id) === parseInt(this.orderNumber)
        );
      }
      // console.log("Listed Orders: ", this.orders);
    });
  }

  medableDisplayDate(medableDate) {
    const dateObj = new Date(medableDate);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();

    const displayMonth = month < 10 ? `0${month}` : month;
    const displayDay = day < 10 ? `0${day}` : day;

    return `${displayMonth}/${displayDay}/${year}`;
  }

  syncItemsToORders = () => {
    console.log('load all orderItems from medable....');
    this.orderService.getAllOrderItems().subscribe((items) => {
      console.log('All medable orderItems loaded: ', items);
    });
  };
}
