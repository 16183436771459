<div class="forms-form fitter_insurance_form">
  <div
    class="pull-right"
    *ngIf="(hasRole.admin || hasRole.executive) && !canEdit && showFitter"
  >
    <button class="button edit-button" (click)="goToEditFitter()">
      {{ 'fitter.edit' | lang }}
    </button>
  </div>
  <form
    (ngSubmit)="f.form.valid && onSubmit()"
    #f="ngForm"
    class="step"
    novalidate
  >
    <div class="row">
      <div class="col-xs-12">
        <h2 *ngIf="canEdit || showFitter">
          {{ selectedFitter.name.first }} {{ selectedFitter.name.last }}
        </h2>
        <h3 *ngIf="canEdit || showFitter">{{ selectedFitter.email }}</h3>
        <label *ngIf="!canEdit && !showFitter">
          {{ 'fitter.search_fitter' | lang }}
        </label>
        <div *ngIf="!canEdit && !showFitter" class="autocomplete-search">
          <input
            name="searchFitters"
            id="autocomplete_fitters"
            type="text"
            class="highlight"
            [(ngModel)]="fittersAutocomplete"
            [ngbTypeahead]="searchFitters"
            [resultTemplate]="autocompleteResultsFitters"
            (ngModelChange)="onFitterSelected()"
            placeholder="{{ 'fitter.search_fitter' | lang }}"
            [inputFormatter]="formatterFitters"
          />
          <ng-template #autocompleteResultsFitters let-r="result" let-t="term">
            {{ r.name.first }} {{ r.name.last }} {{ r.email }}
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showFitter">
      <div
        class="col-xs-12 col-md-6"
        *ngIf="hasRole.admin && canEdit"
        [ngClass]="{
          'required-input': f.form.invalid && (f.form.dirty || f.form.touched)
        }"
      >
        <label>
          {{ 'fitter.search_location' | lang }}
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>

        <div class="autocomplete-search">
          <input
            name="searchLocations"
            id="autocomplete_locations"
            type="text"
            class="highlight"
            [(ngModel)]="locationsAutocomplete"
            [ngbTypeahead]="searchLocations"
            [resultTemplate]="autocompleteResultsLocations"
            (ngModelChange)="onLocationSelected()"
            placeholder="{{ 'fitter.search_location' | lang }}"
            [inputFormatter]="formatterLocations"
          />
          <ng-template
            #autocompleteResultsLocations
            let-r="result"
            let-t="term"
          >
            {{ r.c_company }} - {{ r.c_address.c_postal_city }}
          </ng-template>
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <label>{{ 'fitter.related_locations' | lang }}</label>
        <ul class="location-table">
          <div *ngIf="!canEdit">
            <div *ngFor="let location of relatedLocationsList">
              <li *ngIf="!location.deleted">
                {{ location.c_location.c_company }} -
                {{ location.c_location.c_address.c_postal_city }}
                <button
                  type="button"
                  *ngIf="hasRole.admin && canEdit"
                  class="delete-trash pull-right"
                  (click)="removeLocation(location)"
                >
                  <div></div>
                </button>
                <div class="clearfix"></div>
              </li>
            </div>
          </div>
          <div *ngIf="canEdit">
            <div *ngFor="let location of relatedLocationsList">
              <li *ngIf="!location.deleted">
                {{ location.c_location.c_company || location.c_company }} -
                {{
                  location.c_location.c_address.c_postal_city ||
                    location.c_address.c_postal_city
                }}
                <button
                  type="button"
                  *ngIf="hasRole.admin && canEdit"
                  class="delete-trash pull-right"
                  (click)="removeLocation(location)"
                >
                  <div></div>
                </button>
                <div class="clearfix"></div>
              </li>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12" *ngIf="showFitter && canEdit">
        <button
          class="button next-button"
          type="submit"
          [disabled]="buttonsDisabled"
        >
          {{ 'fitter.save_info' | lang }}
        </button>
        <!-- <button class="button back-button" type="cancel" (click)="cancel()" [disabled]="buttonsDisabled">{{ 'fitter.cancel' | lang }}</button> -->
        <button
          class="button back-button"
          type="cancel"
          (click)="goToBack()"
          [disabled]="buttonsDisabled"
        >
          {{ 'fitter.back' | lang }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" *ngIf="showFitter && !canEdit">
        <button class="button back-button" type="cancel" (click)="goToBack()">
          {{ 'fitter.back' | lang }}
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="fitterSubmited">
    <p>{{ 'fitter.submited' | lang }}</p>
  </div>
</div>
