<header class="header">
  <div
    class="open_menu"
    [ngClass]="{ open: isSidebarOpen$ | async }"
    (click)="toggleSidebar()"
  ></div>
  <div class="name_container" (click)="onShowLogout()">
    <div class="icon-logout"></div>
    ​
    <label>
      <b>{{ user_name.first }} {{ user_name.last }}</b>
      <i class="fa fa-angle-down fa-fw" aria-hidden="true"></i>
    </label>
  </div>
  <div class="logout_container" *ngIf="showLogout" (mouseleave)="mouseLeave()">
    <a role="button" (click)="logout()">{{ 'logout' | lang }}</a>
  </div>
</header>
