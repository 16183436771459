import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class LocationService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  httpOptionsAux = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
  };

  constructor(private http: HttpClient) {}

  createLocation(location: any): Observable<any> {
    console.log('Creating Location: ', location);
    return this.http.post<any>(
      `${this.API.locations}`,
      location,
      this.httpOptions
    );
  }

  createContact(contact: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.supplier}`,
      contact,
      this.httpOptions
    );
  }

  createProviderReference(provider: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.providerLocations}`,
      provider,
      this.httpOptions
    );
  }

  createFitterReference(provider: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.account_locations}`,
      provider,
      this.httpOptions
    );
  }

  createInsuranceReference(insurance: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.insuranceLocations}`,
      insurance,
      this.httpOptions
    );
  }

  editContact(contactId: string, contact: any): Observable<any> {
    delete contact.acl;
    return this.http.put<any>(
      `${this.API.supplier}/${contactId}`,
      contact,
      this.httpOptions
    );
  }

  editLocation(locationId: string, location: any): Observable<any> {
    delete location.acl;
    return this.http.put<any>(
      `${this.API.locations}/${locationId}`,
      location,
      this.httpOptions
    );
  }

  unsetLocationCategory(locationId: string, location: any): Observable<any> {
    delete location.acl;
    return this.http.patch<any>(
      `${this.API.locations}/${locationId}`,
      location,
      this.httpOptions
    );
  }

  getLocation(locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.locations}/${locationId}`,
      this.httpOptions
    );
  }

  getProviders(locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.providerLocations}?paths[]=c_physician&where={"c_location":{"$in": ["${locationId}"]}}&expand[]=c_physician`,
      this.httpOptions
    );
  }

  getLocations(page: number): Observable<any> {
    const self = this;
    const localSubject = new Subject();
    let allLoc = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.locations}?limit=1000&skip=${page * 1000}`,
          self.httpOptions
        )
        .subscribe((response) => {
          allLoc = allLoc.concat(response.data);
          if (response.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLoc);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getLocationsFromCin7(page: number): Observable<any> {
    const self = this;
    const localSubject = new Subject();
    let allLoc = [];
    const query = ['rows=250', `page=${page}`, 'order=modifiedDate,id ASC'];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.supplierCin7}?` + query.join('&'),
          self.httpOptions
        )
        .subscribe((response) => {
          allLoc = allLoc.concat(response.data);
          console.log('LocationsFromCin7: ', response);
          if (response.data.length === 250) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLoc);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getCin7Branches(page: number = 1): Observable<any> {
    const self = this;
    const localSubject = new Subject();
    let allLoc = [];
    const query = ['rows=250', `page=${page}`, 'order=modifiedDate,id ASC'];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.branchesCin7}?` + query.join('&'),
          self.httpOptions
        )
        .subscribe((response) => {
          allLoc = allLoc.concat(response.data);
          console.log('Brances from cin7: ', response);
          if (response.data.length === 250) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLoc);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getLocationWithCin7Id(locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.locations}?where={"c_cin7_id":"${locationId}"}`,
      this.httpOptions
    );
  }

  getMedableContacts(page: number): Observable<any> {
    const self = this;
    const localSubject = new Subject();
    let allLoc = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.supplier}?limit=1000&skip=${page * 1000}`,
          self.httpOptions
        )
        .subscribe((response) => {
          allLoc = allLoc.concat(response.data);
          if (response.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLoc);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  deleteLocation(locationId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.locations}/${locationId}`,
      this.httpOptions
    );
  }

  deleteContact(supplierId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.supplier}/${supplierId}`,
      this.httpOptions
    );
  }

  delete(urlName: string, _id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API[urlName]}/${_id}`,
      this.httpOptions
    );
  }

  getSynchronizationDate(): Observable<any> {
    return this.http.get<any>(
      `${this.API.synchronizationDate}?limit=1`,
      this.httpOptions
    );
  }

  updateSynchronizationDate(
    dateId: string,
    syncDate: string,
    syncId: string
  ): Observable<any> {
    const data = {
      c_contact_synchronization_date: syncDate,
      c_contact_synchronization_id: syncId,
    };
    return this.http.put<any>(
      `${this.API.synchronizationDate}/${dateId}`,
      data,
      this.httpOptions
    );
  }

  createSynchronizationDate(syncDate: string, syncId: string): Observable<any> {
    const data = {
      c_contact_synchronization_date: syncDate,
      c_contact_synchronization_id: syncId,
    };
    return this.http.post<any>(
      `${this.API.synchronizationDate}`,
      data,
      this.httpOptions
    );
  }

  searchLocations(input: string, queryParam: string): Observable<any> {
    const limit = 'limit=20';
    const filters = [`{"c_company":{"$regex":"/${input}/i"}}`, queryParam];
    const where = `where={"$and": [${filters
      .filter((f) => f != '')
      .join(',')}]}`;
    const sort = `sort={"_id": -1}`;

    const query = `${limit}&${where}&${sort}`;
    return this.http.get<any>(
      `${this.API.locations}?${query}`,
      this.httpOptions
    );
  }

  createHcpcs(hpcps: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.inventoryInsuranceLoc}`,
      hpcps,
      this.httpOptions
    );
  }

  editHcpcs(hcpcsId: string, hpcps: any): Observable<any> {
    delete hpcps.acl;
    return this.http.post<any>(
      `${this.API.inventoryInsuranceLoc}/${hcpcsId}`,
      hpcps,
      this.httpOptions
    );
  }

  deleteHcpcs(hcpcsId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.inventoryInsuranceLoc}/${hcpcsId}`,
      this.httpOptions
    );
  }

  getExpectedLocationsFromCin7(): Observable<any> {
    const localSubject = new Subject();
    const self = this;

    this.getSynchronizationDate().subscribe((date) => {
      // if (date.data.length && date.data[0].c_contact_synchronization_date) {
      //   console.log("Getting outdated");
      //   self.getOutdatedContactsFromCin7(date.data[0].c_contact_synchronization_date).subscribe( locs => {
      //     localSubject.next({
      //       locations: locs,
      //       dateId: date.data[0]._id
      //     });
      //     localSubject.complete();
      //   });
      // } else {
      //   console.log("Getting all");
      //   self.getLocationsFromCin7(1).subscribe( locs => {
      //     localSubject.next({
      //       locations: locs,
      //       dateId: (date.data.length) ? date.data[0]._id : undefined
      //     });
      //     localSubject.complete();
      //   });
      // }
      self.getLocationsFromCin7(1).subscribe((locs) => {
        localSubject.next({
          locations: locs,
          dateId: date.data.length ? date.data[0]._id : undefined,
        });
        localSubject.complete();
      });
    });

    return localSubject;
  }

  getOutdatedContactsFromCin7(date): Observable<any> {
    const self = this;
    const localSubject = new Subject();
    let allCont = [];
    let page = 1;

    function loadAll() {
      const query = [
        'rows=250',
        `page=${page}`,
        `where=modifiedDate>'${date}'`,
        'order=modifiedDate,id ASC',
      ];

      self.http
        .get<any>(
          `${self.API.supplierCin7}?` + query.join('&'),
          self.httpOptions
        )
        .subscribe((response) => {
          allCont = allCont.concat(response.data);
          // console.log("OutDatedContacts: ", response.data);
          if (response.data.length === 250) {
            page++;
            loadAll();
          } else {
            localSubject.next(allCont);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  sendImageFile(file: any, locationId: string): Observable<any> {
    return this.http.put<any>(
      `${this.API.locations}/${locationId}/c_clinic_logo`,
      { content: file.name },
      this.httpOptions
    );
  }

  getImageContent(locationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.locations}/${locationId}/c_clinic_logo/content`,
      this.httpOptions
    );
  }

  getAllMedableLocations(): Observable<any> {
    const localSubject = new Subject();
    const self = this;
    const chunck = 1000;
    let allLocations = [];
    let page = 0;

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.locations}?limit=${chunck}&skip=${page * chunck}`,
          self.httpOptions
        )
        .subscribe((locs) => {
          allLocations = allLocations.concat(locs.data);
          if (locs.hasMore) {
            page++;
            loadAll();
          } else {
            localSubject.next(allLocations);
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }
}
