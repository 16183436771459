import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import * as moment from 'moment';
import { CONSTANTS } from '../../../config/constants';
import { FormService } from '../../services/form/form.service';
import { LocationService } from '../../services/location/location.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ProviderService } from '../provider/provider.service';

@Injectable()
export class OrderpdfService {
  logo: any = CONSTANTS.LOGO_BLUE;
  httpAux = {
    headers: new HttpHeaders({
      // 'Content-Type':  'image/png',
    }),
    withCredentials: false,
    responseType: 'blob' as 'json',
  };

  constructor(
    private formService: FormService,
    private locationService: LocationService,
    private http: HttpClient,
    private providerService: ProviderService
  ) {}

  populateOrder(order, orderForms, orderLines, modifierForms = []) {
    const date = new Date(order.c_order_date);
    const dob = new Date(order.c_patient.c_dob);
    let provider = order.c_provider_location.c_physician.c_preffix
      ? order.c_provider_location.c_physician.c_preffix + '. '
      : '';
    provider +=
      order.c_provider_location.c_physician.c_name.c_first_name +
      ' ' +
      order.c_provider_location.c_physician.c_name.c_last_name;
    provider += order.c_provider_location.c_physician.c_suffix
      ? ',' + order.c_provider_location.c_physician.c_suffix
      : '';

    let dd = {
      info: {
        title: 'Order',
      },
      content: [
        {
          columns: [
            {
              image: this.logo,
              width: 100,
            },
            {
              text: 'Detailed Written Order',
              style: 'header',
              margin: [70, 0, 0, 5],
            },
          ],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              color: '#cbcbcb',
            },
          ],
          margin: [0, 0, 0, 30],
        },
        {
          text:
            'Order Number: ' +
            order.c_number_id +
            ' - ' +
            (date.getMonth() + 1) +
            '/' +
            date.getDate() +
            '/' +
            date.getFullYear(),
          style: ['header', 'margins'],
        },
        { text: 'Demographics', style: ['subHeader', 'margins'] },
        {
          columns: [
            {
              stack: [
                { text: 'Patient Name:', style: 'itemtitle' },
                {
                  text:
                    order.c_patient.c_name.c_first_name +
                    ' ' +
                    order.c_patient.c_name.c_last_name,
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'Patient DOB:', style: 'itemtitle' },
                {
                  text:
                    dob.getMonth() +
                    1 +
                    '/' +
                    dob.getDate() +
                    '/' +
                    dob.getFullYear(),
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'Patient MRN:', style: 'itemtitle' },
                { text: order.c_patient.c_mrn, style: 'margins' },
              ],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                { text: 'Insurance:', style: 'itemtitle' },
                { text: order.c_insurance.c_insurance_name, style: 'margins' },
              ],
            },
            {
              stack: [
                { text: 'Provider Name:', style: 'itemtitle' },
                { text: provider, style: 'margins' },
              ],
            },
            {
              stack: [
                { text: 'Provider NPI:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_physician.c_npi,
                  style: 'margins',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                { text: 'Location Name:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_location.c_company,
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'Street Address 1:', style: 'itemtitle' },
                {
                  text:
                    order.c_provider_location.c_location.c_delivery_address &&
                    order.c_provider_location.c_location.c_delivery_address
                      .c_street_address_1
                      ? order.c_provider_location.c_location.c_delivery_address
                          .c_street_address_1
                      : '-',
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'Street Address 2:', style: 'itemtitle' },
                {
                  text:
                    order.c_provider_location.c_location.c_delivery_address &&
                    order.c_provider_location.c_location.c_delivery_address
                      .c_street_address_2
                      ? order.c_provider_location.c_location.c_delivery_address
                          .c_street_address_2
                      : '-',
                  style: 'margins',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                { text: 'City:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_location.c_delivery_address
                    .c_city
                    ? order.c_provider_location.c_location.c_delivery_address
                        .c_city
                    : '-',
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'State:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_location.c_delivery_address
                    .c_state
                    ? order.c_provider_location.c_location.c_delivery_address
                        .c_state
                    : '-',
                  style: 'margins',
                },
              ],
            },
            {
              stack: [
                { text: 'Postal Code:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_location.c_delivery_address
                    .c_postal_code
                    ? order.c_provider_location.c_location.c_delivery_address
                        .c_postal_code
                    : '-',
                  style: 'margins',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                { text: 'Phone Number:', style: 'itemtitle' },
                {
                  text: order.c_provider_location.c_location.c_phone
                    ? order.c_provider_location.c_location.c_phone
                    : '-',
                  style: 'margins',
                },
              ],
            },
            {},
            {},
          ],
        },
        { text: 'Prescribed Items', style: ['subHeader', 'margins'] },
        {
          stack: [],
        },
        { text: 'Notes', style: ['subHeader', 'margins'] },
        { text: order.c_notes ? order.c_notes : '', style: 'margins' },
        {
          columns: [
            {
              stack: [
                { text: 'Physician Signature:', style: 'itemtitle' },
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 40,
                      x2: 200,
                      y2: 40,
                      lineWidth: 1,
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                { text: 'Signature Date:', style: 'itemtitle' },
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 40,
                      x2: 200,
                      y2: 40,
                      lineWidth: 1,
                    },
                  ],
                },
              ],
            },
          ],
          margin: [0, 5, 0, 15],
        },
        { text: 'Compliance Documentation', style: ['subHeader', 'margins'] },
        {
          stack: [],
        },
      ],
      styles: {
        margins: {
          margin: [0, 5, 0, 5],
        },
        header: {
          bold: true,
          fontSize: 18,
          color: '#2277ff',
        },
        subHeader: {
          bold: true,
          fontSize: 14,
          color: '#2277ff',
        },
        itemtitle: {
          bold: true,
        },
      },
    };

    for (let i = 0; i < orderLines.length; i++) {
      let line = {
        table: {
          widths: ['auto', '*'],
          headerRows: 1,
          body: [],
          margin: [0, 5, 0, 10],
        },

        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 1;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
          },
          hLineColor(i, node) {
            return 'gray';
          },
          vLineColor(i, node) {
            return 'gray';
          },
          paddingLeft(i, node) {
            return 5;
          },
          paddingRight(i, node) {
            return 5;
          },
          paddingTop(i, node) {
            return 5;
          },
          paddingBottom(i, node) {
            return 5;
          },
        },
      };

      line.table.body.push([
        {
          text:
            orderLines[i].c_product.c_hcpcs_code +
            ' - ' +
            orderLines[i].c_product.c_name +
            '-' +
            orderLines[i].c_product.cpy_product_option.c_size,
          bold: true,
          colSpan: 2,
        },
        {},
      ]);
      line.table.body.push([
        { rowSpan: 0, text: 'Quantity', style: 'itemtitle' },
        orderLines[i].c_quantity.toString(),
      ]);
      line.table.body.push([
        { rowSpan: 0, text: 'Costs', style: 'itemtitle' },
        'Charge out: ' +
          orderLines[i].c_charge_out_cost.toString() +
          ' | Self pay: ' +
          orderLines[i].c_self_pay_cost.toString() +
          ' | Allowable: ' +
          orderLines[i].c_allowable_cost.toString(),
      ]);

      const modifiers = orderLines[i].c_modifier.filter(
        (modifier) => modifier.c_checked && !modifier.c_modifier_ref.c_warning
      );
      //console.log("Printing Modifier: ", modifiers);
      for (let j = 0; j < modifiers.length; j++) {
        const modifier = modifiers[j].c_modifier;
        if (j === 0) {
          line.table.body.push([
            {
              rowSpan: modifiers.length,
              text: 'Modifiers',
              style: 'itemtitle',
            },
            modifier,
          ]);
        } else {
          line.table.body.push(['', modifier]);
        }
      }
      if (modifiers.length === 0) {
        line.table.body.push([{ text: 'Modifiers', style: 'itemtitle' }, '']);
      }

      const icd10s = orderLines[i].c_icd_10;
      for (let j = 0; j < icd10s.length; j++) {
        const icd10 = icd10s[j].c_icd_10_code;
        if (j === 0) {
          line.table.body.push([
            { rowSpan: icd10s.length, text: 'ICD 10 Code', style: 'itemtitle' },
            icd10,
          ]);
        } else {
          line.table.body.push(['', icd10]);
        }
        //line.table.body.push(['', 'test']);
      }
      if (icd10s.length === 0) {
        line.table.body.push([{ text: 'ICD 10 Code', style: 'itemtitle' }, '']);
      }

      dd.content[10]['stack'].push(line);
    }

    for (let i = 0; i < orderForms.length; i++) {
      let form = {
        text:
          orderForms[i].c_inventory_form.c_product.c_hcpcs_code +
          ' ' +
          orderForms[i].c_inventory_form.c_form.c_name,
        style: 'margins',
      };
      dd.content[15]['stack'].push(form);
    }

    modifierForms.map((mf) => {
      const form = {
        text:
          mf.c_product.c_hcpcs_code + ' ' + mf.c_modifier_form.c_form.c_name,
        style: 'margins',
      };
      dd.content[15]['stack'].push(form);
    });
    console.log('printing: ', { ...dd });
    pdfMake.createPdf({ ...dd }).open();
  }

  getClinicName(modifierForms) {
    let clinicName = '';
    const warrantyForms = [];
    modifierForms.map((form) => {
      if (
        form.c_modifier_form.c_form.c_name.toLowerCase().indexOf('warranty') >
        -1
      ) {
        warrantyForms.push(form);
      }
    });

    if (!warrantyForms.length) {
      console.log('No warranty');
      return clinicName;
    }

    const warrantyForm = warrantyForms[0];
    console.log('WarrantyForm: ', warrantyForm);
    clinicName = this.extractClinicName(warrantyForm);
    // clinicName = warrantyForm.c_responses[0].c_response[0];
    return clinicName;
  }

  extractClinicName(warrantyForm) {
    let clinicName = ' - ';
    const nameQuestionIndex = this.getNameQuestionIndex(warrantyForm);
    if (nameQuestionIndex == -1) {
      return clinicName;
    }
    const nameQuestion = warrantyForm.c_responses[nameQuestionIndex],
      responseType = nameQuestion.c_question.c_response_type;

    if (responseType == 'text') {
      clinicName = nameQuestion.c_response[0];
      return clinicName;
    } else if (responseType == 'check') {
      const responseIndex = parseInt(
          nameQuestion.c_response[0].replace("'", '')
        ),
        clinicName = nameQuestion.c_question.c_response_options[responseIndex];
      return clinicName;
    }

    return clinicName;
  }

  getNameQuestionIndex(warrantyForm) {
    const questionList = warrantyForm.c_responses;
    let index = -1;
    const searchTerms = ['clinic', 'name'];

    questionList.map((question, idx) => {
      const termsFound = searchTerms.filter(
        (st) => question.c_question.c_text.toLowerCase().indexOf(st) > -1
      ).length;
      if (termsFound == searchTerms.length) {
        console.log('Index found: ', idx);
        index = idx;
      }
    });
    return index;
  }

  populateOrderFinal(
    orderSign,
    order,
    orderForms,
    orderLines,
    modifierForms = []
  ) {
    if (orderSign) {
      //console.log("Order To Populate has signature: ", orderSign);
    }

    const hasLogo = false;
    const hasSignature = false;
    const imagesLoaded = [];
    const ordLocation = order.c_provider_location.c_location;
    const ordProvider = order.c_provider_location.c_physician;
    const logoLoaded = this.loadClinicLogo(ordLocation);
    imagesLoaded.push(logoLoaded);
    const signatureLoaded = this.loadProviderSignature(ordProvider);
    imagesLoaded.push(signatureLoaded);

    forkJoin(imagesLoaded).subscribe((results) => {
      const logo = results[0]['image'];
      const signature = results[1]['image'];
      this.populateOrderNew(
        orderSign,
        order,
        orderForms,
        orderLines,
        logo,
        signature,
        modifierForms
      );
    });
  }

  getClinicNameNew = (forms) => {
    const warrantyForms =
      forms.filter(
        (f) =>
          f.c_modifier_form.c_form.c_name.toLowerCase().indexOf('warranty') > -1
      ) || [];

    if (!warrantyForms.length) {
      return '';
    }

    const response = warrantyForms[0].c_responses[0].c_response[0];

    console.log('attempt: ', parseInt(response.replace(`'`, '')));

    if (isNaN(parseInt(response.replace(`'`, '')))) {
      return response;
    }
    const index = parseInt(response.replace(`'`, ''));
    const name =
      warrantyForms[0].c_responses[0].c_question.c_response_options[index];
    return name;
  };

  loadClinicLogo(location) {
    const self = this;
    const localSubject = new Subject();
    if (!location.c_clinic_logo) {
      return new Observable((observer) => {
        observer.next({
          exists: false,
          image: '',
        });
        observer.complete();
      });
    } else {
      const locationId = location._id;
      this.locationService
        .getImageContent(locationId)
        .subscribe((medableImage) => {
          if (!medableImage.url) {
            localSubject.next({
              exists: false,
              image: '',
            });
            localSubject.complete();
          } else {
            this.http
              .get<any>(medableImage.url, this.httpAux)
              .subscribe((awsImage) => {
                const reader = new FileReader();
                const testImg = new Image();
                reader.onloadend = function () {
                  // console.log("What is this? ", this);
                  testImg.src = reader.result.toString();
                  // console.log("Other Image Data: ", testImg.src);
                  testImg.onload = function () {
                    const imageSettings = self.getImageSettings(
                      testImg,
                      70,
                      100
                    );
                    // const topMargin = (70 - Math.round((100 * testImg.height)/testImg.width )) / 2;
                    // console.log("The width of the image is " + testImg.width + "px.");
                    // console.log("The height of the image is " + testImg.height + "px.");
                    // console.log("Resize image wodth: 100 px");
                    // console.log("Resized image height: " + Math.round((100 * testImg.height)/testImg.width ));
                    // console.log("Image settings: ", imageSettings);

                    let imgMargins = [];
                    if (imageSettings['marginTop']) {
                      imgMargins = [0, imageSettings['marginTop'], 0, 0];
                    } else if (imageSettings['marginLeft']) {
                      imgMargins = [imageSettings['marginLeft'], 0, 0, 0];
                    } else {
                      imgMargins = [0, 0, 0, 0];
                    }

                    const logo = {
                      image: reader.result,
                      margin: imgMargins,
                      border: [true, true, false, false],
                      fit: [100, 70],
                      // height: 70
                    };
                    // console.log("Logo Loaded");
                    localSubject.next({
                      exists: true,
                      image: logo,
                    });
                    localSubject.complete();
                  };
                };

                reader.readAsDataURL(awsImage);
              });
          }
        });
    }

    return localSubject;
  }

  getImageSettings(image, desiredHeight, desiredWidth) {
    let response = {};
    const oImageWidth = image.width;
    const oImageHeight = image.height;
    const dImageWidth = desiredWidth;
    const dImageHeight = Math.round(dImageWidth * oImageHeight) / oImageWidth;
    if (dImageHeight > desiredHeight) {
      const nImageHeight = desiredHeight;
      const nImageWidth =
        Math.round(desiredHeight * oImageWidth) / oImageHeight;
      response = {
        height: nImageHeight,
        marginLeft: (desiredWidth - nImageWidth) / 2,
      };

      return response;
    } else {
      response = {
        width: dImageWidth,
        marginTop: (desiredHeight - dImageHeight) / 2,
      };

      return response;
    }
  }

  loadProviderSignature(provider) {
    const localSubject = new Subject();

    if (!provider.c_signature) {
      return new Observable((observer) => {
        observer.next({
          exists: false,
          image: '',
        });
        observer.complete();
      });
    } else {
      const providerId = provider._id;
      this.providerService
        .getImageContent(providerId)
        .subscribe((medableImage) => {
          if (!medableImage.url) {
            localSubject.next({
              exists: false,
              image: '',
            });
            localSubject.complete();
          } else {
            this.http
              .get<any>(medableImage.url, this.httpAux)
              .subscribe((awsImage) => {
                const reader = new FileReader();
                reader.onloadend = function () {
                  const signature = {
                    image: reader.result,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    width: 70,
                  };
                  console.log('Signature loaded');
                  localSubject.next({
                    exists: true,
                    image: signature,
                  });
                  localSubject.complete();
                };
                reader.readAsDataURL(awsImage);
              });
          }
        });
    }

    return localSubject;
  }

  generateOrderSignature(signature) {
    if (signature) {
      return [
        {
          image: signature,
          fit: [150, 20],
          border: [false, false, false, false],
        },
      ];
    } else {
      return [];
    }
  }

  timestampToReadable = (timestamp) => {
    if (!timestamp) {
      return '';
    }

    const ts = parseInt(timestamp);
    const date = new Date(ts),
      mo =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1,
      d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      y = date.getFullYear(),
      h = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();

    // return `${mo}/${d}/${y}, ${h}:${min}:${sec}`;
    return `${mo}/${d}/${y}, ${h}:${min}`;
  };

  populateOrderNew(
    orderSign,
    order,
    orderForms,
    orderLines,
    logo,
    signature,
    modifierForms = []
  ) {
    // console.log("Order: ", order);
    // console.log("OrderForms: ", orderForms);
    // console.log("OrderLines: ", orderLines);
    console.log('Forms: ', modifierForms);
    console.log('Populating order new');
    const orderSignature = this.generateOrderSignature(orderSign);
    const patientName =
      order.c_patient.c_name.c_first_name +
      ' ' +
      order.c_patient.c_name.c_last_name;
    const orderDate = this.medableToDisplayDate(order.c_order_date);
    const dob = this.medableToDisplayDate(order.c_patient.c_dob);
    const mrn = order.c_patient.c_mrn;
    const primaryInsurance = order.c_insurance.c_insurance_name;

    const primaryInsuranceId = order.c_primary_insurance_id_number
      ? order.c_primary_insurance_id_number
      : '-';
    const secondaryInsurance = order.c_secondary_insurance
      ? order.c_secondary_insurance.c_insurance_name || '-'
      : '-';
    const secondaryInsuranceId = order.c_secondary_insurance_id_number
      ? order.c_secondary_insurance_id_number
      : '-';
    const physician = order.c_provider_location.c_physician;
    const pPrefix = physician.c_preffix ? physician.c_preffix : '';
    const pSuffix = physician.c_suffix ? physician.c_suffix : '';
    const pFirstName = physician.c_name.c_first_name;
    const pLastName = physician.c_name.c_last_name;
    const providerName = `${pPrefix} ${pFirstName} ${pLastName} ${pSuffix}`;
    const npi = physician.c_npi;
    const location = order.c_provider_location.c_location.c_delivery_address;
    const locData = {};
    const clinicName = this.getClinicNameNew(modifierForms);
    const orderedDate = order.c_ordered_date
      ? moment.utc(order.c_ordered_date).format('MM/DD/yyyy')
      : '';
    let icdLine = '';
    const createdTimestamp = order.c_created_timestamp;
    const cdNew = this.timestampToReadable(createdTimestamp);

    orderLines.map((ol) => {
      ol.c_icd_10.map((icd) => {
        if (icdLine === '') {
          icdLine = icd.c_icd_10_code;
        } else {
          icdLine = `${icdLine} | ${icd.c_icd_10_code}`;
        }
      });
    });

    if (!location) {
      locData['city'] = '-';
      locData['zip'] = '-';
      locData['state'] = '-';
      locData['street'] = '-';
    } else {
      (locData['city'] = location.c_city ? location.c_city : '-'),
        (locData['zip'] = location.c_postal_code
          ? location.c_postal_code
          : '-'),
        (locData['state'] = location.c_state ? location.c_state : '-'),
        (locData['street'] = location.c_street_address_1
          ? location.c_street_address_1
          : '-');
    }

    const spaces = {
      patient: {
        label: {
          small: 40,
          big: 100,
          xBig: 120,
        },
        data: {
          big: 195,
          small: 135,
          xSmall: 115,
        },
      },
    };

    const headingBlue = '#4286f4';

    const data = {
      info: {
        title: 'Order',
      },
      content: [
        {
          text: 'Standard Written Order|Proof of Delivery',
          style: 'header',
        },
        {
          table: {
            widths: [100, 220, 170],
            heights: [70, 70, 70],
            body: [
              [
                {
                  text: '[Clinic Logo]',
                  border: [true, true, false, false],
                },
                {
                  text: clinicName,
                  border: [false, true, true, true],
                  alignment: 'center',
                  fontSize: 18,
                  margin: [0, 20, 0, 0],
                },
                {
                  table: {
                    widths: [170],
                    heights: [10, 60],
                    body: [
                      [
                        {
                          text: 'Delivery Address:',
                          style: 'subHeaders',
                        },
                      ],
                      [
                        {
                          margin: [-5, 0, 0, 0],
                          table: {
                            widths: [35, 120],
                            body: [
                              [
                                {
                                  border: [true, false, false, false],
                                  text: 'State:',
                                  fontSize: 10,
                                },
                                {
                                  border: [false, false, false, true],
                                  text: locData['state'],
                                  fontSize: 10,
                                },
                              ],
                              [
                                {
                                  border: [true, false, false, false],
                                  text: 'City:',
                                  fontSize: 10,
                                },
                                {
                                  border: [false, false, false, true],
                                  text: locData['city'],
                                  fontSize: 10,
                                },
                              ],
                              [
                                {
                                  border: [true, false, false, false],
                                  text: 'Zip:',
                                  fontSize: 10,
                                },
                                {
                                  border: [false, false, false, true],
                                  text: locData['zip'],
                                  fontSize: 10,
                                },
                              ],
                              [
                                {
                                  border: [true, false, false, false],
                                  text: 'Street:',
                                  fontSize: 10,
                                },
                                {
                                  border: [false, false, false, true],
                                  text: locData['street'],
                                  fontSize: 10,
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    ],
                  },
                  layout: {
                    fillColor(i, node) {
                      return i === 0 ? headingBlue : null;
                    },
                  },
                  border: [false, false, false, false],
                  margin: [-5, -3, -20, -3],
                },
              ],
            ],
          },
          margin: [0, 10, 0, 0],
        },
        {
          table: {
            widths: [250, 250],
            heights: ['*'],
            body: [
              [
                {
                  margin: [-5, -4, 0, 0],
                  border: [true, false, true, true],
                  table: {
                    widths: [250],
                    heights: [10],
                    body: [
                      [
                        {
                          text: 'Patient Demographics',
                          style: 'subHeaders',
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.small,
                              spaces.patient.data.big,
                            ],
                            body: [
                              [
                                {
                                  text: 'Name:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: patientName,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.small,
                              spaces.patient.data.big,
                            ],
                            body: [
                              [
                                {
                                  text: 'DOB:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: dob,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.small,
                              spaces.patient.data.big,
                            ],
                            body: [
                              [
                                {
                                  text: 'MRN:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: mrn,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.big,
                              spaces.patient.data.small,
                            ],
                            body: [
                              [
                                {
                                  text: 'Primary Insurance:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: primaryInsurance,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.big,
                              spaces.patient.data.small,
                            ],
                            body: [
                              [
                                {
                                  text: 'Primary Ins. ID:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: primaryInsuranceId,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.xBig,
                              spaces.patient.data.xSmall,
                            ],
                            body: [
                              [
                                {
                                  text: 'Secondary Insurance:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: secondaryInsurance,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.xBig,
                              spaces.patient.data.xSmall,
                            ],
                            body: [
                              [
                                {
                                  text: 'Secondary Ins. ID:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: secondaryInsuranceId,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                    ],
                  },
                  layout: {
                    fillColor(i, node) {
                      return i === 0 ? headingBlue : null;
                    },
                  },
                },
                {
                  margin: [-5, -4, 0, 0],
                  border: [true, false, true, true],
                  table: {
                    widths: [250],
                    heights: [10],
                    body: [
                      [
                        {
                          text: 'Provider Information',
                          style: 'subHeaders',
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.small,
                              spaces.patient.data.big,
                            ],
                            body: [
                              [
                                {
                                  text: 'Name:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: providerName,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.big,
                              spaces.patient.data.small,
                            ],
                            body: [
                              [
                                {
                                  text: 'NPI Number:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: npi,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.big,
                              spaces.patient.data.small,
                            ],
                            body: [
                              [
                                {
                                  text: 'Order Date:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: orderedDate /*orderDate*/,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.xBig,
                              spaces.patient.data.xSmall,
                            ],
                            body: [
                              [
                                {
                                  text: 'Provider Signature:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: '',
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          table: {
                            widths: [
                              spaces.patient.label.big,
                              spaces.patient.data.small,
                            ],
                            body: [
                              [
                                {
                                  text: 'Signature Date:',
                                  border: [false, false, false, false],
                                },
                                {
                                  text: '' /*orderDate*/,
                                  border: [false, false, false, true],
                                },
                              ],
                            ],
                          },
                          border: [true, false, true, false],
                        },
                      ],
                    ],
                  },
                  layout: {
                    fillColor(i, node) {
                      return i === 0 ? headingBlue : null;
                    },
                  },
                },
              ],
            ],
          },
        },
        {
          border: [true, false, true, true],
          table: {
            widths: [510],
            body: [
              [
                {
                  layout: {
                    fillColor(i, node) {
                      return i === 0 ? headingBlue : null;
                    },
                  },
                  margin: [-5, -3, 0, 0],
                  border: [true, false, true, true],
                  table: {
                    widths: [510],
                    body: [
                      [
                        {
                          text: 'Prescribed Items:',
                          style: 'subHeaders',
                        },
                      ],
                      [
                        {
                          stack: [],
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
        },
        {
          unbreakable: true,
          stack: [
            {
              table: {
                widths: [150, 350],
                body: [
                  [
                    {
                      text: 'Auth/Order Notes:',
                      alignment: 'center',
                      style: 'subHeaders',
                    },
                    {
                      text: order.c_notes,
                    },
                  ],
                  [
                    {
                      text: [
                        {
                          text:
                            'Proof of Delivery and Authorization to Release Medical Information and Permit Payment of Insurance Benefits:',
                          bold: true,
                        },
                        {
                          text:
                            'I have received the above product(s) as prescribed by my doctor. I authorize the release to my doctor and for them to release to my insurer(s) any needed information for this or a related claim. I request that payment of authorized benefits be made on my behalf, and I assign the benefits payable for the medical equipment provided. Although I recognize that I have the primary responsibility for contacting and submitting claims to my insurer(s), I have received the equipment and authorized submitting claim(s) to any of the insurer(s) as may be required. I understand that I am responsible for deductibles, co-insurance, and co-payments not covered by my insurer(s). Should my insurance plan(s) not provide coverage in part or in the entirety for any reason, I understand that I may be responsible for payments.',
                        },
                      ],
                      colSpan: 2,
                      fontSize: 8,
                    },
                  ],
                  [
                    {
                      colSpan: 2,
                      margin: [-5, 0, 0, 0],
                      table: {
                        widths: [305, 110],
                        body: [
                          [
                            {
                              border: [false, false, true, false],
                              margin: [-5, 0, 0, 0],
                              table: {
                                widths: [135, 160],
                                body: [
                                  [
                                    {
                                      text: 'Patient/Authorized Signature:',
                                      fontSize: 10,
                                      border: [false, false, false, false],
                                    },
                                    {
                                      border: [false, false, false, true],
                                      table: {
                                        widths: [160],
                                        body: [orderSignature],
                                      },
                                    },
                                  ],
                                ],
                              },
                            },
                            {
                              border: [false, false, false, false],
                              margin: [-5, 0, 0, 0],
                              table: {
                                widths: [75, 100],
                                body: [
                                  [
                                    {
                                      text: 'Date Delivered:',
                                      fontSize: 10,
                                      border: [false, false, false, false],
                                    },
                                    {
                                      text: orderDate,
                                      border: [false, false, false, true],
                                    },
                                  ],
                                ],
                              },
                            },
                          ],
                        ],
                      },
                    },
                  ],
                ],
              },
              layout: {
                fillColor(row, node, cell) {
                  // console.log("row: ", row,  " node: ", node, " cell: ", cell);
                  let color = null;
                  if (row == 0 && cell == 0) {
                    color = headingBlue;
                  }
                  if (row == 2) {
                    color = '#eee';
                  }
                  return color;
                },
              },
            },
            {
              table: {
                widths: [295, 205],
                body: [
                  [
                    {
                      border: [false, false, false, false],
                      text: '',
                      alignment: 'center',
                      style: 'subHeaders',
                    },
                    {
                      alignment: 'right',
                      border: [false, false, false, false],
                      text: `${cdNew} ` || '',
                    },
                  ],
                ],
              },
            },
          ],
        },
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 18,
          alignment: 'center',
        },
        noMargin: {
          margin: [0, 0, 0, 0],
        },
        subHeaders: {
          bold: true,
          color: '#fff',
          fontSize: 13,
          alignment: 'center',
        },
      },
    };

    if (logo === '') {
      // console.log("No Logo");
      // console.log("Here? ", data.content[1]['table'].body[0][0]['text']);
      data.content[1]['table'].body[0][0]['text'] = '';
    } else {
      data.content[1]['table'].body[0][0] = logo;
    }

    if (signature === '') {
      data.content[2]['table'].body[0][1]['table'].body[4][0]['table'][
        'body'
      ][0][1].text = '';
    } else {
      data.content[2]['table'].body[0][1]['table'].body[4][0]['table'][
        'body'
      ][0][1] = signature;
    }
    // console.log("Calling add product..");
    this.addProductsNew(data, orderLines);
    console.log('pdmake from this: ', JSON.stringify(data));
    pdfMake.createPdf({ ...data }).open();
  }

  addProductsNew(baseObj, orderLines) {
    console.log('Adding Product');
    orderLines.map((orderLine) => {
      const product = orderLine.c_product;
      const hcpcs = orderLine.c_selected_hcpcs || product.c_hcpcs_code;
      const pName = product.c_name;
      const pSize = product.cpy_product_options
        ? product.cpy_product_option.c_size
        : '-';
      const pHeading = `${hcpcs} - ${pName} - ${pSize}`;
      const quantity = orderLine.c_quantity;
      const chargeOut = orderLine.c_charge_out_cost;
      const allowable = orderLine.c_allowable_cost || 0;
      const payment = orderLine.c_self_pay_cost ? orderLine.c_self_pay_cost : 0;
      const salesTax = orderLine.c_sales_tax ? orderLine.c_sales_tax : 0;
      const productCode = orderLine.c_product_option_code;
      // console.log("Payment: ", payment);
      const line = {
        unbreakable: true,
        stack: [],
      };

      const headerRow = {
        margin: [-5, 0, 0, 0],
        table: {
          widths: [150, 65, 45, 55, 50, 45, 45],
          body: [
            [
              {
                text: 'HCPCS & Description',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Modifiers',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Quantity',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Charge Out',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Allowable',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Payment',
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: 'Sales Tax',
                alignment: 'center',
                fontSize: 10,
              },
            ],
          ],
        },
      };

      const contentRow = {
        margin: [-5, 0, 0, 0],
        layout: {
          fillColor(row, node) {
            return row == 0 ? '#eee' : null;
          },
        },
        table: {
          widths: [150, 65, 45, 55, 50, 45, 45],
          body: [
            [
              {
                text: pHeading,
                alignment: 'center',
                fontSize: 10,
              },
              {
                margin: [-5, 0, 0, 0],
                table: {
                  // widths: [100],
                  body: [],
                },
              },
              {
                text: quantity,
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: chargeOut,
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: allowable,
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: payment,
                alignment: 'center',
                fontSize: 10,
              },
              {
                text: salesTax,
                alignment: 'center',
                fontSize: 10,
              },
            ],
          ],
        },
      };

      const stickerRow = {
        margin: [-5, 0, 0, 0],
        table: {
          widths: [175, 140, 175],
          heights: [40],
          body: [
            [
              {
                border: [true, false, false, false],
                text: `Product Code: ${productCode}`,
                alignment: 'left',
                margin: [0, 15, 0, 0],
                fontSize: 10,
              },
              {
                border: [false, false, false, false],
                text: 'Place Sticker Here',
                alignment: 'center',
                margin: [0, 15, 0, 0],
                fontSize: 10,
              },
              {
                border: [false, false, true, false],
                text: '',
                alignment: 'center',
                margin: [0, 15, 0, 0],
                fontSize: 10,
              },
            ],
          ],
        },
      };

      // console.log("OrderLine: ", orderLine);
      const icdSection = {
        margin: [-5, 0, 0, 0],
        table: {
          widths: [100, 400],
          body: [],
        },
      };

      orderLine.c_icd_10.map((icd) => {
        const icdRow = [
          {
            text: 'ICD-10: ',
            alignment: 'center',
            fontSize: 10,
          },
          {
            text: `${icd.c_icd_10_code} - ${icd.c_description}`,
            alignment: 'left',
            fontSize: 10,
          },
        ];

        icdSection.table.body.push(icdRow);
      });

      const warningsLength = orderLine.c_modifier.filter(
        (m) => m.c_modifier_ref.c_warning && m.c_checked
      ).length;
      orderLine.c_modifier.map((modifier) => {
        const warningRow = [
          {
            text: 'Warning: ',
            alignment: 'center',
            fontSize: 10,
          },
          {
            text: `${modifier.c_modifier}`,
            alignment: 'left',
            fontSize: 10,
          },
        ];

        if (modifier.c_modifier_ref.c_warning && modifier.c_checked) {
          icdSection.table.body.push(warningRow);
        }
      });

      // let i = 0;
      console.log('Modifiers to print: ', orderLine.c_modifier);
      if (
        orderLine.c_modifier.filter(
          (m) => m.c_checked && !m.c_modifier_ref.c_warning
        ).length
      ) {
        orderLine.c_modifier.map((modifier) => {
          const modifierObj = [
            {
              text: modifier.c_modifier || '-',
              alignment: 'center',
              border: [false, false, false, false],
              fontSize: 10,
            },
          ];
          if (modifier.c_checked && !modifier.c_modifier_ref.c_warning) {
            contentRow['table'].body[0][1]['table'].body.push(modifierObj);
            // i++;
          }
        });
      } else {
        const modifierObj = [
          {
            text: '-',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ];
        contentRow['table'].body[0][1]['table'].body.push(modifierObj);
      }

      line.stack.push(headerRow);
      line.stack.push(contentRow);
      console.log('***ContentRow: ', JSON.stringify(contentRow));
      if (orderLine.c_icd_10.length || warningsLength) {
        line.stack.push(icdSection);
      }
      line.stack.push(stickerRow);
      baseObj.content[3].table.body[0][0].table.body[1][0].stack.push(line);
    });
  }

  addProducts(baseObj, orderLines) {
    orderLines.map((orderLine) => {
      const product = orderLine.c_product;
      const hcpcs = product.c_hcpcs_code;
      const pName = product.c_name;
      const pSize = product.cpy_product_option.c_size;
      const pHeading = `${hcpcs} - ${pName} - ${pSize}`;
      const quantity = orderLine.c_quantity;
      const chargeOut = orderLine.c_charge_out_cost;
      const allowable = orderLine.c_allowable_cost;
      const payment = orderLine.c_self_pay_cost;
      console.log('Patient Payment: ', payment);
      console.log('here!');
      const line = [
        {
          layout: {
            fillColor(i, node) {
              return i === 1 ? '#eee' : null;
            },
          },
          margin: [-5, -3, 0, 0],
          border: [true, false, true, true],
          table: {
            widths: [150, 100, 45, 65, 55, 50],
            heights: ['*', '*', 40],
            body: [
              [
                {
                  text: 'HCPCS & Description',
                  alignment: 'center',
                },
                {
                  text: 'Modifiers',
                  alignment: 'center',
                },
                {
                  text: 'Quantity',
                  alignment: 'center',
                },
                {
                  text: 'Charge Out',
                  alignment: 'center',
                },
                {
                  text: 'Allowable',
                  alignment: 'center',
                },
                {
                  text: 'Payment',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: pHeading,
                  alignment: 'center',
                },
                {
                  margin: [-5, 0, 0, 0],
                  table: {
                    widths: [100],
                    body: [],
                  },
                },
                {
                  text: quantity,
                  alignment: 'center',
                },
                {
                  text: chargeOut,
                  alignment: 'center',
                },
                {
                  text: allowable,
                  alignment: 'center',
                },
                {
                  text: payment,
                  alignment: 'center',
                },
              ],
              [
                {
                  margin: [0, 10, 0, 10],
                  table: {
                    heights: [20],
                    widths: [500],
                    body: [
                      [
                        {
                          text: 'Place Sticker Here',
                          alignment: 'center',
                          border: [false, false, false, false],
                          fontSize: 10,
                        },
                      ],
                    ],
                  },
                  colSpan: 6,
                },
              ],
            ],
          },
        },
      ];

      let i = 0;
      orderLine.c_modifier.map((modifier) => {
        const modifierObj = [
          {
            text: modifier.c_modifier,
            alignment: 'center',
            border: [false, false, false, false],
          },
        ];
        console.log('Printing Modifier: ', modifier);
        if (modifier.c_checked && !modifier.c_modifier_ref.c_warning) {
          line[0]['table'].body[1][1]['table'].body[i] = modifierObj;
          i++;
        }
      });
      console.log('XXX: ', baseObj.content[3].table.body[0][0].table.body[1]);
      baseObj.content[3].table.body[0][0].table.body[1][0].stack.push(line);
    });
  }

  medableToDisplayDate(medableDate) {
    const dt = new Date(medableDate);
    console.log('DisplayDay: ', dt.getUTCDate());
    const day = dt.getUTCDate() < 10 ? `0${dt.getUTCDate()}` : dt.getUTCDate();
    const month =
      dt.getUTCMonth() < 9 ? `0${dt.getUTCMonth() + 1}` : dt.getUTCMonth() + 1;
    const year = dt.getUTCFullYear();
    const displayDate = `${month}/${day}/${year}`;

    console.log('DisplayDate: ', displayDate);
    return displayDate;
  }
}
