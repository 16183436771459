import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { ModifierService } from '../../services/modifier/modifier.service';
import { SharedService } from '../../services/shared/shared.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.css'],
})
export class ModifierComponent implements OnInit {
  hasRole: boolean = false;
  viewModifier: boolean = false;
  notEditable: boolean = false;
  editModifier: boolean = false;
  indexPage: boolean = false;
  createOrEdit: boolean = false;
  createMode: boolean = false;
  editMode: boolean = false;

  modifier_hcpcs_to_delete: any[] = [];
  modifier_insurances_to_delete: any[] = [];
  questions_to_delete: any[] = [];

  modifierId: string;
  enteredCode: string;

  modifier: any = {};

  modifier_questions: any[] = [];

  modifier_question: any = {};

  response_options: any[] = [];
  response_option: any = {
    // c_option_text: ''
    c_attaching_forms: [],
    c_attaching_modifiers: [],
  };

  // List of all hcpcs and insurances from Medable
  hcpcs_codes: any[];
  hcpcs_results: any[];
  // List of filtered hcpcs and insurances for autocomplete
  insurances: any[];
  insurances_result: any[];

  // hcpcs codes object list associated to the modifier
  hcpcs_code_list: any[] = [];
  // Insurances object list associated to the modifier
  insurances_list: any[] = [];

  // hcpcs codes object list associated to the question
  hcpcs_code_question_list: any[] = [];

  insurance_question_list: any[] = [];

  allForms: any[];
  filteredForms: any[];
  attachingFormInput: string = '';

  allModifiers: any[];
  filteredModifiers: any[];
  attachingModifierInput: string = '';

  autoPopulateInsurances: any[] = [];

  expectedAsyncCreations = 3;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private modifierService: ModifierService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.modifierId = params['id'] ? params['id'] : '0';
      this.hasRole = this.authService.getAuthorizedUserRole();

      // Index Page
      if (this.state.is('forms.modifiersIndex')) {
        this.indexPage = true;
      }
      // If view state
      else if (this.state.is('forms.modifiersView')) {
        this.viewModifier = true;
        this.notEditable = true;
        this.getModifier();
      } // Edit state
      else if (this.state.is('forms.modifiersEdit')) {
        this.viewModifier = false;
        this.notEditable = false;
        this.editModifier = true;
        this.getModifier();
        this.getAllHcpcsCodesMedable();
        this.getAllInsurancesMedable();
        this.getAllFormsMedable();
        this.getAllModifiersMedable();
        this.createOrEdit = true;
      } else if (this.state.is('forms.modifiersCreate')) {
        this.createMode = true;
        this.createOrEdit = true;
        this.getAllHcpcsCodesMedable();
        this.getAllInsurancesMedable();
        this.getAllFormsMedable();
        this.getAllModifiersMedable();
      }
    });
  }

  goToEditModifier(modifierId) {
    this.router.navigate(['forms/modifiers/:id/edit', { id: modifierId }]);
  }

  gotoViewModifier(modifierId) {
    this.router.navigate(['forms/modifiers/:id/view', { id: modifierId }]);
  }

  goToCreateModifier() {
    this.router.navigate(['forms/modifiers/new']);
  }

  gotoModifiersIndex() {
    this.router.navigate(['forms/modifiers/index']);
  }

  getModifier() {

    this.hcpcs_code_list = [];
    this.insurances_list = [];

    this.modifierService.getModifier(this.modifierId).subscribe((modifier) => {
      this.modifier = modifier;
      // console.log('View: This Modifier: ', this.modifier);
      if (modifier.c_auto_populate_insurance != undefined) {
        this.autoPopulateInsurances = [];
        modifier.c_auto_populate_insurance.map((insObj) => {
          this.autoPopulateInsurances.push(insObj.c_insurance);
        });
      }
      this.getModifierHcpcs(this.modifierId);
      this.getModifierInsurances(this.modifierId);
      this.getModifierQuestions(this.modifierId);
    });
  }

  allCodesChange() {
    console.log(this.modifier);
  }

  warningChange() {
    console.log('Modifier: ', this.modifier);
  }

  // Get modifier question list with response options + (Hcpcs List + Insurances List)
  getModifierQuestions(modifierId) {
    this.questions_to_delete = [];
    this.modifierService
      .getModifierQuestions(modifierId)
      .subscribe((questions) => {
        // console.log('This Modifier Questions Before: ', questions);
        // Add hcpcs_codes to questions
        questions.data.map((questData) => {
          this.modifierService
            .getModifierQuestionHcpcs(modifierId, questData._id)
            .subscribe((qHcpcList) => {
              let formattedCodes = qHcpcList.data.map(
                (codeObj) => codeObj.c_hcpcs_code
              );
              questData.hcpcs_codes = formattedCodes;
            });
        });
        // Add Insurances to questions
        questions.data.map((questData) => {
          this.modifierService
            .getModifierQuestionInsurances(modifierId, questData._id)
            .subscribe((qInsList) => {
              let formattedInsurances = qInsList.data.map(
                (insObj) => insObj.c_insurance
              );
              questData.insurances = formattedInsurances;
            });
        });

        // Add empty attaching form + modifier if no form/modifier is attached
        questions.data.map((questData) => {
          questData.newOption = {
            c_attaching_forms: [],
            c_attaching_modifiers: [],
          };

          this.getQuestionResponseOptions(modifierId, questData);

          questData.editable = false;
          this.questions_to_delete.push(questData._id);
        });

        this.modifier_questions = questions.data;
        // console.log('Modifier Questions After: ', this.modifier_questions);
      });
  }

  getQuestionResponseOptions(modifierId, question) {
    this.modifierService
      .getModifierQuestionResponseOptions(modifierId, question._id)
      .subscribe((rOpts) => {
        // console.log('Question Response Options Before: ', rOpts);

        question.c_response_options = rOpts.data.map((rOpt) => {
          return {
            c_option_text: rOpt.c_response_option_text,
            c_attaching_forms: rOpt.c_attaching_form_list.map(
              (fl) => fl.c_attaching_form
            ),
            c_attaching_modifiers: rOpt.c_attaching_modifier_list.map(
              (ml) => ml.c_attaching_modifier
            ),
          };
        });
      });

    // console.log('Question After: ', question);
  }

  getModifierHcpcs(modifierId) {
    this.modifier_hcpcs_to_delete = [];
    this.modifierService.getModifierHcpcs(modifierId).subscribe((resp) => {
      // this.hcpcs_code_list = resp.data;
      resp['data'].map((codeObject) => {
        this.hcpcs_code_list.push(codeObject.c_hcpcs_code);
        this.modifier_hcpcs_to_delete.push(codeObject._id);
      });
    });
  }

  getModifierInsurances(modifierId) {
    this.modifier_insurances_to_delete = [];
    this.modifierService.getModifierInsurances(modifierId).subscribe((resp) => {
      // console.log('Modifier insurances: ', resp);
      resp.data.map((insuranceObject) => {
        this.insurances_list.push(insuranceObject.c_insurance);
        this.modifier_insurances_to_delete.push(insuranceObject._id);
      });
    });
  }

  getAllHcpcsCodesMedable() {
    console.log("GET ALL HCPCS ")
    this.modifierService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
      this.hcpcs_codes = allCodes;
    });
  }

  getAllInsurancesMedable() {
    this.modifierService
      .getAllInsurancesMedable()
      .subscribe((allInsurances) => {
        this.insurances = allInsurances;
      });
  }

  getAllFormsMedable() {
    this.modifierService.getAllFormsMedable().subscribe((allForms) => {
      this.allForms = allForms;
    });
  }

  getAllModifiersMedable() {
    this.modifierService.getAllModifiersMedable().subscribe((allModifiers) => {
      this.allModifiers = allModifiers;
    });
  }

  // Search for hcpcs codes that match the input but exclude those already added in the excludeList array
  searchHcpcsWrapper(excludedList) {
    console.log("SEARCH HCPCS WRAPPER")
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.hcpcs_codes.filter(
              (v) =>
                v.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                excludedList.filter(
                  (code) =>
                    code.c_hcpcs_code
                      .toLocaleLowerCase()
                      .indexOf(v.c_hcpcs_code.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return codes.length ? codes.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchHcpcsModifier() {
    console.log("SEARCH HCPCS modifier")
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.hcpcs_codes.filter(
              (v) =>
                v.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.hcpcs_code_list.filter(
                  (code) =>
                    code.c_hcpcs_code
                      .toLocaleLowerCase()
                      .indexOf(v.c_hcpcs_code.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return codes.length ? codes.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchHcpcsQList() {
    console.log("SEARCH HCPCS q list")
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.hcpcs_codes.filter(
              (v) =>
                v.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.hcpcs_code_question_list.filter(
                  (code) =>
                    code.c_hcpcs_code
                      .toLocaleLowerCase()
                      .indexOf(v.c_hcpcs_code.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return codes.length ? codes.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchInsuranceWrapper(excludeList) {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.insurances
              .filter((v) => {
                if (
                  v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                    -1 &&
                  excludeList.filter((insurance) => {
                    if (
                      insurance.c_insurance_name
                        .toLocaleLowerCase()
                        .indexOf(v.c_insurance_name.toLowerCase()) > -1
                    ) {
                      return insurance;
                    }
                  }).length == 0
                ) {
                  return v;
                }
              })
              .slice(0, 10);
            this.insurances_result = insurances;
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return search;
  }

  searchInsuranceModifier() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.insurances
              .filter((v) => {
                if (
                  v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                    -1 &&
                  this.insurances_list.filter((insurance) => {
                    if (
                      insurance.c_insurance_name
                        .toLocaleLowerCase()
                        .indexOf(v.c_insurance_name.toLowerCase()) > -1
                    ) {
                      return insurance;
                    }
                  }).length == 0
                ) {
                  return v;
                }
              })
              .slice(0, 10);
            this.insurances_result = insurances;
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return search;
  }

  searchInsuranceAuto() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.insurances
              .filter((v) => {
                if (
                  v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                    -1 &&
                  this.autoPopulateInsurances.filter((insurance) => {
                    if (
                      insurance.c_insurance_name
                        .toLocaleLowerCase()
                        .indexOf(v.c_insurance_name.toLowerCase()) > -1
                    ) {
                      return insurance;
                    }
                  }).length == 0
                ) {
                  return v;
                }
              })
              .slice(0, 10);
            // this.insurances_result = insurances;
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return search;
  }

  searchInsuranceQlist() {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.insurances
              .filter((v) => {
                if (
                  v.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                    -1 &&
                  this.insurance_question_list.filter((insurance) => {
                    if (
                      insurance.c_insurance_name
                        .toLocaleLowerCase()
                        .indexOf(v.c_insurance_name.toLowerCase()) > -1
                    ) {
                      return insurance;
                    }
                  }).length == 0
                ) {
                  return v;
                }
              })
              .slice(0, 10);
            this.insurances_result = insurances;
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return search;
  }

  searchForm = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const forms = this.allForms
            .filter((v) => {
              if (v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                return v;
              }
            })
            .slice(0, 10);
          this.filteredForms = forms;
          return forms.length ? forms : [];
        }
      })
    );
  };

  searchFormRopt = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const forms = this.allForms
            .filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.response_option.c_attaching_forms.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            )
            .slice(0, 10);

          this.filteredForms = forms;
          return forms.length ? forms : [];
        }
      })
    );
  };

  searchFormWrapper(excludedList) {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const forms = this.allForms.filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                excludedList.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return forms.length ? forms.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchModifierWrapper(excludedList) {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const modifiers = this.allModifiers.filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                excludedList.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return modifiers.length ? modifiers.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchModifierWrapperNew(index) {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const modifiers = this.allModifiers.filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.modifier_questions[
                  index
                ].newOption.c_attaching_modifiers.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return modifiers.length ? modifiers.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchFormWrapperNew(index) {
    let search = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const forms = this.allForms.filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.modifier_questions[
                  index
                ].newOption.c_attaching_forms.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            );
            // this.hcpcs_results = codes;
            // console.log("aaA:", codes);
            return forms.length ? forms.slice(0, 10) : [];
          }
        })
      );
    };

    return search;
  }

  searchModifier = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const modifiers = this.allModifiers
            .filter((v) => {
              if (v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                return v;
              }
            })
            .slice(0, 10);
          this.filteredModifiers = modifiers;
          return modifiers.length ? modifiers : [];
        }
      })
    );
  };

  searchModifierRopt = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const modifiers = this.allModifiers
            .filter(
              (v) =>
                v.c_name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                this.response_option.c_attaching_modifiers.filter(
                  (f) =>
                    f.c_name
                      .toLocaleLowerCase()
                      .indexOf(v.c_name.toLowerCase()) > -1
                ).length == 0
            )
            .slice(0, 10);
          this.filteredModifiers = modifiers;
          return modifiers.length ? modifiers : [];
        }
      })
    );
  };

  // Don't place hcpcs codes' value in the input, jusd add it to the list
  hcpcsCodeFormatter = (x: { c_hcpcs_code: string }) => '';
  insuranceFormatter = (x: { c_insurance_name: string }) => '';
  formFormatter = (x: { c_name: string }) => '';
  modifierFormatter = (x: { c_name: string }) => '';

  addHcpcsCode(code, object) {
    object.push(code);
    // console.log("code_list: ", this.hcpcs_code_list);
  }

  removeHcpcsCode(codeIndex, object, allowed = true) {
    if (allowed) {
      object.splice(codeIndex, 1);
    }
  }

  addInsurance(insurance, object) {
    object.push(insurance);
  }

  removeInsurance(insuranceIndex, object, allowed = true) {
    if (allowed) {
      object.splice(insuranceIndex, 1);
    }
  }

  attachForm(form, object) {
    object.c_attaching_forms.push(form);
  }

  removeAttForm(index, object, allowed = true) {
    if (allowed) {
      object.c_attaching_forms.splice(index, 1);
    }
  }

  removeAttModifier(index, object, allowed = true) {
    if (allowed) {
      object.c_attaching_modifiers.splice(index, 1);
    }
  }

  attachModifier(modifier, object) {
    object.c_attaching_modifiers.push(modifier);
  }

  addOptionToAddedQuestion(questionIndex, newOption) {
    let addingOption = { ...newOption };
    this.modifier_questions[questionIndex].c_response_options.push(
      addingOption
    );

    this.modifier_questions[questionIndex].newOption = {
      c_attaching_forms: [],
      c_attaching_modifiers: [],
    };
  }

  removeOptionAdded(questionIndex, optionIndex) {
    this.modifier_questions[questionIndex].c_response_options.splice(
      optionIndex,
      1
    );
  }

  addOption() {
    let response_option = { ...this.response_option };
    this.response_options.push(response_option);
    this.response_option = {
      c_attaching_forms: [],
      c_attaching_modifiers: [],
    };
    // console.log("Options: ", this.response_options);
  }

  removeOptionListed(index) {
    this.response_options.splice(index, 1);
  }

  addQuestion() {
    // console.log("Adding Question to list...");
    // console.log("Selected for question: ", this.hcpcs_code_question_list);
    let questionTemplate = {
      c_question_text: this.modifier_question.c_question_text,
      c_response_options: this.response_options,
      newOption: {
        c_attaching_forms: [],
        c_attaching_modifiers: [],
      },

      editable: false,
      hcpcs_codes: [...this.hcpcs_code_question_list],
      insurances: this.insurance_question_list,
    };
    this.modifier_questions.push(questionTemplate);
    this.modifier_question = {};
    this.response_options = [];
    this.hcpcs_code_question_list = [];
    this.insurance_question_list = [];
    this.response_option = {
      c_attaching_forms: [],
      c_attaching_modifiers: [],
    };
    // console.log("Question List: ", this.modifier_questions);
  }

  editQuestionAdded(index) {
    this.modifier_questions[index].editable = true;
  }

  finishEditQuestionAdded(questionIndex) {
    this.modifier_questions[questionIndex].editable = false;
  }

  removeQuestionAdded(questionIndex) {
    this.modifier_questions.splice(questionIndex, 1);
  }

  // When SUbmiting Modifier Form
  onSubmit() {
    let operation = '';
    if (this.modifierId != '0') {
      operation = 'edit';
    } else {
      operation = 'create';
    }

    if (operation == 'edit') {
      this.updateModifier();
    } else if (operation == 'create') {
      this.createModifier();
    }
  }

  createModifier() {
    // Create Modifier
    if (this.autoPopulateInsurances.length) {
      this.modifier['c_auto_populate_insurance'] = [];
      this.autoPopulateInsurances.map((api) => {
        this.modifier['c_auto_populate_insurance'].push({
          c_insurance: api._id,
        });
      });
    }

    this.modifierService.createModifier(this.modifier).subscribe((resp) => {
      console.log('Modifier Created! ', resp);
      // Save Hcpcs Codes associated to the modifier
      this.saveModifierHcpcs(resp._id);
      this.saveModifierInsurances(resp._id);
      this.saveModifierQuestions(resp._id);

      setTimeout(() => {
        this.gotoViewModifier(resp._id);
      }, 3000);
    });
  }

  updateModifier() {
    let modifObj = {
      c_name: this.modifier.c_name,
      c_warning: this.modifier.c_warning,
    };
    if (this.modifier.c_all_hcpcs_codes) {
      console.log('Modif Obj: ', modifObj);
      modifObj['c_all_hcpcs_codes'] = true;
    } else {
      modifObj['c_all_hcpcs_codes'] = false;
    }
    if (this.autoPopulateInsurances.length) {
      modifObj['c_auto_populate_insurance'] = [];
      this.autoPopulateInsurances.map((api) => {
        modifObj['c_auto_populate_insurance'].push({
          c_insurance: api._id,
        });
      });
    }

    // Update Modifier Base Object (name)
    this.modifierService
      .updateModifier(this.modifierId, modifObj)
      .subscribe((resp) => {
        console.log('Modifier Updated: ', resp);
        let modifHcpcsToDelete = this.modifier_hcpcs_to_delete.length;
        let modifInsToDelete = this.modifier_insurances_to_delete.length;
        let modifQuestToDelete = this.questions_to_delete.length;

        // If there were no old hcpcs codes, insert the new ones directly
        if (modifHcpcsToDelete == 0) {
          this.saveModifierHcpcs(resp._id);
        } else {
          // Delete All previously saved hcpcs codes associated with this modifier
          this.modifier_hcpcs_to_delete.map((codeToDelete) => {
            this.modifierService
              .deleteModifierHcpcs(codeToDelete)
              .subscribe((dResp) => {
                console.log('ModifierHcpcs Deleted!', dResp);
                modifHcpcsToDelete--;
                // After all old codes were deleted, save new ones!
                if (!modifHcpcsToDelete) {
                  this.saveModifierHcpcs(resp._id);
                }
              });
          });
        }
        // If there were no old insurances insert new ones directly
        if (modifInsToDelete == 0) {
          this.saveModifierInsurances(resp._id);
        } else {
          // Delete All previously saved insurances associated to the modifier
          this.modifier_insurances_to_delete.map((insToDelete) => {
            this.modifierService
              .deleteModifierInsurances(insToDelete)
              .subscribe((dResp) => {
                console.log('Insurance Deleted!', dResp);
                modifInsToDelete--;
                // After all old insurances were deleted
                if (!modifInsToDelete) {
                  this.saveModifierInsurances(resp._id);
                }
              });
          });
        }

        if (modifQuestToDelete == 0) {
          this.saveModifierQuestions(resp._id);
        }

        this.questions_to_delete.map((qToDelete) => {
          this.modifierService
            .deleteModifierQuestion(qToDelete)
            .subscribe((qDeleted) => {
              console.log('Question Deleted!', qDeleted);
              modifQuestToDelete--;
              if (!modifQuestToDelete) {
                this.saveModifierQuestions(resp._id);
              }
            });
        });

        setTimeout(() => {
          this.gotoViewModifier(resp._id);
        }, 3000);
      });
  }

  saveModifierQuestions(modifierId) {
    let expectedIterations = this.modifier_questions.length;
    // let observables = [];
    // For every question associated with this modifier
    this.modifier_questions.map((question) => {
      // Formatted modifier question object ready to be saved in medable
      let questionStruct = {
        c_question_text: question.c_question_text,
        c_modifier: modifierId,
      };

      // let request = this.modifierService.createModifierQuestion(questionStruct);
      this.modifierService
        .createModifierQuestion(questionStruct)
        .subscribe((savedQuestion) => {
          // console.log("Modifier Question succesfully saved!", savedQuestion);

          let response_options = question.c_response_options.map((respOpt) => {
            return {
              c_modifier: modifierId,
              c_question: savedQuestion._id,
              c_response_option_text: respOpt.c_option_text,
              c_attaching_form_list: respOpt.c_attaching_forms.map((af) => {
                return { c_attaching_form: af._id };
              }),

              c_attaching_modifier_list: respOpt.c_attaching_modifiers.map(
                (am) => {
                  return { c_attaching_modifier: am._id };
                }
              ),
            };
          });

          console.log('Mapped Response Options: ', response_options);

          // Save each response Option
          response_options.map((rOpt) => {
            this.modifierService
              .createModifierQuestionResponseOption(rOpt)
              .subscribe((saved) => {
                // console.log("Response Option SUccesfully saved!!!", saved);
              });
          });

          // For each hcpcs of the question save it
          question.hcpcs_codes.map((qCode) => {
            let tmpMQHcpcs = {
              c_modifier: modifierId,
              c_modifier_question: savedQuestion._id,
              c_hcpcs_code: qCode._id,
            };
            this.modifierService
              .createModifierQuestionHcpcs(tmpMQHcpcs)
              .subscribe((savedQuestionHcpcs) => {
                // console.log("Hcpcs Question saved succesfully", savedQuestionHcpcs);
              });
          });

          question.insurances.map((insurance) => {
            let tmpMQInsurance = {
              c_modifier: modifierId,
              c_modifier_question: savedQuestion._id,
              c_insurance: insurance._id,
            };

            this.modifierService
              .createModifierQuestionInsurance(tmpMQInsurance)
              .subscribe((savedQuestionInsurance) => {
                // console.log("Question Insurance succesfully saved!", savedQuestionInsurance);
              });
          });

          expectedIterations--;

          if (!expectedIterations) {
            this.expectedAsyncCreations--;
          }
        });
    });
  }

  // Save Modifer Hcpcs List
  saveModifierHcpcs(modifierId) {
    let expectedIterations = this.hcpcs_code_list.length;
    // let executedIterations = 0;

    this.hcpcs_code_list.map((code) => {
      let tmpObj = {
        c_modifier: modifierId,
        c_hcpcs_code: code._id,
      };

      this.modifierService.createModifierHcpcs(tmpObj).subscribe((response) => {
        // console.log("Modifier Hcpcs Saved!", response);
        expectedIterations--;
        if (!expectedIterations) {
          // console.log("All hcpcs Codes saved!");
          this.expectedAsyncCreations--;
        }
      });
    });
  }

  saveModifierInsurances(modifierId) {
    let expectedIterations = this.insurances_list.length;

    this.insurances_list.map((insurance) => {
      let tmpObj = {
        c_modifier: modifierId,
        c_insurance: insurance._id,
      };

      this.modifierService
        .createModifierInsurance(tmpObj)
        .subscribe((response) => {
          // console.log("Modifier Insurance Created!", response);
          expectedIterations--;
          if (!expectedIterations) {
            console.log('All Insurances Saved!');
          }
          this.expectedAsyncCreations--;
        });
    });
  }

  deleteModifier() {
    this.showPopupWindow('modifier.delete.confirm').then((resp) => {
      if (resp.selected === 'LEFT') {
        this.modifierService
          .deleteModifier(this.modifierId)
          .subscribe((response) => {
            this.gotoModifiersIndex();
          });
      }
    });
  }

  showPopupWindow(popupText) {
    const info = {
      subtitle: popupText,
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
      // inputConfirm:true
    };

    let modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    return modalInstance.result;
  }

  cancel() {
    this.showPopupWindow('modifier.cancel.form').then((resp) => {
      if (resp.selected === 'LEFT') {
        if (this.modifierId != '0') {
          this.gotoViewModifier(this.modifierId);
        } else {
          this.gotoModifiersIndex();
        }
      }
    });
  }

  modifierNotUpdatable() {
    if (this.modifier_question.c_question_text) {
      return true;
    } else {
      return false;
    }
  }
}
