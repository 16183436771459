<!-- Item List Component -->
<app-location-groups-list class="ListContainer"></app-location-groups-list>

<div class="forms-form">
  <app-location-groups-index
    *ngIf="stateName == 'forms.locationGroupsIndex'"
  ></app-location-groups-index>
  <app-location-groups-create
    *ngIf="stateName == 'forms.locationGroupsCreate'"
  ></app-location-groups-create>
  <app-location-groups-view
    *ngIf="stateName == 'forms.locationGroupsView'"
  ></app-location-groups-view>
  <app-location-groups-edit
    *ngIf="stateName == 'forms.locationGroupsEdit'"
  ></app-location-groups-edit>
</div>
