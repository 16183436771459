<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchProvider"
      class="search-input"
      placeholder="search by name"
      (keyup)="$event.keyCode == 13 && getProviders(searchProvider)"
    />
    <button
      type="button"
      class="btn-link"
      (click)="getProviders(searchProvider)"
    >
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <div class="container-button">
    <a
      *ngIf="
        !stateCreate &&
        (hasRole.admin || hasRole.executive || hasRole.site_manager)
      "
      class="button create"
      (click)="goToCreateProvider()"
    >
      {{ 'providers.provider-form-new.title' | lang }}
    </a>
  </div>
  <ul>
    <li *ngFor="let provider of providers">
      <label (click)="goToViewProvider(provider._id)">
        {{ provider.c_preffix ? provider.c_preffix + '. ' : ' ' }}
        {{ provider.c_name.c_first_name + ' ' + provider.c_name.c_last_name
        }}{{ provider.c_suffix ? ', ' + provider.c_suffix : ' ' }}
      </label>
    </li>
  </ul>
</div>
