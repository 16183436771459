import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './components/auth/auth.component';
import { BulkOperationsComponent } from './components/bulk-operations/bulk-operations.component';
import { CustomAccessRouteComponent } from './components/custom-access-route/custom-access-route.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FittersComponent } from './components/fitters/fitters.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormComponent } from './components/form/form.component';
import { FormsComponent } from './components/forms/forms.component';
import { HeaderComponent } from './components/header/header.component';
import { Icd10Component } from './components/icd10/icd10.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { LocationGroupsMainComponent } from './components/location-groups/location-groups-main/location-groups-main.component';
import { LocationComponent } from './components/location/location.component';
import { LoginComponent } from './components/login/login.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { OrderComponent } from './components/order/order.component';
import { PatientComponent } from './components/patient/patient.component';
import { ProviderComponent } from './components/provider/provider.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
    ],
  },
  {
    path: '',
    component: FormsComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'reporting', component: ReportingComponent },
      { path: 'bulk', component: BulkOperationsComponent },
      { path: 'externalAccess', component: CustomAccessRouteComponent },
      {
        path: 'forms/providers/index',
        component: ProviderComponent,
        data: { stateName: 'forms.providersIndex' },
      },
      {
        path: 'forms/providers/new',
        component: ProviderComponent,
        data: { stateName: 'forms.providersCreate' },
      },
      {
        path: 'forms/providers/:id/edit',
        component: ProviderComponent,
        data: { stateName: 'forms.providersEdit' },
      },
      {
        path: 'forms/providers/:id/view',
        component: ProviderComponent,
        data: { stateName: 'forms.providersView' },
      },
      {
        path: 'forms/locations/index',
        component: LocationComponent,
        data: { stateName: 'forms.locationsIndex' },
      },
      {
        path: 'forms/locations/new',
        component: LocationComponent,
        data: { stateName: 'forms.locationsCreate' },
      },
      {
        path: 'forms/locations/:id/edit',
        component: LocationComponent,
        data: { stateName: 'forms.locationsEdit' },
      },
      {
        path: 'forms/locations/:id/view',
        component: LocationComponent,
        data: { stateName: 'forms.locationsView' },
      },
      { path: 'forms/inventories', component: HeaderComponent },
      { path: 'forms/patients', component: HeaderComponent },
      {
        path: 'forms/insurances/index',
        component: InsuranceComponent,
        data: { stateName: 'forms.insurancesIndex' },
      },
      {
        path: 'forms/insurances/new',
        component: InsuranceComponent,
        data: { stateName: 'forms.insurancesCreate' },
      },
      {
        path: 'forms/insurances/:id/edit',
        component: InsuranceComponent,
        data: { stateName: 'forms.insurancesEdit' },
      },
      {
        path: 'forms/insurances/:id/view',
        component: InsuranceComponent,
        data: { stateName: 'forms.insurancesView' },
      },
      { path: 'forms/form', component: HeaderComponent },
      {
        path: 'forms/fitters/index',
        component: FittersComponent,
        data: { stateName: 'forms.fittersIndex' },
      },
      {
        path: 'forms/fitters/:id/view',
        component: FittersComponent,
        data: { stateName: 'forms.fittersView' },
      },
      {
        path: 'forms/fitters/:id/edit',
        component: FittersComponent,
        data: { stateName: 'forms.fittersEdit' },
      },
      {
        path: 'forms/icd10s/index',
        component: Icd10Component,
        data: { stateName: 'forms.icd10sIndex' },
      },
      {
        path: 'forms/icd10s/new',
        component: Icd10Component,
        data: { stateName: 'forms.icd10sCreate' },
      },
      {
        path: 'forms/icd10s/:id/edit',
        component: Icd10Component,
        data: { stateName: 'forms.icd10sEdit' },
      },
      {
        path: 'forms/icd10s/:id/view',
        component: Icd10Component,
        data: { stateName: 'forms.icd10sView' },
      },
      {
        path: 'forms/patients/index',
        component: PatientComponent,
        data: { stateName: 'forms.patientsIndex' },
      },
      {
        path: 'forms/patients/new',
        component: PatientComponent,
        data: { stateName: 'forms.patientsCreate' },
      },
      {
        path: 'forms/patients/:id/view',
        component: PatientComponent,
        data: { stateName: 'forms.patientsView' },
      },
      {
        path: 'forms/patients/:id/edit',
        component: PatientComponent,
        data: { stateName: 'forms.patientsEdit' },
      },
      {
        path: 'forms/inventories/index',
        component: InventoryComponent,
        data: { stateName: 'forms.inventoriesIndex' },
      },
      {
        path: 'forms/inventories/:id/view',
        component: InventoryComponent,
        data: { stateName: 'forms.inventoriesView' },
      },
      {
        path: 'forms/forms/index',
        component: FormComponent,
        data: { stateName: 'forms.formsIndex' },
      },
      {
        path: 'forms/forms/:id/view',
        component: FormComponent,
        data: { stateName: 'forms.formsView' },
      },
      {
        path: 'forms/forms/:id/edit',
        component: FormComponent,
        data: { stateName: 'forms.formsEdit' },
      },
      {
        path: 'forms/forms/new',
        component: FormComponent,
        data: { stateName: 'forms.formsNew' },
      },
      {
        path: 'forms/modifiers/index',
        component: ModifierComponent,
        data: { stateName: 'forms.modifiersIndex' },
      },
      {
        path: 'forms/modifiers/:id/view',
        component: ModifierComponent,
        data: { stateName: 'forms.modifiersView' },
      },
      {
        path: 'forms/modifiers/new',
        component: ModifierComponent,
        data: { stateName: 'forms.modifiersCreate' },
      },
      {
        path: 'forms/modifiers/:id/edit',
        component: ModifierComponent,
        data: { stateName: 'forms.modifiersEdit' },
      },
      {
        path: 'forms/orders/index',
        component: OrderComponent,
        data: { stateName: 'forms.ordersIndex' },
      },
      {
        path: 'forms/orders/:id/view',
        component: OrderComponent,
        data: { stateName: 'forms.ordersView' },
      },
      {
        path: 'forms/orders/new',
        component: OrderComponent,
        data: { stateName: 'forms.ordersCreate' },
      },
      {
        path: 'forms/orders/:id/edit',
        component: OrderComponent,
        data: { stateName: 'forms.ordersEdit' },
      },
      {
        path: 'forms/orders/:id/edit/orderForm',
        component: OrderComponent,
        data: { stateName: 'forms.orderFormsEdit' },
      },
      {
        path: 'forms/orders/:id/edit/ruleForm',
        component: OrderComponent,
        data: { stateName: 'forms.ruleFormsEdit' },
      },
      {
        path: 'forms/patients/history/:id/view',
        component: PatientComponent,
        data: { stateName: 'forms.patientsHistory' },
      },
      {
        path: 'forms/locationGroups/index',
        component: LocationGroupsMainComponent,
        data: { stateName: 'forms.locationGroupsIndex' },
      },
      {
        path: 'forms/locationGroups/:id/view',
        component: LocationGroupsMainComponent,
        data: { stateName: 'forms.locationGroupsView' },
      },
      {
        path: 'forms/locationGroups/new',
        component: LocationGroupsMainComponent,
        data: { stateName: 'forms.locationGroupsCreate' },
      },
      {
        path: 'forms/locationGroups/:id/edit',
        component: LocationGroupsMainComponent,
        data: { stateName: 'forms.locationGroupsEdit' },
      },
      // forms/orders/:id/edit/orderForm
    ],
  },
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [],
})
export class AppRoutingModule {}
