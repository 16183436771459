import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { PopupComponent } from '../popup/popup.component';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { LocationService } from '../../services/location/location.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-fitters',
  templateUrl: './fitters.component.html',
  styleUrls: ['./fitters.component.css'],
})
export class FittersComponent implements OnInit {
  location: any;
  allLocations: any[];
  relatedLocationsList: any[];
  allFitters: any[];
  fitterId: string;
  selectedFitter: any;

  fitterSubmited = false;
  fittersAutocomplete: any;
  providersAutocomplete: any;
  locationsAutocomplete: any;

  modalInstance: any = undefined;
  hasRole: any;

  showEditForm = false;
  canEdit = false;
  showFitter = false;

  buttonsDisabled = false;

  notEditable = true;

  relatedLocationIds: any[];
  snapshotLocations: any[];

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private formsService: FormsService
  ) {
    this.onFitterSelected = this.onFitterSelected.bind(this);
    this.getRelatedLocations = this.getRelatedLocations.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);
    this.goToBack = this.goToBack.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.goToIndexFitter = this.goToIndexFitter.bind(this);
    this.goToViewFitter = this.goToViewFitter.bind(this);
    this.goToEditFitter = this.goToEditFitter.bind(this);
    // this.closeModalInstance = this.closeModalInstance.bind(this);
    this.selectedFitter = {
      name: { first: '', last: '' },
      email: '',
    };
  }

  ngOnInit() {
    this.getFitters();
    this.sub = this.route.params.subscribe((params) => {
      this.fitterId = params.id ? params.id : '0';

      this.hasRole = this.authService.getAuthorizedUserRole();

      if (this.state.is('forms.fittersView')) {
        this.showFitter = true;
        this.canEdit = false;
        this.getFitter();
        this.getRelatedLocations();
      }
      if (this.state.is('forms.fittersEdit')) {
        this.showFitter = true;
        this.canEdit = true;
        this.getFitter();
        this.getRelatedLocations(() => {
          this.getLocations(() => {
            this.updateLocationsList();
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getLocations(cb = () => {}) {
    this.formsService.get('locations').subscribe((locations) => {
      this.allLocations = locations.data;
      console.log('aaaa', this.allLocations);
      this.snapshotLocations = this.allLocations.slice(0);
      cb();
    });
  }

  removeRelatedLocation(location) {
    return this.relatedLocationIds.indexOf(location._id) === -1;
  }

  updateLocationsList() {
    this.allLocations = this.snapshotLocations.slice(0);
    this.relatedLocationIds = this.relatedLocationsList.map((location) => {
      if (location.deleted) {
        return;
      }
      return location.c_location._id;
    });
    this.allLocations = this.allLocations.filter(
      this.removeRelatedLocation.bind(this)
    );
  }

  getRelatedLocations(cb = () => {}) {
    this.formsService
      .get(
        'account_locations',
        '?paths[]=c_location&where={"c_fitter":{"$in": ["' +
          this.fitterId +
          '"]}}&expand[]=c_location'
      )
      .subscribe((locations) => {
        this.relatedLocationsList = locations.data;
        cb();
      });
  }

  // closeModalInstance() {
  //     console.log("Enter closemodal this: ", this);
  //     if(this.modalInstance) {
  //         console.log("enter if");
  //         this.modalInstance.close();
  //     }
  // }

  getFitter() {
    const fitterId = this.fitterId;

    const filter = `where={"_id": "${fitterId}"}`,
      params = `?${filter}&paths[]=name&paths[]=email&limit=10000`;

    this.formsService.get('all_accounts', params).subscribe((fitters) => {
      const data = fitters.data;
      this.selectedFitter = data.filter((fitter) => fitter._id === fitterId)[0];
      this.showFitter = true;
    });
  }

  getFitters() {
    this.formsService
      .get('all_accounts', '?paths[]=name&paths[]=email&limit=10000')
      .subscribe((fitters) => {
        console.log('All Fitters: ', fitters.data);
        this.allFitters = fitters.data;
      });
  }

  removeLocation(location) {
    location.deleted = true;
    // const index: number = this.relatedLocationsList.indexOf(location);
    // if (index !== -1) {
    //   this.relatedLocationsList.splice(index, 1);
    // }
    this.locationsAutocomplete = '';
    this.updateLocationsList();
  }

  goToViewFitter() {
    if (this.modalInstance) {
      setTimeout(() => {
        // this.modalInstance.close();
      }, 10);
    }
    this.router.navigate(['forms/fitters/:id/view', { id: this.fitterId }]);
  }

  goToEditFitter() {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
    this.router.navigate(['forms/fitters/:id/edit', { id: this.fitterId }]);
  }

  goToIndexFitter() {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
    this.router.navigate(['forms/fitters/index', { id: this.fitterId }]);
  }

  cancel() {
    const info = {
      title: 'forms.confirm.title',
      subtitle: 'forms.confirm.subtitle',
      btnLeft: 'NO',
      btnLeftClass: 'no',
      btnRight: 'YES',
      btnRightClass: 'yes',
    };
    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);
    modalInstance.result.then(this.goToBack);
  }

  updateReferences() {
    for (let i = 0; i < this.relatedLocationsList.length; i++) {
      const location = this.relatedLocationsList[i];
      if (location.deleted) {
        this.locationService
          .delete('account_locations', location._id)
          .subscribe((response) => {
            //console.log("Deleted");
          });
      } else if (location.created) {
        const locationFitter = {
          c_fitter: this.fitterId,
          c_location: location.c_location._id,
        };

        this.locationService
          .createFitterReference(locationFitter)
          .subscribe((response) => {
            //console.log("Created");
          });
      }
    }

    // this.goToBack();
  }

  onSubmit() {
    this.updateReferences();
    if (this.state.is('forms.fittersEdit')) {
      this.goToViewFitter();
    } else {
      this.goToBack();
    }
  }

  goToBack() {
    if (this.state.is('forms.fittersEdit')) {
      this.goToViewFitter();
    } else {
      this.router.navigate(['forms/fitters/index']);
    }
  }

  onFitterSelected() {
    if (typeof this.fittersAutocomplete === 'object') {
      this.selectedFitter = this.fittersAutocomplete;
      this.fitterId = this.fittersAutocomplete._id;
      this.goToViewFitter();
    }
  }

  onLocationSelected() {
    if (typeof this.locationsAutocomplete === 'object') {
      const createElement = {
        c_location: this.locationsAutocomplete,
        object: 'c_fitter_location',
        created: true,
      };
      this.relatedLocationsList.push(createElement);
      this.locationsAutocomplete = '';
      this.updateLocationsList();
    }
  }

  searchFitters = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const email = this.allFitters
            .filter(
              (v) => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const firstname = this.allFitters
            .filter(
              (v) => v.name.first.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const lastname = this.allFitters
            .filter(
              (v) => v.name.last.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          return email.length
            ? email
            : lastname.length
            ? lastname
            : firstname.length
            ? firstname
            : [];
        }
      })
    );
  };

  formatterFitters = (x: { email: string }) => x.email;

  searchLocations = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') {
          return [];
        } else {
          const companies = this.allLocations
            .filter(
              (v) => v.c_company.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          const cities = this.allLocations
            .filter(
              (v) =>
                v.c_address.c_postal_city
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10);
          return companies.length ? companies : cities.length ? cities : [];
        }
      })
    );
  };

  formatterLocations = (x: {
    c_company: string;
    c_address: {
      c_postal_city: string;
    };
  }) => x.c_company + ' ' + x.c_address.c_postal_city;
}
