import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { HcpcsService } from '../../services/hcpcs/hcpcs.service';
import { InventoryService } from '../../services/inventory/inventory.service';
import { LocationService } from '../../services/location/location.service';
import { StockService } from '../../services/stock/stock.service';
import { SupplierService } from '../../services/supplier/supplier.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
})
export class InventoryComponent implements OnInit {
  inventoryId: string;
  inventory: any;

  hasRole: any;

  showForm: boolean;
  viewInventory: boolean = false;

  isEditMode: boolean = false;

  inventories: any;
  indexPage: boolean = false;
  inventoryUpdateStarted: boolean = false;

  progressBar = {
    total: 0,
    done: 0,
    percent: 0,
    display: false,
  };

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private inventoryService: InventoryService,
    private router: Router,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private hcpcsService: HcpcsService,
    private supplierService: SupplierService,
    private stockService: StockService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    // this.getAllHcpcsCodes();
    this.dataTest();
    this.addMissingHcpcs();
    this.sub = this.route.params.subscribe((params) => {
      this.inventoryId = params['id'] ? params['id'] : '0';
      this.hasRole = this.authService.getAuthorizedUserRole();

      // If index, we show the create button.
      this.indexPage = this.state.is('forms.inventoriesIndex');

      if (this.state.is('forms.inventoriesView')) {
        this.getInventory();
      }
    });
  }

  getProductLocation = (inventory) => {
    this.inventoryService.getProductLocation(inventory._id).subscribe((loc) => {
      console.log('Product location: ', loc);

      let processed = loc.data.map((l) => l.c_location.c_company);
      console.log('Processed Locations: ', processed);
    });
  };

  getAllHcpcsCodes() {
    // Uncomment this block to get all the hcpcs codes from Cin7
    // this.inventoryService.getHcpcsCodes().subscribe( res => {
    //     console.log("HCPCS CODES: ", res);
    // Uncomment this line to process hcpcs codes and insert them distinctivelly into medable
    //     //this.processhcpcsCodes(res);
    // });

    // This block reads all the distinct hcpcs codes saved in Medable
    this.inventoryService.getHcpcsCodesMedable().subscribe((res) => {
      console.log('Hcpcs Codes From Medable: ', res);
    });
  }

  dataTest() {
    this.supplierService.getSupplierTest('72').subscribe((suppls) => {
      console.log('Suppls: ', suppls);
      // const supplList = suppls.data.map( s => `"${s._id}"`);
      // // console.log("suppList: ", supplList);
      // this.inventoryService.findInventoryBySuppliers(supplList).subscribe( invs=> {
      //     console.log("Invs: ", invs);
      // });

      // const supplsToDelete = suppls.data.map(s => s._id);
      // console.log("To Delete: ", supplsToDelete);

      // supplsToDelete.splice(0, 20).map( std => {
      //     this.supplierService.deleteSupplier(std).subscribe( deleted => {
      //         console.log("Supplier Deleted: ", deleted);
      //     });
      // });
    });
  }

  // Process hcpcs codes from cin7 and insert them distinctively in Medable
  processhcpcsCodes(inventories) {
    // let hcpcs_codes = [];
    // let hcpcs_codes_stats = [];
    // inventories.map( inventory => {
    //     if(inventory.customFields.products_1000 && inventory.customFields.products_1000 != null && inventory.customFields.products_1000 != '(null)')
    //         hcpcs_codes.push(inventory.customFields.products_1000);
    // });
    // hcpcs_codes.map( code => {
    //     let count = 0;
    //     hcpcs_codes.map( inner_code => {
    //         if(inner_code == code)
    //             count++;
    //     });
    //     let tmpObj = {
    //         c_hcpcs_code: code,
    //     };
    //     let exists = 0;
    //     hcpcs_codes_stats.map( code_obj => {
    //         if(code_obj.c_hcpcs_code == tmpObj.c_hcpcs_code)
    //             exists = 1;
    //     });
    //     if (exists == 0)
    //         hcpcs_codes_stats.push(tmpObj);
    // });
    // console.log("HCPCS Codes: ", hcpcs_codes);
    // console.log("HCPCS Codes Stats: ", hcpcs_codes_stats);
    //Inserting Codes to Medable
    // hcpcs_codes_stats.map( code_obj => {
    //     this.inventoryService.insertHcpcsCode(code_obj).subscribe( resp => {
    //         console.log("Code Succesfully Inserted: ", resp);
    //     })
    // });
  }

  getInventory() {
    this.inventoryService
      .getInventory(this.inventoryId)
      .subscribe((inventory) => {
        this.getProductLocation(inventory);
        console.log('Medable Inventory: ', inventory);
        this.inventory = {
          c_name: inventory.c_name,
          c_hcpcs_code: inventory.c_hcpcs_code,
          c_product_options: inventory.c_product_options,
          c_cost: inventory.c_cost,
          c_cin7_id: inventory.c_cin7_id,
        };
        if (inventory.c_supplier_id) {
          this.inventory.c_supplier_id = inventory.c_supplier_id;
        } else {
          this.inventory.c_supplier_id = { c_company: ' ' };
        }

        // this.inventory.c_supplier_id = {c_company : ' '};
        // console.log(this.inventory);
        this.viewInventory = true;
      });
  }

  updateInventory() {
    this.inventoryUpdateStarted = true;
    console.log('this inventory: ', this.inventory);
    this.inventoryService
      .getCin7Inventory(this.inventory.c_cin7_id)
      .subscribe((cin7Inventory) => {
        console.log('Cin7Inventory: ', cin7Inventory);

        if (cin7Inventory.data.length > 0) {
          const inventory = cin7Inventory.data[0];

          this.supplierService
            .getSupplier(inventory.supplierId)
            .subscribe((supplier) => {
              const supp = supplier.data[0] ? [supplier.data[0]] : [];
              const adaptedInventory = this.updateObjStructure([inventory], supp);
              this.inventoryService
                .editInventory(this.inventoryId, adaptedInventory[0])
                .subscribe((response) => {
                  console.log('Invnetory updated, updating stock...', response);
                  this.updateStock(response);
                });
            });
        }
      });

    setTimeout(() => {
      if (this.inventoryUpdateStarted) {
        this.goToViewInventory(this.inventoryId);
      }
    }, 8000);
  }

  updateStock(inventory) {
    const promises = [];

    let loop = 0;
    let expectedIterations = 0;
    let iterations = 0;

    inventory.c_product_options.forEach((item) => {
      if (item.c_code) {
        expectedIterations++;
      }
    });

    console.log('Expected: ', expectedIterations);
    inventory.c_product_options.map((opts) => {
      loop++;
      // console.log("loopt: ", loop);
      const code = opts.c_code;
      if (code) {
        const promise = this.getStockByCode(opts, code, loop);
        promises.push(promise);
      }
    });

    console.log('promises: ', promises);
    forkJoin(promises).subscribe((responses) => {
      console.log('Finished!', responses);
      this.goToViewInventory(this.inventoryId);
    });
  }

  updateStockNew(inventory) {
    //const
  }

  getStockByCode(opts, code, loop) {
    const uoiPromises = [];
    let result = [];
    let localPromise = new Subject();
    //console.log("Getting stock for code: ", code)

    setTimeout(() => {
      this.stockService.getStockFromCin7(code).subscribe((stockCin7List) => {
        if (code.toLowerCase() == 'g702') {
          console.log('G702 - stockcin7list: ', stockCin7List);
        }

        console.log('stock by code : ', stockCin7List);

        if (
          stockCin7List.data.length &&
          typeof stockCin7List.data != 'string'
        ) {
          // console.log("founr items: ", stockCin7List)
          stockCin7List.data.map((stockCin7) => {
            const promise = this.updateOrInsertStock(opts, stockCin7);
            uoiPromises.push(promise);
          });
        } else {
          console.log(
            'Not Good: ',
            stockCin7List,
            stockCin7List.data.length,
            typeof stockCin7List.data
          );
          console.log('no stock found for code: ', code);
          // localPromise.next(1);
          // localPromise.complete();
          // const rateLimit = new Subject();
          // uoiPromises.push(rateLimit);
          // rateLimit.next(1);
          // rateLimit.complete();
        }

        forkJoin(uoiPromises).subscribe((res) => {
          console.log('FOrking DOne!!!', res);
          localPromise.next(res);
          localPromise.complete();
        });
      });
    }, 1010 * loop);

    return localPromise;
  }

  updateOrInsertStock(opts, stockCin7) {
    let localPromise = new Subject();

    this.locationService
      .getLocationWithCin7Id(stockCin7.branchId)
      .subscribe((location) => {
        if (!location.data.length) {
          console.log('No location: ');
          localPromise.next();
          localPromise.complete();
        } else {
          const newStock = this.createStock(
            stockCin7,
            location.data[0]._id,
            opts.c_code
          );
          this.stockService
            .getStock(newStock.c_location, newStock.c_product_option_code)
            .subscribe((stock) => {
              console.log('Stock: ', stock);
              if (stock.data.length) {
                this.stockService
                  .updateStock(newStock, stock.data[0]._id)
                  .subscribe((res) => {
                    // console.log("UpdateStockResponse: ", res);
                    localPromise.next(res);
                    localPromise.complete();
                  });
              } else {
                console.log('NewStock', newStock);
                this.stockService.insertStock(newStock).subscribe((res) => {
                  localPromise.next(res);
                  localPromise.complete();
                });
              }
            });
        }
      });

    return localPromise;
  }

  createStock(stockCin7, locationId, code) {
    return {
      c_location: locationId,
      c_product: this.inventoryId,
      c_product_option_code: code,
      c_stock: stockCin7.available,
      c_modified_date: new Date(),
    };
  }

  updateObjStructure(inventories, suppliers) {
    const updatedLocations = [];
    for (let i = 0; i < inventories.length; i++) {
      let medableStructure = {
        c_cin7_id: inventories[i].id,
        c_category: inventories[i].category ? inventories[i].category : '',
        c_name: inventories[i].name,
        c_product_options: inventories[i].productOptions
          ? this.generateProductOptionsStructure(inventories[i].productOptions)
          : [],
        c_style_code: inventories[i].styleCode,
        c_hcpcs_code: inventories[i].customFields.products_1000,
      };

      const supplier = this.getSupplierId(inventories[i].supplierId, suppliers);
      if (supplier !== '') {
        medableStructure['c_supplier_id'] = supplier;
      }

      updatedLocations.push(medableStructure);
    }

    return updatedLocations;
  }

  generateProductOptionsStructure(productOptions) {
    const updatedProductOptions = [];

    for (let i = 0; i < productOptions.length; i++) {
      const prodOpt = {
        c_product_id: productOptions[i].productId.toString(),
        c_code: productOptions[i].code,
        c_size: productOptions[i].option1 || '-',
        c_color: productOptions[i].option2 || '-',
        c_unit: productOptions[i].option3 || '-',
        c_barcode: productOptions[i].barcode ? productOptions[i].barcode : '',
      };

      updatedProductOptions.push(prodOpt);
    }

    return updatedProductOptions;
  }

  getSupplierId(id, suppliers) {
    let ret = '';
    suppliers.map((element) => {
      if (element.c_cin7_id == id) {
        ret = element._id;
      }
    });
    return ret;
  }

  updateInventories() {
    // uncomment this and comment the below code to manually update the location-inventories
    // this.inventoryService.getInventoriesLocation();
    const promises = [];
    const self = this;
    let suppliers = [];

    let inventoriesCin7 = [];
    let dateId = '';

    const promiseInv = this.inventoryService.getInventories(0);
    promises.push(promiseInv);
    // promiseInv.subscribe(inv => {
    //     self.inventories = inv;
    // });

    const datePromise = this.inventoryService.getExpectedInventoriesFromCin7();
    promises.push(datePromise);
    // datePromise.subscribe(data => {
    //     dateId = data.dateId;
    //     inventoriesCin7 = data.inventories;
    // });

    const promiseSup = this.supplierService.getSuppliers();
    promises.push(promiseSup);
    // promiseSup.subscribe(sups => {
    //     suppliers = sups.data;
    // });

    forkJoin(promises).subscribe((responses) => {
      self.inventories = responses[0];
      dateId = responses[1]['dateId'];
      inventoriesCin7 = responses[1]['inventories'];
      // console.log("Inventoriescin&: ", inventoriesCin7);
      suppliers = responses[2]['data'];
      // console.log("Suppliers: ", suppliers);
      console.log('Initial Forking done, parameters set...');
      const medableObjStructure = this.updateObjStructure(
        inventoriesCin7,
        suppliers
      );
      // console.log("???: ", dateId, " cin7Inv: ", inventoriesCin7);
      if (dateId && inventoriesCin7.length) {
        console.log('has dateId: ', dateId, ' cin7Inv: ', inventoriesCin7);
        const lastInventory = inventoriesCin7[inventoriesCin7.length - 1];
        this.checkForUpdates(
          medableObjStructure,
          dateId,
          lastInventory.modifiedDate,
          lastInventory.id
        );
      } else {
        console.log('No dateId: ');
        this.checkForUpdates(medableObjStructure, dateId);
      }
    });
  }

  checkForUpdates(
    medableObjStructure,
    dateId,
    syncDate = new Date(),
    syncId = 0
  ) {
    console.log('MedableObjStructure: ', medableObjStructure);
    const promises = [];
    medableObjStructure
      .filter((mob) => mob.c_hcpcs_code)
      .map((cin7) => {
        const medInv = this.inventories.find(
          (obj) => obj.c_cin7_id == cin7.c_cin7_id
        );
        //   console.log("medInv: ", medInv);
        //return;
        if (medInv) {
          if (!medInv.c_supplier_id && cin7.c_supplier_id) {
            console.log('Editing Inventory...NEEDED', cin7);
            // console.log("MedInv: ", medInv);
            // console.log("Cin7: ", cin7);
            const promise = this.inventoryService.editInventory(
              medInv._id,
              cin7
            );
            promises.push(promise);
          }
        } else {
          console.log('Creting Inventory...', cin7);
          const promise = this.inventoryService.createInventory(cin7);
          promises.push(promise);
        }
      });

    // return;
    if (medableObjStructure.length) {
      if (dateId) {
        const datePromise = this.inventoryService.updateSynchronizationDate(
          dateId,
          syncDate,
          syncId
        );
        promises.push(datePromise);
      } else {
        const datePromise = this.inventoryService.createSynchronizationDate(
          syncDate,
          syncId
        );
        promises.push(datePromise);
      }
    }

    forkJoin(promises).subscribe(() => {
      // window.location.reload();
      console.log('sync done!');
      this.gotoInventoriesIndex();
    });
  }

  gotoInventoriesIndex() {
    this.router.navigate(['forms/inventories/index']);
  }

  goToViewInventory(inventoryId) {
    this.router.navigate(['forms/inventories/:id/view', { id: inventoryId }]);
  }

  /**
   * Function to mark inactive items in inventory.
   */
  markInactive = () => {
    this.progressBar = {
      total: 0,
      done: 0,
      percent: 0,
      display: true,
    };
    // Load all Inactive products from cin7
    this.inventoryService.cin7GetInactiveItems().subscribe((inactive) => {
      let items = inactive.data;
      this.progressBar.display = true;
      this.progressBar.total = items.length;

      console.log('Items:', items);

      // For each inactive item from cin7
      items.forEach((item) => {
        // Get medable products based on cin7 id
        this.getItemsByCin7Id(item.id).subscribe((mdb) => {
          console.log('MEDB Items: ', mdb);
          if (mdb.data && mdb.data.length) {
            // Run update req for all the products with given cin7_id in parallel.
            this.markInactiveBatch(mdb.data).subscribe((upd) => {
              // Show progress in UI.
              this.updateMarkProgress();
            });
          } else {
            // If there are no products with cin7_id
            // Show progress in UI
            this.updateMarkProgress();
          }
        });
      });
    });
  };

  /**
   * Function to get medable items based on cin7 id
   */
  getItemsByCin7Id = (cin7Id) => {
    let resource = 'inventories',
      query = `?where={"c_cin7_id": ${cin7Id}}`;
    return this.formsService.get(resource, query);
  };

  /**
   * Mark a batch of inactive items
   */
  markInactiveBatch = (items) => {
    let reqs = items.map((i) =>
      this.inventoryService.editInventory(i._id, { c_status: 'inactive' })
    );

    return forkJoin(reqs);
  };

  /**
   * Update progress bar
   */
  updateMarkProgress = () => {
    this.progressBar.done = this.progressBar.done + 1;
    this.progressBar.percent = Math.round(
      (this.progressBar.done * 100) / this.progressBar.total
    );
    // Hide progress bar once all items marked
    if (this.progressBar.done === this.progressBar.total) {
      this.progressBar.display = false;
    }
  };

  /**
   * Function to mark products in Medable that are no longer present in cin7.
   */
  markDeleted = () => {
    // Load all products from cin7
    this.getAllCin7Products().then((cin7PrRes) => {
      console.log('All cin7 items res: ', cin7PrRes);
      // Load all products from Medable
      this.inventoryService.getAllMedableProducts().subscribe((medPrRes) => {
        console.log('All medable products: ', medPrRes);
        // Only check not marked products.
        let notMarked = medPrRes.filter((mp) => !mp.c_deleted);
        // Check products from Medable that are not present in the cin7 list
        let medableDeleted = notMarked.filter((medP) => {
          // Check if present in cin7 list.
          let byCin7Id = cin7PrRes['items'].filter(
            (cin7P) => Number(cin7P.id) === Number(medP.c_cin7_id)
          );

          return byCin7Id.length === 0;
        });

        console.log('Medable Deleted Items: ', medableDeleted.length);
        let testBatch = medableDeleted.slice(0, 800);

        testBatch.forEach((medItm) => {
          this.inventoryService
            .editInventory(medItm._id, { c_deleted: true })
            .subscribe((mrkRes) => {
              console.log('Item marked... ');
            });
        });
      });
    });
  };

  /**
   * Function to get all products from cin7.
   */
  getAllCin7Products = () => {
    console.log('Getting all cin7 products...');
    // Page counter
    let page = 1;
    // Will hold all the products.
    let allProducts = [];
    let self = this;

    return new Promise((resolve) => {
      function loadPage(page) {
        self.inventoryService.getCin7ProdPage(page).subscribe((pgRes) => {
          let items = pgRes.data;
          if (items.length) {
            console.log('Has More...');
            allProducts = allProducts.concat(items);
            page++;
            loadPage(page);
          } else {
            resolve({ items: allProducts });
          }
        });
      }

      loadPage(page);
    });
  };

  /**
   * Function to get all prod from Medable & return a promise
   */
  getAllProdMedablePromise = () => {
    return new Promise((resolve) => {
      this.inventoryService.getAllMedableProducts().subscribe((prod) => {
        resolve(prod);
      });
    });
  };

  /**
   * Function to update status of products
   */
  updateInventoryStatus = async (delta = false) => {
    console.log('Updaing inventory status...');

    console.log('Loading test prod... ');
    this.inventoryService.getCin7Inventory('2535').subscribe((tp) => {
      console.log('TP: ', tp);
    });

    // Load all products from cin7
    let cin7ProdRes = await this.getAllCin7Products(),
      cin7Products = cin7ProdRes['items'];

    if (!cin7Products) {
      console.log('Error loading cin7 products...');
      return;
    }

    // Load all products from Medable
    let medProducts = await this.getAllProdMedablePromise();

    // Generate list of products to update.
    let toUpdate = this.getProdctsToUpdate(cin7Products, medProducts);

    // Generate list of requests to be executed in parallel for all items that need to be updated.
    let promises = toUpdate.map((u) =>
      this.inventoryService.editInventory(u._id, u.data)
    );

    forkJoin(promises).subscribe((res) => {
      console.log('Updating status DOne!!!');
    });
  };

  /**
   * Function to generate list of produtcs that need their status updated.
   */
  getProdctsToUpdate = (cin7Products, medProducts) => {
    let toUpdate = [];

    medProducts.forEach((mp) => {
      // Get medable product's status
      // check if there is a cin7 item with the product's id and different status.
      let cin7 = cin7Products.filter(
        (c) => Number(c.id) === Number(mp.c_cin7_id)
      )[0];

      if (!cin7) {
        if (mp.c_cin7_id === '2535') {
          console.log('Test prod... has no cin7 ref');
        }

        // Product no longer exists in cin7.
        if (!mp.c_deleted) {
          if (mp.c_cin7_id === '2535') {
            console.log('Test prod... not marked yet');
          }
          // If medable product doesn't have the deleted status, it needs to be updated.
          toUpdate.push({
            _id: mp._id,
            data: { c_deleted: true },
            type: 'deleted',
          });
        } else {
          if (mp.c_cin7_id === '2535') {
            console.log('Test prod.. already marked.');
          }
        }
      } else {
        if (mp.c_cin7_id === '2535') {
          console.log('Test prod... has cin7 ref: ', cin7);
        }
        let mdInactive = (mp.c_status || '') === 'inactive',
          mdActive = (mp.c_status || '') !== 'inactive';

        if (cin7.status === 'Inactive' && mdActive) {
          // If cin7 is marked inactive and medable product not
          toUpdate.push({
            _id: mp._id,
            data: { c_status: 'inactive' },
            type: 'inactive',
          });
        } else if (mdInactive && cin7.status !== 'Inactive') {
          // If medable product is marked as inactive and cin7 not.
          toUpdate.push({
            _id: mp._id,
            data: { c_status: 'public' },
            type: 'active',
          });
        }
      }
    });

    return toUpdate;
  };

  addMissingHcpcs = () => {
    let missing = 'L0472'.toLowerCase();

    // this.formService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
    //   console.log("AllHcpcsCodesL ", allCodes);
    //   let missingMathc = allCodes.filter(
    //     (c) => c.c_hcpcs_code.toLowerCase() === missing
    //   );

    //   if (!missingMathc.length) {
    //     console.log("Code should be added.");
    //     // this.formService
    //     //   .createHcpcsCode({ c_hcpcs_code: missing })
    //     //   .subscribe((added) => {
    //     //     console.log("Code added res: ", added);
    //     //   });
    //   } else {
    //     console.log("code already there.");
    //     let exId = missingMathc[0]._id;
    //     console.log("Existing id: ", exId);
    //     this.formService
    //       .updateHcpcsCode(exId, { c_hcpcs_code: missing.toUpperCase() })
    //       .subscribe((cUpd) => {
    //         console.log("Updated code: ", cUpd);
    //       });
    //   }
    // });
  };

  /**
   * Function to sync the HCPCS codes collection based on existing products.
   */
  syncHcpcsCodes = () => {
    // List of manually observed product HCPCS codes, that we'll try to avoid
    // adding to the existing HCPCS code list.

    // Load all existing codes from Medable
    this.hcpcsService.getAllHcpcsCodesMedable().subscribe((allHcpcs) => {
      let justCodes = allHcpcs.map((hcpcs) => hcpcs.c_hcpcs_code);

      let validPattern = /^(([A-Z][0-9]{4}))|(([A-Z][0-9]{4})\/([A-Z][0-9]{4}))$/;

      // Load all existing products from Medable.
      this.inventoryService.getAllMedableProducts().subscribe((allProd) => {
        // Hold distinct product HCPCS Codes.
        let distinctProdCodes = [];

        // Will holds prod with multiple codes.
        let multipleCodesProd = [];
        allProd.forEach((p) => {
          let prodCode = p.c_hcpcs_code,
            added = distinctProdCodes.indexOf(prodCode) > -1,
            exclude = !validPattern.test(prodCode);

          if (prodCode.indexOf('/') > -1) {
            multipleCodesProd.push(p);
          }
          if (!added && !exclude) {
            distinctProdCodes.push(prodCode);
          }
        });

        console.log('Prod with multiple codes: ', multipleCodesProd);

        // Get all product HCPCS codes that are not in the HCPCS code list.
        let missingCodes = distinctProdCodes.filter((dc) => {
          return justCodes.indexOf(dc) === -1;
        });

        if (!missingCodes.length) {
          // Stop if there are no missing codes
          console.log('No missing codes...');
          return;
        }

        // Map each code to an insert statement
        let insertPromises = missingCodes.map((c) =>
          this.hcpcsService.createHcpcsCpde({ c_hcpcs_code: c })
        );

        // Run all insert statements in paralell.
        forkJoin(insertPromises).subscribe((res) => {
          console.log('Codes inserted... ');
        });
      });
    });
  };
}
