import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class FittersService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createHcpcs(hcpcs: any): Observable<any> {
    return this.http.post<any>(
      `${this.API.inventoryInsuranceLoc}`,
      hcpcs,
      this.httpOptions
    );
  }

  editHcpcs(hcpcsId: string, hcpcs: any): Observable<any> {
    delete hcpcs.acl;
    return this.http.put<any>(
      `${this.API.inventoryInsuranceLoc}/${hcpcsId}`,
      hcpcs,
      this.httpOptions
    );
  }

  deleteHcpcs(hcpcsId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.inventoryInsuranceLoc}/${hcpcsId}`,
      this.httpOptions
    );
  }
}
