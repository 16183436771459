import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupComponent } from '../popup/popup.component';

import { ActiveStateService } from '../../services/active-state/active-state.service';
import { AuthService } from '../../services/auth/auth.service';
import { FormsService } from '../../services/forms/forms.service';
import { InsuranceService } from '../../services/insurance/insurance.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
})
export class InsuranceComponent implements OnInit {
  insurance: any = {};
  insuranceId: string;

  hasRole: any;

  showCreateEditForm = false;
  isEditMode = false;
  notEditable = false;
  somethingToShow = false;

  private sub;

  constructor(
    private authService: AuthService,
    private state: ActiveStateService,
    private insuranceService: InsuranceService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private formsService: FormsService
  ) {
    this.deleteInsurance = this.deleteInsurance.bind(this);
    this.goToViewInsurance = this.goToViewInsurance.bind(this);
    this.goToBack = this.goToBack.bind(this);
  }

  ngOnInit() {
    // let id = this.route.snapshot.paramMap.get('id');
    this.sub = this.route.params.subscribe((params) => {
      this.insuranceId = params.id ? params.id : '0';

      this.hasRole = this.authService.getAuthorizedUserRole();

      // If index, we show the create button.
      this.somethingToShow = this.state.is('forms.insurancesIndex');

      if (this.somethingToShow) {
        return;
      }

      //  if edit or view, load the insurances data
      if (this.state.is('forms.insurancesCreate')) {
        this.showCreateEditForm = true;
      } else {
        this.getInsurance();
      }

      // in case of view of the insurances, disable editing
      if (this.state.is('forms.insurancesView')) {
        this.notEditable = true;
      }

      if (this.state.is('forms.insurancesEdit')) {
        this.showCreateEditForm = true;
        this.isEditMode = true;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSubmit() {
    if (this.state.is('forms.insurancesCreate')) {
      this.createInsurance();
    } else if (this.state.is('forms.insurancesEdit')) {
      this.editInsurance();
    }
  }

  createInsurance() {
    this.insuranceService.createInsurance(this.insurance).subscribe((data) => {
      this.insuranceId = data._id;
      setTimeout(this.goToViewInsurance, 1000);
    });
  }

  editInsurance() {
    console.log('Editing Insurance: ', this.insurance);
    this.insuranceService
      .editInsurance(this.insuranceId, this.insurance)
      .subscribe(() => {
        setTimeout(this.goToViewInsurance, 1000);
      });
  }

  getInsurance() {
    this.insuranceService
      .getInsurance(this.insuranceId)
      .subscribe((insurance) => {
        this.insurance = {
          c_insurance_name: insurance.c_insurance_name,
          c_phone_number: insurance.c_phone_number
            ? insurance.c_phone_number
            : '',
          c_fax_number: insurance.c_fax_number ? insurance.c_fax_number : '',
        };
      });
  }

  deleteInsurance() {
    this.insuranceService.deleteInsurance(this.insuranceId).subscribe(() => {
      this.formsService
        .get(
          'inventoryInsuranceLoc',
          '?where={"c_insurance":"' + this.insuranceId + '"}'
        )
        .subscribe((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.formsService.delete(
              'inventoryInsuranceLoc',
              response.data[i]._id
            );
          }
        });
      this.router.navigate(['forms/insurances/index']);
    });
  }

  showPopupDelete() {
    const info = {
      subtitle: 'forms.confirm.deleteStrong',
      btnLeft: 'NO',
      btnLeftClass: 'no delete-button-pos',
      btnRight: 'YES',
      btnRightClass: 'yes',
      inputConfirm: true,
    };
    const modalInstance = this.modalService.open(PopupComponent);
    this.sharedService.setPopupInfo(info);

    modalInstance.result.then(this.deleteInsurance);
  }

  goToCreateInsurance() {
    this.router.navigate(['forms/insurances/new']);
  }

  goToEditInsurance() {
    this.router.navigate([
      'forms/insurances/:id/edit',
      { id: this.insuranceId },
    ]);
    // this.router.navigateByUrl(`forms/insurances/${this.insuranceId}/edit`);
  }

  goToViewInsurance() {
    this.router.navigate([
      'forms/insurances/:id/view',
      { id: this.insuranceId },
    ]);
  }

  cancel() {
    if (
      this.insurance.c_fax_number ||
      this.insurance.c_insurance_name ||
      this.insurance.c_phone_number
    ) {
      const info = {
        title: 'forms.confirm.title',
        subtitle: 'forms.confirm.subtitle',
        btnLeft: 'NO',
        btnLeftClass: 'no',
        btnRight: 'YES',
        btnRightClass: 'yes',
      };
      const modalInstance = this.modalService.open(PopupComponent);
      this.sharedService.setPopupInfo(info);
      modalInstance.result.then(this.goToBack);
    } else {
      this.goToBack();
    }
  }

  goToBack() {
    if (this.insuranceId === '0') {
      this.router.navigate(['forms/insurances/index']);
    } else {
      this.router.navigate([
        'forms/insurances/:id/view',
        { id: this.insuranceId },
      ]);
    }
  }
}
