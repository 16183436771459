import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';

import { LanguagePipe } from '../../pipes/language/language.pipe';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LanguagePipe],
})
export class LoginComponent implements OnInit {
  user: any = {
    email: '',
    password: '',
  };

  message: string = null;
  errorMessage: string = null;
  hasCustomMessage = false;
  tokenRequired = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private langPipe: LanguagePipe
  ) {}

  ngOnInit() {
    // reset login status
    // this.authService.logout();
    // this.loadScript();
  }

  loadScript() {
    console.log('preparing to load...');
    const node = document.createElement('script');
    node.id = 'ze-snippet';
    node.src =
      'https://static.zdassets.com/ekr/snippet.js?key=5ea9741a-8969-4d67-afd1-fb47fea5e6be'; // insert url in between quotes
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    const authContainer = document.getElementsByClassName('auth-container')[0];
    const leftOffset = window.innerWidth / 2 - 80;
    const topOffset =
      authContainer.getBoundingClientRect().top * 2 +
      authContainer.clientHeight;
    const node2 = document.createElement('script');
    node2.id = 'ze-snippet2';
    // insert url in between quotes
    node2.type = 'text/javascript';
    node2.async = true;
    node2.charset = 'utf-8';
    node2.text =
      "window.zESettings = {webWidget: {launcher: {label: {'*': 'Help',}},position: { horizontal: 'left', vertical: 'top' },offset: { horizontal: '" +
      leftOffset +
      "px', vertical: '" +
      topOffset +
      "px' },color: { theme: '#2277ff', launcherText: '#fff', }}};";
    document.getElementsByTagName('head')[0].appendChild(node2);

    console.log('window width: ', window.innerWidth);

    console.log(
      'AuthContainer top: ',
      authContainer.getBoundingClientRect().top
    );
    console.log('AuthContainer Height: ', authContainer.scrollHeight);
  }

  login() {
    this.errorMessage = null;
    if (this.user.location) {
      this.user.location.singleUse = false;
    }

    this.authService.login(this.user).subscribe(
      (user) => {
        switch (user.code) {
          case 'kNewLocation':
            this.tokenRequired = true;
            this.errorMessage = user.message;
            break;
          case 'kUnverifiedLocation':
            this.tokenRequired = true;
            this.errorMessage = user.message;
            break;
          case 'kInvalidCredentials':
            this.errorMessage = user.message;
            break;
          case 'kAccountLocked':
            this.errorMessage = user.message;
            break;
          default:
            this.authService.setAuthorizedUser(user);
            this.router.navigate(['/dashboard']);
        }
      },
      (error) => {
        this.errorMessage =
          error.message || this.langPipe.transform('login.form.error.generic');
      }
    );
  }
}
