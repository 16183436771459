import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { CONSTANTS } from '../../../config/constants';
import { ENDPOINTS } from '../../../config/endpoints';

@Injectable()
export class SupplierService {
  API: any = ENDPOINTS.forms;
  medableClientKey: string = CONSTANTS.MEDABLE_CLIENT_KEY;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Medable-Client-Key': this.medableClientKey,
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  getSuppliers(): Observable<any> {
    const localSubject = new Subject();
    const chunck = 1000;
    const self = this;
    let step = 0;
    let allSupliers = [];

    function loadAll() {
      self.http
        .get<any>(
          `${self.API.supplier}?limit=${chunck}&skip=${step * chunck}`,
          self.httpOptions
        )
        .subscribe((suppls) => {
          console.log('supls: ', suppls);
          allSupliers = allSupliers.concat(suppls.data);
          if (suppls.hasMore) {
            console.log('hasMore suppliers...');
            step++;
            loadAll();
          } else {
            // console.log("all suppliers")
            localSubject.next({ data: allSupliers });
            localSubject.complete();
          }
        });
    }

    loadAll();
    return localSubject;
  }

  getSupplier(supplierId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.supplier}?limit=1&where={"c_cin7_id":` + supplierId + `}`,
      this.httpOptions
    );
  }

  getSupplierTest(supplierId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API.supplier}?where={"c_cin7_id":` + supplierId + `}`,
      this.httpOptions
    );
  }

  deleteSupplier(supplierId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.API.supplier}/${supplierId}`,
      this.httpOptions
    );
  }
}
