<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchInventory"
      class="search-input"
      placeholder="search by name or sku"
      (keyup)="$event.keyCode == 13 && getInventories(searchInventory)"
    />
    <button
      type="button"
      class="btn-link"
      (click)="getInventories(searchInventory)"
    >
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>
  <div>
    <ul>
      <li *ngFor="let inventory of inventories">
        <label (click)="goToViewInventory(inventory._id)">
          {{ inventory.c_name }}
        </label>
      </li>
    </ul>
  </div>
</div>
