<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="searchForm"
      class="search-input"
      placeholder="search by name"
      (keyup)="$event.keyCode == 13 && getForms(searchForm)"
    />
    <button type="button" class="btn-link" (click)="getForms(searchForm)">
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div>

  <div class="container-button">
    <a
      class="button create"
      *ngIf="hasRole.admin && !stateCreate"
      (click)="gotoCreateForm()"
    >
      {{ 'forms.form-form-new.title' | lang }}
    </a>
  </div>
  <ul>
    <li *ngFor="let form of forms">
      <label (click)="gotoViewForm(form._id)">{{ form.c_name }}</label>
    </li>
  </ul>
</div>
