<!-- Patient List -->
<app-patient-list class="ListContainer"></app-patient-list>

<div
  *ngIf="
    somethingToShow &&
    (hasRole.admin ||
      hasRole.executive ||
      hasRole.fitter ||
      hasRole.site_manager)
  "
  class="forms-form"
>
  <button
    type="button"
    class="button create-button"
    (click)="goToCreatePatient()"
  >
    {{ 'patients.patient-form-new.title' | lang }}
  </button>
</div>

<!-- Create Patient Form -->
<form
  *ngIf="showForm"
  name="form"
  (ngSubmit)="f.form.valid && onSubmit()"
  #f="ngForm"
  class="forms-form step"
  novalidate
>
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ 'patients.title' | lang }}</h1>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xs-12 col-md-6"
        ng-class="{ 'required-input': form.$submitted && !form.first.$valid }"
      >
        <label>
          {{ 'patients.name.first_name' | lang }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>
        <input
          name="first"
          [(ngModel)]="patient.c_name.c_first_name"
          type="text"
          required
          [disabled]="notEditable"
        />
      </div>
      <div
        class="col-xs-12 col-md-6"
        ng-class="{ 'required-input': form.$submitted && !form.last.$valid }"
      >
        <label>
          {{ 'patients.name.last_name' | lang }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>
        <input
          name="last"
          [(ngModel)]="patient.c_name.c_last_name"
          type="text"
          required
          [disabled]="notEditable"
        />
      </div>
    </div>

    <div class="row">
      <div
        class="col-xs-12 col-md-6"
        ng-class="{ 'required-input': form.$submitted && !form.dob.$valid }"
      >
        <label>
          {{ 'patients.DOB' | lang }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select a date"
            angular-mydatepicker
            name="mydate"
            [(ngModel)]="dobModel"
            [options]="dpOptions"
            #dp="angular-mydatepicker"
            required
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dp.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dp.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <label>{{ 'patients.phone_number' | lang }}</label>
        <input
          [(ngModel)]="patient.c_phone_number"
          type="text"
          [disabled]="notEditable"
          name="phone_number"
        />
      </div>
    </div>

    <div class="row">
      <div
        class="col-xs-12 col-md-6"
        ng-class="{ 'required-input': form.$submitted && !form.mrn.$valid }"
      >
        <label>
          {{ 'patients.MRN' | lang }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>
        <input
          name="mrn"
          [(ngModel)]="patient.c_mrn"
          type="text"
          required
          [disabled]="notEditable"
        />
      </div>
      <div class="col-xs-12 col-md-6">
        <label>{{ 'address.street_address_1' | lang }}</label>
        <input
          [(ngModel)]="patient.c_address.c_street_address_1"
          type="text"
          [disabled]="notEditable"
          name="street_address1"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <label>{{ 'address.street_address_2' | lang }}</label>
        <input
          [(ngModel)]="patient.c_address.c_street_address_2"
          type="text"
          [disabled]="notEditable"
          name="street_address2"
        />
      </div>
      <div class="col-xs-12 col-md-6">
        <label>{{ 'address.city' | lang }}</label>
        <input
          [(ngModel)]="patient.c_address.c_city"
          type="text"
          [disabled]="notEditable"
          name="city"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <label>{{ 'address.state' | lang }}</label>
        <input
          [(ngModel)]="patient.c_address.c_state"
          type="text"
          [disabled]="notEditable"
          name="state"
        />
      </div>
      <div class="col-xs-12 col-md-6">
        <label>{{ 'address.postal_code' | lang }}</label>
        <input
          [(ngModel)]="patient.c_address.c_postal_code"
          type="text"
          [disabled]="notEditable"
          name="postal_code"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <span *ngIf="duplicateError" class="span-duplicated-error">
          {{ 'forms.error.repeated_patient' | lang }}
        </span>
        <button
          class="button next-button"
          type="submit"
          [disabled]="!f.form.valid"
        >
          {{
            isEditMode ? ('patients.save' | lang) : ('patients.submit' | lang)
          }}
        </button>
        <button class="button back-button" type="button" (click)="cancel()">
          {{ 'forms.cancel' | lang }}
        </button>
      </div>
    </div>
  </div>
</form>

<!-- View Patient -->
<div *ngIf="viewPatient" class="forms-form">
  <div class="row">
    <div class="col-xs-12">
      <h1>
        {{ patient.c_name.c_first_name }} {{ patient.c_name.c_last_name }}
      </h1>
      <div class="pull-right">
        <button
          class="button"
          [disabled]="!hasOrders"
          (click)="gotoViewPatientHistory(patientId)"
        >
          {{ 'patients.view.orders' | lang }}
        </button>
        <button
          *ngIf="hasRole.admin"
          class="button delete-button"
          (click)="deletePatient()"
        >
          {{ 'patients.delete' | lang }}
        </button>
        <button class="button" (click)="gotoEditPatient(patientId)">
          {{ 'patients.edit' | lang }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="first-info-container">
      <ul>
        <li>
          <label>{{ 'patients.patient_info' | lang }}</label>
        </li>
        <li>
          <label>{{ 'patients.DOB' | lang }}</label>
          <p>{{ patient.c_dob | date: 'MM/dd/yyyy' }}</p>
        </li>
        <li>
          <label>{{ 'patients.phone_number' | lang }}</label>
          <p>{{ patient.c_phone_number ? patient.c_phone_number : '-' }}</p>
        </li>
        <li>
          <label>{{ 'patients.MRN' | lang }}</label>
          <p>{{ patient.c_mrn ? patient.c_mrn : '-' }}</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="row second-info-container">
    <div class="col-xs-12 col-md-6">
      <label class="name">{{ 'address.street_address_1' | lang }}</label>
      <label class="info">
        {{
          patient.c_address.c_street_address_1
            ? patient.c_address.c_street_address_1
            : '-'
        }}
      </label>
    </div>
    <div class="col-xs-12 col-md-6">
      <label class="name">{{ 'address.street_address_2' | lang }}</label>
      <label class="info">
        {{
          patient.c_address.c_street_address_2
            ? patient.c_address.c_street_address_2
            : '-'
        }}
      </label>
    </div>
    <div class="col-xs-12 col-md-6">
      <label class="name">{{ 'address.city' | lang }}</label>
      <label class="info">
        {{ patient.c_address.c_city ? patient.c_address.c_city : '-' }}
      </label>
    </div>
    <div class="col-xs-12 col-md-6">
      <label class="name">{{ 'address.state' | lang }}</label>
      <label class="info">
        {{ patient.c_address.c_state ? patient.c_address.c_state : '-' }}
      </label>
    </div>
    <div class="col-xs-12 col-md-6">
      <label class="name">{{ 'address.postal_code' | lang }}</label>
      <label class="info">
        {{
          patient.c_address.c_postal_code
            ? patient.c_address.c_postal_code
            : '-'
        }}
      </label>
    </div>
  </div>
</div>

<div class="forms-form" *ngIf="patientHistory">
  <div class="row">
    <div class="col-xs-12">
      <h1>Orders History</h1>
      <div class="pull-right">
        <button class="button" (click)="goToViewPatient(patientId)">
          {{ 'patients.view.patient' | lang }}
        </button>
        <button class="button" (click)="gotoEditPatient(patientId)">
          {{ 'patients.edit' | lang }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="col-xs-12 ll-whole-order-container mb50"
    *ngFor="let order of patientOrders; let orderIndex = index"
  >
    <!-- First Row Info -->
    <div class="row">
      <div class="first-info-container" *ngIf="orderIndex === 0">
        <ul>
          <li>
            <label>{{ 'orders.patient' | lang }}</label>
          </li>
          <li>
            <label>
              {{ 'orders.order-form.label.patient_first_name' | lang }}
            </label>
            <p>{{ order.c_patient.c_name.c_first_name }}</p>
          </li>
          <li>
            <label>
              {{ 'orders.order-form.label.patient_last_name' | lang }}
            </label>
            <p>{{ order.c_patient.c_name.c_last_name }}</p>
          </li>
          <li>
            <label>{{ 'orders.order-form.label.patient_dob' | lang }}</label>
            <p>{{ order.c_patient.c_dob | date: 'MM/dd/yyyy' }}</p>
          </li>
          <li>
            <label>{{ 'orders.order-form.label.patient_mrn' | lang }}</label>
            <p>{{ order.c_patient.c_mrn }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <h2>Order {{ order.c_number_id }}, {{ order.display_date }}</h2>
      </div>
    </div>
    <!-- Secondary Info -->
    <div class="row second-info-container">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.physician' | lang }}:
        </label>
        <label class="info">
          {{
            order.c_provider_location.c_physician.c_preffix
              ? order.c_provider_location.c_physician.c_preffix + '. '
              : ''
          }}{{ order.c_provider_location.c_physician.c_name.c_first_name }}
          {{ order.c_provider_location.c_physician.c_name.c_last_name
          }}{{
            order.c_provider_location.c_physician.c_suffix
              ? ',' + order.c_provider_location.c_physician.c_suffix
              : ''
          }}
        </label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.location' | lang }}:
        </label>
        <label class="info">
          {{ order.c_provider_location.c_location.c_company }}
        </label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.patient_insurance' | lang }}:
        </label>
        <label class="info">{{ order.c_insurance.c_insurance_name }}</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.notes' | lang }}:
        </label>
        <label class="info">{{ order.c_notes }}</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.billed_date' | lang }}:
        </label>
        <label class="info" *ngIf="order.c_billed_date">
          {{ order.c_billed_date | date: 'MM/dd/yyyy' }}
        </label>
        <label class="info" *ngIf="!order.c_billed_date">-</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.claim_id' | lang }}:
        </label>
        <label class="info" *ngIf="order.c_claim_id">
          {{ order.c_claim_id }}
        </label>
        <label class="info" *ngIf="!order.c_claim_id">-</label>
      </div>
    </div>

    <!-- Line Items -->
    <h3>
      <div class="list-icon"></div>
      {{ 'orders.order-form.label.line_items' | lang }}
    </h3>

    <div class="row">
      <div class="col-xs-12">
        <ul class="list-selected">
          <li *ngFor="let line of order.orderLineItems; let lineIndex = index">
            <div class="list-selected-header">
              <div class="row">
                <div class="col-xs-2 vendor-wrapper">
                  <label class="vendor-label">
                    {{ line.c_product.c_name }}
                  </label>
                  <p class="vendor-code">
                    {{ line.c_product.c_product_options.c_size }}
                  </p>
                </div>
                <div class="col-xs-2 quantity-wrapper">
                  <label>Quantity</label>
                  <p>{{ line.c_quantity }}</p>
                </div>
                <div class="col-xs-8">
                  <div class="list-wrapper">
                    <label>Charge out</label>
                    <p>{{ line.c_charge_out_cost | currency }}</p>
                  </div>
                  <div class="list-wrapper">
                    <label>Self pay</label>
                    <p>{{ line.c_self_pay_cost | currency }}</p>
                  </div>
                  <div class="list-wrapper">
                    <label>Allowable</label>
                    <p>{{ line.c_allowable_cost | currency }}</p>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>

            <!-- Modifiers -->
            <div class="list-selected-subitem">
              <div class="list-selected-subitem-name">
                <label>Modifiers</label>
              </div>
              <ul class="list-selected-subitem-list">
                <li *ngFor="let modifier of line.c_modifier">
                  <label [ngClass]="{ 'label-disabled': !modifier.c_checked }">
                    {{ modifier.c_modifier }}
                  </label>
                  <input
                    type="checkbox"
                    class="radio-button"
                    [(ngModel)]="modifier.c_checked"
                    *ngIf="line.edit"
                  />
                </li>
              </ul>
            </div>
            <div style="clear: both"></div>

            <!-- Icd10s -->
            <div class="list-selected-subitem">
              <div class="list-selected-subitem-name">
                <label>ICD 10 codes</label>
              </div>
              <ul class="list-selected-subitem-list">
                <li *ngFor="let icd of line.c_icd_10; let icdIndex = index">
                  <label>
                    {{ icd.c_icd_10_code }} - {{ icd.c_description }}
                  </label>
                </li>
              </ul>
            </div>

            <div class="clearfix"></div>
          </li>
        </ul>
      </div>
    </div>

    <h3 *ngIf="order.orderForms.length > 0">
      <div class="list-icon"></div>
      {{ 'orders.order-form.title.step5' | lang }}
    </h3>

    <div class="row">
      <div class="col-xs-12">
        <div
          *ngFor="let form of order.orderForms; let formIndex = index"
          class="list-forms"
        >
          <div *ngIf="form.c_modifier_form.c_form">
            <div class="col-xs-10 col-sm-11">
              <button
                type="button"
                class="button back-button download-icon"
                (click)="populateModifierForm(orderIndex, formIndex)"
              >
                {{ form.c_product.c_hcpcs_code }}
                {{ form.c_modifier_form.c_form.c_name }}
                <div></div>
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
