<div class="list-container">
  <!-- <div class="search-container">
    <input [(ngModel)]="searchLocationGroup" class="search-input" placeholder="search by name" (keyup)="$event.keyCode == 13 && getLocationGroups(searchLocationGroup)">
    <button type="button" class="btn-link" (click)="getLocationGroups(searchLocationGroup)">
      <span class="glyphicon glyphicon-search"></span>
    </button>
  </div> -->

  <div class="container-button">
    <a
      *ngIf="hasRole.admin || hasRole.executive || hasRole.site_manager"
      class="button create"
      (click)="gotoCreateLocationGroups()"
    >
      Create LocationGroup
    </a>
  </div>
  <ul>
    <li *ngFor="let locationGroup of locationGroupList">
      <label (click)="gotoViewLocationGroup(locationGroup._id)">
        {{ locationGroup.c_group_name }}
      </label>
    </li>
  </ul>
</div>
