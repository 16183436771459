import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';

import { LanguagePipe } from '../../pipes/language/language.pipe';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [LanguagePipe],
})
export class ResetPasswordComponent implements OnInit {
  email = '';
  token: string;
  errorMessage: string;
  password: string;
  confirmPassword: string;
  passwordResetted: boolean;

  constructor(
    private authService: AuthService,
    private langPipe: LanguagePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params.param1;
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (!this.token) {
      this.errorMessage = this.langPipe.transform(
        'reset-password.message.invalid_password_reset_token'
      );
      return false;
    }

    if (this.password === this.confirmPassword) {
      this.errorMessage = null;
      this.authService.resetPassword(this.token, this.password).subscribe(
        () => {
          this.router.navigate(['/login']);
          // this.$state.go('auth.login', {
          //   message: this.langPipe.transform('reset-password.message.success')
          // });
        },
        (error) => {
          this.errorMessage = error.reason;
        }
      );
    } else {
      this.errorMessage = this.langPipe.transform(
        'reset-password.message.passwords_must_match'
      );
    }
  }
}
