<app-inventory-list class="ListContainer"></app-inventory-list>

<div class="forms-form" *ngIf="viewInventory">
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ 'inventories.title' | lang }}</h1>
      <div class="pull-right">
        <button class="button btn white" (click)="updateInventory()">
          {{ 'inventories.update' | lang }}
        </button>
        <!-- <button *ngIf="hasRole.admin || hasRole.site_manager" class="button btn white" (click)="getAllHcpcsCodes()">GET HCPCS CODES</button> -->
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="row">
    <div class="first-info-container">
      <ul>
        <li>
          <label>{{ 'inventories.item.info' | lang }}</label>
        </li>
        <li>
          <label>{{ 'inventories.item.name' | lang }}</label>
          <p>{{ inventory.c_name }}</p>
        </li>
        <li>
          <label>{{ 'inventories.HCPCS_code' | lang }}</label>
          <p>{{ inventory.c_hcpcs_code }}</p>
        </li>
        <li>
          <label>{{ 'inventories.item.vendor' | lang }}</label>
          <p>{{ inventory.c_supplier_id.c_company }}</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="row second-info-container">
    <div class="col-xs-12">
      <div>
        <label class="product-options"><div></div></label>
        <h3>{{ 'inventories.product_options' | lang }}</h3>
      </div>
    </div>

    <div class="col-xs-12 col-lg-8">
      <div class="list-selected-header">
        <div class="row">
          <div class="col-xs-4 inventory-list-label">
            <label>Code</label>
          </div>
          <div class="col-xs-4 inventory-list-label">
            <label>Size</label>
          </div>
          <div class="col-xs-4 inventory-list-label">
            <label>Unit</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-lg-8">
      <ul class="inventory-table full-width">
        <li *ngFor="let product_option of inventory.c_product_options">
          <label class="info">{{ product_option.c_code }}</label>
          <label class="info">{{ product_option.c_size }}</label>
          <label class="info">{{ product_option.c_unit }}</label>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="forms-form" *ngIf="indexPage">
  <div class="inv-btns-container">
    <!-- Update Inventories -->
    <div class="inv-btn-cont">
      <a
        *ngIf="hasRole.admin || hasRole.site_manager"
        class="button create-button"
        (click)="updateInventories()"
      >
        {{ 'inventories.inventory-form-update.title' | lang }}
      </a>
    </div>
    <!-- Mark Inactive Products -->
    <!-- <div class="inv-btn-cont">
            <a *ngIf= "hasRole.admin || hasRole.site_manager" class="button create-button" (click)="markInactive()" >Mark Inactive</a>
        </div> -->
    <div class="inv-btn-cont">
      <a
        *ngIf="hasRole.admin"
        class="button create-button"
        (click)="updateInventoryStatus()"
      >
        Update Status
      </a>
    </div>
    <!-- Sync HCPCS codes -->
    <div class="inv-btn-cont">
      <a
        *ngIf="hasRole.admin"
        class="button create-button"
        (click)="syncHcpcsCodes()"
      >
        Sync HCPCS
      </a>
    </div>
    <!-- Display Progress  -->
    <div *ngIf="progressBar.display" class="progress-bar-container">
      <!-- Outer -->
      <div class="pgb-outer">
        <div
          class="pgb-inner"
          [ngStyle]="{ width: progressBar.percent + '%' }"
        ></div>
      </div>
      <div class="pgb-text-container">
        <div class="pgb-text">
          Processed:
          <b>{{ progressBar.done }}</b>
          of
          <b>{{ progressBar.total }}</b>
          ({{ progressBar.percent }}%)
        </div>
      </div>
    </div>
  </div>
</div>
