import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { InventoryService } from '../../services/inventory/inventory.service';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css'],
})
export class InventoryListComponent implements OnInit {
  inventories: any = [];
  stateEdit = false;
  hasRole: any;
  searchInventory: any;

  constructor(
    private authService: AuthService,
    private inventoryService: InventoryService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getInventories('');
  }

  getInventories(input) {
    this.inventoryService.searchInventories(input).subscribe(
      (inventories) => {
        this.inventories = this.sortInventories(inventories.data);
      },
      (error) => {
        console.error(error.reason);
      }
    );
  }

  goToViewInventory(inventoryId) {
    this.router.navigate(['forms/inventories/:id/view', { id: inventoryId }]);
  }

  sortInventories(inventories) {
    const sorted = inventories.sort((a, b) => {
      const aName = a.c_name.trim().toLowerCase();
      const bName = b.c_name.trim().toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }
}
