import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { FormService } from '../../services/form/form.service';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css'],
})
export class FormListComponent implements OnInit {
  forms: any;
  hasRole: any;
  searchForm = '';
  stateCreate: any;

  constructor(
    private formService: FormService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.getForms('');
  }

  getForms(input) {
    this.formService.searchForms(input).subscribe((forms) => {
      console.log('FOrms: ', forms);
      this.forms = this.sortForms(forms.data);
    });
  }

  gotoEditForm(formId) {
    this.router.navigate(['forms/forms/:id/edit', { id: formId }]);
  }

  gotoViewForm(formId) {
    this.router.navigate(['forms/forms/:id/view', { id: formId }]);
  }

  gotoCreateForm() {
    this.router.navigate(['forms/forms/new']);
  }

  search(form) {
    return (
      form.c_name.toLowerCase().indexOf(this.searchForm.toLowerCase() || '') !==
      -1
    );
  }

  sortForms(forms) {
    const sorted = forms.sort((a, b) => {
      const aName = a.c_name.replace(/\s/gim, '').toLowerCase();
      const bName = b.c_name.replace(/\s/gim, '').toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }
}
