<div class="list-container">
  <div class="search-container">
    <input
      [(ngModel)]="patientName"
      class="search-input mb10"
      placeholder="search by patient name"
      (keyup)="$event.keyCode == 13 && searchOrdersByInput()"
    />
    <!-- <input [(ngModel)]="patientDob" class="search-input mb10" placeholder="search by patient DOB" (keyup)="$event.keyCode == 13 && searchOrders()"> -->
    <input
      [(ngModel)]="patientMrn"
      class="search-input mb10"
      placeholder="search by patient MRN"
      (keyup)="$event.keyCode == 13 && searchOrdersByInput()"
    />

    <div class="input-group datepicker-group datepicker-listcontainer mb10">
      <input
        class="form-control datepicker-input"
        style="float: none"
        angular-mydatepicker
        name="patient_search_dob"
        [(ngModel)]="patientDobModel"
        [options]="dpOptions"
        #psDob="angular-mydatepicker"
        placeholder="search patient dob"
      />

      <span class="input-group-btn">
        <button
          type="button"
          class="btn btn-default"
          (click)="psDob.clearDate()"
        >
          <i class="glyphicon glyphicon-remove"></i>
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="psDob.toggleCalendar()"
        >
          <i class="glyphicon glyphicon-calendar"></i>
        </button>
      </span>
    </div>

    <input
      [(ngModel)]="orderNumber"
      class="search-input mb10"
      placeholder="search by order number"
      (keyup)="$event.keyCode == 13 && searchOrdersByInput()"
    />

    <a class="button create" (click)="searchOrdersByInput()">Search Orders</a>
  </div>

  <div class="container-button">
    <a
      *ngIf="
        (hasRole.admin ||
          hasRole.executive ||
          hasRole.fitter ||
          hasRole.site_manager) &&
        !stateCreate
      "
      class="button create"
      (click)="goToCreateOrder()"
    >
      {{ 'orders.order-form-new.title' | lang }}
    </a>
  </div>

  <div>
    <ul>
      <li *ngFor="let order of orders" (click)="goToViewOrder(order._id)">
        <label>
          <span *ngIf="order.c_number_id">
            {{ order.c_patient.c_mrn }},
            {{
              order.c_patient.c_name ? order.c_patient.c_name.c_first_name : ''
            }}
            {{
              order.c_patient.c_name ? order.c_patient.c_name.c_last_name : ''
            }}, {{ order.c_order_date_show }}
            <b>
              {{ order.c_draft ? '(Draft)' : '' }}
              <span class="red-text">
                {{ order.c_deleted ? '(Deleted)' : '' }}
              </span>
            </b>
          </span>
        </label>
      </li>
    </ul>
  </div>
</div>
