<app-order-list class="ListContainer"></app-order-list>

<!-- Create Btn (Index Page) -->
<div
  *ngIf="
    indexPage &&
    (hasRole.admin ||
      hasRole.executive ||
      hasRole.fitter ||
      hasRole.site_manager)
  "
  class="forms-form"
>
  <button
    type="button"
    class="button create-button"
    (click)="goToCreateOrder()"
  >
    {{ 'orders.order-form-new.title' | lang }}
  </button>
</div>

<!-- View -->
<div class="forms-form" *ngIf="viewOrder">
  <!-- Delete Order Popup -->
  <div *ngIf="showDeleteOrderReason" class="order-ddelete-popup">
    <div class="odp-content-container">
      <div class="odp-title">Reason for deletion</div>
      <div class="odp-header">
        Please enter the reason for the order deletion below
      </div>
      <div class="odp-reason">
        <textarea
          name="odp-delete-reason"
          [(ngModel)]="orderDeletionReason"
          class="odp-delete-reason"
        ></textarea>
      </div>
      <div class="odp-error-tow">
        <div class="odp-error-row-text" *ngIf="showOrderDeleteReasonError">
          A delete reason is required
        </div>
      </div>
      <div class="odp-actions-container">
        <div class="odp-cancel-btn" (click)="hideDeleteOrderReason()">
          CANCEL
        </div>
        <div class="odp-confirm-btn" (click)="proceedToDeleteOrder()">
          CONFIRM
        </div>
      </div>
    </div>
  </div>
  <!-- Title + Tools -->
  <div class="row">
    <div class="col-xs-12 order-header-container">
      <h1>
        <span *ngIf="order.c_number_id">{{ order.c_number_id }} -</span>
        {{ order.c_order_date | date: 'MM/dd/yyyy':'utc' }}
        <span class="secondary-text">{{ order.c_draft ? '(Draft)' : '' }}</span>
      </h1>
      <!-- If order is not deleted, display edit & delete buttons -->
      <div class="pull-right views" *ngIf="!order.c_deleted">
        <button
          *ngIf="
            ((hasRole.admin || hasRole.executive || hasRole.site_manager) &&
              (!order.c_billed_date || orderVoided)) ||
            order.c_draft
          "
          class="button delete-button"
          (click)="deleteOrder(order.c_draft)"
        >
          {{ order.c_draft ? 'CANCEL DRAFT' : ('orders.delete' | lang) }}
        </button>
        <button
          *ngIf="
            (hasRole.admin || hasRole.executive) &&
            order.c_billed_date &&
            !orderVoided
          "
          class="button delete-button"
          disabled="true"
        >
          Billed
        </button>
        <button
          *ngIf="
            hasRole.admin ||
            hasRole.site_manager ||
            hasRole.fitter ||
            hasRole.executive
          "
          class="button edit-button"
          (click)="goToEditOrder(orderId)"
        >
          {{ order.c_draft ? 'EDIT DRAFT' : ('orders.edit' | lang) }}
        </button>
      </div>
      <!-- If order is deleted just display a message -->
      <span class="small-text deleted-text" *ngIf="order.c_deleted">
        This order is deleted.
      </span>
      <div class="clearfix"></div>
    </div>
  </div>

  <div *ngIf="order.c_deleted" class="row ord-del-reason-container">
    <div class="ord-del-rsn-label">Delete Reason:</div>
    <div class="ord-delete-reason-text">
      {{ order ? order.c_delete_reason : '' }}
    </div>
  </div>

  <!-- Firs Info -->
  <div class="row">
    <div class="first-info-container">
      <ul>
        <li>
          <label>{{ 'orders.patient' | lang }}</label>
        </li>
        <li>
          <label>
            {{ 'orders.order-form.label.patient_first_name' | lang }}
          </label>
          <p>{{ order.c_patient.c_name.c_first_name }}</p>
        </li>
        <li>
          <label>
            {{ 'orders.order-form.label.patient_last_name' | lang }}
          </label>
          <p>{{ order.c_patient.c_name.c_last_name }}</p>
        </li>
        <li>
          <label>{{ 'orders.order-form.label.patient_dob' | lang }}</label>
          <p>{{ order.c_patient.c_dob | date: 'MM/dd/yyyy' }}</p>
        </li>
        <li>
          <label>{{ 'orders.order-form.label.patient_mrn' | lang }}</label>
          <p>{{ order.c_patient.c_mrn }}</p>
        </li>
      </ul>
    </div>
  </div>

  <!-- Cin7 Ref field TMP (only visible to admins) -->
  <div *ngIf="hasRole.admin && !order.c_draft" class="row">
    <div class="col-md-12 ord-cin7-ref-container">
      <!-- Lable -->
      <div class="ord-cin7-ref-lbl">Cin7 Ref:</div>
      <!-- Value -->
      <div class="ord-cin7-ref-val-container">
        <input type="text" [(ngModel)]="ordCin7SoRef" />
      </div>
      <!-- Action btn -->
      <div class="cin7-ref-edit-btn-container" (click)="updateOrderCin7SoRef()">
        UPDATE REF.
      </div>
    </div>
  </div>

  <!-- Print -->
  <div class="row">
    <div class="col-md-12">
      <button
        type="button"
        class="button back-button pull-left"
        (click)="populateOrder()"
        style="border-width: 1px"
      >
        Print POD
      </button>
      <button
        type="button"
        class="button back-button pull-left"
        (click)="printAllDocs()"
        style="border-width: 1px"
      >
        Print All Documents
      </button>
      <div class="button back-button pull-left file-upload">
        <label for="file">
          <i class="fa fa-2x fa-paperclip"></i>
        </label>
        <input
          type="file"
          id="file"
          name="file"
          (change)="$event.target.files.length > 0 ? newExternalDocument = $event.target : newExternalDocument = null"
        >
        <div *ngIf="newExternalDocument">
          <span style="padding: 0 10px;">
            {{newExternalDocument.value.split('\\').pop()}}
          </span>
          <button
            class="button delete-button"
            (click)="newExternalDocument = null"
          >
            Clear
          </button>
          <button
            class="button edit-button"
            (click)="handleFileInputUpload()"
          >
            Upload File
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Secondary Info -->
  <div class="row second-info-container">
    <!-- Row1 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.orderingProvider' | lang }}:
        </label>
        <label class="info">
          {{
            order.c_provider_location.c_physician.c_preffix
              ? order.c_provider_location.c_physician.c_preffix + '. '
              : ''
          }}{{ order.c_provider_location.c_physician.c_name.c_first_name }}
          {{ order.c_provider_location.c_physician.c_name.c_last_name
          }}{{
            order.c_provider_location.c_physician.c_suffix
              ? ',' + order.c_provider_location.c_physician.c_suffix
              : ''
          }}
        </label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'dashboard.billingProvider.name' | lang }}
        </label>
        <label class="info">
          {{
            selectedBillingProvider.displayName
              ? selectedBillingProvider.displayName
              : '-'
          }}
        </label>
      </div>
    </div>

    <!-- Row2 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.label.insurance.primary' | lang }}:
        </label>
        <label class="info">{{ order.c_insurance.c_insurance_name }}</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.label.insurance.primary.id' | lang }}:
        </label>
        <label class="info">
          {{
            order.c_primary_insurance_id_number
              ? order.c_primary_insurance_id_number
              : '-'
          }}
        </label>
      </div>
    </div>

    <!-- Row3 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.label.insurance.secondary' | lang }}:
        </label>
        <label class="info">
          {{
            order.c_secondary_insurance
              ? order.c_secondary_insurance.c_insurance_name
              : '-'
          }}
        </label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.label.insurance.secondary.id' | lang }}:
        </label>
        <label class="info">
          {{
            order.c_secondary_insurance_id_number
              ? order.c_secondary_insurance_id_number
              : '-'
          }}
        </label>
      </div>
    </div>

    <!-- Row4 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.notes' | lang }}:
        </label>
        <label class="info">{{ order.c_notes }}</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.billed_date' | lang }}:
        </label>
        <label class="info" *ngIf="order.c_billed_date">
          {{ order.c_billed_date | date: 'MM/dd/yyyy':'UTC' }}
        </label>
        <label class="info" *ngIf="!order.c_billed_date">-</label>
      </div>
    </div>

    <!-- Row5 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.claim_id' | lang }}:
        </label>
        <label class="info" *ngIf="order.c_claim_id">
          {{ order.c_claim_id }}
        </label>
        <label class="info" *ngIf="!order.c_claim_id">-</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">
          {{ 'orders.order-form.label.location' | lang }}:
        </label>
        <label class="info">
          {{ order.c_provider_location.c_location.c_company }}
        </label>
      </div>
    </div>

    <!-- Row6 -->
    <div class="col-xs-12">
      <div class="col-xs-12 col-md-6">
        <label class="name">{{ 'forms.form.label.language' | lang }}:</label>
        <label class="info">{{ order.c_language }}</label>
      </div>
      <div class="col-xs-12 col-md-6">
        <label class="name">Order Date:</label>
        <label class="info">
          {{
            order.c_ordered_date
              ? (order.c_ordered_date | date: 'MM/dd/yyyy':'utc')
              : '-'
          }}
        </label>
      </div>
    </div>
  </div>

  <!-- Line Items -->
  <h3>
    <div class="list-icon"></div>
    {{ 'orders.order-form.label.line_items' | lang }}
  </h3>

  <div class="row">
    <div class="col-xs-12">
      <ul class="list-selected">
        <li *ngFor="let line of orderLineItems; let i = index">
          <div *ngIf="!line.c_deleted" class="line-item-wrapper">
            <div *ngIf="line.c_deleted" class="deleted-item-cover">
              <span class="red-text small-text">Deleted</span>
            </div>
            <div class="list-selected-header">
              <div class="row">
                <div class="col-xs-2 vendor-wrapper">
                  <label class="vendor-label">
                    {{ line.c_product.c_name }}
                  </label>
                  <p class="vendor-code">
                    {{
                      line.c_product.cpy_product_option != undefined
                        ? line.c_product.cpy_product_option.c_size +
                          ' | ' +
                          line.c_product.cpy_product_option.c_code
                        : '-'
                    }}
                  </p>
                  <p class="vendor-code">{{ line.c_product.c_hcpcs_code }}</p>
                </div>

                <div class="col-xs-2 quantity-wrapper">
                  <label>Quantity</label>
                  <p>{{ line.c_quantity }}</p>
                </div>
                <div class="col-xs-8">
                  <div class="list-wrapper">
                    <label>Charge out</label>
                    <p>{{ line.c_charge_out_cost | currency }}</p>
                  </div>
                  <div class="list-wrapper">
                    <label>Patient Payment</label>
                    <p>{{ line.c_self_pay_cost | currency }}</p>
                  </div>
                  <div class="list-wrapper">
                    <label>Allowable</label>
                    <p>{{ line.c_allowable_cost | currency }}</p>
                  </div>
                  <div class="list-wrapper">
                    <label>Sales Tax</label>
                    <p>{{ line.c_sales_tax | currency }}</p>
                  </div>
                </div>
                <!-- <div *ngIf="hasRole.admin" class="col-xs-12 invoice-data-wrapper">
                      <div class="col-xs-2">
                        <p>{{line.c_invoiced ? 'Invoiced' : 'Not Invoiced'}}</p>
                      </div>
                      <div class="col-xs-8">
                        <p>
                          <label class="black_txt no_greedy_width"> Invoice Nr.: </label>
                          <label class="no_greedy_width">{{ line.c_invoice_number != '' ? line.c_invoice_number : '-' }}</label>
                        </p>
                      </div>
                    </div> -->

                <!-- Display invoice view -->
                <div class="col-xs-12 invoice-data-wrapper">
                  <app-invoice-view
                    [invoiced]="line.c_invoiced"
                    [invoiceNr]="line.c_invoice_number"
                  ></app-invoice-view>
                </div>
                <!-- Multiple HCPCS View -->
                <div
                  *ngIf="line.c_multiple_hcpcs"
                  class="col-xs-12 item-extra-row-data-wrapper"
                >
                  <div class="col-xs-12">
                    <label class="multiple-hcpcs-label blue-text">
                      Selected HCPCS:
                      <span class="blue-text bold-text">
                        {{ line.c_selected_hcpcs }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
            <!-- Modifiers -->
            <div class="list-selected-subitem">
              <div class="list-selected-subitem-name">
                <label>Modifiers</label>
              </div>
              <ul class="list-selected-subitem-list">
                <!-- <li *ngFor="let modifier of line.c_modifier">
                      <label [ngClass]="{'label-disabled': !modifier.c_checked }">{{modifier.c_modifier}}</label>
                      <input type="checkbox" class="radio-button" [(ngModel)]="modifier.c_checked" *ngIf="line.edit" />
                    </li> -->
                <div
                  class="col-xs-12"
                  *ngFor="let modifier of line.c_modifier; let mi = index"
                >
                  <li
                    *ngIf="!modifier.c_modifier_ref.c_warning"
                    class="basic-bottom-border"
                  >
                    <label
                      [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                    >
                      {{ modifier.c_modifier }}
                    </label>
                    <input
                      *ngIf="line.edit"
                      type="checkbox"
                      name="{{ 'mcheck_' + key + '_' + mi }}"
                      class="radio-button"
                      [ngClass]="{ 'ng-valid-parse': modifier.c_checked }"
                      [(ngModel)]="modifier.c_checked"
                    />
                  </li>
                </div>
              </ul>
            </div>
            <div style="clear: both"></div>

            <div class="list-selected-subitem">
              <div class="list-selected-subitem-name">
                <label>Warnings</label>
              </div>
              <ul class="list-selected-subitem-list">
                <div
                  class="col-xs-12"
                  *ngFor="let modifier of line.c_modifier; let mi = index"
                >
                  <li
                    *ngIf="modifier.c_modifier_ref.c_warning"
                    class="basic-bottom-border"
                  >
                    <label
                      [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                    >
                      {{ modifier.c_modifier }}
                    </label>
                  </li>
                </div>
              </ul>
            </div>

            <div style="clear: both"></div>

            <!-- Icd10s -->
            <div class="list-selected-subitem">
              <div class="list-selected-subitem-name">
                <label>ICD-10 Codes</label>
              </div>
              <ul class="list-selected-subitem-list">
                <li *ngFor="let icd of line.c_icd_10; let icdIndex = index">
                  <label>
                    {{ icd.c_icd_10_code }} - {{ icd.c_description }}
                  </label>
                </li>
              </ul>
            </div>

            <div class="clearfix"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <h3>
    <div class="list-icon"></div>
    {{ 'orders.order-form.title.step5' | lang }}
  </h3>

  <!-- OrderForms -->
  <div class="row">
    <div class="col-xs-12">
      <!-- <div *ngFor="let form of orderForms; let fIndex = index" class="list-forms">
        <div *ngIf="form.c_inventory_form.c_form">
          <div class="col-xs-10 col-sm-11">
            <button type="button" class="button back-button download-icon" (click)="populateOrderForm(fIndex)">{{ form.displayHcpcs || form.c_inventory_form.c_product.c_hcpcs_code }} {{ form.c_inventory_form.c_form.c_name }}<div></div></button>
          </div>
          <div class="col-xs-2 col-sm-1">
            <button type="button" class="edit-pen" style="margin-top: 5px;" (click)="goToEditOrderForm(orderId, form._id)"><div></div></button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div> -->

      <div
        *ngFor="let form of modifierForms; let mIndex = index"
        class="list-forms"
      >
        <div class="col-xs-10 col-sm-11">
          <button
            type="button"
            class="button back-button download-icon"
            (click)="populateModifierForm(mIndex)"
          >
            {{ form.displayHcpcs || form.c_product.c_hcpcs_code }}
            {{ form.c_modifier_form.c_form.c_name }}
            <div></div>
          </button>
        </div>
        <div class="col-xs-2 col-sm-1">
          <button
            type="button"
            class="edit-pen"
            style="margin-top: 5px"
            (click)="goToEditRuleForm(orderId, form._id)"
          >
            <div></div>
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>

  <h3 class="external-document-title" *ngIf="externalDocuments.length > 0">
    <div class="list-icon"></div>
    {{ 'orders.order-external-document.title' | lang }}
  </h3>

  <!-- Order External Document -->
  <div class="row">
    <div class="col-xs-12">
      <div  *ngFor="let doc of externalDocuments; let i = index">
        <div *ngIf="externalDocuments.length && doc.filename" class="external-documents list-forms">
          <div class="col-xs-10 col-sm-11" >
            <button
              type="button"
              class="button back-button download-icon"
              (click)="openOrderExternalDocument(doc._id)"
            >
              {{ doc.filename.length > 50 ? doc.filename.slice(0,50) + '[...].' + doc.filename.split('.').pop() : doc.filename }}
              <div></div>
            </button>
          </div>
          <div class="col-xs-2 col-sm-1" >
            <button
              type="button"
              class="delete-trash"
              style="margin-top: 30px"
              (click)="removeOrderExternalDocument(doc._id)">
              <div></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Order -->

<div class="forms-form" *ngIf="editOrder">
  <!-- Title -->
  <div class="row">
    <div class="col-xs-12">
      <h1 [hidden]="step == 5">
        {{ 'orders.edit' | lang }}
        <span class="secondary-text">{{ order.c_draft ? '(Draft)' : '' }}</span>
      </h1>
      <!-- <h1 *ngIf="step == 5 || step == 6">{{ 'orders.order-form.title.step5' | lang }}</h1> -->
    </div>
  </div>

  <!-- Form -->
  <form
    name="order"
    (ngSubmit)="onSubmit() && orderLineItems.length > 0"
    #f="ngForm"
    novalidate
    [hidden]="step == 5 || step == 6"
  >
    <div class="container-fluid step">
      <!-- FIRST STEP -->
      <hr />
      <h2>{{ 'orders.order-form.title.step1' | lang }}</h2>
      <!-- Section: Patient Information*** -->
      <!-- Date Of Service -->
      <div class="row">
        <!-- Date of service -->
        <div
          class="col-xs-12 col-md-6"
          [ngClass]="{
            'required-input': f.form.submitted && !f.form.order_date.valid
          }"
        >
          <label>
            {{ 'orders.order-form.label.date' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <div class="input-group datepicker-group">
            <input
              class="form-control datepicker-input"
              style="float: none"
              placeholder="Select a date"
              angular-mydatepicker
              name="date_of_service"
              [(ngModel)]="orderDateModel"
              [options]="dpOptions"
              #dpDos="angular-mydatepicker"
              (focusout)="formatDateInput($event, 'orderDateModel')"
              [disabled]="
                !(hasRole.admin || hasRole.executive || hasRole.site_manager) &&
                !order.c_draft
              "
            />

            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                (click)="dpDos.clearDate()"
                [disabled]="
                  !(hasRole.admin || hasRole.executive || hasRole.site_manager) && !order.c_draft
                "
              >
                <i class="glyphicon glyphicon-remove"></i>
              </button>
              <button
                type="button"
                class="btn btn-default"
                (click)="dpDos.toggleCalendar()"
                [disabled]="
                  !(hasRole.admin || hasRole.executive || hasRole.site_manager) && !order.c_draft
                "
              >
                <i class="glyphicon glyphicon-calendar"></i>
              </button>
            </span>
          </div>
        </div>

        <!-- Order Date -->
        <div class="col-sm-12 col-md-6">
          <label>
            {{ 'dashboard.ordered_date' | lang }}
            <i
              class="fa fa-question highlighting-icon"
              name="question"
              (mouseenter)="displayToolTop()"
              (mouseleave)="hideToolTip()"
            ></i>
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <p class="cutom-tt" *ngIf="displayOrderDateTollTop">
            date the item was orderd by the provider
          </p>
          <div class="input-group datepicker-group">
            <input
              class="form-control datepicker-input"
              style="float: none"
              placeholder="Select a date"
              angular-mydatepicker
              name="ordered_date"
              [(ngModel)]="orderedDateModel"
              [options]="dpOptions"
              #dpCod="angular-mydatepicker"
              (focusout)="formatDateInput($event, 'orderedDateModel')"
            />

            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                (click)="dpCod.clearDate()"
              >
                <i class="glyphicon glyphicon-remove"></i>
              </button>
              <button
                type="button"
                class="btn btn-default"
                (click)="dpCod.toggleCalendar()"
              >
                <i class="glyphicon glyphicon-calendar"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <!-- Billed Date -->
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label>{{ 'orders.order-form.label.billed_date' | lang }}</label>
          <div class="input-group datepicker-group">
            <input
              class="form-control datepicker-input"
              style="float: none"
              placeholder="Select a date"
              angular-mydatepicker
              name="billed_date"
              [(ngModel)]="billedDateModel"
              [options]="dpOptions"
              #dpBd="angular-mydatepicker"
              (focusout)="formatDateInput($event, 'billedDateModel')"
            />

            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                (click)="dpBd.clearDate()"
              >
                <i class="glyphicon glyphicon-remove"></i>
              </button>
              <button
                type="button"
                class="btn btn-default"
                (click)="dpBd.toggleCalendar()"
              >
                <i class="glyphicon glyphicon-calendar"></i>
              </button>
            </span>
          </div>
        </div>
        <!-- Claim Id -->
        <div class="col-xs-12 col-md-6">
          <label>{{ 'orders.order-form.label.claim_id' | lang }}</label>
          <input name="claim_id" [(ngModel)]="order.c_claim_id" type="text" />
        </div>
      </div>

      <!-- Section: List Patients*** -->
      <!-- Patient AUtocomplete -->
      <div class="row">
        <div class="col-sm-12 col-md-6 hcpcs_autocomplete">
          <label>{{ 'patients.patient-form-search.title' | lang }}</label>
          <input
            type="text"
            name="searchPatient"
            placeholder="{{
              'orders.order-form.placeholder.search-patient' | lang
            }}"
            id="autocomplete_Patient"
            class="highlight"
            [ngModel]="patientListInput"
            (focus)="patientListInputFocus()"
            (focusout)="patientListInputFocusOut()"
            [ngbTypeahead]="searchPatientListWrapper2()"
            [resultTemplate]="autocompletePatientList"
            [inputFormatter]="patientListFormatter"
          />
          <div class="ll-typeahead-loading-ps" *ngIf="searchingPatient">
            <span>Searching...</span>
          </div>
          <ng-template #autocompletePatientList let-r="result" let-t="term">
            <li
              [ngClass]="{ 'll-inactive-list-items': searchingPatient }"
              (click)="selectPatient(r)"
            >
              {{
                r.c_mrn +
                  ' ' +
                  r.c_name.c_first_name +
                  ' ' +
                  r.c_name.c_last_name
              }}
            </li>
          </ng-template>
        </div>
      </div>
      <!-- Patient: First + Last Name -->
      <div class="row">
        <div
          class="col-xs-12 col-md-6"
          [ngClass]="{
            'required-input': f.form.$submitted && !f.form.first_name.valid
          }"
        >
          <label>
            {{ 'orders.order-form.label.patient_first_name' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            name="first_name"
            [(ngModel)]="order.c_patient.c_name.c_first_name"
            type="text"
            required
            [disabled]="true"
          />
        </div>
        <div
          class="col-xs-12 col-md-6"
          [ngClass]="{
            'required-input': f.form.submitted && !f.form.last_name.valid
          }"
        >
          <label>
            {{ 'orders.order-form.label.patient_last_name' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            name="last_name"
            [(ngModel)]="order.c_patient.c_name.c_last_name"
            type="text"
            required
            [disabled]="true"
          />
        </div>
      </div>
      <!-- Patient: DOB + MRN -->
      <div class="row">
        <!-- DOB -->
        <div class="col-xs-12 col-md-6">
          <label>DOB*</label>
          <div class="input-group datepicker-group">
            <input
              class="form-control datepicker-input"
              style="float: none"
              angular-mydatepicker
              name="patient_dob"
              [(ngModel)]="dateOfBirthModel"
              [options]="dpDob"
              #dpDob="angular-mydatepicker"
              [readOnly]="!patientEditable"
              (focusout)="formatDateInput($event, 'dateOfBirthModel')"
            />

            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                (click)="dpDob.clearDate()"
                [disabled]="!patientEditable"
              >
                <i class="glyphicon glyphicon-remove"></i>
              </button>
              <button
                type="button"
                class="btn btn-default"
                (click)="dpDob.toggleCalendar()"
                [disabled]="!patientEditable"
              >
                <i class="glyphicon glyphicon-calendar"></i>
              </button>
            </span>
          </div>
        </div>
        <!-- MRN -->
        <div
          class="col-xs-12 col-md-6"
          [ngClass]="{
            'required-input': f.form.submitted && !f.form.mrn.valid
          }"
        >
          <label>
            {{ 'orders.order-form.label.patient_mrn' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            name="mrn"
            [(ngModel)]="order.c_patient.c_mrn"
            type="text"
            required
            [disabled]="true"
          />
        </div>
      </div>
      <!-- END FIRST STEP -->

      <h2>{{ 'orders.order-form.title.step2' | lang }}</h2>

      <!-- SECOND STEP (Edit) -->

      <!-- Insurance & Provider -->

      <!-- Primary Insurance + ID # -->
      <div class="row">
        <!-- Primary Insurance -->
        <div class="col-xs-12 col-md-6 hcpcs_autocomplete">
          <label>
            {{ 'orders.label.insurance.primary' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            type="text"
            name="searchInsuranceP"
            placeholder="{{
              'orders.order-form.placeholder.patient_insurance' | lang
            }}"
            id="autocomplete_insurance"
            class="highlight"
            [(ngModel)]="selectedInsurances.primary.document"
            (focus)="insuranceInputFocus('primary')"
            (focusout)="insuranceInputFocusOut('primary')"
            [ngbTypeahead]="searchInsuranceWrapper()"
            [resultTemplate]="autocompleteInsuranceListP"
            [inputFormatter]="insuranceFormatter"
            required
          />
          <ng-template #autocompleteInsuranceListP let-r="result" let-t="term">
            <li (click)="selectInsurance(r, 'primary')">
              {{ r.c_insurance_name }}
            </li>
          </ng-template>
        </div>

        <!-- Primary Insurance ID # -->
        <div class="col-xs-12 col-md-6">
          <label>{{ 'orders.label.insurance.primary.id' | lang }}</label>
          <input
            #primaryInsuranceID
            name="primary_insurance_id"
            type="text"
            (keyup)="primaryInsuranceTyping()"
            [(ngModel)]="selectedInsurances.primary.id"
            maxlength="25"
          />
        </div>
      </div>

      <!-- Secondary Insrance + ID # -->
      <div class="row">
        <!-- Secondary Insurance -->
        <div class="col-xs-12 col-md-6 hcpcs_autocomplete">
          <label>
            {{ 'orders.label.insurance.secondary' | lang }}
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            type="text"
            name="searchInsuranceSecondary"
            placeholder="{{
              'orders.order-form.placeholder.patient_insurance' | lang
            }}"
            id="autocomplete_insurance"
            class="highlight"
            [(ngModel)]="selectedInsurances.secondary.document"
            (focus)="insuranceInputFocus('secondary')"
            (focusout)="insuranceInputFocusOut('secondary')"
            [ngbTypeahead]="searchInsuranceWrapper()"
            [resultTemplate]="autocompleteInsuranceListS"
            [inputFormatter]="insuranceFormatter"
          />
          <ng-template #autocompleteInsuranceListS let-r="result" let-t="term">
            <li (click)="selectInsurance(r, 'secondary')">
              {{ r.c_insurance_name }}
            </li>
          </ng-template>
        </div>
        <!-- Secondary Insurance ID # -->
        <div class="col-xs-12 col-md-6">
          <label>{{ 'orders.label.insurance.secondary.id' | lang }}</label>
          <input
            #secondaeyInsuranceID
            name="secondary_insurance_id"
            type="text"
            (keyup)="secondaryInsuranceTyping()"
            [(ngModel)]="selectedInsurances.secondary.id"
            maxlength="25"
          />
        </div>
      </div>

      <!-- Provider + Location -->
      <div class="row">
        <!-- Provider -->
        <div class="col-xs-12 col-md-6 hcpcs_autocomplete">
          <label>
            {{ 'orders.order-form.label.orderingProvider' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            type="text"
            required
            name="searchPhysician"
            placeholder="{{ 'orders.order-form.placeholder.physician' | lang }}"
            id="autocomplete_physician"
            class="highlight"
            [ngModel]="selectedPhysician"
            (focus)="physicianInputFocus()"
            (focusout)="physicianInputFocusOut()"
            [ngbTypeahead]="searchPhysicianWrapper()"
            [resultTemplate]="autocompletePhysicianList1"
            [inputFormatter]="physicianFormatter"
          />
          <ng-template #autocompletePhysicianList1 let-r="result" let-t="term">
            <li (click)="selectPhysician(r)">
              {{
                r.c_physician.c_preffix +
                  ' ' +
                  r.c_physician.c_name.c_first_name +
                  ' ' +
                  r.c_physician.c_name.c_last_name +
                  ' ' +
                  r.c_physician.c_suffix
              }}
            </li>
          </ng-template>
        </div>

        <!-- Billing Provider Section -->
        <div class="col-xs-12 col-md-6" *ngIf="!multipleBillingProviders">
          <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
          <input
            [(ngModel)]="selectedBillingProvider.displayName"
            type="text"
            disabled
            name="billing_provider"
            required
          />
        </div>

        <div class="col-xs-12 col-md-6" *ngIf="multipleBillingProviders">
          <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
          <select
            [(ngModel)]="chosenBillingProvider"
            name="billing_provider_select"
            (change)="billingProviderChosen()"
          >
            <option
              *ngFor="
                let providerObj of availableBillingProviders;
                let idx = index
              "
              [ngValue]="providerObj.c_provider"
            >
              {{ providerObj.c_provider.displayName }}
            </option>
          </select>
        </div>

        <!-- Provider Location Section -->
        <div class="row" *ngIf="multipleLocations">
          <div class="col-xs-12">
            <label>{{ 'orders.order-form.label.location' | lang }}</label>
            <select
              [(ngModel)]="chosenLocation"
              name="location_select"
              (change)="locationChosen()"
              required
            >
              <option
                *ngFor="let location of availableLocations"
                [ngValue]="location"
              >
                {{ location.c_location.c_company }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="!multipleLocations">
          <div class="col-xs-12">
            <label>{{ 'orders.order-form.label.location' | lang }}</label>
            <input
              [(ngModel)]="selectedPhysician.c_location.c_company"
              type="text"
              disabled
              name="physician_location"
              required
            />
          </div>
        </div>
      </div>

      <!-- END SECOND STEP (Edit)-->

      <hr />

      <h2>{{ 'orders.order-form.title.step3' | lang }}</h2>
      <h3>
        <div class="list-icon"></div>
        {{ 'orders.order-form.label.line_items' | lang }}
      </h3>
      <!-- Edit Order Orderline Items -->
      <div class="row">
        <ul class="list-selected order">
          <li
            *ngFor="let line of orderLineItems; let i = index"
            [hidden]="line.deleted"
          >
            <div *ngIf="!line.c_deleted" class="line-item-wrapper">
              <!-- Delete Item Popup -->
              <div
                *ngIf="line.showDeleteReasonPopup"
                class="order-ddelete-popup"
              >
                <div class="odp-content-container">
                  <div class="odp-title">Reason for deletion</div>
                  <div class="odp-header">
                    Please enter the reason for the item deletion below
                  </div>
                  <div class="odp-reason">
                    <textarea
                      name="odp-delete-reason"
                      [(ngModel)]="line.c_delete_reason"
                      class="odp-delete-reason"
                    ></textarea>
                  </div>
                  <div class="odp-error-tow">
                    <div
                      class="odp-error-row-text"
                      *ngIf="line.itemDeleteReasonError"
                    >
                      A delete reason is required
                    </div>
                  </div>
                  <div class="odp-actions-container">
                    <div
                      class="odp-cancel-btn"
                      (click)="hideItemDeleteReason(i)"
                    >
                      CANCEL
                    </div>
                    <div class="odp-confirm-btn" (click)="confirmRemoveItem(i)">
                      CONFIRM
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Delete Item Popup -->
              <div class="deleted-item-cover" *ngIf="line.c_deleted">
                <span class="red-text">Deleted</span>
              </div>
              <div class="list-selected-header">
                <div class="row">
                  <div class="col-xs-2 vendor-wrapper">
                    <label class="vendor-label">
                      {{ line.c_product.c_name }}
                    </label>
                    <p class="vendor-code">
                      {{
                        line.c_product.cpy_product_option != undefined
                          ? line.c_product.cpy_product_option.c_size
                          : '-'
                      }}
                    </p>
                  </div>

                  <div class="col-xs-2 quantity-wrapper">
                    <label>Quantity</label>
                    <input
                      name="{{ 'lineQuantity_' + i }}"
                      type="number"
                      [(ngModel)]="line.c_quantity"
                      min="1"
                      step="1"
                      [disabled]="!line.edit"
                    />
                  </div>

                  <div class="col-xs-7 lineitem-wrapper">
                    <div class="row">
                      <div class="col-xs-3">
                        <label>Charge out</label>
                        <input
                          type="number"
                          [(ngModel)]="line.c_charge_out_cost"
                          min="0"
                          (keyup)="
                            checkProdPrice(
                              line.c_charge_out_cost,
                              i,
                              'c_charge_out_cost'
                            )
                          "
                          step=".01"
                          [disabled]="!line.edit"
                          name="{{ 'coc_' + i }}"
                        />
                        <span class="currency">$</span>
                      </div>
                      <div class="col-xs-3">
                        <label>Patient Payment</label>
                        <input
                          type="number"
                          [(ngModel)]="line.c_self_pay_cost"
                          min="0"
                          (keyup)="
                            checkProdPrice(
                              line.c_self_pay_cost,
                              i,
                              'c_self_pay_cost'
                            )
                          "
                          step=".01"
                          [disabled]="!line.edit"
                          name="{{ 'spc_' + i }}"
                        />
                        <span class="currency">$</span>
                      </div>
                      <div class="col-xs-3">
                        <label>Allowable</label>
                        <input
                          type="number"
                          [(ngModel)]="line.c_allowable_cost"
                          min="0"
                          (keyup)="
                            checkProdPrice(
                              line.c_allowable_cost,
                              i,
                              'c_allowable_cost'
                            )
                          "
                          step=".01"
                          [disabled]="!line.edit"
                          name="{{ 'cac_' + i }}"
                        />
                        <span class="currency">$</span>
                      </div>
                      <div class="col-xs-2">
                        <label>Sales Tax</label>
                        <input
                          type="number"
                          [(ngModel)]="line.c_sales_tax"
                          min="0"
                          (keyup)="
                            checkProdPrice(
                              line.c_allowable_cost,
                              i,
                              'c_allowable_cost'
                            )
                          "
                          step=".01"
                          [disabled]="!line.edit"
                          name="{{ 'st_' + i }}"
                        />
                        <span class="currency">$</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-1">
                    <button
                      type="button"
                      class="delete-trash"
                      (click)="removeProduct(i)"
                      [disabled]="notEditable"
                    >
                      <div></div>
                    </button>
                    <button
                      type="button"
                      class="edit-pen"
                      (click)="editOrderLine(i)"
                      [disabled]="notEditable"
                      [hidden]="line.edit"
                    >
                      <div></div>
                    </button>
                    <button
                      type="button"
                      class="finish-editing"
                      (click)="finishEditOrderLine(i)"
                      [disabled]="notEditable"
                      [hidden]="!line.edit"
                    >
                      <div></div>
                    </button>
                  </div>

                  <!-- If user is admin, display editable invoice section -->
                  <div
                    *ngIf="hasRole.admin"
                    class="col-xs-12 invoice-data-wrapper"
                  >
                    <div class="col-xs-2">
                      <label>Invoiced</label>
                      <input
                        type="checkbox"
                        [(ngModel)]="line.c_invoiced"
                        class="radio-button ll-custom-checkbox"
                        name="{{ 'invoiced_' + i }}"
                      />
                    </div>
                    <div class="col-xs-8">
                      <label>Invoice Nr.</label>
                      <input
                        class="auto_width"
                        type="text"
                        name="{{ 'invoice_nr_' + i }}"
                        [(ngModel)]="line.c_invoice_number"
                      />
                    </div>
                  </div>

                  <!-- If user not admin display not editable invoice section -->
                  <div
                    *ngIf="!hasRole.admin"
                    class="col-xs-12 invoice-data-wrapper"
                  >
                    <app-invoice-view
                      [invoiced]="line.c_invoiced"
                      [invoiceNr]="line.c_invoice_number"
                    ></app-invoice-view>
                  </div>

                  <!-- Multiple HCPCS Section Edit -->
                  <div
                    *ngIf="line.c_multiple_hcpcs"
                    class="col-xs-12 item-extra-row-data-wrapper"
                  >
                    <div class="col-xs-12">
                      <label class="multiple-hcpcs-label">
                        HCPCS Code:
                        <span class="small-star-txt">
                          (* multiple available)
                        </span>
                      </label>
                      <select
                        [(ngModel)]="line.c_selected_hcpcs"
                        name="{{ 'line_item_hcpcs_' + key }}"
                        class="slim-select col-xs-12 col-md-6"
                      >
                        <option value="">Choose</option>
                        <option
                          *ngFor="
                            let code of line.c_product.c_hcpcs_code.split('/')
                          "
                          [value]="code"
                        >
                          {{ code }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xs-12 invoice-data-wrapper">
                    <!-- <button type="button" class="button" (click)="toggleScanning(i)">{{ !startScan.value ? 'Scan Barcode' : 'Cancel' }}</button>
                        <zxing-scanner class="barcode-scanner-video" *ngIf="startScan.value && startScan.idx == i"
                            [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
                            (scanSuccess)="scanSuccessHandler($event, i)"
                            (scanError)="scanErrorHandler($event)"></zxing-scanner>
                        <p *ngIf="line.c_barcode">Barcode: {{ line.c_barcode }}</p> -->
                    <!-- Payment Method Section -->
                    <div
                      *ngIf="
                        line.c_self_pay_cost > 0 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12">
                        <label>Payment Method</label>
                        <select
                          [(ngModel)]="line.c_payment_method"
                          (ngModelChange)="paymentMethodChange($event, key)"
                          name="{{ 'spc_pmt' + key }}"
                        >
                          <option value="0">Choose</option>
                          <option value="1">Cash</option>
                          <option value="2">Check</option>
                          <option value="3">Card</option>
                        </select>
                      </div>
                    </div>
                    <!-- Check Details Section -->
                    <div
                      *ngIf="
                        line.c_payment_method == 2 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12">
                        <input
                          class="full-width-input mt35"
                          type="text"
                          placeholder="Check Number"
                          name="{{ 'spc_cdt_number' + key }}"
                          [(ngModel)]="line.c_check_number"
                          [disabled]="!line.edit"
                        />
                      </div>
                    </div>
                    <!-- Card Details Section -->
                    <div
                      *ngIf="
                        line.c_payment_method == 3 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12 mt10">
                        <input
                          class="full-width-input"
                          type="text"
                          placeholder="Card Type (eg. Visa)"
                          name="{{ 'spc_cdt_name' + key }}"
                          [(ngModel)]="line.c_card_type"
                          [disabled]="!line.edit"
                        />
                      </div>
                      <div class="col-xs-12">
                        <input
                          (keypress)="cardNrChanged($event, line)"
                          class="full-width-input"
                          type="text"
                          placeholder="Card Last 4 Digits"
                          name="{{ 'spc_cdt' + key }}"
                          [(ngModel)]="line.c_card_number"
                          [disabled]="!line.edit"
                        />
                      </div>
                      <div class="col-xs-12">
                        <input
                          class="full-width-input"
                          type="text"
                          placeholder="Approval Code"
                          name="{{ 'spc_cdt_code' + key }}"
                          [(ngModel)]="line.c_approval_code"
                          [disabled]="!line.edit"
                        />
                      </div>
                    </div>
                  </div>

                  <div style="clear: both"></div>
                </div>
              </div>

              <div class="list-selected-subitem">
                <div class="list-selected-subitem-name">Modifiers</div>
                <ul class="list-selected-subitem-list">
                  <!-- <li *ngFor="let modifier of line.c_modifier; let mi = index">
                        <span [ngClass]="{'label-disabled': !modifier.c_checked }">{{modifier.c_modifier}}</span>
                        <input (click)="displayModifier(modifier)" name="{{'mdc_' + i + '_' + mi}}" type="checkbox" class="radio-button ll-custom-checkbox" [ngClass]="{'ng-valid-parse' : modifier.c_checked}" [(ngModel)]="modifier.c_checked" [hidden]="!line.edit" />
                      </li> -->
                  <div
                    class="col-xs-12"
                    *ngFor="let modifier of line.c_modifier; let mi = index"
                  >
                    <li
                      *ngIf="
                        !modifier.c_warning &&
                        !modifier.c_modifier_ref.c_warning
                      "
                      class="basic-bottom-border"
                    >
                      <label
                        [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                      >
                        {{ modifier.c_modifier }}
                      </label>
                      <input
                        *ngIf="line.edit"
                        type="checkbox"
                        name="{{ 'mdc_' + key + '_' + mi }}"
                        class="radio-button ll-custom-checkbox"
                        [ngClass]="{ 'ng-valid-parse': modifier.c_checked }"
                        [(ngModel)]="modifier.c_checked"
                        [hidden]="!line.edit"
                      />
                    </li>
                  </div>
                </ul>
              </div>

              <div style="clear: both"></div>
              <!-- Edit Order Warning Section -->
              <div class="list-selected-subitem">
                <div class="list-selected-subitem-name">Warnings</div>
                <ul class="list-selected-subitem-list">
                  <div
                    class="col-xs-12"
                    *ngFor="let modifier of line.c_modifier; let mi = index"
                  >
                    <li
                      *ngIf="
                        modifier.c_warning || modifier.c_modifier_ref.c_warning
                      "
                      class="basic-bottom-border"
                    >
                      <label
                        [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                      >
                        {{ modifier.c_modifier }}
                      </label>
                      <input
                        *ngIf="line.edit"
                        type="checkbox"
                        name="{{ 'mdc_' + key + '_' + mi }}"
                        class="radio-button ll-custom-checkbox"
                        [ngClass]="{ 'ng-valid-parse': modifier.c_checked }"
                        [(ngModel)]="modifier.c_checked"
                        [hidden]="!line.edit"
                      />
                    </li>
                  </div>
                </ul>
              </div>

              <div style="clear: both"></div>

              <div class="list-selected-subitem">
                <div class="list-selected-subitem-name">ICD-10 Codes</div>
                <ul class="list-selected-subitem-list">
                  <li *ngFor="let icd of line.c_icd_10; let keyIcd = index">
                    {{ icd.c_icd_10_code }} - {{ icd.c_description }}
                    <button
                      type="button"
                      class="delete-trash"
                      (click)="removeIcd10OrderLine(i, keyIcd)"
                      [hidden]="!line.edit"
                    >
                      <div></div>
                    </button>
                  </li>
                  <li [hidden]="!line.edit" class="hcpcs_autocomplete">
                    <input
                      type="text"
                      name="searchIcd"
                      placeholder="Add ICD-10 Code"
                      id="autocomplete_icd10"
                      class="highlight"
                      [ngModel]="icd10Input"
                      [ngbTypeahead]="searchIcdWrapper(i)"
                      [resultTemplate]="autocompleteIcd"
                      [inputFormatter]="icdFormatter"
                    />
                    <div class="ll-typeahead-loading" *ngIf="searching[i]">
                      <span>Searching...</span>
                    </div>

                    <ng-template #autocompleteIcd let-r="result" let-t="term">
                      <li
                        [ngClass]="{ 'll-inactive-list-items': searching[i] }"
                        (click)="addIcd10OrderLine(i, r)"
                      >
                        {{ r.c_icd_10_code + ' - ' + r.c_description }}
                      </li>
                    </ng-template>

                    <label style="margin: 0">
                      <span class="required-span">
                        {{ 'forms.error.repeated_item' | lang }}
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
              <div style="clear: both"></div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <div
          class="col-xs-12 col-md-12 product-autocomplete"
          ngClass="{'required-input': (f.form.submitted && orderLineItems.length == 0) || repeatedOrderLine}"
        >
          <label>Add Product</label>
          <input
            type="text"
            name="searchProduct"
            placeholder="{{ 'orders.order-form.add_hcpcs' | lang }}"
            id="autocomplete_product"
            class="highlight"
            value="{{ loadingProducts }}"
            [ngbTypeahead]="searchProductWrapper()"
            [resultTemplate]="autocompleteProduct"
            [inputFormatter]="productFormatter"
            [disabled]="!productsLoaded"
          />

          <ng-template #autocompleteProduct let-r="result" let-t="term">
            <div class="product-autocomplete-item-container">
              <div (click)="addProduct(r)" class="product-autoccomplete-item">
                {{
                  r.c_product.cpy_product_option.c_code +
                    ' ' +
                    r.c_product.c_name +
                    ' ' +
                    r.c_product.cpy_product_option.c_size
                }}
              </div>
              <div
                class="product-check-stock-container"
                ngClass="{'r.loadingStock' : 'inactive-color'}"
                (mouseenter)="getProductStock(r)"
              >
                <div *ngIf="r.loadingStock" class="product-stock-loading">
                  ...
                </div>
                <p class="check-stock-button">
                  {{ r.availiableStock ? r.availiableStock : 'CHECK STOCK' }}
                </p>
              </div>
            </div>
            <!-- <li (click)="addProduct(r)" >{{ r.c_product.cpy_product_option.c_code + ' ' + r.c_product.c_name + ' ' + r.c_product.cpy_product_option.c_size }}</li> -->
          </ng-template>
          <!-- <li (click)="addProduct(r)" >{{ r.c_product.cpy_product_option.c_code + ' ' + r.c_product.c_name + ' ' + r.c_product.cpy_product_option.c_size }}</li> -->

          <label style="margin: 0">
            <span class="required-span" [hidden]="repeatedOrderLine">
              {{ 'orders.order-form.error.line_items' | lang }}
            </span>
            <span class="required-span" [hidden]="!repeatedOrderLine">
              {{ 'forms.error.repeated_item' | lang }}
            </span>
          </label>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xs-12">
          <button [disabled]="!productsLoaded" type="button" class="button" (click)="toggleScanning(key)">{{ !startScan ? 'Scan Barcode' : 'Cancel Scanning' }}</button>
          <zxing-scanner *ngIf="startScan"
              [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
              (scanSuccess)="scanSuccessHandler($event)"
              (scanError)="scanErrorHandler($event)"></zxing-scanner>
          <p class="error-message">{{scannedNotFound}}</p>
        </div>
      </div> -->

      <!-- END THIRD STEP -->

      <hr />

      <!-- 4th STEP -->
      <h2>{{ 'orders.order-form.title.step4' | lang }}</h2>
      <div class="row">
        <div
          class="col-xs-12"
          [ngClass]="{
            'required-input': f.form.submitted && !f.form.notes_area.valid
          }"
        >
          <label>
            {{ 'orders.order-form.label.notes' | lang }}*
            <span class="required-span">
              {{ 'forms.error.length' | lang }}
            </span>
          </label>
          <textarea
            name="notes_area"
            [(ngModel)]="order.c_notes"
            [disabled]="notEditable"
            maxlength="1000"
            required
          ></textarea>
        </div>
      </div>

      <!-- Order Signature -->

      <div class="row">
        <div class="col-xs-12">
          <label>Patient Signature</label>
          <!-- If has signature & no change button clicked, display current signature -->
          <div
            *ngIf="
              orderhasSignature &&
              !changeOrderSignature &&
              !order.c_signature_removed
            "
          >
            <img [src]="orderSignatureSrc" alt="orderSignature" />
            <div class="row">
              <div class="col-xs-6">
                <div
                  class="button neutral-button change-sign-btn"
                  (click)="changeSignature()"
                >
                  CHANGE
                </div>
              </div>
            </div>
          </div>
          <!-- If no signature, display add signature button -->
          <div
            *ngIf="
              (!orderhasSignature || order.c_signature_removed) &&
              !changeOrderSignature
            "
          >
            <p>missing</p>
            <div class="row">
              <div class="col-xs-6">
                <div
                  class="button neutral-button change-sign-btn"
                  (click)="changeSignature()"
                >
                  ADD
                </div>
              </div>
            </div>
          </div>
          <!-- If Change/Add button clicked, display signturepad -->
          <div class="row" *ngIf="changeOrderSignature">
            <div class="col-xs-12">
              <signature-pad
                [options]="signaturePadOptions"
                (onBeginEvent)="drawStart()"
                (onEndEvent)="drawComplete()"
              ></signature-pad>
              <div class="row">
                <div class="col-xs-6 change-sign-buttons-container">
                  <div class="button neutral-button" (click)="drawClear()">
                    CLEAR
                  </div>
                  <div
                    class="button delete-button"
                    (click)="cancelSignChange()"
                  >
                    CANCEL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" [hidden]="notEditable">
        <div class="col-xs-12">
          <div class="buttons-wrapper">
            <button
              type="submit"
              class="button next-button"
              [hidden]="!orderForms.length == 0 || order.c_draft"
              [disabled]="
                orderLineItems.length == 0 ||
                !f.form.valid ||
                disableSubmitButtons ||
                !(this.locationSelected || !this.multipleLocations)
              "
            >
              {{
                editOrder
                  ? ('orders.order-form.label.save_order' | lang)
                  : ('orders.order-form.label.submit_order' | lang)
              }}
            </button>
            <button
              type="button"
              (click)="draft('edit')"
              class="button next-button"
              [hidden]="!orderForms.length == 0 || !order.c_draft"
              [disabled]="
                orderLineItems.length == 0 ||
                !f.form.valid ||
                disableSubmitButtons
              "
            >
              {{
                editOrder
                  ? ('orders.order-form.label.save_draft' | lang)
                  : ('orders.order-form.label.submit_order' | lang)
              }}
            </button>
            <button
              id="publish-draft-btn"
              type="button"
              (click)="draft('publish')"
              class="button next-button"
              [hidden]="!orderForms.length == 0 || !order.c_draft"
              [disabled]="
                orderLineItems.length == 0 ||
                !f.form.valid ||
                disableSubmitButtons
              "
            >
              PUBLISH DRAFT
            </button>
            <button
              type="submit"
              class="button next-button"
              [hidden]="!orderForms.length > 0"
              [disabled]="orderLineItems.length == 0 || !f.form.valid"
            >
              {{ 'orders.order-form.label.submit_go_froms' | lang }}
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
      <!-- END 4th STEP -->
    </div>
  </form>

  <h1 *ngIf="step == 5" class="form-title">
    {{ 'orders.order-form.title.step5' | lang }}
  </h1>
  <div class="step step5" *ngIf="step == 5">
    <form
      *ngIf="mfQuestion.display === true"
      name="step5"
      #st6f="ngForm"
      (ngSubmit)="nextModifierFormQuestion()"
      novalidate
    >
      <h2 style="border: none">
        {{ mfQuestion.inventory }} - {{ mfQuestion.form }}
      </h2>
      <div class="form-row" [ngClass]="{ 'required-input': mfQuestion.error }">
        <label [hidden]="question.c_required">{{ mfQuestion.c_text }}</label>

        <label *ngIf="question.c_required">
          {{ mfQuestion.c_text }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>

        <textarea
          [(ngModel)]="mfQuestion.response"
          name="question_response"
          *ngIf="mfQuestion.c_response_type === 'text'"
          name="answer-text"
        ></textarea>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let oKey = index
            "
          >
            <input
              class="radio-button"
              name="{{ 'option_check_' + oKey }}"
              [(ngModel)]="option.checked"
              type="checkbox"
            />
            <span>{{ option.text }}</span>
          </li>
        </ul>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && !mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let key = index
            "
          >
            <input
              name="radio-{{ key }}"
              class="radio-button"
              [(ngModel)]="mfQuestion.response"
              value="'{{ key }}'"
              type="radio"
            />
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>

      <div class="row mt13" *ngIf="shalDisplaySignaturePad">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>Patient Signature</label>
          </div>
          <div class="col-xs-12">
            <signature-pad
              [options]="signaturePadOptions"
              (onBeginEvent)="drawStart()"
              (onEndEvent)="drawCompleteMf(modifierForms[mfStepQuestion.form])"
            ></signature-pad>
            <div class="row">
              <div class="col-xs-6 change-sign-buttons-container">
                <div
                  class="button neutral-button"
                  (click)="drawClearMf(modifierForms[mfStepQuestion.form])"
                >
                  CLEAR
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button
          type="submit"
          class="button next-button"
          [disabled]="!st6f.form.valid"
        >
          NEXT
        </button>
        <button
          [hidden]="mfStepQuestion.form == 0 && mfStepQuestion.question == 0"
          type="button"
          class="button back-button"
          (click)="previousModifierFormQuestion()"
        >
          BACK
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Create Order -->
<div class="forms-form" *ngIf="createMode">
  <h1 *ngIf="step == 1" class="form-title">
    {{ 'orders.order-form.title.step1' | lang }}
  </h1>
  <!-- <button type="button" class="button" (click)="toggleScanning()">SCAN BARCODE</button> -->
  <!-- Create Step 1 -->
  <div class="step" *ngIf="step == 1">
    <form
      name="st1f"
      (ngSubmit)="st1f.form.valid && checkForErrorsStepOne()"
      #st1f="ngForm"
      novalidate
    >
      <div class="container-fluid">
        <!-- Date Of Service -->
        <div class="row">
          <!-- Date of service input -->
          <div class="col-sm-12 col-md-6">
            <label>
              {{ languageLabels[language].dosLabel | lang }}
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <div class="input-group datepicker-group">
              <input
                class="form-control datepicker-input"
                style="float: none"
                placeholder="Select a date"
                angular-mydatepicker
                name="date_of_service"
                [(ngModel)]="orderDateModel"
                [options]="dpOptions"
                #dpCdos="angular-mydatepicker"
                (focusout)="formatDateInput($event, 'orderDateModel')"
              />

              <span class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpCdos.clearDate()"
                >
                  <i class="glyphicon glyphicon-remove"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpCdos.toggleCalendar()"
                >
                  <i class="glyphicon glyphicon-calendar"></i>
                </button>
              </span>
            </div>
          </div>

          <!-- Order Date -->
          <div class="col-sm-12 col-md-6">
            <label>
              {{ 'dashboard.ordered_date' | lang }}
              <i
                class="fa fa-question highlighting-icon"
                name="question"
                (mouseenter)="displayToolTop()"
                (mouseleave)="hideToolTip()"
              ></i>
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <p class="cutom-tt" *ngIf="displayOrderDateTollTop">
              date the item was orderd by the provider
            </p>
            <div class="input-group datepicker-group">
              <input
                class="form-control datepicker-input"
                style="float: none"
                placeholder="Select a date"
                angular-mydatepicker
                name="ordered_date"
                [(ngModel)]="orderedDateModel"
                [options]="dpOptions"
                #dpCod="angular-mydatepicker"
                (focusout)="formatDateInput($event, 'orderedDateModel')"
              />

              <span class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpCod.clearDate()"
                >
                  <i class="glyphicon glyphicon-remove"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpCod.toggleCalendar()"
                >
                  <i class="glyphicon glyphicon-calendar"></i>
                </button>
              </span>
            </div>
          </div>
        </div>

        <!-- List Patients - Autucomplete -->
        <div class="row">
          <div class="col-sm-12 col-md-6 hcpcs_autocomplete">
            <label>{{ 'patients.patient-form-search.title' | lang }}</label>
            <input
              type="text"
              name="searchPatientC"
              placeholder="{{
                'orders.order-form.placeholder.search-patient' | lang
              }}"
              id="autocomplete_Patient"
              class="highlight"
              [ngModel]="patientListInput"
              (focus)="patientListInputFocus()"
              (focusout)="patientListInputFocusOut()"
              [ngbTypeahead]="searchPatientListWrapper2()"
              [resultTemplate]="autocompletePatientList"
              [inputFormatter]="patientListFormatter"
            />
            <div class="ll-typeahead-loading-ps" *ngIf="searchingPatient">
              <span>Searching...</span>
            </div>
            <ng-template #autocompletePatientList let-r="result" let-t="term">
              <li
                [ngClass]="{ 'll-inactive-list-items': searchingPatient }"
                (click)="selectPatient(r)"
              >
                {{
                  r.c_mrn +
                    ' ' +
                    r.c_name.c_first_name +
                    ' ' +
                    r.c_name.c_last_name
                }}
              </li>
            </ng-template>
          </div>
        </div>
        <!-- First + Last Name -->
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label>
              {{ 'orders.order-form.label.patient_first_name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="first_name"
              [(ngModel)]="order.c_patient.c_name.c_first_name"
              type="text"
              required
              [disabled]="patientSelected"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <label>
              {{ 'orders.order-form.label.patient_last_name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="last_name"
              [(ngModel)]="order.c_patient.c_name.c_last_name"
              type="text"
              required
              [disabled]="patientSelected"
            />
          </div>
        </div>
        <!-- DOB + MRN -->
        <div class="row">
          <!-- DOB -->
          <div class="col-xs-12 col-md-6">
            <label>DOB*</label>
            <div class="input-group datepicker-group">
              <input
                class="form-control datepicker-input"
                style="float: none"
                angular-mydatepicker
                name="patient_dob"
                [(ngModel)]="dateOfBirthModel"
                [options]="dpOptions"
                #dpDob="angular-mydatepicker"
                [readonly]="patientSelected"
                (focusout)="formatDateInput($event, 'dateOfBirthModel')"
              />

              <span class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpDob.clearDate()"
                  [disabled]="patientSelected"
                >
                  <i class="glyphicon glyphicon-remove"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dpDob.toggleCalendar()"
                  [disabled]="patientSelected"
                >
                  <i class="glyphicon glyphicon-calendar"></i>
                </button>
              </span>
            </div>
          </div>
          <!-- MRN -->
          <div class="col-xs-12 col-md-6">
            <label>
              {{ 'orders.order-form.label.patient_mrn' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="mrn"
              [(ngModel)]="order.c_patient.c_mrn"
              type="text"
              required
              [disabled]="patientSelected"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'forms.form.label.language' | lang }}</label>
            <select [(ngModel)]="chosenLanguage" name="language-select">
              <option
                *ngFor="let language of languageOptions"
                [ngValue]="language"
              >
                {{ language.label }}
              </option>
            </select>
          </div>
        </div>

        <!-- Buttons -->
        <div class="row">
          <div class="col-xs-12">
            <span [hidden]="!duplicatedError" class="span-duplicated-error">
              {{ 'forms.error.repeated_patient' | lang }}
            </span>
            <button
              type="submit"
              class="button next-button"
              [disabled]="!st1f.form.valid"
            >
              NEXT
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- END Create Step 1 -->

  <!-- Create Step 2 -->
  <h1 *ngIf="step == 2" class="form-title">
    {{ 'orders.order-form.title.step2' | lang }}
  </h1>
  <div class="step" *ngIf="step == 2">
    <form
      name="st2f"
      #st2f="ngForm"
      (ngSubmit)="st2f.form.valid && nextStep()"
      novalidate
    >
      <div class="streach-container">
        <!-- Insurances -->
        <!-- Primary Insurance -->
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>
              {{ 'orders.label.insurance.primary' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              type="text"
              name="searchInsuranceP"
              placeholder="{{
                'orders.order-form.placeholder.patient_insurance' | lang
              }}"
              id="autocomplete_insurance"
              class="highlight"
              [(ngModel)]="selectedInsurances.primary.document"
              (focus)="insuranceInputFocus('primary')"
              (focusout)="insuranceInputFocusOut('primary')"
              [ngbTypeahead]="searchInsuranceWrapper()"
              [resultTemplate]="autocompleteInsuranceListP"
              [inputFormatter]="insuranceFormatter"
              required
            />
            <ng-template
              #autocompleteInsuranceListP
              let-r="result"
              let-t="term"
            >
              <li (click)="selectInsurance(r, 'primary')">
                {{ r.c_insurance_name }}
              </li>
            </ng-template>
          </div>
        </div>
        <!-- Primary Insurance ID -->
        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'orders.label.insurance.primary.id' | lang }} *</label>
            <input
              #primaryInsuranceID
              name="primary_insurance_id"
              type="text"
              (keyup)="primaryInsuranceTyping(primaryInsuranceID.value)"
              [(ngModel)]="selectedInsurances.primary.id"
              maxlength="25"
              required
            />
          </div>
        </div>
        <!-- Secondary Insurance -->
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>
              {{ 'orders.label.insurance.secondary' | lang }}
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              type="text"
              name="searchInsuranceSecondary"
              placeholder="{{
                'orders.order-form.placeholder.patient_insurance' | lang
              }}"
              id="autocomplete_insurance"
              class="highlight"
              [(ngModel)]="selectedInsurances.secondary.document"
              (focus)="insuranceInputFocus('secondary')"
              (focusout)="insuranceInputFocusOut('secondary')"
              [ngbTypeahead]="searchInsuranceWrapper()"
              [resultTemplate]="autocompleteInsuranceListS"
              [inputFormatter]="insuranceFormatter"
            />
            <ng-template
              #autocompleteInsuranceListS
              let-r="result"
              let-t="term"
            >
              <li (click)="selectInsurance(r, 'secondary')">
                {{ r.c_insurance_name }}
              </li>
            </ng-template>
          </div>
        </div>
        <!-- Secondary Insurance ID -->
        <div class="row">
          <div class="col-xs-12">
            <label>{{ 'orders.label.insurance.secondary.id' | lang }}</label>
            <input
              #secondaeyInsuranceID
              name="secondary_insurance_id"
              type="text"
              (keyup)="secondaryInsuranceTyping(secondaeyInsuranceID.value)"
              [(ngModel)]="selectedInsurances.secondary.id"
              maxlength="25"
            />
          </div>
        </div>
        <!-- Physician Autocomplete -->
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete">
            <label>
              {{ 'orders.order-form.label.orderingProvider' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              type="text"
              required
              name="searchPhysician"
              placeholder="{{
                'orders.order-form.placeholder.physician' | lang
              }}"
              id="autocomplete_physician"
              class="highlight"
              [ngModel]="selectedPhysician"
              (focus)="physicianInputFocus()"
              (focusout)="physicianInputFocusOut()"
              [ngbTypeahead]="searchPhysicianWrapper()"
              [resultTemplate]="autocompletePhysicianList"
              [inputFormatter]="physicianFormatter"
            />
            <ng-template #autocompletePhysicianList let-r="result" let-t="term">
              <li (click)="selectPhysician(r)">
                {{
                  r.c_physician.c_preffix +
                    ' ' +
                    r.c_physician.c_name.c_first_name +
                    ' ' +
                    r.c_physician.c_name.c_last_name +
                    ' ' +
                    r.c_physician.c_suffix
                }}
              </li>
            </ng-template>
          </div>
        </div>

        <!-- Provider Location -->
        <div class="row" *ngIf="multipleLocations">
          <div class="col-xs-12">
            <label>{{ 'orders.order-form.label.location' | lang }}</label>
            <select
              [(ngModel)]="chosenLocation"
              name="location_select"
              (change)="locationChosen()"
            >
              <option
                *ngFor="let location of availableLocations"
                [ngValue]="location"
              >
                {{ location.c_location.c_company }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="!multipleLocations">
          <div class="col-xs-12">
            <label>{{ 'orders.order-form.label.location' | lang }}</label>
            <input
              [(ngModel)]="selectedPhysician.c_location.c_company"
              type="text"
              disabled
              name="physician_location"
              required
            />
          </div>
        </div>

        <!-- Billing Provider Section -->
        <div class="row" *ngIf="!multipleBillingProviders">
          <div class="col-xs-12">
            <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
            <input
              [(ngModel)]="selectedBillingProvider.displayName"
              type="text"
              disabled
              name="billing_provider"
              required
            />
          </div>
        </div>
        <div class="row" *ngIf="multipleBillingProviders">
          <div class="col-xs-12">
            <label>{{ 'dashboard.billingProvider.name' | lang }}</label>
            <select
              [(ngModel)]="chosenBillingProvider"
              name="billing_provider_select"
              (change)="billingProviderChosen()"
            >
              <option
                *ngFor="let providerObj of availableBillingProviders"
                [ngValue]="providerObj.c_provider"
              >
                {{ providerObj.c_provider.displayName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Designated Fitter Section-->
        <div class="row">
          <div
            class="col-xs-12 hcpcs_autocomplete fitter-autocomplete resp-autocomplete"
          >
            <label>
              {{ 'orders.order-form.label.designated_fitter' | lang }}*
            </label>
            <input
              type="text"
              name="searchFitter"
              placeholder="Designate a Fitter"
              id="autocomplete_fitter"
              class="highlight"
              [ngModel]="selectedFitter"
              (focus)="designatedFitterInputFocus()"
              (focusout)="designatedFitterInputFocusOut()"
              [ngbTypeahead]="searchFitterWrapper()"
              [resultTemplate]="autocompleteFitterList"
              [inputFormatter]="fitterFormatter"
              required
            />
            <ng-template #autocompleteFitterList let-r="result" let-t="term">
              <li (click)="selectFitter(r)">
                {{ r.firstName + ' ' + r.lastName }}
                <br />
                <span class="small-text">{{ r.email }}</span>
              </li>
            </ng-template>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <button
              type="submit"
              class="button next-button"
              [disabled]="secondStepValid()"
            >
              NEXT
            </button>
            <button
              type="button"
              class="button back-button"
              (click)="previousStep()"
            >
              BACK
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- END Create Step 2 -->

  <!-- Create Step 3 -->
  <h1 *ngIf="step == 3" class="form-title">
    {{ 'orders.order-form.title.step3' | lang }}
  </h1>
  <div class="step" *ngIf="step == 3">
    <form
      name="step3"
      (ngSubmit)="orderLineItems.length > 0 && nextStep()"
      #st3f="ngForm"
      novalidate
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12">
            <label style="font-weight: bold">
              <div class="list-icon"></div>
              {{ 'orders.order-form.label.line_items' | lang }}
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <ul class="list-selected">
              <li *ngFor="let line of orderLineItems; let key = index">
                <div class="list-selected-header">
                  <div class="row">
                    <div class="col-xs-2 vendor-wrapper">
                      <label class="vendor-label">
                        {{ line.c_product.c_name }}
                      </label>
                      <p class="vendor-code">
                        {{ line.c_product.cpy_product_option.c_size }}
                      </p>
                    </div>

                    <div class="col-xs-2 quantity-wrapper">
                      <label>Quantity</label>
                      <input
                        type="number"
                        name="{{ 'quantity_' + key }}"
                        [(ngModel)]="line.c_quantity"
                        min="1"
                        step="1"
                        [disabled]="!line.edit"
                      />
                    </div>

                    <div class="col-xs-7 lineitem-wrapper">
                      <div class="row">
                        <div class="col-xs-3">
                          <label>Charge out</label>
                          <input
                            type="number"
                            name="{{ 'cost_out_' + key }}"
                            [(ngModel)]="line.c_charge_out_cost"
                            (keyup)="
                              checkProdPrice(
                                line.c_charge_out_cost,
                                key,
                                'c_charge_out_cost'
                              )
                            "
                            min="0"
                            step=".01"
                            [disabled]="!line.edit"
                          />
                          <span class="currency">$</span>
                        </div>
                        <div class="col-xs-3">
                          <label>Patient Payment</label>
                          <input
                            type="number"
                            name="{{ 'spc_' + key }}"
                            [(ngModel)]="line.c_self_pay_cost"
                            min="0"
                            (keyup)="
                              checkProdPrice(
                                line.c_self_pay_cost,
                                key,
                                'c_self_pay_cost'
                              )
                            "
                            step=".01"
                            [disabled]="!line.edit"
                            (ngModelChange)="paymentModelChanged($event, key)"
                          />
                          <span class="currency">$</span>
                        </div>
                        <div class="col-xs-3">
                          <label>Allowable</label>
                          <input
                            type="number"
                            name="{{ 'ac_' + key }}"
                            [(ngModel)]="line.c_allowable_cost"
                            min="0"
                            (keyup)="
                              checkProdPrice(
                                line.c_allowable_cost,
                                key,
                                'c_allowable_cost'
                              )
                            "
                            step=".01"
                            [disabled]="!line.edit"
                          />
                          <span class="currency">$</span>
                        </div>
                        <div class="col-xs-2">
                          <label>Sales Tax</label>
                          <input
                            type="number"
                            name="{{ 'st_' + key }}"
                            [(ngModel)]="line.c_sales_tax"
                            min="0"
                            (keyup)="
                              checkProdPrice(
                                line.c_allowable_cost,
                                key,
                                'c_allowable_cost'
                              )
                            "
                            step=".01"
                            [disabled]="!line.edit"
                          />
                          <span class="currency">$</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-xs-1">
                      <button
                        type="button"
                        class="delete-trash"
                        (click)="removeProduct(key)"
                        [disabled]="notEditable"
                      >
                        <div></div>
                      </button>
                      <button
                        type="button"
                        class="edit-pen"
                        (click)="editOrderLine(key)"
                        [disabled]="notEditable"
                        *ngIf="!line.edit"
                      >
                        <div></div>
                      </button>
                      <button
                        type="button"
                        class="finish-editing"
                        (click)="finishEditOrderLine(key)"
                        [disabled]="notEditable"
                        *ngIf="line.edit"
                      >
                        <div></div>
                      </button>
                    </div>
                    <!-- Invoice Section  -->
                    <div
                      *ngIf="hasRole.admin"
                      class="col-xs-12 invoice-data-wrapper"
                    >
                      <div class="col-xs-2">
                        <label>Invoiced</label>
                        <input
                          type="checkbox"
                          [(ngModel)]="line.c_invoiced"
                          class="radio-button ll-custom-checkbox"
                          name="{{ 'invoiced_' + key }}"
                        />
                      </div>
                      <div class="col-xs-6">
                        <label>Invoice Nr.</label>
                        <input
                          class="auto_width"
                          type="text"
                          name="{{ 'invoice_nr_' + key }}"
                          [(ngModel)]="line.c_invoice_number"
                        />
                      </div>
                    </div>
                    <!-- Payment Method Section -->
                    <div
                      *ngIf="
                        line.c_self_pay_cost > 0 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12">
                        <label>Payment Method</label>
                        <select
                          [(ngModel)]="line.c_payment_method"
                          (ngModelChange)="paymentMethodChange($event, key)"
                          name="{{ 'spc_pmt' + key }}"
                        >
                          <option value="0">Choose</option>
                          <option value="1">Cash</option>
                          <option value="2">Check</option>
                          <option value="3">Card</option>
                        </select>
                      </div>
                    </div>
                    <!-- Multiple HCPCS Section -->
                    <div
                      *ngIf="line.c_multiple_hcpcs"
                      class="col-xs-12 item-extra-row-data-wrapper"
                    >
                      <div class="col-xs-12">
                        <label class="multiple-hcpcs-label">
                          HCPCS Code:
                          <span class="small-star-txt">
                            (* multiple available)
                          </span>
                        </label>
                        <select
                          [(ngModel)]="line.c_selected_hcpcs"
                          name="{{ 'line_item_hcpcs_' + key }}"
                          class="slim-select col-xs-12 col-md-6"
                        >
                          <option value="">Choose</option>
                          <option
                            *ngFor="let code of line.availableHCPCS"
                            [value]="code"
                          >
                            {{ code }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- Check Details Section -->
                    <div
                      *ngIf="
                        line.c_payment_method == 2 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12">
                        <input
                          class="full-width-input mt35"
                          type="text"
                          placeholder="Check Number"
                          name="{{ 'spc_cdt' + key }}"
                          [(ngModel)]="line.c_check_number"
                          [disabled]="!line.edit"
                        />
                      </div>
                    </div>
                    <!-- Card Details Section -->
                    <div
                      *ngIf="
                        line.c_payment_method == 3 && receiptAssociatedToClinic
                      "
                      class="col-xs-6 invoice-data-wrapper"
                    >
                      <div class="col-xs-12 mt10">
                        <input
                          class="full-width-input"
                          type="text"
                          placeholder="Card Type (eg. Visa)"
                          name="{{ 'spc_cdt' + key }}"
                          [(ngModel)]="line.c_card_type"
                          [disabled]="!line.edit"
                        />
                      </div>
                      <div class="col-xs-12">
                        <input
                          (keypress)="cardNrChanged($event, line)"
                          class="full-width-input"
                          type="text"
                          placeholder="Card Last 4 Digits"
                          name="{{ 'spc_cdt' + key }}"
                          [(ngModel)]="line.c_card_number"
                          [disabled]="!line.edit"
                        />
                      </div>
                      <div class="col-xs-12">
                        <input
                          class="full-width-input"
                          type="text"
                          placeholder="Approval Code"
                          name="{{ 'spc_cdt_code' + key }}"
                          [(ngModel)]="line.c_approval_code"
                          [disabled]="!line.edit"
                        />
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </div>
                <!-- Create Order Modifiers Section -->
                <div class="list-selected-subitem">
                  <div class="list-selected-subitem-name">
                    <label>Modifiers</label>
                  </div>
                  <ul class="list-selected-subitem-list">
                    <!-- <li *ngFor="let modifier of line.c_modifier_details; let mi = index">
                    <label [ngClass]="{'label-disabled': !modifier.c_checked }">{{modifier.c_name}}</label>
                    <input (click)="displayModifier(modifier)" type="checkbox" name="{{ 'mcheck_' + key + '_' + mi }}" class="radio-button ll-custom-checkbox" [ngClass]="{'ng-valid-parse' : modifier.c_checked}" [(ngModel)]="modifier.c_checked" *ngIf="line.edit" />
                  </li> -->

                    <div
                      class="col-xs-12"
                      *ngFor="
                        let modifier of line.c_modifier_details;
                        let mi = index
                      "
                    >
                      <li
                        *ngIf="!modifier.c_warning"
                        class="basic-bottom-border"
                      >
                        <label
                          [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                        >
                          {{ modifier.c_name }}
                        </label>
                        <input
                          (click)="displayModifier(modifier)"
                          type="checkbox"
                          name="{{ 'mcheck_' + key + '_' + mi }}"
                          class="radio-button ll-custom-checkbox"
                          [ngClass]="{ 'ng-valid-parse': modifier.c_checked }"
                          [(ngModel)]="modifier.c_checked"
                          *ngIf="line.edit"
                        />
                      </li>
                    </div>
                  </ul>
                </div>

                <div style="clear: both"></div>
                <!-- Create Order Warnings Section -->
                <div class="list-selected-subitem">
                  <div class="list-selected-subitem-name">
                    <label>Warnings</label>
                  </div>
                  <ul class="list-selected-subitem-list">
                    <div
                      class="col-xs-12"
                      *ngFor="let modifier of line.c_modifier; let miw = index"
                    >
                      <li
                        *ngIf="modifier.c_warning"
                        class="basic-bottom-border"
                      >
                        <label
                          [ngClass]="{ 'label-disabled': !modifier.c_checked }"
                        >
                          {{ modifier.c_modifier }}
                        </label>
                        <input
                          (click)="displayModifier(modifier)"
                          type="checkbox"
                          name="{{ 'wmcheck_' + key + '_' + miw }}"
                          class="radio-button ll-custom-checkbox"
                          [ngClass]="{ 'ng-valid-parse': modifier.c_checked }"
                          [(ngModel)]="modifier.c_checked"
                          *ngIf="line.edit"
                        />
                      </li>
                    </div>
                  </ul>
                </div>

                <div style="clear: both"></div>

                <div class="list-selected-subitem">
                  <div class="list-selected-subitem-name">
                    <label>ICD-10 Codes</label>
                  </div>

                  <ul class="list-selected-subitem-list">
                    <li *ngFor="let icd of line.c_icd_10; let keyIcd = index">
                      <label>
                        {{ icd.c_icd_10_code }} - {{ icd.c_description }}
                      </label>
                      <button
                        type="button"
                        class="delete-trash"
                        (click)="removeIcd10OrderLine(key, keyIcd)"
                        *ngIf="line.edit"
                      >
                        <div></div>
                      </button>
                    </li>
                    <li [hidden]="!line.edit" class="hcpcs_autocomplete">
                      <input
                        type="text"
                        name="{{ 'searchIcd_' + key }}"
                        placeholder="Add ICD-10 Code"
                        id="autocomplete_icd10"
                        class="highlight"
                        [ngModel]="icd10Input"
                        [ngbTypeahead]="searchIcdWrapper(key)"
                        [resultTemplate]="autocompleteIcd"
                        [inputFormatter]="icdFormatter"
                      />
                      <div class="ll-typeahead-loading" *ngIf="searching[key]">
                        <span>Searching...</span>
                      </div>

                      <ng-template #autocompleteIcd let-r="result" let-t="term">
                        <li
                          [ngClass]="{
                            'll-inactive-list-items': searching[key]
                          }"
                          (click)="addIcd10OrderLine(key, r)"
                        >
                          {{ r.c_icd_10_code + ' - ' + r.c_description }}
                        </li>
                      </ng-template>

                      <label style="margin: 0">
                        <span class="required-span">
                          {{ 'forms.error.repeated_item' | lang }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="clearfix"></div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div
            class="col-xs-12 col-md-12 product-autocomplete"
            ngClass="{'required-input': (st3f.form.submitted && orderLineItems.length == 0) || repeatedOrderLine}"
          >
            <label>Add Product</label>
            <input
              type="text"
              name="searchProduct"
              placeholder="{{ 'orders.order-form.add_hcpcs' | lang }}"
              id="autocomplete_product"
              class="highlight"
              value="{{ loadingProducts }}"
              [ngbTypeahead]="searchProductWrapper()"
              [resultTemplate]="autocompleteProduct"
              [inputFormatter]="productFormatter"
              [disabled]="!productsLoaded"
            />
            <ng-template #autocompleteProduct let-r="result" let-t="term">
              <div class="product-autocomplete-item-container">
                <div (click)="addProduct(r)" class="product-autoccomplete-item">
                  {{
                    r.c_product.cpy_product_option.c_code +
                      ' ' +
                      r.c_product.c_name +
                      ' ' +
                      r.c_product.cpy_product_option.c_size
                  }}
                </div>
                <div
                  class="product-check-stock-container"
                  ngClass="{'r.loadingStock' : 'inactive-color'}"
                  (mouseenter)="getProductStock(r)"
                >
                  <div *ngIf="r.loadingStock" class="product-stock-loading">
                    ...
                  </div>
                  <p class="check-stock-button">
                    {{ r.availiableStock ? r.availiableStock : 'CHECK STOCK' }}
                  </p>
                </div>
              </div>
              <!-- <li (click)="addProduct(r)" >{{ r.c_product.cpy_product_option.c_code + ' ' + r.c_product.c_name + ' ' + r.c_product.cpy_product_option.c_size }}</li> -->
            </ng-template>

            <label style="margin: 0">
              <span class="required-span" [hidden]="repeatedOrderLine">
                {{ 'orders.order-form.error.line_items' | lang }}
              </span>
              <span class="required-span" [hidden]="!repeatedOrderLine">
                {{ 'forms.error.repeated_item' | lang }}
              </span>
            </label>
          </div>
        </div>
        <!-- <div class="row">
        <div class="col-xs-12">
          <button [disabled]="!productsLoaded" type="button" class="button" (click)="toggleScanning(key)">{{ !startScan ? 'Scan Barcode' : 'Cancel Scanning' }}</button>
          <zxing-scanner *ngIf="startScan"
              [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
              (scanSuccess)="scanSuccessHandler($event)"
              (scanError)="scanErrorHandler($event)"></zxing-scanner>
          <p class="error-message">{{scannedNotFound}}</p>
        </div>
      </div> -->

        <div *ngIf="validationError.error" class="row">
          <div class="validation-err-container">
            {{ validationError.message }}
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <button
              type="submit"
              class="button next-button"
              [disabled]="!orderLineItems.length > 0"
            >
              NEXT
            </button>
            <button
              type="button"
              class="button back-button"
              (click)="previousStep()"
            >
              BACK
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- END Step 3 -->

  <!-- Create Step 4 -->

  <h1 *ngIf="step == 4" class="form-title">
    {{ 'orders.order-form.title.summary' | lang }}
  </h1>
  <div class="step" *ngIf="step == 4">
    <!-- Order Summary -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <label style="font-weight: bold">
            <div class="list-icon"></div>
            {{ 'orders.order-form.title.summary' | lang }}
          </label>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-xs-1"></div> -->
        <div class="col-xs-10">
          <ul class="list-selected">
            <li *ngFor="let line of orderLineItems; let key = index">
              <div class="list-selected-header">
                <div class="row">
                  <div class="col-xs-12 vendor-wrapper">
                    <label class="vendor-label">
                      {{ line.c_product.c_name + ' - ' }}
                      <span class="ll-blue-text">
                        {{
                          line.c_product.cpy_product_option.c_size +
                            ' | ' +
                            line.c_product.cpy_product_option.c_code +
                            ' | ' +
                            line.c_product.c_hcpcs_code
                        }}
                      </span>
                    </label>
                  </div>

                  <div class="col-xs-2 quantity-wrapper">
                    <label>Quantity</label>
                    <!-- <input type="number" name="{{ 'quantity_' + key }}" [(ngModel)]="line.c_quantity" min="1" step="1" [disabled]="!line.edit" /> -->
                    <p>{{ line.c_quantity }}</p>
                  </div>

                  <div class="col-xs-10 lineitem-wrapper">
                    <div class="row">
                      <div class="col-xs-2">
                        <label>Charge out</label>
                        <!-- <input type="number" name="{{ 'cost_out_' + key }}"  [(ngModel)]="line.c_charge_out_cost" (keyup)="checkProdPrice(line.c_charge_out_cost, key, 'c_charge_out_cost')" min="0" step=".01" [disabled]="!line.edit" />
                          <span class="currency">$</span> -->
                        <p>{{ '$ ' + line.c_charge_out_cost }}</p>
                      </div>
                      <div class="col-xs-3">
                        <label>Patient Payment</label>
                        <!-- <input type="number" name="{{ 'spc_' + key }}" [(ngModel)]="line.c_self_pay_cost" min="0" (keyup)="checkProdPrice(line.c_self_pay_cost, key, 'c_self_pay_cost')" step=".01" [disabled]="!line.edit" />
                          <span class="currency">$</span> -->
                        <p>{{ '$ ' + line.c_self_pay_cost }}</p>
                      </div>
                      <div class="col-xs-2">
                        <label>Allowable</label>
                        <!-- <input type="number" name="{{ 'ac_' + key }}" [(ngModel)]="line.c_allowable_cost" min="0" (keyup)="checkProdPrice(line.c_allowable_cost, key, 'c_allowable_cost')" step=".01" [disabled]="!line.edit" />
                          <span class="currency">$</span> -->
                        <p>{{ '$ ' + line.c_allowable_cost }}</p>
                      </div>
                      <div class="col-xs-2">
                        <label>Sales Tax</label>
                        <!-- <input type="number" name="{{ 'st_' + key }}" [(ngModel)]="line.c_sales_tax" min="0" (keyup)="checkProdPrice(line.c_allowable_cost, key, 'c_allowable_cost')" step=".01" [disabled]="!line.edit" />
                          <span class="currency">$</span> -->
                        <p>{{ '$ ' + line.c_sales_tax }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- Invoice fields -->
                  <div
                    *ngIf="hasRole.admin"
                    class="col-xs-12 invoice-data-wrapper"
                  >
                    <div class="col-xs-2">
                      <p>{{ line.c_invoiced ? 'Invoiced' : 'Not Invoiced' }}</p>
                    </div>
                    <div class="col-xs-8">
                      <p>
                        <span class="black_txt">Invoice Nr.:</span>
                        <span>
                          {{
                            line.c_invoice_number != ''
                              ? line.c_invoice_number
                              : '-'
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
              <div style="clear: both"></div>

              <div class="list-selected-subitem">
                <ul class="list-selected-subitem-list">
                  <li *ngFor="let icd of line.c_icd_10; let keyIcd = index">
                    <label>
                      {{ icd.c_icd_10_code }} - {{ icd.c_description }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Order Summary -->
    <form
      name="step4"
      #st4f="ngForm"
      (ngSubmit)="st4f.form.valid && onSubmit()"
      novalidate
    >
      <!-- Notes -->
      <div class="streach-container">
        <div class="row">
          <div class="col-xs-12">
            <label>
              {{ 'orders.order-form.label.notes' | lang }}*
              <span class="required-span">
                {{ 'forms.error.length' | lang }}
              </span>
            </label>
            <textarea
              name="notes_area"
              [(ngModel)]="order.c_notes"
              [disabled]="notEditable"
              maxlength="1000"
              required
            ></textarea>
          </div>
        </div>
      </div>
      <!-- Patient Signature -->

      <div class="streach-container">
        <div class="row">
          <div class="col-xs-12">
            <label>Patient Signature</label>
            <signature-pad
              [options]="signaturePadOptions"
              (onBeginEvent)="drawStart()"
              (onEndEvent)="drawComplete()"
            ></signature-pad>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <div
              class="button neutral-button clear-button"
              (click)="drawClear()"
            >
              CLEAR
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row">
        <div class="col-xs-12" [hidden]="notEditable">
          <button
            type="submit"
            class="button next-button"
            [hidden]="orderForms.length > 0"
            [disabled]="!st4f.form.valid || disableSubmitButtons"
          >
            {{
              isEditMode
                ? ('orders.order-form.label.save_order' | lang)
                : ('orders.order-form.label.submit_order' | lang)
            }}
          </button>
          <button
            type="submit"
            class="button next-button"
            [hidden]="!orderForms.length > 0"
            [disabled]="!st4f.form.valid"
          >
            {{ 'orders.order-form.label.submit_go_froms' | lang }}
          </button>
          <button
            class="button back-button draft-button"
            type="button"
            [hidden]="orderForms.length > 0"
            [disabled]="!st4f.form.valid"
            (click)="draft('create')"
          >
            {{ 'forms.draft' | lang }}
          </button>
          <button
            type="button"
            class="button back-button"
            (click)="previousStep()"
          >
            BACK
          </button>
          <button class="button back-button" type="button" (click)="cancel()">
            {{ 'forms.cancel' | lang }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <!-- END Step 4 -->

  <!-- END Step 5 -->

  <!-- Create Step6 -->
  <h1 *ngIf="step == 5" class="form-title">
    {{ 'orders.order-form.title.step5' | lang }}
  </h1>
  <div class="step step5" *ngIf="step == 5">
    <form
      *ngIf="mfQuestion.display === true"
      name="step5"
      #st6f="ngForm"
      (ngSubmit)="nextModifierFormQuestion()"
      novalidate
    >
      <h2 style="border: none">
        {{ mfQuestion.inventory }} - {{ mfQuestion.form }}
      </h2>
      <div class="form-row" [ngClass]="{ 'required-input': mfQuestion.error }">
        <label [hidden]="question.c_required">{{ mfQuestion.c_text }}</label>

        <label *ngIf="question.c_required">
          {{ mfQuestion.c_text }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>

        <textarea
          [(ngModel)]="mfQuestion.response"
          name="question_response"
          *ngIf="mfQuestion.c_response_type === 'text'"
          name="answer-text"
        ></textarea>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let oKey = index
            "
          >
            <input
              class="radio-button"
              name="{{ 'option_check_' + oKey }}"
              [(ngModel)]="option.checked"
              type="checkbox"
            />
            <span>{{ option.text }}</span>
          </li>
        </ul>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && !mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let key = index
            "
          >
            <input
              name="radio-{{ key }}"
              class="radio-button"
              [(ngModel)]="mfQuestion.response"
              value="'{{ key }}'"
              type="radio"
            />
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>

      <!-- ModifierForm Signature -->
      <!-- shallDisplaySignaturePad -->
      <div class="row mt13" *ngIf="shalDisplaySignaturePad">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>Patient Signature</label>
          </div>
          <div class="col-xs-12">
            <signature-pad
              [options]="signaturePadOptions"
              (onBeginEvent)="drawStart()"
              (onEndEvent)="drawCompleteMf(modifierForms[mfStepQuestion.form])"
            ></signature-pad>
            <div class="row">
              <div class="col-xs-6 change-sign-buttons-container">
                <div
                  class="button neutral-button"
                  (click)="drawClearMf(modifierForms[mfStepQuestion.form])"
                >
                  CLEAR
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button
          type="submit"
          class="button next-button"
          [disabled]="!st6f.form.valid"
        >
          NEXT
        </button>
        <button
          [hidden]="mfStepQuestion.form == 0 && mfStepQuestion.question == 0"
          type="button"
          class="button back-button"
          (click)="previousModifierFormQuestion()"
        >
          BACK
        </button>
      </div>
    </form>
  </div>

  <!-- END Step 6 -->
</div>

<div class="forms-form" *ngIf="editingOrderForm">
  <h1 class="form-title">{{ 'orders.order-form.title.step5' | lang }}</h1>
  <div class="step step5">
    <form
      *ngIf="question.display === true"
      name="step5"
      #st5f="ngForm"
      (ngSubmit)="nextFormQuestion()"
      novalidate
    >
      <h2 style="border: none">
        {{ question.inventory }} - {{ question.form }}
      </h2>
      <div class="form-row" [ngClass]="{ 'required-input': question.error }">
        <label [hidden]="question.c_required">{{ question.c_text }}</label>

        <label *ngIf="question.c_required">
          {{ question.c_text }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>

        <textarea
          [(ngModel)]="question.response"
          name="question_response"
          *ngIf="question.c_response_type === 'text'"
          name="answer-text"
        ></textarea>

        <ul
          *ngIf="
            question.c_response_type === 'check' && question.c_multi_select
          "
        >
          <li
            *ngFor="let option of question.c_response_options; let oKey = index"
          >
            <input
              class="radio-button ll-custom-checkbox"
              name="{{ 'option_check_' + oKey }}"
              [(ngModel)]="option.checked"
              type="checkbox"
            />
            <span>{{ option.text }}</span>
          </li>
        </ul>

        <ul
          *ngIf="
            question.c_response_type === 'check' && !question.c_multi_select
          "
        >
          <li
            *ngFor="let option of question.c_response_options; let key = index"
          >
            <input
              name="radio-{{ key }}"
              class="radio-button ll-custom-checkbox"
              [(ngModel)]="question.response"
              value="'{{ key }}'"
              type="radio"
            />
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>

      <div class="col-xs-12">
        <button
          type="submit"
          class="button next-button"
          [disabled]="!st5f.form.valid"
        >
          NEXT
        </button>
        <button
          [hidden]="stepQuestion.form == 0 && stepQuestion.question == 0"
          type="button"
          class="button back-button"
          (click)="previousFormQuestion()"
        >
          BACK
        </button>
      </div>
    </form>
  </div>
</div>

<div class="forms-form" *ngIf="editingRuleForm">
  <h1 class="form-title">{{ 'orders.order-form.title.step5' | lang }}</h1>
  <div class="step step5">
    <form
      name="step6"
      #st6f="ngForm"
      (ngSubmit)="nextModifierFormQuestion()"
      novalidate
    >
      <h2 style="border: none">
        {{ mfQuestion.inventory }} - {{ mfQuestion.form }}
      </h2>
      <div class="form-row" [ngClass]="{ 'required-input': mfQuestion.error }">
        <label [hidden]="question.c_required">{{ mfQuestion.c_text }}</label>

        <label *ngIf="question.c_required">
          {{ mfQuestion.c_text }} *
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </label>

        <textarea
          [(ngModel)]="mfQuestion.response"
          name="question_response"
          *ngIf="mfQuestion.c_response_type === 'text'"
          name="answer-text"
        ></textarea>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let oKey = index
            "
          >
            <input
              class="radio-button"
              name="{{ 'option_check_' + oKey }}"
              [(ngModel)]="option.checked"
              type="checkbox"
            />
            <span>{{ option.text }}</span>
          </li>
        </ul>

        <ul
          *ngIf="
            mfQuestion.c_response_type === 'check' && !mfQuestion.c_multi_select
          "
        >
          <li
            *ngFor="
              let option of mfQuestion.c_response_options;
              let key = index
            "
          >
            <input
              name="radio-{{ key }}"
              class="radio-button"
              [(ngModel)]="mfQuestion.response"
              value="'{{ key }}'"
              type="radio"
            />
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>

      <div class="row mt13" *ngIf="shalDisplaySignaturePad">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>Patient Signature</label>
          </div>
          <!-- If has signature and not requested change -->
          <div class="col-xs-12" *ngIf="!changeRuleFormSignature">
            <div class="row">
              <div class="col-xs-12" *ngIf="ruleFormSignature != ''">
                <img [src]="ruleFormSignature" alt="ruleFormSignature" />
              </div>
              <div class="row">
                <div class="col-xs-6 change-sign-buttons-container">
                  <div
                    class="button neutral-button"
                    (click)="enableChangeRFSignature()"
                  >
                    CHANGE
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="changeRuleFormSignature">
            <signature-pad
              [options]="signaturePadOptions"
              (onBeginEvent)="drawStart()"
              (onEndEvent)="drawCompleteMf(modifierForms[mfStepQuestion.form])"
            ></signature-pad>
            <div class="row">
              <div class="col-xs-6 change-sign-buttons-container">
                <div
                  class="button neutral-button"
                  (click)="drawClearMf(modifierForms[mfStepQuestion.form])"
                >
                  CLEAR
                </div>
                <div
                  class="button delete-button"
                  (click)="disableChangeRFSignature()"
                >
                  CANCEL
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button
          type="submit"
          class="button next-button"
          [disabled]="!st6f.form.valid"
        >
          NEXT
        </button>
        <button
          [hidden]="mfStepQuestion.form == 0 && mfStepQuestion.question == 0"
          type="button"
          class="button back-button"
          (click)="previousModifierFormQuestion()"
        >
          BACK
        </button>
      </div>
    </form>
  </div>
</div>
