<!-- show list form -->
<app-location-list
  class="ListContainer"
  [ngClass]="{ hiddenList: somethingToShow }"
></app-location-list>

<div class="forms-form" *ngIf="!somethingToShow">
  <!-- show edit form -->
  <div *ngIf="showEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ location.c_location_name }}</h1>

        <div class="clearfix"></div>
      </div>
    </div>
    <form
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      class="step"
      novalidate
    >
      <div class="container-fluid">
        <!-- Name & Phone -->
        <div class="row">
          <!-- Location Name -->
          <div
            class="col-xs-12 cold-md-6"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <label>
              {{ 'locations.name' | lang }} *
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </label>
            <input
              name="name"
              [(ngModel)]="location.c_location_name"
              type="text"
              required
              [disabled]="notEditable"
            />
          </div>
          <!-- Location Phone -->
          <div class="col-xs-12 col-md-6">
            <label>{{ 'locations.phone_number' | lang }}</label>
            <input
              name="phone_number"
              [(ngModel)]="location.c_phone"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <!-- Street Addresses 1&2 -->
        <div class="row">
          <!-- Street Address 1 -->
          <div class="col-xs-12 col-md-6">
            <label>{{ 'address.street_address_1' | lang }}</label>
            <input
              name="street_address_1"
              [(ngModel)]="location.c_address.c_street_address_1"
              type="text"
              [disabled]="notEditable"
            />
          </div>
          <!-- Street Address 2 -->
          <div class="col-xs-12 col-md-6">
            <label>{{ 'address.street_address_2' | lang }}</label>
            <input
              name="street_address_2"
              [(ngModel)]="location.c_address.c_street_address_2"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <!-- City, State & Zip -->
        <div class="row">
          <!-- City -->
          <div class="col-md-4 col-xs-12">
            <label>{{ 'address.city' | lang }}</label>
            <input
              name="address_city"
              [(ngModel)]="location.c_address.c_city"
              type="text"
              [disabled]="notEditable"
            />
          </div>
          <!-- State -->
          <div class="col-md-4 col-xs-12">
            <label>{{ 'address.state' | lang }}</label>
            <input
              name="address_state"
              [(ngModel)]="location.c_address.c_state"
              type="text"
              [disabled]="notEditable"
            />
          </div>
          <!-- Zip -->
          <div class="col-md-4 col-xs-12">
            <label>{{ 'address.postal_code' | lang }}</label>
            <input
              name="address_postal_code"
              [(ngModel)]="location.c_address.c_postal_code"
              type="text"
              [disabled]="notEditable"
            />
          </div>
        </div>
        <hr />
        <!-- Log -->
        <div class="row">
          <div class="col-xs-12 col-md-6 text-center">
            <div
              class="fileUpload button back-button left-side-button clinic-logo-btn"
            >
              <span>{{ 'locations.select.logo' | lang }}</span>
              <input
                type="file"
                class="upload"
                id="file"
                (change)="handleFileInput($event)"
              />
            </div>
            <button
              class="button delete-button delete-clinic-logo-btn"
              type="button"
              (click)="deleteImage()"
              *ngIf="image"
              style="float: right"
            >
              &times;
            </button>
            <label *ngIf="image" class="left-block">
              {{ 'locations.selected.logo' | lang }}
            </label>
            <img
              [src]="imageUrl"
              *ngIf="image"
              style="width: 150px"
              class="left-block"
            />
          </div>
        </div>
        <!-- Providers Section - Search Input & List -->
        <div class="row">
          <div
            class="col-xs-12 col-md-6"
            [ngClass]="{
              'required-input':
                f.form.invalid && (f.form.dirty || f.form.touched)
            }"
          >
            <h3>
              {{ 'locations.related_providers' | lang }}
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </h3>
            <!-- Providers Autocomplete -->
            <div class="autocomplete-search">
              <input
                name="searchProviders"
                id="autocomplete_providers"
                type="text"
                class="highlight"
                [(ngModel)]="providersAutocomplete"
                [ngbTypeahead]="searchProviders"
                [resultTemplate]="autocompleteResultsProviders"
                (ngModelChange)="onProviderSelected()"
                placeholder="{{ 'locations.search_provider' | lang }}"
                [inputFormatter]="formatterProviders"
              />
              <ng-template
                #autocompleteResultsProviders
                let-r="result"
                let-t="term"
              >
                {{ r.c_preffix }} {{ r.c_name.c_first_name }}
                {{ r.c_name.c_last_name }} {{ r.c_suffix }}
              </ng-template>
            </div>
          </div>
          <!-- Providers List -->
          <div class="col-xs-12 col-md-6" style="padding-top: 50px">
            <ul class="inventory-table">
              <div *ngFor="let provider of providersList">
                <li *ngIf="!provider.deleted">
                  {{
                    provider.c_physician.c_preffix
                      ? provider.c_physician.c_preffix + '. '
                      : ' '
                  }}{{ provider.c_physician.c_name.c_first_name }}
                  {{ provider.c_physician.c_name.c_last_name }}
                  {{
                    provider.c_physician.c_suffix
                      ? ', ' + provider.c_physician.c_suffix
                      : ' '
                  }}
                  <button
                    *ngIf="hasRole.admin"
                    type="button"
                    class="delete-trash pull-right"
                    (click)="removeProvider(provider)"
                  >
                    <div></div>
                  </button>
                  <div class="clearfix"></div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <!-- Fitters Section - Search Input & List -->
        <div class="row">
          <!-- <div class="col-xs-12 col-md-6" [ngClass]="{'required-input': f.form.invalid && (f.form.dirty || f.form.touched)}"> -->
          <div class="col-xs-12 col-md-6">
            <h3>
              {{ 'locations.related_fitters' | lang }}
              <span class="required-span">
                {{ 'forms.error.required' | lang }}
              </span>
            </h3>
            <!-- Fitters Autocomplete -->
            <div class="autocomplete-search">
              <input
                name="searchFitters"
                id="autocomplete_fitters"
                type="text"
                class="highlight"
                [(ngModel)]="fittersAutocomplete"
                [ngbTypeahead]="searchFitters"
                [resultTemplate]="autocompleteResultsFitters"
                (ngModelChange)="onFitterSelected()"
                placeholder="{{ 'locations.search_fitter' | lang }}"
                [inputFormatter]="formatterFitters"
              />
              <ng-template
                #autocompleteResultsFitters
                let-r="result"
                let-t="term"
              >
                {{ r.name.first }} {{ r.name.last }} {{ r.email }}
              </ng-template>
            </div>
          </div>
          <!-- Fitter List -->
          <div class="col-xs-12 col-md-6" style="padding-top: 50px">
            <ul class="inventory-table">
              <div *ngFor="let fitter of fittersList">
                <li *ngIf="!fitter.deleted">
                  {{ fitter.c_fitter.name.first }}
                  {{ fitter.c_fitter.name.last }}
                  <br />
                  {{ fitter.c_fitter.email }}
                  <button
                    type="button"
                    *ngIf="hasRole.admin"
                    class="delete-trash pull-right"
                    (click)="removeFitter(fitter)"
                  >
                    <div></div>
                  </button>
                  <div class="clearfix"></div>
                </li>
              </div>
            </ul>
          </div>
        </div>

        <!-- Insurances Section - Search Input & List -->
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <h3>{{ 'locations.related_insurances' | lang }}</h3>
            <!-- Insurances Autocomplete -->
            <div class="autocomplete-search">
              <input
                name="searchInsurances"
                id="autocomplete_insurances"
                type="text"
                class="highlight"
                [(ngModel)]="insurancesAutocomplete"
                [ngbTypeahead]="searchInsurances"
                [resultTemplate]="autocompleteResultsInsurances"
                placeholder="{{ 'locations.search_insurance' | lang }}"
                [inputFormatter]="formatterInsurances"
                (focusout)="insuranceFocusout()"
              />
              <ng-template
                #autocompleteResultsInsurances
                let-r="result"
                let-t="term"
              >
                <li (click)="insuranceSelected(r)">{{ r.c_insurance_name }}</li>
              </ng-template>
            </div>
          </div>
          <!-- Insurances List -->
          <div class="col-xs-12 col-md-6" style="padding-top: 50px">
            <ul class="inventory-table">
              <div *ngFor="let insurance of insurancesList; let idx = index">
                <li *ngIf="!insurance.deleted">
                  {{ insurance.c_insurance_name }}
                  <button
                    type="button"
                    *ngIf="hasRole.admin"
                    class="delete-trash pull-right"
                    (click)="removeInsurance(insurance)"
                  >
                    <div></div>
                  </button>
                  <div class="clearfix"></div>
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12" *ngIf="notEditable">
            <button
              class="button next-button"
              type="submit"
              [disabled]="!f.form.valid"
            >
              {{
                isEditMode
                  ? ('locations.save' | lang)
                  : ('locations.submit' | lang)
              }}
            </button>
            <button class="button back-button" type="button" (click)="cancel()">
              {{ 'forms.cancel' | lang }}
            </button>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="locationsSubmited">
      <p>{{ 'locations.submited' | lang }}</p>
    </div>
  </div>
  <!-- show view form -->
  <div *ngIf="!showEditForm">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ location.c_location_name }}</h1>
        <div class="pull-right" *ngIf="notEditable">
          <button
            *ngIf="hasRole.admin || hasRole.executive || hasRole.site_manager"
            class="button edit-button"
            (click)="goToEditLocation()"
          >
            {{ 'locations.edit' | lang }}
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <div class="row">
      <div class="first-info-container">
        <ul>
          <li>
            <label>{{ 'locations.title' | lang }}</label>
          </li>
          <li>
            <label>{{ 'address.street_address_1' | lang }}</label>
            <p>{{ location.c_address.c_street_address_1 }}</p>
          </li>
          <li>
            <label>{{ 'address.city' | lang }}</label>
            <p>{{ location.c_address.c_city }}</p>
          </li>
          <li>
            <label>{{ 'address.state' | lang }}</label>
            <p>{{ location.c_address.c_state }}</p>
          </li>
          <li>
            <label>{{ 'address.postal_code' | lang }}</label>
            <p>{{ location.c_address.c_postal_code }}</p>
          </li>
          <li>
            <label>{{ 'locations.phone_number' | lang }}</label>
            <p>{{ location.c_phone }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- Related Providers & Fitters -->
    <div class="row">
      <!-- Related Providers Section -->
      <div class="col-xs-12 col-md-6">
        <h3>{{ 'locations.related_providers' | lang }}</h3>
        <ul class="inventory-table">
          <li *ngFor="let provider of providersList">
            {{
              provider.c_physician.c_preffix
                ? provider.c_physician.c_preffix + '. '
                : ' '
            }}{{ provider.c_physician.c_name.c_first_name }}
            {{ provider.c_physician.c_name.c_last_name }}
            {{
              provider.c_physician.c_suffix
                ? ', ' + provider.c_physician.c_suffix
                : ' '
            }}
          </li>
        </ul>
      </div>
      <!-- Related Fitters Section -->
      <div class="col-xs-12 col-md-6">
        <h3>{{ 'locations.related_fitters' | lang }}</h3>
        <ul class="inventory-table">
          <li *ngFor="let fitter of fittersList">
            {{ fitter.c_fitter.name.first }} {{ fitter.c_fitter.name.last }}
            <br />
            {{ fitter.c_fitter.email }}
          </li>
        </ul>
      </div>
      <!-- Related Insurances Section -->
      <div class="col-xs-12 col-md-6">
        <h3>{{ 'locations.related_insurances' | lang }}</h3>
        <ul class="inventory-table">
          <li *ngFor="let insurance of insurancesList">
            {{ insurance.c_insurance_name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="row second-info-container">
      <div class="col-xs-12">
        <div>
          <label class="product-options"><div></div></label>
          <h3>{{ 'locations.stock' | lang }}</h3>
        </div>
      </div>

      <div class="search-container col-xs-12 col-lg-8">
        <input
          [(ngModel)]="searchStock"
          class="search-input"
          placeholder="search by product option code"
          (keyup)="$event.keyCode == 13 && getStocks(searchStock)"
        />
        <button
          type="button"
          class="btn-link search-button"
          (click)="getStocks(searchStock)"
        >
          <span class="glyphicon glyphicon-search"></span>
        </button>
      </div>

      <div class="col-xs-12 col-lg-8">
        <div class="list-selected-header">
          <div class="row">
            <div class="col-xs-4 inventory-list-label">
              <label>Product</label>
            </div>
            <div class="col-xs-4 inventory-list-label">
              <label>Product Option Code</label>
            </div>
            <div class="col-xs-4 inventory-list-label">
              <label>Stock</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-8">
        <ul class="inventory-table full-width">
          <li *ngFor="let stock of stocks">
            <label class="info">
              {{ stock.c_product.c_name }}
            </label>
            <label class="info">
              {{ stock.c_product_option_code }}
            </label>
            <label class="info">
              {{ stock.c_stock }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- show index form -->
<div class="forms-form" *ngIf="somethingToShow">
  <button
    *ngIf="hasRole.admin"
    type="button"
    class="button create-button"
    (click)="updateLocations()"
  >
    {{ 'locations.location-form-update.title' | lang }}
  </button>
</div>
