<div class="forms-form" style="width: calc(100% - 30px)">
  <div class="row">
    <div class="col-xs-12">
      <h1>Reports</h1>
      <div class="pull-right">
        <button
          type="button"
          class="button edit-button"
          (click)="exportDashboardItems()"
          [disabled]="formattedLineItems.length == 0"
        >
          {{ 'dashboard.export' | lang }}
        </button>
      </div>
    </div>
  </div>

  <!-- Orders Form -->
  <form
    name="dashboard"
    #dashboard="ngForm"
    (ngSubmit)="dashboard.form.valid && getTableItems()"
    novalidate
  >
    <!-- Order Date Filters  -->
    <div class="row">
      <div class="col-xs-12">
        <label>{{ 'dashboard.dos_label' | lang }}</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min date"
            angular-mydatepicker
            name="minDate"
            [(ngModel)]="minDateModel"
            [options]="dpOptions"
            #dpMin="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMin.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <div class="required-container">
          <span class="required-span">
            {{ 'forms.error.required' | lang }}
          </span>
        </div>
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max date"
            angular-mydatepicker
            name="maxDate"
            [(ngModel)]="maxDateModel"
            [options]="dpOptions"
            #dpMax="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMax.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Billed Date Filters -->

    <div class="row ll-mt-20">
      <div class="col-xs-12">
        <label>Billed Date Range:</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min (Billed) date"
            angular-mydatepicker
            name="minBilledDate"
            [(ngModel)]="minBilledDateModel"
            [options]="dpOptions"
            #dpMinB="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinB.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinB.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max (Billed) date"
            angular-mydatepicker
            name="maxBilledDate"
            [(ngModel)]="maxBilledDateModel"
            [options]="dpOptions"
            #dpMaxB="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxB.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxB.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Deleted Date Filters -->

    <div class="row ll-mt-20">
      <div class="col-xs-12">
        <label>Deleted Date Range:</label>
      </div>

      <!-- Date Input - minDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select min (Deleted) date"
            angular-mydatepicker
            name="minDeleteDate"
            [(ngModel)]="minDeletedDateModel"
            [options]="dpOptions"
            #dpMinD="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinD.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMinD.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>

      <!-- Date Input - maxDate -->
      <div class="col-xs-12 col-sm-5 col-md-3">
        <!-- Datepicker Input -->
        <div class="input-group datepicker-group">
          <input
            class="form-control datepicker-input"
            style="float: none"
            placeholder="Select max (Deleted) date"
            angular-mydatepicker
            name="maxDeleteDate"
            [(ngModel)]="maxDeletedDateModel"
            [options]="dpOptions"
            #dpMaxD="angular-mydatepicker"
          />

          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxD.clearDate()"
            >
              <i class="glyphicon glyphicon-remove"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="dpMaxD.toggleCalendar()"
            >
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Location Filter Listing -->
    <div class="row">
      <!-- Location  -->
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Location List:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="col-xs-12 ll-item-list-container">
              <div
                class="ll-list-item"
                *ngFor="let locationItem of locationItems; let i = index"
              >
                <div class="ll-list-item-text">
                  {{ locationItem.c_company }}
                </div>
                <div
                  class="ll-list-item-delete"
                  (click)="removeLocationItem(i)"
                >
                  x
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Location & Insurance -->
    <div class="row">
      <!-- Location  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Location:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
            <input
              type="text"
              name="searchLocation"
              placeholder="Select Location"
              id="autocomplete_location"
              class="highlight"
              [ngModel]="selectedLocation"
              (focus)="locationInputFocus()"
              (focusout)="locationInputFocusOut()"
              [ngbTypeahead]="searchLocationWrapper()"
              [resultTemplate]="autocompleteLocationList"
              [inputFormatter]="locationFormatter"
            />
            <ng-template #autocompleteLocationList let-r="result" let-t="term">
              <li (click)="selectLocation(r)">{{ r.c_company }}</li>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- Insurance  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Insurance:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
            <input
              type="text"
              name="searchInsurance"
              placeholder="Select Insurance"
              id="autocomplete_insurance"
              class="highlight"
              [ngModel]="selectedInsurance"
              (focus)="insuranceInputFocus()"
              (focusout)="insuranceInputFocusOut()"
              [ngbTypeahead]="searchInsuranceWrapper()"
              [resultTemplate]="autocompleteInsuranceList"
              [inputFormatter]="insuranceFormatter"
            />
            <ng-template #autocompleteInsuranceList let-r="result" let-t="term">
              <li (click)="selectInsurance(r)">{{ r.c_insurance_name }}</li>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- Creator & Fiter -->
    <div class="row">
      <!-- Creator  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Creator:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
            <input
              type="text"
              name="searchOrderCretor"
              placeholder="Select User"
              id="autocomplete_order_creator"
              class="highlight"
              [ngModel]="selectedCreator"
              (focus)="userInputFocus()"
              (focusout)="userInputFocusOut()"
              [ngbTypeahead]="searchCreatorWrapper()"
              [resultTemplate]="autocompleteCreatorList"
              [inputFormatter]="creatorFormatter"
            />
            <ng-template
              #autocompleteCreatorList
              class="scrollable-autotomplete-list"
              let-r="result"
              let-t="term"
            >
              <li (click)="selectUser(r)">
                {{ r.firstName + ' ' + r.lastName }}
                <br />
                <span class="small-text">{{ '(' + r.email + ')' }}</span>
              </li>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- Fitter  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Fitter:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
            <input
              type="text"
              name="searchFitter"
              placeholder="Select Fitter"
              id="autocomplete_fitter"
              class="highlight"
              [ngModel]="selectedFitter"
              (focus)="fitterInputFocus()"
              (focusout)="fitterInputFocusOut()"
              [ngbTypeahead]="searchFitterWrapper()"
              [resultTemplate]="autocompleteFitterList"
              [inputFormatter]="fitterFormatter"
            />
            <ng-template #autocompleteFitterList let-r="result" let-t="term">
              <li (click)="selectFitter(r)">
                {{ r.firstName + ' ' + r.lastName }}
                <br />
                <span class="small-text">{{ '(' + r.email + ')' }}</span>
              </li>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- HCPCS & SKU -->
    <div class="row">
      <!-- HCPCS  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>HCPCS:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 hcpcs_autocomplete resp-autocomplete">
            <input
              type="text"
              name="searchHcpcs"
              placeholder="Select HCPCS"
              id="autocomplete_hcpcs"
              class="highlight"
              [ngModel]="selectedHcpcs"
              (focus)="hcpcsInputFocus()"
              (focusout)="hcpcsInputFocusOut()"
              [ngbTypeahead]="searchHcpcsWrapper()"
              [resultTemplate]="autocompleteHcpcsList"
              [inputFormatter]="hcpcsFormatter"
            />
            <ng-template #autocompleteHcpcsList let-r="result" let-t="term">
              <li (click)="selectHcpcs(r)">{{ r.c_hcpcs_code }}</li>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- SKU  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>SKU:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <input
              type="text"
              [(ngModel)]="sku"
              placeholder="Enter SKU"
              name="sku_input"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Status & query Running -->
    <div class="row">
      <!-- Status  -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Status:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="selectedStatus" name="statusDropdown">
              <option *ngFor="let fs of filterStatuses" [ngValue]="fs">
                {{ fs }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Deleted Status -->
      <!-- <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>(Order) Delete Status:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="selectedDeleteStatus" name="deleteStatusDropdown">
              <option *ngFor="let fs of filterDeletedStatuses" [ngValue]="fs">{{fs}}</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>

    <!-- Item Deleted State -->
    <!-- <div class="row">
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>(Item) Delete Status:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select [(ngModel)]="selectedDeleteStatusItem" name="deleteStatusItemDropdown">
              <option *ngFor="let fs of filterDeletedStatusesItem" [ngValue]="fs">{{fs}}</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Chunck Size & Query Running -->
    <div class="row">
      <!-- Chunck Size -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Chunk Size:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <input
              type="number"
              [(ngModel)]="chunckSizeInput"
              name="chunckSizeInput"
            />
          </div>
        </div>
      </div>
      <!-- Query Running -->
      <div class="col-sm-6 col-md-3">
        <div class="row">
          <div class="col-xs-12 ll-mt-20">
            <label>Loading Mode:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <select
              [(ngModel)]="selectedLoadingMode"
              name="loadingModeDropdown"
            >
              <option *ngFor="let fs of filterLoadingMode" [ngValue]="fs">
                {{ fs }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Progress Loading Indicator -->
    <div class="rep-prg-loading-container" *ngIf="displayProcessLoading">
      <div class="prg-row">
        <!-- Displayed Text -->

        <div class="rep-prg-loading-text">
          <div class="rep-prg-text-row">{{ prgOrderText }}</div>
          <div class="rep-prg-text-row">{{ prgOrderItemsText }}</div>
          <!-- <div class="rep-prg-text-row">{{prgRemainingTimeText}}</div> -->
        </div>

        <!-- Spinner Container -->
        <div class="rep-prg-spinner-container">
          <!-- Spinner -->
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <div class="prg-row">
        <!-- Progress Bar Container -->
        <div class="rep-prg-progress-bar-container">
          <!-- Progress Bar Outer -->
          <div class="rep-prg-progress-bar-outer">
            <!-- Progress Bar Inner -->
            <div
              class="rep-prg-progress-bar-inner"
              [ngStyle]="{ width: prgBarPercentage + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Button -->
    <div class="row">
      <div class="col-xs-12 col-sm-2 col-md-2">
        <button
          type="submit"
          class="button next-button ll-ml-0 ll-no-float"
          [ngClass]="{ 'small-txt': !icdsLoaded }"
          [disabled]="!searchFormValid() || !icdsLoaded"
        >
          {{ icdsLoaded ? 'SEARCH' : 'Please wait...' }}
        </button>
      </div>
    </div>
  </form>

  <!-- Bulk Update Modal -->
  <ng-template #content let-modal>

        <div class="popup-content">
          <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.close()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Bulk Update</h4>
          </div>
          <div *ngIf="!processingBulkUpdate" class="modal-body" style="overflow-x: auto">

            <div class="row separate-top">
              <ul class="reporting-table">
                <li>
                  <ul class="ll-table-header">
                    <li class="rt-order-nr">{{ 'dashboard.order' | lang }}</li>

                    <li class="rt-status">{{ 'dashboard.status' | lang }}</li>

                    <li class="rt-billed_date">{{ 'dashboard.billed_date' | lang }}</li>
                    <li class="rt-claim-id">{{ 'dashboard.claim_id' | lang }}</li>

                    <li class="rt-system-date">{{ 'dashboard.system_date' | lang }}</li>
                    <li class="rt-order-date">{{ 'dashboard.date' | lang }}</li>

                    <li class="rt-patient-mrn">{{ 'dashboard.patient_mrn' | lang }}</li>
                    <li class="rt-patient-name">{{ 'dashboard.patient_name' | lang }}</li>
                    <li class="rt-creator-name">{{ 'dashboard.creator.name' | lang }}</li>
                    <li class="rt-fitter-name">{{ 'dashboard.fitter.name' | lang }}</li>
                    <li class="rt-billing-provider">
                      {{ 'dashboard.billingProvider.name' | lang }}
                    </li>

                    <li class="rt-ord-provider">
                      {{ 'orders.order-form.label.orderingProvider' | lang }}
                    </li>
                    <li class="rt-location">{{ 'dashboard.location' | lang }}</li>
                    <li class="rt-p-ins">{{ 'dashboard.primary_insurance' | lang }}</li>
                    <li class="rt-p-ins-id">
                      {{ 'dashboard.primary_insurance_id' | lang }}
                    </li>
                    <li class="rt-s-ins">{{ 'dashboard.secondary_insurance' | lang }}</li>
                    <li class="rt-s-ins-id">
                      {{ 'dashboard.secondary_insurance_id' | lang }}
                    </li>
                    <li class="rt-order-id">orderID</li>

                    <li class="rt-last-modified">
                      {{ 'dashboard.lastModified' | lang }}
                    </li>
                    <li class="rt-last-modified-by">
                      {{ 'dashboard.last_modified_by' | lang }}
                    </li>
                    <li class="rt-cin7-ref">{{ 'dashboard.cin7_ref' | lang }}</li>
                  </ul>
                </li>
                <li>

                  <!-- Datepicker Input -->
                    <div class="input-group datepicker-group" style="width: 194px; margin: 10px 0 10px 180px;">
                      <input
                        class="form-control datepicker-input"
                        style="float: none"
                        placeholder="Select date for all"
                        angular-mydatepicker
                        name="displayBilledDate"
                        (dateChanged)="bulkChangeAllBilledDates($event)"
                        [options]="dpOptions"
                        #dpMax="angular-mydatepicker"
                      />
                      <span class="input-group-btn">
                        <button
                          type="button"
                          class="btn btn-default"
                          (click)="dpMax.clearDate()"
                        >
                          <i class="glyphicon glyphicon-remove"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          (click)="dpMax.toggleCalendar()"
                        >
                          <i class="glyphicon glyphicon-calendar"></i>
                        </button>
                      </span>
                    </div>
                  </li>

                  <li
                    *ngFor="let order of bulkUpdateOrders"
                  >
                    <ul class="rt-value-list">

                      <li
                        (click)="goToViewOrder(order.id)"
                        class="rt-order-nr clickable-item"
                      >{{ order.numberId }}</li>
                      <li class="rt-status">{{ order.status }}</li>

                      <li class="rt-billed_date" style="width: 170px;">

                        <!-- Datepicker Input -->
                        <div class="input-group datepicker-group" style="margin-right: 15px">
                          <input
                            class="form-control datepicker-input"
                            style="float: none"
                            placeholder="Select date"
                            angular-mydatepicker
                            name="displayBilledDate"
                            [(ngModel)]="order.billed_date_obj"
                            [options]="dpOptions"
                            #dpMax="angular-mydatepicker"
                          />
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-default"
                              (click)="dpMax.clearDate()"
                            >
                              <i class="glyphicon glyphicon-remove"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-default"
                              (click)="dpMax.toggleCalendar()"
                            >
                              <i class="glyphicon glyphicon-calendar"></i>
                            </button>
                          </span>
                        </div>
                      </li>

                      <li class="rt-claim-id">
                        <input name="claim_id" [(ngModel)]="order.newClaimId" type="text" />
                      </li>

                      <li class="rt-system-date">{{ order.systemDate }}</li>
                      <li class="rt-order-date">{{ order.display_order_date }}</li>
                      <li
                        (click)="goToViewPatient(order.patient.id)"
                        class="rt-patient-mrn clickable-item"
                      >{{ order.patient.mrn }}</li>
                      <li
                        (click)="goToViewPatient(order.patient.id)"
                        class="rt-patient-name clickable-item"
                      >{{ order.patient.fullName }}</li>
                      <li class="rt-creator-name">{{ order.creatorName }}</li>
                      <li class="rt-fitter-name">{{ order.fitterName }}</li>
                      <li class="rt-billing-provider">
                        {{ order.billingProviderName }}
                      </li>

                      <li class="rt-ord-provider">{{ order.providerName }}</li>
                      <li class="rt-location">{{ order.providerLocation }}</li>
                      <li class="rt-p-ins">{{ order.primaryInsurance }}</li>
                      <li class="rt-p-ins-id">{{ order.primaryInsId }}</li>
                      <li class="rt-s-ins">{{ order.secondaryInsurance }}</li>
                      <li class="rt-s-ins-id">{{ order.secondaryInsId }}</li>
                      <li class="rt-order-id">{{ order.id }}</li>

                      <li class="rt-last-modified">{{ order.lastModified }}</li>
                      <li class="rt-last-modified-by">{{ order.lastModifiedBy }}</li>
                      <li class="rt-cin7-ref">{{ order.cin7Ref || '-' }}</li>
                    </ul>
                  </li>

              </ul>
            </div>

          </div>
          <div *ngIf="processingBulkUpdate" class="modal-body">
            <div class="alert alert-warning">
              {{ processingBulkUpdate }}
            </div>
          </div>
          <div class="modal-footer" *ngIf="!processingBulkUpdate">
            <div class="row full-width" style="display: flex; justify-content: flex-end">
              <button class="button back-button" type="button" (click)="modal.close()">
                {{ 'forms.cancel' | lang }}
              </button>
              <button
                type="button"
                class="button next-button"
                [disabled]="disableBulkUpdateButton()"
                (click)="updateOrders(modal)"
              >
                {{ 'dashboard.update_orders' | lang }}
              </button>
            </div>

          </div>
        </div>

  </ng-template>

  <!-- Bulk Update Button -->
  <div class="row">
    <button
      type="button"
      class="button edit-button"
      (click)="openModal(content)"
      [disabled]="bulkUpdateOrders.length == 0"
    >
      {{ 'dashboard.bulk_update' | lang }}
    </button>
  </div>

  <!-- END Orders Form -->

  <div class="row separate-top">
    <ul class="reporting-table">
      <li>
        <ul class="ll-table-header">
          <li class="rt-order-check">{{ 'dashboard.check' | lang }}</li>
          <li class="rt-order-nr">{{ 'dashboard.order' | lang }}</li>
          <li class="rt-system-date">{{ 'dashboard.system_date' | lang }}</li>
          <li class="rt-order-date">{{ 'dashboard.date' | lang }}</li>
          <li class="rt-status">{{ 'dashboard.status' | lang }}</li>
          <li class="rt-delete-reason">
            {{ 'dashboard.delete_reason' | lang }}
          </li>
          <li class="rt-delete-date">{{ 'dashboard.delete_date' | lang }}</li>
          <li class="rt-billed_date">{{ 'dashboard.billed_date' | lang }}</li>
          <li class="rt-claim-id">{{ 'dashboard.claim_id' | lang }}</li>
          <li class="rt-patient-mrn">{{ 'dashboard.patient_mrn' | lang }}</li>
          <li class="rt-patient-name">{{ 'dashboard.patient_name' | lang }}</li>
          <li class="rt-creator-name">{{ 'dashboard.creator.name' | lang }}</li>
          <li class="rt-fitter-name">{{ 'dashboard.fitter.name' | lang }}</li>
          <li class="rt-billing-provider">
            {{ 'dashboard.billingProvider.name' | lang }}
          </li>
          <li class="rt-hcpcs">{{ 'dashboard.hcpcs' | lang }}</li>
          <li class="rt-item-name">{{ 'dashboard.item_name' | lang }}</li>
          <li class="rt-poc">{{ 'dashboard.product_option_code' | lang }}</li>
          <li class="rt-qty">{{ 'dashboard.quantity' | lang }}</li>
          <li class="rt-size">{{ 'dashboard.size' | lang }}</li>
          <li class="rt-vendor">{{ 'dashboard.vendor' | lang }}</li>
          <li class="rt-ord-provider">
            {{ 'orders.order-form.label.orderingProvider' | lang }}
          </li>
          <li class="rt-location">{{ 'dashboard.location' | lang }}</li>
          <li class="rt-p-ins">{{ 'dashboard.primary_insurance' | lang }}</li>
          <li class="rt-p-ins-id">
            {{ 'dashboard.primary_insurance_id' | lang }}
          </li>
          <li class="rt-s-ins">{{ 'dashboard.secondary_insurance' | lang }}</li>
          <li class="rt-s-ins-id">
            {{ 'dashboard.secondary_insurance_id' | lang }}
          </li>
          <li class="rt-chrg-out-cost">
            {{ 'dashboard.charge_out_cost' | lang }}
          </li>
          <li class="rt-allow-cost">{{ 'dashboard.allowable_cost' | lang }}</li>
          <li class="rt-patient-payment">
            {{ 'dashboard.patient_payment' | lang }}
          </li>
          <li class="rt-sales-tax">{{ 'dashboard.sales_tax' | lang }}</li>
          <li class="rt-modifiers">{{ 'dashboard.modifiers' | lang }}</li>
          <li class="rt-warnings">{{ 'dashboard.warnings' | lang }}</li>
          <li class="rt-icdcs">{{ 'dashboard.icds' | lang }}</li>
          <li class="rt-order-id">orderID</li>
          <li class="rt-item-id">itemId</li>
          <li class="rt-invoiced">Invoiced</li>
          <li class="rt-invoice-nr">Invoice Number</li>
          <li class="rt-last-modified">
            {{ 'dashboard.lastModified' | lang }}
          </li>
          <li class="rt-last-modified-by">
            {{ 'dashboard.last_modified_by' | lang }}
          </li>
          <li class="rt-cin7-ref">{{ 'dashboard.cin7_ref' | lang }}</li>
        </ul>
      </li>
      <div *ngIf="lineItemsPage.length">
        <li
          *ngFor="let line of lineItemsPage"
        >
          <ul class="rt-value-list">
            <li
              class="rt-order-check"
            >
              <input
                type="checkbox"
                [checked]="selectedForBulk(line.order.id)"
                (change)="selectOrderForBulkUpdate($event, line.order)"
              /></li>
            <li
              (click)="goToViewOrder(line.order.id)"
              class="rt-order-nr clickable-item"
            >{{ line.order.numberId }}</li>
            <li class="rt-system-date">{{ line.order.systemDate }}</li>
            <li class="rt-order-date">{{ line.order.display_order_date }}</li>
            <li class="rt-status">{{ line.order.status }}</li>
            <li class="rt-delete-reason">
              {{ line.order.delete_reason || line.delete_reason }}
            </li>
            <li class="rt-delete-date">
              {{ line.order.deleteDate || line.deleteDate }}
            </li>
            <li class="rt-billed_date">{{ line.order.display_billed_date }}</li>
            <li class="rt-claim-id">{{ line.order.claimId }}</li>
            <li
              (click)="goToViewPatient(line.order.patient.id)"
              class="rt-patient-mrn clickable-item"
            >{{ line.order.patient.mrn }}</li>
            <li
              (click)="goToViewPatient(line.order.patient.id)"
              class="rt-patient-name clickable-item"
            >{{ line.order.patient.fullName }}</li>
            <li class="rt-creator-name">{{ line.order.creatorName }}</li>
            <li class="rt-fitter-name">{{ line.order.fitterName }}</li>
            <li class="rt-billing-provider">
              {{ line.order.billingProviderName }}
            </li>
            <li class="rt-hcpcs">
              {{ line.selectedHcpcs || line.product.hcpcs }}
            </li>
            <li class="rt-item-name">{{ line.product.name }}</li>
            <li class="rt-poc">{{ line.product.optionCode }}</li>
            <li class="rt-qty">{{ line.quantity }}</li>
            <li class="rt-size">{{ line.product.size }}</li>
            <li class="rt-vendor">{{ line.product.vendor }}</li>
            <li class="rt-ord-provider">{{ line.order.providerName }}</li>
            <li class="rt-location">{{ line.order.providerLocation }}</li>
            <li class="rt-p-ins">{{ line.order.primaryInsurance }}</li>
            <li class="rt-p-ins-id">{{ line.order.primaryInsId }}</li>
            <li class="rt-s-ins">{{ line.order.secondaryInsurance }}</li>
            <li class="rt-s-ins-id">{{ line.order.secondaryInsId }}</li>
            <li class="rt-chrg-out-cost">{{ '$' + line.chargeOutCost }}</li>
            <li class="rt-allow-cost">{{ '$' + line.allowableCost }}</li>
            <li class="rt-patient-payment">{{ '$' + line.selfPay }}</li>
            <li class="rt-sales-tax">{{ '$' + line.salesTax }}</li>
            <li class="rt-modifiers">{{ line.modifiers }}</li>
            <li class="rt-warnings">{{ line.warnings }}</li>
            <li class="rt-icdcs">{{ line.icds }}</li>
            <li class="rt-order-id">{{ line.order.id }}</li>
            <li class="rt-item-id">{{ line.itemId }}</li>
            <li class="rt-invoiced">{{ line.invoiced }}</li>
            <li class="rt-invoice-nr">{{ line.invoiceNumber }}</li>
            <li class="rt-last-modified">{{ line.order.lastModified }}</li>
            <li class="rt-last-modified-by">{{ line.order.lastModifiedBy }}</li>
            <li class="rt-cin7-ref">{{ line.order.cin7Ref || '-' }}</li>
          </ul>
        </li>
      </div>

      <li
        *ngIf="!orderLineItemsFilter.length && lineItemsLoaded"
        class="ll-pp-background"
      >
        <label>{{ 'dashboard.no_results' | lang }}</label>
      </li>
    </ul>
  </div>

  <div class="row paginator" *ngIf="gPages > 0">
    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="prevPage()"
      [disabled]="actualPage == 1"
    >
      <i class="glyphicon glyphicon-chevron-left"></i>
    </button>

    <ul>
      <div *ngIf="gPageList.length <= 7">
        <div *ngFor="let n of gPageList; let i = index" class="ll-page-number">
          <li
            [ngClass]="{ active: i == actualPage - 1 }"
            class="pagination-numbers"
            (click)="loadPage(i + 1)"
          >
            {{ i + 1 }}
          </li>
        </div>
      </div>

      <div *ngIf="gPageList.length > 7">
        <li
          [ngClass]="{ active: actualPage == 1 }"
          class="pagination-numbers"
          (click)="loadPage(1)"
        >
          {{ 1 }}
        </li>

        <li class="dots" *ngIf="actualPage >= 4">...</li>

        <span *ngFor="let n of gPageList; let i = index">
          <li
            *ngIf="
              i + 1 - actualPage <= 2 &&
              actualPage - (i + 1) <= 2 &&
              i + 1 != 1 &&
              i + 1 != gPageList.length
            "
            [ngClass]="{ active: i + 1 == actualPage }"
            class="pagination-numbers"
            (click)="loadPage(i + 1)"
          >
            {{ i + 1 }}
          </li>
        </span>

        <li class="dots" *ngIf="gPageList.length - actualPage > 5">...</li>

        <li
          [ngClass]="{ active: actualPage == gPageList.length }"
          class="pagination-numbers"
          (click)="loadPage(gPageList.length)"
        >
          {{ gPageList.length }}
        </li>
      </div>
    </ul>

    <button
      type="button"
      class="btn btn-default btn-sm paginator-buttons"
      (click)="nextPage()"
      [disabled]="actualPage == gPageList.length"
    >
      <i class="glyphicon glyphicon-chevron-right"></i>
    </button>
  </div>
</div>
