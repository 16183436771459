<div class="col-xs-12 text-center">
  <h3>CHANGE EXTERNAL ACCESS PASSWORD</h3>
</div>

<div class="col-xs-0 col-md-3"></div>
<div class="col-xs-12 col-md-6">
  <form name="form" (ngSubmit)="onSubmit()" #f="ngForm" class="step" novalidate>
    <div class="container-fluid">
      <!-- Old Password -->
      <div class="row">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>Old Access Password *</label>
          </div>
          <div class="col-xs-12 input-horizontal-container">
            <input
              required
              name="oldPassword"
              [(ngModel)]="oldPassword"
              [type]="returnInputType('oldPassword')"
              class="mr-15"
            />
            <i
              class="fa fa-eye fa-2x highlighting-icon"
              name="opi"
              [ngClass]="{ 'highlighted-icon': oldPasswordVisible }"
              (click)="toggleInputType('oldPassword')"
            ></i>
          </div>
        </div>
      </div>

      <!-- New Password -->
      <div class="row">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>New Access Password *</label>
          </div>
          <div class="col-xs-12 input-horizontal-container">
            <input
              required
              name="newPassword"
              [(ngModel)]="newPassword"
              [type]="returnInputType('newPassword')"
              class="mr-15"
            />
            <i
              class="fa fa-eye fa-2x highlighting-icon"
              name="npi"
              [ngClass]="{ 'highlighted-icon': newPasswordVisible }"
              (click)="toggleInputType('newPassword')"
            ></i>
          </div>
        </div>
      </div>

      <!-- Confirm New Password -->
      <div class="row">
        <div class="col-xs-12">
          <div class="col-xs-12">
            <label>Confirm New Access Password *</label>
          </div>
          <div class="col-xs-12 input-horizontal-container">
            <input
              required
              name="confirmNewPassword"
              [(ngModel)]="confirmNewPassword"
              [type]="returnInputType('confirmNewPassword')"
              class="mr-15"
            />
            <i
              class="fa fa-eye fa-2x highlighting-icon"
              name="cnpi"
              [ngClass]="{ 'highlighted-icon': confirmNewPasswordVisible }"
              (click)="toggleInputType('confirmNewPassword')"
            ></i>
          </div>
        </div>
      </div>

      <div class="col-xs-12 custom-err-msg-container">
        <p>
          <span class="error-message">{{ errorMessage }}</span>
        </p>
        <p>
          <span class="success-message">{{ successMessage }}</span>
        </p>
      </div>

      <div class="row">
        <div class="col-xs-12 text-center">
          <button
            class="button next-button centered-button"
            type="submit"
            [disabled]="!f.form.valid"
          >
            CHANGE PASSWORD
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
