import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { addDays, format, isAfter, isBefore, isWithinInterval } from 'date-fns';
import * as moment from 'moment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { AuthService } from '../../services/auth/auth.service';
import { DateService } from '../../services/date/date.service';
import { FormsService } from '../../services/forms/forms.service';
import { OrderService } from '../../services/order/order.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// import * as FileSaver from 'file-saver';;
declare var require: any;

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css'],
})
export class ReportingComponent implements OnInit {
  orderLineItemsFilter: any[] = [];

  selectedLocation: any = {
    c_company: '',
  };

  selectedInsurance: any = {
    c_insurance_name: '',
  };
  insuranceSelected: boolean = false;
  locationSelected: boolean = false;
  hcpcsSelected: boolean = false;

  allProducts: any;

  allHcpcsCodes: any;
  allUsers: any;
  userSelected: boolean = false;
  selectedCreator: any = {
    firstName: '',
    lastName: '',
    email: '',
  };

  selectedHcpcs: any = {
    c_hcpcs_code: '',
  };

  productFilterIds: any = [];

  selectedFitter: any = {
    firstName: '',
    lastName: '',
    email: '',
  };

  sku: string = '';
  chunckSizeInput: number = 100;

  fitterSelecrted: boolean = false;

  filteredOrderIds: string[];
  initialLoadingDone: boolean = false;

  icdsLoaded: boolean = false;
  allIcdCodes: any;

  allProviderLocations: any;
  allLocations: any;
  allInsurances: any;
  selectedProviderLocation: any[] = [];
  filterStatuses: string[] = [
    'any',
    'dispatched',
    'draft',
    'billed',
    'deleted',
  ];

  filterDeletedStatuses: string[] = ['not deleted', 'any', 'deleted'];

  filterDeletedStatusesItem: string[] = ['not deleted', 'any', 'deleted'];

  selectedStatus: string = this.filterStatuses[0];
  selectedDeleteStatus: string = this.filterDeletedStatuses[0];
  selectedDeleteStatusItem: string = this.filterDeletedStatusesItem[0];

  filterLoadingMode: string[] = ['serial', 'parallel'];

  selectedLoadingMode = this.filterLoadingMode[0];

  formattedLineItems: any[] = [];
  lineItemsPage: any[] = [];

  pageList: any[];

  minDateModel: IMyDateModel;
  maxDateModel: IMyDateModel;
  minBilledDateModel: IMyDateModel;
  maxBilledDateModel: IMyDateModel;
  minDeletedDateModel: IMyDateModel;
  maxDeletedDateModel: IMyDateModel;

  hasRole: any = {};

  gItemsPerPage: number = 10;
  gPages: number = 0;
  gPageList: any[] = [];

  lineItemsLoaded: boolean = false;

  filesaver = require('file-saver');
  ordersToProcess: number = -1;
  pageCount: any[] = [];
  exportData: any[] = [];
  exportDataOrder: any = [];
  csvHeader: any[] = [];

  count: number = 0;
  actualPage: number = 0;

  locations: any[] = [];
  orders: any[] = [];
  prevMaxId: any[] = [];
  locationItems: any[] = [];

  displayProcessLoading = false;
  prgChuncksLoaded = 0;
  prgTotalChuncks = 0;
  prgOrderText = 'Filtering & Loading OrderIds...';
  prgOrderItemsText = 'Waiting for orderIds...';
  prgBarPercentage = 0;
  prgRemainingTimeText = '-';

  datePipe = new DatePipe('en-US');
  today = new Date();

  dpOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    disableSince: this.dateService.toDatepickerFormat(addDays(new Date(), 1)),
  };

  bulkUpdateOrders: any[] = [];
  processingBulkUpdate: any = null;

  constructor(
    private authService: AuthService,
    private formsService: FormsService,
    private languagePipe: LanguagePipe,
    private router: Router,
    private orderService: OrderService,
    private dateService: DateService,
    private modalService: NgbModal
) {}

  ngOnInit() {
    this.getAllIcds(); // Once this function is loaded it will fire the request to load the orders
    this.getAllProviderLocations();
    this.getAllLocations();
    this.getAllInsurances();
    this.getAllUsers();
    this.getAllHcpcsCodes();
    this.getAllProducts();
    this.hasRole = this.authService.getAuthorizedUserRole();
    this.initializeDate();

    // this.getOrders();
    this.loadDeletedDataInRange();
  }

  getAllProducts() {
    this.orderService.getAllProducts().subscribe((prods) => {
      this.allProducts = prods;
      console.log('All Products Loaded: ');
    });
  }

  getAllHcpcsCodes() {
    this.orderService.getAllHcpcsCodesMedable().subscribe((allCodes) => {
      this.allHcpcsCodes = allCodes[0].data;
      console.log('All hcpocsCodes Medable: ');
    });
  }

  getAllInsurances() {
    this.orderService.getAllInsurances().subscribe((insurances) => {
      console.log('All Insurances Loaded: ');
      this.allInsurances = insurances;
    });
  }

  getAllUsers() {
    this.formsService.get('allFitters', '').subscribe((allUsers) => {
      console.log('All Users Loaded: ');
      this.allUsers = allUsers.data;
    });
  }

  getAllLocations() {
    this.orderService.getAllLocations().subscribe((locations) => {
      this.allLocations = locations;
    });
  }

  getAllProviderLocations() {
    this.orderService.getAllProviderLocations().subscribe((pLocations) => {
      console.log('All Provider Locations: ');
      this.allProviderLocations = pLocations;
    });
  }

  getAllIcds() {
    this.orderService.getAllIcdCodes().subscribe((icds) => {
      this.allIcdCodes = icds;
      this.icdsLoaded = true;
      console.log('All icd codes loaded...');
      this.getTableItems(false);
    });
  }

  initializeDate() {
    const today = this.dateService.toUTCDate(new Date());
    this.minDateModel = this.dateService.toDatepickerModel(today);
    this.maxDateModel = this.dateService.toDatepickerModel(today);
  }

  generateFileNameMin() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'

    if (this.onlyDeletedRange()) {
      fnm = this.dateService.format(this.minDeletedDateModel, 'MM_dd_yyyy');
    } else {
      if (this.minDateModel) {
        fnm = this.dateService.format(this.minDateModel, 'MM_dd_yyyy');
      } else if (this.minBilledDateModel) {
        fnm = this.dateService.format(this.minBilledDateModel, 'MM_dd_yyyy');
      }
    }

    return fnm;
  }

  generateFileNameMax() {
    let fnm = 'undefined';
    //(this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : 'udf'
    if (this.onlyDeletedRange()) {
      fnm = this.dateService.format(this.maxDeletedDateModel, 'MM_dd_yyyy');
    } else {
      if (this.maxDateModel) {
        fnm = this.dateService.format(this.maxDateModel, 'MM_dd_yyyy');
      } else if (this.maxBilledDateModel) {
        fnm = this.dateService.format(this.maxBilledDateModel, 'MM_dd_yyyy');
      }
    }

    return fnm;
  }

  updateOrderText = (step) => {
    this.prgOrderText = `Loading orderIds in batches, ${step} batches loaded... Has more...`;
  };

  // Getting orderlineitems based on the filters
  getTableItems(search: boolean = true, paginator = 0, specificPage = false) {
    this.displayProcessLoading = true;
    // Open Loading indicator
    this.bulkUpdateOrders = [];
    this.formattedLineItems = [];
    if (search) {
      // this.openModal(100);
      console.log('Selected Location: ', this.selectedLocation);
    }

    if (this.onlyDeletedRange()) {
      console.log('*******Only deleted range set');
    } else {
      console.log('*******Not only deleted range set');
    }

    this.getOrdersBasedonFilters(this.updateOrderText).subscribe((orders) => {
      console.log('Orders Loaded');
      if (!orders.length) {
        console.log('Fin.');
        this.formattedLineItems = [];
        this.lineItemsPage = [];
        this.gPageList = [];
        this.gPages = 0;
        this.displayProcessLoading = false;
      } else {
        this.getFilteredLineItems().subscribe((items) => {
          console.log('Fin.');
        });
      }
    });
  }

  dosRangeSet = () => {
    return this.minDateModel && this.maxDateModel;
  };

  billedRangeSet = () => {
    return this.minBilledDateModel && this.maxBilledDateModel;
  };

  deletedRangeSet = () => {
    return this.minDeletedDateModel && this.maxDeletedDateModel;
  };

  onlyDeletedRange = () => {
    if (
      !this.dosRangeSet() &&
      !this.billedRangeSet() &&
      this.deletedRangeSet()
    ) {
      return true;
    }

    return false;
  };

  getFilteredLineItems(): Observable<any> {
    const localSubject = new Subject();
    const requests = [];
    console.log('OrderIds: ', this.filteredOrderIds);

    if (this.selectedLoadingMode === 'parallel') {
      this.loadChunckData().subscribe((loadedLines) => {
        localSubject.next(loadedLines);
        localSubject.complete();
      });
    } else if (this.selectedLoadingMode === 'serial') {
      this.loadAllLineItems().subscribe((loadedLines) => {
        localSubject.next(loadedLines);
        localSubject.complete();
      });
    }

    return localSubject;
  }

  buildLineItemsFilter(filteredIds) {
    if (!this.hcpcsSelected) {
      this.productFilterIds = [];
    }
    const conditions = [`{"c_medable_order": {"$in": [${filteredIds}]}}`];
    // let filter = `where={"c_medable_order": {"$in": [${filteredIds}]}}`;
    // if (this.productFilterIds.length) {
    //   filter = `${filter}`
    // }

    // if (this.productFilterIds.length) {
    //   const hcpcsFilter = `{"c_product" : {"$in": [${this.productFilterIds}]}}`;
    //   conditions.push(hcpcsFilter);
    // }

    const filter = `where={"$and": [${conditions.join(',')}]}`;

    return filter;
  }

  loadChunckData() {
    const chunckSize = this.chunckSizeInput > 5 ? this.chunckSizeInput : 5;
    const reqLimit = 2000;
    const chuncks = Math.ceil(this.filteredOrderIds.length / chunckSize);
    const requests = [];
    const localSubject = new Subject();

    for (let i = 0; i < chuncks; i++) {
      const filteredIds = this.filteredOrderIds
          .splice(0, chunckSize)
          .map((oi) => `"${oi['_id']}"`),
        filter = this.buildLineItemsFilter(filteredIds),
        expands = [
          'expand[]=c_medable_order',
          'expand[]=c_medable_order.c_patient',
          'expand[]=c_product',
          'expand[]=c_medable_order.c_designated_fitter',
          'expand[]=c_medable_order.c_fitter',
          'expand[]=c_product.c_supplier_id',
          'expand[]=c_medable_order.c_provider_location',
          'expand[]=c_medable_order.c_provider_location.c_physician',
          'expand[]=c_medable_order.c_provider_location.c_location',
          'expand[]=c_modifier.c_modifier_ref',
          'expand[]=c_medable_order.c_billing_provider',
          'expand[]=c_medable_order.c_secondary_insurance',
          'expand[]=c_medable_order.c_last_modified_by',
        ],
        expand = expands.join('&'),
        limit = `limit=${reqLimit}`,
        query = `?${filter}&${expand}&${limit}`;

      const request = this.formsService.get('orderLineItems', query);

      requests.push(request);
    }

    forkJoin(requests).subscribe((results) => {
      // console.log("Line Results: ", results);
      this.formatLineResults(results);
      localSubject.next(results);
      localSubject.complete();
    });

    return localSubject;
  }

  /**
   * Test function to load deleted orders & items
   */
  loadDeletedDataInRange = () => {
    let minDate = '2020-04-01',
      maxDate = '2020-04-04';

    let res = 'orderLineItems',
      itemMin = `{"c_delete_date" : {"$gte" : "${minDate}"}}`,
      itemMax = `{"c_delete_date" : {"$lte" : "${maxDate}"}}`,
      ordMin = `{"c_order_delete_date" : {"$gte" : "${minDate}"}}`,
      ordMax = `{"c_order_delete_date" : {"$lte" : "${maxDate}"}}`,
      itemFilter = `{"$and" : [${itemMin}, ${itemMax}]}`,
      ordFilter = `{"$and" : [${ordMin}, ${ordMax}]}`,
      filter = `{"$or": [${itemFilter}, ${ordFilter}]}`,
      where = `?where=${filter}`;

    this.formsService.get('orderLineItems', where).subscribe((rsp) => {
      console.log('***###***###***Deleted orders or items in range: ', rsp);
    });
  };

  loadAllLineItems = () => {
    console.log('Started loading orderItems...');
    const chunckSize = this.chunckSizeInput > 5 ? this.chunckSizeInput : 5,
      reqLimit = 2000,
      chuncks = Math.ceil(this.filteredOrderIds.length / chunckSize),
      localSubject = new Subject();

    this.prgOrderItemsText = `Loading orderitems in batches: 0 of ${chuncks} batches loaded`;

    let step = 0,
      allOrderLineItems = [],
      self = this;
    console.log('Total chunks: ', chuncks);

    function loadAll() {
      let startTime = new Date().getTime();
      let filteredIds = self.filteredOrderIds
          .splice(0, chunckSize)
          .map((oi) => `"${oi['_id']}"`),
        filter = self.buildLineItemsFilter(filteredIds),
        expands = [
          'expand[]=c_medable_order',
          'expand[]=c_medable_order.c_patient',
          'expand[]=c_product',
          'expand[]=c_medable_order.c_designated_fitter',
          'expand[]=c_medable_order.c_fitter',
          'expand[]=c_product.c_supplier_id',
          'expand[]=c_medable_order.c_provider_location',
          'expand[]=c_medable_order.c_provider_location.c_physician',
          'expand[]=c_medable_order.c_provider_location.c_location',
          'expand[]=c_modifier.c_modifier_ref',
          'expand[]=c_medable_order.c_billing_provider',
          'expand[]=c_medable_order.c_secondary_insurance',
          'expand[]=c_medable_order.c_last_modified_by',
        ],
        expand = expands.join('&'),
        limit = `limit=${reqLimit}`,
        query = `?${filter}&${expand}&${limit}`,
        request = self.formsService.get('orderLineItems', query);

      request.subscribe((res) => {
        // self.formatLineResults(res);
        console.log('LineItems from all mode: ', res);
        allOrderLineItems = allOrderLineItems.concat(res);
        step++;
        let finishTime = new Date().getTime(),
          requestDuration = finishTime - startTime,
          reqDurationSeconds = requestDuration / 1000,
          estimatedRemainingSeconds =
            reqDurationSeconds * chuncks - reqDurationSeconds * step,
          remainingTimeText =
            estimatedRemainingSeconds / 60 < 1
              ? `Estimated completion in: ${estimatedRemainingSeconds} seconds`
              : `Estimated completion in: ${
                  estimatedRemainingSeconds / 60
                } minutes`;
        self.prgRemainingTimeText = remainingTimeText;
        self.prgOrderItemsText = `Loading orderitems in batches: ${step} of ${chuncks} batches loaded`;
        self.prgBarPercentage = (step * 100) / chuncks;
        console.log('Step: ', step);
        if (step < chuncks) {
          console.log('Loaded: ', res.data.length, ' items');
          console.log('Has more....');
          loadAll();
        } else {
          self.prgOrderItemsText = `All orderitems loaded...`;
          self.prgBarPercentage = 100;
          console.log(
            'Finished loading all orderLineItems: ',
            allOrderLineItems
          );
          self.formatLineResults(allOrderLineItems);
          localSubject.next(res);
          localSubject.complete();
          self.displayProcessLoading = false;
        }
      });
    }

    loadAll();

    return localSubject;
  };

  addFormattedLine(line) {
    const formattedLine = this.buildFormattedLine(line);
    this.formattedLineItems.push(formattedLine);
  }

  formatLineResults(results) {
    console.log('Formatting Line Results: ', results);
    const filterSku = this.sku.toLowerCase();
    const filterHcpcs = this.selectedHcpcs.c_hcpcs_code;
    results.map((res) => {
      let resLines = res.data;
      // Apply item deleted status filter
      // if (this.selectedDeleteStatusItem === "not deleted") {
      //   resLines = resLines.filter(rl => !rl.c_deleted);
      // } else if (this.selectedDeleteStatusItem === "deleted") {
      //   resLines = resLines.filter(rl => rl.c_deleted);
      // }

      // Apply status filter (for deleted items/orders)

      if (this.selectedStatus !== 'any' && this.selectedStatus !== 'deleted') {
        // If filter status is not any and not deleted, remove deleted orders & orderItems from the list
        resLines = resLines.filter((rl) => {
          let itemDeleted = rl.c_deleted,
            itemOrder = rl.c_medable_order,
            orderDeleted = itemOrder.c_deleted;

          return !itemDeleted && !orderDeleted;
        });
      } else if (this.selectedStatus === 'deleted') {
        // If filterStatus is deleted, only return deleted items or items who's order is deleted
        resLines = resLines.filter((rl) => {
          let itemDeleted = rl.c_deleted,
            itemOrder = rl.c_medable_order,
            orderDeleted = itemOrder.c_deleted;

          return itemDeleted || orderDeleted;
        });
      }

      // Filter lines by deleted date
      resLines = this.filterLinesByDeletedDate(resLines);

      resLines.map((line) => {
        // Apply Sku Filter
        if (
          this.sku == '' ||
          (this.sku != '' &&
            line.c_product_option_code.toLowerCase() == filterSku)
        ) {
          // Apply Hcpcs Filter
          if (
            !this.hcpcsSelected ||
            (this.hcpcsSelected && line.c_product.c_hcpcs_code == filterHcpcs)
          ) {
            this.addFormattedLine(line);
          }
        }
      });
    });

    this.gPages = Math.ceil(
      this.formattedLineItems.length / this.gItemsPerPage
    );
    console.log('LineItems: ', this.formattedLineItems);
    console.log('Total Pages: ', this.gPages);
    this.gPageList = [];
    for (let i = 0; i < this.gPages; i++) {
      const tmp = {
        pageNr: i + 1,
      };
      this.gPageList.push(tmp);
    }

    console.log('pageList: ', this.gPageList);

    this.actualPage = 1;
    this.loadPage(1);
    // console.log("Formatted Line Results: ", this.formattedLineItems);
  }

  filterLinesByDeletedDate = (lines) => {
    // console.log("Lines are: ", lines);
    console.log('Min Deleted date is: ', this.minDeletedDateModel);
    console.log('Max Deleted date is: ', this.maxDeletedDateModel);
    const minDate = this.minDeletedDateModel && this.dateService.toDate(
      this.minDeletedDateModel.singleDate.date
    );
    const maxDate =  this.maxDeletedDateModel && this.dateService.toDate(
      this.maxDeletedDateModel.singleDate.date
    );

    if (!this.minDeletedDateModel && !this.maxDeletedDateModel) {
      console.log('Returning all lines');
      return lines;
    } else if (this.minDeletedDateModel && this.maxDeletedDateModel) {
      console.log(
        `Returning lines between: ${this.minDeletedDateModel} - ${this.maxDeletedDateModel} `
      );
      return this.filterItemsDelBetween(lines, minDate, maxDate);
    } else if (this.minDeletedDateModel) {
      console.log('Returning lines after: ', this.minDeletedDateModel);
      return this.filterItemsDelAfter(lines, minDate);
    } else if (this.maxDeletedDateModel) {
      console.log('Returning lines before: ', this.maxDeletedDateModel);
      return this.filterItemsDelBefore(lines, maxDate);
    }

    return lines;
  };

  /**
   * Filter for items that either have a delete date lower than the provided maxMOm
   * or their order has a deleted date lower than then provided maxMom
   */
  filterItemsDelBefore = (lines, maxDate: Date) => {
    let filtered = lines.filter((l) => {
      let ordDel = l.c_medable_order.c_delete_date,
        itemDel = l.c_delete_date;

      if (!ordDel && !itemDel) {
        return false;
      }

      if (ordDel && isBefore(new Date(ordDel), maxDate)) {
        return true;
      }

      if (itemDel && isBefore(new Date(itemDel), maxDate)) {
        return true;
      }

      return false;
    });

    return filtered;
  };

  /**
   * Filter for items that either have a delete date bigger than the provided minMom
   * or their order has a deleted date bigger than then provided minMom
   */
  filterItemsDelAfter = (lines, minDate: Date) => {
    let filtered = lines.filter((l) => {
      let ordDel = l.c_medable_order.c_delete_date,
        itemDel = l.c_delete_date;

      if (!ordDel && !itemDel) {
        return false;
      }

      if (ordDel && isAfter(new Date(ordDel), minDate)) {
        return true;
      }

      if (itemDel && isAfter(new Date(itemDel), minDate)) {
        return true;
      }

      return false;
    });

    return filtered;
  };

  /**
   * Filter for items that either have a delete date bigger than the provided minMom
   * or their order has a deleted date bigger than then provided minMom
   */
  filterItemsDelBetween = (lines, minDate: Date, maxDate: Date) => {
    let filtered = lines.filter((l) => {
      let ordDel = l.c_medable_order.c_delete_date,
        itemDel = l.c_delete_date;

      if (!ordDel && !itemDel) {
        return false;
      }

      if (ordDel) {
        return isWithinInterval(new Date(ordDel), {
          start: minDate,
          end: maxDate,
        });
      }

      if (itemDel) {
        return isWithinInterval(new Date(itemDel), {
          start: minDate,
          end: maxDate,
        });
      }

      return false;
    });

    return filtered;
  };

  loadPage(pageNumber) {
    this.actualPage = pageNumber;
    const itemsPerPage = 10;
    this.lineItemsPage = this.formattedLineItems.slice(
      (pageNumber - 1) * itemsPerPage,
      pageNumber * itemsPerPage
    );
    console.log('Loading Page: ', this.lineItemsPage);
  }

  displaySystemDate(medableDateString) {
    return format(new Date(medableDateString), 'MM/dd/yyyy')
  }

  getOrderCreatorName(medableOrder) {
    //`${medableOrder.c_fitter.name.first} ${medableOrder.c_fitter.name.last}`
    const orderCreatorId = medableOrder.creator._id;
    const orderCreatorUser = this.getUserBasedOnId(orderCreatorId);
    //console.log("OrderCreator: ", orderCreatorId);
    let creatorName = '-';
    // console.log("CreatorName: ", orderCreatorUser)
    if (orderCreatorUser) {
      creatorName = `${orderCreatorUser.firstName} ${orderCreatorUser.lastName}`;
    }
    // console.log("CreatorName concatenated: ", creatorName)
    return creatorName;
  }

  getUserBasedOnId(userId) {
    //console.log("searching userId: ", userId, " in userList: ", this.allUsers);
    const user = this.allUsers.filter((u) => u.id == userId)[0];
    return user;
  }

  formatDeletedDate = (deletedDate) => {
    if (!deletedDate) {
      return '';
    }

    return moment(deletedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  };

  buildFormattedLine(line) {
    const medableOrder = line.c_medable_order;
    const orderPatient = medableOrder.c_patient;
    const lineProduct = line.c_product;
    const orderPhysician = medableOrder.c_provider_location.c_physician;
    //console.log("RawLine: ", line);
    const fLine = {
      selectedHcpcs: line.c_selected_hcpcs,
      quantity: line.c_quantity,
      deleteDate: this.formatDeletedDate(line.c_delete_date),
      delete_reason: line.c_delete_reason,
      chargeOutCost: line.c_charge_out_cost,
      allowableCost: line.c_allowable_cost,
      selfPay: line.c_self_pay_cost,
      salesTax: line.c_sales_tax,
      modifiers: this.generateLineModifiers(line),
      warnings: this.generateLineWarnings(line),
      icds: this.generateLineIcds(line),
      order: {
        cin7Ref: medableOrder.c_cin7_so_code,
        id: medableOrder._id,
        lastModifiedBy: this.getLastModifiedBy(medableOrder),
        deleteDate: this.formatDeletedDate(medableOrder.c_delete_date),
        systemDate: this.displaySystemDate(medableOrder.created),
        delete_reason: medableOrder.c_delete_reason,
        numberId: medableOrder.c_number_id,
        display_order_date: this.medableDateToDisplay(
          new Date(medableOrder.c_order_date)
        ),
        display_billed_date: medableOrder.c_billed_date
          ? this.medableDateToDisplay(new Date(medableOrder.c_billed_date))
          : '-',
        status: this.getOrderStatus(medableOrder, line),
        claimId: medableOrder.c_claim_id ? medableOrder.c_claim_id : '-',
        creatorName: this.getOrderCreatorName(medableOrder),
        fitterName: medableOrder.c_designated_fitter
          ? `${medableOrder.c_designated_fitter.name.first} ${medableOrder.c_designated_fitter.name.last}`
          : '-',
        billingProviderName: medableOrder.c_billing_provider
          ? this.getBillingProviderName(medableOrder)
          : '-',
        providerName: this.getProvider(orderPhysician),
        providerLocation: this.getProviderLocation(medableOrder),
        primaryInsurance: line.c_insurance_name,
        primaryInsId: medableOrder.c_primary_insurance_id_number
          ? medableOrder.c_primary_insurance_id_number
          : '-',
        secondaryInsurance: medableOrder.c_secondary_insurance
          ? medableOrder.c_secondary_insurance.c_insurance_name
          : '-',
        secondaryInsId: medableOrder.c_secondary_insurance_id_number
          ? medableOrder.c_secondary_insurance_id_number
          : '-',
        patient: {
          id: orderPatient._id,
          mrn: orderPatient.c_mrn,
          fullName: orderPatient.c_name
            ? `${orderPatient.c_name.c_first_name} ${orderPatient.c_name.c_last_name}`
            : '-',
        },
        lastModified: medableOrder.c_last_modified
          ? moment(medableOrder.c_last_modified).format('MM/DD/YYYY - HH:mm:ss')
          : '-',
      },
      product: {
        hcpcs: lineProduct.c_hcpcs_code,
        name: lineProduct.c_name,
        optionCode: line.c_product_option_code,
        size: this.getProductSize(line),
        vendor:
          lineProduct.c_supplier_id != undefined
            ? line.c_product.c_supplier_id.c_company
            : '-',
      },
      itemId: line._id,
      invoiced: line.c_invoiced,
      invoiceNumber: line.c_invoice_number != '' ? line.c_invoice_number : '-',
    };

    return fLine;
  }

  getLastModifiedBy = (order) => {
    if (!order.c_last_modified_by) {
      return '-';
    }

    let lastMod = order.c_last_modified_by,
      name = lastMod.name,
      first = name.first,
      last = name.last,
      full = `${first} ${last}`;

    return full;
  };

  // getBillingProviderName(medableOrder) {
  //   console.log("MedableOrder: ", medableOrder);
  //   return '-';
  // }

  getBillingProviderName(order) {
    const preffix = order.c_billing_provider.c_preffix
        ? `${order.c_billing_provider.c_preffix} `
        : ``,
      firstName = order.c_billing_provider.c_name.c_first_name,
      lastName = order.c_billing_provider.c_name.c_last_name,
      suffix = order.c_billing_provider.c_suffix
        ? ` ${order.c_billing_provider.c_suffix}`
        : '';

    return `${preffix}${firstName} ${lastName}${suffix}`;
  }

  /**
   * Function to get an item's hcpcs code.
   * If multiple available, return selected one, else return the product's code.
   */
  getItemHCPCSCode = (item) => {
    let prod = item.product;

    if (!prod.hcpcs) {
      return '-';
    }

    return item.selectedHcpcs || prod.hcpcs;
  };

  exportDashboardItems() {
    this.csvHeader = [
      this.languagePipe.transform('dashboard.order'),
      this.languagePipe.transform('dashboard.system_date'),
      this.languagePipe.transform('dashboard.date'),
      this.languagePipe.transform('dashboard.status'),
      this.languagePipe.transform('dashboard.delete_reason'),
      this.languagePipe.transform('dashboard.delete_date'),
      this.languagePipe.transform('dashboard.billed_date'),
      this.languagePipe.transform('dashboard.claim_id'),
      this.languagePipe.transform('dashboard.mrn'),
      this.languagePipe.transform('dashboard.patient_name'),
      this.languagePipe.transform('dashboard.creator.name'),
      this.languagePipe.transform('dashboard.fitter.name'),
      this.languagePipe.transform('dashboard.hcpcs'),
      this.languagePipe.transform('dashboard.item_name'),
      this.languagePipe.transform('dashboard.product_option_code'),
      this.languagePipe.transform('dashboard.quantity'),
      this.languagePipe.transform('dashboard.size'),
      this.languagePipe.transform('dashboard.vendor'),
      this.languagePipe.transform('orders.order-form.label.orderingProvider'),
      this.languagePipe.transform('dashboard.billingProvider.name'),
      this.languagePipe.transform('dashboard.location'),
      this.languagePipe.transform('dashboard.primary_insurance'),
      this.languagePipe.transform('dashboard.primary_insurance_id'),
      this.languagePipe.transform('dashboard.secondary_insurance'),
      this.languagePipe.transform('dashboard.secondary_insurance_id'),
      this.languagePipe.transform('dashboard.charge_out_cost'),
      this.languagePipe.transform('dashboard.allowable_cost'),
      this.languagePipe.transform('dashboard.patient_payment'),
      this.languagePipe.transform('dashboard.sales_tax'),
      this.languagePipe.transform('dashboard.modifiers'),
      this.languagePipe.transform('dashboard.warnings'),
      this.languagePipe.transform('dashboard.icds'),
      this.languagePipe.transform('dashboard.orderId'),
      this.languagePipe.transform('dashboard.itemId'),
      this.languagePipe.transform('dashboard.invoiced'),
      this.languagePipe.transform('dashboard.invoiceNr'),
      this.languagePipe.transform('dashboard.lastModified'),
      this.languagePipe.transform('dashboard.last_modified_by'),
      this.languagePipe.transform('dashboard.cin7_ref'),
    ];
    // const min = (this.minDate) ? (this.minDate.date.month + '_' + this.minDate.date.day + '_' + this.minDate.date.year) : '';
    // const max = this.maxDate.date.month + 1 + '_' + this.maxDate.date.day + '_' + this.maxDate.date.year;
    // const csvName = 'Export ' + min + ' - ' + max + '.csv';
    const min = this.generateFileNameMin(),
      max = this.generateFileNameMax(),
      csvName = 'Export ' + min + ' - ' + max + '.csv';
    this.exportData = [];
    this.exportDataOrder = [];
    const self = this;

    const fileTxt = this.formattedLineItems.map((item) => {
      const order = item.order;
      const product = item.product;
      const orderPatient = item.order.patient;
      const oli = [
        `"${order.numberId || '-'}"`,
        `"${order.systemDate || '-'}"`,
        `"${order.display_order_date || '-'}"`,
        `"${order.status || '-'}"`,
        `"${self.getDeleteReason(item)}"`,
        `"${self.getDeleteDate(item)}"`,
        `"${order.display_billed_date || '-'}"`,
        `"${(order.claimId || '-').replace(/"/g, "''")}"`,
        `"${(orderPatient.mrn || '-').replace(/"/g, "''")}"`,
        `"${(orderPatient.fullName || '-').replace(/"/g, "''")}"`,
        `"${order.creatorName || '-'}"`,
        `"${order.fitterName || '-'}"`,
        `"${self.getItemHCPCSCode(item)}"`,
        `"${(product.name || '-').replace(/"/g, "''")}"`,
        `"${(product.optionCode || '-').replace(/"/g, "''")}"`,
        `"${item.quantity || '-'}"`,
        `"${(product.size || '-').replace(/"/g, "''")}"`,
        `"${(product.vendor || '-').replace(/"/g, "''")}"`,
        `"${(order.providerName || '-').replace(/"/g, "''")}"`,
        `"${(order.billingProviderName || '-').replace(/"/g, "''")}"`,
        `"${(order.providerLocation || '-').replace(/"/g, "''")}"`,
        `"${(order.primaryInsurance || '-').replace(/"/g, "''")}"`,
        `"${(order.primaryInsId || '-').replace(/"/g, "''")}"`,
        `"${(order.secondaryInsurance || '-').replace(/"/g, "''")}"`,
        `"${(order.secondaryInsId || '-').replace(/"/g, "''")}"`,
        `"${item.chargeOutCost || '-'}"`,
        `"${item.allowableCost || '-'}"`,
        `"${item.selfPay || '-'}"`,
        `"${item.salesTax || '-'}"`,
        `"${item.modifiers || '-'}"`,
        `"${item.warnings || '-'}"`,
        `"${item.icds || '-'}"`,
        `"${item.order.id || '-'}"`,
        item.itemId ? '"' + item.itemId + '"' : '-',
        '"' + item.invoiced + '"',
        item.invoiceNumber ? '"' + item.invoiceNumber + '"' : '-',
        order.lastModified ? `"${order.lastModified}"` : '-',
        `"${order.lastModifiedBy || '-'}"`,
        `"${order.cin7Ref || '-'}"`,
      ];

      return oli.join(',');
    });

    let mappedTxt = fileTxt.join('\n');
    mappedTxt = self.csvHeader.join(',') + '\n' + mappedTxt;
    const file = new Blob([mappedTxt], { type: 'text/plain;charset=utf-8' });
    file['name'] = csvName;
    this.filesaver.saveAs(file);
  }

  getDeleteDate = (line) => line.order.deleteDate || line.deleteDate || '-';

  getDeleteReason = (line) =>
    line.order.delete_reason || line.delete_reason || '-';

  getProductSize(line) {
    const product = line.c_product;
    if (!product.c_product_options) {
      return '-';
    } else {
      const sizeObj = product.c_product_options.filter(
        (opt) => opt.c_code == line.c_product_option_code
      )[0];
      const size = sizeObj != null ? sizeObj.c_size : '-';
      // console.log("option: ", option);
      return size;
    }
  }

  // generateLineModifiers
  generateLineWarnings(line) {
    // console.log("Line Warnings: ", line.c_modifier);
    const warnings = line.c_modifier
      .filter((m) => m.c_checked && m.c_modifier_ref.c_warning)
      .map((m) => m.c_modifier)
      .join(', ');
    return warnings;
  }

  generateLineModifiers(line) {
    // console.log("Line Modifier: ", line.c_modifier);
    const modifiers = line.c_modifier
      .filter((m) => m.c_checked && !m.c_modifier_ref.c_warning)
      .map((m) => m.c_modifier)
      .join(', ');
    return modifiers;
  }

  generateLineIcds(line) {
    const icdCodes = [];
    line.c_icd_10.map((icdId) => {
      const icdCode = this.allIcdCodes
        .filter((icd) => icd._id == icdId)
        .map((icd) => icd.c_icd_10_code)[0];
      icdCodes.push(icdCode);
    });

    return icdCodes.join(', ');
  }

  getProvider(physician) {
    const prefix = physician.c_preffix ? `${physician.c_preffix} ` : '';
    const firstName = physician.c_name.c_first_name;
    const lastName = physician.c_name.c_last_name;
    const suffix = physician.c_suffix ? ` ${physician.c_suffix}` : '';

    return `${prefix}${firstName} ${lastName}${suffix}`;
  }

  getProviderLocation(order) {
    if (!order.c_provider_location.c_location) {
      return '-';
    } else {
      return order.c_provider_location.c_location.c_company;
    }
  }

  /**
   * Function to filter orders based on selecred deleted status.
   */
  filterOrdersBasedOnDeletedStatus = (orderList) => {
    if (this.selectedDeleteStatus === 'any') {
      return orderList;
    } else if (this.selectedDeleteStatus === 'deleted') {
      return orderList.filter((ord) => ord.c_deleted);
    } else if (this.selectedDeleteStatus === 'not deleted') {
      return orderList.filter((ord) => !ord.c_deleted);
    }
  };

  getOrdersBasedonFilters(cb = null): Observable<any> {
    const localSubject = new Subject();
    const ordersQuery = this.buildOrderFilterQuery();
    // Get the medable ids of all the orders based on the filters
    this.orderService.getAllOrderIds(ordersQuery, cb).subscribe((orderIds) => {
      console.log('All orderIds Loaded: ', { ...orderIds });

      // Only filter for order ids that are not deleted
      this.filteredOrderIds = orderIds;
      this.prgOrderText = `All ${orderIds.length} orderIds Loaded...`;
      this.prgOrderItemsText = ` `;
      localSubject.next(this.filteredOrderIds);
      localSubject.complete();
    });

    return localSubject;
  }

  lateFilterOrders(orders) {
    if (this.selectedStatus == 'any') {
      return orders;
    }

    return orders.filter((ord) => {
      if (
        this.selectedStatus == 'dispatched' &&
        !ord.c_billed_date &&
        !ord.c_draft
      ) {
        return true;
      }

      if (this.selectedStatus == 'draft' && ord.c_draft) {
        return true;
      }

      if (
        this.selectedStatus == 'billed' &&
        ord.c_billed_date &&
        !ord.c_draft
      ) {
        return true;
      }
    });
  }

  billedFilterSet() {
    return this.minBilledDateModel || this.maxBilledDateModel;
  }

  addBilledFilters(filters) {
    if (this.minBilledDateModel) {
      const minBiledDateFormatted = this.dateService.format(
          this.minBilledDateModel
        ),
        filterString = `{"c_billed_date": {"$gte" : "${minBiledDateFormatted}"}}`;
      filters.push(filterString);
    }

    if (this.maxBilledDateModel) {
      const maxBilledDateFormatted = this.dateService.format(
          this.maxBilledDateModel
        ),
        filterString = `{"c_billed_date": {"$lte" : "${maxBilledDateFormatted}"}}`;
      filters.push(filterString);
    }
  }

  addStatusFilter(filters) {
    switch (this.selectedStatus) {
      case 'dispatched':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": false}`);
        break;

      case 'draft':
        filters.push(`{"c_draft": true}`);
        break;

      case 'billed':
        filters.push(`{"$or": [{"c_draft": false}, {"c_draft": null}]}`);
        filters.push(`{"c_dispatched": true}`);
        break;
    }
  }

  buildFilterList() {
    console.log(
      'Billed date filters -  Min: ',
      this.minBilledDateModel,
      ' Max: ',
      this.maxBilledDateModel
    );
    const filters = [];

    if (this.onlyDeletedRange()) {
      let maxDos = moment().format('YYYY-MM-DD'),
        minDos = moment('2020/04/01', 'YYYY/MM/DD').format('YYYY-MM-DD');

      filters.push(`{"c_order_date": {"$gte" : "${minDos}"}}`);
      filters.push(`{"c_order_date": {"$lte" : "${maxDos}"}}`);

      let deleteF = `{"$or": [{"c_deleted" : true}, {"c_deleted_items": true}]}`;
      filters.push(deleteF);
    } else {
      if (this.minDateModel && this.maxDateModel) {
        const minDateFormatted = this.dateService.format(this.minDateModel);
        const maxDateFormatted = this.dateService.format(this.maxDateModel);
        filters.push(`{"c_order_date": {"$gte" : "${minDateFormatted}"}}`);
        filters.push(`{"c_order_date": {"$lte" : "${maxDateFormatted}"}}`);
      }
    }

    // filters.push(`{"c_draft": false}`);

    /*const filters = [
      `{"c_order_date": {"$gte" : "${minDateFormatted}"}}`,
      `{"c_order_date": {"$lte" : "${maxDateFormatted}"}}`,
    ]*/

    if (this.billedFilterSet()) {
      this.addBilledFilters(filters);
    }

    if (this.selectedStatus != 'any') {
      this.addStatusFilter(filters);
    }

    if (this.selectedProviderLocation.length) {
      const locationFilter = `{"c_provider_location": {"$in": [${this.selectedProviderLocation}]}}`;
      filters.push(locationFilter);
    }

    if (this.selectedInsurance._id) {
      const insuranceFilter = `{"c_insurance" : "${this.selectedInsurance._id}"}`;
      filters.push(insuranceFilter);
    }

    if (this.selectedCreator.id) {
      const creatorFilter = `{"c_fitter" : "${this.selectedCreator.id}"}`;
      filters.push(creatorFilter);
    }

    if (this.selectedFitter.id) {
      const fitterFilter = `{"c_designated_fitter" : "${this.selectedFitter.id}"}`;
      filters.push(fitterFilter);
    }

    return filters;
  }

  buildOrderFilterQuery() {
    const filterList = this.buildFilterList();
    const condition = `where={"$and": [${filterList.join(',')}]}`;
    const extra = ``;
    const query = `${condition}`;
    console.log('Orders Filter: ', query);
    return query;
  }

  getOrderStatus(order, line) {
    if (line.c_deleted) {
      return 'Item Deleted';
    }

    if (order.c_deleted) {
      return 'Order Deleted';
    }
    const status =
      order.c_draft ? 'Draft' :
        order.c_billed_date ? 'Billed' : 'Dispatched';
    return status;
  }

  getOrderCreator(order) {
    const creatorName = order.c_fitter.name;
    const firstName = creatorName.first;
    const lastName = creatorName.last;

    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  getDesignatedFitter(order) {
    const creatorName = order.c_designated_fitter.name;
    const firstName = creatorName.first;
    const lastName = creatorName.last;
    // console.log("Order FItter: ", order.c_designated_fitter.name)
    const creatorFullName = `${firstName} ${lastName}`;
    return creatorFullName;
  }

  medableDateToDisplay(medableDate) {
    // console.log("medblDate: ", medableDate.getUTCDate());
    const day =
      medableDate.getUTCDate() < 10
        ? `0${medableDate.getUTCDate()}`
        : medableDate.getUTCDate();
    const month =
      medableDate.getUTCMonth() + 1 < 10
        ? `0${medableDate.getUTCMonth() + 1}`
        : medableDate.getUTCMonth() + 1;
    const year = medableDate.getUTCFullYear();

    const dateString = `${month}/${day}/${year}`;
    // console.log("DateString: ", dateString);
    return dateString;
  }

  goToViewOrder(orderId) {
    const url = `/forms/orders/:id/view;id=${orderId}`
    window.open(url, '_blank');
  }

  goToViewPatient(patientId) {
    const url = `/forms/patients/:id/view;id=${patientId}`
    window.open(url, '_blank');
  }

  getOrderLineItems(orderId, orderIndex) {
    const expandFields = [
      'c_product',
      'c_product.c_supplier_id',
      'c_modifier.c_modifier_ref',
    ];

    const expands = 'expand[]=' + expandFields.join('&expand[]=');
    const filter = `where={"c_medable_order" : "${orderId}"}`;
    const query = `?${filter}&${expands}`;

    this.orderService.getOrderLineWithICD(query).subscribe((orderLineItems) => {
      orderLineItems.data.map((orderLineItem) => {
        const orderLineItemObject = { ...orderLineItem };
        orderLineItemObject.order = this.orders[orderIndex];
        const oPhysician =
          orderLineItemObject.order.c_provider_location.c_physician;
        const ppfx = oPhysician.c_preffix ? `${oPhysician.c_preffix} ` : '';
        const pfn = oPhysician.c_name.c_first_name;
        const pln = oPhysician.c_name.c_last_name;
        const psfx = oPhysician.c_suffix ? ` ${oPhysician.c_suffix}` : '';
        orderLineItemObject.order[
          'providerName'
        ] = `${ppfx}${pfn} ${pln}${psfx}`;
        orderLineItemObject['modifiers'] = '-';
        const modifiers = orderLineItemObject.c_modifier.filter(
          (m) => m.c_checked
        );
        if (modifiers.length) {
          orderLineItemObject['modifiers'] = modifiers
            .map((m) => m.c_modifier)
            .join(' | ');
        }

        if (!orderLineItemObject.c_sales_tax) {
          orderLineItemObject.c_sales_tax = 0;
        }
        if (orderLineItemObject.c_product.c_product_options != undefined) {
          orderLineItemObject.c_product.cpy_product_option = orderLineItemObject.c_product.c_product_options.filter(
            (opt) => opt.c_code === orderLineItemObject.c_product_option_code
          )[0];
        }

        if (orderLineItemObject.c_secondary_insurance) {
          console.log('Order with secondary insurance: ', orderLineItemObject);
        }
        this.orderLineItemsFilter.push(orderLineItemObject);
      });
      this.ordersToProcess--;

      this.lineItemsLoaded = true;
      console.log('Loaded Order List: ', this.orderLineItemsFilter);
    });
  }

  dateToMedableFormat(datePickerFormat) {
    const year = datePickerFormat.date.year;
    const month =
      datePickerFormat.date.month > 10
        ? datePickerFormat.date.month
        : '0' + datePickerFormat.date.month;
    const day =
      datePickerFormat.date.day > 10
        ? datePickerFormat.date.day
        : '0' + datePickerFormat.date.day;

    return year + '-' + month + '-' + day;
  }

  searchFormValid() {
    const valid =
      (this.minDateModel && this.maxDateModel) ||
      (this.minBilledDateModel && this.maxBilledDateModel) ||
      (this.minDeletedDateModel && this.maxDeletedDateModel);
    return valid;
  }

  nextPage() {
    // this.actualPage++;
    // this.getOrders(1);
    this.loadPage(this.actualPage + 1);
  }

  prevPage() {
    // this.actualPage--;
    // this.getOrders(-1);
    this.loadPage(this.actualPage - 1);
  }

  goToPage(pageNumber) {
    this.actualPage = pageNumber;
    //this.getOrders(pageNumber, true);
    this.loadPage(pageNumber);
  }

  getOrderLineItemsExport(ids) {
    const localSubject = new Subject();
    const expands = 'expand[]=c_product&expand[]=c_product.c_supplier_id';
    const filter = `where={"c_medable_order":{"$in":[${ids}]}}`;
    const query = `?${filter}&${expands}`;

    this.orderService.getOrderLineWithICD(query).subscribe((data) => {
      data = data.data;
      data.map((line) => {
        if (line.c_product.c_product_options != undefined) {
          line.c_product.cpy_product_option = line.c_product.c_product_options.filter(
            (opt) => opt.c_code === line.c_product_option_code
          )[0];
        } else {
          line.c_product.cpy_product_option = '-';
        }
      });
      this.exportData = this.exportData.concat(data);

      localSubject.next(data);
      localSubject.complete();
    });

    return localSubject;
  }

  searchLocationWrapper() {
    let searchLocation = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          // console.log("searching: ", term);
          if (term === '') {
            return [];
          } else {
            const locations = this.allLocations.filter((location) => {
              const matches =
                  location.c_company.toLowerCase().indexOf(term.toLowerCase()) >
                  -1,
                alreadyAdded =
                  this.locationItems.filter((li) => li._id == location._id)
                    .length > 0;

              if (matches && !alreadyAdded) {
                return true;
              }
            });
            console.log('Found Locations: ', locations);
            return locations.length ? locations : [];
          }
        })
      );
    };

    return searchLocation;
  }

  // searchHcpcsWrapper

  searchHcpcsWrapper() {
    let searchHcpcs = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const codes = this.allHcpcsCodes.filter(
              (code) =>
                code.c_hcpcs_code.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            console.log('Found Codes: ', codes);
            return codes.length ? codes : [];
          }
        })
      );
    };

    return searchHcpcs;
  }

  searchInsuranceWrapper() {
    let searchInsurance = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const insurances = this.allInsurances.filter(
              (ins) =>
                ins.c_insurance_name.toLowerCase().indexOf(term.toLowerCase()) >
                -1
            );
            return insurances.length ? insurances : [];
          }
        })
      );
    };

    return searchInsurance;
  }

  searchCreatorWrapper() {
    let searchCreator = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const email = this.allUsers.filter(
              (user) =>
                user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const firstName = this.allUsers.filter(
              (user) =>
                user.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const lastName = this.allUsers.filter(
              (user) =>
                user.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );

            console.log(
              'Found Users: ',
              email.length
                ? email
                : firstName.length
                ? firstName
                : lastName.length
                ? lastName
                : []
            );
            return email.length
              ? email
              : firstName.length
              ? firstName
              : lastName.length
              ? lastName
              : [];
          }
        })
      );
    };

    return searchCreator;
  }

  searchFitterWrapper() {
    let searchFitter = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(200),
        map((term) => {
          if (term === '') {
            return [];
          } else {
            const email = this.allUsers.filter(
              (user) =>
                user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const firstName = this.allUsers.filter(
              (user) =>
                user.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );
            const lastName = this.allUsers.filter(
              (user) =>
                user.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
            );

            console.log(
              'Found Users: ',
              email.length
                ? email
                : firstName.length
                ? firstName
                : lastName.length
                ? lastName
                : []
            );
            return email.length
              ? email
              : firstName.length
              ? firstName
              : lastName.length
              ? lastName
              : [];
          }
        })
      );
    };

    return searchFitter;
  }

  locationInputFocus() {
    this.selectedLocation = {
      c_company: '',
    };
    this.locationSelected = false;
  }

  locationInputFocusOut() {
    setTimeout(() => {
      if (!this.locationSelected) {
        this.selectedLocation = {
          c_company: '',
        };
      }
    }, 500);
  }

  hcpcsInputFocus() {
    this.selectedHcpcs = {
      c_hcpcs_code: '',
    };
    this.hcpcsSelected = false;
  }

  hcpcsInputFocusOut() {
    setTimeout(() => {
      if (!this.hcpcsSelected) {
        this.selectedHcpcs = {
          c_hcpcs_code: '',
        };
      }
    }, 500);
  }

  buildHcpcsFilter(hcpcs) {
    const productIds = this.allProducts
      .filter((p) => p.c_hcpcs_code == hcpcs.c_hcpcs_code)
      .map((prod) => `"${prod._id}"`);

    this.productFilterIds = productIds;
    console.log('Filtered productIds: ', productIds);
  }

  selectHcpcs(hcpcs) {
    this.buildHcpcsFilter(hcpcs);
    this.selectedHcpcs = hcpcs;
    this.hcpcsSelected = true;
  }

  userInputFocus() {
    this.selectedCreator = {
      firstName: '',
      lastName: '',
      email: '',
    };
    this.userSelected = false;
  }

  userInputFocusOut() {
    setTimeout(() => {
      if (!this.userSelected) {
        this.selectedCreator = {
          firstName: '',
          lastName: '',
          email: '',
        };
      }
    }, 500);
  }

  selectUser(user) {
    this.selectedCreator = user;
    this.userSelected = true;
  }

  fitterInputFocus() {
    this.selectedFitter = {
      firstName: '',
      lastName: '',
      email: '',
    };
    this.userSelected = false;
  }

  fitterInputFocusOut() {
    setTimeout(() => {
      if (!this.fitterSelecrted) {
        this.selectedFitter = {
          firstName: '',
          lastName: '',
          email: '',
        };
      }
    }, 500);
  }

  selectFitter(fitter) {
    this.selectedFitter = fitter;
    this.fitterSelecrted = true;
  }

  selectLocation(location) {
    this.locationItems.push(location);
    this.buildProviderLocationIds();
    //this.selectedLocation = location;
    //this.locationSelected = true;
    console.log('Current LocationItems: ', this.locationItems);
  }

  removeLocationItem(index) {
    this.locationItems.splice(index, 1);
  }

  insuranceInputFocus() {
    this.selectedInsurance = {
      c_insurance_name: '',
    };
    this.insuranceSelected = false;
  }

  insuranceInputFocusOut() {
    setTimeout(() => {
      if (!this.insuranceSelected) {
        this.selectedInsurance = {
          c_insurance_name: '',
        };
      }
    }, 500);
  }

  selectInsurance(insurance) {
    // this.buildProviderLocationIds(location);
    this.selectedInsurance = insurance;
    this.insuranceSelected = true;
  }

  buildProviderLocationIds() {
    console.log('Building ids for location: ', location);
    let providerLocationIds = [];
    // const locationId = location._id;
    // this.selectedProviderLocation = this.allProviderLocations
    //                                     .filter( pLoc =>
    //                                       pLoc.c_location._id == locationId
    //                                     ).map( pLoc =>
    //                                       `"${pLoc._id}"`
    //                                     );
    this.locationItems.map((li) => {
      const addedLocationIds = this.allProviderLocations
        .filter((pLoc) => pLoc.c_location._id == li._id)
        .map((pLoc) => `"${pLoc._id}"`);
      providerLocationIds = providerLocationIds.concat(addedLocationIds);
    });

    this.selectedProviderLocation = providerLocationIds;
    console.log('Selected Provider Locations: ', providerLocationIds);
  }

  locationFormatter = (x: { c_company: string }) => x.c_company;

  insuranceFormatter = (x: { c_insurance_name: string }) => x.c_insurance_name;

  creatorFormatter = (x: {
    firstName: string;
    lastName: string;
    id?: string;
  }) => (x.id ? `${x.firstName} ${x.lastName}` : '');

  fitterFormatter = (x: { firstName: string; lastName: string; id?: string }) =>
    x.id ? `${x.firstName} ${x.lastName}` : '';

  hcpcsFormatter = (x: { c_hcpcs_code: string }) => x.c_hcpcs_code;

  selectOrderForBulkUpdate(event: any, order: any) {

    if (event.currentTarget.checked) {
      // Select
      const billedDate =
        order.display_billed_date && order.display_billed_date !== '-' ?
        this.dateService.toDatepickerModel(new Date(order.display_billed_date)) : null;
      const orderSelected = {
        ...order,
        billed_date_obj: billedDate,
        newClaimId: order.claimId
      }
      this.bulkUpdateOrders.push(orderSelected);
    } else {
      // Unselect
      const index = this.bulkUpdateOrders.findIndex( (item) => item.id === order.id );
      this.bulkUpdateOrders.splice(index, 1);
    }
  }

  selectedForBulk(id) {
    for(const order of this.bulkUpdateOrders) {
      if (id == order.id)
        return true;
    }
    return false
  }

  openModal(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  bulkChangeAllBilledDates(event) {
    const newDateObj = event.singleDate.epoc ? event : null;
    this.bulkUpdateOrders.map(item => { item.billed_date_obj = newDateObj; })
  }

  disableBulkUpdateButton() {
    for (const item of this.bulkUpdateOrders) {
      if(this.isUpdatable(item)) return false;
    }
    return true;
  }

  isUpdatable(order) {
    if (order.newClaimId !== order.claimId) return true;

    if (order.display_billed_date === '-') {
      return !!order.billed_date_obj;
    } else {
      if (order.billed_date_obj === null)
        return true;
      else if (
        this.medableDateToDisplay(new Date(order.billed_date_obj.singleDate.date))
        !== order.display_billed_date
      )
        return true;
    }
    return false;
  }

  updateOrders(modalInstance) {
    let observables: Observable<any>[] = [];

    for (let order of this.bulkUpdateOrders) {

      const date = this.dateService.format(order.billed_date_obj);
      let dispatched = true;
      if (date === null) {
        dispatched = false;
      }
      if (this.isUpdatable(order)){
        const orderToUpdate = {
          c_billed_date: date,
          c_claim_id: order.newClaimId,
          c_dispatched: dispatched
        }
        observables.push(this.orderService.editOrder(orderToUpdate, order.id))
      }
    }

    forkJoin(observables)
      .subscribe(
        sucessArray => {
        this.getTableItems();
        setTimeout( () => {
          this.processingBulkUpdate = "The orders have been updated successfully!";
        }, 1000);

        setTimeout( () => {
          modalInstance.close();
          this.processingBulkUpdate = null;
        }, 6000);
      },
        error => {
          setTimeout( () => {
            console.log("ERROR bulk update: ", error)
            this.processingBulkUpdate = "There has been an error while updating the orders. Please try again.";
          }, 500);
        });
  }
}
