<app-form-list class="ListContainer"></app-form-list>

<!-- Index -->
<div class="forms-form" *ngIf="indexPage">
  <button
    type="button"
    *ngIf="hasRole.admin"
    class="button create-button"
    (click)="goToCreateForm()"
  >
    {{ 'forms.form-form-new.title' | lang }}
  </button>
</div>

<!-- View -->
<div class="forms-form" *ngIf="viewForm">
  <div class="col-xs-12">
    <h1>{{ form.c_name }}</h1>
    <div class="pull-right" *ngIf="notEditable">
      <button
        *ngIf="hasRole.admin"
        class="button delete-button"
        (click)="deleteForm()"
      >
        {{ 'forms.delete' | lang }}
      </button>
      <button
        *ngIf="hasRole.admin"
        class="button edit-button"
        (click)="goToEditForm(formId)"
      >
        {{ 'forms.edit' | lang }}
      </button>
      <button
        *ngIf="hasRole.admin"
        class="button download-button"
        (click)="downloadTemplate(formId)"
      >
        {{ 'forms.download' | lang }}
      </button>
    </div>
  </div>

  <div class="row mb25">
    <div class="first-info-container">
      <ul>
        <li class="hidden"></li>
        <li>
          <label>{{ 'forms.selected_file' | lang }}</label>
          <p>{{ form.c_template_name }}</p>
        </li>
        <li>
          <button
            style="margin-top: 0"
            (click)="showForm(formId)"
            class="button back-button"
          >
            Show form
          </button>
        </li>
        <li>
          <label>{{ 'forms.form.label.name.base' | lang }}</label>
          <p>{{ form.c_base_name ? form.c_base_name : '-' }}</p>
        </li>
        <li>
          <label>{{ 'forms.form.label.language' | lang }}</label>
          <p>{{ form.c_language ? form.c_language : '-' }}</p>
        </li>
      </ul>
    </div>
  </div>

  <!-- HCPCS Code List -->
  <div class="row mb10">
    <div class="col-xs-12">
      <label>{{ 'modifier.hcpcs_codes' | lang }}</label>
      <label>
        {{
          form.c_all_hcpcs_codes
            ? '(All)'
            : form.c_hcpcs_codes != undefined && form.c_hcpcs_codes.length > 0
            ? ''
            : ' (None)'
        }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="let code of form.c_hcpcs_codes; let i = index"
        >
          <div class="ll-list-item-text">
            {{ code.c_hcpcs_code.c_hcpcs_code }}
          </div>
          <!-- <div class="ll-list-item-delete" (click)="removeHcpcsCode(i)" >x</div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Insurance List -->
  <div class="row mb10">
    <div class="col-xs-12">
      <label>Insurances:</label>
      <label>
        {{
          form.c_insurances == undefined || form.c_insurances.length === 0
            ? '(All)'
            : ''
        }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="let insurance of form.c_insurances; let i = index"
        >
          <div class="ll-list-item-text">
            {{ insurance.c_insurance.c_insurance_name }}
          </div>
          <!-- <div class="ll-list-item-delete" (click)="removeHcpcsCode(i)" >x</div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Locations List -->
  <div class="row mb10">
    <div class="col-xs-12">
      <label>Locations:</label>
      <label>
        {{
          form.c_locations == undefined || form.c_locations.length === 0
            ? '(All)'
            : ''
        }}
      </label>
      <div class="col-xs-12 ll-item-list-container">
        <div
          class="ll-list-item-noteditable"
          *ngFor="let location of form.c_locations; let i = index"
        >
          <div class="ll-list-item-text">
            {{ location.c_location.c_company }}
          </div>
          <!-- <div class="ll-list-item-delete" (click)="removeHcpcsCode(i)" >x</div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Contains Signature -->
  <div class="row">
    <div class="col-xs-12">
      <label>
        {{
          form.c_contains_signature
            ? '-Contains Patient Signature'
            : '-Does not contain patient signature'
        }}
      </label>
    </div>
  </div>

  <div class="row separate-top">
    <div class="col-xs-12">
      <label style="font-weight: bold">
        <div class="list-icon"></div>
        {{ 'forms.questions' | lang }}
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <ul class="list-selected">
        <li *ngFor="let question of questions">
          <div class="list-selected-header">
            <label>{{ question.c_text }}</label>
            <div class="clearfix"></div>
          </div>

          <div class="list-selected-subitem">
            <div class="list-selected-subitem-name">
              <label>{{ 'forms.questions.settings' | lang }}</label>
            </div>
            <ul class="list-selected-subitem-list">
              <li>
                <label [ngClass]="{ 'label-disabled': !question.c_required }">
                  {{ 'forms.questions.required' | lang }}
                </label>
              </li>
              <li>
                <label>{{ 'forms.questions.type' | lang }}</label>
                <span *ngIf="question.c_response_type == 'text'">Text</span>
                <span *ngIf="question.c_response_type == 'check'">Options</span>
              </li>
              <li *ngIf="question.c_response_type === 'check'">
                <label
                  [ngClass]="{ 'label-disabled': !question.c_multi_select }"
                >
                  {{ 'forms.questions.multiselect' | lang }}
                </label>
              </li>
            </ul>
          </div>

          <div class="clearfix"></div>

          <div
            class="list-selected-subitem"
            *ngIf="question.c_response_type === 'check'"
          >
            <div class="list-selected-subitem-name">
              <label>{{ 'forms.questions.options' | lang }}</label>
            </div>
            <ul class="list-selected-subitem-list">
              <li *ngFor="let option of question.c_response_options">
                <label>{{ option }}</label>
              </li>
            </ul>
          </div>

          <div class="clearfix"></div>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Create/Edit -->

<div class="forms-form" *ngIf="createOrEdit">
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ 'forms.title' | lang }}</h1>
      <div class="pull-right" *ngIf="notEditable">
        <button
          *ngIf="hasRole.admin"
          class="button delete-button"
          ng-click="showPopupDelete()"
        >
          {{ 'forms.delete' | lang }}
        </button>
        <button
          *ngIf="hasRole.admin"
          class="button"
          ng-click="goToEditForm(formId)"
        >
          {{ 'forms.edit' | lang }}
        </button>
      </div>
    </div>
  </div>

  <form
    class="step"
    name="form"
    (ngSubmit)="
      f.form.valid &&
        onSubmit() &&
        (flow.files.length || form.c_template_name != null || 1)
    "
    #f="ngForm"
    novalidate
  >
    <div class="container-fluid">
      <!-- Name -->
      <div class="row">
        <div
          class="col-xs-12"
          [ngClass]="{ 'required-input': form.$submitted && !form.name.$valid }"
        >
          <label>
            {{ 'forms.form.label.name' | lang }} *
            <span class="required-span">
              {{ 'forms.error.required' | lang }}
            </span>
          </label>
          <input
            name="name"
            [(ngModel)]="form.c_name"
            type="text"
            required
            [disabled]="notEditable"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label>{{ 'forms.form.label.name.base' | lang }}</label>
          <input
            name="base-name"
            [(ngModel)]="form.c_base_name"
            type="text"
            [disabled]="notEditable"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label>{{ 'forms.form.label.language' | lang }}</label>
          <select [(ngModel)]="chosenLanguage" name="language-select">
            <option
              *ngFor="let language of languageOptions"
              [ngValue]="language"
            >
              {{ language.label }}
            </option>
          </select>
        </div>
      </div>

      <!-- HCPCS Code List -->
      <div class="row">
        <div class="col-xs-12">
          <label>{{ 'modifier.hcpcs_codes' | lang }}</label>
          <label>-All</label>
          <input
            (change)="allCodesChange()"
            name="all_codes_check"
            type="checkbox"
            class="radio-button ll-custom-checkbox"
            [(ngModel)]="form.c_all_hcpcs_codes"
          />
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let code of hcpcs_codes_added; let i = index"
            >
              <div class="ll-list-item-text">{{ code.c_hcpcs_code }}</div>
              <div class="ll-list-item-delete" (click)="removeHcpcsCode(i)">
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Contains Patient Signature  -->
      <div class="row">
        <div class="col-xs-12">
          <label>Contains Patient Signature</label>
          <input
            (change)="toggleContainsSignature()"
            name="contains_signature_check"
            type="checkbox"
            class="radio-button ll-custom-checkbox"
            [(ngModel)]="form.c_contains_signature"
          />
        </div>
      </div>

      <!-- HCPCS Code Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>{{ 'modifier.hcpcs.add' | lang }}</label>
          <!-- Autocomplete hcpcs input -->
          <input
            placeholder="{{ 'modifier.placeholder.hcpcs' | lang }}"
            name="searchHcpcsCode"
            id="autocomplete_hcpcs_code"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchHcpcs()"
            [resultTemplate]="autocompleteHcpcsCodes"
            [inputFormatter]="hcpcsCodeFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteHcpcsCodes let-r="result" let-t="term">
            <li (click)="addHcpcsCode(r)">{{ r.c_hcpcs_code }}</li>
          </ng-template>
        </div>
      </div>

      <!-- Insurances List -->
      <div class="row">
        <div class="col-xs-12">
          <label>Insurances</label>
          <label>
            {{
              insurances_added == undefined || insurances_added.length === 0
                ? ' (All)'
                : ''
            }}
          </label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let insurance of insurances_added; let i = index"
            >
              <div class="ll-list-item-text">
                {{ insurance.c_insurance_name }}
              </div>
              <div class="ll-list-item-delete" (click)="removeInsurance(i)">
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Insurance Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>Add Particular Insurance Provider</label>
          <!-- Autocomplete insurance input -->
          <input
            placeholder="Add Insurance Provider"
            name="searchInsurance"
            id="autocomplete_insurance"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchInsurance()"
            [resultTemplate]="autocompleteInsurances"
            [inputFormatter]="insuranceFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteInsurances let-r="result" let-t="term">
            <li (click)="addInsurance(r)">{{ r.c_insurance_name }}</li>
          </ng-template>
        </div>
      </div>

      <!-- Locations List -->
      <div class="row">
        <div class="col-xs-12">
          <label>Locations</label>
          <div class="col-xs-12 ll-item-list-container">
            <div
              class="ll-list-item"
              *ngFor="let location of locationsAdded; let i = index"
            >
              <div class="ll-list-item-text">{{ location.c_company }}</div>
              <div class="ll-list-item-delete" (click)="removeLocation(i)">
                x
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Location Input -->
      <div class="row">
        <div class="col-xs-12 hcpcs_autocomplete">
          <label>Add Particular Location (Clinic)</label>
          <!-- Autocomplete insurance input -->
          <input
            placeholder="Add Location"
            name="searchLocation"
            id="autocomplete_location"
            type="text"
            class="highlight"
            [ngbTypeahead]="searchLocation()"
            [resultTemplate]="autocompleteLocation"
            [inputFormatter]="locationFormatter"
          />
          <!-- Template for suggested hcpcs codes -->
          <ng-template #autocompleteLocation let-r="result" let-t="term">
            <li (click)="addLocation(r)">{{ r.c_company }}</li>
          </ng-template>
        </div>
      </div>

      <div
        class="row separate-top"
        [hidden]="notEditable"
        ngClass="{'required-input': flow.files[0] && flow.files[0].name.indexOf('.json') == -1}"
      >
        <div
          class="col-xs-12 col-md-6"
          flow-init="{singleFile: true}"
          flow-name="flow"
        >
          <div
            class="button back-button pull-left fileUpload"
            [attr.disabled]="notEditable"
            [hidden]="notEditable"
          >
            <span>{{ 'forms.select_file' | lang }}</span>
            <input
              type="file"
              flow-btn
              class="upload"
              [disabled]="notEditable"
              accept="application/json"
              (change)="fileEvent($event.target.files)"
            />
          </div>
          <div class="clearfix"></div>
          <span class="required-span">
            {{ 'forms.error.invalidFormat' | lang }}
          </span>
        </div>
        <div class="col-xs-12 col-md-6">
          <label *ngIf="flow.files[0]" style="margin-top: 5px">
            {{ 'forms.selected_file' | lang }}
          </label>
          <span *ngFor="let file of flow.files">{{ file.name }}</span>
        </div>
      </div>

      <div
        class="row separate-top"
        *ngIf="!flow.files.length && form.c_template_name"
      >
        <div class="col-xs-12 col-md-6">
          <label style="margin-top: 5px">
            {{ 'forms.selected_file' | lang }}
          </label>
          <span>{{ form.c_template_name }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label style="font-weight: bold">
            <div class="list-icon"></div>
            {{ 'forms.questions' | lang }}
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <ul class="list-selected">
            <li *ngFor="let question of questions; let i = index">
              <div *ngIf="!question.delete" class="list-selected-header">
                <div class="col-xs-9 col-md-9">
                  <input
                    class="full-width"
                    type="text"
                    [(ngModel)]="questions[i].c_text"
                    [disabled]="!question.edit"
                    name="{{ 'question_input' + i }}"
                  />
                </div>
                <div class="col-xs-3 col-md-3">
                  <button
                    type="button"
                    class="delete-trash"
                    (click)="removeQuestion(i)"
                    [disabled]="notEditable"
                  >
                    <div></div>
                  </button>
                  <button
                    type="button"
                    class="edit-pen"
                    (click)="editQuestion(i)"
                    [disabled]="notEditable"
                    [hidden]="question.edit"
                  >
                    <div></div>
                  </button>
                  <button
                    type="button"
                    class="finish-button button"
                    (click)="finishEditQuestion(i)"
                    [hidden]="!question.edit"
                  >
                    Finish editing
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>

              <div *ngIf="!question.delete" class="list-selected-subitem">
                <div class="list-selected-subitem-name">
                  <label>{{ 'forms.questions.settings' | lang }}</label>
                </div>
                <ul class="list-selected-subitem-list">
                  <li>
                    <label
                      [ngClass]="{ 'label-disabled': !question.c_required }"
                    >
                      {{ 'forms.questions.required' | lang }}
                    </label>
                    <input
                      type="checkbox"
                      class="radio-button cst-checkbox"
                      name="{{ 'qr_checkbox' + i }}"
                      [(ngModel)]="question.c_required"
                      [hidden]="!question.edit"
                    />
                  </li>
                  <li>
                    <label>{{ 'forms.questions.type' | lang }}</label>
                    <select
                      [(ngModel)]="question.c_response_type"
                      [disabled]="!question.edit"
                      name="{{ 'question_select' + i }}"
                    >
                      <option value="text">Text</option>
                      <option value="check">Options</option>
                    </select>
                  </li>
                  <li [hidden]="!(question.c_response_type === 'check')">
                    <label
                      [ngClass]="{ 'label-disabled': !question.c_multi_select }"
                    >
                      {{ 'forms.questions.multiselect' | lang }}
                    </label>
                    <input
                      type="checkbox"
                      name="{{ question_resp_checkbox + i }}"
                      name="q_multiselect"
                      class="radio-button cst-checkbox"
                      [(ngModel)]="question.c_multi_select"
                      [disabled]="!question.edit"
                      [hidden]="!question.edit"
                    />
                  </li>
                </ul>
              </div>

              <div class="clearfix"></div>

              <div
                *ngIf="!question.delete"
                class="list-selected-subitem"
                [hidden]="!(question.c_response_type === 'check')"
              >
                <div class="list-selected-subitem-name">
                  <label>{{ 'forms.questions.options' | lang }}</label>
                </div>
                <ul class="list-selected-subitem-list">
                  <li
                    *ngFor="
                      let qcOption of question.c_response_options;
                      let oIdx = index;
                      trackBy: trackByFn
                    "
                  >
                    <div class="col-xs-11">
                      <input
                        type="text"
                        name="{{ 'question_' + i + '_option_' + oIdx }}"
                        [disabled]="!question.edit"
                        [(ngModel)]="question.c_response_options[oIdx]"
                        class="full-width"
                      />
                    </div>
                    <div class="col-xs-1">
                      <button
                        type="button"
                        class="delete-trash mt13"
                        (click)="removeOption(i, oIdx)"
                        [hidden]="!question.edit"
                      >
                        <div></div>
                      </button>
                    </div>
                    <div class="clearfix"></div>
                  </li>
                  <li
                    ng-show="question.edit"
                    [ngClass]="{ 'required-input': question.error }"
                  >
                    <div class="col-xs-12 col-sm-10">
                      <input
                        type="text"
                        name="q_new_option"
                        [(ngModel)]="question.optionNew"
                        placeholder="Add option"
                      />
                      <span class="required-span">
                        {{ 'forms.error.required' | lang }}
                      </span>
                    </div>
                    <div class="col-xs-12 col-sm-2">
                      <button
                        type="button"
                        class="inline-button button"
                        (click)="addOption(questions.indexOf(question))"
                        [disabled]="!question.optionNew"
                      >
                        ADD
                      </button>
                    </div>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              </div>

              <div class="clearfix"></div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <input
            class="col-xs-12 col-sm-10"
            name="questionAdd"
            placeholder="Add new question"
            type="text"
            [(ngModel)]="questionNew.text"
          />
          <div class="col-xs-12 col-sm-2">
            <button
              type="button"
              class="inline-button button"
              (click)="addQuestion()"
            >
              ADD
            </button>
          </div>
        </div>
      </div>

      <div class="row" [hidden]="notEditable">
        <div class="col-xs-12">
          <span [hidden]="!editingQuestions" class="span-duplicated-error">
            {{ 'forms.error.editing_questions' | lang }}
          </span>
          <button
            class="button next-button"
            type="submit"
            [disabled]="!f.form.valid"
          >
            {{ isEditMode ? ('forms.save' | lang) : ('forms.submit' | lang) }}
          </button>
          <button type="button" class="button back-button" (click)="cancel()">
            {{ 'forms.cancel' | lang }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12" [hidden]="!notEditable">
          <button (click)="showForm(formId)" class="button next-button">
            Show form
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
